import React, { useState, useEffect } from "react"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import clsx from "clsx"
import PropTypes from "prop-types"
import DoneIcon from "@material-ui/icons/Done"

import {
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import EmailIcon from "@material-ui/icons/Email"

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    margin: 10,
  },
  margin: {
    margin: 2,
  },
}))

const UpdateOrgEmail = ({
  org,
  emailAddresses,
  setEmailAddresses,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const ouid = org.ouid
  const [emailAccount, setEmailAccount] = useState("")
  const [addedEmails, setAddedEmails] = useState([])
  const [newArray, setNewArray] = useState([])
  const [emailId, setEmailId] = useState(null)

  const [deleteOrgEmailAccount] = useMutation(DELETE_ORG_EMAIL_MUTATION, {
    variables: {
      emailId: emailId,
    },
    onCompleted: () => {
      const prevEmails = emailAddresses
      const filteredEmailArray = emailAddresses.filter(x => x.id !== emailId)
      setEmailAddresses(filteredEmailArray)
      setEmailAccount("")
    },
  })

  const [createOrgEmailAccount] = useMutation(ADD_ORG_EMAIL_MUTATION, {
    variables: { ouid: ouid, emailAddress: emailAccount },
    onCompleted: data => {
      const prevEmails = emailAddresses
      const emailValue = data.createOrgEmailAccount.emailAccount
      setEmailAddresses(prevEmails => [...prevEmails, emailValue])
      setEmailAccount("")
    },
  })
  const handleFormEmail = event => {
    createOrgEmailAccount()
  }
  const handleDelete = email => {
    const filteredArray = emailAddresses.filter(x => x.id !== email.id)
    setNewArray(filteredArray)
    setEmailId(email.id)
  }
  useEffect(() => {
    if (emailId) {
      deleteOrgEmailAccount()
    }
  }, [emailId])

  return (
    <>
      <Box className={classes.top} mb={2}>
        <EmailIcon style={{ fontSize: 30, marginBottom: -6 }} />
        <Box ml={3} display="inline">
          <Typography display="inline" variant="h3">
            Email
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <>
            {emailAddresses &&
              emailAddresses.map(email => (
                <Box key={email.id}>
                  <Chip
                    icon={<DoneIcon />}
                    label={email.emailAddress}
                    variant="outlined"
                    onDelete={e => handleDelete(email)}
                  />
                </Box>
              ))}
          </>
        </Grid>
        <Grid item sm container direction="column">
          <form
            onSubmit={event => {
              event.preventDefault()
              handleFormEmail(event)
            }}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Email"
                margin="normal"
                name="emailAccount"
                onChange={event => setEmailAccount(event.target.value)}
                type="email"
                value={emailAccount}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                aria-label="add email"
                variant="contained"
                color="secondary"
              >
                Add Email Address
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  )
}

const ADD_ORG_EMAIL_MUTATION = gql`
  mutation ($emailAddress: String!, $ouid: String!) {
    createOrgEmailAccount(emailAddress: $emailAddress, ouid: $ouid) {
      emailAccount {
        id
        emailAddress
      }
    }
  }
`

const DELETE_ORG_EMAIL_MUTATION = gql`
  mutation ($emailId: Int!) {
    deleteOrgEmailAccount(emailAccountId: $emailAccountId) {
      emailAccountId
    }
  }
`
UpdateOrgEmail.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
}

UpdateOrgEmail.default = {
  onSubmitSuccess: () => {},
}

export default UpdateOrgEmail
