import React, { createContext, useEffect } from "react"

const TasksContext = createContext({
  page: 1,
  pageSize: 10,
  pageCount: null,
  search: "",
  selectedTask: "",
  tasks: [],
  taskFilters: [],
  userPerm: "",
  showUnassignedTasks: true,
  allStaffColumns: [],
  activeStaffColumns: [],
  staffTasks: [],
})

export default TasksContext
