import React, { createContext } from "react"

const SortContext = createContext({
  section: "orders",
  search: "",
  pageSize: 10,
  page: 1,
  recentFirst: true,
  sidebar: false,
  archivedFilter: false,
  genericOrderBy: "",
  itemTasksOrderBy: "",
  quoteTasksOrderBy: "",
  orderTasksOrderBy: "",
  projectTasksOrderBy: "",
})

export default SortContext
