import React, { useState, useContext } from "react"
import { Link, navigate } from "gatsby"
import clsx from "clsx"
import moment from "moment"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  InputAdornment,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
  AlertTriangle as SpamIcon,
  CheckSquare as CheckSquareIcon,
  Gift as SamplesIcon,
} from "react-feather"
import NewIcon from "@material-ui/icons/FiberNew"
import Label from "../../../Global/Label"
import GenericMoreButton from "../../../Global/GenericMoreButton"
import BulkOperations from "./BulkOperations"
import SortContext from "../../../../context/SortContext"

function applyPagination(inquiries, page, limit) {
  return inquiries.slice(page * limit, page * limit + limit)
}

const paymentStatusColors = {
  canceled: "error",
  pending: "warning",
  completed: "success",
  rejected: "error",
}
const businessUnitOptions = [
  {
    id: 0,
    name: "All",
  },
  {
    id: 1,
    name: "Jersey Emblems",
  },
  {
    id: 2,
    name: "ICON Emblem",
  },
  {
    id: 3,
    name: "Brians Beanies",
  },
  {
    id: 4,
    name: "Decide Direct",
  },
]

const sortOptions = [
  {
    value: "newestFirst",
    label: "Newest first",
  },
  {
    value: "oldestFirst",
    label: "Oldest first",
  },
]
const useStyles = makeStyles(theme => ({
  root: {},
  table: {
    marginTop: 5,
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

function Results({
  className,
  res,
  businessUnitFilter,
  setBusinessUnitFilter,
  recentFirstFilter,
  setRecentFirstFilter,
  ...rest
}) {
  const { state, dispatch } = useContext(SortContext)
  const page = state.page
  const pageSize = state.pageSize
  const searchContext = state.search
  const [searchBar, setSearchBar] = useState(searchContext)
  const classes = useStyles()
  const resCount = res.count
  const resPage = res.page
  const resPages = res.pages
  const inquiries = res.objects
  const name = res.name
  const [sort, setSort] = useState(sortOptions[0].value)

  const [selectedInquiries, setSelectedInquiries] = useState([])

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  const handleSelectAllInquiries = event => {
    setSelectedInquiries(
      event.target.checked ? inquiries.map(inquiry => inquiry.id) : []
    )
  }

  const handleSelectOneInquiry = (event, inquiryId) => {
    if (!selectedInquiries.includes(inquiryId)) {
      setSelectedInquiries(prevSelected => [...prevSelected, inquiryId])
    } else {
      setSelectedInquiries(prevSelected =>
        prevSelected.filter(id => id !== inquiryId)
      )
    }
  }

  const handlePageChange = (event, newPage) => {
    let x = 1
    const pagiNewPage = newPage
    dispatch({ type: "SET_PAGE", payload: pagiNewPage })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  // const handleRecentFirstChange = event => {
  //   event.persist()

  //   let value = null

  //   if (event.target.checked) {
  //     value = true
  //   }

  //   setFromCartFilter(value)
  // }

  // const handleInProgressChange = event => {
  //   event.persist()

  //   let value = null

  //   if (event.target.checked) {
  //     value = true
  //   }
  //   setInProgressFilter(value)
  // }

  const handleBusinessUnitChange = event => {
    event.persist()

    let value = 0

    if (event.target.value !== 0) {
      value = event.target.value
    }

    setBusinessUnitFilter(value)
  }

  // const handleOrderStatusChange = event => {
  //   event.persist()

  //   let value = ""

  //   if (event.target.value !== "all") {
  //     value = event.target.value
  //   }
  //   setOrderStatusFilter(value)
  // }
  const handleSortChange = event => {
    event.persist()
    if (event.target.value !== "oldestFirst") {
      // value = event.target.valuesetRecentFirstFilter(true)
    } else {
      setRecentFirstFilter(false)
    }

    // setSort(event.target.value)
  }

  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
  }

  const handleClearFilters = event => {
    setBusinessUnitFilter(0)
    // setFromCartFilter(null)
    // setInProgressFilter(null)
    // setOrderStatusFilter("")
  }
  const enableBulkOperations = selectedInquiries.length > 0
  const selectedSomeInquiries =
    selectedInquiries.length > 0 && selectedInquiries.length < inquiries.length
  const selectedAllInquiries = selectedInquiries.length === inquiries.length

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card className={classes.roundedCard}>
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={10} sm={5}>
              <Paper
                component="form"
                onSubmit={event => {
                  event.preventDefault()
                  handleSearch(event)
                }}
                className={classes.searchField}
              >
                <InputBase
                  fullWidth
                  className={classes.input}
                  placeholder="Search Inquiries"
                  inputProps={{ "aria-label": "Search Inquiries" }}
                  onChange={event => setSearchBar(event.target.value)}
                  value={searchBar}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={handleClearSearchResults}
                >
                  <CancelIcon />
                </IconButton>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Sort By"
                name="sort"
                onChange={handleSortChange}
                select
                SelectProps={{ native: true }}
                value={recentFirstFilter}
                variant="outlined"
              >
                {sortOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>

        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                className={classes.businessUnitField}
                label="Business Unit"
                name="businessUnit"
                onChange={handleBusinessUnitChange}
                select
                SelectProps={{ native: true }}
                value={businessUnitFilter}
                variant="outlined"
              >
                <option></option>
                {businessUnitOptions.map(businessUnitOption => (
                  <option
                    key={businessUnitOption.id}
                    value={businessUnitOption.id}
                  >
                    {businessUnitOption.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormControlLabel
                className={classes.shippableField}
                control={
                  <Checkbox
                    // checked={inProgressFilter}
                    // onChange={handleInProgressChange}
                    name="IncludeSpam"
                  />
                }
                label="Include Spam"
              />
            </Grid>
            {/* <Grid item xs={12} sm={3}>
              <TextField
                className={classes.orderStatusField}
                fullWidth
                label="Order Status"
                name="orderStatus"
                // onChange={handleOrderStatusChange}
                select
                SelectProps={{ native: true }}
                // value={orderStatusFilter}
                variant="outlined"
              >
                <option></option> */}
            {/* {orderStatusOptions.map(orderStatusOption => (
                  <option
                    key={orderStatusOption.id}
                    value={orderStatusOption.id}
                  >
                    {orderStatusOption.name}
                  </option>
                ))} */}
            {/* </TextField>
            </Grid> */}
            <Grid item xs={6} sm={2}>
              <FormControlLabel
                className={classes.shippableField}
                control={
                  <Checkbox
                    // checked={inProgressFilter}
                    // onChange={handleInProgressChange}
                    name="New"
                  />
                }
                label="New"
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormControlLabel
                className={classes.shippableField}
                control={
                  <Checkbox
                    // checked={fromCartFilter}
                    // onChange={handleFromCartChange}
                    name="Sample Request"
                  />
                }
                label="Sample Request"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearFilters}
                fullWidth
              >
                Clear
              </Button>
            </Grid>
          </Grid>
          {/* <form
            noValidate
            onSubmit={event => {
              event.preventDefault()
              handleSearch(event)
            }}
          >
            <TextField
              className={classes.queryField}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={event => setSearchBar(event.target.value)}
              // onChange={handleQueryChange}
              placeholder="Search Inquiries"
              // value={query}
              value={searchBar}
              variant="outlined"
            />
            <Button type="submit" variant="contained" color="primary">
              Search
            </Button>
          </form> */}
        </Box>
        <Box flexGrow={1} />
        <Divider />
        <CardHeader
          action={<GenericMoreButton />}
          title={`${resCount} Records found. Page ${page + 1} of ${resPages}`}
        />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={1150}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAllInquiries}
                      indeterminate={selectedSomeInquiries}
                      onChange={handleSelectAllInquiries}
                    />
                  </TableCell>
                  <TableCell>SBU + Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Info</TableCell>

                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inquiries.map(inquiry => {
                  const isInquirySelected = selectedInquiries.includes(
                    inquiry.id
                  )
                  function PersonName() {
                    if (inquiry.inquiryPerson) {
                      var person = inquiry.inquiryPerson
                    }
                    if (inquiry.userInquiryPerson) {
                      var person = inquiry.userInquiryPerson
                    }
                    return person.firstName + " " + person.lastName
                  }
                  const id = inquiry.id
                  const inquiryId = inquiry.id
                  return (
                    <TableRow
                      key={id}
                      selected={selectedInquiries.indexOf(id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isInquirySelected}
                          onChange={event =>
                            handleSelectOneInquiry(event, inquiry.id)
                          }
                          value={isInquirySelected}
                        />
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/manager/inquiries/${inquiry.id}`}
                          state={{ id: `${inquiry.id}` }}
                          className={classes.link}
                        >
                          {inquiry.businessUnit.name}
                        </Link>
                        {/* {inquiry.payment.ref} */}
                        <Typography variant="body2" color="textSecondary">
                          {moment(inquiry.createdAt).format(
                            "DD MMM YYYY | hh:mm"
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {/* <Label color={paymentStatusColors[inquiry.payment.status]}>
                          {inquiry.payment.status}
                        </Label> */}
                        {inquiry.new && (
                          <NewIcon
                            // fontSize="small"
                            style={{ color: "green" }}
                          />
                        )}{" "}
                        {inquiry.quoteCreated && (
                          // <IconButton
                          //   color="secondary"
                          //   aria-label="quote created"
                          //   component="span"
                          //   size="small"
                          // >
                          <CheckSquareIcon
                            fontSize="small"
                            style={{ color: "blue" }}
                          />
                          // </IconButton>
                        )}{" "}
                        {inquiry.spam && (
                          <SvgIcon fontSize="small" style={{ color: "orange" }}>
                            <SpamIcon />
                          </SvgIcon>
                        )}
                      </TableCell>
                      <TableCell>
                        <PersonName />
                      </TableCell>
                      <TableCell>
                        {inquiry.contactFormMessage && "Contact Form Message"}
                        {inquiry.contactFormMessage && <br />}
                        {inquiry.source}
                      </TableCell>
                      <TableCell>
                        {inquiry.samplesRequested && (
                          <SvgIcon fontSize="small" style={{ color: "pink" }}>
                            <SamplesIcon />
                          </SvgIcon>
                        )}
                      </TableCell>

                      <TableCell align="right">
                        <IconButton
                          onClick={event => {
                            navigate(
                              `/manager/inquiries/${inquiry.id}`,
                              {
                                state: { id: `${inquiry.id}` },
                              },
                              { replace: true }
                            )
                          }}
                        >
                          {/* <IconButton
                          component={Link}
                          to={`/manager/inquiries/${inquiry.id}`}
                          state={{id: `${inquiry.id}`}}
                          state={inquiry}
                        > */}
                          <SvgIcon fontSize="small">
                            <ArrowRightIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={resCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </Card>
      <BulkOperations
        open={enableBulkOperations}
        selected={selectedInquiries}
        setSelectedInquiries={setSelectedInquiries}
      />
    </div>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  inquiries: PropTypes.array,
}

Results.defaultProps = {
  inquiries: [],
}

export default Results
