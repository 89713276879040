import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Box, Container, Grid, makeStyles } from "@material-ui/core"
import Brief from "./Brief"
import StatusOverview from "./StatusOverview"
// import ToDoList from "./ToDoList"
import Holder from "./Holder"
import Members from "./Members"

const useStyles = makeStyles(theme => ({
  root: {},
  statusBox: {
    marginTop: theme.spacing(3),
  },
}))

function Overview({ project, className, ...rest }) {
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={3}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item sm={8} xl={9} xs={12}>
        <Brief project={project} />

        <Box className={classes.statusBox}>
          <StatusOverview project={project} />
        </Box>
      </Grid>
      <Grid item container xs={12} sm={4} xl={3} spacing={3}>
        <Grid item xs={12}>
          <Holder project={project} />
          {project.personProject && (
            <Box mt={3}>
              <Members project={project} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Overview
