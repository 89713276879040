import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"

import {
  AppBar,
  Box,
  Divider,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  SvgIcon,
} from "@material-ui/core"
import { Menu as MenuIcon } from "react-feather"

import { THEMES } from "../../../constants"
import Account from "./Account"
import Logo from "../Logo"
import Help from "../Help"
// import Contacts from "./Contacts"
// import Notifications from "./Notifications"
// import Search from "./Search"
// import Settings from "./Settings"

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          // boxShadow: "1px 2px 9px #888888",
          backgroundColor: theme.palette.primary.dark,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    backgroundColor: "#DEDEDE",
    minHeight: 64,
  },

  logoBox: {
    width: 235,
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  divider: {
    height: 40,
    margin: 4,
  },
  name: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(20),
    padding: theme.spacing(0, 0, 0, 3),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    display: "inline",
  },
}))

function TopBar({
  className,
  onMobileNavOpen,
  isLoggedIn,
  setIsLoggedIn,
  user,
  ...rest
}) {
  const classes = useStyles()

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="primary"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Box className={classes.logoBox}>
            <Box mt={1} display="flex">
              <Link to="/">
                <Logo />
              </Link>
              <Link to="/" className={classes.link}>
                <Typography className={classes.name}>segmentNation</Typography>
              </Link>
            </Box>
            {/* <SvgIcon>
              <Logo />
            </SvgIcon> */}
            {/* <Image
              fluid={arrowhead.childImageSharp.fluid}
              className="topbar-logo"
            /> */}

            <div className={classes.grow} />
            <Divider className={classes.divider} orientation="vertical" />
          </Box>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {/* <Search />
        <Contacts />
        <Notifications /> */}
        {/* <Settings /> */}
        <Box ml={2} display="flex">
          <Help user={user} />
          <Account
            user={user}
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
          />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

export default TopBar
