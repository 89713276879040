import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Breadcrumbs,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { PlusCircle as PlusIcon } from "react-feather"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

function Header({ className, ...rest }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid
        alignItems="center"
        container
        justify="space-between"
        spacing={3}
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Grid item>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              variant="body1"
              className={classes.link}
              color="inherit"
              to="/manager/"
            >
              Dashboard
            </Link>

            <Typography variant="body1" color="textPrimary">
              Plans
            </Typography>
          </Breadcrumbs>
          <Typography variant="h3" color="textPrimary">
            Plans
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            component={Link}
            to="/manager/plans/create"
            variant="contained"
          >
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <PlusIcon />
            </SvgIcon>
            Make a new plan
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
