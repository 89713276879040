import React, { useEffect, useContext, useReducer } from "react"
import MessagesContext from "./MessagesContext"
import MessagesReducer from "./MessagesReducer"

const MessagesProvider = props => {
  const initialState = useContext(MessagesContext)
  const isBrowser = () => typeof window !== "undefined"
  const localState =
    isBrowser() && JSON.parse(window.localStorage.getItem("cnv"))
  const [state, dispatch] = useReducer(
    MessagesReducer,
    localState || initialState
  )

  useEffect(() => {
    isBrowser() && window.localStorage.setItem("cnv", JSON.stringify(state))
  }, [state])

  return (
    <MessagesContext.Provider value={{ state, dispatch }}>
      {props.children}
    </MessagesContext.Provider>
  )
}

export default MessagesProvider
