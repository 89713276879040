import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
// import Error from "../../../../Utils/Error"
import FormError from "../../../../Utils/FormError"

import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"

import NavigateNextIcon from "@material-ui/icons/NavigateNext"

const useStyles = makeStyles(() => ({
  root: {},
}))

const ItemCreateFormStepOne = ({
  setActiveStep,
  setItem,
  setSpec,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const [itemName, setItemName] = useState("")
  const [isActive, setIsActive] = useState(true)
  const [isPrivate, setIsPrivate] = useState(true)
  const [pricingModelId, setPricingModelId] = useState("")
  const [businessUnitId, setBusinessUnitId] = useState("")
  const [categoryId, setCategoryId] = useState("")
  const [enterPrivate, setEnterPrivate] = useState(false)

  const { loading, data, error } = useQuery(FORM_INFO_QUERY)
  const [createItem] = useMutation(CREATE_ITEM_MUTATION, {
    variables: {
      name: itemName,
      isActive: isActive,
      isPrivate: isPrivate,
      pricingModelId: pricingModelId,
      businessUnitId: businessUnitId,
      categoryId: categoryId,
    },

    onCompleted: data => {
      // const uid = data.createPerson.person.uid
      // setUid(data.createPerson.person.uid)
      console.log(data)
      setItem(data.createItem.item)
      nextStep()
      // console.log(data.createPerson.person.uid)
    },
  })

  if (loading) return <Loading />
  // if (error) return <FormError />
  if (error) return <FormError />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const handleChange = event => {
    setCategoryId()
  }

  const handleSubmit = (event, createItem) => {
    event.preventDefault()

    createItem()
  }
  const handleCheckActive = event => {
    setIsActive(event.target.checked)
  }
  const handleCheckPrivate = event => {
    setIsPrivate(event.target.checked)
  }
  const nextStep = () => {
    //   setActiveStep(1)
    {
      isPrivate ? setActiveStep(1) : setActiveStep(3)
    }
    //   return (
    //     <div>
    //       {isPrivate ? (
    //         <PrivateItemCreateForm itemId={itemId} />
    //       ) : (
    //         <ItemSpecs itemId={itemId} />
    //       )}
    //     </div>
    //   )
  }
  const categories = data.categories
  const sbus = data.sbus
  const pricingModels = data.pricingModels

  return (
    <Card className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Box p={2}>
        <form
          onSubmit={event => handleSubmit(event, createItem)}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <TextField
            fullWidth
            label="Item Name"
            margin="dense"
            size="small"
            name="itemName"
            onChange={event => setItemName(event.target.value)}
            type="itemName"
            value={itemName}
            variant="outlined"
          />
          <TextField
            label="Select a Business Unit"
            name="businessUnit"
            onChange={event => setBusinessUnitId(event.target.value)}
            select
            margin="dense"
            size="small"
            fullWidth
            variant="outlined"
            SelectProps={{ native: true }}
            value={businessUnitId}
          >
            <option></option>
            {sbus.map(b => (
              <option key={b.id} value={b.id}>
                {b.name}
              </option>
            ))}
          </TextField>
          <TextField
            label="Select an Item Category"
            name="category"
            onChange={event => setCategoryId(event.target.value)}
            value={categoryId}
            select
            margin="dense"
            size="small"
            fullWidth
            variant="outlined"
            SelectProps={{ native: true }}
          >
            <option></option>
            {categories.map(c => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </TextField>
          <TextField
            label="Select a Pricing Model"
            name="pricingModel"
            onChange={event => setPricingModelId(event.target.value)}
            select
            margin="dense"
            size="small"
            fullWidth
            variant="outlined"
            SelectProps={{ native: true }}
            value={pricingModelId}
          >
            <option></option>
            {pricingModels.map(p => (
              <option key={p.id} value={p.id}>
                {p.name}
              </option>
            ))}
          </TextField>

          <Box alignItems="center" display="flex" mt={2} ml={-1}>
            <Checkbox
              checked={isActive}
              // name="supplier"
              onChange={handleCheckActive}
            />

            <Typography variant="body2" color="textSecondary">
              This item is active.
            </Typography>
          </Box>
          <Box alignItems="center" display="flex" mt={2} ml={-1}>
            <Checkbox
              checked={isPrivate}
              // name="supplier"
              onChange={handleCheckPrivate}
            />

            <Typography variant="body2" color="textSecondary">
              This is a private item.
            </Typography>
          </Box>
          <Box mt={2}>
            <Button
              color="primary"
              // disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Add New Item
            </Button>
          </Box>
        </form>
        {error ? <FormError /> : ""}
      </Box>
    </Card>
  )
}

const FORM_INFO_QUERY = gql`
  query {
    categories {
      id
      name
    }
    pricingModels {
      id
      name
    }
    sbus {
      id
      name
    }
  }
`
const CREATE_ITEM_MUTATION = gql`
  mutation (
    $name: String!
    $isActive: Boolean
    $isPrivate: Boolean
    $categoryId: Int
    $businessUnitId: Int
    $pricingModelId: Int
  ) {
    createItem(
      name: $name
      isActive: $isActive
      isPrivate: $isPrivate
      categoryId: $categoryId
      businessUnitId: $businessUnitId
      pricingModelId: $pricingModelId
    ) {
      item {
        id
        name
        category {
          id
          name
        }
        businessUnit {
          id
          name
        }
        isPrivate
        isActive
        pricingModel {
          id
          name
        }
      }
    }
  }
`

ItemCreateFormStepOne.propTypes = {
  className: PropTypes.string,
}

export default ItemCreateFormStepOne
