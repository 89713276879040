import React, { useState } from "react"
import MDEditor from "@uiw/react-md-editor"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

const Editor = ({ classes, value, setValue, view }) => {
  return (
    <div className="container">
      {view === "write" && <MDEditor value={value} onChange={setValue} />}
      {view === "read" && <MDEditor.Markdown source={value} />}
    </div>
  )
}
const styles = theme => ({
  root: {},
})
export default withStyles(styles)(Editor)
