export default function reducer(state, action) {
  switch (action.type) {
    case "SET_SPEC_ID":
      return {
        ...state,
        specId: action.payload,
      }
    case "SET_INQUIRY_SPECS":
      return {
        ...state,
        inquirySpecs: action.payload,
      }
    case "SET_ITEM_ID":
      return {
        ...state,
        itemId: action.payload,
      }
    case "SET_SPEC_SUMMARY":
      return {
        ...state,
        specSummary: action.payload,
      }
    case "SET_ITEM":
      return {
        ...state,
        item: action.payload,
      }
    case "SET_CATEGORY_ID":
      return {
        ...state,
        categoryId: action.payload,
      }
    case "SET_EDITING":
      return {
        ...state,
        editing: action.payload,
      }
    case "SET_ACTIVE_STEP":
      return {
        ...state,
        activeStep: action.payload,
      }
    default:
      return state
  }
}
