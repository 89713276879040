import React, { useState } from "react"
import UploadProfileImage from "./UploadProfileImage"
import ImageCropper from "./ImageCropper"

const ProfileImage = ({
  user,
  profileImageUrl,
  setProfileImageUrl,
  setUseGravatar,
  image,
  setImage,
  setUpdatePhoto,
}) => {
  const [imageFile, setImageFile] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [fileField, setFileField] = useState("")
  return (
    <>
      {imageFile ? (
        <UploadProfileImage
          user={user}
          imageFile={imageFile}
          setImageFile={setImageFile}
          fileField={fileField}
          setFileField={setFileField}
          image={image}
          setImage={setImage}
          setProfileImageUrl={setProfileImageUrl}
          profileImageUrl={profileImageUrl}
          setUseGravatar={setUseGravatar}
          setUpdatePhoto={setUpdatePhoto}
        />
      ) : (
        <ImageCropper
          setFileField={setFileField}
          setImageFile={setImageFile}
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
        />
      )}
    </>
  )
}

export default ProfileImage
