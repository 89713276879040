import React, { useContext } from "react"
// import { useHistory, useParams } from "react-router"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Hidden,
  IconButton,
  Input,
  Paper,
  Tooltip,
  makeStyles,
  Typography,
} from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import SearchIcon from "@material-ui/icons/Search"
import { SettingsInputAntennaOutlined } from "@material-ui/icons"
import MessagesContext from "../context/MessagesContext"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    height: 68,
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
}))

function Toolbar({ className, setPanel, ...rest }) {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)
  // const history = useHistory()
  // const { systemLabel, customLabel } = useParams()

  const handleBack = () => {
    dispatch({ type: "SET_PANEL", payload: "conversationList" })
    dispatch({ type: "SET_SELECTED_CONVERSATION_ID", payload: "" })
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Tooltip title="Back">
        <IconButton onClick={handleBack}>
          <ArrowBackIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Box flexGrow={1} />
      <Typography display="inline" variant="h6" color="textPrimary">
        Start a conversation
      </Typography>
    </div>
  )
}

Toolbar.propTypes = {
  className: PropTypes.string,
}

export default Toolbar
