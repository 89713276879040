import React, { useState } from "react"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  Grid,
  MobileStepper,
  Typography,
} from "@material-ui/core"

import withStyles from "@material-ui/core/styles/withStyles"

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import blue from "@material-ui/core/colors/blue"
import grey from "@material-ui/core/colors/grey"
import StarsIcon from "@material-ui/icons/Stars"
import ImageIcon from "@material-ui/icons/Image"

const Images = ({ classes, item }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [imageDialogOpen, setImageDialogOpen] = useState(false)
  const itemImages = item.itemImage
  const currentImage = item.itemImage[activeStep]
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  return (
    <div>
      <Dialog open={imageDialogOpen} onClose={() => setImageDialogOpen(false)}>
        <img src={currentImage.imageUrl} alt="full size image" />
      </Dialog>
      <Card className={classes.roundedCard}>
        <CardHeader
          avatar={
            <Avatar
              aria-label="featured"
              style={
                currentImage.featured
                  ? { color: "white", backgroundColor: blue[500] }
                  : {
                      color: "white",
                      backgroundColor: grey[800],
                    }
              }
              className={classes.avatar}
            >
              {currentImage.featured ? <StarsIcon /> : <ImageIcon />}
            </Avatar>
          }
          title={currentImage.title ? currentImage.title : ""}
          subheader={currentImage.note ? currentImage.note : ""}
        />
        <CardActionArea onClick={() => setImageDialogOpen(true)}>
          <CardMedia
            component="img"
            alt={currentImage.image}
            height="275px"
            image={currentImage.carouselImageUrl}
            title={currentImage.title ? currentImage.title : ""}
          />
        </CardActionArea>
        {itemImages.length > 1 && (
          <MobileStepper
            variant="dots"
            steps={itemImages.length}
            position="static"
            activeStep={activeStep}
            className={classes.root}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === 5}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        )}
      </Card>
    </div>
  )
}

const styles = theme => ({
  root: {},
  roundedCard: {
    borderRadius: "15px",
  },
})
export default withStyles(styles)(Images)
