import React, { useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import OrgCreateContext from "./context/OrgCreateContext"
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

function Header({ className, ...rest }) {
  const classes = useStyles()
  const { state, dispatch } = useContext(OrgCreateContext)
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            className={classes.link}
            color="inherit"
            to="/manager/"
          >
            Dashboard
          </Link>
          <Link
            variant="body1"
            className={classes.link}
            color="inherit"
            to="/manager/orgs/"
          >
            Organizations
          </Link>

          <Typography variant="body1" color="textPrimary">
            New
          </Typography>
        </Breadcrumbs>
        <Box mt={2}>
          <Typography variant="h3" color="textPrimary">
            New Organization
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {state.org.name ? state.org.name : ""}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
