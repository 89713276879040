export default function reducer(state, action) {
  switch (action.type) {
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      }
    case "SET_SEARCH_BAR":
      return {
        ...state,
        searchBar: action.payload,
      }
    case "SET_PAGE":
      return {
        ...state,
        page: action.payload,
      }
    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageSize: action.payload,
      }
    case "SET_PAGE_INDEX":
      return {
        ...state,
        pageIndex: action.payload,
      }
    case "SET_COUNT":
      return {
        ...state,
        count: action.payload,
      }
    case "SET_SELECTED_PROPOSALS":
      return {
        ...state,
        selectedProposals: action.payload,
      }
    case "SET_PREREG_FILTER":
      return {
        ...state,
        preregFilter: action.payload,
      }
    case "SET_BUSINESS_UNIT_FILTER":
      return {
        ...state,
        businessUnitFilter: action.payload,
      }
    case "SET_LIKELIHOOD_FILTER":
      return {
        ...state,
        likelihoodFilter: action.payload,
      }
    default:
      return state
  }
}
