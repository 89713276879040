import React, { useState, useEffect } from "react"
import clsx from "clsx"
import {
  Card,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { useQuery } from "@apollo/client"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import gql from "graphql-tag"
import FileCard from "./FileCard"

const useStyles = makeStyles(() => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
}))

const noDataResponse = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <Typography variant="h3">
            This person has no files on record.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

const Files = ({ person, className, ...rest }) => {
  const classes = useStyles()
  const [myFiles, setMyFiles] = useState([])
  const uid = person.uid
  const [search, setSearch] = useState("")
  const { loading, error, data } = useQuery(IMAGE_FILES_QUERY, {
    variables: {
      uid: uid,
    },
    onCompleted: data => {
      setMyFiles(data.itemImagesByOwner)
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) {
    return { noDataResponse }
  }
  if (data == null) {
    return { noDataResponse }
  }

  if (myFiles.length === 0) {
    return (
      <div>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardHeader title="No Files on Record" />
          <Divider />
        </Card>
      </div>
    )
  }

  return (
    <>
      {/* <Typography variant="h3">Item Images</Typography> */}
      <Grid container spacing={3}>
        {myFiles.map(file => (
          <Grid item key={file.id} lg={4} md={4} sm={6} xs={12}>
            <FileCard file={file} myFiles={myFiles} setMyFiles={setMyFiles} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

const IMAGE_FILES_QUERY = gql`
  query ($uid: String) {
    itemImagesByOwner(uid: $uid) {
      id
      image
      item {
        id
        name
      }
      note
      title
      imageUrl
    }
  }
`
export default Files
