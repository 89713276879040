import React, { useState } from "react"
import { Box, Container, Divider, Tab, Tabs } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useParams } from "@reach/router"
import { useQuery } from "@apollo/client"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import gql from "graphql-tag"
import Page from "../../../Global/Page"

import Header from "./Header"
import Details from "./Details"
// import Assets from "./Assets"
// import Account from "./Account"
// import Service from "./Service"

function OrgDetailsView({ classes }) {
  const params = useParams()
  const ouid = params.ouid
  const [currentTab, setCurrentTab] = useState("details")
  const { loading, error, data } = useQuery(ORG_DETAILS_QUERY, {
    variables: { ouid: ouid },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      console.log(data)
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const org = data.org

  const tabs = [
    { value: "details", label: "Details" },
    { value: "account", label: "Account" },
    { value: "assets", label: "Assets" },
    { value: "service", label: "Service" },
  ]

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  return (
    <Page className={classes.root} title="Contact Details">
      <Container maxWidth={false}>
        <Header org={org} />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === "details" && <Details org={org} />}
          {/* {currentTab === "account" && <Account person={person} />}
          {currentTab === "assets" && <Assets person={person} />}
          {currentTab === "service" && <Service person={person} />} */}
        </Box>
      </Container>
    </Page>
  )
}

export const ORG_DETAILS_QUERY = gql`
  query ($ouid: String!) {
    org(ouid: $ouid) {
      id
      ouid
      name
      nickname
      about
      dateAdded
      dateModified
      logo
      isSupplier
      orgSocialMedia {
        id
        socialMediaType {
          id
          name
        }
        username
        url
      }

      phoneOrg {
        id
        phoneType {
          id
          phoneType
        }
        phoneNumber
      }
      emailOrg {
        id
        emailAddress
      }
      addressOrg {
        id
        name
        thirdPartyAddress
        createdAt
        modifiedAt
        address {
          uid
          region {
            id
            name
            label
            worldRegion
            globalRegion
          }
          isOrg
          category {
            id
            name
          }
          attention
          addressLine1
          addressLine2
          addressLine3
          zone
          city
          postalCode
        }
      }
      people {
        uid
        firstName
        lastName
        phonePerson {
          id
          phoneType {
            id
            phoneType
          }
          phoneNumber
        }
        emailPerson {
          id
          emailAddress
        }
      }
      orgAdmin {
        id
        person {
          uid
          firstName
          lastName
        }
      }
    }
  }
`
const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "92vh",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
})
export default withStyles(styles)(OrgDetailsView)
