import React from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import {
  PlusCircle as PlusCircleIcon,
  MinusCircle as MinusCircleIcon,
  ArrowLeftCircle as BackArrowCircleIcon,
} from "react-feather"

const useStyles = makeStyles(theme => ({
  root: {},
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}))

function Header({ className, postData, ...rest }) {
  const classes = useStyles()
  const title = postData && postData.title ? postData.title : ""
  const handleClickNewDoc = () => {
    navigate("../new")
  }
  const handleClick = () => {
    navigate("../")
  }
  return (
    // <div className={clsx(classes.root, className)} {...rest}>
    <Grid container justify="space-between" spacing={3}>
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link color="inherit" to="/manager" className={classes.link}>
            Dashboard
          </Link>
          <Link color="inherit" to="../" className={classes.link}>
            Docs
          </Link>
          <Typography color="textPrimary">{title}</Typography>
        </Breadcrumbs>
        <Box mt={2}>
          <Typography variant="h3" color="textPrimary">
            Docs
          </Typography>
        </Box>
      </Grid>

      <Grid item>
        <Button
          onClick={handleClickNewDoc}
          color="secondary"
          variant="contained"
          className={classes.action}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PlusCircleIcon />
          </SvgIcon>
          New Doc
        </Button>

        <Button
          onClick={handleClick}
          color="secondary"
          variant="contained"
          className={classes.action}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <BackArrowCircleIcon />
          </SvgIcon>
          View All
        </Button>
      </Grid>
    </Grid>
    // </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
