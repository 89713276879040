import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import gql from "graphql-tag"

import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
  TextField,
} from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}))

const PreregInvite = ({ preReg, className, ...rest }) => {
  const classes = useStyles()
  const [sendView, setSendView] = useState(false)
  const preregId = preReg.preregId
  const preregEmail = preReg.preregEmail

  const [createPrereg] = useMutation(CREATE_PREREG_EMAIL_TOKEN, {
    variables: {
      preregId: preregId,
    },
    onCompleted: (data, response, errors) => {
      console.log(data)
    },
  })
  //   const [emailInvite] = useMutation(PREREG_TOKEN_MUTATION, {
  //     variables: {
  //       preregEmail: false,
  //       personUid: 1,
  //       regionId: region,
  //     },
  //     onCompleted: (data, response, errors) => {
  //       console.log(region)
  //       console.log(data.createAddress.address.uid)
  //       setAddressUid(data.createAddress.address.uid)
  //       addPersonAddress()
  //     },
  //   })

  return (
    <>
      <form
        onSubmit={event => {
          event.preventDefault()
          createPrereg(event)
        }}
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box mt={1}>
          {sendView ? (
            <>
              <Button
                color="secondary"
                // disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Email Registration Invite *
              </Button>
            </>
          ) : (
            <>
              <Button
                color="secondary"
                // disabled={isSubmitting}
                fullWidth
                size="large"
                variant="contained"
                onClick={() => setSendView(true)}
              >
                Email Registration Invite *
              </Button>
              <Typography variant="caption">
                * re-send active link or create and email a fresh link if
                expired
              </Typography>
            </>
          )}
        </Box>
      </form>
    </>
  )
}

const CREATE_PREREG_EMAIL_TOKEN = gql`
  mutation ($preregId: String!) {
    createPrc(preregId: $preregId) {
      prcToken {
        token
        preregUser {
          preregId
          person {
            uid
            firstName
            lastName
          }
        }
      }
    }
  }
`

export default PreregInvite
