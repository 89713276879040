import React, { useContext } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import { MY_UPLOADED_IMAGES_QUERY } from "./UploadList"
import IconButton from "@material-ui/core/IconButton"
import TrashIcon from "@material-ui/icons/DeleteForeverOutlined"
import UserContext from "../../context/UserContext"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  button: {
    display: "none",
  },
  button: {
    margin: theme.spacing(1),
  },
  icon: {
    marginLeft: theme.spacing.unit,
  },
}))

const DeleteImage = ({ image }) => {
  const classes = useStyles()

  const { state, dispatch } = useContext(UserContext)
  const currentUser = state.currentUser
  const isCurrentUser = currentUser.id === image.user.id
  const id = image.id

  // const handleUpdateCache = (cache, { data: { deleteImage } }) => {
  //   const data = cache.readQuery({ query: MY_UPLOADED_IMAGES_QUERY })
  //   const index = data.me.image.findIndex(
  //     image => Number(image.id) === deleteImage.imageId
  //   )
  //   // data.tracks.splice (index, 1);
  //   const images = [
  //     ...data.me.image.slice(0, index),
  //     ...data.me.image.slice(index + 1),
  //   ]
  //   cache.writeQuery({ query: MY_UPLOADED_IMAGES_QUERY, data: { images } })
  // }

  const [deleteImage, { loading, error }] = useMutation(DELETE_IMAGE_MUTATION, {
    variables: {
      imageId: image.id,
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: MY_UPLOADED_IMAGES_QUERY }],
    onCompleted: (response, errors) => {
      console.log("success")
    },
    onError: err => console.error(err),
  })

  return (
    isCurrentUser && (
      <>
        <IconButton onClick={deleteImage}>
          <TrashIcon />
        </IconButton>
      </>
    )
  )
}
const DELETE_IMAGE_MUTATION = gql`
  mutation ($imageId: Int!) {
    deleteImage(imageId: $imageId) {
      imageId
    }
  }
`
export default DeleteImage
