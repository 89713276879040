import React, { useContext, useReducer } from "react"
import ProjectReducer from "./ProjectReducer"
import ProjectContext from "./ProjectContext"

const ProjectProvider = props => {
  const initialState = useContext(ProjectContext)
  const [state, dispatch] = useReducer(ProjectReducer, initialState)

  return (
    <ProjectContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ProjectContext.Provider>
  )
}
export default ProjectProvider
