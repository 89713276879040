import React from "react"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import SkipNextIcon from "@material-ui/icons/SkipNext"
import { Link } from "gatsby"
import green from "@material-ui/core/colors/green"
import red from "@material-ui/core/colors/red"
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "spaceBetween",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },

  link: {
    textDecoration: "none",
  },
  roundedCard: {
    borderRadius: "15px",
  },
  red: {
    color: red,
  },
  green: {
    color: green,
  },
}))

const SamplesProofsListCard = ({ sp }) => {
  const classes = useStyles()
  const theme = useTheme()
  const image = sp.sampleProofUrl

  return (
    <Card className={clsx(classes.root, classes.roundedCard)}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography
            component="h5"
            variant="h5"
            // className={sp.closed ? classes.red : classes.green}
            // color={sp.closed ? red : green}
          >
            {`Version ${sp.version}`} {sp.closed ? "Closed" : "Open"}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {`Version ${sp.version}`}
          </Typography>
        </CardContent>
      </div>

      <CardMedia
        className={classes.cover}
        image={sp.sampleProofUrl && sp.sampleProofUrl}
        title={`Version ${sp.version}`}
      />
    </Card>
  )
}
export default SamplesProofsListCard
