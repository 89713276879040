import React, { useState, useContext } from "react"
import clsx from "clsx"
import moment from "moment"
import { Link } from "gatsby"
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardActions,
  Divider,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from "@material-ui/core"
import Rating from "@material-ui/lab/Rating"
import Pagination from "@material-ui/lab/Pagination"
import PerfectScrollbar from "react-perfect-scrollbar"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import SortContext from "../../../../../../context/SortContext"
const useStyles = makeStyles(() => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
}))

const ConversationList = ({
  person,
  personConversations,
  setConversationSelected,
  setSelectedConversation,
  className,
  ...rest
}) => {
  const classes = useStyles()
  //   const personConvs = personConversations && personConversations.objects
  //   const [conversations, setConversations] = useState(
  //     personConversations.objects
  //   )
  const { state, dispatch } = useContext(SortContext)
  const personUid = person.uid
  const handleSelectConversation = conv => {
    setSelectedConversation(conv)
    setConversationSelected(true)
  }
  const handleChange = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
  }
  if (!personConversations || personConversations.count === 0) {
    return (
      <div>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardHeader
            title={
              person.user && person.user.isVerified
                ? "No conversations on record"
                : "Person must have a verified user account to use this feature."
            }
          />
          {person.user && person.user.isVerified && (
            <CardActions>
              <Divider />
              <Button size="small" color="secondary" variant="contained">
                Start a new conversation
              </Button>
            </CardActions>
          )}
          <Divider />
        </Card>
      </div>
    )
  }

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          title="Conversations"
          action={
            personConversations.pages > 1 && (
              <Pagination
                count={personConversations.pages}
                page={state.page}
                onChange={handleChange}
              />
            )
          }
        />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Start Date</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Participant</TableCell>
                  <TableCell>Topic</TableCell>
                  <TableCell>Message Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {personConversations.objects.map(conv => {
                  return (
                    <TableRow key={conv.uuid}>
                      <TableCell>
                        <Button onClick={() => handleSelectConversation(conv)}>
                          {moment(conv.startDate).format("DD MMM YYYY | HH:MM")}
                        </Button>
                      </TableCell>
                      <TableCell>{conv.category.name}</TableCell>

                      <TableCell>
                        {conv.participants.some(
                          participant => participant.person.uid === personUid
                        )
                          ? "Participant"
                          : "Invited"}
                      </TableCell>
                      <TableCell>{conv.topic}</TableCell>
                      <TableCell>{conv.conversationMessage.length}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

export default ConversationList
