import React, { useState, useEffect, useContext } from "react"
import gql from "graphql-tag"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import clsx from "clsx"
import PropTypes from "prop-types"
import SpecContext from "../ItemSpecs/context/SpecContext"
import {
  Avatar,
  Box,
  Button,
  Grid,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"

import Error from "../../../../../Utils/Error"
import Loading from "../../../../../Utils/Loading"
import { navigate } from "gatsby"
import { ChangeHistorySharp } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

// const snakeToCamel = str => {
//   if (str.includes("_")) {
//     str.replace(/([-_][a-z])/g, group =>
//       group.toUpperCase().replace("-", "").replace("_", "")
//     )
//   }
// }

// Important Note - this form is the only form used to add and update specs for this item on the app. It shows up in several spots. Be careful when making changes and useContext whenever you can.
const EmbroideredPatchesSpecs = ({
  className,
  setActiveStep,
  // item,
  // specToEdit,
  // spec,
  // inquirySpecs,
  // editing,
  // setEditing,
  // updateItem,
  // setUpdateItem,
  ...rest
}) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(SpecContext)
  const [message, setMessage] = useState("")
  const [width, setWidth] = useState("")
  const [height, setHeight] = useState("")
  const [background, setBackground] = useState("")
  const [border, setBorder] = useState("")
  const [backing, setBacking] = useState("")
  const [threadColors, setThreadColors] = useState("")
  const [backgroundColor, setBackgroundColor] = useState("")
  const [borderColor, setBorderColor] = useState("")
  const [note, setNote] = useState("")
  const summary = state.specSummary
  const editFields = JSON.parse(summary)
  const editing = state.editing
  const specId = state.specId
  const existingSpecs = state.inquirySpecs
    ? state.inquirySpecs
    : editFields
    ? editFields
    : ""
  // const existingSpecs = snakeToCamel(exSpecs)
  // var camelCased = myString.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase(); });
  useEffect(() => {
    if (existingSpecs) {
      let threadColors = existingSpecs.threadColors
        ? existingSpecs.threadColors
        : existingSpecs.thread_colors
        ? existingSpecs.thread_colors
        : ""
      let backgroundColor = existingSpecs.backgroundColor
        ? existingSpecs.backgroundColor
        : existingSpecs.background_color
        ? existingSpecs.background_color
        : ""
      let borderColor = existingSpecs.borderColor
        ? existingSpecs.borderColor
        : existingSpecs.border_color
        ? existingSpecs.border_color
        : ""

      {
        existingSpecs.width && setWidth(existingSpecs.width)
      }
      {
        existingSpecs.height && setHeight(existingSpecs.height)
      }
      {
        existingSpecs.background && setBackground(existingSpecs.background)
      }
      {
        existingSpecs.border && setBorder(existingSpecs.border)
      }
      {
        existingSpecs.backing && setBacking(existingSpecs.backing)
      }
      {
        threadColors && setThreadColors(threadColors)
      }
      {
        backgroundColor && setBackgroundColor(backgroundColor)
      }
      {
        borderColor && setBorderColor(borderColor)
      }
      {
        existingSpecs.note && setNote(existingSpecs.note)
      }
    }
    // else findPatchSpecs()
  }, [])
  // const [findPatchSpecs, { loading, data, error }] = useLazyQuery(
  //   PATCH_SPEC_QUERY,
  //   {
  //     variables: {
  //       specId: specId,
  //     },
  //     onError: error => {
  //       dispatch({ type: "SET_EDITING", payload: false })
  //       setMessage("No existing embroidered patch spec found. Create one now.")
  //     },
  //     onCompleted: data => {
  //       let editData = data.embroideredPatch
  //       if (!state.inquirySpecs) {
  //         dispatch({ type: "SET_EDITING", payload: true })
  //         setWidth(editData.width)
  //         setHeight(editData.height)
  //         setBackground(editData.background)
  //         setBorder(editData.border)
  //         setBacking(editData.backing)
  //         setThreadColors(editData.threadColors)
  //         setBackgroundColor(editData.backgroundColor)
  //         setBorderColor(editData.borderColor)
  //         setNote(editData.note)
  //       }
  //     },
  //   }
  // )
  const [handleInitialSubmit, { loading }] = useMutation(
    CREATE_EMBROIDERED_PATCH_SPEC_MUTATION,
    {
      variables: {
        specId: specId,
        width: width,
        height: height,
        background: background,
        border: border,
        backing: backing,
        threadColors: threadColors,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        note: note,
      },
      onCompleted: (data, response, errors) => {
        nextStep()
      },
    }
  )
  const [handleUpdate] = useMutation(UPDATE_EMBROIDERED_PATCH_SPEC_MUTATION, {
    variables: {
      specId: specId,
      width: width,
      height: height,
      background: background,
      border: border,
      backing: backing,
      threadColors: threadColors,
      backgroundColor: backgroundColor,
      borderColor: borderColor,
      note: note,
    },
    onError: () => setMessage("something didn't work"),
    onCompleted: (data, response, errors) => {
      setMessage("Spec update successful")
    },
  })

  // const handleMissingPatchSpec = () => {
  //   setEditing(false)
  //   setMessage("No existing embroidered patch spec found. Create one now.")
  // }

  const handleSubmit = event => {
    {
      editing ? handleUpdate() : handleInitialSubmit()
    }
  }
  const nextStep = event => {
    setActiveStep(4)
  }
  const backgrounds = [
    {
      id: 1,
      name: "Twill",
      value: "twill",
    },
    {
      id: 2,
      name: "100% Embroidery",
      value: "100percent",
    },
    {
      id: 3,
      name: "Felt",
      value: "felt",
    },
    {
      id: 4,
      name: "Vinyl",
      value: "vinyl",
    },
  ]

  const borders = [
    {
      id: 1,
      name: "Merrowed Edge",
      value: "merrowed",
    },
    {
      id: 2,
      name: "Laser Cut",
      value: "handcut",
    },
    {
      id: 3,
      name: "Hand Cut",
      value: "handcut",
    },
  ]

  const backings = [
    {
      id: 1,
      name: "Heat Seal Glue",
      value: "heatseal",
    },
    {
      id: 2,
      name: "Plastic",
      value: "plastic",
    },
    {
      id: 3,
      name: "Adhesive (sticker)",
      value: "adhesive",
    },
    {
      id: 4,
      name: "Hook and Loop (1 side only)",
      value: "hookloop1",
    },
    {
      id: 5,
      name: "Hook and Loop (2 sides)",
      value: "hookloop2",
    },
    {
      id: 6,
      name: "Magnet",
      value: "magnet",
    },
    {
      id: 7,
      name: "Pellon",
      value: "pellon",
    },
  ]
  if (loading) return <Loading />

  return (
    <>
      {editing && (
        <Typography variant="h5">Update this Embroidered Patch Spec</Typography>
      )}

      <FormControl>
        <form
          onSubmit={event => {
            event.preventDefault()
            handleSubmit(event)
          }}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <TextField
            fullWidth
            label="Width (inches)"
            margin="dense"
            size="small"
            name="width"
            onChange={event => setWidth(event.target.value)}
            type="number"
            value={width}
            variant="outlined"
          />

          <TextField
            fullWidth
            label="Height (inches)"
            margin="dense"
            size="small"
            name="height"
            onChange={event => setHeight(event.target.value)}
            type="number"
            value={height}
            variant="outlined"
          />

          <TextField
            fullWidth
            select
            SelectProps={{ native: true }}
            label="Background Style"
            margin="dense"
            size="small"
            name="background"
            onChange={event => setBackground(event.target.value)}
            type="text"
            value={background}
            variant="outlined"
          >
            <option></option>
            {backgrounds.map(bg => (
              <option key={bg.id} value={bg.value}>
                {bg.name}
              </option>
            ))}
          </TextField>

          <TextField
            fullWidth
            select
            SelectProps={{ native: true }}
            label="Border Style"
            margin="dense"
            size="small"
            name="border"
            onChange={event => setBorder(event.target.value)}
            type="text"
            value={border}
            variant="outlined"
          >
            <option></option>
            {borders.map(b => (
              <option key={b.id} value={b.value}>
                {b.name}
              </option>
            ))}
          </TextField>

          <TextField
            fullWidth
            select
            SelectProps={{ native: true }}
            label="Backing"
            margin="dense"
            size="small"
            name="backing"
            onChange={event => setBacking(event.target.value)}
            type="text"
            value={backing}
            variant="outlined"
          >
            <option></option>
            {backings.map(bk => (
              <option key={bk.id} value={bk.value}>
                {bk.name}
              </option>
            ))}
          </TextField>

          <TextField
            fullWidth
            label="Thread Colors"
            margin="dense"
            size="small"
            name="threadColors"
            onChange={event => setThreadColors(event.target.value)}
            type="text"
            value={threadColors}
            variant="outlined"
          />

          <TextField
            fullWidth
            label="Background Color"
            margin="dense"
            size="small"
            name="backgroundColor"
            onChange={event => setBackgroundColor(event.target.value)}
            type="text"
            value={backgroundColor}
            variant="outlined"
          />

          <TextField
            fullWidth
            label="Border Color"
            margin="dense"
            size="small"
            name="borderColor"
            onChange={event => setBorderColor(event.target.value)}
            type="text"
            value={borderColor}
            variant="outlined"
          />

          <TextField
            fullWidth
            label="Note"
            margin="dense"
            size="small"
            name="note"
            onChange={event => setNote(event.target.value)}
            type="text"
            value={note}
            variant="outlined"
          />
          <Box mt={2}>
            {editing ? (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                Update Embroidered Patches Spec
              </Button>
            ) : (
              <Button fullWidth type="text" variant="contained" color="primary">
                Add Embroidered Patches Spec
              </Button>
            )}

            {message && (
              <Alert
                onClose={() => {
                  setMessage("")
                }}
              >
                {message}
              </Alert>
            )}
          </Box>
        </form>
      </FormControl>
    </>
  )
}

const CREATE_EMBROIDERED_PATCH_SPEC_MUTATION = gql`
  mutation (
    $specId: String!
    $width: Decimal
    $height: Decimal
    $background: String
    $border: String
    $backing: String
    $threadColors: String
    $backgroundColor: String
    $borderColor: String
    $note: String
  ) {
    createEmbroideredPatches(
      specId: $specId
      width: $width
      height: $height
      background: $background
      border: $border
      backing: $backing
      threadColors: $threadColors
      backgroundColor: $backgroundColor
      borderColor: $borderColor
      note: $note
    ) {
      embroideredPatches {
        spec {
          specId
        }
        width
        height
        background
        border
        backing
        threadColors
        backgroundColor
        borderColor
        note
      }
    }
  }
`

const PATCH_SPEC_QUERY = gql`
  query ($specId: String!) {
    embroideredPatch(specId: $specId) {
      spec {
        specId
      }
      width
      height
      background
      border
      backing
      threadColors
      backgroundColor
      borderColor
      note
      size
    }
  }
`
const UPDATE_EMBROIDERED_PATCH_SPEC_MUTATION = gql`
  mutation (
    $specId: String!
    $width: Decimal
    $height: Decimal
    $background: String
    $border: String
    $backing: String
    $threadColors: String
    $backgroundColor: String
    $borderColor: String
    $note: String
  ) {
    updateEmbroideredPatches(
      specId: $specId
      width: $width
      height: $height
      background: $background
      border: $border
      backing: $backing
      threadColors: $threadColors
      backgroundColor: $backgroundColor
      borderColor: $borderColor
      note: $note
    ) {
      embroideredPatches {
        spec {
          specId
          summary
        }
      }
    }
  }
`
EmbroideredPatchesSpecs.propTypes = {
  className: PropTypes.string,
  // onSubmitSuccess: PropTypes.func,
}

// EmbroideredPatchesSpecs.default = {
//   onSubmitSuccess: () => {},
// }
export default EmbroideredPatchesSpecs
