import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Grid, makeStyles } from "@material-ui/core"
import SupplierInfo from "./SupplierInfo"

const useStyles = makeStyles(() => ({
  root: {},
}))

const Details = ({ supplier, className, ...rest }) => {
  const classes = useStyles()

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
      {...rest}
    >
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <SupplierInfo supplier={supplier} />
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        something here
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        here
      </Grid>
    </Grid>
  )
}

Details.propTypes = {
  className: PropTypes.string,
  person: PropTypes.object.isRequired,
}

export default Details
