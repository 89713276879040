import React, { useState, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import useDidMountEffect from "../../../../Global/hooks/useDidMountEffect"
import clsx from "clsx"
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { SentimentSatisfiedAltSharp } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0, 1),
  },
}))

const NewPreregUser = ({
  person,
  setOwnerIsPrereg,
  setPreRegUser,
  className,
}) => {
  const classes = useStyles()
  const [emails, setEmails] = useState(person.emailPerson)
  // const emails = person.emailPerson
  const personUid = person.uid
  const [preregEmail, setPreregEmail] = useState("")
  const [showSelect, setShowSelect] = useState(false)

  // useDidMountEffect(()=>{},[])

  useEffect(() => {
    emails && emails.length > 0 && setShowSelect(true)
  }, [])

  const { data, loading, error } = useQuery(GET_PERSON_EMAILS, {
    variables: {
      uid: personUid,
    },
    onCompleted: data => {
      setEmails(data.person.emailPerson)
    },
  })
  const [createPreRegUser] = useMutation(CREATE_PREREG_USER_MUTATION, {
    variables: {
      personUid: personUid,
      preregEmail: preregEmail,
    },

    onCompleted: data => {
      console.log("SUCCESS")
      setPreRegUser(data.createPrereg.prereg)
      setOwnerIsPrereg(true)
    },
  })
  const handleSubmit = (event, createPreRegUser) => {
    event.preventDefault()
    createPreRegUser()
  }
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <Box className={classes.root}>
      <Typography variant="h4">New Pre-registration Account</Typography>
      <Typography variant="body1">
        Select an email address to associate with the new pre-registration
        account.
      </Typography>

      <Box p={2}>
        <form
          onSubmit={event => handleSubmit(event, createPreRegUser)}
          className={clsx(classes.root, className)}
        >
          {emails ? (
            <TextField
              label="Select and confirm email address"
              name="category"
              onChange={event => setPreregEmail(event.target.value)}
              select
              margin="dense"
              size="small"
              fullWidth
              variant="outlined"
              SelectProps={{ native: true }}
              value={preregEmail}
            >
              <option></option>
              {emails.map(e => (
                <option key={e.id} value={e.emailAddress}>
                  {e.emailAddress}
                </option>
              ))}
            </TextField>
          ) : (
            <TextField
              fullWidth
              label="Enter an email address (required)"
              margin="dense"
              size="small"
              name="preregEmail"
              onChange={event => setPreregEmail(event.target.value)}
              type="preregEmail"
              value={preregEmail}
              variant="outlined"
            />
          )}
          <Box mt={2}>
            <Button
              color="secondary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Create Prereg User Account
            </Button>
          </Box>
          {showSelect && (
            <Box mt={2}>
              <Button
                color="secondary"
                fullWidth
                size="large"
                onClick={() => setShowSelect(false)}
                variant="outlined"
              >
                Use a different email address
              </Button>
            </Box>
          )}
        </form>
      </Box>
    </Box>
  )
}

const GET_PERSON_EMAILS = gql`
  query ($uid: String!) {
    person(uid: $uid) {
      uid
      emailPerson {
        id
        emailAddress
        isVerified
      }
    }
  }
`
const CREATE_PREREG_USER_MUTATION = gql`
  mutation ($personUid: String!, $preregEmail: String!) {
    createPrereg(personUid: $personUid, preregEmail: $preregEmail) {
      prereg {
        preregId
        preregEmail
      }
    }
  }
`
export default NewPreregUser
