import React, { useState } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import ReactMarkdown from "react-markdown"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import { useParams } from "@reach/router"
import Header from "./Header"
import EditMode from "./EditMode"
import Page from "../../../Global/Page"
import moment from "moment"

const DocsDetailsView = ({ classes }) => {
  const params = useParams()
  const [editMode, setEditMode] = useState(false)
  const [title, setTitle] = useState("")
  const [body, setBody] = useState("")
  const [category, setCategory] = useState("")
  const [tags, setTags] = useState([])

  const postId = params && params.id ? parseInt(params.id) : null
  const { data, loading, error } = useQuery(DOC_POST_QUERY, {
    variables: {
      id: postId,
    },
    onCompleted: data => {
      setTitle(data.docsPost.title)
      setBody(data.docsPost.body)
      setCategory(data.docsPost.category)
      setTags(data.docsPost.tags)
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />
  const postData = data.docsPost
  return (
    <Page
      className={classes.root}
      title={postData && postData.title ? postData.title : "Docs"}
    >
      <Container maxWidth={false}>
        <Header postData={postData} />
        {editMode ? (
          <EditMode
            postData={postData}
            setEditMode={setEditMode}
            title={title}
            setTitle={setTitle}
            body={body}
            setBody={setBody}
            category={category}
            setCategory={setCategory}
            tags={tags}
            setTags={setTags}
          />
        ) : (
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12} sm={11}>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.category}>
                  Category: {category && category.name ? category.name : ""}
                </Typography>
                <Paper
                  elevation={0}
                  component="ul"
                  className={classes.chipList}
                >
                  <Typography className={classes.tags}>Tags:</Typography>
                  {tags &&
                    tags.map(tag => (
                      <li key={tag.id}>
                        <Chip
                          className={classes.tagChip}
                          size="small"
                          // avatar={<Avatar>M</Avatar>}
                          label={tag.name}
                          clickable
                          color="primary"
                          variant="outlined"
                          // onDelete={handleDelete}
                          // deleteIcon={<DoneIcon />}
                        />
                      </li>
                    ))}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Button
                  onClick={() => setEditMode(true)}
                  color="primary"
                  variant="outlined"
                  fullWidth
                >
                  Edit
                </Button>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />
            <ReactMarkdown className={classes.body}>{body}</ReactMarkdown>
          </Paper>
        )}
        <Card className={classes.infoCard}>
          <CardContent>
            <Typography>
              Posted: {moment(postData.dateAdded).format("DD MMM YYYY | HH:MM")}{" "}
              by {postData.author.person.firstName}{" "}
              {postData.author.person.lastName}
            </Typography>
            <Typography>
              Latest Update:{" "}
              {moment(postData.dateModified).format("DD MMM YYYY | HH:MM")}
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
    minHeight: "90vh",
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  title: {
    fontSize: "26px",
    fontWeight: "bold",
  },
  category: {
    fontSize: "19px",
  },
  tags: {
    fontSize: "19px",
    display: "inline",
  },
  chipList: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  tagChip: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    "& h1": {
      marginBottom: theme.spacing(3),
    },
    "& h2": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& p": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& li": {
      marginLeft: theme.spacing(4),
    },
  },
  infoCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})

const DOC_POST_QUERY = gql`
  query ($id: Int!) {
    docsPost(id: $id) {
      id
      title
      body
      category {
        id
        name
      }
      author {
        id
        person {
          uid
          firstName
          lastName
        }
      }
      tags {
        id
        name
      }
      dateAdded
      dateModified
    }
  }
`
export default withStyles(styles)(DocsDetailsView)
