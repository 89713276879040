import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"

import {
  Button,
  Divider,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
  CardActionArea,
} from "@material-ui/core"

import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

const worldRegions = [
  "North America",
  "Africa",
  "Antartica",
  "Arab states",
  "Asia & Pacific",
  "Europe",
  "Middle east",
  "South/Latin America",
]
const usaStates = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
]

const canadaProvinces = [
  {
    name: "Alberta",
    abbreviation: "AB",
  },
  {
    name: "British Columbia",
    abbreviation: "BC",
  },
  {
    name: "Manitoba",
    abbreviation: "MB",
  },
  {
    name: "New Brunswick",
    abbreviation: "BN",
  },
  {
    name: "Newfoundland and Labrador",
    abbreviation: "NL",
  },
  {
    name: "Nova Scotia",
    abbreviation: "NS",
  },
  {
    name: "Ontario",
    abbreviation: "ON",
  },
  {
    name: "Prince Edward Island",
    abbreviation: "PE",
  },
  {
    name: "Quebec",
    abbreviation: "QC",
  },
  {
    name: "Saskatchewan",
    abbreviation: "SK",
  },
  {
    name: "Northwest Territories",
    abbreviation: "NT",
  },
  {
    name: "Nunavut",
    abbreviation: "NU",
  },
  {
    name: "Yukon",
    abbreviation: "YT",
  },
]

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formSection: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  infoSection: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  // submit: {
  //   backgroundColor: primary,
  // },
  title: {},
}))

const TestForm = () => {
  const classes = useStyles()
  //   const { state } = useContext(InquiryContext)
  const [submitting, setSubmitting] = useState(false)
  const [region, setRegion] = useState("")
  const [regionId, setRegionId] = useState("")
  const [worldRegion, setWorldRegion] = useState("North America")

  const [attention, setAttention] = useState("")
  const [addressLine1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")
  const [addressLine3, setAddressLine3] = useState("")
  const [city, setCity] = useState("")
  const [zone, setZone] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [inquiryAddressUid, setInquiryAddressUid] = useState("")
  const [collapse, setCollapse] = useState(false)
  const [thankYou, setThankYou] = useState(false)
  const inquiryUuid = " inquiryUuid"

  const { data, loading, error } = useQuery(REGIONS_QUERY)

  //   const [handleSubmit] = useMutation(NEW_INQUIRY_ADDRESS_MUTATION, {
  //     variables: {
  //       inquiryRegionId: region.id,
  //       inquiryUuid: inquiryUuid,
  //       samplesRequested: true,
  //       attention: attention,
  //       addressLine1: addressLine1,
  //       addressLine2: addressLine2,
  //       addressLine3: addressLine3,
  //       city: city,
  //       zone: zone,
  //       postalCode: postalCode,
  //     },
  //     onCompleted: (data, response, errors) => {
  //       console.log(data)

  //       setInquiryAddressUid(data.createInquiryAddress.inquiryAddress.uid)
  //     },
  //     onError: err => console.error(err),
  //   })

  const nextStep = () => {
    setThankYou(true)
  }

  if (loading) return <Loading />
  if (error) return <Error />
  //   if (thankYou === true) return <ThankYou />
  const regions = data.regions

  return (
    <div className={classes.infoSection}>
      <Paper elevation={0} square>
        <form>
          {/*   onSubmit={event => {
            event.preventDefault()
            setSubmitting(true)
            handleSubmit(event)
          }} */}

          <Grid container spacing={3}>
            <Typography>Where do you live?</Typography>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                // label="Where do you live?"
                id="worldRegion"
                type="text"
                helperText="(required)"
                value={worldRegion}
                onChange={event => setWorldRegion(event.target.value)}
                SelectProps={{ native: true }}
              >
                <option>hmmm</option>
                {/* {worldRegions.map((wr, index) => (
                    <option key={wr.index} value={wr}>
                      {wr}
                    </option>
                  ))} */}
              </TextField>
            </Grid>

            {/* <Grid item sm={6}>
                <TextField
                  fullWidth
                  select
                  // label="Select a country or region"
                  id="region"
                  type="text"
                  helperText="(required)"
                  value={region}
                  onChange={event => setRegion(event.target.value)}
                  SelectProps={{ native: true }}
                >
                  {regions
                    .filter(function (region) {
                      return region.worldRegion === worldRegion
                    })
                    .map(region => (
                      <option key={region.id} value={region}>
                        {region.name}
                      </option>
                    ))}
                </TextField>
              </Grid> */}
            <>
              <Grid item xs={12} sm={6}>
                <FormControl required fullWidth>
                  <TextField
                    label="Address"
                    id="addressLine1"
                    helperText="(required)"
                    value={addressLine1}
                    onChange={event => setAddressLine1(event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl required fullWidth>
                  <TextField
                    label={
                      region.id === 1 || region.id === 2
                        ? "(Apt, Unit, Box...)"
                        : "Address Line 2"
                    }
                    id="addressLine2"
                    value={addressLine2}
                    onChange={event => setAddressLine2(event.target.value)}
                  />
                </FormControl>
              </Grid>
              {/* {region.id !== 1 && region.id !== 2 && (
                  <Grid item xs={12} sm={3}>
                    <FormControl required fullWidth>
                      <TextField
                        label="Address Line 3"
                        id="addressLine3"
                        value={addressLine3}
                        onChange={event => setAddressLine3(event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                )} */}

              <Grid item xs={12} sm={5}>
                <TextField
                  label="City"
                  id="city"
                  value={city}
                  fullWidth
                  onChange={event => setCity(event.target.value)}
                />
              </Grid>
              <Divider />

              {/* <Grid item xs={12} sm={3}>
                  <FormControl fullWidth required>
                    {region.id === 1 || region.id === 2 ? (
                      <TextField
                        select
                        label={
                          region.id === 1
                            ? "State"
                            : region.id === 2
                            ? "Province"
                            : "State, Province or Zone"
                        }
                        id="zone"
                        type="text"
                        helperText="(required)"
                        value={zone}
                        onChange={event => setZone(event.target.value)}
                        SelectProps={{ native: true }}
                      >
                        <option></option>
                        {region.id === 1
                          ? usaStates.map((us, index) => (
                              <option key={index} value={us.abbreviation}>
                                {us.name}
                              </option>
                            ))
                          : region.id === 2 &&
                            canadaProvinces.map((ca, index) => (
                              <option key={index} value={ca.abbreviation}>
                                {ca.name}
                              </option>
                            ))}
                      </TextField>
                    ) : (
                      <TextField
                        label="State, Province or Zone"
                        id="zone"
                        type="text"
                        helperText="(required)"
                        value={zone}
                        onChange={event => setZone(event.target.value)}
                        SelectProps={{ native: true }}
                      />
                    )}
                  </FormControl>
                </Grid> */}

              <Grid item xs={12} sm={2}>
                <FormControl>
                  <TextField
                    label="Postal Code"
                    id="postalCode"
                    type="text"
                    helperText="(required)"
                    value={postalCode}
                    onChange={event => setPostalCode(event.target.value)}
                  />
                </FormControl>
              </Grid>
            </>

            <Divider />

            <Grid item xs={12} sm={8}>
              ICON Emblem respects your privacy. We will not share or sell this
              information. Please see our privacy policy.
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting || !addressLine1.trim()}
                >
                  {loading ? "Sending..." : "Complete Your Inquiry"}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  )
}

const REGIONS_QUERY = gql`
  query {
    regions {
      id
      name
      label
      worldRegion
    }
  }
`
const NEW_INQUIRY_ADDRESS_MUTATION = gql`
  mutation (
    $inquiryUuid: String
    $regionId: Int
    $samplesRequested: Boolean
    $attention: String
    $addressLine1: String
    $addressLine2: String
    $addressLine3: String
    $zone: String
    $city: String
    $postalCode: String
  ) {
    createInquiryAddress(
      inquiryUuid: $inquiryUuid
      regionId: $regionId
      samplesRequested: $samplesRequested
      attention: $attention
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressLine3: $addressLine3
      city: $city
      zone: $zone
      postalCode: $postalCode
    ) {
      inquiryAddress {
        uid
        inquiry {
          id
          submitted
        }
        region {
          id
          name
          label
          worldRegion
          globalRegion
        }
        attention
        addressLine1
        addressLine2
        addressLine3
        zone
        city
        postalCode
      }
    }
  }
`

export default TestForm
