import React, { useState, useEffect } from "react"
import clsx from "clsx"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import {
  Box,
  Button,
  Chip,
  Checkbox,
  Input,
  FormControl,
  FormHelperText,
  List,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core"
import { PermDeviceInformationRounded } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    margin: theme.spacing(2, 0, 1),
  },
  button: {
    margin: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {},

  image: {
    width: 130,
  },
  formButton: {
    margin: theme.spacing(2, 0, 1),
  },
}))
const ItemPricing = ({ className, item, specs, setActiveStep, ...rest }) => {
  const classes = useStyles()
  const itemId = item.id
  const [price, setPrice] = useState("")
  const [minQuantity, setMinQuantity] = useState(null)
  const [maxQuantity, setMaxQuantity] = useState(null)
  const [inquiryQty, setInquiryQty] = useState("")
  const [deleteItemPriceId, setDeleteItemPriceId] = useState("")
  const [isActive, setIsActive] = useState(true)
  const [priceName, setPriceName] = useState("")
  const [prices, setPrices] = useState([])
  const [newArray, setNewArray] = useState([])
  const [success, setSuccess] = useState(false)

  const pricingModelId = item.pricingModel.id ? item.pricingModel.id : undefined

  useEffect(() => {
    if (specs) {
      {
        specs.qty && setInquiryQty(`Inquiry quantity: ${specs.qty}`)
      }
    }
  }, [])

  const [handleSubmit, { loading, error }] = useMutation(
    CREATE_PRICE_MUTATION,
    {
      variables: {
        itemId: itemId,
        price: price,
        minQuantity: minQuantity,
        maxQuantity: maxQuantity,
        name: priceName,
        isActive: isActive,
      },
      onCompleted: (data, response, errors) => {
        let priceData = data.createItemPrice.itemPrice
        console.log(priceData)
        const newPrice = { ...priceData }
        setPrices(prices => [...prices, newPrice])
        setPrice(0)
        setMinQuantity(0)
        setMaxQuantity(0)
        setPriceName("")
        setSuccess(true)
      },
    }
  )

  const handleDelete = (event, priceId) => {
    setDeleteItemPriceId(priceId)
    const filteredArray = prices.filter(p => p.id !== priceId)
    setNewArray(filteredArray)
    deletePrice()
  }
  const [deletePrice] = useMutation(DELETE_PRICE_MUTATION, {
    variables: {
      itemPriceId: deleteItemPriceId,
    },
    onCompleted: (data, errors) => {
      // const index = prices.findIndex(
      //   itemPrice => itemPrice.id === deleteItemPriceId
      // )
      // const newList = [...prices.slice(0, index), ...prices.slice(index + 1)]
      setPrices(newArray)
      // setPrices(newList)
    },
  })

  const handleNextStep = event => {
    setActiveStep(6)
  }
  const handleCheckActive = event => {
    setIsActive(event.target.checked)
  }
  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  return (
    <>
      <div>
        {success && (
          <>
            {prices.map(pricing => {
              const priceId = pricing.id
              const getChipLabel = pricingModelId => {
                switch (pricingModelId) {
                  case "1":
                    return `${pricing.minQuantity && pricing.minQuantity} - ${
                      pricing.maxQuantity && pricing.maxQuantity
                    } for $${financial(pricing.price && pricing.price)} each`
                  case "2":
                    return `$${pricing.price && pricing.price} each`
                  case "3":
                    return `${pricing.name && pricing.name}: $${
                      pricing.price && pricing.price
                    }`
                  default:
                    return "undefined pricing model"
                }
              }
              return (
                <List key={pricing.id}>
                  <Chip
                    label={getChipLabel(pricingModelId)}
                    // label={`${pricing.minQuantity} - ${
                    //   pricing.maxQuantity
                    // } for $${financial(pricing.price)} each`}
                    onDelete={() => handleDelete(priceId)}
                    color="primary"
                  />
                </List>
              )
            })}
          </>
        )}

        <Typography className={classes.title} variant="h3">
          Item Pricing: {item.pricingModel.name}
        </Typography>
        {item.pricingModel.id == 1 ? (
          <Typography variant="caption">
            Quantity-based pricing: Define a quantity range and its
            corresponding price.
          </Typography>
        ) : item.pricingModel.id == 2 ? (
          <Typography variant="caption">Standard Pricing Model</Typography>
        ) : item.pricingModel.id == 3 ? (
          <Typography variant="caption">
            Option pricing. Give each price a short name that corresponds to an
            option or attribute.
          </Typography>
        ) : undefined}
        <Typography variant="body1">{inquiryQty}</Typography>
      </div>
      <div>
        <form
          onSubmit={event => {
            event.preventDefault()
            handleSubmit(event)
          }}
          className={clsx(classes.root, className)}
          {...rest}
        >
          {pricingModelId == 1 && (
            <>
              <TextField
                fullWidth
                label="Minimum (low) Quantity"
                margin="dense"
                size="small"
                name="minQty"
                // onBlur={handleBlur}
                onChange={event => setMinQuantity(event.target.value)}
                helperText="For example, 50 (min/low qty) - 99 (max/high qty) for 1.50
            (price) each."
                type="minQty"
                value={minQuantity}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Maximum (high) Quantity"
                margin="dense"
                size="small"
                name="maxQty"
                // onBlur={handleBlur}
                onChange={event => setMaxQuantity(event.target.value)}
                type="maxQty"
                helperText="Please take care not to overlap/intersect qty
            ranges."
                value={maxQuantity}
                variant="outlined"
              />
            </>
          )}
          {pricingModelId == 3 && (
            <TextField
              fullWidth
              label="Option Name"
              margin="dense"
              helperText="Short name"
              size="small"
              name="optionName"
              // onBlur={handleBlur}
              onChange={event => setPriceName(event.target.value)}
              type="text"
              value={priceName}
              variant="outlined"
            />
          )}
          <TextField
            fullWidth
            label="Price"
            margin="dense"
            helperText="No dollar sign (value only)"
            size="small"
            name="price"
            // onBlur={handleBlur}
            onChange={event => setPrice(event.target.value)}
            type="price"
            value={price}
            variant="outlined"
          />
          <Box alignItems="center" display="flex" mt={2} ml={-1}>
            <Checkbox
              checked={isActive}
              // name="supplier"
              onChange={handleCheckActive}
            />

            <Typography variant="body2" color="textSecondary">
              This price is active.
            </Typography>
          </Box>

          <Button
            className={classes.formButton}
            fullWidth
            color="primary"
            variant="contained"
            type="submit"
          >
            Add Price
          </Button>

          <Button
            className={classes.formButton}
            fullWidth
            color="primary"
            variant="outlined"
            onClick={handleNextStep}
          >
            Next: Choose the next step
          </Button>
        </form>
      </div>
    </>
  )
}

const CREATE_PRICE_MUTATION = gql`
  mutation (
    $itemId: Int
    $price: Money!
    $minQuantity: Int
    $maxQuantity: Int
    $name: String
    $isActive: Boolean
  ) {
    createItemPrice(
      itemId: $itemId
      price: $price
      minQuantity: $minQuantity
      maxQuantity: $maxQuantity
      name: $name
      isActive: $isActive
    ) {
      itemPrice {
        item {
          id
          name
        }
        id
        minQuantity
        maxQuantity
        price
        name
        isActive
      }
    }
  }
`

const DELETE_PRICE_MUTATION = gql`
  mutation ($itemPriceId: Int!) {
    deleteItemPrice(itemPriceId: $itemPriceId) {
      itemPriceId
    }
  }
`
export default ItemPricing
