import React, { useState } from "react"
import { Box, Card, Grid, List, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import SampleProofCommentCard from "./SampleProofCommentCard"
const SampleProofComments = ({ classes, id }) => {
  const { data, loading, error } = useQuery(COMMENT_IMAGES_QUERY, {
    variables: {
      sampleProofId: id,
    },
    fetchPolicy: "network-only",
  })
  if (loading) return <Loading />
  if (error) return <Error />
  const commentImages = data.sampleProofCommentImages
  return (
    <div>
      {commentImages.map(comment => (
        <List key={comment.id}>
          <SampleProofCommentCard comment={comment} />
        </List>
      ))}
    </div>
  )
}

const styles = theme => ({
  root: {},
})

const COMMENT_IMAGES_QUERY = gql`
  query ($sampleProofId: Int!) {
    sampleProofCommentImages(sampleProofId: $sampleProofId) {
      id

      sampleProof {
        id
      }
      uploadedBy {
        uid
        firstName
        lastName
      }
      commentImage
      commentImageUrl
      carouselImageUrl
      thumbnailUrl
      sampleProofImageComment {
        id
        comment
        timestamp
        commentColor
        commentTool
      }
    }
  }
`
export default withStyles(styles)(SampleProofComments)
