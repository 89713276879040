import React, { useState, useEffect, useContext, useRef } from "react"

import { Link } from "gatsby"

import moment from "moment"

import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  makeStyles,
  Hidden,
  Input,
  Paper,
  SvgIcon,
} from "@material-ui/core"

import { useSnackbar } from "notistack"

import AddPhotoIcon from "@material-ui/icons/AddPhotoAlternate"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import { Send as SendIcon } from "react-feather"

import PerfectScrollbar from "react-perfect-scrollbar"
import MoreIcon from "@material-ui/icons/MoreVert"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ReplyIcon from "@material-ui/icons/ReplyOutlined"
import ReplyAllIcon from "@material-ui/icons/ReplyAllOutlined"
import DeleteIcon from "@material-ui/icons/DeleteOutlined"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import getInitials from "../../../../utils/getInitials"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import SortContext from "../../../../context/SortContext"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import ConversationPersonSearch from "./ConversationPersonSearch"
import MessagesContext from "../context/MessagesContext"
// import FirstMessage from "./FirstMessage"
// import MessageAdd from "./MessageAdd"
// import { getMail } from 'src/actions/mailActions';
import Toolbar from "./Toolbar"
import { SettingsBluetoothSharp } from "@material-ui/icons"
// import MailReply from './MailReply';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "auto",
  },
  avatar: {
    height: 35,
    width: 35,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.accountLink,
  },
  date: {
    whiteSpace: "nowrap",
  },
  message: {
    color: theme.palette.text.secondary,
    "& > p": {
      ...theme.typography.body1,
      marginBottom: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 24,
  },
  fileInput: {
    display: "none",
  },
  makeMessage: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  paper: {
    borderRadius: "15px",
    margin: theme.spacing(2),
    minHeight: "70vh",
  },
}))

const ConversationNew = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)
  const [addPeople, setAddPeople] = useState(false)
  const [inviteList, setInviteList] = useState([])
  const [categories, setCategories] = useState([])
  const [sbus, setSbus] = useState([])
  const [sbu, setSbu] = useState(sbus[0])
  const [invitedListIds, setInvitedListIds] = useState([])
  const [category, setCategory] = useState(categories[0])
  const [conversationId, setConversationId] = useState("")
  const [conversation, setConversation] = useState("")
  const [okGo, setOkGo] = useState(false)
  const [topic, setTopic] = useState("")
  const messagesRef = useRef(null)
  const categoryId = category ? category.id : 1
  const sbuId = sbu ? sbu.id : 1

  const { enqueueSnackbar } = useSnackbar()
  const fileInputRef = useRef(null)
  const [body, setBody] = useState("")
  const [preview, setPreview] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [fileError, setFileError] = useState("")
  const [file, setFile] = useState("")

  const { data, loading, error } = useQuery(INFO_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      setCategories(data.serviceCategories)
      setSbus(data.sbus)
    },
  })

  const [createConversation] = useMutation(NEW_CONVERSATION_MUTATION, {
    variables: {
      categoryId: categoryId,
      topic: topic,
      invitedListIds: invitedListIds,
      sbuId: sbuId,
      topic: topic,
    },
    onCompleted: data => {
      setConversation(data.createConversation.conversation)
      setConversationId(data.createConversation.conversation.uuid)

      dispatch({
        type: "SET_SELECTED_CONVERSATION_ID",
        payload: data.createConversation.conversation.uuid,
      })
      setOkGo(true)
    },
  })
  //

  const [sendNewMessage] = useMutation(CREATE_CONVERSATION_MESSAGE, {
    variables: {
      conversationId: conversationId,
      messageFile: file,
      messageBody: body,
    },
    onCompleted: data => {
      const message = data.createConversationMessage.message
      console.log(message)
      setConversationId("")
      dispatch({ type: "SET_PANEL", payload: "conversationSelected" })
      setOkGo(false)
      setBody("")
      setPreview("")
      setFile("")
      setSubmitting(false)
    },
  })

  useEffect(() => {
    setInvitedListIds([])
    {
      inviteList.map(invited =>
        setInvitedListIds(ids => [...ids, parseInt(invited.id)])
      )
      console.log(invitedListIds)
    }
  }, [inviteList])

  useEffect(() => {
    if (okGo && conversationId.length > 5) {
      sendNewMessage()
    }
  }, [okGo])

  function scrollMessagesToBottom() {
    if (messagesRef.current) {
      // eslint-disable-next-line no-underscore-dangle
      messagesRef.current._container.scrollTop =
        messagesRef.current._container.scrollHeight
    }
  }
  // useEffect(() => {
  //   if (conversation) {
  //     scrollMessagesToBottom()
  //   }
  //   // eslint-disable-next-line
  // }, [messageList])

  const handleBack = () => {
    dispatch({ type: "SET_SELECTED_CONVERSATION_ID", payload: "" })
    dispatch({ type: "SET_PANEL", payload: "conversationList" })
  }

  const handleFirstMessage = () => {
    console.log("first message handler")
  }
  if (loading) return <Loading />
  if (error) return <Error />

  const handleRemoveInvite = invited => {
    let filteredArray = inviteList.filter(x => x.id !== invited.id)
    setInviteList(filteredArray)
  }

  const handleChange = event => {
    event.persist()
    setBody(event.target.value)
  }

  const handleAttach = () => {
    fileInputRef.current.click()
  }

  const handleImageChange = event => {
    const selectedFile = event.target.files[0]
    const fileSizeLimit = 10000000 // 10mb

    const imgPreview = URL.createObjectURL(selectedFile)

    if (selectedFile && selectedFile.size > fileSizeLimit) {
      setFileError(`${selectedFile.name}: File size too large`)
    } else {
      setPreview(imgPreview)
      setFile(selectedFile)
      setFileError("")
    }
  }

  return (
    <div className={classes.root}>
      <Toolbar />
      <Divider />
      <Paper className={classes.paper}>
        <Box p={2}>
          <Grid container>
            <Grid item container xs={12} sm={9}>
              <Grid item container xs={12}>
                <PerfectScrollbar>
                  <Box p={1} display="flex" justifyContent="flex-start">
                    <Tooltip title="Invite Someone">
                      <IconButton
                        color="secondary"
                        size="small"
                        onClick={() => setAddPeople(true)}
                      >
                        <PersonAddIcon />
                      </IconButton>
                    </Tooltip>

                    {inviteList.map(invited => (
                      <Box key={invited.id} ml={1}>
                        <Chip
                          label={`${invited.person.firstName} ${invited.person.lastName}`}
                          // component={Link}
                          // to={`/manager/people/${invited.person.uid}`}
                          // state={{ uid: `${invited.person.uid}` }}
                          size="small"
                          clickable
                          variant="outlined"
                          onDelete={() => handleRemoveInvite(invited)}
                          color="secondary"
                          clickable
                        />
                      </Box>
                    ))}
                  </Box>
                </PerfectScrollbar>
              </Grid>
              <Grid item xs={12}>
                <Box p={1}>
                  <TextField
                    label="Topic"
                    value={topic}
                    onChange={event => setTopic(event.target.value)}
                    fullWidth
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Box p={1}>
                <TextField
                  select
                  fullWidth
                  label="Category"
                  size="small"
                  variant="outlined"
                  value={category}
                  SelectProps={{ native: true }}
                  onChange={event => setCategory(event.target.value)}
                >
                  {categories &&
                    categories.map(cat => (
                      <option key={cat.id} value={cat}>
                        {cat.name}
                      </option>
                    ))}
                </TextField>
              </Box>
              <Box p={1}>
                <TextField
                  label="Business Unit"
                  variant="outlined"
                  fullWidth
                  size="small"
                  select
                  value={sbu}
                  SelectProps={{ native: true }}
                  onChange={event => setSbu(event.target.value)}
                >
                  {sbus &&
                    sbus.map(s => (
                      <option key={s.id} value={s}>
                        {s.name}
                      </option>
                    ))}
                </TextField>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider />

        <Box className={classes.makeMessage}>
          <Hidden smDown>
            <Avatar
              alt="Person"
              // src={user.avatar}
            />
          </Hidden>
          <Paper variant="outlined" component={Box} flexGrow={1} ml={2} p={1}>
            <Input
              className={classes.input}
              disableUnderline
              fullWidth
              value={body}
              onChange={handleChange}
              placeholder="Leave a message"
            />
          </Paper>
          <Tooltip title="Send">
            <span>
              <IconButton color="secondary" onClick={createConversation}>
                <SvgIcon fontSize="small">
                  <SendIcon />
                </SvgIcon>
              </IconButton>
            </span>
          </Tooltip>
          <Divider className={classes.divider} />
          <Tooltip title="Attach photo">
            <span>
              <IconButton edge="end" onClick={handleAttach}>
                <AddPhotoIcon />
              </IconButton>
            </span>
          </Tooltip>

          <input
            className={classes.fileInput}
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </Box>

        {/* <FirstMessage
        okGo={okGo}
        setOkGo={setOkGo}
        conversationId={conversationId}
        setConversationId={setConversationId}
        conversation={conversation}
        createConversation={createConversation}
        setSelectedConversation={setSelectedConversation}
        setPanel={setPanel}
      /> */}

        <ConversationPersonSearch
          addPeople={addPeople}
          setAddPeople={setAddPeople}
          inviteList={inviteList}
          setInviteList={setInviteList}
        />
      </Paper>
    </div>
  )
}

const INFO_QUERY = gql`
  query {
    serviceCategories {
      id
      name
      description
    }
    sbus {
      id
      name
    }
  }
`

const NEW_CONVERSATION_MUTATION = gql`
  mutation (
    $topic: String
    $categoryId: Int
    $sbuId: Int
    $invitedListIds: [Int]
  ) {
    createConversation(
      topic: $topic
      categoryId: $categoryId
      sbuId: $sbuId
      invitedListIds: $invitedListIds
    ) {
      conversation {
        uuid
        topic
        category {
          id
          name
        }
        startedBy {
          id
        }
        archived
        archivedDate
        startDate
        participants {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        invited {
          id
          email
          person {
            uid
            firstName
            lastName
          }
        }
      }
    }
  }
`
const CREATE_CONVERSATION_MESSAGE = gql`
  mutation (
    $conversationId: String!
    $messageBody: String!
    $messageFile: Upload
  ) {
    createConversationMessage(
      conversationId: $conversationId
      messageFile: $messageFile
      messageBody: $messageBody
    ) {
      message {
        uuid
        messageFile
        messageFrom {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        sent
        message
        messageFileUrl
        viewedMessage {
          id
          viewedDate
          viewer {
            id
            person {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`
export default ConversationNew
