import React, { useState } from "react"
import OrgCreateProvider from "./context/OrgCreateProvider"
import OrgCreateForm from "./OrgCreateForms/OrgCreateForm"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

const OrgCreateView = ({ classes }) => {
  return (
    <OrgCreateProvider>
      <OrgCreateForm />
    </OrgCreateProvider>
  )
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(OrgCreateView)
