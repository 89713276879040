import React, { useState, useEffect, useRef } from "react"
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
// import PROJECT_DOCS_FORM_QUERY from "./NewProjectDoc"

import MDEditor from "@uiw/react-md-editor"

import DocsTags from "../../../../Users/Docs/DocsPublishView/DocsTags"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

const EditMode = ({ classes, projectDoc, setProjectDoc, setView }) => {
  const docTagIds = projectDoc.doc.tags.map(t => t.id)
  const [title, setTitle] = useState(projectDoc.doc.title)
  const [body, setBody] = useState(projectDoc.doc.body)
  const [tagIds, setTagIds] = useState(docTagIds)
  const [tags, setTags] = useState(projectDoc.doc.tags)
  const [tagOptions, setTagOptions] = useState("")
  const [tag, setTag] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [staffOnly, setStaffOnly] = useState(projectDoc.staffOnly)
  const [membersOnly, setMembersOnly] = useState(projectDoc.membersOnly)
  const bodyRef = useRef(bodyRef)
  const { data, loading, error } = useQuery(PROJECT_DOCS_EDIT_FORM_QUERY, {
    onCompleted: data => {
      setTagOptions(data.docsTags)
    },
  })
  const handleRemoveTag = (tagId, event) => {
    const filteredArray = tagIds.filter(t => t !== tagId)
    const filteredTags = tags.filter(tag => tag.id !== tagId)
    setTagIds(filteredArray)
    setTags(filteredTags)
  }
  const handleUpdatePost = () => {
    setSubmitting(true)
    updateDoc()
  }
  const handleTagChange = event => {
    event.persist()
    let value = 0
    if (event.target.value != 0) {
      value = parseInt(event.target.value)
    }

    const newTag = tagOptions.find(tOpt => tOpt.id == value)
    setTag(newTag)
    setTagIds(prevTagIds => [...prevTagIds, value])
    setTags(prevTags => [...prevTags, newTag])
    const newOptions = tagOptions.filter(to => to.id != value)
    setTagOptions(newOptions)
  }
  const [updateDoc] = useMutation(UPDATE_PROJECT_DOC_MUTATION, {
    variables: {
      projectDocId: projectDoc.id,
      title: title,
      body: body,
      tagIds: tagIds,
      staffOnly: staffOnly,
      membersOnly: membersOnly,
    },
    onCompleted: data => {
      const upDoc = data.updateProjectDoc
      setProjectDoc(data.updateProjectDoc.projectDoc)
      setSubmitting(false)
      setView("viewDoc")
    },
  })

  const handleBold = event => {
    bodyRef.current.focus()
    let textToInsert = " **Bold Text** "
    let cursorPosition = bodyRef.current
    let textBeforeCursorPosition = body.slice(0, cursorPosition)
    let textAfterCursorPosition = body.slice(cursorPosition, body.length)
    let newBody =
      textBeforeCursorPosition + textToInsert + textAfterCursorPosition
    setBody(newBody)
  }

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Card className={classes.roundedCard}>
      <CardContent>
        <form
          onSubmit={event => {
            event.preventDefault()
            handleUpdatePost(event)
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Title"
                style={{ margin: 8 }}
                placeholder="Title"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={title}
                onChange={event => setTitle(event.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <div style={{ margin: 8 }}>
                <TextField
                  label="Tags"
                  name="tags"
                  variant="outlined"
                  value={tag}
                  onChange={handleTagChange}
                  select
                  fullWidth
                  SelectProps={{
                    native: true,
                  }}
                >
                  <>
                    <option>Tags</option>
                    {tagOptions &&
                      tagOptions.map(to => (
                        <option key={to.id} value={to.id}>
                          {to.name}
                        </option>
                      ))}
                  </>
                </TextField>
                {/* <DocsTags
                  allTags={allTags}
                  tagIds={tagIds}
                  setTagIds={setTagIds}
                  setTags={setTags}
                /> */}
              </div>
            </Grid>
            <Grid item xs={12}>
              {/* <div>
                {tagOptions
                  .filter(tagop => parseInt(tagop.id) in docTagIds)
                  .map(tg => (
                    <div key={tg}>{tg}</div>
                  ))}
              </div> */}
              {/* <div>
                {tags.map(t => (
                  <div key={t}>{t.name}</div>
                ))}
              </div> */}
              {tags && tags.length > 0 && (
                <Paper
                  elevation={0}
                  component="ul"
                  className={classes.chipList}
                >
                  {tags.map(t => (
                    <li key={t.name}>
                      <Chip
                        className={classes.tagChip}
                        size="small"
                        label={t.name}
                        clickable
                        color="primary"
                        variant="outlined"
                        onDelete={() => handleRemoveTag(t.id)}
                        deleteIcon={<HighlightOffIcon />}
                      />
                    </li>
                  ))}
                </Paper>
              )}
            </Grid>
            <Grid item xs={12}>
              <MDEditor value={body} onChange={setBody} />
            </Grid>
            {/* {body && body.length > 0 && (
              <Grid item xs={12}>
                <Typography>Preview:</Typography>
                <Paper className={classes.previewPaper}>
                  <ReactMarkdown className={classes.body}>{body}</ReactMarkdown>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12}>
              <ButtonGroup
                size="small"
                aria-label="small outlined button group"
              >
                <Button onClick={handleBold}>Bold</Button>
                <Button>Italic</Button>
                <Button>Header</Button>
              </ButtonGroup>
              <TextField
                label="Body"
                style={{ margin: 8 }}
                placeholder="Body"
                fullWidth
                margin="normal"
                multiline
                rows={8}
                rowsMax={120}
                inputRef={bodyRef}
                InputLabelProps={{
                  shrink: true,
                  rows: 10,
                }}
                variant="outlined"
                value={body}
                onChange={event => setBody(event.target.value)}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={staffOnly}
                      onChange={event => setStaffOnly(event.target.checked)}
                      name="staffOnly"
                      color="secondary"
                    />
                  }
                  label="Staff Only"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={membersOnly}
                      onChange={event => setMembersOnly(event.target.checked)}
                      name="membersOnly"
                      color="primary"
                    />
                  }
                  label="Project Members Only"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={submitting}
              >
                {submitting ? "Updating..." : "Update"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}
const PROJECT_DOCS_EDIT_FORM_QUERY = gql`
  query {
    docsTags {
      id
      name
    }
  }
`
const UPDATE_PROJECT_DOC_MUTATION = gql`
  mutation (
    $projectDocId: Int!
    $membersOnly: Boolean
    $staffOnly: Boolean
    $title: String
    $body: String
    $tagIds: [Int]
  ) {
    updateProjectDoc(
      projectDocId: $projectDocId
      membersOnly: $membersOnly
      staffOnly: $staffOnly
      title: $title
      body: $body
      tagIds: $tagIds
    ) {
      projectDoc {
        id
        project {
          id
          title
        }
        staffOnly
        membersOnly
        doc {
          id
          dateAdded
          dateModified
          title
          body
          category {
            id
            name
          }
          tags {
            id
            name
          }
          author {
            id
            person {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`

const styles = theme => ({
  root: {},
  roundedCard: {
    borderRadius: "1rem",
  },

  previewPaper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  tags: {
    fontSize: "19px",
    display: "inline",
  },
  chipList: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  tagChip: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    "& h1": {
      marginBottom: theme.spacing(3),
    },
    "& h2": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& p": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& li": {
      marginLeft: theme.spacing(4),
    },
  },
})
export default withStyles(styles)(EditMode)
