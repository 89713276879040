import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
// import { useDispatch } from 'react-redux';
import {
  Box,
  Checkbox,
  Hidden,
  IconButton,
  Input,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
  SvgIcon,
} from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import RefreshIcon from "@material-ui/icons/Refresh"
import MoreIcon from "@material-ui/icons/MoreVert"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import { Search as SearchIcon, Menu as MenuIcon } from "react-feather"
import MessagesContext from "../context/MessagesContext"

// import { openSidebar } from 'src/actions/mailActions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    height: 68,
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
}))

const Toolbar = ({
  className,
  conversations,
  selectedConversations,
  onDeselectAll,
  onSelectAll,
  ...rest
}) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)
  const searchState = state.search
  const [searchBar, setSearchBar] = useState(searchState)
  const pageSize = state.pageSize

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 1 })
  }

  const handleOpenSidebar = () => {
    dispatch({ type: "OPEN_SIDEBAR" })
  }
  const handlePageChange = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
  }
  const handleCheckboxChange = event =>
    event.target.checked ? onSelectAll() : onDeselectAll()

  const selectedAllConversations =
    selectedConversations === pageSize && pageSize > 0
  const selectedSomeConversations =
    selectedConversations > 0 && selectedConversations < pageSize

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Hidden mdUp>
        <IconButton onClick={handleOpenSidebar}>
          <SvgIcon fontSize="small">
            <MenuIcon />
          </SvgIcon>
        </IconButton>
      </Hidden>

      <Hidden smDown>
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectedAllConversations}
            className={classes.checkbox}
            indeterminate={selectedSomeConversations}
            onChange={handleCheckboxChange}
          />
          <Typography variant="h6" color="textPrimary">
            Select all
          </Typography>
        </Box>
        <Box flexGrow={1} />
        <Paper
          component="form"
          onSubmit={event => {
            event.preventDefault()
            handleSearch(event)
          }}
          // component={Box}
          // display="flex"
          // alignItems="center"
          // variant="outlined"
          // py={0.5}
          // px={1}
        >
          <Input
            className={classes.searchInput}
            disableUnderline
            placeholder="Search Invitations"
            value={searchBar}
            onChange={event => setSearchBar(event.target.value)}
          />
          <IconButton type="submit">
            <SvgIcon color="action" fontSize="small">
              <SearchIcon />
            </SvgIcon>
          </IconButton>
        </Paper>
        {/* <Tooltip title="Refresh">
          <IconButton onClick={() => handleReset}>
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}

        <Box display="flex" alignItems="center">
          <Pagination
            count={conversations.pages}
            page={conversations.page}
            onChange={handlePageChange}
            size="small"
          />
          <Tooltip title="More options">
            <IconButton>
              <MoreIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          {/* {count > 0 && (
            <>
              <Typography noWrap variant="body2" color="textSecondary">
                1 - {count} of {count}
              </Typography>
              <Tooltip title="Previous page">
                <IconButton>
                  <KeyboardArrowRightIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          )} */}
        </Box>
      </Hidden>
    </div>
  )
}

Toolbar.propTypes = {
  className: PropTypes.string,
  onDeselectAll: PropTypes.func,
  onSelectAll: PropTypes.func,
  selectedConversations: PropTypes.number.isRequired,
}

Toolbar.defualtProps = {
  onDeselectAll: () => {},
  onSelectAll: () => {},
}

export default Toolbar
