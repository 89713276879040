import React, { useState, useContext } from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core"
import ThumbUpIcon from "@material-ui/icons/ThumbUp"
import ThumbDownIcon from "@material-ui/icons/ThumbDown"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"

import FaceIcon from "@material-ui/icons/Face"

import SortContext from "../../../../context/SortContext"
import useDidMountEffect from "../../../Global/hooks/useDidMountEffect"

const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
  matchButtons: {
    marginTop: theme.spacing(1),
  },
  typography: {
    padding: theme.spacing(2),
  },
  hideIfQuoted: {
    display: "none",
  },
  link: {
    textDecoration: "none",
    color: "#000000",
  },
}))

const UserInquiryInfo = ({ inquiry, className, ...rest }) => {
  const { state, dispatch } = useContext(SortContext)
  const classes = useStyles()

  const [spam, setSpam] = useState(
    inquiry.spam && inquiry.spam ? inquiry.spam : false
  )
  const [deleteMe, setDeleteMe] = useState(
    inquiry.deleteMe && inquiry.deleteMe ? inquiry.deleteMe : false
  )
  const [newInquiry, setNewInquiry] = useState(
    inquiry.new && inquiry.new ? inquiry.new : false
  )
  const [samplesRequested, setSamplesRequested] = useState(
    inquiry.samplesRequested && inquiry.samplesRequested
      ? inquiry.samplesRequested
      : false
  )
  const [quoteCreated, setQuoteCreated] = useState(
    inquiry.quoteCreated && inquiry.quoteCreated ? inquiry.quoteCreated : false
  )
  const [personUid, setPersonUid] = useState("")

  const firstName = inquiry.userInquiryPerson.firstName
  const lastName = inquiry.userInquiryPerson.lastName
  const emailAddresses = inquiry.userInquiryPerson.emailPerson
  const phones = inquiry.userInquiryPerson.phonePerson
  const lastInitial = lastName[0]
  const [anchorEl, setAnchorEl] = useState(null)

  const [updateInquiryBooleans, { data, loading }] = useMutation(
    UPDATE_INQUIRY_BOOLEANS,
    {
      variables: {
        inquiryUuid: inquiry.inquiryUuid,
        deleteMe: deleteMe,
        spam: spam,
        samplesRequested: samplesRequested,
        quoteCreated: quoteCreated,
        new: newInquiry,
      },
      onCompleted: data => {
        const inquiryUpdate = data.updateInquiryBooleans.inquiry
        setDeleteMe(inquiryUpdate.deleteMe)
        setSpam(inquiryUpdate.spam)
        setSamplesRequested(inquiryUpdate.samplesRequested)
        setQuoteCreated(inquiryUpdate.quoteCreated)
        setNewInquiry(inquiryUpdate.new)
      },
    }
  )

  // useDidMountEffect(() => {
  //   updateInquiryBooleans()
  // }, [spam])
  // useDidMountEffect(() => {
  //   updateInquiryBooleans()
  // }, [deleteMe])
  // useDidMountEffect(() => {
  //   updateInquiryBooleans()
  // }, [samplesRequested])
  // useDidMountEffect(() => {
  //   updateInquiryBooleans()
  // }, [quoteCreated])
  useDidMountEffect(() => {
    updateInquiryBooleans()
  }, [newInquiry, quoteCreated, samplesRequested, deleteMe, spam])

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title={`Inquiry #${inquiry.id.split("-").shift()}`} />
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Date (Time)</TableCell>
              <TableCell>
                <div>
                  {moment(inquiry.createdAt).format("MMMM Do, YYYY (HH:MM)")}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Business Unit</TableCell>
              <TableCell>
                <div>
                  <a href={inquiry.businessUnit.homepage}>
                    {inquiry.businessUnit.name}
                  </a>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <>
                  <IconButton
                    onClick={event => {
                      navigate(
                        `/manager/people/${inquiry.userInquiryPerson.uid}`,
                        {
                          state: { uid: `${inquiry.userInquiryPerson.uid}` },
                        },
                        { replace: true }
                      )
                    }}
                  >
                    <FaceIcon style={{ fontSize: 65 }} color="primary" />
                  </IconButton>
                </>
              </TableCell>
              <TableCell>
                <div>
                  {firstName} {lastName}
                  {emailAddresses.map(e => (
                    <div key={e.id}>{e.emailAccount}</div>
                  ))}
                  {phones.map(p => (
                    <div key={p.id}>
                      {p.phoneType.phoneType}: {p.phoneNumber}
                    </div>
                  ))}
                </div>
                <div></div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Source</TableCell>
              <TableCell>{inquiry.source}</TableCell>
            </TableRow>

            <TableRow className={quoteCreated && classes.hideIfQuoted}>
              <TableCell>Spam</TableCell>
              <TableCell>
                <Switch
                  checked={spam}
                  onChange={event => setSpam(event.target.checked)}
                  color="secondary"
                  name="spam"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Samples Requested</TableCell>
              <TableCell>
                {quoteCreated && samplesRequested ? (
                  <ThumbUpIcon />
                ) : quoteCreated && !samplesRequested ? (
                  <ThumbDownIcon />
                ) : (
                  <Switch
                    checked={samplesRequested}
                    onChange={event =>
                      setSamplesRequested(event.target.checked)
                    }
                    color="secondary"
                    name="samplesRequested"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                )}
              </TableCell>
            </TableRow>

            <TableRow className={quoteCreated && classes.hideIfQuoted}>
              <TableCell>New</TableCell>
              <TableCell>
                <Switch
                  checked={newInquiry}
                  onChange={event => setNewInquiry(event.target.checked)}
                  color="secondary"
                  name="newInquiry"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Quote Created</TableCell>
              <TableCell>
                <Switch
                  checked={quoteCreated}
                  onChange={event => setQuoteCreated(event.target.checked)}
                  color="secondary"
                  name="quoteCreated"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
            </TableRow>
            {quoteCreated && (
              <TableRow>
                <TableCell>Quote Info</TableCell>
                <TableCell>
                  {inquiry.quoteInquiry &&
                    inquiry.quoteInquiry.map(iq => (
                      <Link
                        to={`/manager/proposals/${iq.id}`}
                        state={{ id: iq.id }}
                        className={classes.link}
                      >
                        Quoted{" "}
                        {moment(iq.createdAt).format("MMM Do, YYYY (HH:MM)")}
                      </Link>
                    ))}
                </TableCell>
              </TableRow>
            )}
            <TableRow className={quoteCreated && classes.hideIfQuoted}>
              <TableCell>Delete Me</TableCell>
              <TableCell>
                <Switch
                  checked={deleteMe}
                  onChange={event => setDeleteMe(event.target.checked)}
                  color="secondary"
                  name="deleteMe"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </>
  )
}

UserInquiryInfo.propTypes = {
  className: PropTypes.string,
  inquiry: PropTypes.object.isRequired,
}

const UPDATE_INQUIRY_BOOLEANS = gql`
  mutation (
    $inquiryUuid: String!
    $spam: Boolean
    $deleteMe: Boolean
    $quoteCreated: Boolean
    $samplesRequested: Boolean
    $new: Boolean
  ) {
    updateInquiryBooleans(
      inquiryUuid: $inquiryUuid
      spam: $spam
      deleteMe: $deleteMe
      quoteCreated: $quoteCreated
      samplesRequested: $samplesRequested
      new: $new
    ) {
      inquiry {
        id
        inquiryUuid
        deleteMe
        spam
        samplesRequested
        quoteCreated
        new
      }
    }
  }
`

export default UserInquiryInfo
