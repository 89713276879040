import React, { useState } from "react"
import clsx from "clsx"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import Brief from "./Brief"
import ProjectList from "./ProjectList"
import TimelineChart from "./TimelineChart"

const Overview = ({ plan, classes, ...rest }) => {
  return (
    <Grid container spacing={3} className={classes.root} {...rest}>
      <Grid item sm={8} xl={9} xs={12}>
        <Brief plan={plan} />

        <Box className={classes.statusBox}>
          <TimelineChart />
        </Box>
      </Grid>
      <Grid item container xs={12} sm={4} xl={3} spacing={3}>
        <Grid item xs={12}>
          <ProjectList plan={plan} />
        </Grid>
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  root: {},
  statusBox: {
    marginTop: theme.spacing(3),
  },
})
export default withStyles(styles)(Overview)
