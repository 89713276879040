import React, { useRef, useState } from "react"
// import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
// import SortContext from "../../../../context/SortContext"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import PropTypes from "prop-types"
import clsx from "clsx"
import { useSnackbar } from "notistack"
import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  Input,
  Paper,
  SvgIcon,
  Tooltip,
  makeStyles,
} from "@material-ui/core"
import AddPhotoIcon from "@material-ui/icons/AddPhotoAlternate"
import PhotoIcon from "@material-ui/icons/Photo"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import { Send as SendIcon } from "react-feather"
// import { addMessage } from 'src/actions/chatActions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2),
  },
  divider: {
    width: 1,
    height: 24,
  },
  fileInput: {
    display: "none",
  },
  messageInput: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, 1, 0, 1),
  },
}))

function MessageAdd({
  className,
  disabled,
  userConversation,
  messageList,
  setMessageList,
  onAdd,
  ...rest
}) {
  const classes = useStyles()
  //   const { user } = useSelector((state) => state.account);
  //   const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar()
  const fileInputRef = useRef(null)
  const [body, setBody] = useState("")
  const [preview, setPreview] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [fileError, setFileError] = useState("")
  //   const attachments = []
  //   const attachment = attachments && attachments[0]
  const [file, setFile] = useState("")
  const [sendNewMessage, { loading, error }] = useMutation(
    CREATE_CONVERSATION_MESSAGE,
    {
      variables: {
        conversationId: userConversation.conversation.uuid,
        messageFile: file,
        messageBody: body,
      },
      onCompleted: data => {
        const message = data.createConversationMessage.message
        console.log(message)
        setMessageList(prevMessage => [...prevMessage, message])
        setBody("")
        setPreview("")
        setFile("")
        setSubmitting(false)
      },
    }
  )
  const handleChange = event => {
    event.persist()
    setBody(event.target.value)
  }
  const handleNewMessage = () => {
    setSubmitting(true)
    sendNewMessage()
  }

  const handleAttach = () => {
    fileInputRef.current.click()
  }

  const handleImageChange = event => {
    const selectedFile = event.target.files[0]
    const fileSizeLimit = 10000000 // 10mb

    const imgPreview = URL.createObjectURL(selectedFile)

    if (selectedFile && selectedFile.size > fileSizeLimit) {
      setFileError(`${selectedFile.name}: File size too large`)
    } else {
      setPreview(imgPreview)
      setFile(selectedFile)
      setFileError("")
    }
  }
  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Avatar
        alt="Person"
        // src={user.avatar}
      />
      <Box flexGrow={1}>
        <Paper
          variant="outlined"
          component="form"
          onSubmit={event => {
            event.preventDefault()
            handleNewMessage(event)
          }}
          className={classes.messageInput}
        >
          <Input
            className={classes.input}
            disableUnderline
            fullWidth
            value={body}
            onChange={handleChange}
            //   onKeyUp={handleKeyUp}
            placeholder="Leave a message"
          />
        </Paper>
      </Box>
      <Tooltip title="Send">
        <span>
          <IconButton
            color="secondary"
            onClick={handleNewMessage}
            disabled={loading}
          >
            <SvgIcon fontSize="small">
              <SendIcon />
            </SvgIcon>
          </IconButton>
        </span>
      </Tooltip>
      <Divider className={classes.divider} />

      <span>
        {file ? (
          <Tooltip title="Remove image">
            <IconButton
              color="secondary"
              edge="end"
              onClick={() => setFile("")}
            >
              <PhotoIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Attach image">
            <IconButton color="secondary" edge="end" onClick={handleAttach}>
              <AddPhotoIcon />
            </IconButton>
          </Tooltip>
        )}
      </span>

      {/* <Tooltip title="Attach file">
        <span>
          <IconButton edge="end" onClick={handleAttach} disabled={disabled}>
            <AttachFileIcon />
          </IconButton>
        </span>
      </Tooltip> */}
      <input
        className={classes.fileInput}
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleImageChange}
      />
    </div>
  )
}

MessageAdd.propTypes = {
  className: PropTypes.string,
  thread: PropTypes.object,
  disabled: PropTypes.bool,
  onAdd: PropTypes.func,
}

MessageAdd.defaultProps = {
  className: "",
  disabled: false,
  onAdd: () => {},
}

const CREATE_CONVERSATION_MESSAGE = gql`
  mutation (
    $conversationId: String!
    $messageBody: String!
    $messageFile: Upload
  ) {
    createConversationMessage(
      conversationId: $conversationId
      messageFile: $messageFile
      messageBody: $messageBody
    ) {
      message {
        uuid
        messageFile
        messageFrom {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        sent
        message
        messageFileUrl
        viewedMessage {
          id
          viewedDate
          viewer {
            id
            person {
              uid
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`
export default MessageAdd
