import React, { useState, useContext, useEffect } from "react"
import { Grid, Typography } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import gql from "graphql-tag"
import InquiriesList from "./InquiriesList"
import SortContext from "../../../../../../context/SortContext"
const noDataResponse = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <Typography variant="h3">
            This person has no inquiries on record.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
const Inquiries = ({ person }) => {
  const uid = person.uid
  const { state, dispatch } = useContext(SortContext)
  const search = ""
  const { loading, error, data } = useQuery(PERSON_INQUIRIES_QUERY, {
    variables: {
      uid: uid,
      page: state.page,
      pageSize: state.pageSize,
      search: state.search,
    },
    onCompleted: data => {
      console.log(data)
    },
  })
  useEffect(() => {
    dispatch({ type: "SET_PAGE_SIZE", payload: 15 })
    dispatch({ type: "SET_PAGE", payload: 0 })
    dispatch({ type: "SET_SEARCH", payload: "" })
  }, [])
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) {
    return { noDataResponse }
  }
  if (data == null) {
    return { noDataResponse }
  }
  const personInquiries = data.inquiriesByPerson

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <InquiriesList personInquiries={personInquiries} />
        </Grid>
      </Grid>
    </>
  )
}

const PERSON_INQUIRIES_QUERY = gql`
  query ($uid: String!, $page: Int, $pageSize: Int, $search: String) {
    inquiriesByPerson(
      uid: $uid
      page: $page
      pageSize: $pageSize
      search: $search
    ) {
      page
      pages
      count
      hasNext
      hasPrev

      objects {
        id
        userInquiryPerson {
          uid
          firstName
          lastName
        }
        inquiryPerson {
          id
          firstName
          lastName
        }
        id
        inquiryUuid
        businessUnit {
          name
        }
        createdAt
        processedAt
        processedBy {
          person {
            firstName
            lastName
          }
        }
        source
        quoteCreated
        inquiryInquiryItem {
          id
          attachment
          attachmentUrl
          note
          converted
          description
        }
      }
    }
  }
`
export default Inquiries
