import React from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { Edit as EditIcon } from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import Label from "../../../Global/Label"
const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

function Header({ className, person, ...rest }) {
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            className={classes.link}
            variant="body1"
            color="inherit"
            to="/manager/"
          >
            Dashboard
          </Link>
          <Link
            className={classes.link}
            variant="body1"
            color="inherit"
            to="/manager/people/"
          >
            People
          </Link>
          <Typography variant="body1" color="textPrimary">
            {person.firstName} {person.lastName}
          </Typography>
        </Breadcrumbs>
        <Box display="block" mt={1}>
          <Typography variant="h3" color="textPrimary" display="inline">
            {person.firstName} {person.lastName}
          </Typography>
          <Box ml={2} display="inline">
            {person.user == undefined ? (
              <Label color="error">No user account</Label>
            ) : (
              <Label color={person.user.isVerified ? "success" : "error"}>
                {person.user.isVerified ? "Verified User" : "Unverified User"}
              </Label>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  person: PropTypes.object.isRequired,
}

export default Header
