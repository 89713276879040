import React, { useState } from "react"
import { Box, Card, Container, Grid, makeStyles } from "@material-ui/core"
import Page from "../../../Global/Page"
import Header from "./Header"
import ItemCreateForm from "./ItemCreateForm"
// import ItemCreateInfo from "./ItemCreateInfo"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: 100,
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

const ItemCreateView = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Create a new item">
      <Container maxWidth={false}>
        <Header />
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7}>
              <Card className={classes.roundedCard}>
                <ItemCreateForm />
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default ItemCreateView
