/* eslint-disable max-len */
import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import SortContext from "../../../../context/SortContext"
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
} from "react-feather"
import getInitials from "../../../../utils/getInitials"
import { useLocation } from "@reach/router"
const tabs = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "current",
    label: "Current",
  },
  {
    value: "billsDue",
    label: "Bills Due",
  },
]

const sortOptions = [
  {
    value: "updatedAt|desc",
    label: "Last update (newest first)",
  },
  {
    value: "updatedAt|asc",
    label: "Last update (oldest first)",
  },
  {
    value: "orders|desc",
    label: "Total orders (high to low)",
  },
  {
    value: "orders|asc",
    label: "Total orders (low to high)",
  },
]

function applyFilters(people, query, filters) {
  return people.filter(person => {
    let matches = true

    if (query) {
      const properties = ["email", "name"]
      let containsQuery = false

      properties.forEach(property => {
        if (person[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true
        }
      })

      if (!containsQuery) {
        matches = false
      }
    }

    Object.keys(filters).forEach(key => {
      const value = filters[key]

      if (value && person[key] !== value) {
        matches = false
      }
    })

    return matches
  })
}

function applyPagination(people, page, limit) {
  return people.slice(page * limit, page * limit + limit)
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }

  if (b[orderBy] > a[orderBy]) {
    return 1
  }

  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySort(customers, sort) {
  const [orderBy, order] = sort.split("|")
  const comparator = getComparator(order, orderBy)
  const stabilizedThis = customers.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    // eslint-disable-next-line no-shadow
    const order = comparator(a[0], b[0])

    if (order !== 0) return order

    return a[1] - b[1]
  })

  return stabilizedThis.map(el => el[0])
}

const useStyles = makeStyles(theme => ({
  root: {},
  queryField: {
    // width: 500,
    margin: 1,
  },
  searchButton: {
    margin: 1,
  },
  bulkOperations: {
    position: "relative",
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: "absolute",
    width: "100%",
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  table: {
    marginTop: 5,
  },
  avatar: {
    height: 30,
    width: 30,
    marginRight: theme.spacing(1),
    fontSize: "13px",
  },
  roundedCard: {
    borderRadius: "15px",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.link,
  },
}))

const Results = ({ className, res, ...rest }) => {
  const { state, dispatch } = useContext(SortContext)
  const location = useLocation()
  const userPermission = location.pathname.split("/")[1]
  const classes = useStyles()
  const resCount = res.count
  const resPage = res.page
  const resPages = res.pages
  const orgs = res.objects.filter(r => r.isSupplier === true)
  const orgId = orgs.ouid

  const page = state.page
  const pageSize = state.pageSize
  const searchContext = state.search

  const [currentTab, setCurrentTab] = useState("all")
  const [selectedOrgs, setSelectedOrgs] = useState(orgs)

  const [newPage, setNewPage] = useState(1)

  const [query, setQuery] = useState("")
  const [sort, setSort] = useState(sortOptions[0].value)
  const [searchBar, setSearchBar] = useState(searchContext)

  const [filters, setFilters] = useState({
    billsDue: null,
    current: null,
  })

  // const { count, page, rowsPerPage, onChangePage } = props;

  // const handleFirstPageButtonClick = (event) => {
  //   dispatch({ type: 'SET_PAGE', payload:0 });
  // };

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      billsDue: null,
      current: null,
    }

    if (value !== "all") {
      updatedFilters[value] = true
    }

    setFilters(updatedFilters)
    setSelectedOrgs([])
    setCurrentTab(value)
  }

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  // const handleQueryChange = event => {
  //   event.persist()filter
  //   setQuery(event.target.value)
  // }

  const handleSortChange = event => {
    event.persist()
    setSort(event.target.value)
  }

  const handleSelectAllOrgs = (event, personId) => {
    setSelectedOrgs(event.target.checked ? orgs.map(org => orgId) : [])
  }

  const handleSelectOneOrg = (event, orgId) => {
    if (!selectedOrgs.includes(orgId)) {
      setSelectedOrgs(prevSelected => [...prevSelected, orgId])
    } else {
      setSelectedOrgs(prevSelected => prevSelected.filter(id => id !== orgId))
    }
  }

  const handlePageChange = (event, newPage) => {
    let x = 1
    const pagiNewPage = newPage
    dispatch({ type: "SET_PAGE", payload: pagiNewPage })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  // Usually query is done on backend with indexing solutions
  const filteredOrgs = applyFilters(orgs, query, filters)
  // const sortedPeople = applySort(filteredPeople, sort)

  const enableBulkOperations = false
  // const enableBulkOperations = selectedOrgs.length > 0
  //   selectedCustomers.length > 0 && selectedCustomers.length < customers.length
  // const selectedAllCustomers = selectedCustomers.length === customers.length

  return (
    <Card className={classes.roundedCard}>
      <Tabs
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="secondary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map(tab => (
          <Tab key={tab.value} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
      <Divider />

      <Box p={2} minHeight={56} display="flex" alignItems="center">
        <form
          noValidate
          onSubmit={event => {
            event.preventDefault()
            handleSearch(event)
          }}
        >
          <TextField
            className={classes.queryField}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={event => setSearchBar(event.target.value)}
            placeholder="Search suppliers"
            value={searchBar}
            variant="outlined"
          />

          <Button
            className={classes.searchButton}
            type="submit"
            variant="contained"
            color="primary"
          >
            Search
          </Button>
        </form>

        <Box flexGrow={1} />
        <TextField
          label="Sort By"
          name="sort"
          size="small"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sort}
          variant="outlined"
        >
          {sortOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>

      {/* {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
            // checked={selectedAllCustomers}
            // indeterminate={selectedSomeCustomers}
            // onChange={handleSelectAllCustomers}
            />
            <Button variant="outlined" className={classes.bulkAction}>
              Delete
            </Button>
            <Button variant="outlined" className={classes.bulkAction}>
              Edit
            </Button>
          </div>
        </div>
      )} */}
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                  checked={selectedAllCustomers}
                  indeterminate={selectedSomeCustomers}
                  onChange={handleSelectAllCustomers}
                  />
                </TableCell> */}
                <TableCell>Name</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Categories</TableCell>
                <TableCell>Preferred</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedOrgs.map(org => {
                const primaryAddress = org.addressOrg ? org.addressOrg[0] : ""
                const primaryCountry =
                  primaryAddress &&
                  primaryAddress.address &&
                  primaryAddress.address.region
                    ? primaryAddress.address.region.name
                    : ""
                // const country =
                //   org.addressOrg.length > 0 &&
                //   org.addressOrg[0] &&
                //   org.addressOrg[0].region
                //     ? org.addressOrg[0].region.name
                //     : ""
                return (
                  <TableRow
                    hover
                    key={org.ouid}
                    // selected={isCustomerSelected}
                  >
                    {/* <TableCell padding="checkbox">
                    <Checkbox
                    checked={isCustomerSelected}
                    onChange={event =>
                      handleSelectOneCustomer(event, customer.objects.id)
                    }
                    value={isCustomerSelected}
                    />
                  </TableCell> */}
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatar} src={org.avatar}>
                          {getInitials(org.name)}
                        </Avatar>
                        <div>
                          <Link
                            className={classes.link}
                            color="inherit"
                            to={`/${userPermission}/orgs/${org.ouid}`}
                            variant="h6"
                            state={{ ouid: `${org.ouid}` }}
                          >
                            {org.name}
                          </Link>
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell>{primaryCountry}</TableCell>
                    {/* <TableCell>
                      {person.addressPerson === undefined
                        ? ""
                        : `${person.addressPerson[0]}`}
                    </TableCell> */}
                    {/* <TableCell>{person.orders}</TableCell> */}
                    <TableCell>orders</TableCell>
                    <TableCell>
                      spent
                      {/* {person.currency}
                      {person.spent} */}
                    </TableCell>
                    <TableCell>
                      user id
                      {/* {person.user === null
                        ? "Not User"
                        : `User ${person.user.id}`}*/}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        to={`./${org.ouid}`}
                        component={Link}
                        state={{ ouid: `${org.ouid}` }}
                      >
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                      <IconButton
                        to={`./${org.ouid}`}
                        component={Link}
                        state={{ ouid: `${org.ouid}` }}
                      >
                        <SvgIcon fontSize="small">
                          <ArrowRightIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>

      <TablePagination
        component="div"
        count={resCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Card>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  suppliers: PropTypes.array,
}

// Results.defaultProps = {
//   people [],
// }
// TablePaginationActions.propTypes = {
//   count: PropTypes.number.isRequired,
//   onChangePage: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
// };
export default Results
