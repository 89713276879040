import React, { useState } from "react"
import clsx from "clsx"
import {
  ButtonBase,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core"

import SamplesProofsListCard from "./SamplesProofsListCard"
import NewSampleProof from "./NewSampleProof"

const useStyles = makeStyles(() => ({
  root: {},
  roundedCard: {
    borderRadius: "15px",
  },
}))

const CreateSamplesProofs = ({
  item,
  className,
  setActiveView,
  setFileSet,
  samplesProofs,
  setSamplesProofs,
  ...rest
}) => {
  const classes = useStyles()
  const [files, setFiles] = useState([])

  // const samplesProofs = item.sampleProofItem
  // const [samplesProofs, setSamplesProofs] = useState(item.sampleProofItem)

  const handleSelect = ({ event, sp }) => {
    setFileSet(sp)
    setActiveView("details")
  }

  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Card className={classes.roundedCard}>
              <CardContent>
                <Grid container spacing={3}>
                  {!samplesProofs ||
                    (samplesProofs.count === 0 && (
                      <Card>
                        <CardContent>
                          <Typography>No sample proofs</Typography>
                        </CardContent>
                      </Card>
                    ))}
                  {samplesProofs &&
                    samplesProofs.map(sp => {
                      const spId = sp.id
                      return (
                        <Grid item xs={12} sm={6} key={sp.id}>
                          <ButtonBase onClick={e => handleSelect({ sp })}>
                            <SamplesProofsListCard sp={sp} />
                          </ButtonBase>
                        </Grid>
                      )
                    })}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Card className={classes.roundedCard}>
              <NewSampleProof
                samplesProofs={samplesProofs}
                setSamplesProofs={setSamplesProofs}
                item={item}
              />
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default CreateSamplesProofs
