import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core"
import LockOpenIcon from "@material-ui/icons/LockOpenOutlined"

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}))

const SupplierInfo = ({ supplier, className, ...rest }) => {
  const classes = useStyles()
  //   const emailAddresses = person.emailPerson
  //   const phones = person.phonePerson
  //   const addresses = person.addressPerson
  //   const socials = person.personSocialMedia
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Supplier Profile" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>
              Acct Admin
            </TableCell>
            <TableCell>
              {supplier.orgAdmin && (
                <Typography variant="body2" color="textSecondary">
                  <>
                    {supplier.orgAdmin.firstName} {supplier.orgAdmin.lastName}
                  </>
                </Typography>
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.fontWeightMedium}>Email</TableCell>
            <TableCell>
              {supplier.emailOrg && (
                <Typography variant="body2" color="textSecondary">
                  <>
                    {supplier.emailOrg.map(email => (
                      <div key={email.id}>{email.emailAddress}</div>
                    ))}
                  </>
                </Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>Phone</TableCell>
            <TableCell>
              {supplier.phoneOrg && (
                <Typography variant="body2" color="textSecondary">
                  <>
                    {supplier.phoneOrg.map(phone => (
                      <div key={phone.id}>
                        {phone.phoneNumber} ({phone.phoneType.phoneType})
                      </div>
                    ))}
                  </>
                </Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>
              Social Media
            </TableCell>
            <TableCell>
              {supplier.orgSocialMedia && (
                <Typography variant="body2" color="textSecondary">
                  <>
                    {supplier.orgSocialMedia.map(s => (
                      <div key={s.id}>
                        {s.socialMediaType.name}: {s.userName}
                      </div>
                    ))}
                  </>
                </Typography>
              )}
            </TableCell>
          </TableRow>
          {/* Todo: Add international address mapping logic */}
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>Address</TableCell>
            <TableCell>
              {supplier.addressOrg && (
                <Typography variant="body2" color="textSecondary">
                  <>
                    {supplier.addressOrg.map(address => {
                      const a = JSON.parse(address.address.summary)
                      return (
                        <div key={address.id}>
                          {a.street1}
                          <br />
                          {a.street2}
                          <br />
                          {a.city}, {a.state} {a.code}
                          <br />
                          {address.region.name}
                        </div>
                      )
                    })}
                  </>
                </Typography>
              )}
              {/* {addresses === "undefined" || addresses.length === 0 ? (
                <Typography variant="body2">No address on file</Typography>
              ) : (
                <>
                  {addresses.map(address => {
                    const a = JSON.parse(address.address.summary)
                    return (
                      <div key={address.id}>
                        {a.street1}
                        <br />
                        {a.street2}
                        <br />
                        {a.city}, {a.state} {a.code}
                      </div>
                    )
                  })}
                </>
              )} */}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  )
}

SupplierInfo.propTypes = {
  className: PropTypes.string,
  supplier: PropTypes.object.isRequired,
}

export default SupplierInfo
