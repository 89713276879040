import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { Box, Grid, makeStyles } from "@material-ui/core"
import OrgInfo from "./OrgInfo"
import OrgPeople from "./OrgPeople"
import AboutOrg from "./AboutOrg"
// import UserAccount from "./UserAccount"
// import PreRegAccount from "./PreRegAccount"
// import CreatePreregAccount from "./CreatePreregAccount"
// import NewPersonOrder from "./NewPersonOrder"
// import NewPersonProposal from "./NewPersonProposal"
// import RemovePerson from "./RemovePerson"
// import Emails from './Emails';
// import Invoices from './Invoices';
// import OtherActions from './OtherActions';

const useStyles = makeStyles(() => ({
  root: {},
}))

function Details({ org, className, ...rest }) {
  const classes = useStyles()
  const [businessUnits, setBusinessUnits] = useState([])
  const [taxes, setTaxes] = useState([])
  const { data, loading, error } = useQuery(FORM_INFO_QUERY, {
    fetchPolicy: "cache-first",
    onCompleted: data => {
      setBusinessUnits(data.sbus)
      setTaxes(data.taxes)
    },
  })

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      alignItems="flex-start"
      spacing={3}
      {...rest}
    >
      <Grid item lg={4} md={6} xl={3} xs={12}>
        {/* OrgInfo will show for all orgs in the database */}
        <OrgInfo org={org} />
      </Grid>

      <Grid item container lg={4} md={6} xl={3} xs={12} direction="column">
        <Grid item xs={12}>
          <OrgPeople org={org} />
          {/* <NewPersonOrder person={person} businessUnits={businessUnits} /> */}
        </Grid>
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <AboutOrg org={org} />
      </Grid>
    </Grid>
  )
}

Details.propTypes = {
  className: PropTypes.string,
  person: PropTypes.object.isRequired,
}

const FORM_INFO_QUERY = gql`
  query {
    sbus {
      id
      name
    }
    taxes {
      id
      code
      name
    }
  }
`

export default Details
