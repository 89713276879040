import React, { useState, useRef, useContext } from "react"
import { Link } from "gatsby"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import clsx from "clsx"
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import Rating from "@material-ui/lab/Rating"

import {
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
} from "react-feather"

import moment from "moment"
import PerfectScrollbar from "react-perfect-scrollbar"

import ProposalListContext from "./context/ProposalListContext"

const businessUnitOptions = [
  {
    id: "all",
    name: "All Business Units",
  },
  {
    id: 1,
    name: "Icon Emblem",
  },
]

const Results = ({ classes, ...rest }) => {
  const { state, dispatch } = useContext(ProposalListContext)

  const now = new Date()

  const { loading, error, data } = useQuery(LIST_ALL_PROPOSALS_QUERY, {
    variables: {
      search: state.search,
      page: state.page,
      pageSize: state.pageSize,
      preregFilter: state.preregFilter,
      businessUnitFilter: state.businessUnitFilter,
      likelihoodFilter: state.likelihoodFilter,
    },
    onCompleted: data => {
      const resultsObjects = data.quotes.objects
      const resultsPageIndex = data.quotes.page - 1
      const resultsCount = data.quotes.count
      dispatch({ type: "SET_COUNT", payload: resultsCount })
      dispatch({ type: "SET_PAGE_INDEX", payload: resultsPageIndex })
      dispatch({ type: "SET_SELECTED_PROPOSALS", payload: resultsObjects })
      console.log(data)
    },
    fetchPolicy: "cache-and-network",
  })

  const handleBusinessUnitChange = event => {
    event.persist()
    let value = ""
    if (event.target.value !== "all") {
      value = event.target.value
    }
    dispatch({ type: "SET_BUSINESS_UNIT_FILTER", payload: value })
  }

  const handlePreregChange = event => {
    event.persist()
    let value = null
    if (event.target.checked) {
      value = true
    }
    dispatch({ type: "SET_PREREG_FILTER", payload: value })
  }

  const handlePageChange = newPage => {
    dispatch({ type: "SET_PAGE", payload: newPage })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: event.target.value })
    dispatch({ type: "SET_PAGE", payload: 1 })
  }

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: state.searchBar })
    dispatch({ type: "SET_PAGE", payload: 1 })
  }

  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
  }

  const handleClearFilters = event => {
    dispatch({ type: "SET_BUSINESS_UNIT_FILTER", payload: null })
    dispatch({ type: "SET_PREREG_FILTER", payload: null })
    dispatch({ type: "SET_LIKELIHOOD_FILTER", payload: null })
    dispatch({ type: "SET_SEARCH", payload: "" })
  }

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }

  return (
    <Box mt={2}>
      <Card className={clsx(classes.root, classes.roundedCard)} {...rest}>
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={10} sm={5}>
              <Paper
                component="form"
                onSubmit={event => {
                  event.preventDefault()
                  handleSearch(event)
                }}
                className={classes.searchField}
              >
                <InputBase
                  fullWidth
                  className={classes.input}
                  placeholder="Search Proposals"
                  inputProps={{ "aria-label": "Search Proposals" }}
                  value={state.searchBar}
                  onChange={event =>
                    dispatch({
                      type: "SET_SEARCH_BAR",
                      payload: event.target.value,
                    })
                  }
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={handleClearSearchResults}
                >
                  <CancelIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormControlLabel
                className={classes.inProgressField}
                control={
                  <Checkbox
                    checked={state.preregFilter}
                    onChange={handlePreregChange}
                    name="preregFilter"
                  />
                }
                label="Only Pre-reg"
              />
            </Grid>

            <Grid item xs={5} sm={2}>
              <Rating
                name="Quote Likelihood"
                max={5}
                value={state.likelihoodFilter}
                onChange={(event, newValue) => {
                  dispatch({ type: "SET_LIKELIHOOD_FILTER", payload: newValue })
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                className={classes.supplierField}
                fullWidth
                name="businessunit"
                onChange={handleBusinessUnitChange}
                select
                SelectProps={{ native: true }}
                value={state.businessUnitFilter ? state.businessUnitFilter : ""}
                variant="outlined"
                size="small"
              >
                {businessUnitOptions.map(businessUnitOption => (
                  <option
                    key={businessUnitOption.id}
                    value={businessUnitOption.id}
                  >
                    {businessUnitOption.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearFilters}
                fullWidth
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Box>

        <PerfectScrollbar>
          <Box minWidth={1200}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>SBU</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Likelihood</TableCell>
                  <TableCell>Items</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.selectedProposals &&
                  state.selectedProposals.map(p => {
                    const quoteId = p.id
                    const quoteUser = p.userQuoteUser
                    const itemCount = p.quoteForItems && p.quoteForItems.length

                    const preregQuoteUser = p.preRegQuoteUser
                    const status = !p.isActive
                      ? "Inactive"
                      : p.expiresAt && p.expiresAt > now
                      ? "Expired"
                      : p.sent && p.sentAt
                      ? `Sent ${moment(p.sentAt).format("MM/DD/YYYY")}`
                      : "Incomplete"
                    const userName =
                      quoteUser &&
                      quoteUser.user &&
                      quoteUser.user.person &&
                      quoteUser.user.person.firstName +
                        " " +
                        quoteUser.user.person.lastName
                    const preregName =
                      preregQuoteUser &&
                      preregQuoteUser.preregUser.person &&
                      preregQuoteUser.preregUser.person.firstName +
                        " " +
                        preregQuoteUser.preregUser.person.lastName
                    const userUid =
                      quoteUser &&
                      quoteUser.user &&
                      quoteUser.user.person &&
                      quoteUser.user.person.uid
                    const preregUid =
                      preregQuoteUser &&
                      preregQuoteUser.preregUser.person &&
                      preregQuoteUser.preregUser.person.uid
                    const uid = userUid ? userUid : preregUid ? preregUid : ""
                    const name = userName
                      ? userName
                      : preregName
                      ? preregName
                      : ""
                    const likelihood = p.likelihood
                    const createdAt = moment(p.createdAt).format("MM/DD/YYYY")
                    return (
                      <TableRow hover key={p.id}>
                        <TableCell>
                          <Button
                            size="small"
                            component={Link}
                            color="primary"
                            to={`/manager/proposals/${p.id}`}
                            state={{ id: `${p.id}` }}
                          >
                            {p.id}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Link
                            className={classes.name}
                            variant="subtitle2"
                            color="textPrimary"
                            to={`/manager/people/${uid}`}
                            state={{ uid: `${uid}` }}
                          >
                            {name}
                          </Link>
                        </TableCell>
                        <TableCell>{p.businessUnit.name}</TableCell>
                        <TableCell>{status}</TableCell>
                        <TableCell>{createdAt}</TableCell>

                        <TableCell>
                          <Rating max={5} value={likelihood} readOnly />
                        </TableCell>

                        <TableCell>{itemCount}</TableCell>

                        <TableCell align="right">
                          <IconButton
                            component={Link}
                            to={`/manager/proposals/${p.id}`}
                            state={{ id: Object.values(quoteId) }}
                          >
                            <SvgIcon fontSize="small">
                              <ArrowRightIcon />
                            </SvgIcon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={state.count}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={state.pageIndex}
              rowsPerPage={state.pageSize}
              rowsPerPageOptions={[5, 10, 15, 25, 50]}
            />
          </Box>
        </PerfectScrollbar>
      </Card>
    </Box>
  )
}

const styles = theme => ({
  root: {},
  roundedCard: {
    borderRadius: "15px",
  },
  table: {
    marginTop: 5,
  },

  supplierField: {
    flexBasis: 200,
  },
  batchStatusField: {
    flexBasis: 200,
  },
  productionStatusField: {
    flexBasis: 200,
  },
  inProgressField: {},

  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0,
  },
  image: {
    height: 68,
    width: 68,
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  name: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
})

const LIST_ALL_PROPOSALS_QUERY = gql`
  query (
    $page: Int
    $pageSize: Int
    $search: String
    $preregFilter: Boolean
    $likelihoodFilter: Int
    $businessUnitFilter: Int
  ) {
    quotes(
      page: $page
      pageSize: $pageSize
      search: $search
      preregFilter: $preregFilter
      likelihoodFilter: $likelihoodFilter
      businessUnitFilter: $businessUnitFilter
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        createdAt
        sent
        sentAt

        expiresAt
        isActive
        likelihood

        businessUnit {
          name
        }
        isPrereg
        preRegQuoteUser {
          id
          preregUser {
            person {
              uid
              firstName
              lastName
            }
          }
        }
        userQuoteUser {
          id
          user {
            id
            person {
              uid
              firstName
              lastName
            }
          }
        }
        quoteForItems {
          id
          item {
            id
          }
        }
      }
    }
  }
`

export default withStyles(styles)(Results)
