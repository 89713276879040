import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Breadcrumbs,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { PlusCircle as PlusCircleIcon } from "react-feather"
import { useLocation } from "@reach/router"
const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    color: theme.palette.common.black,
    textDecoration: "none",
  },
}))

function Header({ className, ...rest }) {
  const classes = useStyles()
  const location = useLocation()
  const userPermission = location.pathname.split("/")[1]
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            className={classes.link}
            to={`/${userPermission}/`}
          >
            Dashboard
          </Link>
          <Link
            variant="body1"
            className={classes.link}
            to={`/${userPermission}/supply`}
          >
            Supply
          </Link>
          <Typography variant="body1" color="textPrimary">
            Suppliers
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PlusCircleIcon />
          </SvgIcon>
          New Supplier
        </Button>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
