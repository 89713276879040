export default function reducer(state, action) {
  switch (action.type) {
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      }
    case "SET_PAGE":
      return {
        ...state,
        page: action.payload,
      }
    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageSize: action.payload,
      }

    case "SET_GENERIC_ORDER_BY":
      return {
        ...state,
        genericOrderBy: action.payload,
      }
    case "SET_ITEM_TASK_ORDER_BY":
      return {
        ...state,
        itemTasksOrderBy: action.payload,
      }
    case "SET_ORDER_TASK_ORDER_BY":
      return {
        ...state,
        orderTasksOrderBy: action.payload,
      }
    case "SET_QUOTE_TASK_ORDER_BY":
      return {
        ...state,
        quoteTasksOrderBy: action.payload,
      }
    case "SET_PROJECT_TASK_ORDER_BY":
      return {
        ...state,
        projectTasksOrderBy: action.payload,
      }
    // case "SET_FIRST":
    //     return {
    //         ...state,
    //         first: action.payload,
    //     }
    // case "SET_SKIP":
    //     return {
    //         ...state,
    //         skip: action.payload
    //     }
    default:
      return state
  }
}
