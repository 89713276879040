import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Avatar,
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { deepOrange, deepPurple } from "@material-ui/core/colors"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.palette.text.secondaryContrast,
    backgroundColor: theme.palette.secondary.main,
    display: "inline",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

function Header({ quote, className, ...rest }) {
  const classes = useStyles()
  const quoteId = quote.id

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            className={classes.link}
            variant="body1"
            color="inherit"
            to="/manager"
            component={Link}
          >
            Management
          </Link>
          <Link
            className={classes.link}
            variant="body1"
            color="inherit"
            to="/manager/proposals"
            component={Link}
          >
            Proposals
          </Link>
          <Typography variant="body1" color="textPrimary">
            Proposal {quoteId}
          </Typography>
        </Breadcrumbs>
        <Box mt={2}>
          <Typography display="inline" variant="h3" color="textPrimary">
            Proposal Details
          </Typography>
          <Avatar variant="rounded" className={classes.avatar}>
            PR
          </Avatar>
        </Box>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
