import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  avatar: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    padding: 2,
    marginBotton: 5,
  },
}))

const CustomFields = ({ item, className, ...rest }) => {
  const [customFields, setCustomFields] = useState(item.customItemField)
  const [componentInDevelopment, setComponentInDevelopment] = useState(true)
  const classes = useStyles()

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Custom Fields" />
      <Divider />
      <CardContent>
        {customFields.length > 0 ? (
          <List disablePadding>
            {customFields &&
              customFields.map(cf => (
                <ListItem key={cf.id}>
                  <ListItemText
                    primary={cf.name && cf.name}
                    primaryTypographyProps={{ variant: "h6" }}
                    secondary={cf.description && cf.description}
                    secondaryTypographyProps={{ variant: "caption" }}
                  />

                  <Typography variant="body2" color="textSecondary">
                    {cf.isActive && cf.isActive ? "Active" : "Inactive"}
                  </Typography>
                </ListItem>
              ))}
          </List>
        ) : (
          <Typography variant="h5">No Custom Fields</Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          size="small"
          color="primary"
          variant="contained"
          disabled={componentInDevelopment}
        >
          Component in Development
          {/* Add a Custom Field */}
        </Button>
      </CardActions>
    </Card>
  )
}

CustomFields.propTypes = {
  className: PropTypes.string,
  option: PropTypes.object.isRequired,
}
export default CustomFields
