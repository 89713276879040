import React, { useCallback, useState, useEffect } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import moment from "moment"
import PerfectScrollbar from "react-perfect-scrollbar"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import Label from "../../../../Label"
import GenericMoreButton from "../../../../Global/GenericMoreButton"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"

const labelColors = {
  complete: "success",
  pending: "warning",
  rejected: "error",
}

const useStyles = makeStyles(theme => ({
  root: {},
  navigateNextIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const CurrentProductionItems = ({ className, ...rest }) => {
  const classes = useStyles()

  const [orders, setOrders] = useState(null)
  // const { loading, error, data } = useQuery(PRODUCTION_ITEMS_QUERY, {
  const { loading, error, data } = useQuery(ORDER_ITEMS_IN_PRODUCTION_QUERY, {
    variables: {
      pageSize: 5,
      page: 1,
      search: "",
      orderStatusFilter: 3,
    },
  })

  if (loading) return <Loading />

  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  // const productionItems = data.itemsInProduction.objects
  const productionItems = data.orderItems.objects
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader action={<GenericMoreButton />} title="Items In Production" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection="desc">
                  <Tooltip enterDelay={300} title="Sort">
                    <TableSortLabel active direction="desc">
                      Order
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell sortDirection="desc">
                  <Tooltip enterDelay={300} title="Sort">
                    <TableSortLabel active direction="desc">
                      Item
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>

                <TableCell>Status</TableCell>
                <TableCell align="right">Dates</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productionItems &&
                productionItems.map(pi => (
                  <TableRow hover key={pi.id}>
                    <TableCell>
                      <Button
                        small
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        {pi.order ? pi.order.id : ""}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {pi.item.name} <br />
                      Item Id: {pi.item.id} <br />
                      {pi.item.category.name} <br />
                      {/* {pi.customFields ? } <br /> */}
                      {/* {pi.project && `Project Id: ${pi.project.id}`} */}
                    </TableCell>

                    <TableCell>
                      {/* <Label color={labelColors[order.status]}> */}
                      <Label>
                        status name
                        {/* {pi.itemproductionSet.productionStatus.name} */}
                      </Label>
                      <br />
                      {pi.itemproductionSet.batch &&
                        `${pi.itemproductionSet.batch.supplier.name}`}{" "}
                      <br />
                      <Link to={"/manager/"}>Supplier Order</Link>
                    </TableCell>
                    <TableCell align="right">
                      {/* Ship By: {moment(pi.expectedShipDate).format("MMM DD")} */}
                      <br />
                      Due: {moment(pi.dueInHands).format("MMM DD")}
                      <br />
                      {/* Drop Dead: {moment(pi.dropDeadDate).format("MMM DD")} */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button component={Link} size="small" to="/manager/items/">
          See all
          <NavigateNextIcon className={classes.navigateNextIcon} />
        </Button>
      </Box>
    </Card>
  )
}

CurrentProductionItems.propTypes = {
  className: PropTypes.string,
}

const ORDER_ITEMS_IN_PRODUCTION_QUERY = gql`
  query (
    $page: Int
    $pageSize: Int
    $search: String
    $itemFilter: Int
    $orderStatusFilter: Int
  ) {
    orderItems(
      page: $page
      pageSize: $pageSize
      search: $search
      itemFilter: $itemFilter
      orderStatusFilter: $orderStatusFilter
    ) {
      count
      page
      pages
      hasNext
      hasPrev
      objects {
        id
        customFields
        qty
        order {
          id
        }
        item {
          id
          name
          category {
            id
            name
          }
        }
        dueInHands
        itemproductionSet {
          id
          productionStatus {
            id
            name
          }
          batch {
            id
            supplier {
              id
              ouid
              name
            }
          }
        }
      }
    }
  }
`
const PRODUCTION_ITEMS_QUERY = gql`
  query ($page: Int, $pageSize: Int, $search: String) {
    itemsInProduction(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        item {
          id
          name
          category {
            id
            name
          }
        }
        expectedShipDate
        dropDeadDate
        inHandsDate
        productionStatus {
          id
          name
        }
        project {
          id
        }
        batch {
          id
          supplier {
            name
          }
        }
      }
    }
  }
`
export default CurrentProductionItems
