import React, { useState, useContext } from "react"
import { Box, Grid, Typography } from "@material-ui/core"

import withStyles from "@material-ui/core/styles/withStyles"
import gql from "graphql-tag"
import SortContext from "../../../../../../context/SortContext"
import { useQuery, useMutation } from "@apollo/client"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import EmailList from "./EmailList"
import EmailDetails from "./EmailDetails"

const noDataResponse = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <Typography variant="h3">
            This person has no conversations on record.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

const EmailPerson = ({ classes, person }) => {
  const { state, dispatch } = useContext(SortContext)
  const [uid, setUid] = useState(person.uid)
  const [emailSelected, setEmailSelected] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState("")
  const { loading, data, error } = useQuery(PERSON_EMAILS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      uid: uid,
      page: state.page,
      pageSize: state.pageSize,
      search: state.search,
    },
    onCompleted: data => {
      console.log(data)
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) {
    return { noDataResponse }
  }
  if (data == null) {
    return { noDataResponse }
  }
  const personEmails = data.mailMessages
  return (
    <>
      <Grid container spacing={3}>
        {emailSelected ? (
          <Grid item md={12} xl={12} xs={12}>
            {/* {selectedConversation && selectedConversation.uuid} */}
            <EmailDetails
              selectedEmail={selectedEmail}
              setEmailSelected={setEmailSelected}
            />
          </Grid>
        ) : (
          <Grid item md={12} xl={12} xs={12}>
            <EmailList
              person={person}
              personEmails={personEmails}
              setEmailSelected={setEmailSelected}
              setSelectedEmail={setSelectedEmail}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

const styles = theme => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
})

const PERSON_EMAILS_QUERY = gql`
  query ($page: Int, $pageSize: Int, $search: String, $uid: String) {
    mailMessages(page: $page, pageSize: $pageSize, search: $search, uid: $uid) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        mailbox {
          id
          name
          fromEmail
        }
        subject
        messageId
        inReplyTo {
          id
        }
        fromHeader
        toHeader
        outgoing
        body
        encoded
        processed
        read
        eml
        replies {
          id
          mailbox {
            id
          }

          encoded
        }
      }
    }
  }
`
export default withStyles(styles)(EmailPerson)
