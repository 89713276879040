import React, { useCallback, useEffect, useState } from "react"
import { Box, Container, Grid, makeStyles } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import Page from "../../../Global/Page"
import Header from "./Header"
import BatchInfo from "./BatchInfo"
import BatchItems from "./BatchItems"
// import AddOrderItem from "./AddOrderItem"
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const SupplierBatchDetailsView = ({ id }) => {
  const classes = useStyles()

  const [addBatchItem, setAddBatchItem] = useState(false)
  const [items, setItems] = useState([])
  const { data, loading, error } = useQuery(SUPPLIER_BATCH_DETAILS_QUERY, {
    variables: {
      id: id,
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const batch = data.itemBatch
  return (
    <Page className={classes.root} title="Order Details">
      <Container maxWidth={false}>
        <Header batch={batch} />
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item md={4} xl={3} xs={12}>
              <BatchInfo batch={batch} />
            </Grid>
            <Grid item md={8} xl={9} xs={12}>
              {
                addBatchItem && "add order item"
                // <AddOrderItem
                //   id={id}
                //   items={items}
                //   setItems={setItems}
                //   setAddOrderItem={setAddOrderItem}
                // />
              }
              <BatchItems
                id={id}
                batch={batch}
                // setItems={setItems}
                // addOrderItem={addOrderItem}
                // setAddOrderItem={setAddOrderItem}
              />
            </Grid>
          </Grid>
        </Box>
        {/* <Box mt={3}> {JSON.stringify(data)}</Box> */}
      </Container>
    </Page>
  )
}

const SUPPLIER_BATCH_DETAILS_QUERY = gql`
  query ($id: Int) {
    itemBatch(id: $id) {
      id
      supplier {
        ouid
        name
      }
      batchStatus {
        id
        name
      }
      shipFrom {
        ouid
        name
      }
      carrier {
        ouid
        name
      }
      tracking
      shipTo {
        uid
        firstName
        lastName
      }
      expectedArrival
      actualArrival
      noteInternal
      noteSupplier
      orderItemBatch {
        id
        item {
          id
          name
          category {
            id
            name
          }
          businessUnit {
            id
            name
          }
          isPrivate
        }
      }
    }
  }
`
export default SupplierBatchDetailsView
