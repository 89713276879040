import React, { useState, useContext, useEffect } from "react"
import { Box, Container, makeStyles } from "@material-ui/core"
import Page from "../../../Global/Page"
import Header from "./Header"
import Results from "./Results"
import SortContext from "../../../../context/SortContext"
import PerfectScrollbar from "react-perfect-scrollbar"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
// import useDidMountEffect from "../../../Global/hooks/useDidMountEffect"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "91vh",
    paddingTop: theme.spacing(1),
    // paddingBottom: 100,
  },
}))

const ItemListView = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(SortContext)
  // const [search, setSearch] = useState("")
  // const [page, setPage] = useState(0)
  // const [pageSize, setPageSize] = useState(10)
  const search = state.search
  const page = state.page + 1
  const pageSize = state.pageSize

  const { loading, error, data } = useQuery(LIST_ALL_ITEMS_QUERY, {
    variables: {
      search: search,
      page: page,
      pageSize: pageSize,
    },
    fetchPolicy: "cache-and-network",
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const itemResults = data.items.objects
  const res = data.items

  return (
    <Page className={classes.root} title="Item List">
      <PerfectScrollbar>
        <Container maxWidth={false}>
          <Header />
          {itemResults && (
            <Box mt={3}>
              <Results res={res} />
            </Box>
          )}
        </Container>
      </PerfectScrollbar>
    </Page>
  )
}
export const LIST_ALL_ITEMS_QUERY = gql`
  query ($page: Int!, $pageSize: Int!, $search: String) {
    items(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        name
        dateAdded
        dateModified
        isPrivate
        isActive
        category {
          name
        }
        featuredImageThumbnailUrl
        businessUnit {
          name
        }
      }
    }
  }
`
export default ItemListView
