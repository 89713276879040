import React, { useContext } from "react"
import clsx from "clsx"
import moment from "moment"
import { Link } from "gatsby"
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from "@material-ui/core"
import PerfectScrollbar from "react-perfect-scrollbar"
import Pagination from "@material-ui/lab/Pagination"
import SortContext from "../../../../../../context/SortContext"
const useStyles = makeStyles(() => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
}))

const OrderList = ({ personOrders, className, ...rest }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(SortContext)
  const handleChange = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
  }
  if (personOrders.count === 0) {
    return (
      <div>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardHeader title="No Orders on Record" />
          <Divider />
        </Card>
      </div>
    )
  }

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          title="Orders"
          action={
            personOrders.pages > 1 && (
              <Pagination
                count={personOrders.pages}
                page={state.page}
                onChange={handleChange}
              />
            )
          }
        />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Business Unit</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Line Items</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell align="right">Due</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {personOrders.objects.map(order => (
                  <TableRow key={order.id}>
                    <TableCell>
                      <Link to={`/manager/orders/${order.id}`}>{order.id}</Link>
                    </TableCell>
                    <TableCell>
                      Date{" "}
                      {/* {moment(inquiry.createdAt).format("DD/MM/YYYY | HH:MM")} */}
                    </TableCell>

                    <TableCell>{order.businessUnit.name}</TableCell>
                    <TableCell>
                      {order.status && order.status.orderStatus}
                    </TableCell>
                    <TableCell>{order.orderWithItem.length}</TableCell>
                    <TableCell>total</TableCell>
                    <TableCell align="right">due</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

export default OrderList
