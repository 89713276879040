import React, { useState, useEffect } from "react"
import Header from "./Header"
import UploadRegions from "./UploadRegions"
import TestForm from "./TestForm"
import { Box, Container, TextField, makeStyles } from "@material-ui/core"
import Page from "../../../Global/Page"

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  input: {
    display: "none",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {},

  image: {
    width: 200,
  },
  inquiryImg: {
    maxWidth: 300,
  },
  box: {
    minHeight: "800px",
  },
}))

const managerForms = [
  {
    id: 1,
    name: "Upload Regions",
    component: <UploadRegions />,
  },
  {
    id: 2,
    name: "Test Form",
    component: <TestForm />,
  },
]

const PeopleManagerView = ({ className, ...rest }) => {
  const classes = useStyles()
  const [managerFormId, setManagerFormId] = useState(2)
  const [managerForm, setManagerForm] = useState("")

  useEffect(() => {
    getForm()
  }, [managerForm])

  const getForm = managerFormId => {
    switch (managerFormId) {
      case 1:
        return <UploadRegions />
      case 2:
        return <TestForm />
      default:
        return <TestForm />
    }
  }

  return (
    <Page className={classes.root} title="Manager's Forms">
      <Container maxWidth={false}>
        <Header />
        <Box classname={classes.box}>
          <TextField
            fullWidth
            select
            SelectProps={{ native: true }}
            margin="dense"
            size="small"
            name="state"
            // onChange={event => setStateUS(event.target.value)}
            onChange={event => setManagerForm(event.target.value)}
            type="text"
            value={managerForm}
            variant="outlined"
          >
            {managerForms.map(form => (
              <option key={form.id} value={form}>
                {form.name}
              </option>
            ))}
          </TextField>
          {getForm(managerForm)}
        </Box>{" "}
      </Container>
    </Page>
  )
}

export default PeopleManagerView
