import React, { useState } from "react"
import clsx from "clsx"
import { useMutation } from "@apollo/client"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import gql from "graphql-tag"

import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  makeStyles,
  TextField,
} from "@material-ui/core"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  roundedCard: {
    marginBottom: theme.spacing(3),
    borderRadius: "15px",
  },
}))

const NewPersonOrder = ({
  person,
  className,
  businessUnits,
  taxes,
  ...rest
}) => {
  const classes = useStyles()
  const sbus = businessUnits
  const uid = person.uid
  const [sbu, setSbu] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [handleSubmit, { data, loading, error }] = useMutation(
    QUICK_CREATE_ORDER_MUTATION,
    {
      variables: { customerUid: uid, sbuId: sbu },
      fetchPolicy: "no-cache",
      onCompleted: data => {
        let orderId = data.createOrderQuick.order.id
        navigate(`/manager/orders/${orderId}`, { state: { id: orderId } })
      },
    }
  )

  const handleBusinessUnitChange = event => {
    event.persist()

    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
      console.log("value is")
      console.log(value)
    }
    setSbu(value)
  }

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <>
      <Card
        className={clsx(classes.root, classes.roundedCard, className)}
        {...rest}
      >
        <CardHeader title="Start a new order" />
        <Divider />
        <CardContent>
          <form
            onSubmit={event => {
              event.preventDefault()
              setIsSubmitting(true)
              handleSubmit(event)
            }}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <TextField
              label="Business Unit"
              InputLabelProps={{
                shrink: true,
              }}
              name="businessUnit"
              onChange={handleBusinessUnitChange}
              select
              size="small"
              fullWidth
              variant="outlined"
              SelectProps={{ native: true }}
              value={sbu}
            >
              <option></option>
              {sbus.map(s => (
                <option key={s.id} value={s.id}>
                  {s.name}
                </option>
              ))}
            </TextField>
            <Box mt={1}>
              <Button
                disabled={isSubmitting}
                fullWidth
                size="small"
                type="submit"
                color="secondary"
                variant="contained"
                // endIcon={<ArrowForwardIcon />}
              >
                Start a New Order
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </>
  )
}

const QUICK_CREATE_ORDER_MUTATION = gql`
  mutation ($customerUid: String, $sbuId: Int) {
    createOrderQuick(customerUid: $customerUid, sbuId: $sbuId) {
      order {
        id
        customer {
          uid
          firstName
          lastName
        }
        businessUnit {
          id
          name
        }
      }
    }
  }
`

export default NewPersonOrder
