import React, { useState, useEffect } from "react"
import {
  Box,
  Fade,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import {
  BookOpen as OpenIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from "react-feather"
import red from "@material-ui/core/colors/red"
import grey from "@material-ui/core/colors/grey"
import green from "@material-ui/core/colors/green"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import Editor from "./Editor"
import Alert from "@material-ui/lab/Alert"

const Strategy = ({ classes, plan }) => {
  const planId = plan.id
  const strategyValue = plan.strategy ? plan.strategy : "What's your strategy?"
  const [view, setView] = useState("read")
  const [value, setValue] = useState(strategyValue)
  const [needToSave, setNeedToSave] = useState(false)
  const [errorNotification, setErrorNotification] = useState(false)
  const [saveNotification, setSaveNotification] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [saveStrategy, { data, loading, error }] = useMutation(
    SAVE_STRATEGY_MUTATION,
    {
      variables: {
        planId: planId,
        strategy: value,
      },
      onError: () => {
        setErrorNotification(true)
      },
      onCompleted: data => {
        setSaveNotification(true)
        setNeedToSave(false)
        setIsSaving(false)
      },
    }
  )
  useEffect(
    prevValue => {
      if (!needToSave) {
        if (value !== prevValue) {
          setNeedToSave(true)
        }
      }
    },
    [value]
  )

  const handleSave = () => {
    setIsSaving(true)
    setView("read")
    saveStrategy()
  }

  const handleCloseAlert = () => {
    if (errorNotification) {
      setErrorNotification(false)
    }
    if (saveNotification) {
      setSaveNotification(false)
    }
  }

  return (
    <div>
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={2}>
              <Typography variant="h4">Strategy</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <IconButton onClick={() => setView("read")} disabled={isSaving}>
                <SvgIcon
                  className={
                    view === "read" ? classes.activeView : classes.basic
                  }
                >
                  <OpenIcon />
                </SvgIcon>
              </IconButton>
              <IconButton onClick={() => setView("write")} disabled={isSaving}>
                <SvgIcon
                  className={
                    view === "write" ? classes.activeView : classes.basic
                  }
                >
                  <EditIcon />
                </SvgIcon>
              </IconButton>

              <IconButton
                onClick={handleSave}
                disabled={!needToSave || isSaving}
              >
                <SvgIcon
                  className={needToSave ? classes.saveMe : classes.saved}
                >
                  <SaveIcon />
                </SvgIcon>
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Fade in={saveNotification}>
                <Alert severity="success" onClose={handleCloseAlert}>
                  Document Saved
                </Alert>
              </Fade>
              <Fade in={errorNotification}>
                <Alert severity="error" onClose={handleCloseAlert}>
                  Save error. Try again.
                </Alert>
              </Fade>
            </Grid>
          </Grid>
        </div>
      )}
      <Editor view={view} value={value} setValue={setValue} />
    </div>
  )
}

const styles = theme => ({
  root: {},
  saveMe: {
    color: red[700],
  },
  basic: {
    color: grey[800],
  },
  saved: {
    color: grey[400],
  },
  activeView: {
    color: green[700],
  },
})

const SAVE_STRATEGY_MUTATION = gql`
  mutation ($planId: Int!, $strategy: String) {
    updateStrategy(planId: $planId, strategy: $strategy) {
      plan {
        id
        strategy
      }
    }
  }
`
export default withStyles(styles)(Strategy)
