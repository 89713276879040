import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Menu,
  MenuItem,
  Paper,
  Tags,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { Link } from "gatsby"
import { ToggleButtonGroup, ToggleButton, Pagination } from "@material-ui/lab"
import ViewModuleIcon from "@material-ui/icons/ViewModule"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"

import FavoriteIcon from "@material-ui/icons/Favorite"
import ShareIcon from "@material-ui/icons/Share"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import CategoryIcon from "@material-ui/icons/Category"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  card: {
    marginTop: theme.spacing(2),
    borderRadius: "15px",
  },
  title: {
    position: "relative",
    "&:after": {
      position: "absolute",
      bottom: -8,
      left: 0,
      content: '" "',
      height: 3,
      width: 48,
      backgroundColor: theme.palette.primary.main,
    },
  },
  tagChip: {
    marginRight: theme.spacing(1),
  },
  sortButton: {
    textTransform: "none",
    letterSpacing: 0,
    marginRight: theme.spacing(2),
  },
  actionArea: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary,
  },
}))

function Results({ res, className, ...rest }) {
  const classes = useStyles()
  const sortRef = useRef(null)
  const plans = res.objects
  const [openSort, setOpenSort] = useState(false)
  const [selectedSort, setSelectedSort] = useState("Most popular")
  const [mode, setMode] = useState("grid")

  const handleSortOpen = () => {
    setOpenSort(true)
  }

  const handleSortClose = () => {
    setOpenSort(false)
  }

  const handleSortSelect = value => {
    setSelectedSort(value)
    setOpenSort(false)
  }

  const handleModeChange = (event, value) => {
    setMode(value)
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb={2}
      >
        <Typography className={classes.title} variant="h5" color="textPrimary">
          Found {res.objects.length} plans
        </Typography>
        <Box display="flex" alignItems="center">
          {/* <Button
            className={classes.sortButton}
            onClick={handleSortOpen}
            ref={sortRef}
          >
            selected sort 
            <ArrowDropDownIcon />
          </Button> */}
          {/* <ToggleButtonGroup
            exclusive
            onChange={handleModeChange}
            size="small"
            value={mode}
          >
            <ToggleButton value="grid">
              <ViewModuleIcon />
            </ToggleButton>
          </ToggleButtonGroup> */}
        </Box>
      </Box>
      <Grid container spacing={3}>
        {plans.map(plan => {
          const excerpt = plan.objective ? plan.objective.slice(0, 200) : ""
          const ellipsis = plan.objective.length > excerpt.length ? "..." : ""
          return (
            <Grid item key={plan.id} xs={4}>
              <Card className={classes.card}>
                <CardActionArea
                  component={Link}
                  to={`./${plan.id}`}
                  state={{ id: plan.id }}
                >
                  <CardHeader
                    title={plan.title}
                    subheader={
                      <>
                        <Typography>
                          {plan.isActive ? "Active" : "Inactive"}
                        </Typography>
                      </>
                    }
                    titleTypographyProps={{ style: { fontSize: "26px" } }}
                  />
                </CardActionArea>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <List>
                        <ListItem>
                          <ListItemIcon>
                            <AccessTimeIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={`Initiated ${moment(plan.startDate).format(
                              "MMM Do, YYYY"
                            )}`}
                            secondary={`by ${plan.createdBy.person.firstName} ${plan.createdBy.person.lastName}`}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar
                              variant="rounded"
                              className={classes.avatar}
                            >
                              <CategoryIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              plan.category && plan.category.name
                                ? plan.category.name
                                : "No Assigned Category"
                            }
                            secondary="Category"
                          />
                        </ListItem>
                        {plan.projectPlan && plan.projectPlan.length > 0 && (
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar
                                variant="rounded"
                                className={classes.avatar}
                              >
                                {plan.projectPlan
                                  ? plan.projectPlan.length
                                  : "0"}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                plan.projectPlan && (
                                  <>
                                    <Typography>
                                      {`${plan.projectPlan.length} project${
                                        plan.projectPlan.length != 1
                                          ? "s "
                                          : " "
                                      }in this plan`}
                                    </Typography>
                                  </>
                                )
                              }
                            />
                          </ListItem>
                        )}
                      </List>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Typography variant="body1">
                          {excerpt}
                          {ellipsis}
                        </Typography>
                      </Box>
                      {plan.tags && (
                        <Box mt={2}>
                          {plan.tags &&
                            plan.tags.map(tag => (
                              <Chip
                                key={tag.id}
                                variant="outlined"
                                color="primary"
                                label={tag.name}
                                className={classes.tagChip}
                              />
                            ))}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
      <Box mt={6} display="flex" justifyContent="center">
        <Pagination count={3} />
      </Box>
      {/* <Menu
        anchorEl={sortRef.current}
        className={classes.menu}
        onClose={handleSortClose}
        open={openSort}
        elevation={1}
      >
        {["Most recent", "Popular", "Price high", "Price low", "On sale"].map(
          option => (
            <MenuItem key={option} onClick={() => handleSortSelect(option)}>
              <ListItemText primary={option} />
            </MenuItem>
          )
        )}
      </Menu> */}
    </div>
  )
}

Results.propTypes = {
  className: PropTypes.string,
}

export default Results
