import React, { useState, useContext } from "react"
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  makeStyles,
} from "@material-ui/core"
import Page from "../../../Global/Page"
import useIsMountedRef from "../../../../hooks/useIsMountedRef"
import Header from "./Header"
import Results from "./Results"
// import SupplierOrderList from "./SupplierOrderList"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import SupplierOrderContext from "../../../../context/SupplierOrderContext"
import PerfectScrollbar from "react-perfect-scrollbar"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    height: "100%",
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))
const tabs = [
  { value: "orders", label: "Orders" },
  { value: "batches", label: "Batches" },
  { value: "invoices", label: "Invoices" },
]
const SupplierOrderListView = () => {
  const classes = useStyles()
  const isMountedRef = useIsMountedRef()
  const { state, dispatch } = useContext(SupplierOrderContext)
  const search = state.search
  const page = state.page + 1
  const pageSize = state.pageSize
  const [currentTab, setCurrentTab] = useState("orders")
  const [supplierFilter, setSupplierFilter] = useState("")
  const [batchStatusFilter, setBatchStatusFilter] = useState(null)
  const [productionStatusFilter, setProductionStatusFilter] = useState(null)
  const [inProgressFilter, setInProgressFilter] = useState(null)
  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  const getCurrentTab = currentTab => {
    switch (currentTab) {
      case "orders":
        return "orders"
      case "batches":
        return "batches"
      case "invoices":
        return "invoices"
      default:
        return "orders"
    }
  }
  const { loading, error, data } = useQuery(LIST_ALL_SUPPLIER_ORDERS_QUERY, {
    variables: {
      search: search,
      page: page,
      pageSize: pageSize,
      supplierFilter: supplierFilter,
      batchStatusFilter: batchStatusFilter,
      productionStatusFilter: productionStatusFilter,
    },
    fetchPolicy: "cache-and-network",
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const res = data.supplierItemOrders

  return (
    <Page className={classes.root} title="Supplier Orders">
      <Container maxWidth={false}>
        <Header currentTab={currentTab} tabs={tabs} />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {/* {getCurrentTab(currentTab)} */}
          {/* <Results
            res={res}
            supplierFilter={supplierFilter}
            setSupplierFilter={setSupplierFilter}
            batchStatusFilter={batchStatusFilter}
            setBatchStatusFilter={setBatchStatusFilter}
            productionStatusFilter={productionStatusFilter}
            setProductionStatusFilter={setProductionStatusFilter}
            inProgressFilter={inProgressFilter}
            setInProgressFilter={setInProgressFilter}
          /> */}
        </Box>
      </Container>
    </Page>
  )
}

const LIST_ALL_SUPPLIER_ORDERS_QUERY = gql`
  query (
    $page: Int
    $pageSize: Int
    $search: String
    $supplierFilter: String
    $batchStatusFilter: Int
    $productionStatusFilter: Int
    $inProgressFilter: Boolean
  ) {
    supplierItemOrders(
      page: $page
      pageSize: $pageSize
      search: $search
      supplierFilter: $supplierFilter
      batchStatusFilter: $batchStatusFilter
      productionStatusFilter: $productionStatusFilter
      inProgressFilter: $inProgressFilter
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        item {
          id
          name
          category {
            id
            name
          }
        }
        production {
          id
          productionStatus {
            id
            name
          }
          expectedShipDate
          inHandsDate
          dropDeadDate
          repeatItem
          noteInternal
          noteClient
        }
        batch {
          id
          supplier {
            id
            name
          }
          batchStatus {
            id
            name
          }

          shipFrom {
            ouid
            name
          }
          carrier {
            ouid
            name
          }
          tracking
          shipTo {
            uid
            firstName
            lastName
          }
          expectedArrival
          actualArrival
          noteInternal
          noteSupplier
        }
        invoice {
          id
          supplier {
            name
            ouid
          }
          paid
        }
        qty
        inProgress
        itemCost {
          id
          minQuantity
          maxQuantity
          cost
        }
      }
    }
  }
`

export default SupplierOrderListView
