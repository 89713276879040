import React from "react"
import ProjectProvider from "../context/ProjectProvider"
import ProjectCreation from "./ProjectCreation"

const ProjectCreateView = () => {
  return (
    <ProjectProvider>
      <ProjectCreation />
    </ProjectProvider>
  )
}

export default ProjectCreateView
