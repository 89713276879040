import React from "react"
import { Container, Grid, makeStyles } from "@material-ui/core"

import CurrentCustomers from "./CurrentCustomers"
import CurrentProductionItems from "./CurrentProductionItems"
import TopSuppliers from "./TopSuppliers"
import CurrentSupplierOrders from "./CurrentSupplierOrders"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 64,
      paddingRight: 64,
    },
  },
}))

const ItemProductionDashboard = ({ className, ...rest }) => {
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={8} xs={12}>
          <CurrentProductionItems />
        </Grid>

        <Grid item lg={4} xs={12}>
          <CurrentCustomers />
        </Grid>
        <Grid item lg={8} xs={12}>
          <CurrentSupplierOrders />
        </Grid>
        <Grid item lg={4} xs={12}>
          <TopSuppliers />
        </Grid>
      </Grid>
    </>
  )
}

export default ItemProductionDashboard
