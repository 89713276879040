import React, { useEffect } from "react"
import {
  Card,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core"
import { withStyles, useTheme } from "@material-ui/core/styles"
import NoteIcon from "@material-ui/icons/Note"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"

const ProjectDocsList = ({
  classes,
  project,
  projectDoc,
  setProjectDoc,
  view,
  setView,
}) => {
  const theme = useTheme()
  const projectId = project.id
  const page = 1
  const pageSize = 20
  const search = ""

  const { data, loading, error } = useQuery(PROJECT_DOCS_QUERY, {
    variables: {
      page: page,
      pageSize: pageSize,
      search: search,
      projectId: projectId,
    },
    onCompleted: data => {
      const docsCount = data.projectDocs.count
      if (docsCount == 0) {
        setView("createDoc")
      }
    },
  })
  const handleSetProjectDoc = (proDoc, event) => {
    setProjectDoc(proDoc)
    setView("viewDoc")
  }

  if (loading) return <Loading />
  if (error) return <Error />

  const projectDocs = data.projectDocs
  return (
    <Card className={classes.roundedCard}>
      <List>
        <ListItem button onClick={() => setView("createView")}>
          <ListItemIcon>
            <AddCircleIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Create New Doc" />
        </ListItem>
        <Divider />
        {projectDocs && projectDocs.count > 0 ? (
          projectDocs.objects.map(pd => (
            <ListItem
              button
              key={pd.id}
              // value={pd}
              onClick={() => handleSetProjectDoc(pd)}
            >
              <ListItemIcon>
                <NoteIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary={pd.doc.title} />
            </ListItem>
          ))
        ) : (
          <Typography>No docs yet</Typography>
        )}
      </List>
    </Card>
  )
}

const styles = theme => ({
  root: {},
  roundedCard: {
    borderRadius: "1rem",
  },
})
const PROJECT_DOCS_QUERY = gql`
  query ($page: Int, $pageSize: Int, $search: String, $projectId: Int) {
    projectDocs(
      page: $page
      pageSize: $pageSize
      search: $search
      projectId: $projectId
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        project {
          id
        }
        membersOnly
        staffOnly
        taskOverdueStatus
        doc {
          id
          title
          body
          category {
            id
            name
          }
          author {
            id
            person {
              firstName
              lastName
            }
          }
          tags {
            id
            name
          }
          dateAdded
          dateModified
        }
      }
    }
  }
`
export default withStyles(styles)(ProjectDocsList)
