import React, { useState, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import PerfectScrollbar from "react-perfect-scrollbar"

import InquiryItemConversion from "./InquiryItemConversion"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"

import {
  Box,
  Button,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
  List,
  ListItem,
  CardActionArea,
} from "@material-ui/core"
import { Bold } from "react-feather"
import Draggable from "react-draggable"

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}
const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 2,
    width: "100%",
  },
  detailsKey: {
    textTransform: "capitalize",
  },
  closeButton: {
    position: "absolute",
    right: 2,
    top: 2,
    // color: theme.palette.grey[500],
  },
  body: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  // content: {
  //   flex: "1 0 auto",
  // },
  cover: {
    maxHeight: 300,
    maxWidth: 300,
  },
  divider: {
    marginBottom: 5,
  },
  heading: {
    fontWeight: "bold",
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    // fontSize: theme.typography.pxToRem(15),
    // color: palette.text.secondary,
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

const InquiryItems = ({ inquiry, person, isPerson, className, ...rest }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [fromInquiry, setFromInquiry] = useState(true)
  const [openAdd, setOpenAdd] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const items = inquiry.inquiryInquiryItem
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpenAddDialog = () => {
    setOpenAdd(true)
  }
  const handleCloseAddDialog = () => {
    setOpenAdd(false)
  }
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  function capitalizeFLetter() {
    var input = document.getElementById("input")
    var x = document.getElementById("div")
    var string = input.value
    x.innerHTML = string.charAt(0).toUpperCase() + string.slice(1)
  }
  return (
    <>
      <Card
        className={clsx(classes.root, classes.roundedCard, className)}
        {...rest}
      >
        <CardHeader title="Inquiry items" />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={300}>
            {items.map(inquiryItem => {
              const specs = JSON.parse(inquiryItem.description)
              const sortedSpecs = Object.entries(specs).sort((a, b) =>
                b[0].localeCompare(a[0])
              )
              const inquiryFile = inquiryItem.attachmentUrl
              const measurementFields = [
                "width",
                "height",
                "length",
                "depth",
                "thickness",
              ]
              const titleFields = ["name", "title"]
              const noteFields = ["notes", "note", "comments", "comment"]
              const specSize = []
              const specDetails = []
              const specNote = []
              const specTitle = []
              for (const [key, value] of sortedSpecs) {
                let titles = titleFields.includes(key)
                let measurements = measurementFields.includes(key)
                let notes = noteFields.includes(key)
                let details = !measurements && !notes && !titles
                let format = (
                  <Fragment key={key}>
                    {(key.charAt(0).toUpperCase() + key.slice(1)).replace(
                      /_/g,
                      " "
                    )}
                    : {value.charAt(0).toUpperCase() + value.slice(1)}
                    <br />
                  </Fragment>
                )
                if (titles) specTitle.push(format)
                if (measurements) specSize.push(format)
                if (notes) specNote.push(format)
                if (details) specDetails.push(format)
              }
              return (
                <Accordion
                  key={inquiryItem.id}
                  expanded={expanded === `panel${inquiryItem.id}`}
                  onChange={handleChange(`panel${inquiryItem.id}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={8} sm={4}>
                        <Typography className={classes.heading}>
                          {specs.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sm={4}>
                        <Typography className={classes.secondaryHeading}>
                          {inquiryItem.inquiryItemType.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <Card elevation={0}> */}
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={5}>
                        <Box mb={2}>
                          <Button
                            fullWidth
                            size="small"
                            color="primary"
                            variant="outlined"
                            onClick={handleClickOpen}
                          >
                            Convert to Item
                          </Button>
                        </Box>
                        <Dialog
                          onClose={handleClose}
                          aria-labelledby="#draggable-dialog-title"
                          PaperComponent={PaperComponent}
                          open={open}
                        >
                          <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                            onClose={handleClose}
                          >
                            Convert Inquiry to Item
                          </DialogTitle>
                          <DialogContent dividers>
                            {isPerson ? (
                              <InquiryItemConversion
                                handleClose={handleClose}
                                inquiryItem={inquiryItem}
                                inquiry={inquiry}
                                specs={specs}
                                fromInquiry={fromInquiry}
                                setFromInquiry={setFromInquiry}
                                person={person}
                              />
                            ) : (
                              <Typography>
                                Please convert inquiry person prior to
                                converting this item.
                              </Typography>
                            )}
                          </DialogContent>
                        </Dialog>
                        <CardActionArea>
                          {inquiryFile ? (
                            <CardMedia
                              component="img"
                              alt="Attached Image"
                              image={inquiryFile}
                              title="Attached Image"
                              className={classes.cover}
                            />
                          ) : (
                            "No Inquiry Files"
                          )}
                        </CardActionArea>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <CardContent>
                          <Typography>{specTitle}</Typography>
                          <Typography>{specSize}</Typography>
                          <Typography>{specDetails}</Typography>
                          <Typography>{specNote}</Typography>
                        </CardContent>
                      </Grid>
                    </Grid>
                    {/* </Card> */}
                  </AccordionDetails>
                </Accordion>
              )
            })}
            {/* <Box>Converted items:</Box> */}
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

InquiryItems.propTypes = {
  className: PropTypes.string,
  inquiry: PropTypes.object.isRequired,
}

export default InquiryItems
