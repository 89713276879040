import React, { useState, useContext, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core"
import NavBar from "./NavBar"
import TopBar from "./TopBar"
import UserContext from "../../context/UserContext"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Cred from "../Cred"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}))

function DashboardLayout({ children }) {
  const { state, dispatch } = useContext(UserContext)
  const classes = useStyles()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [user, setUser] = useState("")

  const isBrowser = () => typeof window !== "undefined"
  const [counting, setCounting] = useState(false)

  const [rand, setRand] = useState(null)
  const timer = useRef(null)

  // Will logout if no clicks or scrolls in 5 minutes
  const delay = 300

  useEffect(() => {
    if (isLoggedIn) {
      setCounting(true)
      setRand(Math.random)
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (counting) {
      timer.current = setTimeout(function () {
        handleLogout()
      }, delay * 1000)
      return () => {
        clearTimeout(timer.current)
      }
    }
  }, [rand])

  const handleReset = () => {
    clearTimeout(timer.current)
    setCounting(true)
    setRand(Math.random)
  }
  useEffect(() => {
    if (isLoggedIn || state.illinois) {
      window.addEventListener("keydown", handleReset)
      window.addEventListener("scroll", handleReset)
      window.addEventListener("click", handleReset)

      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", handleReset)
        window.removeEventListener("scroll", handleReset)
        window.removeEventListener("click", handleReset)
      }
    }
  }, [rand])

  const [handleLogout, { client, loading, error }] = useMutation(
    LOGOUT_MUTATION,
    {
      onCompleted: data => {
        // console.log("logout on timer")
        dispatch({ type: "SET_ILLINOIS", payload: false })
        setIsLoggedIn(false)
        setUser("")
        client.resetStore()
        setCounting(false)
      },
    }
  )

  if (state.illinois && state.virginia && isLoggedIn)
    return (
      <div className={classes.root}>
        <TopBar
          user={user}
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          onMobileNavOpen={() => setMobileNavOpen(true)}
        />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
      </div>
    )

  if (
    state.illinois === false ||
    state.virginia === false ||
    isLoggedIn === false
  )
    return (
      <Cred
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        user={user}
        setUser={setUser}
      />
    )
}

DashboardLayout.propTypes = {
  children: PropTypes.any,
}
const LOGOUT_MUTATION = gql`
  mutation {
    deleteTokenCookie {
      deleted
    }
  }
`

export default DashboardLayout
