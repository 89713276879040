import React, { useState } from "react"

import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import PropTypes from "prop-types"

import EditIcon from "@material-ui/icons/Edit"
import DoneIcon from "@material-ui/icons/Done"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import PhoneIcon from "@material-ui/icons/Phone"

import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"

import MuiPhoneNumber from "mui-phone-input-ssr"
import useDidMountEffect from "../../../../../Global/hooks/useDidMountEffect"
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

const UpdateOrgPhone = ({ org, phones, setPhones, classes }) => {
  const ouid = org.ouid
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneTypeId, setPhoneTypeId] = useState(1)
  const [newArray, setNewArray] = useState([])
  const [newPhone, setNewPhone] = useState("")
  const [phoneNumberId, setPhoneNumberId] = useState(null)

  const [phoneTypes, setPhoneTypes] = useState([])

  const { loading, error } = useQuery(PHONE_TYPES_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      setPhoneTypes(data.phoneTypes)
    },
  })

  const [deleteOrgPhone] = useMutation(DELETE_ORG_PHONE_MUTATION, {
    variables: {
      phoneNumberId: phoneNumberId,
    },
    onCompleted: () => {
      setPhones(newArray)
    },
  })
  const [addOrgPhone] = useMutation(ADD_ORG_PHONE_MUTATION, {
    onCompleted: data => {
      const phoneValue = data.createPhoneNumberOrg.phoneNumber
      setPhones(prevSelected => [...prevSelected, phoneValue])
      setNewPhone("")
      setPhoneTypeId(1)
    },
  })

  const handlePhoneForm = () => {
    addOrgPhone({
      variables: {
        ouid: ouid,
        phoneNumber: newPhone,
        phoneTypeId: phoneTypeId,
      },
    })
  }
  function handlePhoneChange(value) {
    setNewPhone(value)
  }
  const handleDelete = phone => {
    const filteredArray = phones.filter(x => x.id !== phone.id)
    setNewArray(filteredArray)
    setPhoneNumberId(phone.id)
  }
  useDidMountEffect(() => {
    deleteOrgPhone()
  }, [phoneNumberId])

  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <>
      <Box>
        <Typography variant="h3">Phone Numbers</Typography>
        <Box>
          <Box>
            <>
              {phones.map(phone => (
                <Box key={phone.id} mt={2} mb={2}>
                  <Chip
                    icon={<DoneIcon />}
                    label={`${phone.phoneNumber}`}
                    variant="outlined"
                    onDelete={e => handleDelete(phone)}
                  />
                </Box>
              ))}
            </>
          </Box>

          <div className={classes.root}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={12} sm={12}>
                <InputLabel htmlFor="formatted-text-mask-input">
                  New phone number:
                </InputLabel>
                <Box mt={1}>
                  <TextField
                    label="Phone Number Type"
                    name="phoneType"
                    onChange={event => setPhoneTypeId(event.target.value)}
                    select
                    margin="dense"
                    size="small"
                    fullWidth
                    variant="outlined"
                    SelectProps={{ native: true }}
                    value={phoneTypeId}
                  >
                    {phoneTypes.map(pt => (
                      <option key={pt.id} value={pt.id}>
                        {pt.phoneType}
                      </option>
                    ))}
                  </TextField>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <MuiPhoneNumber
                    label="Phone Number"
                    id="phone"
                    value="phone"
                    fullWidth
                    defaultCountry="us"
                    regions={["america", "europe", "asia"]}
                    disableAreaCodes
                    onChange={handlePhoneChange}
                  />

                  {/* <NoSsr>
                    <MuiPhoneInput
                      label="Phone Number"
                      id="phone"
                      value="phone"
                      fullWidth
                      defaultCountry="us"
                      regions={["america", "europe", "asia"]}
                      disableAreaCodes
                      onChange={handlePhoneChange}
                    />
                  </NoSsr> */}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box mt={1} mb={1}>
                  <Button
                    size="small"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    aria-label="add phone"
                    onClick={() => handlePhoneForm()}
                    className={classes.margin}
                  >
                    Add Phone Number
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Box>
    </>
  )
}

const ADD_ORG_PHONE_MUTATION = gql`
  mutation ($ouid: String!, $phoneTypeId: Int, $phoneNumber: String) {
    createPhoneNumberOrg(
      ouid: $ouid
      phoneTypeId: $phoneTypeId
      phoneNumber: $phoneNumber
    ) {
      phoneNumber {
        id
        phoneType {
          id
          phoneType
        }
        phoneNumber
        org {
          id
          ouid
          name
        }
      }
    }
  }
`

const DELETE_ORG_PHONE_MUTATION = gql`
  mutation ($phoneNumberId: Int!) {
    deletePhoneNumberOrg(phoneNumberId: $phoneNumberId) {
      phoneNumberId
    }
  }
`

const PHONE_TYPES_QUERY = gql`
  query {
    phoneTypes {
      id
      phoneType
    }
  }
`

UpdateOrgPhone.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
}

UpdateOrgPhone.default = {
  onSubmitSuccess: () => {},
}

const styles = theme => ({
  root: {},
  button: {
    margin: 10,
  },
  margin: {
    margin: 2,
  },
  phoneInput: {
    minHeight: 10,
    padding: 3,
    margin: 3,
    border: "solid",
    borderRadius: 3,
    borderColor: "#ccc",
    boxSizing: "border-box",
    display: "block",
  },
})
export default withStyles(styles)(UpdateOrgPhone)
