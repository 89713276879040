import React, { useState } from "react"
import clsx from "clsx"
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Draggable, { DraggableCore } from "react-draggable"

const ProjectList = ({ classes, plan }) => {
  const [projects, setProjects] = useState(plan.projectPlan)
  const [selectedProject, setSelectedProject] = useState("")
  const handleStart = event => {
    setSelectedProject(event.target.value)
  }
  return (
    <Card className={clsx(classes.root, classes.roundedCard)}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Projects
        </Typography>
        {projects ? (
          projects.map(p => (
            <Draggable
              key={p.id}
              axis="y"
              handle=".handle"
              defaultPosition={{ x: 0, y: 0 }}
              position={null}
              grid={[25, 25]}
              scale={1}
              //   onStart={handleStart}
              onDrag={handleStart}
              //   onStop={this.handleStop}
              className={
                selectedProject ? classes.selectedItem : classes.notSelectedItem
              }
            >
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <Typography variant="h5" className="handle">
                      {p.project.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>(-)</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Draggable>
          ))
        ) : (
          <Typography variant="body1">No projects in this plan.</Typography>
        )}
      </CardContent>
    </Card>
  )
}

const styles = theme => ({
  root: {},
  roundedCard: {
    borderRadius: "15px",
  },
  selectedItem: {
    borderColor: "#00000",
  },
  notSelectedItem: {
    borderColor: "#FF6347",
  },
})
export default withStyles(styles)(ProjectList)
