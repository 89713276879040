import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import {
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardHeader,
  Dialog,
  DialogTitle,
  Divider,
  Paper,
  Switch,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  makeStyles,
} from "@material-ui/core"

import Rating from "@material-ui/lab/Rating"
import useDidMountEffect from "../../../Global/hooks/useDidMountEffect"
import { deepOrange, deepPurple } from "@material-ui/core/colors"
import {
  User as UserIcon,
  Mail as PreviewIcon,
  Send as SendIcon,
} from "react-feather"
// import DateFnsUtils from "@date-io/date-fns"
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDateTimePicker,
// } from "@material-ui/pickers"
import Draggable from "react-draggable"
import UpdateBusinessUnit from "./UpdateProposalForms/UpdateBusinessUnit"
import PreviewQuote from "./PreviewQuote"
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}
const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  preregAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    display: "inline",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  userAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepOrange[500],
    display: "inline",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: "#000000",
  },
}))

// const statusOptions = ["Canceled", "Completed", "Rejected"]

function ProposalInfo({ quote, className, ...rest }) {
  const classes = useStyles()
  const isPrereg = quote.isPrereg
  var user = isPrereg
    ? quote.preRegQuoteUser.preregUser
    : quote.userQuoteUser.user
  var preregEmail =
    isPrereg &&
    quote.preRegQuoteUser &&
    quote.preRegQuoteUser.preregUser &&
    quote.preRegQuoteUser.preregUser.preregEmail
      ? quote.preRegQuoteUser.preregUser.preregEmail
      : "No prereg email address"
  var personName = user.person.firstName + " " + user.person.lastName

  var uid = user.person.uid
  var userEmail =
    quote.userQuoteUser &&
    quote.userQuoteUser.user &&
    quote.userQuoteUser.user.email
  var userPersonEmail =
    quote.userQuoteUser &&
    quote.userQuoteUser.user &&
    quote.userQuoteUser.user.person &&
    quote.userQuoteUser.user.person.emailPerson

  var createdBy = quote.createdBy
  var sendToDefault = isPrereg ? preregEmail : userEmail ? userEmail : ""
  var createdByName =
    createdBy.person.firstName + " " + createdBy.person.lastName
  var sentAt = quote.sentAt
  const quoteId = quote.id
  const [rating, setRating] = useState(quote.likelihood)
  const [status, setStatus] = useState(quote.isActive)
  const [sbu, setSbu] = useState(quote.businessUnit)
  const [sbus, setSbus] = useState([])
  const [previewEmail, setPreviewEmail] = useState(false)
  const [confirmSend, setConfirmSend] = useState(false)
  const [sendEmailTo, setSendEmailTo] = useState("brikerr@gmail.com")
  const [businessUnitDialogOpen, setBusinessUnitDialogOpen] = useState(false)
  const [expirationDate, setExpirationDate] = useState(quote.expiresAt)
  const [changeInfo, setChangeInfo] = useState(false)

  const { data, loading, error } = useQuery(FORM_INFO_QUERY, {
    fetchPolicy: "cache-first",
    onCompleted: data => {
      setSbus(data.sbus)
    },
  })
  const [updateRating] = useMutation(UPDATE_RATING, {
    variables: {
      quoteId: quoteId,
      likelihood: rating,
    },
    onCompleted: data => {
      setRating(data.updateQuoteRating.quote.likelihood)
    },
  })

  const [updateQuoteInfo] = useMutation(UPDATE_QUOTE_INFO, {
    variables: {
      quoteId: quoteId,
      expiresAt: expirationDate,
      businessUnitId: sbu.id,
    },
    onCompleted: data => {
      setSbu(data.updateQuoteInfo.quote.businessUnit)
      setExpirationDate(data.updateQuoteInfo.quote.expiresAt)
      setChangeInfo(false)
      handleCloseEditBusinessUnitDialog()
      console.log(data)
    },
  })
  const [sendQuote] = useMutation(SEND_QUOTE, {
    variables: {
      quoteId: quoteId,
      personUid: uid,
      sendTo: sendEmailTo,
      preview: previewEmail,
    },
    onCompleted: data => {
      console.log(data)
      console.log("quote was sent")
    },
  })
  useDidMountEffect(() => {
    updateRating()
  }, [rating])

  useEffect(() => {
    if (quote.likelihood > 5) {
      setRating(5)
    }
  }, [])

  useEffect(() => {
    changeInfo && updateQuoteInfo()
  }, [changeInfo])

  const [updateStatus] = useMutation(UPDATE_STATUS, {
    variables: {
      quoteId: quoteId,
      isActive: status,
    },
    onCompleted: data => {
      setStatus(data.updateQuoteStatus.quote.isActive)
    },
  })

  useDidMountEffect(() => {
    updateStatus()
  }, [status])

  const handleOpenEditBusinessUnitDialog = () => {
    setBusinessUnitDialogOpen(true)
  }
  const handleCloseEditBusinessUnitDialog = () => {
    setBusinessUnitDialogOpen(false)
  }
  const handleExpirationDateChange = date => {
    setExpirationDate(date)
    setChangeInfo(true)
  }
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title={`${quote.businessUnit.name} Proposal # ${quote.id} `}
        // title={`${order.businessUnit.name} Order # ${order.id} | Status: ${order.status.orderStatus}`}
      />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>
              <Link
                component={Link}
                to={`/manager/people/${uid}`}
                className={classes.link}
              >
                {personName}
              </Link>
              {quote.userQuoteUser && (
                <SvgIcon fontSize="small" color="secondary">
                  <UserIcon />
                </SvgIcon>
              )}
              {quote.preRegQuoteUser && quote.preRegQuoteUser.preregUser && (
                <br />
              )}
              {quote.preRegQuoteUser &&
                quote.preRegQuoteUser.preregUser &&
                "Pregistered user"}
              {quote.preRegQuoteUser &&
                quote.preRegQuoteUser.preregUser.registered &&
                " (converted)"}
              {/* <Avatar className={classes.preregAvatar}>PR</Avatar> */}
              {/* <Avatar className={classes.userAvatar}>
                <AccountCircleIcon />
              </Avatar> */}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <ButtonBase onClick={handleOpenEditBusinessUnitDialog}>
                Business Unit
              </ButtonBase>
            </TableCell>
            <TableCell>
              {/* <Link component={Link} to={`/manager/people/${uid}`}> */}
              {sbu.name}
              {/* </Link> */}
            </TableCell>
          </TableRow>
          <Dialog
            open={businessUnitDialogOpen}
            onClose={handleCloseEditBusinessUnitDialog}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            sbus={sbus}
          >
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
              Update Business Unit
            </DialogTitle>

            <UpdateBusinessUnit
              sbus={sbus}
              setSbu={setSbu}
              sbu={sbu}
              updateQuoteInfo={updateQuoteInfo}
            />
          </Dialog>
          <TableRow>
            <TableCell>Created Date</TableCell>
            <TableCell>
              {moment(quote.createdAt).format("MM/DD/YYYY HH:MM")}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Proposed By</TableCell>
            <TableCell>{createdByName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Sent</TableCell>
            <TableCell>
              {quote.sent && quote.sentAt
                ? moment(quote.sentAt).format("MM/DD/YYYY HH:MM")
                : "Not Sent"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Expires</TableCell>
            <TableCell>
              {moment(quote.expiresAt).format("MM/DD/YYYY")}
              {/* Use another picker. This is out of date and also may not SSR */}
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  size="small"
                  id="update-expiration-date"
                  label="Expiration Date"
                  value={expirationDate}
                  onChange={handleExpirationDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider> */}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              <Switch
                checked={status}
                onChange={event => setStatus(event.target.checked)}
                name="QuoteStatus"
                color="primary"
                inputProps={{ "aria-label": "Quote Status" }}
              />
              {quote.isActive ? "Active" : "Not Active"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Likelihood</TableCell>
            <TableCell>
              <Rating
                name="Quote Likelihood"
                max={5}
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue)
                }}
              />

              {/* {order.orderWithItem && order.orderWithItem.length} */}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Email Proposal To:</TableCell>
            <TableCell>
              {isPrereg ? (
                preregEmail
              ) : user && userPersonEmail && userPersonEmail.length > 1 ? (
                <TextField
                  fullWidth
                  name="option"
                  size="small"
                  onChange={event => setSendEmailTo(event.target.value)}
                  select
                  SelectProps={{ native: true }}
                  value={sendEmailTo}
                  variant="outlined"
                >
                  <option value={"Change This"}>{sendEmailTo}</option>
                  {userPersonEmail.map(email => (
                    <option key={email.id} value={email.emailAddress}>
                      {email.emailAddress}
                    </option>
                  ))}
                </TextField>
              ) : (
                userEmail
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Dialog
        open={previewEmail}
        onClose={() => setPreviewEmail(false)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        quote={quote}
        dividers={true}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Preview Email Quote
        </DialogTitle>
        <PreviewQuote quote={quote} sendToDefault={sendToDefault} user={user} />
      </Dialog>
      <CardActions>
        {previewEmail ? (
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => sendQuote}
          >
            <PreviewIcon className={classes.actionIcon} />
            Send Preview
          </Button>
        ) : (
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => setPreviewEmail(true)}
          >
            <PreviewIcon className={classes.actionIcon} />
            Preview Email Proposal
          </Button>
        )}
      </CardActions>
      <CardActions>
        <Button fullWidth variant="outlined" color="primary">
          <SendIcon className={classes.actionIcon} />
          Send Proposal
        </Button>
      </CardActions>
    </Card>
  )
}

ProposalInfo.propTypes = {
  className: PropTypes.string,
}
const FORM_INFO_QUERY = gql`
  query {
    categories {
      id
      name
    }
    pricingModels {
      id
      name
    }
    sbus {
      id
      name
    }
  }
`
const UPDATE_RATING = gql`
  mutation ($quoteId: Int, $likelihood: Int) {
    updateQuoteRating(quoteId: $quoteId, likelihood: $likelihood) {
      quote {
        id
        likelihood
      }
    }
  }
`
const UPDATE_STATUS = gql`
  mutation ($quoteId: Int, $isActive: Boolean) {
    updateQuoteStatus(quoteId: $quoteId, isActive: $isActive) {
      quote {
        id
        isActive
      }
    }
  }
`
const UPDATE_QUOTE_INFO = gql`
  mutation ($quoteId: Int, $businessUnitId: Int, $expiresAt: DateTime) {
    updateQuoteInfo(
      quoteId: $quoteId
      businessUnitId: $businessUnitId
      expiresAt: $expiresAt
    ) {
      quote {
        id
        isActive
        expiresAt
        businessUnit {
          id
          name
        }
      }
    }
  }
`

const SEND_QUOTE = gql`
  mutation (
    $personUid: String!
    $quoteId: Int!
    $sendTo: String
    $preview: Boolean
  ) {
    sendQuote(
      personUid: $personUid
      quoteId: $quoteId
      sendTo: $sendTo
      preview: $preview
    ) {
      quote {
        id
        createdBy {
          id
          email
        }
        createdAt
        sent
        sentAt
        modifiedAt
        expiresAt
        isActive
        isPrereg
        businessUnit {
          id
          name
        }
        likelihood
        preRegQuoteUser {
          id
          preregUser {
            preregId
            preregEmail
          }
        }
        quoteForItems {
          id
          item {
            id
            name
          }
        }
      }
    }
  }
`
export default ProposalInfo
