import React, { useContext, useState } from "react"
import { Box, Container, makeStyles } from "@material-ui/core"
import Page from "../../../Global/Page"
import useIsMountedRef from "../../../../hooks/useIsMountedRef"
import Header from "./Header"
import Results from "./Results"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import SortContext from "../../../../context/SortContext"
import PerfectScrollbar from "react-perfect-scrollbar"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "91vh",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const PeopleListView = () => {
  const classes = useStyles()
  const isMountedRef = useIsMountedRef()
  const { state, dispatch } = useContext(SortContext)
  const [supplierFilter, setSupplierFilter] = useState(false)
  const search = state.search
  const page = state.page + 1
  const pageSize = state.pageSize

  const { loading, error, data } = useQuery(LIST_ALL_ORGS_QUERY, {
    variables: {
      search: search,
      page: page,
      pageSize: pageSize,
      supplierFilter: supplierFilter,
    },
    fetchPolicy: "cache-and-network",
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const orgs = data.orgs.objects
  const res = data.orgs

  return (
    <Page className={classes.root} title="Organization List">
      <PerfectScrollbar>
        <Container maxWidth={false}>
          <Header />
          {orgs && (
            <Box mt={3}>
              <Results
                res={res}
                supplierFilter={supplierFilter}
                setSupplierFilter={setSupplierFilter}
              />
            </Box>
          )}
        </Container>
      </PerfectScrollbar>
    </Page>
  )
}
export const LIST_ALL_ORGS_QUERY = gql`
  query (
    $page: Int
    $pageSize: Int
    $search: String
    $supplierFilter: Boolean
  ) {
    orgs(
      page: $page
      pageSize: $pageSize
      search: $search
      supplierFilter: $supplierFilter
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        ouid
        name
        nickname
        about
        dateAdded
        dateModified
        logo
        orgAdmin {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        orgSocialMedia {
          id
          socialMediaType {
            id
            name
            url
          }
          username
          url
        }
        people {
          uid
          firstName
          lastName
        }
        addressOrg {
          id
          address {
            uid
            region {
              id
              name
            }
            isOrg
            category {
              id
              name
            }
            summary
            attention
            addressLine1
            addressLine2
            addressLine3
            zone
            city
            postalCode
          }
        }
      }
    }
  }
`
export default PeopleListView
