import React from "react"

import { Box, ButtonBase, SvgIcon, makeStyles } from "@material-ui/core"

import { HelpCircle as HelpIcon } from "react-feather"
import { navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  icon: {
    height: 30,
    width: 30,
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  popover: {
    width: 200,
  },
}))

const Help = ({ user }) => {
  const classes = useStyles()
  const isStaff = user.staff
  const isManager = user.manager
  const navLink =
    isStaff && isManager
      ? "/manager/docs"
      : isStaff && !isManager
      ? "/staff/docs"
      : "/"
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={() => navigate(navLink)}
      >
        <SvgIcon className={classes.icon}>
          <HelpIcon />
        </SvgIcon>
      </Box>
    </>
  )
}

export default Help
