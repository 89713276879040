import React, { Fragment } from "react"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

import moment from "moment"

const accountBenefits = [
  "Place your order",
  "Ask a question about this proposal",
  "Calculate tax and shipping",
  "View mockups and sample photos",
  "Request any changes to your design",
  "Update your communications preferences",
  "View our proposal and ordering policy",
  "Request a new quote for something else",
]

const PreviewQuote = ({ classes, quote, user, sendToDefault }) => {
  const businessUnit = quote.businessUnit.name
  const toEmail = sendToDefault ? sendToDefault : "Please set an email address"
  const fromEmail = quote.businessUnit.primaryEmail
  const sentDateTime = quote.sentAt

  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }

  const emailSubheader = (
    <>
      <Typography>To: {toEmail} </Typography>
      <Typography>
        From: {businessUnit} ({fromEmail})
      </Typography>
      <Typography>
        {moment(sentDateTime).format("YYYY/MM/DD | hh:mm")}
      </Typography>
    </>
  )

  return (
    <DialogContent dividers={true} className={classes.content}>
      <Card>
        <CardHeader
          title={`Proposal from ${businessUnit}`}
          subheader={emailSubheader}
        />
        <Divider />
        <CardContent>
          <Typography>
            Hi
            {user.person && user.person.firstName
              ? " " + user.person.firstName + ","
              : ","}
          </Typography>
          <Typography gutterBottom>
            Thanks for your interest in {businessUnit}. Here's the customized
            proposal you requested.
          </Typography>

          {quote.quoteForItems &&
            quote.quoteForItems.map(i => {
              const itemName = i.item && i.item.name ? i.item.name : ""
              const isPrivate = i.item.isPrivate
              const pricingModelId = i.item.pricingModel.id
              const category =
                i.item && i.item.category && i.item.category.name
                  ? i.item.category.name
                  : ""
              const pricingModel =
                i.item && i.item.pricingModel && i.item.pricingModel.name
                  ? i.item.pricingModel.name
                  : ""
              const variablePrices = (
                <>
                  <Table>
                    {i.item.itemPrice &&
                      i.item.itemPrice.map(ip => (
                        <TableRow key={ip.id}>
                          <TableCell>
                            {ip.minQuantity} - {ip.maxQuantity}pcs{" "}
                          </TableCell>
                          <TableCell>${financial(ip.price)} ea</TableCell>
                        </TableRow>
                      ))}
                  </Table>
                </>
              )
              const standardPrices = (
                <Typography>
                  {i.item.itemBasePrice && i.item.itemBasePrice.basePrice
                    ? `${financial(i.item.itemBasePrice.basePrice)} ea`
                    : ""}
                </Typography>
              )
              const optionsPrices = (
                <Table>
                  <TableBody>
                    {i.item.itemPrice
                      ? i.item.itemPrice.map(ip => (
                          <TableRow key={ip.id}>
                            <TableCell>{ip.name ? ip.name : ""}</TableCell>
                            <TableCell>${financial(ip.price)} ea</TableCell>
                          </TableRow>
                        ))
                      : ""}
                  </TableBody>
                </Table>
              )
              const rawSpecs =
                i.item.specItem && i.item.specItem.summary
                  ? i.item.specItem.summary
                  : []
              const itemSpec = JSON.parse(rawSpecs)

              const sortedSpecs = Object.entries(itemSpec).sort((a, b) =>
                b[0].localeCompare(a[0])
              )

              const measurementFields = [
                "width",
                "height",
                "length",
                "depth",
                "thickness",
              ]
              const noteFields = ["notes", "note", "comments", "comment"]
              const specSize = []
              const specDetails = []
              const specNote = []

              for (const [key, value] of sortedSpecs) {
                let measurements = measurementFields.includes(key)
                if (measurements) console.log(value)
                let notes = noteFields.includes(key)
                let details = !measurements && !notes
                let format = (
                  <Fragment key={key}>
                    {isNaN(key.charAt(0)) &&
                      (key.charAt(0).toUpperCase() + key.slice(1)).replace(
                        /_/g,
                        " "
                      )}
                    :{" "}
                    {isNaN(value.charAt(0))
                      ? value.charAt(0).toUpperCase() + value.slice(1)
                      : value}
                    <br />
                  </Fragment>
                )
                if (measurements) specSize.push(format)
                if (notes) specNote.push(format)
                if (details) specDetails.push(format)
              }
              return (
                <Box key={i.id} mt={2}>
                  <Typography variant="h4">{itemName}</Typography>
                  <Typography>{category}</Typography>
                  <Typography>
                    {isPrivate
                      ? "Private item. Only you can order this item."
                      : "Public item. Anyone can order it."}
                  </Typography>
                  <Box className={classes.specSection}>
                    <Typography variant="body1">{specSize}</Typography>
                    <Typography variant="body1" gutterBottom>
                      {specDetails}
                    </Typography>
                    <Typography variant="body1">{specNote}</Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      * measurements are in inches
                    </Typography>
                  </Box>
                  <Typography>
                    {i.item && i.item.pricingModel
                      ? `Pricing model: ${pricingModel}`
                      : ""}
                  </Typography>
                  <Box>
                    {/* Variable/Quantity Pricing Model */}
                    {pricingModelId == 1
                      ? variablePrices
                      : {
                          /* Standard Pricing Model */
                        }(pricingModelId == 2 && i.item.itemBasePrice)
                      ? standardPrices
                      : {
                          /* Variable/Options pricing model */
                        }(pricingModelId == 3)
                      ? optionsPrices
                      : ""}
                  </Box>
                </Box>
              )
            })}
          <Box mt={2}>
            <Typography>
              For your convenience, we recommend logging in to your account to:{" "}
            </Typography>
            <List className={classes.list}>
              {accountBenefits.map((ab, index) => (
                <ListItem key={index}>
                  <ListItemText primary={ab} />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box mt={2}>
            <Divider />
            <Typography>File Attachments: </Typography>
            <List>
              {quote.quoteForItems &&
                quote.quoteForItems.map(item => (
                  <ListItem key={item.id}>
                    <List>
                      {item.quoteItemSampleProofs &&
                        item.quoteItemSampleProofs.map(proof => (
                          <ListItem key={proof.id} dense>
                            <ListItemAvatar>
                              <Avatar
                                variant="rounded"
                                src={proof.thumbnailUrl}
                                alt={`Version ${proof.version}`}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={`Proof version ${proof.version}`}
                            />
                          </ListItem>
                        ))}
                    </List>
                    <List>
                      {item.quoteItemImages &&
                        item.quoteItemImages.map(image => (
                          <ListItem key={Image.id} dense>
                            <ListItemAvatar>
                              <Avatar
                                variant="rounded"
                                src={image.thumbnailUrl}
                                alt={image.title}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={image.title} />
                          </ListItem>
                        ))}
                    </List>
                  </ListItem>

                  //   quoteItemSampleProofs &&
                  // quote.quoteForItems.quoteItemSampleProofs.map(proof => (
                  //   <ListItem key={proof.id} dense>
                  //     {JSON.stringify(proof)}
                  //     {/* <ListItemAvatar>
                  //       <Avatar
                  //         variant="rounded"
                  //         src={proof.thumbnailUrl}
                  //         alt={`Version ${proof.version}`}
                  //       />
                  //     </ListItemAvatar>
                  //     <ListItemText primary={`Proof version ${proof.version}`} /> */}
                  //   </ListItem>
                  // ))}
                ))}
            </List>
            {/* <List>
              {quote.quoteForItems &&
                quote.quoteForItems.quoteItemImages &&
                quote.quoteForItems.quoteItemImages.map(image => (
                  <ListItem key={image.id} dense>
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        src={image.thumbnailUrl}
                        alt={image.title}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={image.title} />
                  </ListItem>
                ))}
            </List> */}
          </Box>
        </CardContent>
      </Card>
    </DialogContent>
  )
}
const styles = theme => ({
  root: {},
  list: {
    marginLeft: theme.spacing(3),
  },
  specSection: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: 0,
  },
})

export default withStyles(styles)(PreviewQuote)
