import React, { useState, useContext } from "react"
import { Link, navigate } from "gatsby"
import {
  Box,
  Button,
  ButtonGroup,
  ButtonBase,
  Card,
  CardActionArea,
  CardHeader,
  CardContent,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"

import {
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
  AlertTriangle as SpamIcon,
  CheckSquare as CheckSquareIcon,
  Gift as SamplesIcon,
} from "react-feather"
import withStyles from "@material-ui/core/styles/withStyles"
import SortContext from "../../../../context/SortContext"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import ReactMarkdown from "react-markdown"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

const sortOptions = [
  {
    value: "newestFirst",
    label: "Newest first",
  },
  {
    value: "oldestFirst",
    label: "Oldest first",
  },
]

const Results = ({
  classes,
  res,
  docsCategoryFilter,
  setDocsCategoryFilter,
  docsTagsFilter,
  setDocsTagsFilter,
  viewDetails,
  setViewDetails,
  setDocId,
}) => {
  const { state, dispatch } = useContext(SortContext)
  const [categoryList, setCategoryList] = useState([])

  const [tagList, setTagList] = useState([])
  const [newArray, setNewArray] = useState([])
  const page = state.page
  const pageSize = state.pageSize
  const pageSizes = [5, 10, 15, 20]

  const searchContext = state.search
  const [searchBar, setSearchBar] = useState(searchContext)
  const resCount = res.count
  const resPage = res.page
  const resPages = res.pages
  const docsList = res.objects
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  const [sort, setSort] = useState(sortOptions[0].value)

  const { data, loading, error } = useQuery(DOCS_LIST_OPTIONS_QUERY, {
    onCompleted: data => {
      const categoryListData =
        data && data.docsCategories ? data.docsCategories : []
      const tagListData = data && data.docsTags ? data.docsTags : []
      setCategoryList(categoryListData)
      setTagList(tagListData)
    },
  })

  const handleRemoveTag = (tagId, event) => {
    const filteredArray = docsTagsFilter.filter(t => t !== tagId)
    setDocsTagsFilter(filteredArray)
  }

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
  }
  const handleCategoryChange = event => {
    event.persist()

    let value = 0

    if (event.target.value !== 0) {
      value = event.target.value
    }

    setDocsCategoryFilter(value)
  }

  const handleTagsChange = event => {
    setDocsTagsFilter(event.target.value)
  }
  const handleChangePage = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
  }

  const handlePageSizeChange = size => {
    dispatch({ type: "SET_PAGE_SIZE", payload: size })
  }
  return (
    <div className={classes.root}>
      <Paper
        className={classes.filterBox}
        variant="outlined"
        elevation={0}
        minHeight={120}
        display="flex"
        alignItems="center"
      >
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item xs={10} sm={5}>
            <Paper
              component="form"
              onSubmit={event => {
                event.preventDefault()
                handleSearch(event)
              }}
              className={classes.searchField}
            >
              <InputBase
                fullWidth
                className={classes.input}
                placeholder="Search Docs"
                inputProps={{ "aria-label": "Search Docss" }}
                onChange={event => setSearchBar(event.target.value)}
                value={searchBar}
              />
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                color="primary"
                className={classes.iconButton}
                aria-label="clear"
                onClick={handleClearSearchResults}
              >
                <CancelIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              //   className={classes.businessUnitField}
              label="Category"
              name="category"
              onChange={handleCategoryChange}
              select
              SelectProps={{ native: true }}
              value={docsCategoryFilter}
              variant="outlined"
            >
              <option value={0}>All Categories</option>
              {categoryList.map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl color="primary" variant="outlined" fullWidth>
              <InputLabel id="tags-mutiple-checkbox-label">Tags</InputLabel>
              <Select
                labelId="tags-mutiple-checkbox-label"
                id="tags-mutiple-checkbox"
                select
                label="Tags"
                multiple
                value={docsTagsFilter}
                onChange={handleTagsChange}
                // input={<Input />}
                renderValue={selected =>
                  tagList
                    .filter(tag => tag.id in selected)
                    .map(t => t.name)
                    .join(", ")
                }
                MenuProps={MenuProps}
              >
                {tagList.map(tag => (
                  <MenuItem key={tag.id} value={tag.id}>
                    {/* <Checkbox checked={selected} /> */}
                    <ListItemText primary={tag.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} component="ul" className={classes.chipList}>
              {docsTagsFilter &&
                docsTagsFilter.length > 0 &&
                docsTagsFilter.map(tagId => {
                  const t = tagList.find(element => element.id === tagId)
                  return (
                    <li key={tagId}>
                      <Chip
                        className={classes.tagChip}
                        size="small"
                        label={t ? t.name : ""}
                        clickable
                        color="primary"
                        variant="outlined"
                        onDelete={e => handleRemoveTag(tagId)}
                        deleteIcon={<CancelIcon />}
                      />
                    </li>
                  )
                })}
            </Paper>
          </Grid>

          <Grid item xs={12} sm={3}>
            {resPages > 1 ? (
              <Pagination
                count={resPages}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
                size="small"
                defaultPage={1}
              />
            ) : (
              <Typography>Page 1 of 1</Typography>
            )}
          </Grid>
          <Grid item sm={6}></Grid>
          <Grid item xs={12} sm={3}>
            <ButtonGroup
              fullWidth
              size="small"
              aria-label="small outlined button group"
            >
              {pageSizes.map(size => (
                <Button
                  key={size}
                  variant={size === pageSize ? "contained" : "outlined"}
                  onClick={() => handlePageSizeChange(size)}
                >
                  {size}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
        </Grid>
      </Paper>

      <div>
        {res.objects.map(doc => {
          const documentId = doc.id
          const excerpt = doc.body.slice(0, 100)

          return (
            <Card key={doc.id} className={classes.card}>
              <CardActionArea component={Link} to={`./${doc.id}`}>
                <CardHeader
                  title={doc.title}
                  subheader={doc.category.name}
                  titleTypographyProps={{ style: { fontSize: "26px" } }}
                />
              </CardActionArea>
              <CardContent>
                <ReactMarkdown className={classes.body}>
                  {excerpt}
                </ReactMarkdown>
                {doc.body.length > 100 && (
                  <Box mt={2}>
                    <Typography gutterBottom>
                      <Link to={`./${doc.id}`} className={classes.link}>
                        Read More
                      </Link>
                    </Typography>
                  </Box>
                )}
                <Divider className={classes.divider} />
                <Paper
                  elevation={0}
                  component="ul"
                  className={classes.chipList}
                >
                  {doc.tags &&
                    doc.tags.map(tag => (
                      <li key={tag.id}>
                        <Chip
                          className={classes.tagChip}
                          size="small"
                          label={tag.name}
                          clickable
                          color="primary"
                          variant="outlined"
                        />
                      </li>
                    ))}
                </Paper>
              </CardContent>
            </Card>
          )
        })}
      </div>
    </div>
  )
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  filterBox: {
    padding: theme.spacing(2),
    borderRadius: "15px",
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    // fullWidth: true,
  },
  card: {
    marginTop: theme.spacing(2),
    borderRadius: "15px",
  },
  docTitle: {
    fontSize: "22px",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  body: {
    fontFamily: theme.typography.fontFamily,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.link,
  },
  chipList: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  tagChip: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    "& h1": {
      marginBottom: theme.spacing(3),
    },
    "& h2": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& p": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& li": {
      marginLeft: theme.spacing(4),
    },
  },
})

const DOCS_LIST_OPTIONS_QUERY = gql`
  query {
    docsTags {
      id
      name
    }
    docsCategories {
      id
      name
    }
  }
`

export default withStyles(styles)(Results)
