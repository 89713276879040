import React, { createContext, useEffect } from "react"

const SupplierOrderContext = createContext({
  page: 1,
  pageSize: 10,
  pageCount: null,
  search: "",
})

export default SupplierOrderContext
