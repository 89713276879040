import React, { createContext } from "react"

const ProjectContext = createContext({
  todoList: false,
  taskDetails: false,

  tagOptions: [],
  categoryOptions: [],
  staffList: [],
  isSubmitting: false,

  title: "",
  startDate: "",
  dueDate: "",
  completed: false,
  completedDate: "",
  clientProject: false,
  projectManagerId: null,
  objective: "",
  priority: 1,
  categoryId: 2,
  projectTags: [],
  value: null,
  budget: null,

  partOfPlan: false,
  planId: null,

  page: 1,
  pageSize: 10,
  search: "",
})
export default ProjectContext
