import React, { useCallback, useEffect, useState } from "react"
import { Box, Container, Grid, makeStyles } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import Page from "../../../Global/Page"
import Header from "./Header"
import CartInfo from "./CartInfo"
import CartItems from "./CartItems"
// import AddOrderItem from "./AddOrderItem"
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const CartDetailsView = ({ id, className, ...rest }) => {
  const classes = useStyles()
  const [cartDetails, setCartDetails] = useState("")
  const [addCartItem, setAddCartItem] = useState(false)
  const [search, setSearch] = useState(false)
  const [createNew, setCreateNew] = useState(false)
  const [items, setItems] = useState([])

  const { data, loading, error } = useQuery(CART_DETAILS_QUERY, {
    variables: {
      cartId: id,
    },
    onCompleted: (data, response, errors) => {
      console.log(data)
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const cart = data.cart

  return (
    <Page className={classes.root} title="Cart Details">
      <Container maxWidth={false}>
        <Header cart={cart} />
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item md={4} xl={3} xs={12}>
              <CartInfo cart={cart} />
            </Grid>
            <Grid item md={8} xl={9} xs={12}>
              <CartItems cart={cart} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

const CART_DETAILS_QUERY = gql`
  query ($cartId: String) {
    cart(cartId: $cartId) {
      id
      cartId
      person {
        uid
        firstName
        lastName

        emailPerson {
          id
          emailAddress
        }
      }
      items
      totalItems
      totalUniqueItems
      started
      latestChange
      businessUnit {
        id
        name
      }
      cartOpen
      cartExpired
      expirationDate
    }
  }
`

export default CartDetailsView
