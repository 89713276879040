import React, { useState, useRef, useContext } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { Link } from "gatsby"
import { Pagination } from "@material-ui/lab"
// import green from "@material-ui/core/color/green"
import { blue, deepOrange, green } from "@material-ui/core/colors"
import CategoryIcon from "@material-ui/icons/Category"
import ProjectContext from "../context/ProjectContext"

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  card: {
    marginTop: theme.spacing(2),
    borderRadius: "15px",
  },
  title: {
    position: "relative",
    "&:after": {
      position: "absolute",
      bottom: -8,
      left: 0,
      content: '" "',
      height: 3,
      width: 48,
      backgroundColor: theme.palette.primary.main,
    },
  },
  tagChip: {
    marginRight: theme.spacing(1),
  },
  sortButton: {
    textTransform: "none",
    letterSpacing: 0,
    marginRight: theme.spacing(2),
  },
  avatar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary,
  },
  pmAvatar: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
  taskNumber: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
  },
  categoryAvatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}))

function Results({ res, className, ...rest }) {
  const classes = useStyles()
  const { state, dispatch } = useContext(ProjectContext)
  const pages = res.pages
  const projects = res.objects
  const page = state.page
  const [setOpenSort] = useState(false)
  const [setSelectedSort] = useState("Most popular")
  const [setMode] = useState("grid")

  const handlePageChange = (event, value) => {
    dispatch({ type: "SET_PAGE_CHANGE", payload: value })
  }

  // const handleSortOpen = () => {
  //   setOpenSort(true)
  // }

  // const handleSortClose = () => {
  //   setOpenSort(false)
  // }

  // const handleSortSelect = value => {
  //   setSelectedSort(value)
  //   setOpenSort(false)
  // }

  // const handleModeChange = (event, value) => {
  //   setMode(value)
  // }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb={2}
      >
        <Typography className={classes.title} variant="h5" color="textPrimary">
          Found {res.objects.length} projects
        </Typography>
        <Box display="flex" alignItems="center">
          {/* <Button
            className={classes.sortButton}
            onClick={handleSortOpen}
            ref={sortRef}
          >
            selected sort 
            <ArrowDropDownIcon />
          </Button> */}
          {/* <ToggleButtonGroup
            exclusive
            onChange={handleModeChange}
            size="small"
            value={mode}
          >
            <ToggleButton value="grid">
              <ViewModuleIcon />
            </ToggleButton>
          </ToggleButtonGroup> */}
        </Box>
      </Box>
      <Grid container spacing={3}>
        {projects.map(project => {
          const excerpt = project.objective
            ? project.objective.slice(0, 200)
            : ""
          const ellipsis =
            project.objective.length > excerpt.length ? "..." : ""
          return (
            <Grid item key={project.id} xs={12} sm={4}>
              <Card className={classes.card}>
                <CardActionArea
                  component={Link}
                  to={`./${project.id}`}
                  state={{ id: project.id }}
                >
                  <CardHeader
                    title={project.title}
                    subheader={
                      <>
                        <Typography>
                          {project.completed
                            ? `Completed ${moment(project.completedDate).format(
                                "MMM Do, YYYY"
                              )}`
                            : `Due: ${moment(project.dueDate).format(
                                "MMM Do, YYYY"
                              )}`}
                        </Typography>
                        {project.clientProject && (
                          <Typography>Client Project</Typography>
                        )}
                      </>
                    }
                    titleTypographyProps={{ style: { fontSize: "26px" } }}
                  />
                </CardActionArea>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <List>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar
                              variant="rounded"
                              className={clsx(classes.avatar, classes.pmAvatar)}
                            >
                              PM
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              project.projectManager
                                ? project.projectManager.person.firstName +
                                  " " +
                                  project.projectManager.person.lastName
                                : "No Project Manager"
                            }
                            secondary="Project Manager"
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar
                              variant="rounded"
                              className={clsx(
                                classes.categoryAvatar,
                                classes.avatar
                              )}
                            >
                              <CategoryIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              project.category && project.category.name
                                ? project.category.name
                                : "No Assigned Category"
                            }
                            secondary="Category"
                          />
                        </ListItem>
                        {project.projectTask && project.projectTask.length > 0 && (
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar
                                variant="rounded"
                                className={clsx(
                                  classes.taskNumber,
                                  classes.avatar
                                )}
                              >
                                {project.projectTask
                                  ? project.projectTask.length
                                  : "0"}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                project.projectTask
                                  ? project.projectTask.length + " " + "Tasks"
                                  : "No Tasks"
                              }
                            />
                          </ListItem>
                        )}
                      </List>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Typography variant="body1">
                          {excerpt}
                          {ellipsis}
                        </Typography>
                      </Box>
                      {project.tags && (
                        <Box mt={2}>
                          {project.tags &&
                            project.tags.map(tag => (
                              <Chip
                                key={tag.id}
                                variant="outlined"
                                color="primary"
                                label={tag.name}
                                className={classes.tagChip}
                              />
                            ))}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
      {pages.length > 1 && (
        <Box mt={6} display="flex" justifyContent="center">
          <Pagination count={pages} page={page} onChange={handlePageChange} />
        </Box>
      )}
      {/* <Menu
        anchorEl={sortRef.current}
        className={classes.menu}
        onClose={handleSortClose}
        open={openSort}
        elevation={1}
      >
        {["Most recent", "Popular", "Price high", "Price low", "On sale"].map(
          option => (
            <MenuItem key={option} onClick={() => handleSortSelect(option)}>
              <ListItemText primary={option} />
            </MenuItem>
          )
        )}
      </Menu> */}
    </div>
  )
}

Results.propTypes = {
  className: PropTypes.string,
}

export default Results
