import React, { useCallback, useEffect, useState, useContext } from "react"
import { Box, Container, makeStyles } from "@material-ui/core"

import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import PerfectScrollbar from "react-perfect-scrollbar"
//   import axios from 'src/utils/axios';
//   import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Page from "../../../Global/Page"
import Header from "./Header"
import Results from "./Results"
import SortContext from "../../../../context/SortContext"
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "91vh",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

function InquiryListView() {
  const classes = useStyles()
  const { state, dispatch } = useContext(SortContext)
  // const isMountedRef = useIsMountedRef();
  // const [inquiries, setInquiries] = useState(null);
  const [recentFirstFilter, setRecentFirstFilter] = useState(true)
  const [businessUnitFilter, setBusinessUnitFilter] = useState(0)

  const { loading, error, data } = useQuery(INQUIRY_LIST_QUERY, {
    variables: {
      search: state.search,
      page: state.page + 1,
      pageSize: state.pageSize,
    },
    fetchPolicy: "cache-and-network",
  })

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const res = data.inquiries

  // useEffect(() => {
  //   getOrders();
  // }, [getOrders]);

  return (
    <Page className={classes.root} title="Inquiries">
      <PerfectScrollbar>
        <Container maxWidth={false}>
          <Header />
          <Box mt={3}>
            <Results
              res={res}
              recentFirstFilter={recentFirstFilter}
              setRecentFirstFilter={setRecentFirstFilter}
              businessUnitFilter={businessUnitFilter}
              setBusinessUnitFilter={setBusinessUnitFilter}
            />
          </Box>
        </Container>
      </PerfectScrollbar>
    </Page>
  )
}

const INQUIRY_LIST_QUERY = gql`
  query (
    $page: Int
    $pageSize: Int
    $search: String
    $samplesRequestedFilter: Boolean
    $businessUnitFilter: Int
    $unquotedFilter: Boolean
    $recentFirstFilter: Boolean
    $oldestFirstFilter: Boolean
  ) {
    inquiries(
      page: $page
      pageSize: $pageSize
      search: $search
      samplesRequestedFilter: $samplesRequestedFilter
      businessUnitFilter: $businessUnitFilter
      unquotedFilter: $unquotedFilter
      recentFirstFilter: $recentFirstFilter
      oldestFirstFilter: $oldestFirstFilter
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        inquiryUuid
        processedAt
        userInquiryPerson {
          uid
          firstName
          lastName
        }
        inquiryPerson {
          id

          firstName
          lastName
        }
        createdAt
        source
        spam
        new
        quoteCreated
        samplesRequested
        businessUnit {
          id
          name
        }
      }
    }
  }
`

export default InquiryListView
