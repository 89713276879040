import React from "react"
import clsx from "clsx"

import { Button, Box, TextField, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 2,
    marginBotton: 5,
  },
}))

const UpdatePricingModel = ({
  className,
  item,
  pricingModels,
  pricingModel,
  setPricingModel,
  setUpdateItem,
  updateItemInfo,
  isSubmitting,
  ...rest
}) => {
  const classes = useStyles()

  const handleUpdatePricingModel = event => {
    event.preventDefault()
    updateItemInfo()
  }

  return (
    <>
      <Box padding={5}>
        <form
          onSubmit={event => handleUpdatePricingModel(event)}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <TextField
            label="Select a Pricing Model"
            name="pricingModel"
            onChange={event => setPricingModel(event.target.value)}
            value={pricingModel}
            select
            margin="dense"
            size="small"
            fullWidth
            variant="outlined"
            SelectProps={{ native: true }}
          >
            <option></option>
            {pricingModels.map(p => (
              <option key={p.id} value={p}>
                {p.name}
              </option>
            ))}
          </TextField>
          <Box mt={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Update Pricing Model
            </Button>
          </Box>
        </form>
      </Box>
    </>
  )
}

export default UpdatePricingModel
