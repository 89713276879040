import React, { useState } from "react"
import CreateSamplesProofs from "./CreateSamplesProofs"
import SampleProofDetails from "./SampleProofDetails"

const SampleProofs = ({ item }) => {
  const [activeView, setActiveView] = useState("create")
  const [fileSet, setFileSet] = useState("")
  const [samplesProofs, setSamplesProofs] = useState(item.sampleProofItem)
  const getView = activeView => {
    switch (activeView) {
      case "details":
        return (
          <SampleProofDetails
            item={item}
            fileSet={fileSet}
            setFileSet={setFileSet}
            setActiveView={setActiveView}
            samplesProofs={samplesProofs}
            setSamplesProofs={setSamplesProofs}
          />
        )
      case "create":
        return (
          <CreateSamplesProofs
            item={item}
            setActiveView={setActiveView}
            setFileSet={setFileSet}
            samplesProofs={samplesProofs}
            setSamplesProofs={setSamplesProofs}
          />
        )
      default:
        return (
          <CreateSamplesProofs
            item={item}
            setActiveView={setActiveView}
            setFileSet={setFileSet}
            samplesProofs={samplesProofs}
            setSamplesProofs={setSamplesProofs}
          />
        )
    }
  }

  return <>{getView(activeView)}</>
}

export default SampleProofs
