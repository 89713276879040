import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  Typography,
  makeStyles,
} from "@material-ui/core"
import getInitials from "../../../../../utils/getInitials"
import MapIcon from "@material-ui/icons/Map"

const useStyles = makeStyles(theme => ({
  root: {},
  roundedCard: {
    borderRadius: "15px",
  },
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: 0,
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: "space-between",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
}))

function Holder({ project, className, ...rest }) {
  const classes = useStyles()
  const pmName =
    project.projectManager.person.firstName +
    " " +
    project.projectManager.person.lastName
  return (
    <Card
      className={clsx(classes.root, classes.roundedCard, className)}
      {...rest}
    >
      <CardHeader
        avatar={
          <Avatar
            alt="Project Manager"
            className={classes.avatar}
            // component={RouterLink}
            // src={project.projectManager.person.profileImage}
            // to="#"
          >
            {getInitials(pmName)}
          </Avatar>
        }
        className={classes.header}
        disableTypography
        subheader={
          <Typography variant="h5" color="textSecondary">
            <Link to="#" className={classes.link}>
              {pmName}
            </Link>
          </Typography>
        }
        title={
          <Typography display="block" variant="overline" color="textSecondary">
            Project Manager
          </Typography>
        }
        action={
          project.planProject ? (
            <IconButton aria-label="Plan">
              <MapIcon />
            </IconButton>
          ) : (
            ""
          )
        }
      />
      <CardContent className={classes.content}>
        <List>
          {project.completed && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2" color="textPrimary">
                Completed
              </Typography>
              <Typography variant="h6" color="textSecondary">
                {moment(project.completedDate).format("DD MMM YYYY")}
              </Typography>
            </ListItem>
          )}
          <ListItem className={classes.listItem} disableGutters divider>
            <Typography variant="subtitle2" color="textPrimary">
              Due Date
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {moment(project.dueDate).format("DD MMM YYYY")}
            </Typography>
          </ListItem>
          <ListItem className={classes.listItem} disableGutters divider>
            <Typography variant="subtitle2" color="textPrimary">
              Started
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {moment(project.createdDate).format("DD MMM YYYY")}
            </Typography>
          </ListItem>
          {project.clientProject && project.value && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2" color="textPrimary">
                Project Value
              </Typography>
              <Typography variant="h6" color="textSecondary">
                ${project.value}
              </Typography>
            </ListItem>
          )}
          {project.budget && (
            <ListItem className={classes.listItem} disableGutters divider>
              <Typography variant="subtitle2" color="textPrimary">
                Budget
              </Typography>
              <Typography variant="h6" color="textSecondary">
                ${project.budget && project.budget}
              </Typography>
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  )
}

Holder.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired,
}

export default Holder
