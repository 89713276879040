import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Breadcrumbs,
  Button,
  Box,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"

import {
  Edit as EditIcon,
  PlusCircle as AddIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
} from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 4,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  quoteButton: {
    marginLeft: 5,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
}))

const Header = ({ currentTab, tabs, className, ...rest }) => {
  const classes = useStyles()
  const tabLabel = tabs.find(t => t.value === currentTab).label
  const buttonLabel =
    currentTab === "orders"
      ? "Order"
      : currentTab === "batches"
      ? "Batch"
      : currentTab === "invoices"
      ? "Invoice"
      : ""
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link variant="body1" className={classes.link} to="/manager/">
            Dashboard
          </Link>
          <Link
            variant="body1"
            className={classes.link}
            to="/manager/supply/suppliers"
          >
            Suppliers
          </Link>
          <Typography variant="body1" color="textPrimary">
            Supplier {tabLabel}
          </Typography>
        </Breadcrumbs>
        <Box mt={2}>
          <Typography variant="h3" color="textPrimary">
            Supplier {tabLabel}
          </Typography>
        </Box>
      </Grid>

      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          className={classes.quoteButton}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <AddIcon />
          </SvgIcon>
          New Supplier {buttonLabel}
        </Button>
        <Box mt={2}>
          <Button className={classes.action}>
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <UploadIcon />
            </SvgIcon>
            Import
          </Button>
          <Button className={classes.action}>
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <DownloadIcon />
            </SvgIcon>
            Export
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  // supplierOrder: PropTypes.object.isRequired,
}

export default Header
