import React, { useState } from "react"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import Page from "../../Global/Page"
import Header from "./Header"

const Accounting = ({ classes }) => {
  const view = "managerial"
  function viewSwitch(view) {
    switch (view) {
      case "managerial":
        return "managerial view"
      case "bookkeeper":
        return "bookkeeper view"
      case "reports":
        return "reports view"
      case "splash":
        return "splash page"
      default:
        return "splash page"
    }
  }
  return (
    <Page className={classes.root} title="Accounting">
      <Container>
        <Header />
        <div> {viewSwitch(view)}</div>
      </Container>
    </Page>
  )
}

const styles = theme => ({
  root: {},
})

export default withStyles(styles)(Accounting)
