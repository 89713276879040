import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Radio,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
  Divider,
} from "@material-ui/core"

import moment from "moment"
import PerfectScrollbar from "react-perfect-scrollbar"

import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import PersonCreateForm from "../../../People/PeopleCreateView/PersonCreateForm"
// import SearchPersonConfirm from "./SearchPersonConfirm"

import {
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  UserCheck as CheckIcon,
  User as UserIcon,
  Search as SearchIcon,
  XSquare as CloseIcon,
  X as CancelIcon,
} from "react-feather"
import SortContext from "../../../../../context/SortContext"
import { SettingsConsumer } from "../../../../../context/SettingsContext"

const useStyles = makeStyles(theme => ({
  root: {},
  queryField: {
    margin: "dense",
    size: "small",
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))
const PrivateItemOwnerSearch = ({
  className,
  setPickPerson,
  setPerson,
  setItemOwnerId,
  setIsPrereg,
  ...rest
}) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(SortContext)
  const search = state.search
  const page = state.page
  const pageQ = state.page + 1
  const pageSize = state.pageSize

  const [searchBar, setSearchBar] = useState("")
  const [newPage, setNewPage] = useState(1)
  const [newPiOwner, setNewPiOwner] = useState(false)
  const [fromNewPerson, setFromNewPerson] = useState(false)

  const { loading, error, data } = useQuery(SEARCH_MATCHES_QUERY, {
    variables: {
      search: search,
      page: page,
      pageSize: pageSize,
    },

    fetchPolicy: "cache-and-network-only",
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const resCount = data.people.count
  const resPage = data.people.page
  const resPages = data.people.pages
  const people = data.people.objects
  const matches = data.people.objects

  const selectPerson = ({ match }) => {
    setPerson(match)
    setItemOwnerId(match.uid)
    {
      match.user ? setIsPrereg(false) : setIsPrereg(true)
    }
    dispatch({ type: "SET_SEARCH", payload: "" })
    setPickPerson(true)
  }

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
  }

  const handlePageChange = (event, newPage) => {
    dispatch({ type: "SET_PAGE", payload: newPage })
  }
  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  // if (fromNewPerson && match) {
  //   selectPerson()
  // }

  if (newPiOwner) {
    return (
      <PersonCreateForm
        // match={match}
        // setMatch={setMatch}
        newPiOwner={newPiOwner}
        setFromNewPerson={setFromNewPerson}
        fromNewPerson={fromNewPerson}
      />
    )
  }
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Paper
                component="form"
                onSubmit={event => {
                  event.preventDefault()
                  handleSearch(event)
                }}
                className={classes.searchField}
              >
                <InputBase
                  fullWidth
                  className={classes.input}
                  placeholder="Search People"
                  inputProps={{ "aria-label": "search people" }}
                  onChange={event => setSearchBar(event.target.value)}
                  value={searchBar}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={handleClearSearchResults}
                >
                  <CancelIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={event => setNewPiOwner(true)}
                >
                  Add a New Person
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <PerfectScrollbar>
            <Box minWidth={650}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Date Added</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matches.map(match => {
                    const emails = match.emailPerson
                    const phones = match.phonePerson
                    const addresses = match.addressPerson
                    const user = match.user
                    const uid = match.uid
                    return (
                      <TableRow hover key={match.uid}>
                        <TableCell padding="checkbox">
                          <IconButton onClick={e => selectPerson({ match }, e)}>
                            <SvgIcon fontSize="small">
                              <CheckIcon />
                            </SvgIcon>
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Typography variant="body2" color="textSecondary">
                              {match.firstName} {match.lastName}
                            </Typography>
                            {user && (
                              <SvgIcon fontSize="small" color="secondary">
                                <UserIcon />
                              </SvgIcon>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {emails.map(email => (
                            <div key={email.id}>{email.emailAddress}</div>
                          ))}
                        </TableCell>
                        <TableCell>
                          {phones.map(phone => (
                            <div key={phone.id}>{phone.phoneNumber}</div>
                          ))}
                        </TableCell>
                        <TableCell>
                          {addresses.map(address => (
                            <div key={address.id}>{address.city}</div>
                          ))}
                        </TableCell>
                        <TableCell>
                          {moment(match.dateAdded).format("MMM DD YYYY")}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        </Box>
        <TablePagination
          component="div"
          count={resCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </Card>
    </>
  )
}

export const SEARCH_MATCHES_QUERY = gql`
  query ($page: Int!, $pageSize: Int!, $search: String) {
    people(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        uid
        firstName
        lastName
        middleName
        suffix
        nickname
        title
        about
        userCanUpdate
        isSupplier
        dateAdded
        dateModified
        phonePerson {
          id
          phoneNumber
        }
        addressPerson {
          id
          address {
            uid
            category {
              id
              name
            }
            summary
          }
        }

        emailPerson {
          id
          emailAddress
        }
        user {
          id
          email
        }
      }
    }
  }
`

export default PrivateItemOwnerSearch
