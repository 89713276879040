import React, { useState } from "react"
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"

const NotifyCustomer = ({ classes, item, fileSet, setNotificationDialog }) => {
  const itemName = item.name
  const fileSetId = fileSet.id
  const { data, loading, error } = useQuery(FORM_INFO_QUERY)
  const [serviceCategoryId, setServiceCategoryId] = useState(2)
  const [businessUnitId, setBusinessUnitId] = useState(1)
  const [subject, setSubject] = useState("Please check a new sample or proof")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [returnMessage, setReturnMessage] = useState("Your message was sent")
  const [alert, setAlert] = useState(false)
  const [messageSent, setMessageSent] = useState(false)
  const [success, setSuccess] = useState(false)
  const [messageBody, setMessageBody] = useState(
    `A new ${
      fileSet.isPrototypePhoto
        ? "Prototype / Sample Photo"
        : "Proof / Art / Design file"
    } is available for your item ${itemName}. `
  )
  const handleSubmit = () => {
    setIsSubmitting(true)
    handleSend()
  }
  const [handleSend] = useMutation(SEND_MESSAGE_MUTATION, {
    variables: {
      categoryId: serviceCategoryId,
      sbuId: businessUnitId,
      sampleProofId: fileSetId,
      topic: subject,
      message: messageBody,
    },
    onCompleted: data => {
      setMessageSent(data.sampleProofsScanNotification.messageSent)
      setReturnMessage(data.sampleProofsScanNotification.returnMessage)
      setIsSubmitting(false)

      setAlert(true)
    },
  })

  if (loading) return <Loading />
  if (error) return "error..."
  const businessUnits = data.sbus
  const serviceCategories = data.serviceCategories
  return (
    <>
      <DialogTitle id="alert-dialog-title">Send a notification</DialogTitle>
      <form
        onSubmit={event => {
          event.preventDefault()
          handleSubmit(event)
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Send a new notification to alert the customer that this file is
            ready for viewing and feedback.
          </DialogContentText>
          <TextField
            id="subject"
            label="Subject"
            variant="outlined"
            size="small"
            margin="normal"
            fullWidth
            value={subject}
            onChange={event => setSubject(event.target.value)}
          >
            {subject}
          </TextField>
          <TextField
            id="message"
            label="Message"
            multiline
            rows={4}
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={messageBody}
            onChange={event => setMessageBody(event.target.value)}
          >
            {messageBody}
          </TextField>
          <Box m={2}>
            <Typography variant="body2">
              Do not provide a link or instructions to download or view files.
              The link and instructions will auto-generate and will appear at
              the end of the message.
            </Typography>
          </Box>
          <TextField
            label="Message Category"
            name="serviceCategory"
            onChange={event => setServiceCategoryId(event.target.value)}
            select
            margin="dense"
            size="small"
            fullWidth
            variant="outlined"
            SelectProps={{ native: true }}
            value={serviceCategoryId}
          >
            <option></option>
            {serviceCategories.map(cat => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </TextField>
          <TextField
            label="Select a Business Unit"
            name="businessUnit"
            onChange={event => setBusinessUnitId(event.target.value)}
            select
            margin="dense"
            size="small"
            fullWidth
            variant="outlined"
            SelectProps={{ native: true }}
            value={businessUnitId}
          >
            <option></option>
            {businessUnits.map(b => (
              <option key={b.id} value={b.id}>
                {b.name}
              </option>
            ))}
          </TextField>
        </DialogContent>

        <DialogActions>
          {success ? (
            <Button
              onClick={() => setNotificationDialog(false)}
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isSubmitting}
              >
                Send
              </Button>
              <Button
                onClick={() => setNotificationDialog(false)}
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
            </>
          )}
        </DialogActions>
        <Box m={2}>
          {alert && (
            <Alert
              severity={messageSent ? "success" : "warning"}
              onClose={() => setAlert(false)}
            >
              <AlertTitle>
                {messageSent ? "Message Sent" : "Message Not Sent"}
              </AlertTitle>
              {returnMessage}
            </Alert>
          )}
        </Box>
      </form>
      {isSubmitting && <Loading />}
    </>
  )
}

const FORM_INFO_QUERY = gql`
  query {
    serviceCategories {
      id
      name
      description
    }
    sbus {
      id
      name
      homepage
    }
  }
`
const SEND_MESSAGE_MUTATION = gql`
  mutation (
    $categoryId: Int!
    $sbuId: Int!
    $sampleProofId: Int!
    $topic: String
    $message: String
  ) {
    sampleProofsScanNotification(
      categoryId: $categoryId
      sbuId: $sbuId
      sampleProofId: $sampleProofId
      topic: $topic
      message: $message
    ) {
      returnMessage
      messageSent
      conversation {
        uuid
        startedBy {
          id
          email
        }
        participants {
          id
          email
        }
        invited {
          id
          email
        }
        category {
          id
          name
        }
        topic
        sbu {
          id
          name
        }
      }
      conversationMessage {
        uuid
        messageFile
        messageFileUrl
        messageFrom {
          id
          email
        }
        message
      }
    }
  }
`
const styles = theme => ({
  root: {},
})
export default withStyles(styles)(NotifyCustomer)
