import React, { useState, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import DoneIcon from "@material-ui/icons/Done"
import useDidMountEffect from "../../../../../Global/hooks/useDidMountEffect"

import {
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

const UpdateOrgSocialMedia = ({ classes, org, socials, setSocials }) => {
  const ouid = org.ouid

  const [username, setUsername] = useState("")
  const [id, setId] = useState(null)

  const [socialMediaTypes, setSocialMediaTypes] = useState([])
  const [socialMediaType, setSocialMediaType] = useState(1)
  const [socialMediaTypeId, setSocialMediaTypeId] = useState(1)
  const [socialMediaTypeUrl, setSocialMediaTypeUrl] = useState(
    "https://www.twitter.com"
  )

  const [url, setUrl] = useState("")
  const [newArray, setNewArray] = useState([])

  const { data, loading, error } = useQuery(SOCIAL_MEDIA_TYPES_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      setSocialMediaTypes(data.socialMediaTypes)
    },
  })
  const [deleteSocialMediaAccountOrg] = useMutation(
    DELETE_ORG_SOCIAL_MEDIA_MUTATION,
    {
      variables: {
        id: id,
      },
      onCompleted: () => {
        setSocials(newArray)
        console.log("delete completed")
      },
    }
  )
  const [createSocialMediaAccountOrg] = useMutation(
    ADD_ORG_SOCIAL_MEDIA_MUTATION,
    {
      variables: {
        orgOuid: ouid,
        username: username,
        socialMediaTypeId: socialMediaTypeId,
        url: url,
      },
      onCompleted: data => {
        const accountValue =
          data.createSocialMediaAccountOrg.socialMediaAccountOrg
        setSocials(prevSelected => [...prevSelected, accountValue])
        setUsername("")
        setUrl("")
      },
    }
  )
  const handleFormSocialMedia = event => {
    createSocialMediaAccountOrg()
  }
  const handleSocialMediaTypeChange = event => {
    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
    }
    const smtype = socialMediaTypes.filter(x => x.id == value).pop()
    setSocialMediaTypeUrl(smtype.url)
    setSocialMediaTypeId(value)
    setSocialMediaType(value)
  }

  const handleDelete = account => {
    const filteredArray = socials.filter(x => x.id !== account.id)
    setNewArray(filteredArray)
    setId(account.id)
  }

  useDidMountEffect(() => {
    deleteSocialMediaAccountOrg()
  }, [id])

  useEffect(() => {
    const fullUrl = `${socialMediaTypeUrl}/${username}`
    setUrl(fullUrl)
  }, [username, socialMediaTypeId])

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography>Org Social Media Accounts</Typography>

          {socials.length === 0 ? (
            ""
          ) : (
            <>
              {socials.map(account => (
                <Box key={account.id}>
                  <Chip
                    icon={<DoneIcon />}
                    label={account.username}
                    variant="outlined"
                    onDelete={e => handleDelete(account)}
                  />
                </Box>
              ))}
            </>
          )}
        </Grid>
        <Grid item sm container direction="column">
          <form
            onSubmit={event => {
              event.preventDefault()
              handleFormSocialMedia(event)
            }}
            className={classes.root}
          >
            <Grid item xs={12}>
              <TextField
                name="socialMediaType"
                onChange={handleSocialMediaTypeChange}
                select
                margin="dense"
                size="small"
                fullWidth
                variant="outlined"
                SelectProps={{ native: true }}
                value={socialMediaType}
              >
                {socialMediaTypes.map(smt => (
                  <option key={smt.id} value={smt.id}>
                    {smt.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Username"
                margin="normal"
                name="username"
                onChange={event => setUsername(event.target.value)}
                type="text"
                value={username}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="URL"
                margin="normal"
                name="url"
                onChange={event => setUrl(event.target.value)}
                type="url"
                value={url}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                size="medium"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                aria-label="add social media acct"
                className={classes.margin}
              >
                Add Social Media Account
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  )
}

const ADD_ORG_SOCIAL_MEDIA_MUTATION = gql`
  mutation (
    $orgOuid: String!
    $socialMediaTypeId: Int!
    $username: String!
    $url: String
  ) {
    createSocialMediaAccountOrg(
      orgOuid: $orgOuid
      socialMediaTypeId: $socialMediaTypeId
      username: $username
      url: $url
    ) {
      socialMediaAccountOrg {
        id
        username
        socialMediaType {
          id
          name
          url
        }
        url
      }
    }
  }
`

const DELETE_ORG_SOCIAL_MEDIA_MUTATION = gql`
  mutation ($id: Int!) {
    deleteSocialMediaAccountOrg(id: $id) {
      id
    }
  }
`

const SOCIAL_MEDIA_TYPES_QUERY = gql`
  query {
    socialMediaTypes {
      id
      name
      url
    }
  }
`
UpdateOrgSocialMedia.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
}

UpdateOrgSocialMedia.default = {
  onSubmitSuccess: () => {},
}

const styles = theme => ({
  root: {},
  button: {
    margin: 10,
  },
  margin: {
    margin: 2,
  },
})

export default withStyles(styles)(UpdateOrgSocialMedia)
