import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { makeStyles } from "@material-ui/core/styles"
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  // FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import DoneIcon from "@material-ui/icons/Done"
import UpdateTask from "./UpdateTask"
import moment from "moment"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  title: {
    marginLeft: theme.spacing(1),
  },
  classButton: {
    margin: theme.spacing(1),
  },
}))

const CompletedTasks = ({
  projectTasks,
  completedTasks,
  setCompletedTasks,
  incompleteTasks,
  setIncompleteTasks,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const [edit, setEdit] = useState(false)
  const [editTaskId, setEditTaskId] = useState({})
  const [selectedTaskId, setSelectedTaskId] = useState("")

  const [completedDate, setCompletedDate] = useState(null)
  const [minutesReported, setMinutesReported] = useState(0)
  const [submitting, setSubmitting] = useState(false)

  const [handleUncompleteTask, { loading, error }] = useMutation(
    UNCOMPLETE_TASK_MUTATION,
    {
      variables: {
        taskId: selectedTaskId,
        minutesReported: null,
        completedDate: null,
        taskCompleted: false,
      },
      onCompleted: data => {
        setSelectedTaskId("")

        setSubmitting(false)
        console.log("completed")
      },
    }
  )

  const handleComplete = taskId => {
    let filteredTasks = completedTasks.filter(ct => ct.id != taskId)
    let incompTask = projectTasks.find(pt => pt.id === taskId)
    setIncompleteTasks([...incompleteTasks, incompTask])
    setCompletedTasks(filteredTasks)
    setSubmitting(true)
    handleUncompleteTask()
  }
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <div className={classes.root}>
      {completedTasks
        // .filter(t => t.completed === false)
        .map(todo => {
          const taskId = todo.id
          return (
            <Accordion key={todo.id} id={todo.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="completed-task-details"
                id="completed-task-details-header"
              >
                <DoneIcon />
                <Typography className={classes.title}>
                  {todo.task.title}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography color="textSecondary">
                      {todo.task.note ? todo.task.note : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">
                      Category:{" "}
                      {todo.task.category
                        ? todo.task.category.name
                        : "No Category"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">
                      Due:{" "}
                      {todo.task.dueDate
                        ? moment(todo.task.dueDate).format(
                            "MMM DD, YYYY, h:mm a"
                          )
                        : "No date selected"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">
                      Priority:{" "}
                      {todo.task.priority
                        ? todo.task.priority
                        : "None selected"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">
                      Created:{" "}
                      {moment(todo.task.createdDate).format(
                        "MMM DD, YYYY, h:mm a"
                      )}{" "}
                      {todo.task.createdBy
                        ? `by ${todo.task.createdBy.person.firstName} 
                        ${todo.task.createdBy.person.lastName}`
                        : ""}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography color="textSecondary">
                      Time estimate:{" "}
                      {todo.task.minutesEstimate
                        ? `${todo.task.minutesEstimate} minutes`
                        : "None specified"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">
                      Assigned To:{" "}
                      {todo.task.assignedTo
                        ? todo.task.assignedTo.person.firstName +
                          " " +
                          todo.task.assignedTo.person.lastName
                        : "Not assigned"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="textSecondary">
                      Created By :{" "}
                      {todo.task.createdBy
                        ? todo.task.createdBy.person.firstName +
                          " " +
                          todo.task.createdBy.person.lastName
                        : "Not specified"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {todo.task.completed && (
                      <Typography color="textSecondary">
                        Task completed:{" "}
                        {moment(todo.task.completedDate).format(
                          "MMM DD, YYYY, h:mm a"
                        )}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </AccordionDetails>
              <Divider />

              <AccordionActions>
                <div>
                  <Button
                    size="small"
                    color="secondary"
                    className={classes.taskButton}

                    // onclick={() => setDeleteMode(true)}
                  >
                    Remove
                  </Button>
                  <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setSelectedTaskId(taskId)
                      handleComplete(taskId)
                    }}
                    className={classes.taskButton}
                    // onClick={() => {
                    //   setEditTaskId(todo)
                    //   setEdit(true)
                    // }}
                  >
                    Not Complete
                  </Button>
                  {/* <UpdateTask todo={todo} /> */}
                </div>
              </AccordionActions>
            </Accordion>
          )
        })}
    </div>
  )
}
const UNCOMPLETE_TASK_MUTATION = gql`
  mutation (
    $taskId: Int!
    $taskCompleted: Boolean
    $minutesReported: Int
    $completedDate: DateTime
  ) {
    completeTask(
      taskId: $taskId
      taskCompleted: $taskCompleted
      minutesReported: $minutesReported
      completedDate: $completedDate
    ) {
      task {
        id
        taskForProject {
          id
          task {
            id
            title
            createdDate
            dueDate
            completed
            completedDate
            note
            priority
            minutesEstimate
            minutesReported
            taskOverdueStatus
            category {
              id
              name
            }
            createdBy {
              id
              person {
                uid
                firstName
                lastName
              }
            }
            assignedTo {
              id
              person {
                uid
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`
export default CompletedTasks
