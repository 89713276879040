import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import ImageSearchIcon from "@material-ui/icons/ImageSearch"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import CircularProgress from "@material-ui/core/CircularProgress"
import Divider from "@material-ui/core/Divider"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import Alert from "@material-ui/lab/Alert"
import { MY_UPLOADED_IMAGES_QUERY } from "./UploadList"
// import axios from "axios"
import gql from "graphql-tag"
// import { useMutation } from "@apollo/client"
import { useApolloClient, useMutation } from "@apollo/client"
import TextField from "@material-ui/core/TextField"
const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(1),
  },
  save: {
    color: "green",
  },
  icon: {
    marginLeft: theme.spacing.unit,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
}))

const UploadButton = () => {
  const [file, setFile] = useState("")
  const [title, setTitle] = useState("")
  const [note, setNote] = useState("")
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [fileError, setFileError] = useState("")
  const classes = useStyles()

  const apolloClient = useApolloClient()
  // const handleUpdateCache = (cache, {data:{deleteTrack}}) => {
  //   const data = cache.readQuery({ query: MY_UPLOADED_IMAGES_QUERY })
  //   data.me.image.findIndex(
  //     image => image.id ===
  //   )
  // }
  const [handleSubmit, { loading, error }] = useMutation(
    IMAGE_UPLOAD_MUTATION,
    {
      variables: {
        image: file,
        title: title,
        note: note,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: MY_UPLOADED_IMAGES_QUERY }],
      onCompleted: (response, errors) => {
        setFile("")
        setTitle("")
        setNote("")
        setSuccess(true)
      },
      onError: err => console.error(err),
      // update={handleUpdateCache}
    }
  )

  const handleImageChange = event => {
    const selectedFile = event.target.files[0]
    const fileSizeLimit = 10000000 // 10MB
    if (selectedFile && selectedFile.size > fileSizeLimit) {
      setFileError(`${selectedFile.name}: File size too large`)
    } else {
      setFile(selectedFile)
      setFileError("")
    }
  }

  const handleUpdateCache = (cache, response) => {
    const data = cache.readQuery({ query: MY_UPLOADED_IMAGES_QUERY })
    const images = data.image.concat()
    cache.writeQuery({ query: MY_UPLOADED_IMAGES_QUERY, data: {} })
  }

  return (
    <>
      <Card className={classes.root}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Upload a File
            </Typography>
          </CardContent>
        </CardActionArea>
        <form
          onSubmit={event => {
            event.preventDefault()
            handleSubmit(event)
          }}
        >
          <FormControl error={Boolean(fileError)}>
            {/* <FormControl> */}
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              required
              onChange={handleImageChange}
            />
            <label htmlFor="contained-button-file">
              <Button
                component="span"
                variant="contained"
                color="default"
                className={classes.button}
                startIcon={<ImageSearchIcon />}
              >
                Select an Image File
              </Button>
              {file && file.name}
              <FormHelperText>{fileError}</FormHelperText>
            </label>
            <TextField
              id="standard-full-width"
              label="Title"
              style={{ margin: 8 }}
              placeholder="Image Title (optional)"
              fullWidth
              margin="normal"
              onChange={e => setTitle(e.target.value)}
              value={title}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="standard-full-width"
              label="Note"
              style={{ margin: 8 }}
              placeholder="Note about this image (optional)"
              fullWidth
              margin="normal"
              onChange={e => setNote(e.target.value)}
              value={note}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              disabled={submitting}
              onClick={() => {
                setFileError("")
                setFile("")
                setTitle("")
                setNote("")
              }}
              className={classes.cancel}
            >
              Cancel
            </Button>
            <Button
              disabled={submitting || !file}
              type="submit"
              className={classes.save}
            >
              {submitting ? "Please wait..." : "Add this File"}
            </Button>
          </FormControl>
        </form>
        {success === true ? (
          <Alert
            variant="outlined"
            severity="success"
            onClose={() => {
              setSuccess(false)
            }}
          >
            Your image has successfully uploaded.
          </Alert>
        ) : null}
      </Card>
    </>
  )
}
const IMAGE_UPLOAD_MUTATION = gql`
  mutation ($image: Upload, $title: String, $note: String) {
    uploadFile(image: $image, title: $title, note: $note) {
      itemImage {
        id
        image
        title
        note
      }
    }
  }
`

const LOGIN_QUERY = gql`
  query {
    me {
      id
      admin
    }
  }
`
export default UploadButton
