import React, { useState, useEffect, useCallback } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import moment from "moment"
import PropTypes from "prop-types"
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core"
import getInitials from "../../../../../utils/getInitials"

//   import useIsMountedRef from 'src/hooks/useIsMountedRef';
import GenericMoreButton from "../../../../Global/GenericMoreButton"

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    padding: theme.spacing(3),
    flexGrow: 1,
    "&:first-of-type": {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
}))

const CurrentCustomers = ({ className, ...rest }) => {
  const classes = useStyles()
  //   const isMountedRef = useIsMountedRef()
  const [activities, setActivities] = useState(null)

  // const getActivities = useCallback(() => {
  //   axios
  //     .get('/api/reports/customer-activity')
  //     .then((response) => {
  //       if (isMountedRef.current) {
  //         setActivities(response.data.activities);
  //       }
  //     });
  // }, [isMountedRef]);

  //   useEffect(() => {
  //     getActivities()
  //   }, [getActivities])

  //   if (!activities) {
  //     return null
  //   }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Current Customers" action={<GenericMoreButton />} />
      <Divider />
      <Box display="flex">
        <div className={classes.item}>
          <Typography align="center" variant="h3" color="textPrimary">
            12
          </Typography>
          <Typography
            align="center"
            component="h4"
            gutterBottom
            variant="overline"
            color="textSecondary"
          >
            Current
          </Typography>
        </div>
        <Divider />
        <div className={classes.item}>
          <Typography align="center" variant="h3" color="textPrimary">
            5
          </Typography>
          <Typography
            align="center"
            component="h4"
            gutterBottom
            variant="overline"
            color="textSecondary"
          >
            Need Attention
          </Typography>
        </div>
      </Box>
      <Divider />
      <List disablePadding>
        <ListItem>List Item</ListItem>
        {/* {activities.map((activity, i) => (
            <ListItem
              divider={i < activities.length - 1}
              key={activity.id}
            >
              <ListItemAvatar>
                <Avatar
                  alt="Customer"
                  component={RouterLink}
                  src={activity.customer.avatar}
                  to="#"
                >
                  {getInitials(activity.customer.name)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={(
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="#"
                    underline="none"
                    variant="h6"
                  >
                    {activity.customer.name}
                  </Link>
                )}
                secondary={(
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    {activity.description}
                  </Typography>
                )}
              />
              <Typography
                color="textSecondary"
                noWrap
                variant="caption"
              >
                {moment(activity.createdAt).fromNow()}
              </Typography>
            </ListItem>
          ))} */}
      </List>
    </Card>
  )
}

CurrentCustomers.propTypes = {
  className: PropTypes.string,
}

export default CurrentCustomers
