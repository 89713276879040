import React, { useState } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Typography,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import withStyles from "@material-ui/core/styles/withStyles"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"

const AboutOrg = ({ classes, org }) => {
  const [name, setName] = useState(org.name)
  const [about, setAbout] = useState(org.about)
  const [isSupplier, setIsSupplier] = useState(org.isSupplier)
  const [nickname, setNickname] = useState(org.nickname)
  const [editing, setEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [alert, setAlert] = useState(false)
  const handleCancel = () => {
    setName(org.name)
    setAbout(org.about)
    setIsSupplier(org.isSupplier)
    setNickname(org.nickname)
    setEditing(false)
  }

  const handleCheckSupplier = event => {
    setIsSupplier(event.target.checked)
  }

  const [handleUpdateAbout, { data, loading, error }] = useMutation(
    UPDATE_ORG_MUTATION,
    {
      variables: {
        ouid: org.ouid,
        name: name,
        nickname: nickname,
        about: about,
        isSupplier: isSupplier,
      },
      onCompleted: data => {
        setEditing(false)
        setIsSubmitting(false)
        setAlert(true)
      },
    }
  )
  return (
    <Card className={classes.roundedCard}>
      <CardHeader
        title="About"
        action={
          <IconButton aria-label="edit" onClick={() => setEditing(true)}>
            <EditIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        {editing ? (
          <form
            onSubmit={event => {
              event.preventDefault()
              setIsSubmitting(true)
              handleUpdateAbout(event)
            }}
          >
            <Box>
              <TextField
                id="edit-name"
                label="Org Name"
                fullWidth
                className={classes.field}
                value={name}
                onChange={event => setName(event.target.value)}
                variant="outlined"
              />
              <TextField
                id="edit-nickname"
                label="Org Nickname or Alias"
                fullWidth
                className={classes.field}
                value={nickname}
                onChange={event => setNickname(event.target.value)}
                variant="outlined"
              />
              <TextField
                id="edit-about"
                label="About (edit)"
                fullWidth
                className={classes.field}
                multiline
                rows={4}
                value={about}
                onChange={event => setAbout(event.target.value)}
                variant="outlined"
              />
              <Box alignItems="center" display="flex" mt={2} ml={-1} mb={2}>
                <Checkbox checked={isSupplier} onChange={handleCheckSupplier} />
                <Typography variant="body2" color="textSecondary">
                  This organization is a supplier. Check to add to supplier
                  list.
                </Typography>
              </Box>
              <Grid container justify="space-between">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        ) : (
          <>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>
                    Org Name
                  </TableCell>
                  <TableCell>{name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>
                    Nickname / Alias
                  </TableCell>
                  <TableCell>{nickname}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.fontWeightMedium}>
                    Supplier?{" "}
                  </TableCell>
                  <TableCell>
                    {isSupplier ? "Supplier" : "Not a supplier"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Box mt={2} ml={2}>
              <Typography variant="h5">About {name}</Typography>
              <Typography variant="body2">{about}</Typography>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  )
}

const styles = theme => ({
  root: {},
  roundedCard: {
    borderRadius: "15px",
  },
  field: {
    marginTop: theme.spacing(2),
  },
})
const UPDATE_ORG_MUTATION = gql`
  mutation (
    $ouid: String!
    $name: String
    $nickname: String
    $about: String
    $isSupplier: Boolean
  ) {
    updateOrg(
      ouid: $ouid
      name: $name
      nickname: $nickname
      about: $about
      isSupplier: $isSupplier
    ) {
      org {
        id
        ouid
        name
        nickname
        about
        isSupplier
      }
    }
  }
`

export default withStyles(styles)(AboutOrg)
