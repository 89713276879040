import React, { useContext } from "react"

import PropTypes from "prop-types"
import {
  Button,
  ListItem,
  ButtonBase,
  Typography,
  makeStyles,
} from "@material-ui/core"
import AnnouncementIcon from "@material-ui/icons/Announcement"
import DeleteIcon from "@material-ui/icons/Delete"
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined"
import DraftsIcon from "@material-ui/icons/Drafts"
import ForumIcon from "@material-ui/icons/Forum"
import LabelIcon from "@material-ui/icons/Label"
import SendIcon from "@material-ui/icons/Send"
import StarIcon from "@material-ui/icons/Star"
import InboxIcon from "@material-ui/icons/Inbox"
import MailIcon from "@material-ui/icons/Mail"
import ReportIcon from "@material-ui/icons/Report"
import LabelImportantIcon from "@material-ui/icons/LabelImportant"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import ScheduleIcon from "@material-ui/icons/Schedule"

import LocalActivityIcon from "@material-ui/icons/LocalActivity"

import MessagesContext from "../context/MessagesContext"

import { useLazyQuery } from "@apollo/client"
import gql from "graphql-tag"

const systemLabelIcons = {
  invites: MailIcon,
  updates: InboxIcon,
  conversations: ForumIcon,
  archived: ArchiveOutlinedIcon,
  attention: StarIcon,
  trash: DeleteIcon,
  drafts: DraftsIcon,
  spam: ReportIcon,
  sent: SendIcon,
  important: LabelImportantIcon,
  alerts: AnnouncementIcon,
  today: ScheduleIcon,
  thisWeek: CalendarTodayIcon,
  starred: StarIcon,
  following: LocalActivityIcon,
}

function getIcon(label) {
  if (label.type === "system_label") {
    return systemLabelIcons[label.id]
  }

  return LabelIcon
}

function getTo(label) {
  const baseUrl = "/manager/conversations/"

  if (label.type === "system_label") {
    return `${baseUrl}/${label.id}`
  }

  if (label.type === "custom_label") {
    return `${baseUrl}/label/${label.name}`
  }

  return baseUrl
}

function getColor(label) {
  if (label.type === "custom_label") {
    return label.color
  }

  return null
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  content: {
    flexGrow: 1,
    height: 36,
    textAlign: "left",
    color: theme.palette.text.secondary,
    borderTopRightRadius: 18,
    borderBottomRightRadius: 18,
    paddingRight: 18,
    paddingLeft: 32,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  active: {
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: theme.palette.action.selected,
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 0),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  text: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}))

const LabelItem = ({ label, ...rest }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)

  const [getData, { called, loading, data }] = useLazyQuery(
    USER_CONVERSATION_DATA_QUERY,
    {
      onCompleted: data => {
        let uc = data.userConversationData
        dispatch({ type: "STAR_COUNT", payload: uc.starCount })
        dispatch({ type: "ARCHIVE_COUNT", payload: uc.archiveCount })
        dispatch({ type: "FOLLOWING_COUNT", payload: uc.followingCount })
        dispatch({ type: "INVITES_COUNT", payload: uc.invitesCount })
        dispatch({ type: "UPDATE_COUNT", payload: uc.withUpdatesCount })
        console.log("grabbed data")
      },
    }
  )

  const Icon = getIcon(label)

  const color = getColor(label)
  const displayUnreadCount = Boolean(label.count && label.count > 0)
  const getCount = label => {
    switch (label.id) {
      case "invites":
        return state.invitesCount
      case "starred":
        return state.starCount
      case "following":
        return state.followingCount
      case "updates":
        return state.updateCount
      case "archived":
        return state.archiveCount
      default:
        return ""
    }
  }
  const resetFilters = event => {
    dispatch({ type: "SET_STARRED_FILTER", payload: false })
    dispatch({ type: "SET_SELECTED_CONVERSATION_ID", payload: "" })
    dispatch({ type: "SET_PANEL", payload: "conversationList" })
    dispatch({ type: "SET_ARCHIVED_FILTER", payload: false })
    dispatch({ type: "SET_FOLLOWING_FILTER", payload: false })
    dispatch({ type: "SET_NEW_INVITES_FILTER", payload: false })
    dispatch({ type: "SET_HAS_UPDATE_FILTER", payload: false })
    getData()
  }

  const handleFilter = label => {
    if (label.id === "conversations") {
      resetFilters()
    }
    if (label.id === "starred") {
      resetFilters()
      dispatch({ type: "SET_STARRED_FILTER", payload: true })
    }
    if (label.id === "following") {
      resetFilters()
      dispatch({ type: "SET_FOLLOWING_FILTER", payload: true })
    }
    if (label.id === "archived") {
      resetFilters()
      dispatch({ type: "SET_ARCHIVED_FILTER", payload: true })
    }
    if (label.id === "invites") {
      resetFilters()
      dispatch({ type: "SET_PANEL", payload: "newInvites" })
      dispatch({ type: "SET_NEW_INVITES_FILTER", payload: true })
    }
    if (label.id === "updates") {
      resetFilters()
      dispatch({ type: "SET_EXCLUDE_ARCHIVED_FILTER", payload: true })
      dispatch({ type: "SET_HAS_UPDATE_FILTER", payload: true })
    }
  }

  // const labelCount = {
  //   invites: MailIcon,
  //   updates: InboxIcon,
  //   conversations: ForumIcon,
  //   archived: ArchiveOutlinedIcon,
  //   starred: StarIcon,
  //   following: LocalActivityIcon,
  // }
  return (
    <ListItem className={classes.root} {...rest}>
      <ButtonBase
        // activeClassName={classes.active}
        component={Button}
        onClick={() => handleFilter(label)}
        className={classes.content}
      >
        <Icon className={classes.icon} color="primary" style={{ color }} />
        <Typography className={classes.text} variant="body2" color="secondary">
          {label.name}
        </Typography>
        <Typography color="primary" variant="caption">
          {getCount(label)}
        </Typography>
      </ButtonBase>
    </ListItem>
  )
}

LabelItem.propTypes = {
  label: PropTypes.object.isRequired,
  children: PropTypes.any,
}
const USER_CONVERSATION_DATA_QUERY = gql`
  query {
    userConversationData {
      starCount
      archiveCount
      followingCount
      invitesCount
      withUpdatesCount
    }
  }
`
export default LabelItem
