import React, { useState } from "react"
import {
  Alert,
  Box,
  Button,
  makeStyles,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core"

import MuiAlert from "@material-ui/lab/Alert"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import { SettingsBackupRestoreOutlined } from "@material-ui/icons"

const useStyles = makeStyles(() => ({
  root: {},
  queryField: {
    margin: "dense",
    size: "small",
  },
  text: {
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: 10,
    },
  },
}))

const SearchPersonConfirm = ({
  pickUid,
  pickFirst,
  pickLast,
  inquiry,
  setPickPerson,
  setOpenSearchPerson,
  setIsPerson,
}) => {
  const classes = useStyles()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const id = inquiry.inquiryPerson.id
  const firstName = inquiry.inquiryPerson.firstName
  const lastName = inquiry.inquiryPerson.lastName
  const middleName = inquiry.inquiryPerson.middleName
  const nickname = inquiry.inquiryPerson.nickname
  const title = inquiry.inquiryPerson.title

  // const handleClick = () => {
  //     setSnackBarOpen(true);
  //   };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setSnackBarOpen(false)
  }

  const deSelectPerson = () => {
    console.log(pickUid)
    setPickPerson(false)
  }
  const confirmLog = () => {
    console.log(inquiry.inquiryPerson)
  }

  // const completeChange = (e) =>{
  //     return(
  //         <div className={classes.alert}>
  //         <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleClose}>
  //             <Alert severity="success" >
  //             Record successfully updated.
  //             </Alert>
  //         </Snackbar>
  //       </div>)

  // }
  const [confirmPerson] = useMutation(INQUIRY_PERSON_TO_PERSON_MATCH, {
    variables: {
      id: id,
      isPerson: true,
      personUid: pickUid,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      isSupplier: false,
      middleName: middleName,
      nickname: nickname,
      title: title,
      isOrg: false,
    },
    onCompleted: (response, errors) => {
      // setIsPerson(true)
      setSnackBarOpen(true)
      setOpenSearchPerson(false)
    },
  })
  return (
    <>
      <Grid className={classes.text}>
        <Typography gutterBottom variant="h5" color="textPrimary">
          You're about to match new inquirer {firstName} {lastName} existing
          contact {pickFirst} {pickLast}'s account.
        </Typography>
        <Typography gutterBottom variant="h5" color="textPrimary">
          Are you sure?
        </Typography>
      </Grid>
      <Grid container justify="space-between">
        <Grid item>
          <Button
            onClick={deSelectPerson}
            color="secondary"
            variant="contained"
            type="submit"
          >
            Back to List
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={confirmPerson}
            color="primary"
            variant="contained"
            type="submit"
          >
            {/* <Button onClick={confirmPerson} color="primary" variant="contained" type="submit"> */}
            Select {pickFirst} {pickLast}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
const INQUIRY_PERSON_TO_PERSON_MATCH = gql`
  mutation (
    $id: Int!
    $isPerson: Boolean
    $personUid: String
    $firstName: String
    $lastName: String
    $middleName: String
    $isSupplier: Boolean
    $nickname: String
    $title: String
    $isOrg: Boolean
  ) {
    updateInquiryPerson(
      id: $id
      isPerson: $isPerson
      personUid: $personUid
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      isSupplier: $isSupplier
      nickname: $nickname
      title: $title
      isOrg: $isOrg
    ) {
      inquiryPerson {
        id
        firstName
        lastName
        isPerson
        isSupplier
        person {
          uid
          firstName
          lastName
        }
      }
    }
  }
`
export default SearchPersonConfirm
