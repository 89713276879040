import React, { useState, useEffect, useContext, useRef } from "react"
import { Link } from "gatsby"
import Message from "./Message"

import Markdown from "react-markdown"
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  Hidden,
} from "@material-ui/core"
import PerfectScrollbar from "react-perfect-scrollbar"
import MoreIcon from "@material-ui/icons/MoreVert"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ReplyIcon from "@material-ui/icons/ReplyOutlined"
import ReplyAllIcon from "@material-ui/icons/ReplyAllOutlined"
import DeleteIcon from "@material-ui/icons/DeleteOutlined"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
import getInitials from "../../../../utils/getInitials"
import { useQuery, useMutation } from "@apollo/client"
import { NetworkStatus } from "@apollo/client"
import gql from "graphql-tag"
import SortContext from "../../../../context/SortContext"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import MessageAdd from "./MessageAdd"
import MessagesContext from "../context/MessagesContext"

import Toolbar from "./Toolbar"
import ConversationPersonSearch from "../ConversationNew/ConversationPersonSearch"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "92vh",
    flexDirection: "column",
    overflowY: "hidden",
  },
  avatar: {
    height: 35,
    width: 35,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.accountLink,
  },
  date: {
    whiteSpace: "nowrap",
  },
  message: {
    color: theme.palette.text.secondary,
    "& > p": {
      ...theme.typography.body1,
      marginBottom: theme.spacing(2),
    },
  },
}))

const ConversationDetails = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)
  const uuid = state.selectedConversationId
  const [messageList, setMessageList] = useState([])
  const [inviteList, setInviteList] = useState([])
  const [invitedListIds, setInvitedListIds] = useState([])
  const [participants, setParticipants] = useState([])
  const [participantIds, setParticipantIds] = useState([])
  const [addPeople, setAddPeople] = useState(false)

  const [conversationId, setConversationId] = useState("")
  const messagesRef = useRef(null)
  const [category, setCategory] = useState("")

  const [topic, setTopic] = useState("")
  const [archived, setArchived] = useState(false)

  const { data, loading, error } = useQuery(
    // const { data, loading, error, refetch, networkStatus } = useQuery(
    GET_CONVERSATION_DETAILS,
    {
      fetchPolicy: "network-only",
      // notifyOnNetworkStatusChange: true,
      variables: {
        uuid: uuid,
        // uuid: uuid,
      },
      onCompleted: data => {
        console.log("data fetched")
        console.log(data)
        setMessageList(data.userConversation.conversation.conversationMessage)
        setInviteList(data.userConversation.conversation.invited)
        setParticipants(data.userConversation.conversation.participants)
        setConversationId(data.userConversation.conversation.uuid)
        setCategory(data.userConversation.conversation.category)
        setTopic(data.userConversation.conversation.topic)
        setArchived(data.userConversation.conversation.archived)
      },
    }
  )

  const [updateConversation] = useMutation(UPDATE_CONVERSATION_MUTATION, {
    variables: {
      conversationId: conversationId,
      categoryId: category.id,
      invitedListIds: invitedListIds,
      topic: topic,
      archived: archived,
    },
  })

  function scrollMessagesToBottom() {
    if (messagesRef.current) {
      // eslint-disable-next-line no-underscore-dangle
      messagesRef.current._container.scrollTop =
        messagesRef.current._container.scrollHeight
    }
  }
  useEffect(() => {
    if (userConversation) {
      scrollMessagesToBottom()
    }
  }, [messageList])

  useEffect(() => {
    let ids = inviteList.map(a => a.id)
    setInvitedListIds(ids)
  }, [inviteList])

  useEffect(() => {
    let ids = participants.map(a => a.id)
    setParticipantIds(ids)
  }, [participants])

  // useEffect(() => {
  //   if (conversationId.length > 5) updateConversation()
  // }, [invitedListIds])

  const handleBack = () => {
    dispatch({ type: "SET_SELECTED_CONVERSATION_ID", payload: "" })
    dispatch({ type: "SET_PANEL", payload: "conversationList" })
  }

  const handleDeleteInvite = invited => {
    console.log(invited)
    let filteredArray = inviteList.filter(x => x.id !== invited.id)
    setInviteList(filteredArray)
  }
  // if (networkStatus === NetworkStatus.refetch) return <Loading />
  if (loading) return <Loading />
  if (error) return <Error />

  const userConversation = data.userConversation

  if (!userConversation) {
    return null
  }
  return (
    <div className={classes.root}>
      <Toolbar userConversation={userConversation} />
      <Divider />
      <Box p={2} display="flex" justifyContent="space-between" flexShrink={0}>
        <PerfectScrollbar>
          <Box display="flex" alignItems="flex-start">
            <Box display="flex" alignItems="flex-start">
              <Tooltip title="Invite Someone">
                <IconButton size="small" onClick={() => setAddPeople(true)}>
                  <PersonAddIcon />
                </IconButton>
              </Tooltip>

              {/* </Hidden> */}
              {/* <Tooltip title="More options">
            <IconButton>
              <MoreIcon fontSize="small" />
            </IconButton>
          </Tooltip> */}
            </Box>

            <Box ml={2}>
              <Box mb={1} display="flex" justifyContent="flex-start">
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="textSecondary"
                >
                  Invited:
                </Typography>

                {inviteList
                  .filter(x => !participantIds.includes(x.id))
                  .map(invited => (
                    <Box key={invited.id} ml={1}>
                      <Chip
                        label={`${invited.person && invited.person.firstName} ${
                          invited.person && invited.person.lastName
                        }`}
                        variant="outlined"
                        size="small"
                        onDelete={() => handleDeleteInvite(invited)}
                        color="secondary"
                        clickable
                      />
                    </Box>
                  ))}
              </Box>

              <Box display="flex" justifyContent="flex-start">
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="textSecondary"
                >
                  Participants:
                </Typography>
                {userConversation.conversation.participants.map(participant => (
                  <Box key={participant.id} ml={1}>
                    <Chip
                      label={`${participant.person.firstName} ${participant.person.lastName}`}
                      component={Link}
                      to={`/manager/people/${participant.person.uid}`}
                      state={{ uid: `${participant.person.uid}` }}
                      size="small"
                      clickable
                      color="secondary"
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </PerfectScrollbar>
      </Box>
      <Divider />

      {/* <Box display="flex" py={6} px={3} bgcolor="background.dark"> */}

      <Box
        flexGrow={1}
        p={6}
        px={3}
        bgcolor="background.dark"
        ref={messagesRef}
        component={PerfectScrollbar}
        options={{ suppressScrollX: true }}
      >
        {messageList.length > 0 &&
          messageList.map(message => (
            <Message key={message.uuid} message={message} />
          ))}
      </Box>
      {/* </Box> */}
      <Divider />
      <MessageAdd
        userConversation={userConversation}
        messageList={messageList}
        setMessageList={setMessageList}
      />
      <ConversationPersonSearch
        inviteList={inviteList}
        setInviteList={setInviteList}
        addPeople={addPeople}
        setAddPeople={setAddPeople}
      />
    </div>
  )
}

const GET_CONVERSATION_DETAILS = gql`
  query ($uuid: String!) {
    userConversation(uuid: $uuid) {
      id
      firstQuery
      latestQuery
      follow
      star
      alerts
      hasUpdate
      user {
        person {
          uid
          firstName
          lastName
        }
      }
      conversation {
        uuid
        startedBy {
          id
          person {
            uid
            firstName
            lastName
          }
          staff
          manager
          partner
        }
        invited {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        participants {
          id
          person {
            uid
            firstName
            lastName
            user {
              id
            }
          }
        }
        category {
          id
          name
        }
        sbu {
          id
          name
        }
        topic
        archived
        startDate
        archivedDate
        conversationMessage {
          uuid
          messageFile
          messageFrom {
            id
            person {
              uid
              firstName
              lastName
            }
          }
          message
          sent
          removed
          viewedMessage {
            id
            viewer {
              id
              person {
                uid
                firstName
                lastName
              }
            }
            viewedDate
          }
          messageFileUrl
        }
      }
    }
  }
`

const UPDATE_CONVERSATION_MUTATION = gql`
  mutation (
    $conversationId: String
    $categoryId: Int
    $invitedListIds: [Int]
    $topic: String
    $participantId: Int
    $archived: Boolean
  ) {
    updateConversation(
      conversationId: $conversationId
      categoryId: $categoryId
      invitedListIds: $invitedListIds
      participantId: $participantId
      topic: $topic
      archived: $archived
    ) {
      conversation {
        uuid
        startedBy {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        invited {
          id
          email
          person {
            uid
            firstName
            lastName
          }
        }
        participants {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        category {
          id
          name
        }
        topic
      }
    }
  }
`
export default ConversationDetails
