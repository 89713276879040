import React, { useState } from "react"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

import Page from "../../../Global/Page"
import Header from "./Header"
import Results from "./Results"

// import Results from "./Results"
const PlanListView = ({ classes }) => {
  const page = 1
  const pageSize = 10
  const search = ""
  const { data, loading, error } = useQuery(BROWSE_PLANS_QUERY, {
    variables: {
      page: page,
      pageSize: pageSize,
      search: search,
    },
    onCompleted: data => {
      console.log(data)
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />
  const res = data.plans
  return (
    <Page className={classes.root} title="Plans">
      <Container maxWidth="lg">
        <Header />
        <Results res={res} />
      </Container>
    </Page>
  )
}

const styles = theme => ({
  root: {},
})
const BROWSE_PLANS_QUERY = gql`
  query ($page: Int, $pageSize: Int, $search: String) {
    plans(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        title
        isActive
        startDate
        objective
        strategy
        createdBy {
          id
          person {
            firstName
            lastName
          }
        }
        category {
          id
          name
        }
        tags {
          id
          name
        }
        projectPlan {
          id
          isActive
          project {
            id
          }
          completed
          priority
        }
      }
    }
  }
`
export default withStyles(styles)(PlanListView)
