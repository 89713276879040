import React, { useState, useEffect } from "react"
import clsx from "clsx"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"
import Alert from "@material-ui/lab/Alert"
import useDidMountEffect from "../../../../../Global/hooks/useDidMountEffect"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(1),
  },
  save: {
    marginRight: "2rem",
  },
  previewImage: {
    width: "100%",
    maxWidth: 200,
    maxHeight: 200,
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  dropdown: {
    flexBasis: 200,
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

const statusOptions = [
  { id: 1, name: "Art/Proof in progress", value: 1 },
  { id: 2, name: "Art/Proof awaiting customer approval", value: 2 },
  { id: 3, name: "Sample awaiting customer approval", value: 3 },
  { id: 4, name: "Art/Proof approved by customer", value: 4 },
  { id: 5, name: "Revision in progress", value: 5 },
  { id: 6, name: "Sample creation in progress", value: 6 },
  { id: 7, name: "Sample approved by customer", value: 7 },
  { id: 8, name: "Discrepancy", value: 8 },
  { id: 9, name: "Rejected and abandoned", value: 9 },
]

const UploadSampleProof = ({
  item,
  setImageFile,
  imageFile,
  isVertical,
  setIsVertical,
  samplesProofs,
  setSamplesProofs,
}) => {
  const [file, setFile] = useState("")
  const [statusId, setStatusId] = useState(1)
  const [version, setVersion] = useState(1)
  const [isClosed, setIsClosed] = useState(false)

  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [fileError, setFileError] = useState("")
  const [closedTimestamp, setClosedTimestamp] = useState(null)
  const [proofOrSample, setProofOrSample] = useState("proof")
  let dateTimeNow = new Date().toISOString()
  const id = item.id
  const customerUid = item.privateItem.itemOwner.uid

  useEffect(() => {
    createFile()
  }, [])

  useDidMountEffect(() => {
    createFile()
  }, [version])

  useDidMountEffect(() => {
    createFile()
  }, [proofOrSample])

  const createFile = async () => {
    let newFile = await fetch(imageFile)
      .then(r => r.blob())
      .then(
        imageFile =>
          new File([imageFile], `${id}_${proofOrSample}_${version}.png`, {
            type: "image/png",
          })
      )
    setFile(newFile)
    console.log(newFile)
  }

  const thumbnail = (
    <img
      height={isVertical ? 160 : 120}
      width={isVertical ? 120 : 160}
      src={imageFile}
      alt={"uploaded file thumbnail"}
    />
  )
  const classes = useStyles()

  const [uploadImage, { data, loading, error }] = useMutation(
    CREATE_SAMPLE_PROOF_MUTATION,
    {
      variables: {
        itemId: id,
        image: file,
        statusId: statusId,
        customerUid: customerUid,
        isVertical: isVertical,
        closed: isClosed,
        closedTimestamp: closedTimestamp,
      },

      onCompleted: data => {
        let newFile = data.createSampleProof.sampleProof
        setSamplesProofs(sps => [...sps, newFile])
        setSubmitting(false)
        setSuccess(true)
      },
      onError: err => setFileError(err),
    }
  )

  const handleSubmit = () => {
    setSubmitting(true)
    uploadImage()
  }

  const handleClosed = event => {
    let now = new Date().toISOString()
    setIsClosed(!isClosed)
    if (isClosed) {
      setClosedTimestamp(now)
    } else {
      setClosedTimestamp(null)
    }
  }

  const handleChangeProof = event => {
    setProofOrSample(event.target.value)
  }

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener("load", () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  return (
    <>
      <Card className={clsx(classes.root, classes.roundedCard)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            {thumbnail}
            <Typography variant="caption">{file ? file.name : ""}</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <CardContent>
              <Typography gutterBottom variant="h4">
                Add a Sample or Proof
              </Typography>
            </CardContent>
            <form
              onSubmit={event => {
                event.preventDefault()

                handleSubmit(event)
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={8}>
                  <Box alignItems="center" display="flex">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isClosed}
                          onChange={handleClosed}
                          name="closed"
                        />
                      }
                      label="Closed / Completed"
                    />
                  </Box>
                </Grid>
              </Grid>

              <TextField
                id="status"
                label="Status"
                className={classes.dropdown}
                variant="outlined"
                size="small"
                select
                margin="normal"
                onChange={e => setStatusId(e.target.value)}
                value={statusId}
                SelectProps={{
                  native: true,
                }}
              >
                {statusOptions.map(option => (
                  <option key={option.id} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </TextField>

              <Box alignItems="center" display="flex" mt={2}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Art/Proof or Sample?</FormLabel>
                  <RadioGroup
                    aria-label="proofOrSample"
                    name="proofOrSample"
                    value={proofOrSample}
                    onChange={handleChangeProof}
                    style={{ display: "inline" }}
                  >
                    <FormControlLabel
                      value="proof"
                      control={<Radio />}
                      label="Proof"
                    />
                    <FormControlLabel
                      value="sample"
                      control={<Radio />}
                      label="Sample"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={submitting || !imageFile}
                  type="submit"
                  className={classes.save}
                >
                  {submitting ? "Please wait..." : "Add this File"}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={submitting}
                  onClick={() => {
                    setFileError("")
                    setImageFile(null)
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </Grid>
          {success === true ? (
            <Alert
              variant="outlined"
              severity="success"
              onClose={() => {
                setSuccess(false)
              }}
            >
              Your image has successfully uploaded.
            </Alert>
          ) : !success && fileError ? (
            <Alert
              variant="outlined"
              severity="error"
              onClose={() => {
                setFileError(false)
              }}
            >
              {JSON.stringify(fileError)}
            </Alert>
          ) : (
            ""
          )}
        </Grid>
      </Card>
    </>
  )
}
const CREATE_SAMPLE_PROOF_MUTATION = gql`
  mutation (
    $image: Upload
    $itemId: Int
    $statusId: Int
    $customerUid: String
    $isVertical: Boolean
    $closed: Boolean
    $closedTimestamp: DateTime
  ) {
    createSampleProof(
      image: $image
      itemId: $itemId
      statusId: $statusId
      customerUid: $customerUid
      isVertical: $isVertical
      closed: $closed
      closedTimestamp: $closedTimestamp
    ) {
      sampleProof {
        id
        version
        isVertical
        image
        sampleProofUrl
        isPrototypePhoto
        status {
          id
          name
        }
        closed
      }
    }
  }
`

export default UploadSampleProof
