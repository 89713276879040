import React, { useCallback, useState, useEffect } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
  Box,
  Card,
  CardHeader,
  Divider,
  List,
  makeStyles,
} from "@material-ui/core"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import GenericMoreButton from "../../../../../Global/GenericMoreButton"
import TaskItem from "./TaskItem"
import Pagination from "@material-ui/lab/Pagination"
const useStyles = makeStyles(() => ({
  root: {},
}))

function ItemTasks({ className, ...rest }) {
  const classes = useStyles()

  const [tasks, setTasks] = useState(null)
  const [pageTasks, setPageTasks] = useState(null)
  const [page, setPage] = useState(1)

  const { data, loading, error } = useQuery(ALL_ITEM_TASKS_QUERY, {
    variables: {
      page: page,
      pageSize: 10,
      completedFilter: true,
    },
    onCompleted: data => {
      setTasks(data.itemTasks)
      setPageTasks(data.itemTasks.objects)
    },
  })

  const handlePageChange = (event, value) => {
    setPage(value)
  }
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }

  // useEffect(() => {
  //   getTasks();
  // }, [page]);

  if (!tasks) {
    return null
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader action={<GenericMoreButton />} title="Item Tasks" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={400}>
          {/* {JSON.stringify(tasks)} */}
          <List>
            {pageTasks.map((task, i) => (
              <TaskItem
                divider={i < tasks.length - 1}
                key={task.id}
                task={task}
              />
            ))}
          </List>
        </Box>
        {tasks.pages > 1 && (
          <Pagination
            count={tasks.pages}
            page={page}
            onChange={handlePageChange}
          />
        )}
      </PerfectScrollbar>
    </Card>
  )
}

ItemTasks.propTypes = {
  className: PropTypes.string,
}

const ALL_ITEM_TASKS_QUERY = gql`
  query ($page: Int!, $pageSize: Int!, $completedFilter: Boolean) {
    itemTasks(
      page: $page
      pageSize: $pageSize
      completedFilter: $completedFilter
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        task
        item {
          id
          name
        }
        priority
        isCustomerTask
        due
        completed
        completedDate
        assignedTo {
          id
          email
          staff
          manager
          partner
          person {
            uid
            firstName
            lastName
          }
        }
      }
    }
  }
`
export default ItemTasks
