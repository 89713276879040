import React, { useContext } from "react"
import { Box, Container, makeStyles } from "@material-ui/core"
import Page from "../../../Global/Page"
import useIsMountedRef from "../../../../hooks/useIsMountedRef"
import Header from "./Header"
import Results from "./Results"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import SortContext from "../../../../context/SortContext"
import PerfectScrollbar from "react-perfect-scrollbar"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    height: "100%",
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const SupplierListView = () => {
  const classes = useStyles()
  const isMountedRef = useIsMountedRef()
  const { state, dispatch } = useContext(SortContext)
  const search = state.search
  const page = state.page + 1
  const pageSize = state.pageSize

  const { loading, error, data } = useQuery(LIST_ALL_SUPPLIERS_QUERY, {
    variables: {
      search: search,
      page: page,
      pageSize: pageSize,
    },
    fetchPolicy: "cache-and-network",
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const res = data.suppliers
  return (
    <Page className={classes.root} title="Supplier List">
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Results res={res} />
        </Box>
      </Container>
    </Page>
  )
}

const LIST_ALL_SUPPLIERS_QUERY = gql`
  query ($page: Int, $pageSize: Int, $search: String) {
    suppliers(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        ouid
        name
        nickname
        about
        dateAdded
        dateModified
        people {
          uid
          firstName
          lastName
          emailPerson {
            id
            emailAddress
          }
        }
        isSupplier
        emailOrg {
          id
          emailAddress
        }
        addressOrg {
          id
          address {
            uid
            region {
              id
              name
            }
            summary
          }
          createdAt
          modifiedAt
        }
        orgSocialMedia {
          id
          socialMediaType {
            id
            name
            url
          }
          url
          username
        }
        phoneOrg {
          id
          phoneType {
            id
            phoneType
          }
          phoneNumber
        }
        orgAdmin {
          person {
            uid
            firstName
            lastName
            emailPerson {
              id
              emailAddress
            }
            phonePerson {
              id
              phoneType {
                id
                phoneType
              }
              phoneNumber
            }
          }
        }
      }
    }
  }
`

export default SupplierListView
