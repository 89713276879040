import React, { useState, useContext, useEffect } from "react"
import { Grid, Typography } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import gql from "graphql-tag"
import OrderList from "./OrderList"
import SortContext from "../../../../../../context/SortContext"
const noDataResponse = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <Typography variant="h3">
            This item does not appear in any orders.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
const ItemInOrders = ({ item }) => {
  const id = item.id
  const { state, dispatch } = useContext(SortContext)
  const search = ""
  const page = state.page
  const pageSize = state.pageSize
  const { loading, error, data } = useQuery(ITEM_IN_ORDERS_QUERY, {
    variables: {
      itemId: id,
      page: page,
      pageSize: pageSize,
    },
    onCompleted: data => {
      console.log(data)
    },
  })
  useEffect(() => {
    dispatch({ type: "SET_PAGE_SIZE", payload: 15 })
    dispatch({ type: "SET_PAGE", payload: 1 })
    dispatch({ type: "SET_SEARCH", payload: "" })
  }, [])
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) {
    return { noDataResponse }
  }
  if (data == null) {
    return { noDataResponse }
  }
  const orders = data.ordersWithItem

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <OrderList orders={orders} />
        </Grid>
      </Grid>
    </>
  )
}

const ITEM_IN_ORDERS_QUERY = gql`
  query ($page: Int, $pageSize: Int, $itemId: Int) {
    ordersWithItem(page: $page, pageSize: $pageSize, itemId: $itemId) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        orderDate
        inProgress
        orderTotal
        status {
          id
          orderStatus
        }
        customer {
          uid
          firstName
          lastName
        }
        businessUnit {
          id
          name
        }
      }
    }
  }
`
export default ItemInOrders
