import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import moment from "moment"
import PerfectScrollbar from "react-perfect-scrollbar"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import {
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  UserCheck as CheckIcon,
  User as UserIcon,
  Search as SearchIcon,
  XSquare as CloseIcon,
} from "react-feather"

import SortContext from "../../../../../context/SortContext"

const useStyles = makeStyles(() => ({
  root: {},
  queryField: {
    margin: "dense",
    // size: "small",
  },
}))

const SearchProposalPerson = ({
  setPerson,
  person,
  setPersonUid,
  setStatus,
  className,

  ...rest
}) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(SortContext)
  const search = state.search
  const page = state.page
  const pageQ = state.page + 1
  const pageSize = state.pageSize

  const selectPerson = ({ match }) => {
    setPersonUid(match.uid)
    setPerson(match)
    setStatus("confirm")
  }

  const [searchBar, setSearchBar] = useState("")
  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const { loading, error, data } = useQuery(SEARCH_PROPOSAL_PEOPLE_QUERY, {
    variables: {
      search: search,
      page: pageQ,
      pageSize: pageSize,
    },
    fetchPolicy: "cache-and-network",
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const resCount = data.people.count
  const resPage = data.people.page
  const resPages = data.people.pages
  const people = data.people.objects
  const matches = data.people.objects

  const handlePageChange = (event, newPage) => {
    dispatch({ type: "SET_PAGE", payload: newPage })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  return (
    <>
      <Box minHeight={56} p={2} display="flex" alignItems="center">
        <form
          noValidate
          onSubmit={event => {
            event.preventDefault()
            handleSearch(event)
          }}
        >
          <TextField
            className={classes.queryField}
            fullWidth
            // size="small"
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit" color="primary">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={event => setSearchBar(event.target.value)}
            placeholder="Find this customer"
            value={searchBar}
            variant="outlined"
          />
        </form>
      </Box>

      <Box p={2}>
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Date Added</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {matches.map(match => {
                  const emails = match.emailPerson
                  const phones = match.phonePerson
                  const addresses = match.addressPerson
                  const user = match.user
                  const prereg = match.preregister
                  const uid = match.uid
                  return (
                    <TableRow
                      hover
                      key={match.uid}
                      // selected={isCustomerSelected}
                    >
                      <TableCell padding="checkbox">
                        <IconButton onClick={e => selectPerson({ match }, e)}>
                          <SvgIcon fontSize="small">
                            <CheckIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2" color="textSecondary">
                            {match.firstName} {match.lastName}
                          </Typography>
                          {user && (
                            <SvgIcon fontSize="small" color="secondary">
                              <UserIcon />
                            </SvgIcon>
                          )}
                          {prereg && " (prereg)"}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {emails.map(email => (
                          <div key={email.id}>{email.emailAddress}</div>
                        ))}
                      </TableCell>
                      <TableCell>
                        {phones.map(phone => (
                          <div key={phone.id}>{phone.phoneNumber}</div>
                        ))}
                      </TableCell>
                      <TableCell>
                        City
                        {/* {addresses.map(address => (
                        <div key={address.id}>{address.summary.city}</div>
                      ))} */}
                      </TableCell>
                      <TableCell>
                        {moment(match.dateAdded).format("MMM DD YYYY")}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Box>
      <TablePagination
        component="div"
        count={resCount}
        onChangePage={handlePageChange}
        // onChangePage= {event => dispatch({type: 'SET_PAGE', payload: (newPage)})}
        // onChangeRowsPerPage={event => setPageSize(event.target.value)}
        // onChangeRowsPerPage = {event => dispatch({type: 'SET_PAGE_SIZE', payload: (event.target.value)})}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        // dispatch({ type: "SET_LOGIN" })
        // onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, 25, 50]}
        // ActionsComponent={TablePaginationActions}
      />
    </>
  )
}
export const SEARCH_PROPOSAL_PEOPLE_QUERY = gql`
  query ($page: Int!, $pageSize: Int!, $search: String) {
    people(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        uid
        firstName
        lastName
        middleName
        suffix
        nickname
        title
        about
        userCanUpdate
        isSupplier
        user {
          id
          email
          isVerified
        }
        preregPerson {
          preregId
          preregEmail
        }
        dateAdded
        dateModified
        phonePerson {
          id
          phoneNumber
        }
        addressPerson {
          id
          address {
            uid
            summary
          }
        }
        emailPerson {
          id
          emailAddress
        }

        notePerson {
          id
          note
        }
      }
    }
  }
`
export default SearchProposalPerson
