import React, { useState, useEffect } from "react"
import clsx from "clsx"
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import ReactMarkdown from "react-markdown"
import moment from "moment"
import { XSquare as CloseIcon } from "react-feather"
import EditIcon from "@material-ui/icons/Edit"

const ProjectDocsDetails = ({ classes, projectDoc, setView }) => {
  const [editMode, setEditMode] = useState(false)
  const [title, setTitle] = useState(projectDoc.doc.title)
  const [body, setBody] = useState(projectDoc.doc.body)
  const [category, setCategory] = useState(projectDoc.doc.category)
  const [tags, setTags] = useState(projectDoc.doc.tags)
  const [addTags, setAddTags] = useState(false)
  const handleDeleteTag = (docTagId, event) => {
    const newTags = tags.filter(tag => tag.id != docTagId)
    setTags(newTags)
  }

  return (
    <>
      <Card className={classes.roundedCard}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={11}>
              <Typography className={classes.title}>
                {title ? title : ""}
              </Typography>
              <Typography className={classes.category}>
                Category: {category && category.name ? category.name : ""}
              </Typography>
              {tags && (
                <Paper
                  elevation={0}
                  component="ul"
                  className={classes.chipList}
                >
                  {tags
                    ? tags.map(t => (
                        <li key={t.id}>
                          <Chip
                            className={classes.tagChip}
                            size="small"
                            // avatar={<Avatar>M</Avatar>}
                            label={t ? t.name : ""}
                            clickable
                            color="primary"
                            variant="outlined"
                          />
                        </li>
                      ))
                    : ""}
                </Paper>
              )}
            </Grid>
            <Grid item xs={12} sm={1}>
              <IconButton
                onClick={() => setView("editDoc")}
                color="primary"
                variant="outlined"
                fullWidth
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <ReactMarkdown className={classes.body}>
            {body ? body : ""}
          </ReactMarkdown>{" "}
        </CardContent>
      </Card>

      <Card className={clsx(classes.infoCard, classes.roundedCard)}>
        <CardContent>
          <Typography>
            Posted:{" "}
            {moment(projectDoc.doc.dateAdded).format("DD MMM YYYY | HH:MM")} by{" "}
            {projectDoc.doc.author.person.firstName}{" "}
            {projectDoc.doc.author.person.lastName}
          </Typography>
          <Typography>
            Latest Update:{" "}
            {moment(projectDoc.doc.dateModified).format("DD MMM YYYY | HH:MM")}
          </Typography>
          <Typography>
            Permissions: {projectDoc.staffOnly ? "Staff Only" : ""}
            {projectDoc.staffOnly && projectDoc.membersOnly ? ", " : ""}
            {projectDoc.membersOnly ? "Project Members Only" : ""}
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}

const styles = theme => ({
  root: {},
  roundedCard: {
    borderRadius: "1rem",
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  title: {
    fontSize: "26px",
    fontWeight: "bold",
  },
  category: {
    fontSize: "19px",
  },
  tags: {
    fontSize: "19px",
    display: "inline",
  },
  chipList: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },
  tagChip: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    "& h1": {
      marginBottom: theme.spacing(3),
    },
    "& h2": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& p": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& li": {
      marginLeft: theme.spacing(4),
    },
  },
  infoCard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})
export default withStyles(styles)(ProjectDocsDetails)
