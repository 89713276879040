import React, { useState, useContext, useEffect } from "react"
import { Grid, Typography } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import gql from "graphql-tag"
import ConversationList from "./ConversationList"
import ConversationDetails from "../../../../../Users/Messages/ConversationDetails"
import SortContext from "../../../../../../context/SortContext"
const noDataResponse = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <Typography variant="h3">
            This person has no conversations on record.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
const Conversations = ({ person }) => {
  const uid = person.uid
  const [conversationSelected, setConversationSelected] = useState(false)
  const [selectedConversation, setSelectedConversation] = useState("")
  const { state, dispatch } = useContext(SortContext)
  const search = ""
  const { loading, error, data } = useQuery(PERSON_CONVERSATIONS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      uid: uid,
      page: state.page,
      pageSize: state.pageSize,
      search: state.search,
    },
    onCompleted: data => {
      console.log(data)
    },
  })
  useEffect(() => {
    dispatch({ type: "SET_PAGE_SIZE", payload: 15 })
    dispatch({ type: "SET_PAGE", payload: 0 })
    dispatch({ type: "SET_SEARCH", payload: "" })
  }, [])
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) {
    return { noDataResponse }
  }
  if (data == null) {
    return { noDataResponse }
  }
  const personConversations = data.personConversations

  return (
    <>
      <Grid container spacing={3}>
        {conversationSelected ? (
          <Grid item md={12} xl={12} xs={12}>
            {/* {selectedConversation && selectedConversation.uuid} */}
            <ConversationDetails selectedConversation={selectedConversation} />
          </Grid>
        ) : (
          <Grid item md={12} xl={12} xs={12}>
            <ConversationList
              person={person}
              personConversations={personConversations}
              setConversationSelected={setConversationSelected}
              setSelectedConversation={setSelectedConversation}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

const PERSON_CONVERSATIONS_QUERY = gql`
  query ($page: Int!, $pageSize: Int!, $search: String, $uid: String) {
    personConversations(
      page: $page
      pageSize: $pageSize
      search: $search
      uid: $uid
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        uuid
        conversationMessage {
          uuid
          messageFile
          messageFileUrl
          message
        }
        startedBy {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        invited {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        participants {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        category {
          name
        }
        topic
        sbu {
          name
        }
        startDate
        archived
        archivedDate
      }
    }
  }
`
export default Conversations
