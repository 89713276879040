import React, { useState } from "react"
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  makeStyles,
} from "@material-ui/core"
import { useQuery } from "@apollo/client"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import gql from "graphql-tag"
import Page from "../../../Global/Page"

import Header from "./Header"
import Details from "./Details"
import SupplierPeople from "./SupplierPeople"
import SupplierAssets from "./SupplierAssets"
import SupplierAccount from "./SupplierAccount"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const SupplierDetailsView = ({ ouid }) => {
  const id = ouid
  const classes = useStyles()

  const [currentTab, setCurrentTab] = useState("details")
  const { loading, error, data } = useQuery(SUPPLIER_DETAILS_QUERY, {
    variables: {
      ouid: id,
    },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      console.log(data)
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const supplier = data.org

  const tabs = [
    { value: "details", label: "Details" },
    { value: "people", label: "People" },
    { value: "assets", label: "Assets" },
    { value: "account", label: "Account" },
  ]

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  return (
    <Page className={classes.root} title="Contact Details">
      <Container maxWidth={false}>
        <Header supplier={supplier} />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === "details" && <Details supplier={supplier} />}
          {currentTab === "people" && <SupplierPeople supplier={supplier} />}
          {currentTab === "account" && <SupplierAccount supplier={supplier} />}
          {currentTab === "assets" && <SupplierAssets supplier={supplier} />}
        </Box>
      </Container>
    </Page>
  )
}

const SUPPLIER_DETAILS_QUERY = gql`
  query ($ouid: String) {
    org(ouid: $ouid) {
      id
      ouid
      name
      nickname
      about
      dateAdded
      dateModified
      people {
        uid
        firstName
        lastName
      }
      orgAdmin {
        id
        email
        person {
          uid
          firstName
          lastName
        }
      }
      emailOrg {
        id
        emailAddress
      }
      addressOrg {
        id
        address {
          uid
          region {
            id
            name
          }
          summary
        }
        createdAt
        modifiedAt
      }
      orgSocialMedia {
        id
        socialMediaType {
          id
          name
          url
        }
        url
        username
      }
      phoneOrg {
        id
        phoneType {
          id
          phoneType
        }
        phoneNumber
      }
    }
  }
`
export default SupplierDetailsView
