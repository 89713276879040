import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import moment from "moment"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import SortContext from "../../../../context/SortContext"

import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  InputAdornment,
  Paper,
  SvgIcon,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  Edit as EditIcon,
  Search as SearchIcon,
  ArrowRight as ArrowRightIcon,
  X as CancelIcon,
} from "react-feather"

import Label from "../../../Label"
import GenericMoreButton from "../../../../components/Global/GenericMoreButton"
import BulkOperations from "./BulkOperations"
import { sortBy } from "lodash"

// function applyPagination(orders, page, limit) {
//   return orders.slice(page * limit, page * limit + limit)
// }
const tabs = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "inProgress",
    label: "In Progress",
  },
  {
    value: "fromCart",
    label: "From Cart",
  },
]

const businessUnitOptions = [
  {
    id: 0,
    name: "All",
  },
  {
    id: 1,
    name: "Jersey Emblems",
  },
  {
    id: 2,
    name: "ICON Emblem",
  },
  {
    id: 3,
    name: "Brians Beanies",
  },
  {
    id: 4,
    name: "Decide Direct",
  },
]
const sortOptions = [
  {
    value: "updatedAt|desc",
    label: "Last update (newest first)",
  },
  {
    value: "updatedAt|asc",
    label: "Last update (oldest first)",
  },
  {
    value: "orders|desc",
    label: "Total orders (high to low)",
  },
  {
    value: "orders|asc",
    label: "Total orders (low to high)",
  },
]
const orderStatusOptions = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "new",
    name: "New",
  },
  {
    id: "inProduction",
    name: "In Production",
  },
  {
    id: "shipped",
    name: "Shipped",
  },
  {
    id: "completed",
    name: "Completed",
  },
  {
    id: "onHold",
    name: "On Hold",
  },
  {
    id: "cancelled",
    name: "Cancelled",
  },
  {
    id: "discrepancy",
    name: "Discrepancy",
  },
]

const statusColors = {
  new: "primary",
  inProduction: "secondary",
  shipped: "secondary",
  canceled: "error",
  onHold: "warning",
  completed: "success",
  discrepancy: "error",
}

const useStyles = makeStyles(theme => ({
  root: {},
  table: {
    marginTop: 5,
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  name: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

const Results = ({
  className,
  orderData,
  orderStatusFilter,
  setOrderStatusFilter,
  inProgressFilter,
  setInProgressFilter,
  fromCartFilter,
  setFromCartFilter,
  businessUnitFilter,
  setBusinessUnitFilter,
  ...rest
}) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(SortContext)
  const resCount = orderData.count
  const resPage = parseFloat(orderData.page)
  const paginationPage = resPage - 1
  const resPages = orderData.pages
  const orders = orderData.objects
  const page = state.page
  const pageSize = state.pageSize
  const searchContext = state.search
  const [currentTab, setCurrentTab] = useState("all")
  const [searchBar, setSearchBar] = useState(searchContext)
  const [selectedOrders, setSelectedOrders] = useState(orders)

  const [limit, setLimit] = useState(10)
  const [sort, setSort] = useState(sortOptions[0].value)

  const handleFromCartChange = event => {
    event.persist()

    let value = null

    if (event.target.checked) {
      value = true
    }

    setFromCartFilter(value)
  }

  const handleInProgressChange = event => {
    event.persist()

    let value = null

    if (event.target.checked) {
      value = true
    }
    setInProgressFilter(value)
  }

  const handleBusinessUnitChange = event => {
    event.persist()

    let value = 0

    if (event.target.value !== 0) {
      value = event.target.value
    }

    setBusinessUnitFilter(value)
  }

  const handleOrderStatusChange = event => {
    event.persist()

    let value = ""

    if (event.target.value !== "all") {
      value = event.target.value
    }
    setOrderStatusFilter(value)
  }

  const handleSortChange = event => {
    event.persist()
    setSort(event.target.value)
  }

  const handlePageChange = (event, newPage) => {
    let x = 1
    const pagiNewPage = newPage
    dispatch({ type: "SET_PAGE", payload: pagiNewPage })
  }

  const handleLimitChange = event => {
    setLimit(event.target.value)
  }

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
  }

  const handleClearFilters = event => {
    setBusinessUnitFilter(0)
    setFromCartFilter(null)
    setInProgressFilter(null)
    setOrderStatusFilter("")
  }

  const handleTabsChange = (event, value) => {
    // const updatedFilters = {
    //   ...filters,
    //   billsDue: null,
    //   current: null,
    // }

    // if (value === "all") {
    //   updatedFilters[value] = true
    // }
    setCurrentTab(event.target.value)
    if (value === "all") {
      setFromCartFilter(null)
      setInProgressFilter(null)
    }
    if (value === "inProgress") {
      setFromCartFilter(null)
      setInProgressFilter(true)
    }
    if (value === "fromCart") {
      setFromCartFilter(true)
      setInProgressFilter(null)
    }
    // setFilters(updatedFilters)
    // setSelectedOrgs([])
  }
  return (
    <>
      <Card className={clsx(classes.root, classes.roundedCard)}>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map(tab => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
        <Divider />
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={10} sm={5}>
              <Paper
                component="form"
                onSubmit={event => {
                  event.preventDefault()
                  handleSearch(event)
                }}
                className={classes.searchField}
              >
                <InputBase
                  fullWidth
                  className={classes.input}
                  placeholder="Search Orders"
                  inputProps={{ "aria-label": "Search Orders" }}
                  onChange={event => setSearchBar(event.target.value)}
                  value={searchBar}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={handleClearSearchResults}
                >
                  <CancelIcon />
                </IconButton>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Sort By"
                name="sort"
                onChange={handleSortChange}
                select
                SelectProps={{ native: true }}
                value={sort}
                variant="outlined"
              >
                {sortOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>

        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                className={classes.businessUnitField}
                label="Business Unit"
                name="businessUnit"
                onChange={handleBusinessUnitChange}
                select
                SelectProps={{ native: true }}
                value={businessUnitFilter}
                variant="outlined"
              >
                <option></option>
                {businessUnitOptions.map(businessUnitOption => (
                  <option
                    key={businessUnitOption.id}
                    value={businessUnitOption.id}
                  >
                    {businessUnitOption.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                className={classes.orderStatusField}
                fullWidth
                label="Order Status"
                name="orderStatus"
                onChange={handleOrderStatusChange}
                select
                SelectProps={{ native: true }}
                value={orderStatusFilter}
                variant="outlined"
              >
                <option></option>
                {orderStatusOptions.map(orderStatusOption => (
                  <option
                    key={orderStatusOption.id}
                    value={orderStatusOption.id}
                  >
                    {orderStatusOption.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormControlLabel
                className={classes.shippableField}
                control={
                  <Checkbox
                    checked={inProgressFilter}
                    onChange={handleInProgressChange}
                    name="In Progress"
                  />
                }
                label="In Progress"
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormControlLabel
                className={classes.shippableField}
                control={
                  <Checkbox
                    checked={fromCartFilter}
                    onChange={handleFromCartChange}
                    name="From Cart"
                  />
                }
                label="From Cart"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearFilters}
                fullWidth
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" gutterBottom variant="body2">
                {resCount} Records found. Page {resPage} of {resPages}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <PerfectScrollbar>
          <Box minWidth={1150}>
            <Divider />
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>ID / Order Date</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Business Unit</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Balance Due</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map(order => {
                  const isOrderSelected = selectedOrders.includes(order.id)
                  // const orderStatus = order.status.orderStatus
                  return (
                    <TableRow
                      hover
                      key={order.id}
                      selected={selectedOrders.indexOf(order.id) !== -1}
                    >
                      <TableCell>
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          component={Link}
                          to={`/manager/orders/${order.id}`}
                          state={{ id: `${order.id}` }}
                        >
                          {order.id}
                        </Button>
                        <Typography variant="body2" color="textSecondary">
                          {moment(order.orderDate).format(
                            "DD MMM YYYY | hh:mm"
                          )}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        {order.customer.firstName} {order.customer.lastName}
                      </TableCell>

                      <TableCell>{order.businessUnit.name}</TableCell>

                      <TableCell>
                        {/* <Label> */}
                        {/* <Label color={statusColors[order.status]}> */}
                        {/* {orderStatus && orderStatus} */}
                        {/*  </Label> */}
                      </TableCell>
                      <TableCell>{order.balanceDue}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          component={Link}
                          to={`/manager/orders/${order.id}`}
                          state={{ id: `${order.id}` }}
                        >
                          <SvgIcon fontSize="small">
                            <ArrowRightIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={resCount}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={paginationPage}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array,
}

Results.defaultProps = {
  orders: [],
}

export default Results
