import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

const useStyles = makeStyles(theme => ({
  root: {},
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
}))

function Header({ className, ...rest }) {
  const classes = useStyles()

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            className={classes.link}
            color="inherit"
            to="/manager/home"
          >
            Dashboard
          </Link>
          <Link
            variant="body1"
            className={classes.link}
            color="inherit"
            to="/manager/items"
          >
            Orders
          </Link>
          <Typography variant="body1" color="textPrimary">
            New Order
          </Typography>
        </Breadcrumbs>
        <Box mt={2}>
          <Typography variant="h3" color="textPrimary">
            Add a new order
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          color="secondary"
          component={Link}
          to="/manager/orders"
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
