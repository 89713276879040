import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Box, Grid, Button, Typography } from "@material-ui/core"

import SamplesProofs from "../SamplesProofs"
import UploadItemImage from "./UploadItemImage"
import AllFiles from "./AllFiles"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
const Files = ({ item, className, ...rest }) => {
  const [section, setSection] = useState("all")
  const [files, setFiles] = useState([])
  const [workingFiles, setWorkingFiles] = useState([])

  const { data, loading, error } = useQuery(ALL_FILES_QUERY, {
    variables: {
      id: item.id,
    },
    onCompleted: data => {
      setWorkingFiles(data.item.itemWorkingFile)
      setFiles(data.item.itemImage)
    },
  })
  const sectionSwitch = section => {
    switch (section) {
      case "samplesProofs":
        return <SamplesProofs item={item} />
      case "all":
        return (
          <AllFiles
            item={item}
            files={files}
            setFiles={setFiles}
            setWorkingFiles={setWorkingFiles}
            workingFiles={workingFiles}
          />
        )
      case "upload":
        return (
          <UploadItemImage
            item={item}
            files={files}
            setFiles={setFiles}
            workingFiles={workingFiles}
            setWorkingFiles={setWorkingFiles}
          />
        )
      default:
        return <AllFiles item={item} />
    }
  }
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Button
            fullWidth
            variant={section === "all" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("all")}
          >
            User + Working Files
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            fullWidth
            variant={section === "samplesProofs" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("samplesProofs")}
          >
            Samples + Proofs
          </Button>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Button
            fullWidth
            variant={section === "upload" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("upload")}
            // disabled={asset === "inquiries"}
          >
            Upload
          </Button>
        </Grid>
      </Grid>
      <Box mt={3}>{sectionSwitch(section)}</Box>
    </>
  )
}

const ALL_FILES_QUERY = gql`
  query ($id: Int!) {
    item(id: $id) {
      id
      name
      itemWorkingFile {
        id
        workingFile
        workingFileUrl
        isActive
        timestamp
        version
        comments
      }
      itemImage {
        id
        image
        title
        note
        featured
        proof
        createdBy {
          id
          staff
          partner
          person {
            firstName
            lastName
            isSupplier
          }
        }
        uploadedAt
        imageUrl
        carouselImageUrl
        item {
          id
        }
      }
      sampleProofItem {
        id
        status {
          id
          name
        }
        version
        image
        timestamp
        closed
        closedTimestamp
        sampleProofComment {
          id
          comment
          commentPerson {
            uid
            firstName
            lastName
          }
        }
        sampleProofUrl
      }
    }
  }
`
export default Files
