import React, { useContext, useState, useEffect } from "react"
// import { useHistory, useParams } from "react-router"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Avatar,
  Box,
  Hidden,
  Grid,
  IconButton,
  Input,
  Link,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import TrackChangesIcon from "@material-ui/icons/TrackChanges"
import StarIcon from "@material-ui/icons/Star"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import SearchIcon from "@material-ui/icons/Search"
import MessagesContext from "../context/MessagesContext"
import { SettingsInputAntennaOutlined } from "@material-ui/icons"
import getInitials from "../../../../utils/getInitials"
import moment from "moment"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    height: 68,
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
  avatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  info: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 150,
      maxWidth: 160,
      overflow: "hidden",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 500,
      maxWidth: 900,
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
}))

function Toolbar({ className, userConversation, ...rest }) {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)
  const [star, setStar] = useState(userConversation.star)
  const [follow, setFollow] = useState(userConversation.follow)
  const [alerts, setAlerts] = useState(userConversation.alerts)

  const [updateFollow, { loading, data, error }] = useMutation(
    UPDATE_FOLLOW_USER_CONVERSATION_MUTATION,
    {
      variables: {
        conversationId: userConversation.conversation.uuid,
        star: star,
        follow: follow,
        alerts: alerts,
      },
    }
  )
  useEffect(() => {
    updateFollow()
  }, [star, follow, alerts])

  const handleBack = () => {
    dispatch({ type: "SET_PANEL", payload: "conversationList" })
    dispatch({ type: "SET_SELECTED_CONVERSATION_ID", payload: "" })
  }
  // if (loading) return <Loading />
  if (error) return <Error />
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Tooltip title="Back">
        <IconButton onClick={handleBack}>
          <ArrowBackIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Box mr={2}>
        <Avatar className={classes.avatar}>
          {getInitials(userConversation.conversation.category.name)}
        </Avatar>
      </Box>
      <Box className={classes.info}>
        <Typography variant="h4" noWrap color="textSecondary">
          {userConversation.conversation.topic}
        </Typography>

        <Typography variant="subtitle2" noWrap color="textSecondary">
          Started by:{" "}
          <Link
            className={classes.link}
            to={`/manager/people/${userConversation.conversation.startedBy.person.uid}`}
            state={{
              uid: `${userConversation.conversation.startedBy.person.uid}`,
            }}
          >
            {userConversation.conversation.startedBy.person.firstName}{" "}
            {userConversation.conversation.startedBy.person.lastName}
          </Link>{" "}
          {moment(userConversation.conversation.startDate).format(
            "MMM Do, YYYY"
          )}
        </Typography>
      </Box>

      <Box flexGrow={1} />
      <Tooltip title="Star">
        <IconButton
          color={star ? "secondary" : "primary"}
          size="small"
          onClick={() => setStar(!star)}
        >
          <StarIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Follow">
        <IconButton
          color={follow ? "secondary" : "primary"}
          size="small"
          onClick={() => setFollow(!follow)}
        >
          <TrackChangesIcon />
        </IconButton>
      </Tooltip>

      {/* <Tooltip title="Previous conversation">
        <IconButton>
          <KeyboardArrowLeftIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Next conversation">
        <IconButton>
          <KeyboardArrowRightIcon fontSize="small" />
        </IconButton>
      </Tooltip> */}
    </div>
  )
}

Toolbar.propTypes = {
  className: PropTypes.string,
}
const UPDATE_FOLLOW_USER_CONVERSATION_MUTATION = gql`
  mutation (
    $conversationId: String
    $follow: Boolean
    $star: Boolean
    $alerts: Boolean
  ) {
    updateFollowUserConversation(
      conversationId: $conversationId
      follow: $follow
      star: $star
      alerts: $alerts
    ) {
      userConversation {
        id
        conversation {
          uuid
        }
        follow
        star
        alerts
      }
    }
  }
`
export default Toolbar
