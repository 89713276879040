import React, { useState, useContext } from "react"
import clsx from "clsx"
import moment from "moment"
import { Link } from "gatsby"
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import PerfectScrollbar from "react-perfect-scrollbar"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import SortContext from "../../../../../../context/SortContext"
const useStyles = makeStyles(() => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
}))

const InquiriesList = ({ personInquiries, className, ...rest }) => {
  const classes = useStyles()
  const [inquiries, setInquiries] = useState(personInquiries.objects)
  const { state, dispatch } = useContext(SortContext)
  const handleChange = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
  }
  if (personInquiries.count === 0) {
    return (
      <div>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardHeader title="No Inquiries on Record" />
          <Divider />
        </Card>
      </div>
    )
  }

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          title="Inquiries"
          action={
            personInquiries.pages > 1 && (
              <Pagination
                count={personInquiries.pages}
                page={state.page}
                onChange={handleChange}
              />
            )
          }
        />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell>Items</TableCell>
                  <TableCell>Business Unit</TableCell>
                  <TableCell>Date Processed</TableCell>
                  <TableCell>Processed By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inquiries.map(inquiry => (
                  <TableRow key={inquiry.id}>
                    <TableCell>
                      <Link to={`/manager/inquiries/${inquiry.id}`}>
                        {inquiry.id}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {moment(inquiry.createdAt).format("DD/MM/YYYY | HH:MM")}
                    </TableCell>

                    <TableCell>
                      {inquiry.inquiryInquiryItem.map(item => {
                        const description = JSON.parse(item.description)
                        return (
                          <div key={item.id}>
                            {description.title} |{" "}
                            {item.attachmentUrl && (
                              <a href={item.attachmentUrl}>file upload</a>
                            )}
                          </div>
                        )
                      })}
                    </TableCell>
                    <TableCell>{inquiry.businessUnit.name}</TableCell>
                    <TableCell>
                      {" "}
                      {inquiry.processedAt
                        ? moment(inquiry.processedAt).format(
                            "DD/MM/YYYY | HH:MM"
                          )
                        : "Not Processed"}
                    </TableCell>
                    <TableCell>
                      {inquiry.processedBy &&
                        inquiry.processedBy.person.firstName +
                          " " +
                          inquiry.processedBy.person.lastName}{" "}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

export default InquiriesList
