import React, { useState } from "react"

import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  FormControl,
  MenuItem,
  TextField,
  Typography,
  Card,
  CardHeader,
  Checkbox,
  makeStyles,
  Select,
  InputLabel,
} from "@material-ui/core"
// import { KeyboardDatePicker } from '@material-ui/pickers'
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import clsx from "clsx"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import PROJECT_TODOS_QUERY from "./index"

const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: 0,
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: "space-between",
  },
  addTab: {
    marginLeft: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
  },
  datePicker: {
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
}))

function valuetext(value) {
  return { value }
}

const CreateTask = ({
  project,
  projectTasks,
  setProjectTasks,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const projectId = project.id
  const projectTitle = project.title

  const [title, setTitle] = useState("")
  const [assignedToId, setAssignedToId] = useState("")
  const [categoryId, setCategoryId] = useState("")
  const [dueDate, setDueDate] = useState(null)
  const [completed, setCompleted] = useState(false)
  const [completedDate, setCompletedDate] = useState(null)
  const [note, setNote] = useState("")
  const [priority, setPriority] = useState(1)
  const [minutesEstimate, setMinutesEstimate] = useState(null)
  const [minutesReported, setMinutesReported] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const handleCompleted = event => {
    setCompleted(event.target.checked)
  }

  const handleClear = event => {
    setTitle("")
    setCompletedDate(null)
    setMinutesEstimate(null)
    setMinutesReported(null)
    setCategoryId("")
    setAssignedToId(1)
    setTitle("")
    setPriority(1)
    setCompleted(false)
    setNote("")
    setDueDate("")
    setAssignedToId(null)
  }
  const { loading, error, data } = useQuery(TASK_FORM_QUERY, {
    fetchPolicy: "cache-and-network",
  })

  // const handleUpdateCache = (cache, { data: { handleSubmit } }) => {
  //   const data = cache.readQuery({ query: PROJECT_TODOS_QUERY })
  //   const tasks = data.tasks.concat(handleSubmit.task)
  //   cache.writeQuery({ query: PROJECT_TODOS_QUERY, data: { tasks } })
  // }

  const [handleSubmit] = useMutation(CREATE_PROJECT_TASK_MUTATION, {
    variables: {
      title: title,
      projectId: projectId,
      categoryId: categoryId,
      assignedToId: assignedToId,
      dueDate: dueDate,
      completed: completed,
      completedDate: completedDate,
      note: note,
      priority: priority,
      minutesEstimate: minutesEstimate,
      minutesReported: minutesReported,
    },
    onCompleted: (data, errors) => {
      let taskData = data.createTask.task.taskForProject
      const newTask = { ...taskData }
      setProjectTasks(tasks => [...tasks, newTask])

      setTitle("")
      setCompletedDate(null)
      setMinutesEstimate(null)
      setMinutesReported(null)
      setCategoryId(null)
      setAssignedToId(null)
      setTitle("")
      setPriority(1)
      setCompleted(false)
      setNote("")
      setDueDate(null)
      setSubmitting(false)
    },
    onError: err => console.error(err),
  })

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return "something is wrong"
  if (data == null) {
    return "something is wrong"
  }
  const staff = data.staff
  const categories = data.taskCategories
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          className={classes.header}
          title={
            <Typography
              display="block"
              variant="overline"
              color="textSecondary"
            >
              New Task
            </Typography>
          }
        />
        <Box m={2}>
          <form
            onSubmit={event => {
              event.preventDefault()
              setSubmitting(true)
              handleSubmit(event)
            }}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <TextField
              fullWidth
              label="Task Name"
              name="taskTitle"
              value={title}
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.field}
              onChange={event => setTitle(event.target.value)}
            />

            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={note}
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.field}
              onChange={event => setNote(event.target.value)}
            />

            <TextField
              id="datetime-local"
              label="Due Date"
              type="datetime-local"
              variant="outlined"
              className={classes.field}
              fullWidth
              value={dueDate}
              onChange={event => setDueDate(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* <KeyboardDatePicker
                className={classes.datePicker}
                label="Start Date"
                format="MM/DD/YYYY"
                name="startDate"
                inputVariant="outlined"
                value={values.startDate}
                onBlur={() => setFieldTouched('startDate')}
                onClose={() => setFieldTouched('startDate')}
                onAccept={() => setFieldTouched('startDate')}
                onChange={(date) => setFieldValue('startDate', date)}
              /> */}
            <TextField
              label="Category"
              id="category"
              onChange={event => setCategoryId(event.target.value)}
              select
              margin="dense"
              size="small"
              fullWidth
              variant="outlined"
              className={classes.field}
              value={categoryId}
            >
              <MenuItem value={null}>
                <em>Select a Category</em>
              </MenuItem>
              {categories.map(cat => {
                const catId = parseInt(cat.id)
                return (
                  <MenuItem key={cat.id} value={catId}>
                    {catId} {cat.name}
                  </MenuItem>
                )
              })}
            </TextField>

            <TextField
              id="assigned-to"
              value={assignedToId}
              select
              margin="dense"
              size="small"
              onChange={event => setAssignedToId(event.target.value)}
              label="Assigned To"
              variant="outlined"
              className={classes.field}
              fullWidth
            >
              <MenuItem value="Select a Staff Member">
                <em>Select a Staff Member</em>
              </MenuItem>

              {staff.map(st => (
                <MenuItem key={st.id} value={st.id}>
                  {st.person.firstName} {st.person.lastName}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Priority"
              id="task-priority"
              select
              value={priority}
              margin="dense"
              size="small"
              onChange={event => setPriority(event.target.value)}
              className={classes.field}
              variant="outlined"
              fullWidth
            >
              <MenuItem value={1}>1 - Highest Priority</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5 - Lowest Priority</MenuItem>
            </TextField>

            <TextField
              fullWidth
              label="Minutes to Complete (estimate)"
              value={minutesEstimate}
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.field}
              onChange={event => setMinutesEstimate(event.target.value)}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={completed}
                  onChange={handleCompleted}
                  name="completed"
                  color="primary"
                />
              }
              label="Already Completed"
            />

            <Collapse in={completed} timeout="auto" unmountOnExit>
              <TextField
                id="datetime-local-completed"
                label="Completed Date"
                type="datetime-local"
                variant="outlined"
                fullWidth
                value={completedDate}
                onChange={event => setCompletedDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  native: true,
                }}
                className={classes.field}
              />

              <TextField
                fullWidth
                label="Minutes to Complete (reported)"
                value={minutesReported}
                variant="outlined"
                className={classes.field}
                margin="dense"
                size="small"
                onChange={event => setMinutesReported(event.target.value)}
              />
            </Collapse>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                onClick={handleClear}
                variant="outlined"
                color="secondary"
                size="small"
                disabled={submitting}
              >
                Cancel
              </Button>

              <Button
                color="secondary"
                disabled={submitting}
                type="submit"
                variant="contained"
                size="small"
              >
                Add Task
              </Button>
            </Box>
          </form>
        </Box>
      </Card>
    </>
  )
}

const CREATE_PROJECT_TASK_MUTATION = gql`
  mutation (
    $title: String!
    $categoryId: Int
    $projectId: Int
    $dueDate: DateTime
    $completedDate: DateTime
    $completed: Boolean
    $assignedToId: Int
    $note: String
    $priority: Int
    $minutesEstimate: Int
    $minutesReported: Int
  ) {
    createTask(
      title: $title
      categoryId: $categoryId
      projectId: $projectId
      dueDate: $dueDate
      completedDate: $completedDate
      completed: $completed
      assignedToId: $assignedToId
      note: $note
      priority: $priority
      minutesEstimate: $minutesEstimate
      minutesReported: $minutesReported
    ) {
      task {
        id
        taskForProject {
          id
          project {
            id
          }
          task {
            id
            title
            category {
              id
              name
            }
            completed
            completedDate
            createdBy {
              id
              person {
                uid
                firstName
                lastName
              }
            }
            createdDate
            dueDate
            assignedTo {
              id
              person {
                uid
                firstName
                lastName
              }
            }
            note
            priority
            minutesEstimate
            minutesReported
          }
        }
      }
    }
  }
`

const TASK_FORM_QUERY = gql`
  query {
    staff {
      id
      person {
        uid
        firstName
        lastName
      }
    }
    taskCategories {
      id
      name
    }
  }
`
export default CreateTask
