import React, { useContext } from "react"
import { navigate } from "gatsby"
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import SearchIcon from "@material-ui/icons/Search"
import FaceIcon from "@material-ui/icons/Face"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import SearchPerson from "./SearchPerson"
import AddPerson from "./AddPerson"
import OrgCreateContext from "../context/OrgCreateContext"

const ListPeople = () => {
  const { state, dispatch } = useContext(OrgCreateContext)
  const org = state.org
  const orgPeople = state.orgPeople

  return (
    <>
      {orgPeople.length === 0 ? (
        <Box m={2}>
          <Typography variant="h4">{org.name ? org.name : ""}</Typography>
          <Typography variant="body1">No people in this org yet.</Typography>
        </Box>
      ) : (
        <List>
          {orgPeople.map(p => {
            const firstName = p.firstName ? p.firstName : ""
            const lastName = p.lastName ? p.lastName : ""
            return (
              <ListItem
                key={p.uid}
                button
                onClick={() => navigate(`/manager/people/${p.uid}`)}
              >
                <ListItemIcon>
                  <FaceIcon />
                </ListItemIcon>{" "}
                <ListItemText>
                  {firstName} {lastName}
                </ListItemText>
              </ListItem>
            )
          })}
        </List>
      )}
      <Button
        fullWidth
        style={{ marginBottom: "10px" }}
        variant="outlined"
        color="secondary"
        startIcon={<SearchIcon />}
        onClick={() => dispatch({ type: "SET_PEOPLE_VIEW", payload: "search" })}
      >
        Find People
      </Button>
      <Button
        fullWidth
        style={{ marginBottom: "10px" }}
        variant="outlined"
        color="secondary"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => dispatch({ type: "SET_PEOPLE_VIEW", payload: "add" })}
      >
        Add New People
      </Button>
    </>
  )
}

const AddOrgPeople = ({ classes }) => {
  const { state, dispatch } = useContext(OrgCreateContext)
  const peopleView = state.peopleView
  const ouid = state.ouid

  const addPeopleSwitch = peopleView => {
    switch (peopleView) {
      case "search":
        return <SearchPerson />
      case "add":
        return <AddPerson />
      case "listView":
        return <ListPeople />
      default:
        return <ListPeople />
    }
  }
  return (
    <div>
      <Typography variant="h3">People</Typography>
      <Box mt={2}>{addPeopleSwitch(peopleView)}</Box>
      <Divider className={classes.divider} />
      <Box mt={2}>
        <Grid container justify="space-between">
          <Grid item>
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={() => navigate(`manager/orgs/${ouid}`)}
            >
              View Org Now
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => dispatch({ type: "SET_ACTIVE_STEP", payload: 2 })}
            >
              Add More Info
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

const styles = theme => ({
  root: {},
  button: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})
export default withStyles(styles)(AddOrgPeople)
