import React, { useState, useContext, useEffect } from "react"
import clsx from "clsx"

import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core"
import {
  Check as CheckIcon,
  MinusCircle as CancelIcon,
  Edit as EditIcon,
  XCircle as DeleteIcon,
  Search as SearchIcon,
  PlusCircle as AddIcon,
} from "react-feather"

import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import OrderContext from "./context/OrderContext"
// import OiCard from "./OiCard"
import OrderItemCard from "./OrderItemCard"

const useStyles = makeStyles(theme => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
  redDelete: {
    color: "#FF0000",
  },
  grow: {
    display: "inline",
    flexGrow: 1,
  },
  iconButton: {
    paddingLeft: theme.spacing(2),
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

const OrderItems = ({
  // id,
  // addOrderItem,
  // setAddOrderItem,
  // search,
  // setSearch,
  // createNew,
  // setCreateNew,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(OrderContext)
  const orderId = state.orderDetails.id
  const isCompleted = state.orderDetails.completed
  const [count, setCount] = useState(0)
  const [refresh, setRefresh] = useState(false)
  const [resPage, setResPage] = useState(1)

  const searchContext = ""
  const {
    pageSize,
    page,
    search,
    orderItems,
    isAddingOrderItem,
    isSearching,
    createNewItem,
  } = state
  // useEffect(() => {
  //   dispatch({ type: "SET_ORDER_ID", payload: id })
  // }, [id])

  // useEffect(() => {
  //   if (refresh) {
  //     console.log("refresh")
  //   }
  //   setRefresh(!refresh)
  // }, [refresh])

  const { data, loading, error } = useQuery(ORDER_ITEMS_QUERY, {
    variables: {
      orderId: orderId,
      page: page,
      pageSize: pageSize,
      search: searchContext,
    },
    fetchPolicy: "network-only",
    onCompleted: (data, response, errors) => {
      console.log("fetched order items")
      dispatch({
        type: "SET_ORDER_ITEMS",
        payload: data.orderItemsByOrder.objects,
      })
      setCount(data.orderItemsByOrder.count)
      setResPage(data.orderItemsByOrder.page)
    },
  })

  // Todo: Pagination
  // const handlePageChange = (event, newPage) => {
  //   let x = 1
  //   const pagiNewPage = newPage
  //   dispatch({ type: "SET_PAGE", payload: pagiNewPage })
  // }
  // Todo: Pagination
  // const handleChangeRowsPerPage = event => {
  //   dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
  //   dispatch({ type: "SET_PAGE", payload: 0 })
  // }

  const handleSetSearch = event => {
    dispatch({ type: "TOGGLE_IS_SEARCHING" })
    dispatch({ type: "TOGGLE_IS_ADDING_ORDER_ITEM" })
    // setAddOrderItem(true)
  }

  const handleCreateNew = event => {
    // setCreateNew(true)
    // setAddOrderItem(true)
    dispatch({ type: "TOGGLE_CREATE_NEW_ORDER_ITEM" })
    dispatch({ type: "TOGGLE_IS_ADDING_ORDER_ITEM" })
  }

  if (loading) return <Loading />
  // if (loading) return "loading..."
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }

  return (
    <Card className={classes.roundedCard}>
      <CardHeader
        title="Order items"
        action={
          <>
            <IconButton
              disabled={isSearching || createNewItem}
              className={classes.iconButton}
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleSetSearch}
            >
              <SearchIcon />
            </IconButton>

            <IconButton
              disabled={isSearching || createNewItem}
              className={classes.iconButton}
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleCreateNew}
            >
              <AddIcon />
            </IconButton>
          </>
        }
      />

      <Divider />
      <Grid container direction="row" justify="space-between">
        <Grid item xs={12}>
          {orderItems.map(oi => (
            <Box mt={1} key={oi.id}>
              <OrderItemCard
                oi={oi}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </Box>
          ))}
        </Grid>
      </Grid>
    </Card>
  )
}

const ORDER_ITEMS_QUERY = gql`
  query ($orderId: Int, $page: Int, $pageSize: Int, $search: String) {
    orderItemsByOrder(
      orderId: $orderId
      page: $page
      pageSize: $pageSize
      search: $search
    ) {
      page
      pages
      hasNext
      hasPrev
      count
      objects {
        id
        itemSubtotal
        itemTotal
        customFields
        tax {
          id
          code
          name
          taxRate
        }
        taxAmt
        discount {
          id
          code
          name
          discountRate
        }
        discountAmt
        priceEach
        qty
        item {
          id
          name
          featuredImageThumbnailUrl
          category {
            id
            name
          }
          businessUnit {
            id
            name
          }
          isActive
          isPrivate
          pricingModel {
            id
            name
          }
          itemPrice {
            id
            minQuantity
            maxQuantity
            price
          }
        }
      }
    }
  }
`

export default OrderItems
