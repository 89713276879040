import React, { useState, useEffect } from "react"
import clsx from "clsx"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import DoneIcon from "@material-ui/icons/Done"

import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import useDidMountEffect from "../../../../Global/hooks/useDidMountEffect"
const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 250,
  },
  delete: {
    color: theme.palette.text.danger,
  },
  roundedCard: {
    borderRadius: "15px",
    paddingBottom: theme.spacing(1),
  },
}))
const ItemPricing = ({ item, className, ...rest }) => {
  const classes = useStyles()
  const itemId = item.id
  const pricingModelId = item.pricingModel.id
  const [editing, setEditing] = useState(false)
  const [editingPrice, setEditingPrice] = useState(0)
  const [editingPriceId, setEditingPriceId] = useState(0)
  const [price, setPrice] = useState("")
  const [minQuantity, setMinQuantity] = useState(0)
  const [maxQuantity, setMaxQuantity] = useState(0)
  const [isActive, setIsActive] = useState(true)
  const [priceName, setPriceName] = useState("")
  const [prices, setPrices] = useState(item.itemPrice)

  const [deletingPrice, setDeletingPrice] = useState(0)
  const [deletingPriceId, setDeletingPriceId] = useState(0)
  const [newArray, setNewArray] = useState([])
  const [success, setSuccess] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  //   const prices = item.itemPrice
  const [addPrices, setAddPrices] = useState(false)

  const [createPrice] = useMutation(CREATE_PRICE_MUTATION, {
    variables: {
      itemId: itemId,
      price: price,
      minQuantity: minQuantity,
      maxQuantity: maxQuantity,
      priceName: priceName,
      isActive: isActive,
    },
    onCompleted: (data, response, errors) => {
      let priceData = data.createItemPrice.itemPrice
      console.log(priceData)
      const newPrice = { ...priceData }
      setPrices(prices => [...prices, newPrice])
      setPrice("")
      setMinQuantity("")
      setMaxQuantity("")
      setSuccess(true)
    },
  })
  const [updatePrice] = useMutation(UPDATE_ITEM_PRICE_MUTATION, {
    variables: {
      itemId: itemId,
      itemPriceId: editingPrice.id,
      price: price,
      minQuantity: minQuantity,
      maxQuantity: maxQuantity,
      name: priceName,
      isActive: isActive,
    },
    onCompleted: data => {
      let updatedPrice = data.updateItemPrice.itemPrice
      console.log(data)
      let updatedPriceIndex = prices.findIndex(p => p.id === editingPrice.id)
      const newList = [
        ...prices.slice(0, updatedPriceIndex),
        updatedPrice,
        ...prices.slice(updatedPriceIndex + 1),
      ]
      setPrices(newList)
      handleCancelUpdate()
    },
  })

  const [deletePrice] = useMutation(DELETE_ITEM_PRICE_MUTATION, {
    variables: {
      itemPriceId: deletingPriceId,
    },
    onCompleted: data => {
      setPrices(newArray)
      setDeletingPrice(0)
      setDeletingPriceId(0)
      setConfirmDelete(false)
      console.log("price deleted")
    },
  })

  const handleSubmit = () => {
    editing ? updatePrice() : createPrice()
  }

  const handleDeletePrice = event => {
    const filteredArray = prices.filter(x => x.id !== deletingPrice.id)
    setNewArray(filteredArray)
    setDeletingPriceId(deletingPrice.id)
    console.log(deletingPrice)
  }

  useDidMountEffect(() => {
    deletingPriceId > 0 && deletePrice()
  }, [deletingPriceId])

  useEffect(() => {
    deletingPrice > 0 && setConfirmDelete(true)
  }, [deletingPrice])

  useEffect(() => {
    if (editingPrice !== 0) {
      setPrice(editingPrice.price)
      setMinQuantity(editingPrice.minQuantity)
      setMaxQuantity(editingPrice.maxQuantity)
      setPriceName(editingPrice.name)
      setIsActive(editingPrice.isActive)
      setEditing(true)
    }
  }, [editingPrice])

  useEffect(() => {
    console.log(item.itemPrice)
  }, [])

  const handleAddPrices = event => {
    setAddPrices(true)
  }

  const handleCloseAddPrices = event => {
    setAddPrices(false)
  }

  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  const handleCancelUpdate = event => {
    setEditing(false)
    setEditingPrice(0)
    setPrice("")
    setMinQuantity("")
    setMaxQuantity("")
  }
  const handleCheckActive = event => {
    setIsActive(event.target.checked)
  }
  return (
    <>
      <Card
        className={clsx(classes.root, classes.roundedCard, className)}
        {...rest}
      >
        <CardHeader
          title="Prices"
          subheader={item.pricingModel && item.pricingModel.name}
        />
        <Divider />
        <CardContent>
          {addPrices ? (
            <Box mb={3}>
              <form
                onSubmit={event => {
                  event.preventDefault()
                  handleSubmit(event)
                }}
                className={clsx(classes.root, className)}
                {...rest}
              >
                {pricingModelId == 1 && (
                  <>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label={"Minimum (low) Quantity"}
                      margin="dense"
                      size="small"
                      name="minQuantity"
                      // onBlur={handleBlur}
                      onChange={event => setMinQuantity(event.target.value)}
                      type="text"
                      value={minQuantity}
                      variant="outlined"
                    />

                    <TextField
                      fullWidth
                      label="Maximum (high) Quantity"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="dense"
                      size="small"
                      name="maxQuantity"
                      // onBlur={handleBlur}
                      onChange={event => setMaxQuantity(event.target.value)}
                      type="text"
                      value={maxQuantity}
                      variant="outlined"
                    />
                  </>
                )}
                {pricingModelId == 3 && (
                  <>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Price Name"
                      margin="dense"
                      size="small"
                      name="priceName"
                      // onBlur={handleBlur}
                      onChange={event => setPriceName(event.target.value)}
                      type="text"
                      value={priceName}
                      variant="outlined"
                    />
                  </>
                )}
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Price"
                  margin="dense"
                  size="small"
                  name="price"
                  // onBlur={handleBlur}
                  onChange={event => setPrice(event.target.value)}
                  type="text"
                  value={price}
                  variant="outlined"
                />
                <Box alignItems="center" display="flex" mt={2} ml={-1}>
                  <Checkbox
                    checked={isActive}
                    // name="supplier"
                    onChange={handleCheckActive}
                  />

                  <Typography variant="body2" color="textSecondary">
                    This price is active.
                  </Typography>
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {editing ? "Update Price" : "Enter Price"}
                </Button>
                {editing && (
                  <Box mt={1}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={handleCancelUpdate}
                    >
                      Cancel Update
                    </Button>
                  </Box>
                )}
              </form>
            </Box>
          ) : (
            ""
          )}
          <>
            {prices ? (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Active</TableCell>
                      <TableCell>
                        {pricingModelId == 1
                          ? "Qty"
                          : pricingModelId == 3
                          ? "Name"
                          : ""}
                      </TableCell>
                      <TableCell align="right">Price Ea</TableCell>
                      {addPrices ? (
                        <TableCell align="right">edit/del</TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {prices.map(price => (
                      <>
                        <TableRow key={price.id}>
                          <TableCell>
                            {price.isActive ? (
                              <CheckCircleIcon
                                fontSize="small"
                                color="primary"
                              />
                            ) : undefined}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {pricingModelId == 1
                              ? `${price.minQuantity}-${price.maxQuantity}`
                              : pricingModelId == 3
                              ? `${price.name}`
                              : undefined}
                          </TableCell>
                          <TableCell align="right">
                            ${financial(price.price)}
                          </TableCell>
                          {addPrices ? (
                            <TableCell align="right">
                              <IconButton
                                color="primary"
                                aria-label="edit price"
                                component="span"
                                onClick={() => setEditingPrice(price)}
                              >
                                <EditIcon />
                              </IconButton>
                              <>
                                {deletingPrice.id === price.id ? (
                                  <IconButton
                                    size="small"
                                    className={classes.delete}
                                    aria-label="delete price"
                                    component="span"
                                    onClick={handleDeletePrice}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    aria-label="delete price"
                                    component="span"
                                    onClick={() => setDeletingPrice(price)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                )}
                              </>
                            </TableCell>
                          ) : (
                            ""
                          )}
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box>There is no pricing data for this item.</Box>
            )}
          </>
        </CardContent>

        <CardActions>
          {addPrices ? (
            <Button
              fullWidth
              onClick={handleCloseAddPrices}
              size="small"
              variant="outlined"
              color="primary"
            >
              Close Edit Mode
            </Button>
          ) : (
            <Button
              onClick={handleAddPrices}
              fullWidth
              size="small"
              variant="outlined"
              color="primary"
            >
              Add or Edit Prices
            </Button>
          )}
        </CardActions>
      </Card>
    </>
  )
}
const CREATE_PRICE_MUTATION = gql`
  mutation (
    $itemId: Int
    $price: Money!
    $minQuantity: Int
    $maxQuantity: Int
    $name: String
    $isActive: Boolean
  ) {
    createItemPrice(
      itemId: $itemId
      price: $price
      minQuantity: $minQuantity
      maxQuantity: $maxQuantity
      name: $name
      isActive: $isActive
    ) {
      itemPrice {
        item {
          id
          name
        }
        id
        minQuantity
        maxQuantity
        price
        name
        isActive
      }
    }
  }
`
const UPDATE_ITEM_PRICE_MUTATION = gql`
  mutation (
    $itemPriceId: Int!
    $itemId: Int
    $price: Money!
    $minQuantity: Int
    $maxQuantity: Int
    $name: String
    $isActive: Boolean
  ) {
    updateItemPrice(
      itemPriceId: $itemPriceId
      itemId: $itemId
      price: $price
      minQuantity: $minQuantity
      maxQuantity: $maxQuantity
      name: $name
      isActive: $isActive
    ) {
      itemPrice {
        id
        minQuantity
        maxQuantity
        price
        name
        isActive
      }
    }
  }
`

const DELETE_ITEM_PRICE_MUTATION = gql`
  mutation ($itemPriceId: Int!) {
    deleteItemPrice(itemPriceId: $itemPriceId) {
      itemPriceId
    }
  }
`
export default ItemPricing
