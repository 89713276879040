import React, { useState, useContext } from "react"
import clsx from "clsx"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core"
import { Link } from "gatsby"

import SortContext from "../../../../context/SortContext"
import { useLocation } from "@reach/router"

const useStyles = makeStyles(() => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
}))

const CartItems = ({ cart, className, ...rest }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(SortContext)
  const [count, setCount] = useState(0)
  const page = state.page
  const pageSize = state.pageSize
  const location = useLocation()
  const userPermission = location.pathname.split("/")[1]
  const items = JSON.parse(cart.items)
  const cartItems = JSON.parse(items)

  var obj1 = items && items.replace(/'/g, "") //Replace single quotes with double quotes
  console.log(typeof obj1) // string
  const handlePageChange = (event, newPage) => {
    let x = 1
    const pagiNewPage = newPage
    dispatch({ type: "SET_PAGE", payload: pagiNewPage })
  }
  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  function calcTotal(a, b) {
    return (a * b).toFixed(2)
  }
  // if (loading) return <Loading />
  // if (error) return <Error />
  // if (data == undefined) return ""
  // if (data == null) {
  //   return ""
  // }
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title="Cart items" />
        <Divider />

        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Item #</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Ea</TableCell>

                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {cartItems &&
                  cartItems.map(item => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          component={Link}
                          to={`/${userPermission}/items/${item.id}`}
                          state={{ id: `${item.id}` }}
                        >
                          {item.id}
                        </Button>
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>${financial(item.price)}</TableCell>
                      <TableCell align="right">
                        ${calcTotal(item.quantity, item.price)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        {count > 5 && (
          <TablePagination
            component="div"
            count={count}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25]}
          />
        )}
      </Card>
    </>
  )
}

export default CartItems
