import React, { useState, useEffect } from "react"
import ItemCreateFormStepOne from "./ItemCreateForms/ItemCreateFormStepOne"
import PrivateItemCreateForm from "./ItemCreateForms/PrivateItemCreateForm"
import PreregItemCreateForm from "./ItemCreateForms/PreregItemCreateForm"
import InquiryItemConversion from "./ItemCreateForms/InquiryItemConversion"
import ItemSpecs from "./ItemCreateForms/ItemSpecs"
import ItemImage from "./ItemCreateForms/ItemImage"
import ItemPricing from "./ItemCreateForms/ItemPricing"
import NextStep from "./ItemCreateForms/NextStep"
import { Box, Container, makeStyles } from "@material-ui/core"
import PerfectScrollbar from "react-perfect-scrollbar"
import Page from "../../../Global/Page"
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,

    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
  },
  steps: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
}))
const ItemCreateForm = ({}) => {
  const classes = useStyles()
  const [item, setItem] = useState({})
  const [activeStep, setActiveStep] = useState(0)
  const [person, setPerson] = useState(null)

  const getStepper = activeStep => {
    switch (activeStep) {
      case 0:
        return (
          <ItemCreateFormStepOne
            setActiveStep={setActiveStep}
            item={item}
            setItem={setItem}
          />
        )
      case 1:
        return (
          <PrivateItemCreateForm
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            item={item}
            person={person}
            setPerson={setPerson}
          />
        )
      case 2:
        return (
          <PreregItemCreateForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            item={item}
            person={person}
            setPerson={setPerson}
          />
        )
      case 3:
        return (
          <ItemSpecs
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            item={item}
          />
        )
      case 4:
        return (
          <ItemImage
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            item={item}
          />
        )
      case 5:
        return (
          <ItemPricing
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            item={item}
          />
        )

      case 6:
        return <NextStep activeStep={activeStep} item={item} />

      default:
        return "unknown step"
    }
  }

  return (
    <Page className={classes.root} title="Cart List">
      <PerfectScrollbar>
        {/* <Container maxWidth={false}> */}
        <Box className={classes.steps}>{getStepper(activeStep)}</Box>
        {/* </Container> */}
      </PerfectScrollbar>
    </Page>
  )
}

export default ItemCreateForm
