import React, { useState } from "react"
import UploadSampleProof from "./UploadSampleProof"
import ImageCropper from "./ImageCropper"

const NewSampleProof = ({ item, samplesProofs, setSamplesProofs }) => {
  const [imageFile, setImageFile] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [fileField, setFileField] = useState("")
  const [isVertical, setIsVertical] = useState(false)
  return (
    <>
      {imageFile ? (
        <UploadSampleProof
          item={item}
          imageFile={imageFile}
          setImageFile={setImageFile}
          isVertical={isVertical}
          setIsVertical={setIsVertical}
          fileField={fileField}
          setFileField={setFileField}
          samplesProofs={samplesProofs}
          setSamplesProofs={setSamplesProofs}
        />
      ) : (
        <ImageCropper
          setFileField={setFileField}
          setImageFile={setImageFile}
          isVertical={isVertical}
          setIsVertical={setIsVertical}
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
        />
      )}
    </>
  )
}

export default NewSampleProof
