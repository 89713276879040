import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Button,
  Drawer,
  Grid,
  Hidden,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  Check as CheckIcon,
  X as XIcon,
  Trash as TrashIcon,
} from "react-feather"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}))

function BulkOperations({
  className,
  onDelete,
  // onMarkSpam,
  onMarkUnspam,
  open,
  selected,
  setSelectedCarts,
  ...rest
}) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(null)

  useEffect(() => {
    selected.length > 0 && handleUpdateCartStatus()
  }, [isOpen])

  const [handleUpdateCartStatus, { data, loading, error }] = useMutation(
    UPDATE_CART_STATUS_MUTATION,
    {
      variables: {
        cartId: selected,
        cartOpen: isOpen,
      },
      onCompleted: data => {
        console.log(data)
        setSelectedCarts([])
        setIsOpen(null)
      },
    }
  )

  return (
    <Drawer
      anchor="bottom"
      open={open}
      PaperProps={{ elevation: 1 }}
      variant="persistent"
    >
      <div className={clsx(classes.root, className)} {...rest}>
        <Grid alignItems="center" container spacing={2}>
          <Hidden smDown>
            <Grid item md={3}>
              <Typography color="textSecondary" variant="subtitle1">
                {selected.length} selected
              </Typography>
            </Grid>
          </Hidden>
          <Grid item md={6} xs={12}>
            <div className={classes.actions}>
              <Button onClick={() => setIsOpen(true)}>
                <SvgIcon fontSize="small" className={classes.actionIcon}>
                  <CheckIcon />
                </SvgIcon>
                Cart is Open
              </Button>
              <Button onClick={() => setIsOpen(false)}>
                <SvgIcon fontSize="small" className={classes.actionIcon}>
                  <XIcon />
                </SvgIcon>
                Cart is Closed
              </Button>
              {/* <Button onClick={onDelete}>
                <SvgIcon
                  fontSize="small"
                  className={classes.actionIcon}
                >
                  <TrashIcon />
                </SvgIcon>
                Delete
              </Button> */}
            </div>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  )
}

BulkOperations.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func,
  //   onMarkSpam: PropTypes.func,
  //   onMarkUnspam: PropTypes.func,
  open: PropTypes.bool,
  isOpen: PropTypes.bool,
  selected: PropTypes.array.isRequired,
}

BulkOperations.defaultProps = {
  className: "",
  //   onDelete: () => {},
  //   onMarkSpam: () => {},
  //   onMarkUnspam: () => {},
  open: false,
}
const UPDATE_CART_STATUS_MUTATION = gql`
  mutation ($cartId: [String], $cartOpen: Boolean!) {
    updateCartStatus(cartId: $cartId, cartOpen: $cartOpen) {
      carts {
        id
        cartId
        latestChange
        cartOpen
      }
    }
  }
`
export default BulkOperations
