import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core"

import Loading from "../Utils/Loading"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { navigate } from "gatsby"
import DeleteImage from "./DeleteImage"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}))

function generate(element) {
  return [0, 1, 2].map(value =>
    React.cloneElement(element, {
      key: value,
    })
  )
}

const UploadList = () => {
  const classes = useStyles()
  const [dense, setDense] = useState(false)
  const [secondary, setSecondary] = useState(false)

  const { loading, error, data } = useQuery(MY_UPLOADED_IMAGES_QUERY)
  if (loading) return <Loading />
  if (error) return navigate("/enter/")
  if (data === undefined) return navigate("/enter/")

  const imageList = data.me.image
  const me = data.me
  return (
    <>
      <h1>Image List</h1>
      {/* {JSON.stringify(imageList)} */}
      <Grid item xs={12} md={6}>
        <Typography variant="h6" className={classes.title}>
          My Uploads
        </Typography>
        <div className={classes.demo}>
          <List dense={dense}>
            {imageList.map(image => (
              <ListItem key={image.id}>
                <ListItemAvatar>
                  <Avatar
                    alt="{i.imageUrl}"
                    src={image.imageUrl}
                    className={classes.large}
                  />
                </ListItemAvatar>
                <a href={image.imageUrl}>
                  <ListItemText
                    primary={image.title ? image.title : image.image}
                    secondary={image.note ? image.note : null}
                  />
                </a>
                <ListItemSecondaryAction>
                  <DeleteImage image={image} edge="end" />

                  {/* <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton> */}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
    </>
  )
}
export default UploadList

export const MY_UPLOADED_IMAGES_QUERY = gql`
  query {
    me {
      id
      image {
        id
        image
        imageUrl
        title
        note
        user {
          id
        }
      }
    }
  }
`
