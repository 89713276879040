import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core"
import blue from "@material-ui/core/colors/blue"
import grey from "@material-ui/core/colors/grey"
import StarsIcon from "@material-ui/icons/Stars"
import ImageIcon from "@material-ui/icons/Image"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import Alert from "@material-ui/lab/Alert"
import withStyles from "@material-ui/core/styles/withStyles"
import Loading from "../../../../../../Utils/Loading"

const menuOptions = [
  "View Full Size",
  "Delete",
  // "Feature Image",
  // "Unfeature Image",
  "Update Info",
]

const ImageFileCard = ({
  classes,
  currentImage,
  files,
  setFiles,
  item,
  // itemImageFiles,
  // setItemImageFiles,
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [alert, setAlert] = useState(false)
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false)
  const [title, setTitle] = useState(currentImage.title)
  const [note, setNote] = useState(currentImage.note)
  const [isFeatured, setIsFeatured] = useState(currentImage.featured)
  const [isUpdated, setIsUpdated] = useState(false)
  const [updateAlert, setUpdateAlert] = useState(false)
  // const [openMenu, setOpenMenu] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const ITEM_HEIGHT = 48

  const openMenu = Boolean(anchorEl)

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleCloseUpdate = () => {
    setNote(currentImage.note)
    setIsFeatured(currentImage.featured)
    setTitle(currentImage.title)
    setOpenUpdateDialog(false)
  }
  const handleOpenUpdateDialog = () => {
    setOpenUpdateDialog(true)
  }
  const handleCheckFeatured = event => {
    setIsFeatured(event.target.checked)
  }

  const [handleDeleteImage, { data, loading, error }] = useMutation(
    DELETE_IMAGE_MUTATION,
    {
      variables: {
        imageId: currentImage.id,
      },
      onCompleted: data => {
        setIsSubmitting(false)
        setAlert(true)
      },
    }
  )

  const [handleMakeFeatured] = useMutation(MAKE_FEATURED_IMAGE_MUTATION, {
    variables: {
      imageId: currentImage.id,
      featured: true,
    },
    onCompleted: data => {
      const filteredImages = files.filter(
        imageFile => imageFile.id !== currentImage.id
      )
      const updatedImage = data.featureItemImage
      setFiles([...filteredImages, updatedImage])
      setIsSubmitting(false)
    },
  })

  const handleSelectOption = option => {
    if (option === "Delete") {
      setFiles(files.filter(imageFile => imageFile.id !== currentImage.id))
      handleDeleteImage()
    }
    if (option === "Update Info") {
      // handleUpdateImage()
      handleOpenUpdateDialog()
    }
    if (option === "View Full Size") {
      setOpenDialog(true)
    }
  }
  const [updateItemImage] = useMutation(UPDATE_ITEM_IMAGE_DETAILS_MUTATION, {
    variables: {
      itemId: item.id,
      imageId: currentImage.id,
      featured: isFeatured,
      note: note,
      title: title,
    },
    onCompleted: data => {
      const updatedImage = data.updateItemImage.itemImage
      const updatedFiles = files.filter(file => file.id !== currentImage.id)
      setFiles([...updatedFiles, updatedImage])
      setFiles([])
      setIsUpdated(true)
      setIsSubmitting(false)
      setUpdateAlert(true)
    },
  })
  const handleCompleteUpdate = () => {
    setIsUpdated(false)
    setUpdateAlert(false)
    setOpenUpdateDialog(false)
  }
  const handleUpdateItemImage = () => {
    setIsSubmitting(true)
    updateItemImage()
  }

  return (
    <>
      {alert && (
        <Alert onClose={() => setAlert(false)} severity="success">
          File Deleted
        </Alert>
      )}
      <Dialog
        open={openDialog}
        onClose={isUpdated ? handleCompleteUpdate : handleCloseDialog}
      >
        <img
          className={classes.fullSize}
          src={currentImage.imageUrl}
          alt="full size image"
        />
      </Dialog>
      <Dialog open={openUpdateDialog} onClose={handleCloseUpdate}>
        <DialogTitle id="update-title">{"Update Item Image Info"}</DialogTitle>
        <DialogContent>
          <form
            onSubmit={event => {
              event.preventDefault()
              handleUpdateItemImage(event)
            }}
          >
            <TextField
              fullWidth
              label="Title"
              margin="dense"
              size="small"
              name="Title"
              onChange={event => setTitle(event.target.value)}
              type="text"
              value={title}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Note"
              margin="dense"
              size="small"
              name="Note"
              onChange={event => setNote(event.target.value)}
              type="text"
              value={note}
              variant="outlined"
            />
            <Box alignItems="center" display="flex" mt={2} ml={-1}>
              <Checkbox checked={isFeatured} onChange={handleCheckFeatured} />
              <Typography variant="body2" color="textSecondary">
                Featured Image
              </Typography>
            </Box>
            {updateAlert ? (
              <Alert
                onClose={() => setUpdateAlert(false)}
                severity="success"
                action={
                  <Button
                    onClick={handleCompleteUpdate}
                    color="inherit"
                    size="small"
                  >
                    Close
                  </Button>
                }
              >
                Information is updated
              </Alert>
            ) : (
              <>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Update Item Image
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={handleCloseUpdate}
                  variant="outlined"
                  color="secondary"
                >
                  Cancel
                </Button>
              </>
            )}
          </form>
        </DialogContent>
      </Dialog>
      <Card className={classes.roundedCard}>
        <CardActionArea>
          <CardMedia
            component="img"
            alt={currentImage.image}
            height="275px"
            image={currentImage.carouselImageUrl}
            title={currentImage.title ? currentImage.title : ""}
          />
        </CardActionArea>

        {isSubmitting ? (
          <Loading />
        ) : (
          <CardHeader
            avatar={
              <Avatar
                aria-label="featured"
                style={
                  currentImage.featured
                    ? { color: "white", backgroundColor: blue[500] }
                    : {
                        color: "white",
                        backgroundColor: grey[800],
                      }
                }
                className={classes.avatar}
              >
                {currentImage.featured ? <StarsIcon /> : <ImageIcon />}
              </Avatar>
            }
            action={
              <IconButton onClick={handleOpenMenu} aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title={currentImage.title ? currentImage.title : ""}
            subheader={currentImage.note ? currentImage.note : ""}
          />
        )}
        <Menu
          id="image-options"
          anchorEl={anchorEl}
          keepMounted
          open={openMenu}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {menuOptions.map(option => (
            <MenuItem
              key={option}
              selected={option === "View Full Size"}
              onClick={() => handleSelectOption(option)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Card>
    </>
  )
}

const styles = theme => ({
  root: {},
  roundedCard: {
    borderRadius: "15px",
  },
  fullSize: {
    width: "100%",
    height: "100%",
  },
})

const DELETE_IMAGE_MUTATION = gql`
  mutation ($imageId: Int!) {
    deleteImageFile(imageId: $imageId) {
      imageId
    }
  }
`
const MAKE_FEATURED_IMAGE_MUTATION = gql`
  mutation ($imageId: Int!) {
    featureItemImage(imageId: $imageId) {
      itemImage {
        id
        image
        imageUrl
        item {
          id
          name
        }
        title
        note
        featured
        proof
        uploadedAt
        createdBy {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        carouselImageUrl
        imageUrl
        thumbnailUrl
      }
    }
  }
`

const UPDATE_ITEM_IMAGE_DETAILS_MUTATION = gql`
  mutation (
    $itemId: Int!
    $imageId: Int!
    $featured: Boolean
    $note: String
    $title: String
  ) {
    updateItemImage(
      itemId: $itemId
      imageId: $imageId
      featured: $featured
      note: $note
      title: $title
    ) {
      itemImage {
        id
        image
        item {
          id
          name
        }
        createdBy {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        title
        note
        featured
        uploadedAt
        carouselImageUrl
        imageUrl
        thumbnailUrl
      }
    }
  }
`
export default withStyles(styles)(ImageFileCard)
