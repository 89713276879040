import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  Edit as EditIcon,
  XCircle as DeleteIcon,
  XSquare as CancelIcon,
  Search as SearchIcon,
  PlusCircle as AddIcon,
} from "react-feather"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 2,
    marginBotton: 5,
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

function InquiryNotes({ inquiry, className, ...rest }) {
  const classes = useStyles()
  const inquiryNotes = inquiry.notes
  const [message, setMessage] = useState(inquiryNotes)
  const [editNote, setEditNote] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [updateMessage, { data, loading, error }] = useMutation(
    UPDATE_NOTES_MUTATION,
    {
      variables: {
        inquiryId: inquiry.id,
        inquiryUuid: inquiry.inquiryUuid,
        notes: message,
      },
      onCompleted: data => {
        setMessage(data.updateInquiryNotes.inquiry.notes)
        setEditNote(false)
        setIsSubmitting(false)
      },
    }
  )

  const handleSubmit = (event, updateMessage) => {
    event.preventDefault()
    setIsSubmitting(true)
    updateMessage()
  }

  const handleCancel = event => {
    setEditNote(false)
    setMessage(inquiryNotes)
  }

  return (
    <Card className={classes.roundedCard}>
      <CardHeader
        title="Inquiry Notes (Internal)"
        action={
          editNote ? (
            <>
              <IconButton
                aria-label="cancel"
                color="primary"
                onClick={handleCancel}
              >
                <CancelIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => setEditNote(true)}
              >
                <EditIcon />
              </IconButton>
            </>
          )
        }
      />
      <Divider />
      <CardContent>
        <form
          onSubmit={event => handleSubmit(event, updateMessage)}
          //   className={clsx(classes.root, className)}
          //   {...rest}
        >
          {editNote ? (
            <TextField
              fullWidth
              multiline
              disabled={isSubmitting}
              rows={4}
              //   variant="outlined"
              //   label="Message to Customer"
              value={message}
              onChange={event => setMessage(event.target.value)}
              id="inquiry-notes"
              className={classes.margin}
            />
          ) : (
            <CardContent>
              <Typography variant="body1">
                {message ? message : "Add a message"}
              </Typography>
            </CardContent>
          )}
          {editNote && (
            <CardActions>
              <Button
                type="submit"
                size="small"
                color="primary"
                variant="outlined"
                disabled={isSubmitting}
              >
                Save
              </Button>
              <Button
                size="small"
                color="secondary"
                onClick={handleCancel}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </CardActions>
          )}
        </form>
      </CardContent>
    </Card>
  )
}

InquiryNotes.propTypes = {
  className: PropTypes.string,
  inquiry: PropTypes.object.isRequired,
}

const UPDATE_NOTES_MUTATION = gql`
  mutation ($inquiryId: Int!, $inquiryUuid: String, $notes: String) {
    updateInquiryNotes(
      inquiryId: $inquiryId
      inquiryUuid: $inquiryUuid
      notes: $notes
    ) {
      inquiry {
        inquiryUuid
        id
        notes
      }
    }
  }
`
export default InquiryNotes
