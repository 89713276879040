import React, { useState, useContext, useEffect } from "react"
import { Grid, Typography } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import gql from "graphql-tag"
import CartList from "./CartList"
import SortContext from "../../../../../../context/SortContext"
const noDataResponse = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <Typography variant="h3">
            This person has no carts on record.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
const Carts = ({ person }) => {
  const uid = person.uid
  const { state, dispatch } = useContext(SortContext)
  const search = ""
  const { loading, error, data } = useQuery(PERSON_CARTS_QUERY, {
    variables: {
      uid: uid,
      page: state.page,
      pageSize: state.pageSize,
      search: state.search,
    },
    onCompleted: data => {
      console.log(data)
    },
  })
  useEffect(() => {
    dispatch({ type: "SET_PAGE_SIZE", payload: 15 })
    dispatch({ type: "SET_PAGE", payload: 0 })
    dispatch({ type: "SET_SEARCH", payload: "" })
  }, [])
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) {
    return { noDataResponse }
  }
  if (data == null) {
    return { noDataResponse }
  }
  const personCarts = data.personCarts

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <CartList personCarts={personCarts} />
        </Grid>
      </Grid>
    </>
  )
}

const PERSON_CARTS_QUERY = gql`
  query ($uid: String, $page: Int, $pageSize: Int) {
    personCarts(uid: $uid, page: $page, pageSize: $pageSize) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        cartId
        totalItems
        totalUniqueItems
        started
        latestChange
        businessUnit {
          id
          name
        }
        cartOpen
        expirationDate
        cartExpired
      }
    }
  }
`
export default Carts
