import React, { useState, useEffect, useContext, Fragment } from "react"
import clsx from "clsx"
import { Link } from "gatsby"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import { withStyles } from "@material-ui/styles"
import OrderContext from "./context/OrderContext"
import { useLocation } from "@reach/router"
import {
  Box,
  Button,
  CardMedia,
  ClickAwayListener,
  Divider,
  FormControl,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core"

import { XSquare as RemoveSquareIcon } from "react-feather"

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const OrderItemCard = ({ oi, classes }) => {
  const { state, dispatch } = useContext(OrderContext)
  const { orderItems, oiQty, oiPriceEach } = state
  const { id, completed, isSearching } = state.orderDetails

  const [quantity, setQuantity] = useState(oi.qty)
  const [priceEach, setPriceEach] = useState(oi.priceEach)
  const [itemTotal, setItemTotal] = useState(null)
  const [discountAmt, setDiscountAmt] = useState(oi.discountAmt)
  const [taxAmt, setTaxAmt] = useState(oi.taxAmt)

  const [newArray, setNewArray] = useState([])
  const [pricingModelId, setPricingModelId] = useState(1)
  const [removing, setRemoving] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  useEffect(() => {
    if (itemTotal === null) {
      setItemTotal(oi.itemTotal)
    }
  }, [])
  const item = oi.item
  const itemId = oi.item.id
  const orderItemId = oi.id
  const taxId = oi.tax.id
  const discountId = oi.discount.id
  const thumbnail = oi.item.featuredImageThumbnailUrl
  const categoryName = oi.item.category.name
  const pricing = oi.item.itemPrice
  const customFields = oi.customFields ? JSON.parse(oi.customFields) : ""
  // const customFields = oi.customFields
  const sortedCustomFields = customFields
    ? Object.entries(customFields).sort((a, b) => b[0].localeCompare(a[0]))
    : ""
  function capitalizeFLetter() {
    var input = document.getElementById("input")
    var x = document.getElementById("div")
    var string = input.value
    x.innerHTML = string.charAt(0).toUpperCase() + string.slice(1)
  }
  // const customFieldDetails = []
  // for (const [key, value] of Object.entries(customFields)) {
  //   customFieldDetails.push(
  //     <>
  //       {key}: {value}
  //       <br />
  //     </>
  //   )
  // }
  // useEffect(() => {
  //   if (oi.priceEach !== oi.priceEach) {
  //     updateOrderItem()
  //   }
  // }, [priceEach])

  const handleUpdateOrderItem = () => {
    setIsUpdating(true)
    updateOrderItem()
  }
  const [handleRemove, { loading, error }] = useMutation(
    REMOVE_ORDER_ITEM_MUTATION,
    {
      variables: {
        orderItemId: orderItemId,
      },
      onCompleted: data => {
        dispatch({ type: "SET_ORDER_ITEMS", payload: newArray })
        setRemoving(false)
        setNewArray([])
        // setRefresh(!refresh)
      },
    }
  )
  const [updateOrderItem] = useMutation(UPDATE_ORDER_ITEM_MUTATION, {
    variables: {
      orderItemId: orderItemId,
      orderId: id,
      itemId: itemId,
      taxId: taxId,
      discountId: discountId,
      qty: quantity,
      priceEach: priceEach,
    },

    onCompleted: (data, error) => {
      const updatedItem = data.updateOrderItem.orderItem
      console.log("updated item")
      console.log(updatedItem)
      const updateIndex = orderItems.findIndex(x => x.id === orderItemId)
      const newList = [...orderItems]
      // const newList = new Array(orderItems)
      newList[updateIndex] = {
        ...newList[updateIndex],
        qty: updatedItem.qty,
        priceEach: updatedItem.priceEach,
      }
      console.log("new list")
      console.log(newList)
      dispatch({ type: "SET_ORDER_ITEMS", payload: newList })
      // dispatch({ type: "SET_ORDER_TOTAL", payload: updatedItem.order.total })
      setPriceEach(updatedItem.priceEach)
      setQuantity(updatedItem.qty)
      let newTotal = parseFloat(updatedItem.itemTotal)
      setItemTotal(newTotal)

      console.log("new total")
      console.log(newTotal)
      console.log("updated item")
      console.log(updatedItem)
      // setRefresh(!refresh)
      setIsUpdating(false)
    },
  })

  const handleRemoveItem = () => {
    if (removing) {
      handleRemove()
    } else {
      const filteredArray = orderItems.filter(x => x.id !== orderItemId)
      setNewArray(filteredArray)
      setRemoving(true)
    }
  }

  // Todo: Pricing Models
  //   const priceModelSwitch = priceModel => {
  //     switch (priceModel) {
  //       case "quantity":
  //         return <QuantityPricing />
  //       case "standard":
  //         return <StandardPricing />

  //       default:
  //         return "standard"
  //     }
  //   }

  const handleUpdate = event => {
    let pricingId = event.target.value
    var set = pricing.find(ps => {
      return ps.id === pricingId
    })
    setQuantity(set.minQuantity)
    setPriceEach(set.price)
    let total = parseFloat(set.minQuantity) * parseFloat(set.price)
    setItemTotal(total)
  }
  function splitCamelCaseToString(s) {
    return s.split(/(?=[A-Z])/).join(" ")
  }
  const customFieldSet = []
  if (sortedCustomFields) {
    for (const [key, value] of sortedCustomFields) {
      let format = (
        <Fragment key={key}>
          {/* {(key.charAt(0).toUpperCase() + key.slice(1)).replace(/_/g, " ")}:{" "} */}
          {isNaN(key)
            ? key.charAt(0).toUpperCase() +
              splitCamelCaseToString(key.slice(1)) +
              ": "
            : key}
          {isNaN(value)
            ? value.charAt(0).toUpperCase() + value.slice(1)
            : value}
          {/* {value.charAt(0).toUpperCase() + value.slice(1)} */}
          {/* {value.charAt(0) + value.slice(1)} */}
          {/* {value} */}
          <br />
        </Fragment>
      )
      customFieldSet.push(format)
    }
  }
  if (loading) return "loading..."
  if (error) return "error"
  return (
    <>
      <Box pb={1}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={4} sm={2}>
            <CardMedia
              className={classes.media}
              image={thumbnail ? thumbnail : undefined}
              title={item.name}
            />
          </Grid>
          <Grid item xs={8} sm={4}>
            <Box mb={2}>
              <Link
                className={classes.link}
                to={`/manager/items/${itemId}`}
                state={{ id: { itemId } }}
              >
                <Typography variant="body1">{item.name}</Typography>
              </Link>
              <Typography variant="body2">{categoryName}</Typography>
              <Typography variant="caption">Item # {item.id}</Typography>

              {/* <Typography variant="caption">{customFields}</Typography> */}
              {/* {customFields &&
                customFields.map((cf, index) => (
                  <Typography key={index} variant="caption">
                    {cf}
                  </Typography>
                ))} */}
              <Typography>{customFieldSet}</Typography>

              {/* {sortedCustomFields && sortedCustomFields.map()} */}
              {item.isPrivate && (
                <Typography variant="caption" display="block">
                  Custom Private Item
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item container xs={10} sm={5}>
            <Grid item xs={4}>
              {completed ? (
                <>
                  <Typography variant="body1">{quantity}</Typography>
                </>
              ) : (
                <Box ml={2}>
                  <FormControl>
                    <TextField
                      id="qty"
                      fullWidth
                      select
                      size="small"
                      margin="dense"
                      label="Qty"
                      value={quantity}
                      onChange={handleUpdate}
                      variant="outlined"
                      disabled={completed}
                      SelectProps={{ native: true, padding: 1 }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option className={classes.selected} selected>
                        {quantity}
                      </option>
                      {pricing.map(price => (
                        <option key={price.id} value={price.id}>
                          {price.minQuantity}
                        </option>
                      ))}
                    </TextField>
                  </FormControl>
                </Box>
              )}
            </Grid>

            <Grid item xs={1} sm={false}></Grid>
            <Grid item xs={6} sm={5}>
              <Typography variant="body2">
                ${financial(priceEach)} ea
              </Typography>
              {item.discountAmt > 0 && (
                <Typography variant="body2">
                  ${financial(discountAmt)} discount
                </Typography>
              )}
              {item.taxAmt > 0 && (
                <Typography variant="body2">
                  ${financial(taxAmt)} tax
                </Typography>
              )}
              <Typography variant="subtitle2">
                ${financial(itemTotal)} total
              </Typography>
              {quantity !== oi.qty &&
                (isUpdating ? (
                  <Typography variant="subtitle2">Updating...</Typography>
                ) : (
                  <Button
                    size="small"
                    className={classes.updateButton}
                    onClick={handleUpdateOrderItem}
                  >
                    Update Order
                  </Button>
                ))}
            </Grid>
          </Grid>

          <Grid item container justify="space-between" xs={2} sm={1}>
            <Grid item></Grid>
            <Grid item>
              <ClickAwayListener onClickAway={event => setRemoving(false)}>
                <IconButton
                  aria-label="remove"
                  size="small"
                  className={
                    removing
                      ? clsx(classes.redDelete, classes.margin)
                      : classes.margin
                  }
                  onClick={handleRemoveItem}
                  disabled={completed}
                >
                  <SvgIcon fontSize="small">
                    <RemoveSquareIcon />
                  </SvgIcon>
                </IconButton>
              </ClickAwayListener>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Divider />
    </>
  )
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    boxShadow: "none",
  },
  selected: {
    display: "none",
  },
  update: {
    color: "#FF0000",
  },
  redDelete: {
    color: "#FF0000",
  },
  link: {
    textDecoration: "none",
    color: "#000000",
  },
  media: {
    height: "80px",
    width: "80px",
    margin: theme.spacing(0, 2),
  },
  updateButton: {
    color: "#FF0000",
  },
})

const REMOVE_ORDER_ITEM_MUTATION = gql`
  mutation ($orderItemId: Int!) {
    deleteOrderItem(orderItemId: $orderItemId) {
      orderItemId
    }
  }
`
const UPDATE_ORDER_ITEM_MUTATION = gql`
  mutation (
    $orderItemId: Int!
    $orderId: Int!
    $itemId: Int!
    $taxId: Int
    $discountId: Int
    $qty: Int
    $priceEach: Money
  ) {
    updateOrderItem(
      orderItemId: $orderItemId
      orderId: $orderId
      itemId: $itemId
      taxId: $taxId
      discountId: $discountId
      qty: $qty
      priceEach: $priceEach
    ) {
      orderItem {
        id
        customFields
        itemSubtotal
        itemTotal
        item {
          id
          name
          featuredImageThumbnailUrl
          category {
            id
            name
          }
          businessUnit {
            id
            name
          }
          isActive
          isPrivate
          pricingModel {
            id
            name
          }
          itemPrice {
            id
            minQuantity
            maxQuantity
            price
          }
        }
        order {
          id
          orderTotal
          orderPaymentTotal
          orderBalanceDue
          orderSubtotal
          taxAmt
          discountAmt
          postageAmt
          balanceDue
        }
        qty
        priceEach
        tax {
          id
          code
          taxRate
        }
        taxAmt
        discount {
          id
          code
          name
          discountRate
        }
        discountAmt
      }
    }
  }
`
export default withStyles(styles)(OrderItemCard)
