import React, { useState, useContext } from "react"
import clsx from "clsx"
import PerfectScrollbar from "react-perfect-scrollbar"
import useDidMountEffect from "../../../Global/hooks/useDidMountEffect"
import OrderContext from "./context/OrderContext"
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import { PlusCircle as AddIcon } from "react-feather"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

import OrderItemSearch from "./OrderItemSearch"
import OrderItemCreate from "./OrderItemCreate"

const useStyles = makeStyles(() => ({
  root: {},
  roundedCard: {
    borderRadius: "15px",
  },
}))

const AddOrderItem = ({ className, ...rest }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(OrderContext)
  const orderId = state.orderDetails.id

  const {
    allItems,
    orderItems,
    createNewItem,
    isSearching,
    isAddingOrderItem,
  } = state
  const [item, setItem] = useState("")
  const [itemId, setItemId] = useState(null)
  const [taxId, setTaxId] = useState(1)
  const [discountId, setDiscountId] = useState(1)
  const [qty, setQty] = useState(0)
  const [priceEach, setPriceEach] = useState(0.0)
  const [priceObject, setPriceObject] = useState("")
  const [taxList, setTaxList] = useState("")
  const [selected, setSelected] = useState(false)
  const [itemPricing, setItemPricing] = useState([])
  const [discount, setDiscount] = useState(false)

  const { data, loading, error } = useQuery(SALES_TAXES_QUERY, {
    onCompleted: (data, response, errors) => {
      console.log("got tax info")
      setTaxList(data.taxes)
    },
    fetchPolicy: "cache-and-network",
  })

  const [addItem] = useMutation(ADD_ORDER_ITEM_MUTATION, {
    variables: {
      orderId: orderId,
      itemId: itemId,
      taxId: taxId,
      discountId: discountId,
      qty: qty,
      priceEach: priceEach,
    },

    onCompleted: (data, error) => {
      const addedItem = data.createOrderItem.orderItem

      const newList = orderItems
      newList.push(addedItem)
      console.log(newList)
      dispatch({ type: "SET_ORDER_ITEMS", payload: newList })
      setSelected(false)
      // setSearch(false)
      // setCreateNew(false)
      dispatch({ type: "TOGGLE_CREATE_NEW_ITEM" })
      dispatch({ type: "TOGGLE_IS_SEARCHING" })
      dispatch({ type: "TOGGLE_IS_ADDING_ORDER_ITEM" })
      dispatch({ type: "SET_SEARCH", payload: "" })

      dispatch({ type: "SET_PAGE", payload: 0 })
      // setAddOrderItem(false)
    },
  })

  useDidMountEffect(() => {
    setItemId(item.id)
    setItemPricing(item.itemPrice)
  }, [item])

  useDidMountEffect(() => {
    console.log(priceObject)
    setQty(priceObject.split(",")[0])
    setPriceEach(priceObject.split(",")[1])
  }, [priceObject])

  const handleCheckDiscount = event => {
    setDiscount(event.target.checked)
  }

  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  const handleTest = event => {
    event.preventDefault()
    console.log(orderId)
    console.log(itemId)
    console.log(taxId)
    console.log(discountId)
    console.log(qty)
    console.log(priceEach)
  }
  const handleAddItem = event => {
    event.preventDefault()
    addItem()
  }
  const handleCancel = event => {
    // setSearch(false)
    if (isSearching) dispatch({ type: "TOGGLE_IS_SEARCHING" })
    if (createNewItem) dispatch({ type: "TOGGLE_CREATE_NEW_ITEM" })
    if (isAddingOrderItem) dispatch({ type: "TOGGLE_IS_ADDING_ORDER_ITEM" })
    setSelected(false)
    // setAddOrderItem(false)
  }

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }

  return (
    <>
      <Card className={clsx(classes.root, classes.roundedCard)}>
        <CardHeader
          title="Add to the Order"
          action={
            <IconButton
              size="small"
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
            >
              <ClearIcon />
            </IconButton>
          }
        />

        <Divider />
        {createNewItem && <OrderItemCreate id={orderId} />}
        {isSearching && selected === false && (
          <OrderItemSearch
            id={orderId}
            setItem={setItem}
            setSelected={setSelected}
          />
        )}

        <Box p={2}>
          {isSearching && selected && (
            <>
              <Box mb={2}>
                <Typography variant="h4" color="textPrimary">
                  {item.name}
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {item.category.name}
                </Typography>
              </Box>
              <form
                // onSubmit={event => handleSubmit(event, addItem)}
                onSubmit={handleAddItem}
                className={clsx(classes.root, className)}
                {...rest}
              >
                <Grid container>
                  <Grid item>
                    <Box p={1}>
                      <TextField
                        label="Qty + Pricing"
                        select
                        id="outlined-margin-dense"
                        value={priceObject}
                        className={classes.textField}
                        helperText="Select a Quantity and Price"
                        margin="dense"
                        size="small"
                        variant="outlined"
                        SelectProps={{ native: true }}
                        onChange={event => setPriceObject(event.target.value)}
                      >
                        <option></option>
                        {itemPricing.map(price => (
                          <option
                            key={price.id}
                            value={[`${price.minQuantity}`, `${price.price}`]}
                          >
                            {price.minQuantity} for $
                            {financial(`${price.price}`)} ea
                          </option>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item>
                    {discount && (
                      <Box p={1}>
                        <TextField
                          label="Discount"
                          select
                          id="outlined-margin-dense"
                          value={discountId}
                          className={classes.textField}
                          helperText="Hook this up"
                          margin="dense"
                          variant="outlined"
                          size="small"
                          SelectProps={{ native: true }}
                          onChange={event => setDiscountId(event.target.value)}
                        >
                          <option></option>
                          <option id={1} value={1}>
                            No Discount
                          </option>
                        </TextField>
                      </Box>
                    )}
                  </Grid>
                  <Grid item>
                    <Box p={1}>
                      <TextField
                        label="Tax"
                        id="outlined-margin-dense"
                        className={classes.textField}
                        helperText="Hook This Up (map)"
                        margin="dense"
                        variant="outlined"
                        value={taxId}
                        select
                        size="small"
                        SelectProps={{ native: true }}
                        onChange={event => setTaxId(event.target.value)}
                      >
                        <option></option>
                        {taxList.map(tax => (
                          <option key={tax.id} value={tax.id}>
                            {tax.code}
                          </option>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>
                </Grid>
                <Box alignItems="center" display="flex" mt={1} ml={-1}>
                  <Checkbox checked={discount} onChange={handleCheckDiscount} />
                  <Typography variant="body2" color="textSecondary">
                    Apply a Discount
                  </Typography>
                </Box>
                <Divider />
                <Box alignItems="center" display="flex" mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    startIcon={
                      <SvgIcon>
                        <AddIcon />
                      </SvgIcon>
                    }
                  >
                    Add Item To Order
                  </Button>
                </Box>
              </form>
            </>
          )}
        </Box>
      </Card>
    </>
  )
}

const ADD_ORDER_ITEM_MUTATION = gql`
  mutation (
    $orderId: Int!
    $itemId: Int!
    $taxId: Int
    $discountId: Int
    $qty: Int
    $priceEach: Money
  ) {
    createOrderItem(
      orderId: $orderId
      itemId: $itemId
      taxId: $taxId
      discountId: $discountId
      qty: $qty
      priceEach: $priceEach
    ) {
      orderItem {
        id
        item {
          id
          name
          featuredImageThumbnailUrl
          category {
            id
            name
          }
          businessUnit {
            id
            name
          }
          isActive
          isPrivate
          pricingModel {
            id
            name
          }
          itemPrice {
            id
            minQuantity
            maxQuantity
            price
          }
        }
        itemSubtotal
        itemTotal
        qty
        priceEach
        order {
          id
        }

        tax {
          id
          code
        }
        taxAmt
        discountAmt
        discount {
          id
          code
        }
      }
    }
  }
`

const SALES_TAXES_QUERY = gql`
  query {
    taxes {
      id
      code
      name
      taxRate
    }
  }
`
export default AddOrderItem
