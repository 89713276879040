import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { withStyles, useTheme } from "@material-ui/core/styles"
import { Plus as PlusIcon } from "react-feather"
import ProjectContext from "../context/ProjectContext"

const ProjectDetails = ({ classes, onBack, onNext, ...rest }) => {
  const [tag, setTag] = useState("")
  const { state, dispatch } = useContext(ProjectContext)
  const [projectTags, setProjectTags] = useState(state.projectTags)
  const [projectManager, setProjectManager] = useState("")

  const theme = useTheme()

  const handleAddTag = event => {
    const selectedTag = state.tagOptions.find(t => t.id == tag)
    const oldTags = state.projectTags ? state.projectTags : []
    const newTags = [...oldTags, selectedTag]
    setProjectTags(newTags)
    dispatch({ type: "SET_PROJECT_TAGS", payload: newTags })
    setTag("")
  }

  const handleDeleteTag = chipToDelete => () => {
    const updatedProjectTags = projectTags.filter(
      projectTag => projectTag.id != chipToDelete.id
    )
    setProjectTags(updatedProjectTags)
    dispatch({
      type: "SET_PROJECT_TAGS",
      value: updatedProjectTags,
    })
  }
  const handleChangePm = event => {
    event.persist()

    let value = 0

    if (event.target.value !== 0) {
      value = event.target.value
    }
    dispatch({ type: "SET_PROJECT_MANAGER_ID", payload: value })
    setProjectManager(value)
  }

  return (
    <>
      <Box mb={2}>
        <Typography variant="h3" color="textPrimary">
          About this project
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl required fullWidth>
            <TextField
              fullWidth
              label="Project Name"
              name="projectName"
              // onBlur={handleBlur}
              value={state.title}
              onChange={event =>
                dispatch({
                  type: "SET_PROJECT_TITLE",
                  payload: event.target.value,
                })
              }
              variant="outlined"
            />
            <FormHelperText>* Required</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="pm-selector"
            label="Project Manager"
            variant="outlined"
            value={projectManager}
            onChange={handleChangePm}
            select
            SelectProps={{ native: true }}
            fullWidth
          >
            <option></option>
            {state.staffList &&
              state.staffList.map(s => (
                <option key={s.id} value={s.id}>
                  {s.person.firstName + " " + s.person.lastName}
                </option>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignContent="center">
            <TextField
              id="tag-selector"
              label="Tags"
              variant="outlined"
              value={tag}
              onChange={event => setTag(event.target.value)}
              select
              SelectProps={{ native: true }}
              style={{ width: 300 }}
            >
              <option></option>
              {state.tagOptions &&
                state.tagOptions.map(t => (
                  <option key={t.id} value={t.id}>
                    {t.name}
                  </option>
                ))}
            </TextField>

            <IconButton
              variant="contained"
              className={classes.addTab}
              onClick={handleAddTag}
            >
              <SvgIcon>
                <PlusIcon />
              </SvgIcon>
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box component="ul" className={classes.chipList}>
            {projectTags &&
              projectTags.map(pt => (
                <li key={pt.id}>
                  <Chip
                    label={pt.name}
                    onDelete={handleDeleteTag(pt)}
                    className={classes.chip}
                  />
                </li>
              ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="datetime-local-start"
            label="Start Date"
            type="datetime-local"
            variant="outlined"
            fullWidth
            // className={classes.field}
            value={state.startDate}
            onChange={event =>
              dispatch({ type: "SET_START_DATE", payload: event.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="datetime-local-due"
            label="Due Date"
            type="datetime-local"
            variant="outlined"
            fullWidth
            // className={classes.field}
            value={state.dueDate}
            onChange={event =>
              dispatch({ type: "SET_DUE_DATE", payload: event.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Priority"
            name="priority"
            fullWidth
            onChange={event =>
              dispatch({
                type: "SET_PROJECT_PRIORITY",
                payload: event.target.value,
              })
            }
            select
            variant="outlined"
            SelectProps={{ native: true }}
            value={state.priority}
          >
            <option value={1}>1 - Highest Priority</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5 - Lowest Priority</option>
          </TextField>
        </Grid>
      </Grid>
      <Box mt={6} display="flex">
        {onBack && (
          <Button onClick={onBack} size="large">
            Previous
          </Button>
        )}
        <Box flexGrow={1} />
        <Button
          color="secondary"
          disabled={state.title.length < 3}
          onClick={onNext}
          variant="contained"
          size="large"
        >
          Next
        </Button>
      </Box>
    </>
  )
}

const styles = theme => ({
  root: {},
  addTab: {
    marginLeft: theme.spacing(2),
  },
  tag: {
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  chipList: {
    display: "flex",
    // justifyContent: 'center',
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  datePicker: {
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  preview: {
    fontFamily: theme.typography.fontFamily,
  },
})

ProjectDetails.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
}

export default withStyles(styles)(ProjectDetails)
