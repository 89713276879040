/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { useDropzone } from "react-dropzone"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import MoreIcon from "@material-ui/icons/MoreVert"
import bytesToSize from "../../../../../utils/bytesToSize"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: "pointer",
    },
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}))

function ProjectFilesDropzone({
  className,
  project,
  projectFiles,
  setProjectFiles,
  ...rest
}) {
  const classes = useStyles()
  const [files, setFiles] = useState([])
  const [fileError, setFileError] = useState("")

  const [handleUploadFiles, { data, loading, error }] = useMutation(
    UPLOAD_FILES_MUTATION,
    {
      variables: {
        projectId: project.id,
        uploads: files,
        note: "test file",
      },
      onCompleted: data => {
        console.log(data)
        const newFiles = data.uploadProjectFiles.projectFiles
        setProjectFiles(newFiles)
      },
    }
  )
  // Reference only
  // const createFile = async () => {
  //   let newFile = await fetch(imageFile)
  //     .then(r => r.blob())
  //     .then(
  //       imageFile =>
  //         new File([imageFile], `${name}.jpg`, {
  //           type: "image/jpeg",
  //         })
  //     )
  //   setFile(newFile)
  //   console.log(newFile)
  // }

  const handleDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()
      const fileSizeLimit = 10000000 // 10mb

      reader.onabort = () => console.log("file reading was aborted")
      reader.onerror = () => console.log("file reading has failed")
      reader.onload = () => {
        if (file && file.size > fileSizeLimit) {
          setFileError(`${file.name}: File size too large`)
        } else {
          // setFiles(prevFiles => [...prevFiles].concat(file))
          // console.log(files)

          // setFiles(prevFiles => [...prevFiles].concat(acceptedFiles))
          setFileError("")
        }
        const binaryStr = reader.result
        console.log(binaryStr)
      }
      reader.readAsArrayBuffer(file)
    })
    setFiles(prevFiles => [...prevFiles].concat(acceptedFiles))

    console.log(files.length)
  }, [])

  const handleRemoveAll = () => {
    setFiles([])
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  })

  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {/* <div>
          <img
            alt="Select file"
            className={classes.image}
            src=""
          />
        </div> */}
        <div>
          <Typography gutterBottom variant="h3">
            Select files
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1">
              Drop files here or click <Link underline="always">browse</Link>{" "}
              thorough your machine
            </Typography>
          </Box>
        </div>
      </div>
      {files.length > 0 && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem divider={i < files.length - 1} key={i}>
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: "h5" }}
                    secondary={bytesToSize(file.size)}
                  />
                  <Tooltip title="More options">
                    <IconButton edge="end">
                      <MoreIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <div className={classes.actions}>
            <Button onClick={handleRemoveAll} size="small">
              Remove all
            </Button>
            <Button
              onClick={handleUploadFiles}
              color="secondary"
              size="small"
              variant="contained"
            >
              Upload files
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

ProjectFilesDropzone.propTypes = {
  className: PropTypes.string,
}
const UPLOAD_FILES_MUTATION = gql`
  mutation ($projectId: Int!, $uploads: [Upload], $note: String) {
    uploadProjectFiles(projectId: $projectId, uploads: $uploads, note: $note) {
      projectFiles {
        id
        image
      }
    }
  }
`
export default ProjectFilesDropzone
