import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import getInitials from "../../../../utils/getInitials"
import Gravatar from "react-gravatar"

const useStyles = makeStyles(theme => ({
  root: {},
  name: {
    marginTop: theme.spacing(1),
  },
  avatar: {
    height: 100,
    width: 100,
  },
}))

function ProfileDetails({
  user,
  updatePhoto,
  setUpdatePhoto,
  className,
  profileImageUrl,
  profileImage,
  useGravatar,
  setUseGravatar,
  ...rest
}) {
  const classes = useStyles()
  const [firstName, setFirstName] = useState(user.person.firstName)
  const [lastName, setLastName] = useState(user.person.lastName)
  const [image, setImage] = useState(profileImageUrl)

  const [removePhoto, { loading, error }] = useMutation(REMOVE_PHOTO_MUTATION, {
    variables: {
      uid: user.person.uid,
      firstName: firstName,
      lastName: lastName,
      useGravatar: false,
      image: null,
    },
    onCompleted: data => {
      console.log(data)
    },
  })
  // const handleRemovePhoto = () => {
  //   setImage(null)
  //   removePhoto()
  // }
  //   const myImage = profileImageUrl => {
  //     if (profileImageUrl.length > 2) return profileImageUrl
  //     return user.person.profileImageUrl
  //   }
  //   const [useGravatar, setUseGravatar] = useState(user.person.useGravatar)
  //   const [profileImage, setProfileImage] = useState(user.person.profileImage)

  //   const [updateProfile, { loading, data }] = useMutation(
  //     UPDATE_PERSON_PROFILE_MUTATION,
  //     {
  //       variables: {
  //         uid: user.person.uid,
  //         image: profileImage,
  //         useGravatar: useGravatar,
  //         firstName: firstName,
  //         lastName: lastName,
  //       },
  //       onCompleted: data => {
  //         let updatedProfile = data.updatePersonProfile.person
  //         setFirstName(updatedProfile.firstName)
  //         setLastName(updatedProfile.lastName)
  //         setUseGravatar(updatedProfile.useGravatar)
  //         setProfileImage(updatedProfile.profileImage)
  //         setProfileImageUrl(updatedProfile.profileImageUrl)
  //       },
  //     }
  //   )
  //   const avatarImage = {gravatar ? (<Gravatar email={currentUser.email} size={40} />) : profileImage && profileImageUrl ? profileImageUrl : ""}
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          {user.person.useGravatar === false && user.person.profileImage && (
            <Avatar
              className={classes.avatar}
              // src={myImage}
              alt={`${firstName} ${lastName}`}
              src={user.person.profileImageUrl}
            />
          )}
          {user.person.useGravatar === true && (
            <Avatar>
              <Gravatar email={user.email} size={100} />
            </Avatar>
          )}

          <Typography
            className={classes.name}
            gutterBottom
            variant="h3"
            color="textPrimary"
          >
            {firstName} {lastName}
          </Typography>
          <Typography color="textPrimary" variant="body1">
            Location
            {/* {`${user.state}, ${user.country}`} */}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            timezone
            {/* {user.timezone} */}
          </Typography>
        </Box>
      </CardContent>
      <CardActions>
        {!user.person.profileImage && (
          <Button fullWidth variant="text" onClick={() => setUpdatePhoto(true)}>
            Upload Photo
          </Button>
        )}
        {user.person.useGravatar === false && user.person.profileImage && (
          <Button fullWidth variant="text" onClick={() => removePhoto}>
            Remove Photo
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

ProfileDetails.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
}

const REMOVE_PHOTO_MUTATION = gql`
  mutation (
    $uid: String!
    $image: Upload
    $useGravatar: Boolean
    $firstName: String
    $lastName: String
  ) {
    updatePersonProfile(
      uid: $uid
      image: $image
      useGravatar: $useGravatar
      firstName: $firstName
      lastName: $lastName
    ) {
      person {
        uid
        firstName
        lastName
        useGravatar
        dateModified
        profileImage
        profileImageUrl
      }
    }
  }
`
export default ProfileDetails
