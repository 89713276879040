import React, { createContext } from "react"

const OrderContext = createContext({
  search: "",
  pageSize: 10,
  page: 1,
  orderId: null,

  isSearching: false,
  isAddingOrderItem: false,
  createNewItem: false,

  orderDetails: "",
  orderItems: [],

  addOrderItem: false,
  searchItems: false,
  createItem: false,

  itemsInOrder: 0,

  subTotal: 0.0,
  discount: 0.0,
  tax: 0.0,
  shipping: 0.0,
  total: 0.0,
  balanceDue: 0.0,
  inProgress: true,
  completed: false,

  selectedItem: "",
  oiId: null,
  oiQty: null,
  oiPriceEach: null,

  allItems: [],
})

export default OrderContext
