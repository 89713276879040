import React, { useState } from "react"
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import ImageFiles from "./ImageFiles"
import WorkingFileCard from "./WorkingFileCard"
const AllFiles = ({
  classes,
  item,
  files,
  setFiles,
  workingFiles,
  setWorkingFiles,
}) => {
  return (
    <div>
      {workingFiles && workingFiles.length > 0 && (
        <Box m={2}>
          <Typography variant="h3">Working Files</Typography>
          <Typography variant="body1">Staff view only</Typography>
          {workingFiles.map(wf => (
            <List>
              <ListItem key={wf.id}>
                <WorkingFileCard
                  wf={wf}
                  workingFiles={workingFiles}
                  setWorkingFiles={setWorkingFiles}
                />
              </ListItem>
            </List>
          ))}
          {/* <WorkingFiles
            workingFiles={workingFiles}
            setWorkingFiles={setWorkingFiles}
          /> */}
          <Divider />
        </Box>
      )}
      <Box m={2}>
        <Typography variant="h3">Image Files</Typography>
        <Typography variant="body1">Staff and Customer View</Typography>
      </Box>
      <ImageFiles item={item} files={files} setFiles={setFiles} />
    </div>
  )
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(AllFiles)
