import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  Radio,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"

import moment from "moment"
import PerfectScrollbar from "react-perfect-scrollbar"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import SearchPersonConfirm from "./SearchPersonConfirm"

import {
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  UserCheck as CheckIcon,
  User as UserIcon,
  Search as SearchIcon,
  XSquare as CloseIcon,
} from "react-feather"
import SortContext from "../../../../context/SortContext"

const useStyles = makeStyles(() => ({
  root: {},
  queryField: {
    margin: "dense",
    size: "small",
  },
}))

function SearchPersonAdd({
  inquiry,
  className,
  onSubmitSuccess,
  setOpenSearchPerson,
  setIsPerson,
  ...rest
}) {
  const classes = useStyles()
  const { state, dispatch } = useContext(SortContext)
  const search = state.search
  const page = state.page
  const pageQ = state.page + 1
  const pageSize = state.pageSize

  const firstName = inquiry.inquiryPerson.firstName
  const lastName = inquiry.inquiryPerson.lastName
  const last = inquiry.inquiryPerson.lastName.substring(0, 3)

  const [searchBar, setSearchBar] = useState("")
  const [pickPerson, setPickPerson] = useState(false)
  const [pickUid, setPickUid] = useState("")
  const [pickFirst, setPickFirst] = useState("")
  const [pickLast, setPickLast] = useState("")
  const [newPage, setNewPage] = useState(1)

  const id = inquiry.id

  const selectPerson = ({ match }) => {
    setPickUid(match.uid)
    setPickFirst(match.firstName)
    setPickLast(match.lastName)
    setPickPerson(true)
  }

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  const { loading, error, data } = useQuery(SEARCH_MATCHES_QUERY, {
    variables: {
      search: search,
      page: pageQ,
      pageSize: pageSize,
    },
    fetchPolicy: "cache-and-network",
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const resCount = data.people.count
  const resPage = data.people.page
  const resPages = data.people.pages
  const people = data.people.objects
  const matches = data.people.objects

  const handlePageChange = (event, newPage) => {
    dispatch({ type: "SET_PAGE", payload: newPage })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  return (
    <>
      <Box minWidth={700}>
        {pickPerson ? (
          ""
        ) : (
          <>
            <Typography gutterBottom variant="h3" color="textPrimary">
              Matches for {firstName} {lastName}
            </Typography>
            <Typography gutterBottom variant="p" color="textPrimary">
              Search through our contacts to see if we already have an account
              for {firstName}.
            </Typography>
            <Box
              minHeight={56}
              maxWidth={230}
              display="flex"
              alignItems="center"
            >
              <form
                noValidate
                onSubmit={event => {
                  event.preventDefault()
                  handleSearch(event)
                }}
              >
                <TextField
                  className={classes.queryField}
                  margin="dense"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton type="submit" color="primary">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={event => setSearchBar(event.target.value)}
                  placeholder="Find a match"
                  value={searchBar}
                  variant="outlined"
                />
              </form>
            </Box>
          </>
        )}
      </Box>

      <Box>
        {pickPerson ? (
          <SearchPersonConfirm
            setOpenSearchPerson={setOpenSearchPerson}
            inquiry={inquiry}
            pickUid={pickUid}
            pickFirst={pickFirst}
            pickLast={pickLast}
            id={id}
            setPickPerson={setPickPerson}
            setIsPerson={setIsPerson}
          />
        ) : (
          <PerfectScrollbar>
            <Box minWidth={700}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Date Added</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matches.map(match => {
                    const emails = match.emailPerson
                    const phones = match.phonePerson
                    const addresses = match.addressPerson
                    const user = match.user
                    const uid = match.uid
                    return (
                      <TableRow
                        hover
                        key={match.uid}
                        // selected={isCustomerSelected}
                      >
                        <TableCell padding="checkbox">
                          <IconButton onClick={e => selectPerson({ match }, e)}>
                            <SvgIcon fontSize="small">
                              <CheckIcon />
                            </SvgIcon>
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <Typography variant="body2" color="textSecondary">
                              {match.firstName} {match.lastName}
                            </Typography>
                            {user ? (
                              <SvgIcon fontSize="small" color="secondary">
                                <UserIcon />
                              </SvgIcon>
                            ) : (
                              ""
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {emails.map(email => (
                            <div key={email.id}>{email.emailAddress}</div>
                          ))}
                        </TableCell>
                        <TableCell>
                          {phones.map(phone => (
                            <div key={phone.id}>{phone.phoneNumber}</div>
                          ))}
                        </TableCell>
                        <TableCell>
                          {addresses.map(address => (
                            <div key={address.id}>{address.city}</div>
                          ))}
                        </TableCell>
                        <TableCell>
                          {moment(match.dateAdded).format("MMM DD YYYY")}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        )}
      </Box>
      <TablePagination
        component="div"
        count={resCount}
        onChangePage={handlePageChange}
        // onChangePage= {event => dispatch({type: 'SET_PAGE', payload: (newPage)})}
        // onChangeRowsPerPage={event => setPageSize(event.target.value)}
        // onChangeRowsPerPage = {event => dispatch({type: 'SET_PAGE_SIZE', payload: (event.target.value)})}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        // dispatch({ type: "SET_LOGIN" })
        // onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, 25, 50]}
        // ActionsComponent={TablePaginationActions}
      />
    </>
  )
}

export const SEARCH_MATCHES_QUERY = gql`
  query ($page: Int!, $pageSize: Int!, $search: String) {
    people(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        uid
        firstName
        lastName
        middleName
        suffix
        nickname
        title
        about
        userCanUpdate
        isSupplier
        dateAdded
        dateModified
        phonePerson {
          id
          phoneNumber
        }
        addressPerson {
          id
          address {
            uid
            region {
              id
              name
            }
            category {
              id
              name
            }
            attention
            addressLine1
            addressLine2
            addressLine3
            zone
            city
            postalCode
            summary
          }
        }
        personSocialMedia {
          id
          socialMediaType {
            id
            name
          }
          username
          url
        }
        emailPerson {
          id
          emailAddress
        }

        notePerson {
          id
          note
        }
      }
    }
  }
`

export default SearchPersonAdd
