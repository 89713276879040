import React, { useState, useContext } from "react"
import { Box, Container, makeStyles } from "@material-ui/core"

import Page from "../../../Global/Page"
import useIsMountedRef from "../../../../hooks/useIsMountedRef"
import Header from "./Header"
import Results from "./Results"
import SortContext from "../../../../context/SortContext"
import PerfectScrollbar from "react-perfect-scrollbar"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "91vh",
    paddingTop: theme.spacing(1),
    paddingottom: theme.spacing(1),
  },
}))

const CartListView = () => {
  const classes = useStyles()

  const { state, dispatch } = useContext(SortContext)
  const [products, setProducts] = useState(null)
  const search = state.search
  const page = state.page + 1
  const pageSize = state.pageSize
  const defaultStartDate = new Date("2020-01-01T00:00:01")
  const now = new Date()
  const [cartOpenFilter, setCartOpenFilter] = useState(null)
  const [cartExpiredFilter, setCartExpiredFilter] = useState(null)
  const [cartBusinessUnitFilter, setCartBusinessUnitFilter] = useState(null)
  const [cartStartedFilter, setCartStartedFilter] = useState([
    defaultStartDate,
    now,
  ])
  const [cartLatestChangeFilter, setCartLatestChangeFilter] = useState([
    defaultStartDate,
    now,
  ])

  const { loading, error, data } = useQuery(LIST_ALL_CARTS_QUERY, {
    variables: {
      search: search,
      page: page,
      pageSize: pageSize,
      cartOpenFilter: cartOpenFilter,
      cartExpiredFilter: cartExpiredFilter,
      cartBusinessUnitFilter: cartBusinessUnitFilter,
      cartStartedFilter: cartStartedFilter,
      cartLatestChangeFilter: cartLatestChangeFilter,
    },
    fetchPolicy: "cache-and-network",
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const res = data.carts
  return (
    <Page className={classes.root} title="Cart List">
      <PerfectScrollbar>
        <Container maxWidth={false}>
          <Header />
          <Results
            res={res}
            defaultStartDate={defaultStartDate}
            now={now}
            cartOpenFilter={cartOpenFilter}
            setCartOpenFilter={setCartOpenFilter}
            cartExpiredFilter={cartExpiredFilter}
            setCartExpiredFilter={setCartExpiredFilter}
            cartBusinessUnitFilter={cartBusinessUnitFilter}
            setCartBusinessUnitFilter={setCartBusinessUnitFilter}
            cartStartedFilter={cartStartedFilter}
            setCartStartedFilter={setCartStartedFilter}
            cartLatestChangeFilter={cartLatestChangeFilter}
            setCartLatestChangeFilter={setCartLatestChangeFilter}
          />
        </Container>
      </PerfectScrollbar>
    </Page>
  )
}

const LIST_ALL_CARTS_QUERY = gql`
  query (
    $page: Int
    $pageSize: Int
    $search: String
    $cartOpenFilter: Boolean
    $cartExpiredFilter: Boolean
    $cartStartedFilter: [DateTime]
    $cartBusinessUnitFilter: Int
    $cartLatestChangeFilter: [DateTime]
  ) {
    carts(
      page: $page
      pageSize: $pageSize
      search: $search
      cartOpenFilter: $cartOpenFilter
      cartExpiredFilter: $cartExpiredFilter
      cartStartedFilter: $cartStartedFilter
      cartBusinessUnitFilter: $cartBusinessUnitFilter
      cartLatestChangeFilter: $cartLatestChangeFilter
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        cartId
        person {
          uid
          firstName
          lastName
        }
        started
        latestChange
        cartOpen
        businessUnit {
          id
          name
        }
        items
        totalItems
        totalUniqueItems
        cartExpired
        expirationDate
      }
    }
  }
`
export default CartListView
