import React, { useState, useEffect, useCallback } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import useIsMountedRef from "../../../../Global/hooks/useIsMountedRef"
import Chart from "./Chart"

const useStyles = makeStyles(theme => ({
  root: {},
  current: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  navigateNextIcon: {
    marginLeft: theme.spacing(1),
  },
}))

function getRandomInt(min, max) {
  // eslint-disable-next-line no-param-reassign
  min = Math.ceil(min)
  // eslint-disable-next-line no-param-reassign
  max = Math.floor(max)

  return Math.floor(Math.random() * (max - min + 1)) + min
}

function CurrentOrders({ className, ...rest }) {
  const classes = useStyles()
  const isMountedRef = useIsMountedRef()
  const [data, setData] = useState([
    163,
    166,
    161,
    159,
    99,
    163,
    173,
    166,
    167,
    183,
    176,
    172,
  ])

  const getData = useCallback(() => {
    if (isMountedRef.current) {
      setData(prevData => {
        const newData = [...prevData]

        newData.shift()
        newData.push(0)

        return newData
      })
    }

    setTimeout(() => {
      if (isMountedRef.current) {
        setData(prevData => {
          const newData = [...prevData]
          const random = getRandomInt(100, 200)

          newData.pop()
          newData.push(random)

          return newData
        })
      }
    }, 500)
  }, [isMountedRef])

  useEffect(() => {
    setInterval(() => getData(), 2000)
  }, [getData])

  const labels = data.map((value, i) => i)

  const businesses = [
    {
      businessUnit: "ICON Emblem",
      url: "https://www.iconemblem.com",
      orders: "14",
    },
    {
      businessUnit: "Jersey Emblems",
      url: "https://www.jerseyemblems.com",
      orders: "8",
    },
  ]

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={
          <Typography color="inherit" variant="h3">
            {data[data.length - 1] === 0
              ? data[data.length - 2]
              : data[data.length - 1]}
          </Typography>
        }
        classes={{ action: classes.current }}
        subheader="Orders this Week"
        subheaderTypographyProps={{ color: "textSecondary", variant: "body2" }}
        title="Current Orders"
        titleTypographyProps={{ color: "textPrimary" }}
      />
      <Chart data={data} labels={labels} />
      <List>
        {businesses.map(business => (
          <ListItem
            classes={{ divider: classes.itemDivider }}
            divider
            key={business.businessUnit}
          >
            <ListItemText
              primary={business.businessUnit}
              primaryTypographyProps={{
                color: "textSecondary",
                variant: "body2",
              }}
            />
            <Typography color="inherit">{business.orders}</Typography>
          </ListItem>
        ))}
      </List>
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button component={Link} size="small" to="#">
          See all
          <NavigateNextIcon className={classes.navigateNextIcon} />
        </Button>
      </Box>
    </Card>
  )
}

CurrentOrders.propTypes = {
  className: PropTypes.string,
}

export default CurrentOrders
