import React, { useState } from "react"
import { useQuery, useMutation, useEffect } from "@apollo/client"
import gql from "graphql-tag"
import clsx from "clsx"
import PropTypes from "prop-types"
import DoneIcon from "@material-ui/icons/Done"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import useDidMountEffect from "../../../../../Global/hooks/useDidMountEffect"
import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"
import InstagramIcon from "@material-ui/icons/Instagram"
import ShareIcon from "@material-ui/icons/Share"
// I was in the middle of adding in the social media types. I have not tested this one at all

import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    margin: 10,
  },
  margin: {
    margin: 2,
  },
}))

const UpdatePersonSocial = ({
  person,
  socials,
  setSocials,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const personUid = person.uid
  const [username, setUsername] = useState("")
  const [socialMediaTypeId, setSocialMediaTypeId] = useState(1)
  const [socialMediaType, setSocialMediaType] = useState(1)
  const [id, setId] = useState(null)

  const [addedAccounts, setAddedAccounts] = useState([])
  const [url, setUrl] = useState("")
  const [newArray, setNewArray] = useState([])
  const [socialMediaTypes, setSocialMediaTypes] = useState([])

  const [socialMediaTypeUrl, setSocialMediaTypeUrl] = useState("")

  const { loading, error } = useQuery(SOCIAL_MEDIA_TYPES_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      setSocialMediaTypes(data.socialMediaTypes)
    },
  })
  const [deleteSocialMediaAccountPerson] = useMutation(
    DELETE_PERSON_SOCIAL_MEDIA_MUTATION,
    {
      variables: {
        id: id,
      },
      onCompleted: () => {
        setSocials(newArray)
        console.log("delete completed")
      },
    }
  )
  const [createSocialMediaAccountPerson] = useMutation(
    ADD_PERSON_SOCIAL_MEDIA_MUTATION,
    {
      variables: {
        personUid: personUid,
        username: username,
        socialMediaTypeId: socialMediaTypeId,
        url: url,
      },
      onCompleted: data => {
        // console.log(data.createSocialMediaAccountPerson)
        const accountValue =
          data.createSocialMediaAccountPerson.socialMediaAccountPerson
        setSocials(prevSelected => [...prevSelected, accountValue])
        setUsername("")
        setUrl("")
      },
    }
  )
  const handleFormSocialMedia = event => {
    createSocialMediaAccountPerson()
  }

  const handleSocialMediaTypeChange = event => {
    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
    }
    const smtype = socialMediaTypes.filter(x => x.id == value).pop()

    setSocialMediaTypeUrl(smtype.url)
    setSocialMediaTypeId(value)
    setSocialMediaType(value)
  }

  const handleDelete = account => {
    const filteredArray = socials.filter(x => x.id !== account.id)
    setNewArray(filteredArray)
    setId(account.id)
  }

  useDidMountEffect(() => {
    deleteSocialMediaAccountPerson()
  }, [id])

  // useDidMountEffect(() => {
  //   console.log(socialMediaTypeId)

  //   const smtype = socialMediaTypes.filter(x => x.id == socialMediaTypeId).pop()

  //   console.log(smtype)
  //   setSocialMediaTypeUrl(smtype.url)
  //   // setSocialMediaTypeId(socialMediaType.id)
  //   // setSocialMediaTypeUrl(socialMediaType.url)
  // }, [socialMediaTypeId])

  // useDidMountEffect(() => {
  //   console.log(socialMediaTypeId)

  //   const smtype = socialMediaTypes.filter(x => x.id == socialMediaTypeId).pop()

  //   console.log(smtype)
  //   setSocialMediaTypeUrl(smtype.url)
  //   // setSocialMediaTypeId(socialMediaType.id)
  //   // setSocialMediaTypeUrl(socialMediaType.url)
  // }, [socialMediaTypeId])

  useDidMountEffect(() => {
    // const website = socialMediaTypeUrl
    // const myname = username
    // const fullUrl = `${website}${myname}`
    const fullUrl = `${socialMediaTypeUrl}${username}`
    setUrl(fullUrl)
  }, [username, socialMediaTypeId])

  const iconSwitch = socialTypeId => {
    switch (socialTypeId) {
      case "Twitter":
        return <TwitterIcon />
      case "Facebook":
        return <FacebookIcon />
      case "Instagram":
        return <InstagramIcon />
      default:
        return <ShareIcon />
    }
  }
  return (
    <>
      <Box className={classes.top} mb={2}>
        <ShareIcon style={{ fontSize: 30, marginBottom: -6 }} />
        <Box ml={3} display="inline">
          <Typography display="inline" variant="h3">
            Social Media
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          {/* <Typography>Social Media Accounts</Typography> */}

          <>
            {socials &&
              socials.map(account => {
                const socialTypeId = account.socialMediaType.name
                return (
                  <Box key={account.id} display="inline" margin={1}>
                    <Chip
                      icon={iconSwitch(socialTypeId)}
                      // icon={<ShareIcon />}
                      label={`${account.username} (${account.socialMediaType.name})`}
                      variant="outlined"
                      onDelete={e => handleDelete(account)}
                    />
                  </Box>
                )
              })}
          </>
        </Grid>
        <Grid item sm container direction="column">
          <form
            onSubmit={event => {
              event.preventDefault()
              handleFormSocialMedia(event)
            }}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Grid item xs={12}>
              <TextField
                name="socialMediaType"
                // onChange={event => setSocialMediaTypeId(event.target.value)}
                onChange={handleSocialMediaTypeChange}
                select
                margin="dense"
                size="small"
                fullWidth
                variant="outlined"
                SelectProps={{ native: true }}
                value={socialMediaTypeId}
                // onChange={event => handleUpdateSocialMediaType(smt)}
              >
                {socialMediaTypes.map(smt => (
                  <option key={smt.id} value={smt.id}>
                    {smt.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Username"
                margin="normal"
                name="username"
                onChange={event => setUsername(event.target.value)}
                type="text"
                value={username}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="URL"
                margin="normal"
                name="url"
                onChange={event => setUrl(event.target.value)}
                type="url"
                value={url}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                size="medium"
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                aria-label="add social media acct"
                className={classes.margin}
              >
                Add Social Media Account
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  )
}

const ADD_PERSON_SOCIAL_MEDIA_MUTATION = gql`
  mutation (
    $personUid: String!
    $socialMediaTypeId: Int!
    $username: String!
    $url: String
  ) {
    createSocialMediaAccountPerson(
      personUid: $personUid
      socialMediaTypeId: $socialMediaTypeId
      username: $username
      url: $url
    ) {
      socialMediaAccountPerson {
        id
        username
        socialMediaType {
          id
          name
          url
        }
        url
      }
    }
  }
`

const DELETE_PERSON_SOCIAL_MEDIA_MUTATION = gql`
  mutation ($id: Int!) {
    deleteSocialMediaAccountPerson(id: $id) {
      id
    }
  }
`

const SOCIAL_MEDIA_TYPES_QUERY = gql`
  query {
    socialMediaTypes {
      id
      name
      url
    }
  }
`
UpdatePersonSocial.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
}

UpdatePersonSocial.default = {
  onSubmitSuccess: () => {},
}

export default UpdatePersonSocial
