import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  PlusCircle as PlusCircleIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
} from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

const useStyles = makeStyles(theme => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

function Header({ className, ...rest }) {
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            className={classes.link}
            variant="body1"
            color="inherit"
            to="/manager/home"
          >
            Dashboard
          </Link>

          <Typography variant="body1" color="textPrimary">
            Items
          </Typography>
        </Breadcrumbs>
        <Box mt={2}>
          <Typography variant="h3" color="textPrimary">
            All Items
          </Typography>
        </Box>
        {/* <Box mt={2}>
          <Button className={classes.action}>
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <UploadIcon />
            </SvgIcon>
            Import
          </Button>
          <Button className={classes.action}>
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <DownloadIcon />
            </SvgIcon>
            Export
          </Button>
        </Box> */}
      </Grid>
      <Grid item>
        <Button
          component={Link}
          color="secondary"
          variant="contained"
          className={classes.action}
          to={"/manager/items/create"}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PlusCircleIcon />
          </SvgIcon>
          New Item
        </Button>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
