import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Button,
  Chip,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
  NoSsr,
} from "@material-ui/core"
import ReactMarkdown from "react-markdown"

import ProjectContext from "../context/ProjectContext"

const useStyles = makeStyles(theme => ({
  root: {},
  preview: {
    fontFamily: theme.typography.fontFamily,
  },
  previewPaper: {
    margin: theme.spacing(2),
    minHeight: 200,
  },
  editor: {
    flexGrow: 1,
    "& .ql-editor": {
      minHeight: 300,
    },
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipList: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  category: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1),
  },
}))

function ProjectDescription({ className, onBack, onComplete, ...rest }) {
  const { state, dispatch } = useContext(ProjectContext)
  const projectTags = state.projectTags
  const categoryOptions = state.categoryOptions
  const categoryId = state.categoryId
  const category = categoryOptions.find(cat => cat.id == categoryId)
  const classes = useStyles()
  const [content, setContent] = useState("")
  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const [body, setBody] = useState("")

  const handleChange = value => {
    setContent(value)
  }

  const handleSubmit = event => {
    event.preventDefault()
    onComplete()
  }

  return (
    <>
      <Box mt={2}>
        <Typography className={classes.title}>{state.title}</Typography>
        <Typography className={classes.category}>
          Category: {category.name}
        </Typography>

        <Box component="ul" className={classes.chipList}>
          <Typography variant="body1" style={{ display: "inline" }}>
            Tags:
          </Typography>
          {projectTags &&
            projectTags.map(pt => (
              <li key={pt.id}>
                <Chip label={pt.name} className={classes.chip} />
              </li>
            ))}
        </Box>
      </Box>
      <form
        onSubmit={handleSubmit}
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Box mt={2}>
          <Typography variant="h4" color="textSecondary">
            Project Objective
          </Typography>
          <Typography variant="body2" color="textSecondary">
            No more than 2-3 short sentences
          </Typography>
        </Box>

        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="objective-text-field"
                  label="Objective"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={10}
                  value={state.objective}
                  onChange={event =>
                    dispatch({
                      type: "SET_PROJECT_OBJECTIVE",
                      payload: event.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.previewPaper}>
                  <ReactMarkdown className={classes.preview}>
                    {state.objective}
                  </ReactMarkdown>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {error && (
          <Box mt={2}>
            <FormHelperText error>{FormHelperText}</FormHelperText>
          </Box>
        )}
        <Box mt={6} display="flex">
          {onBack && (
            <Button onClick={onBack} size="large">
              Previous
            </Button>
          )}
          <Box flexGrow={1} />
          <Button
            color="secondary"
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            size="large"
          >
            Start Project
          </Button>
        </Box>
      </form>
    </>
  )
}

ProjectDescription.propTypes = {
  className: PropTypes.string,
  onComplete: PropTypes.func,
  onBack: PropTypes.func,
}

export default ProjectDescription
