import React, { useState, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Button,
  Card,
  CardHeader,
  Collapse,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core"
import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"
import InstagramIcon from "@material-ui/icons/Instagram"

import ShareIcon from "@material-ui/icons/Share"
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid"
import ContactMailIcon from "@material-ui/icons/ContactMail"
import EmailIcon from "@material-ui/icons/Email"
import LockOpenIcon from "@material-ui/icons/LockOpenOutlined"
import PersonIcon from "@material-ui/icons/PersonOutline"
// import Label from "../../../../Global/Label"
import UpdateOrgEmail from "./OrgUpdateForms/UpdateOrgEmail"
import UpdateOrgPhone from "./OrgUpdateForms/UpdateOrgPhone"
import UpdateOrgSocialMedia from "./OrgUpdateForms/UpdateOrgSocialMedia"
import UpdateOrgAddress from "./OrgUpdateForms/UpdateOrgAddress"

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

const OrgInfo = ({ org, className, ...rest }) => {
  const classes = useStyles()
  const [emailAddresses, setEmailAddresses] = useState(org.emailOrg)
  const [phones, setPhones] = useState(org.phoneOrg)
  const [addresses, setAddresses] = useState(org.addressOrg)
  const [socials, setSocials] = useState(org.orgSocialMedia)
  const [updateEmail, setUpdateEmail] = useState(false)
  const [updatePhone, setUpdatePhone] = useState(false)
  const [updateSocial, setUpdateSocial] = useState(false)
  const [updateAddress, setUpdateAddress] = useState(false)
  const [addressToUpdate, setAddressToUpdate] = useState("")

  const iconSwitch = socialTypeId => {
    switch (socialTypeId) {
      case "Twitter":
        return <TwitterIcon style={{ fontSize: 20, marginBottom: -4 }} />
      case "Facebook":
        return <FacebookIcon style={{ fontSize: 20, marginBottom: -4 }} />
      case "Instagram":
        return <InstagramIcon style={{ fontSize: 20, marginBottom: -4 }} />
      default:
        return <ShareIcon style={{ fontSize: 20, marginBottom: -4 }} />
    }
  }
  return (
    <Card
      className={clsx(classes.root, classes.roundedCard, className)}
      {...rest}
    >
      <CardHeader title="Profile" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>
              {/* <Button size="small" onClick={() => setUpdateEmail(true)}>
                Email
              </Button> */}
              <IconButton
                aria-label="email"
                color="secondary"
                className={classes.margin}
                onClick={() => setUpdateEmail(true)}
              >
                <EmailIcon fontSize="large" />
              </IconButton>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {emailAddresses && emailAddresses.length > 0
                  ? emailAddresses.map(email => (
                      <Fragment key={email.id}>
                        {email.emailAddress}
                        <br />{" "}
                      </Fragment>
                    ))
                  : "No email addresses on file"}
              </Typography>
              {/* <Label color={person.verified ? 'success' : 'error'}>
                {person.verified
                  ? 'Email verified'
                  : 'Email not verified'}
              </Label> */}
            </TableCell>
            <Dialog
              fullWidth
              onClose={() => setUpdateEmail(false)}
              PaperProps={{ className: classes.roundedCard }}
              aria-labelledby="update-email"
              open={updateEmail}
            >
              <Box padding={5}>
                <UpdateOrgEmail
                  org={org}
                  emailAddresses={emailAddresses}
                  setEmailAddresses={setEmailAddresses}
                />
              </Box>
            </Dialog>
          </TableRow>

          <TableRow>
            <TableCell className={classes.fontWeightMedium}>
              <IconButton
                color="secondary"
                aria-label="phone"
                className={classes.margin}
                onClick={() => setUpdatePhone(true)}
              >
                <PhoneAndroidIcon fontSize="large" />
              </IconButton>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {phones && phones.length > 0
                  ? phones.map(phone => (
                      <Fragment key={phone.id}>
                        {phone.phoneNumber} ({phone.phoneType.phoneType})
                      </Fragment>
                    ))
                  : "No phone numbers on file"}
              </Typography>
            </TableCell>
            <Dialog
              fullWidth
              onClose={() => setUpdatePhone(false)}
              aria-labelledby="update-email"
              open={updatePhone}
              PaperProps={{ className: classes.roundedCard }}
            >
              <Box padding={5}>
                <UpdateOrgPhone
                  org={org}
                  phones={phones}
                  setPhones={setPhones}
                />
              </Box>
            </Dialog>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>
              <IconButton
                aria-label="social-media"
                color="secondary"
                className={classes.margin}
                onClick={() => setUpdateSocial(true)}
              >
                <ShareIcon fontSize="large" />
              </IconButton>
            </TableCell>
            <TableCell>
              {socials && socials.length > 0
                ? socials.map(s => {
                    const socialTypeId = s.socialMediaType.name
                    return (
                      <Box key={s.id} padding={1}>
                        {iconSwitch(socialTypeId)}
                        {s.url ? (
                          <Box ml={1} display="inline">
                            <a href={s.url} className={classes.link}>
                              <Typography variant="body2" display="inline">
                                {s.username}
                              </Typography>
                            </a>
                          </Box>
                        ) : (
                          s.username
                        )}
                      </Box>
                    )
                  })
                : "No accounts on file"}
            </TableCell>
            <Dialog
              fullWidth
              onClose={() => setUpdateSocial(false)}
              aria-labelledby="update-social-media"
              open={updateSocial}
              PaperProps={{ className: classes.roundedCard }}
            >
              <Box padding={5}>
                <UpdateOrgSocialMedia
                  org={org}
                  socials={socials}
                  setSocials={setSocials}
                />
              </Box>
            </Dialog>
          </TableRow>
          {/* Todo: Add international address mapping logic */}
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>
              {/* <Button size="small" onClick={() => setUpdateAddress(true)}>
                Address
              </Button> */}
              <IconButton
                aria-label="email"
                className={classes.margin}
                onClick={() => setUpdateAddress(true)}
                color="secondary"
              >
                <ContactMailIcon fontSize="large" />
              </IconButton>
            </TableCell>
            <TableCell>
              <>
                {addresses && addresses.length > 0
                  ? addresses.map(address => {
                      const a = address.address
                      return (
                        <>
                          <Box key={a.uid} pb={2}>
                            {a.category && `${a.category.name}:`}
                            {a.category && <br />}
                            {a.attention && `attn: ${a.attention}`}
                            {a.attention && <br />}
                            {a.addressLine1 && a.addressLine1}
                            {a.addressLine1 && <br />}
                            {a.addressLine2 && a.addressLine2}
                            {a.addressLine2 && <br />}
                            {a.addressLine3 && a.addressLine3}
                            {a.addressLine3 && <br />}
                            {a.city}, {a.zone} {a.postalCode} <br />
                            {a.region.name}
                          </Box>
                        </>
                      )
                    })
                  : "No addresses on file"}
              </>
            </TableCell>
            <Dialog
              fullWidth
              onClose={() => setUpdateAddress(false)}
              aria-labelledby="update-address"
              open={updateAddress}
              PaperProps={{ className: classes.roundedCard }}
            >
              <Box padding={5}>
                <UpdateOrgAddress
                  org={org}
                  addresses={addresses}
                  setAddresses={setAddresses}
                  setUpdateAddress={setUpdateAddress}
                />
              </Box>
            </Dialog>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  )
}

OrgInfo.propTypes = {
  className: PropTypes.string,
  org: PropTypes.object.isRequired,
}

export default OrgInfo
