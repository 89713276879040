import React, { useState } from "react"
import clsx from "clsx"
import { useQuery } from "@apollo/client"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import gql from "graphql-tag"
import Moment from "react-moment"
import PreregInvite from "./PreregInvite"
import { WhatIsPrereg } from "./CreatePreregAccount"

import {
  Card,
  CardHeader,
  Dialog,
  Divider,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core"
import HelpIcon from "@material-ui/icons/Help"

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

const PreRegAccount = ({ person, businessUnits, className, ...rest }) => {
  const classes = useStyles()
  const uid = person.uid
  const [dialogOpen, setDialogOpen] = useState(false)
  const { loading, error, data } = useQuery(PREREG_USER_QUERY, {
    variables: { uid },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      console.log(data)
    },
  })

  if (loading) return <Loading />
  if (error) return "Error"
  if (data === undefined) {
    return "Data Undefined"
  }
  if (data === null) {
    return "Data null"
  }
  const preReg = data.preRegister
  const preregId = data.preRegister.preregId
  return (
    <>
      {/* Open dialog to explain the registration flow */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <WhatIsPrereg />
      </Dialog>
      <Card className={classes.roundedCard}>
        <CardHeader
          title="User Registration Status"
          action={
            <IconButton
              aria-label="What is this?"
              onClick={() => setDialogOpen(true)}
            >
              <HelpIcon color="primary" />
            </IconButton>
          }
        />
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Pre-Registered
              </TableCell>
              <TableCell>
                {preReg && preReg.preregEmail && preReg.preregEmail}
              </TableCell>
            </TableRow>
            {preReg.preregconversion && (
              <TableRow>
                <TableCell className={classes.fontWeightMedium}>
                  Registration Link{" "}
                  {preReg.preregconversion.isActive
                    ? " (Active)"
                    : " (Inactive)"}
                </TableCell>
                <TableCell>
                  Sent:{" "}
                  <Moment
                    format="YYYY/MM/DD"
                    date={preReg.preregconversion.dateAdded}
                  />
                  <br />
                  Expires:{" "}
                  <Moment
                    format="YYYY/MM/DD"
                    date={preReg.preregconversion.dateExpiration}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Divider />
      </Card>
      {!preReg.registered && (
        <PreregInvite preReg={preReg} businessUnits={businessUnits} />
      )}
    </>
  )
}

const PREREG_USER_QUERY = gql`
  query ($uid: String!) {
    preRegister(uid: $uid) {
      preregId
      person {
        uid
        firstName
        lastName
      }
      preregEmail
      registered
      preregconversion {
        id
        token
        dateAdded
        dateExpiration
        isActive
      }
    }
  }
`
export default PreRegAccount
