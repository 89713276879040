import React, { useState, useEffect, useContext, useReducer } from "react"
import UserContext from "./UserContext"
import UserReducer from "./UserReducer"

const UserProvider = props => {
  const initialState = useContext(UserContext)
  const [localState, setLocalState] = useState("")
  const [state, dispatch] = useReducer(UserReducer, localState || initialState)
  const isBrowser = () => typeof window !== "undefined"
  useEffect(() => {
    isBrowser() &&
      setLocalState(JSON.parse(window.localStorage.getItem("location")))
  }, [])

  useEffect(() => {
    isBrowser && window.localStorage.setItem("location", JSON.stringify(state))
  }, [state])

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {props.children}
    </UserContext.Provider>
  )
}

export default UserProvider
