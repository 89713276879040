import React, { useState } from "react"
import PersonCreateForm from "../../../People/PeopleCreateView/PersonCreateForm"
import AddPersonForm from "../../../People/PeopleCreateView//PersonCreateForms/AddPersonForm"
import AddEmailForm from "../../../People/PeopleCreateView//PersonCreateForms/AddEmailForm"
import AddPhoneForm from "../../../People/PeopleCreateView//PersonCreateForms/AddPhoneForm"
import AddAddressForm from "../../../People/PeopleCreateView//PersonCreateForms/AddAddressForm"
import AddSocialMediaForm from "../../../People/PeopleCreateView//PersonCreateForms/AddSocialMediaForm"

import clsx from "clsx"
import { Box, makeStyles, Typography } from "@material-ui/core"
const useStyles = makeStyles(() => ({
  root: {},
  instructions: {
    minHeight: 120,
    marginTop: 10,
    marginBottom: 10,
  },
}))

const AddOrderPerson = ({
  className,
  person,
  setPerson,
  setStatus,
  ...rest
}) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const addPerson = activeStep => {
    switch (activeStep) {
      case 0:
        return (
          <AddPersonForm setActiveStep={setActiveStep} setPerson={setPerson} />
        )

      case 1:
        return <AddEmailForm setActiveStep={setActiveStep} person={person} />
      case 2:
        return <AddPhoneForm setActiveStep={setActiveStep} person={person} />
      case 3:
        return <AddAddressForm setActiveStep={setActiveStep} person={person} />
      case 4:
        return (
          <AddSocialMediaForm setActiveStep={setActiveStep} person={person} />
        )
      case 5:
        setStatus("confirm")

      default:
        return "error, try again"
    }
  }
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box className={classes.instructions} p={2}>
        {addPerson(activeStep)}
      </Box>
    </div>
  )
}

export default AddOrderPerson
