import React, { useState } from "react"
import clsx from "clsx"
import { useMutation } from "@apollo/client"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import gql from "graphql-tag"

import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from "@material-ui/core"
import { navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {},
  roundedCard: {
    marginBottom: theme.spacing(3),
    borderRadius: "15px",
  },
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}))

const NewPersonProposal = ({ person, businessUnits, className, ...rest }) => {
  const classes = useStyles()
  const uid = person.uid
  const sbus = businessUnits
  const [sbu, setSbu] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const now = new Date().toISOString()
  const monthOut = new Date()
  monthOut.setDate(monthOut.getDate() + 30)
  const oneMonth = monthOut.toISOString()
  const [likelihood, setLikelihood] = useState(10)
  //   const [isPrereg, setIsPrereg] = useState(false)
  //   const [preregId, setPreregId] = useState("")

  const [notes, setNotes] = useState("")

  const user = person.user && person.user
  const userId = user ? user.id : null

  const isPrereg =
    userId === null &&
    person.preregPerson &&
    person.preregPerson.preregId.length > 5
      ? true
      : false
  const preregId =
    isPrereg && person.preregPerson.preregId && person.preregPerson.preregId

  const [createProposal, { data, loading, error }] = useMutation(
    CREATE_PROPOSAL_MUTATION,
    {
      variables: {
        sbuId: sbu,
        likelihood: likelihood,
        isActive: true,
        isPrereg: isPrereg,
        expiresAt: oneMonth,
        notes: notes,
        preregId: preregId,
        userId: userId,
        fromInquiry: false,
      },
      onCompleted: data => {
        console.log(data)
        const quoteId = data.createQuote.quote.id
        setIsSubmitting(false)
        navigate(`/manager/proposals/${quoteId}`, { state: { id: quoteId } })
      },
    }
  )

  const handleSubmit = () => {
    console.log(sbu)
    console.log(likelihood)
    console.log(isPrereg)
    console.log(oneMonth)
    console.log(preregId)
    console.log(userId)
    createProposal()
  }

  const handleBusinessUnitChange = event => {
    event.persist()

    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
      console.log("value is")
      console.log(value)
    }
    setSbu(value)
  }

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <>
      <Card
        className={clsx(classes.root, classes.roundedCard, className)}
        {...rest}
      >
        <CardHeader title="Start a new proposal or quote" />
        <Divider />
        <CardContent>
          <form
            onSubmit={event => {
              event.preventDefault()
              setIsSubmitting(true)
              handleSubmit(event)
            }}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <TextField
              label="Business Unit"
              InputLabelProps={{
                shrink: true,
              }}
              name="businessUnit"
              onChange={handleBusinessUnitChange}
              select
              size="small"
              fullWidth
              variant="outlined"
              SelectProps={{ native: true }}
              value={sbu}
            >
              <option></option>
              {sbus.map(s => (
                <option key={s.id} value={s.id}>
                  {s.name}
                </option>
              ))}
            </TextField>
            <Box mt={1}>
              <Button
                disabled={isSubmitting}
                fullWidth
                size="small"
                type="submit"
                color="secondary"
                variant="contained"
              >
                Start a New Proposal
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </>
  )
}

const CREATE_PROPOSAL_MUTATION = gql`
  mutation (
    $sbuId: Int
    $likelihood: Int
    $isActive: Boolean
    $isPrereg: Boolean
    $expiresAt: DateTime
    $notes: String
    $preregId: String
    $userId: Int
    $fromInquiry: Boolean
  ) {
    createQuote(
      sbuId: $sbuId
      likelihood: $likelihood
      isActive: $isActive
      isPrereg: $isPrereg
      expiresAt: $expiresAt
      notes: $notes
      preregId: $preregId
      userId: $userId
      fromInquiry: $fromInquiry
    ) {
      quote {
        id
        createdBy {
          id
        }
        createdAt
        businessUnit {
          id
        }
        isPrereg
        userQuoteUser {
          user {
            id
          }
        }
        preRegQuoteUser {
          preregUser {
            preregId
          }
        }
      }
    }
  }
`

export default NewPersonProposal
