import React, { useState } from "react"
import { Box, Button, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { Link } from "gatsby"

const InquiryItemNextStep = ({
  classes,
  item,
  activeStep,
  inquiry,
  handleClose,
}) => {
  const inquiryId = inquiry.id
  const closeInquiry = event => {
    handleClose()
  }
  return (
    <div className={classes.box}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mt={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={closeInquiry}
            >
              Return to Inquiry {inquiryId}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              component={Link}
              to="/manager/proposals/new"
            >
              Go to Proposals
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} mb={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              component={Link}
              to="/manager/orders/new"
            >
              Start a new order
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} mb={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              component={Link}
              to="/manager/orders/new"
            >
              Add to an order
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  root: {
    justifyContent: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    paddingBottom: 40,
    paddingTop: 80,
  },
  box: {
    minWidth: "250px",
  },
  show: {},
  hide: {
    display: "none",
  },
  formButton: {
    marginTop: theme.spacing(2),
  },
})
export default withStyles(styles)(InquiryItemNextStep)
