export default function reducer(state, action) {
  switch (action.type) {
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      }
    case "SET_PAGE":
      return {
        ...state,
        page: action.payload,
      }
    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageSize: action.payload,
      }
    case "SET_ORDER_ID":
      return {
        ...state,
        orderId: action.payload,
      }
    case "SET_ADD_ORDER_ITEM":
      return {
        ...state,
        addOrderItem: action.payload,
      }
    case "SET_ORDER_ITEMS":
      return {
        ...state,
        orderItems: action.payload,
      }
    case "SET_ORDER_DETAILS":
      return {
        ...state,
        orderDetails: action.payload,
      }
    case "SET_ORDER_COMPLETED":
      return {
        ...state,
        completed: action.payload,
      }
    case "SET_ORDER_TAX_AMT":
      return {
        ...state,
        taxAmt: action.payload,
      }
    case "SET_ORDER_TOTAL":
      return {
        ...state,
        total: action.payload,
      }
    case "SET_ALL_ITEMS":
      return {
        ...state,
        allItems: action.payload,
      }
    case "TOGGLE_IS_SEARCHING":
      return {
        ...state,
        isSearching: !state.isSearching,
      }
    case "TOGGLE_IS_ADDING_ORDER_ITEM":
      return {
        ...state,
        isAddingOrderItem: !state.isAddingOrderItem,
      }
    case "TOGGLE_CREATE_NEW_ITEM":
      return {
        ...state,
        createNewItem: !state.createNewItem,
      }

    default:
      return state
  }
}
