import React, { useState, useContext } from "react"
import clsx from "clsx"
import {
  Button,
  Container,
  Paper,
  Grid,
  Step,
  Stepper,
  StepLabel,
  StepContent,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { useQuery, useMutation } from "@apollo/client"

import Loading from "../../../Utils/Loading"
import FormError from "../../../Utils/FormError"
import gql from "graphql-tag"
import Header from "./Header"
import ProposalPerson from "./ProposalPerson"
import Page from "../../../Global/Page"
import useDidMountEffect from "../../../Global/hooks/useDidMountEffect"
import { navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "91vh",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    backgroundColor: theme.palette.background.light,
    paddingT: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(3),
    borderRadius: "15px",
    padding: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
}))
function getSteps() {
  return ["Select a customer", "Pick a business unit", "Confirm and continue"]
}
const ProposalCreateView = ({ className, ...rest }) => {
  const classes = useStyles()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [businessUnitId, setBusinessUnitId] = useState("")
  const [personUid, setPersonUid] = useState("")
  const [likelihood, setLikelihood] = useState(10)
  const [isPrereg, setIsPrereg] = useState(false)
  const [preregId, setPreregId] = useState("")
  const [userId, setUserId] = useState(null)
  const [notes, setNotes] = useState("")
  const [person, setPerson] = useState("")
  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()
  const now = new Date().toISOString()
  const monthOut = new Date()
  monthOut.setDate(monthOut.getDate() + 30)
  const oneMonth = monthOut.toISOString()

  const { loading, data, error } = useQuery(PROPOSAL_FORM_INFO_QUERY)
  const [createProposal] = useMutation(CREATE_NEW_PROPOSAL_MUTATION, {
    variables: {
      sbuId: businessUnitId,
      likelihood: likelihood,
      isActive: true,
      isPrereg: isPrereg,
      expiresAt: oneMonth,
      notes: notes,
      preregId: preregId,
      userId: userId,
      fromInquiry: false,
    },
    onCompleted: data => {
      console.log(data)
      const quoteId = data.createQuote.quote.id
      setIsSubmitting(false)
      navigate(`/manager/proposals/${quoteId}`)
    },
  })
  useDidMountEffect(() => {
    if (person.preregPerson) {
      setPreregId(person.preregPerson.preregId)
    }
    if (person.user) {
      setUserId(person.user.id)
    }
  }, [person])
  const handleForm = event => {
    event.preventDefault()
    // setIsSubmitting(true)
    createProposal()
  }

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
    } else setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <ProposalPerson
            setPersonUid={setPersonUid}
            setPreregId={setPreregId}
            setUserId={setUserId}
            person={person}
            setPerson={setPerson}
            setIsPrereg={setIsPrereg}
          />
        )
      case 1:
        return (
          <>
            <TextField
              label="Select a Business Unit"
              name="businessUnit"
              onChange={event => setBusinessUnitId(event.target.value)}
              select
              margin="dense"
              size="small"
              fullWidth
              variant="outlined"
              SelectProps={{ native: true }}
              value={businessUnitId}
            >
              <option></option>
              {sbus.map(b => (
                <option key={b.id} value={b.id}>
                  {b.name}
                </option>
              ))}
            </TextField>
          </>
        )
      case 2:
        return "Add and go to your new proposal"
      default:
        return (
          <ProposalPerson
            setPersonUid={setPersonUid}
            setPreregId={setPreregId}
            setUserId={setUserId}
            person={person}
            setPerson={setPerson}
            setIsPrereg={setIsPrereg}
          />
        )
    }
  }

  if (loading) return <Loading />
  if (error) return <FormError />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const sbus = data.sbus

  return (
    <>
      <Page className={classes.root} title="Add a New Proposal">
        <Container maxWidth={false}>
          <Header />
          <Paper className={classes.paper}>
            <form onSubmit={event => handleForm(event, createProposal)}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                          <Typography>{getStepContent(index)}</Typography>
                          <div className={classes.actionsContainer}>
                            <Button
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              className={classes.button}
                            >
                              Back
                            </Button>
                            {activeStep === steps.length - 1 ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                className={classes.button}
                              >
                                Complete
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                              >
                                Next
                              </Button>
                            )}
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      </Page>
    </>
  )
}
const PROPOSAL_FORM_INFO_QUERY = gql`
  query {
    sbus {
      id
      name
    }
    taxes {
      id
      code
      name
    }
  }
`

const CREATE_NEW_PROPOSAL_MUTATION = gql`
  mutation (
    $sbuId: Int
    $likelihood: Int
    $isActive: Boolean
    $isPrereg: Boolean
    $expiresAt: DateTime
    $notes: String
    $preregId: String
    $userId: Int
    $fromInquiry: Boolean
  ) {
    createQuote(
      sbuId: $sbuId
      likelihood: $likelihood
      isActive: $isActive
      isPrereg: $isPrereg
      expiresAt: $expiresAt
      notes: $notes
      preregId: $preregId
      userId: $userId
      fromInquiry: $fromInquiry
    ) {
      quote {
        id
        createdBy {
          id
        }
        createdAt
        businessUnit {
          id
        }
        isPrereg
        userQuoteUser {
          user {
            id
          }
        }
        preRegQuoteUser {
          preregUser {
            preregId
          }
        }
      }
    }
  }
`

export default ProposalCreateView
