import React, { useContext, useState } from "react"
import { Box, Container, makeStyles } from "@material-ui/core"
import Page from "../../../Global/Page"
import useIsMountedRef from "../../../../hooks/useIsMountedRef"
import Header from "./Header"
import Results from "./Results"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import SortContext from "../../../../context/SortContext"
import PerfectScrollbar from "react-perfect-scrollbar"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "91vh",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const PeopleListView = () => {
  const classes = useStyles()
  const isMountedRef = useIsMountedRef()
  const { state, dispatch } = useContext(SortContext)
  const [preregFilter, setPreregFilter] = useState(false)
  const [userFilter, setUserFilter] = useState(false)
  const search = state.search
  const page = state.page + 1
  const pageSize = state.pageSize

  const { loading, error, data } = useQuery(LIST_ALL_PEOPLE_QUERY, {
    variables: {
      search: search,
      page: page,
      pageSize: pageSize,
      preregFilter: preregFilter,
      userFilter: userFilter,
    },
    fetchPolicy: "cache-and-network",
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const ppl = data.people.objects
  const res = data.people

  return (
    <Page className={classes.root} title="People List">
      <PerfectScrollbar>
        <Container maxWidth={false}>
          <Header />
          {ppl && (
            <Box mt={3}>
              <Results
                res={res}
                preregFilter={preregFilter}
                setPreregFilter={setPreregFilter}
                userFilter={userFilter}
                setUserFilter={setUserFilter}
              />
            </Box>
          )}
        </Container>
      </PerfectScrollbar>
    </Page>
  )
}
export const LIST_ALL_PEOPLE_QUERY = gql`
  query (
    $page: Int!
    $pageSize: Int!
    $search: String
    $userFilter: Boolean
    $preregFilter: Boolean
  ) {
    people(
      page: $page
      pageSize: $pageSize
      search: $search
      userFilter: $userFilter
      preregFilter: $preregFilter
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        uid
        firstName
        lastName
        middleName
        suffix
        nickname
        title
        about
        userCanUpdate
        isSupplier
        user {
          id
          isVerified
          staff
          manager
        }
        personSocialMedia {
          id
          socialMediaType {
            id
            name
          }
          username
        }
        preregPerson {
          preregId
          preregEmail
        }
        useGravatar
        dateAdded
        dateModified
        phonePerson {
          id
          phoneNumber
        }

        emailPerson {
          id
          emailAddress
        }

        notePerson {
          id
          note
        }
      }
    }
  }
`
export default PeopleListView
