import React, { useState, useContext, Fragment } from "react"
import { Link, navigate } from "gatsby"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Popover,
  SvgIcon,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core"

import FaceIcon from "@material-ui/icons/Face"
import AddPerson from "../AddPerson"
import SearchPerson from "../SearchPerson"
import SortContext from "../../../../context/SortContext"
import useDidMountEffect from "../../../Global/hooks/useDidMountEffect"
import Draggable from "react-draggable"
import {
  PlusCircle as PlusCircleIcon,
  Search as SearchIcon,
  HelpCircle as HelpIcon,
} from "react-feather"

import ThumbUpIcon from "@material-ui/icons/ThumbUp"
import ThumbDownIcon from "@material-ui/icons/ThumbDown"
import { useLocation } from "@reach/router"

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
  matchButtons: {
    marginTop: theme.spacing(1),
  },
  typography: {
    padding: theme.spacing(2),
  },
  hideIfQuoted: {
    display: "none",
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

const statusOptions = ["Canceled", "Completed", "Rejected"]

function InquiryInfo({
  inquiry,
  className,
  person,
  setPerson,
  isPerson,
  setIsPerson,
  ...rest
}) {
  const { state, dispatch } = useContext(SortContext)
  const classes = useStyles()
  const location = useLocation()
  const userPermission = location.pathname.split("/")[1]
  const [fullWidth, setFullWidth] = useState(true)
  const [maxWidth, setMaxWidth] = useState("sm")
  const [status, setStatus] = useState(statusOptions[0])
  const [openAddPerson, setOpenAddPerson] = useState(false)
  const [openSearchPerson, setOpenSearchPerson] = useState(false)
  const [personUid, setPersonUid] = useState(
    inquiry.inquiryPerson.person ? inquiry.inquiryPerson.person.uid : ""
  )
  const [inquiryPerson, setInquiryPerson] = useState(inquiry.inquiryPerson)
  const addresses = inquiry.inquiryAddress
  const emailAddresses = inquiry.emailInquiry
  const phones = inquiry.inquiryPhone
  const firstName = inquiry.inquiryPerson && inquiry.inquiryPerson.firstName
  const lastName = inquiry.inquiryPerson && inquiry.inquiryPerson.lastName
  const [spam, setSpam] = useState(
    inquiry.spam && inquiry.spam ? inquiry.spam : false
  )
  const [deleteMe, setDeleteMe] = useState(
    inquiry.deleteMe && inquiry.deleteMe ? inquiry.deleteMe : false
  )
  const [newInquiry, setNewInquiry] = useState(
    inquiry.new && inquiry.new ? inquiry.new : false
  )
  const [samplesRequested, setSamplesRequested] = useState(
    inquiry.samplesRequested && inquiry.samplesRequested
      ? inquiry.samplesRequested
      : false
  )
  const [quoteCreated, setQuoteCreated] = useState(
    inquiry.quoteCreated && inquiry.quoteCreated ? inquiry.quoteCreated : false
  )
  const lastInitial = lastName ? lastName[0] : ""
  const [anchorEl, setAnchorEl] = useState(null)

  const [updateInquiryBooleans, { data, loading }] = useMutation(
    UPDATE_INQUIRY_BOOLEANS,
    {
      variables: {
        inquiryUuid: inquiry.inquiryUuid,
        deleteMe: deleteMe,
        spam: spam,
        samplesRequested: samplesRequested,
        quoteCreated: quoteCreated,
        new: newInquiry,
      },
      onCompleted: data => {
        const inquiryUpdate = data.updateInquiryBooleans.inquiry
        setDeleteMe(inquiryUpdate.deleteMe)
        setSpam(inquiryUpdate.spam)
        setSamplesRequested(inquiryUpdate.samplesRequested)
        setQuoteCreated(inquiryUpdate.quoteCreated)
        setNewInquiry(inquiryUpdate.new)
      },
    }
  )

  useDidMountEffect(() => {
    updateInquiryBooleans()
  }, [newInquiry, quoteCreated, samplesRequested, deleteMe, spam])

  useDidMountEffect(() => {
    if (isPerson === true) {
      console.log("is person")
      inquiryPerson.person &&
        inquiryPerson.person.uid &&
        setPersonUid(inquiryPerson.person.uid)
      // setPerson(inquiryPerson.person)
    }
  }, [openAddPerson, openSearchPerson])

  const handleChange = event => {
    event.persist()
    setStatus(event.target.value)
  }
  const handleClickOpenAddPerson = () => {
    setOpenAddPerson(true)
  }
  const handleCloseAddPerson = () => {
    setOpenAddPerson(false)
  }

  const handleClickOpenSearchPerson = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
    dispatch({ type: "SET_PAGE", payload: 0 })
    setOpenSearchPerson(true)
  }
  const handleCloseSearchPerson = () => {
    setOpenSearchPerson(false)
  }
  const handlePopover = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopover = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <>
      <Card
        className={clsx(classes.root, classes.roundedCard, className)}
        {...rest}
      >
        <CardHeader
          title={`Inquiry #${inquiry.id.split("-").shift()}`}
          subheader={inquiry.contactFormMessage ? "Contact Form Message" : ""}
        />
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Date (Time)</TableCell>
              <TableCell>
                <div>
                  {moment(inquiry.createdAt).format("MMMM Do, YYYY (HH:MM)")}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Business Unit</TableCell>
              <TableCell>
                <div>
                  <a href={inquiry.businessUnit.homepage}>
                    {inquiry.businessUnit.name}
                  </a>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <div>
                  {isPerson && !openAddPerson ? (
                    <>
                      <IconButton
                        onClick={event => {
                          navigate(
                            `/${userPermission}/people/${personUid}`,
                            {
                              state: { uid: `${personUid}` },
                            },
                            { replace: true }
                          )
                        }}
                      >
                        <FaceIcon style={{ fontSize: 65 }} color="primary" />
                      </IconButton>
                    </>
                  ) : (
                    <div className={classes.matchButtons}>
                      <div>
                        <Button
                          color="primary"
                          variant="outlined"
                          size="small"
                          className={classes.addButton}
                          onClick={handleClickOpenSearchPerson}
                        >
                          <SvgIcon
                            fontSize="small"
                            className={classes.actionIcon}
                          >
                            <SearchIcon />
                          </SvgIcon>
                          People
                        </Button>
                        <Dialog
                          fullWidth={fullWidth}
                          maxWidth={maxWidth}
                          open={openSearchPerson}
                          onClose={handleCloseSearchPerson}
                          aria-labelledby="#draggable-dialog-title"
                          PaperComponent={PaperComponent}
                        >
                          <div>
                            <DialogTitle
                              style={{ cursor: "move" }}
                              id="draggable-dialog-title"
                            >
                              Search for a Person
                            </DialogTitle>
                            {/* <DialogActions>
                              <IconButton
                                onClick={handleCloseSearchPerson}
                                // type="submit"
                                color="default"
                              >
                                <SvgIcon>
                                  <CloseIcon />
                                </SvgIcon>
                              </IconButton>
                            </DialogActions> */}

                            <SearchPerson
                              inquiry={inquiry}
                              setPersonUid={setPersonUid}
                              setOpenSearchPerson={setOpenSearchPerson}
                            />
                          </div>
                        </Dialog>
                      </div>
                      <div>
                        <Button
                          onClick={handleClickOpenAddPerson}
                          color="primary"
                          variant="outlined"
                          size="small"
                          className={classes.addButton}
                        >
                          <SvgIcon
                            fontSize="small"
                            className={classes.actionIcon}
                          >
                            <PlusCircleIcon />
                          </SvgIcon>
                          Person
                        </Button>
                        <Dialog
                          fullWidth={fullWidth}
                          maxWidth={maxWidth}
                          open={openAddPerson}
                          onClose={handleCloseAddPerson}
                          aria-labelledby="form-dialog-title"
                          PaperComponent={PaperComponent}
                        >
                          <div>
                            <DialogTitle
                              style={{ cursor: "move" }}
                              id="draggable-dialog-title"
                            >
                              Add a new person
                            </DialogTitle>
                            {/* <DialogActions>
                              <IconButton
                                onClick={handleCloseAddPerson}
                                type="submit"
                                color="default"
                              >
                                <SvgIcon>
                                  <CloseIcon />
                                </SvgIcon>
                              </IconButton>
                            </DialogActions> */}

                            <AddPerson
                              inquiry={inquiry}
                              // setInquiryPerson={setInquiryPerson}
                              setIsPerson={setIsPerson}
                              setOpenAddPerson={setOpenAddPerson}
                              person={person}
                              setPerson={setPerson}
                              setPersonUid={setPersonUid}
                            />
                          </div>
                        </Dialog>
                      </div>
                    </div>
                  )}
                </div>
              </TableCell>
              <TableCell>
                <div>
                  {firstName} {lastName}
                  {emailAddresses.map(e => (
                    <div key={e.id}>{e.emailAccount}</div>
                  ))}
                  {phones.map(p => (
                    <div key={p.id}>
                      {p.phoneType.phoneType}: {p.phoneNumber}
                    </div>
                  ))}
                </div>
                <div>
                  <IconButton
                    onClick={handlePopover}
                    type="submit"
                    color="secondary"
                    size="small"
                  >
                    <SvgIcon>
                      <HelpIcon style={{ fontSize: 10 }} />
                    </SvgIcon>
                  </IconButton>
                  <Popover
                    id="simple popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Typography className={classes.typography}>
                      First, search to see if we already know the inquirer. If
                      not, add a new "person" account to the database.
                    </Typography>
                  </Popover>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>
                {addresses.map(address => {
                  // const summary = JSON.parse(address.summary)

                  return (
                    <Fragment key={address.uid}>
                      {address.category && `Category: ${address.category.name}`}
                      {address.category && <br />}
                      {address.attention && address.attention}
                      {address.attention && <br />}
                      {address.addressLine1 && address.addressLine1}
                      {address.addressLine1 && <br />}
                      {address.addressLine2 && address.addressLine2}
                      {address.addressLine2 && <br />}
                      {address.addressLine3 && address.addressLine3}
                      {address.addressLine3 && <br />}
                      {address.city && address.city},{" "}
                      {address.zone && address.zone}
                      <br />
                      {address.postalCode && address.postalCode} <br />
                      {address.region && address.region.name}
                    </Fragment>
                  )
                })}
              </TableCell>
              {/* <TableCell>{inquiry.ref}</TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell>Source</TableCell>
              <TableCell>{inquiry.source}</TableCell>
            </TableRow>

            <TableRow className={quoteCreated && classes.hideIfQuoted}>
              <TableCell>Spam</TableCell>
              <TableCell>
                <Switch
                  checked={spam}
                  onChange={event => setSpam(event.target.checked)}
                  color="secondary"
                  name="spam"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Samples Requested</TableCell>
              <TableCell>
                {quoteCreated && samplesRequested ? (
                  <ThumbUpIcon />
                ) : quoteCreated && !samplesRequested ? (
                  <ThumbDownIcon />
                ) : (
                  <Switch
                    checked={samplesRequested}
                    onChange={event =>
                      setSamplesRequested(event.target.checked)
                    }
                    color="secondary"
                    name="samplesRequested"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                )}
              </TableCell>
            </TableRow>

            <TableRow className={quoteCreated && classes.hideIfQuoted}>
              <TableCell>New</TableCell>
              <TableCell>
                <Switch
                  checked={newInquiry}
                  onChange={event => setNewInquiry(event.target.checked)}
                  color="secondary"
                  name="newInquiry"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Quote Created</TableCell>
              <TableCell>
                <Switch
                  checked={quoteCreated}
                  onChange={event => setQuoteCreated(event.target.checked)}
                  color="secondary"
                  name="quoteCreated"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
            </TableRow>
            {quoteCreated && (
              <TableRow>
                <TableCell>Quote Info</TableCell>
                <TableCell>
                  {inquiry.quoteInquiry &&
                    inquiry.quoteInquiry.map(iq => (
                      <Link
                        to={`/${userPermission}/proposals/${iq.id}`}
                        state={{ id: iq.id }}
                        className={classes.link}
                      >
                        Quoted{" "}
                        {moment(iq.createdAt).format("MMM Do, YYYY (HH:MM)")}
                      </Link>
                    ))}
                </TableCell>
              </TableRow>
            )}
            <TableRow className={quoteCreated && classes.hideIfQuoted}>
              <TableCell>Delete Me</TableCell>
              <TableCell>
                <Switch
                  checked={deleteMe}
                  onChange={event => setDeleteMe(event.target.checked)}
                  color="secondary"
                  name="deleteMe"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  name="option"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={status}
                  variant="outlined"
                >
                  {statusOptions.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </Card>
    </>
  )
}

InquiryInfo.propTypes = {
  className: PropTypes.string,
  inquiry: PropTypes.object.isRequired,
}
const UPDATE_INQUIRY_BOOLEANS = gql`
  mutation (
    $inquiryUuid: String!
    $spam: Boolean
    $deleteMe: Boolean
    $quoteCreated: Boolean
    $samplesRequested: Boolean
    $new: Boolean
  ) {
    updateInquiryBooleans(
      inquiryUuid: $inquiryUuid
      spam: $spam
      deleteMe: $deleteMe
      quoteCreated: $quoteCreated
      samplesRequested: $samplesRequested
      new: $new
    ) {
      inquiry {
        id
        inquiryUuid
        deleteMe
        spam
        samplesRequested
        quoteCreated
        new
      }
    }
  }
`
export default InquiryInfo
