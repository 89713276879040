import React, { useState } from "react"
import clsx from "clsx"

import {
  Box,
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  FormControl,
  MenuItem,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  makeStyles,
  Select,
  InputLabel,
} from "@material-ui/core"

import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import PROJECT_TODOS_QUERY from "./index"
import moment from "moment"
const useStyles = makeStyles(theme => ({
  root: { marginBottom: theme.spacing(2) },
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: 0,
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: "space-between",
  },
  addTab: {
    marginLeft: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  field: {
    marginTop: theme.spacing(2),
  },
}))

const UpdateTask = ({
  todo,
  edit,
  setEdit,
  incompleteTasks,
  setIncompleteTasks,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const taskId = todo.id
  const [title, setTitle] = useState(todo.task.title)
  const [assignedToId, setAssignedToId] = useState(todo.task.assignedToId)
  const [dueDate, setDueDate] = useState(moment(todo.task.dueDate))
  const [completed, setCompleted] = useState(todo.task.completed)
  const [completedDate, setCompletedDate] = useState(todo.task.completedDate)
  const [note, setNote] = useState(todo.task.note)
  const [priority, setPriority] = useState(todo.task.priority)
  const [minutesEstimate, setMinutesEstimate] = useState(
    todo.task.minutesEstimate
  )
  const [minutesReported, setMinutesReported] = useState(
    todo.task.minutesReported
  )
  const [categoryId, setCategoryId] = useState(todo.task.category.id)
  const [submitting, setSubmitting] = useState(false)

  const { loading, error, data } = useQuery(TASK_FORM_QUERY, {
    fetchPolicy: "cache-and-network",
  })
  const handleCompleted = event => {
    setCompleted(event.target.checked)
  }
  // const handleUpdateCache = (cache, { data: { handleSubmit } }) => {
  //   const data = cache.readQuery({ query: PROJECT_TODOS_QUERY })
  //   const tasks = data.tasks.concat(handleSubmit.task)
  //   cache.writeQuery({ query: PROJECT_TODOS_QUERY, data: { tasks } })
  // }

  const [handleSubmit] = useMutation(
    // const [handleSubmit, { loading, error }] = useMutation(
    UPDATE_PROJECT_TASK_MUTATION,
    {
      variables: {
        taskId: taskId,
        title: title,
        categoryId: categoryId,
        assignedToId: assignedToId,
        dueDate: dueDate,
        completedDate: completedDate,
        taskCompleted: completed,
        note: note,
        priority: priority,
        minutesEstimate: minutesEstimate,
        minutesReported: minutesReported,
      },

      // onCompleted: (response, errors) => {
      onCompleted: data => {
        let freshTask = data.updateTask.task.taskForProject
        const updatedTaskIndex = incompleteTasks.findIndex(
          it => it.id === freshTask.id
        )
        const newTaskList = [
          ...incompleteTasks.slice(0, updatedTaskIndex),
          freshTask,
          ...incompleteTasks.slice(updatedTaskIndex + 1),
        ]
        setIncompleteTasks(newTaskList)

        setTitle("")
        setAssignedToId(1)
        setTitle("")
        setPriority(1)
        // setCompleted(false)
        setNote("")
        setDueDate("")
        setSubmitting(false)
        setEdit(false)
      },
      onError: err => console.error(err),
    }
  )
  const handleUpdate = () => {
    setSubmitting(true)
    handleSubmit()
  }
  const handleUpdateDueDate = event => {
    event.persist()
    let value = null
    if (event.target.value != value) {
      setDueDate(event.target.value)
    }
  }
  // if (loading) return <Loading />
  if (loading) return "Loading..."
  if (error) return "Error..."
  // if (error) return <Error />
  if (data === undefined) return "something is wrong"
  if (data == null) {
    return "something is wrong"
  }
  const staff = data.staff
  const categories = data.taskCategories

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          className={classes.header}
          title={
            <Typography
              display="block"
              variant="overline"
              color="textSecondary"
            >
              Update Task
            </Typography>
          }
        />

        <form
          onSubmit={event => {
            event.preventDefault()
            handleUpdate(event)
          }}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <CardContent>
            <TextField
              fullWidth
              label="Task Name"
              name="taskTitle"
              value={title}
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.field}
              onChange={event => setTitle(event.target.value)}
            />

            <TextField
              id="outlined-multiline-static"
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={note}
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.field}
              onChange={event => setNote(event.target.value)}
            />

            <TextField
              id="datetime-local"
              label="Due Date"
              type="datetime-local"
              variant="outlined"
              className={classes.field}
              fullWidth
              value={dueDate}
              onChange={handleUpdateDueDate}
              // onChange={event => setDueDate(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Category"
              id="category"
              onChange={event => setCategoryId(event.target.value)}
              select
              margin="dense"
              size="small"
              fullWidth
              variant="outlined"
              className={classes.field}
              value={categoryId}
            >
              <MenuItem value={null}>
                <em>Select a Category</em>
              </MenuItem>
              {categories.map(cat => {
                const catId = parseInt(cat.id)
                return (
                  <MenuItem key={cat.id} value={catId}>
                    {cat.name}
                  </MenuItem>
                )
              })}
            </TextField>

            <TextField
              id="assigned-to"
              value={assignedToId}
              select
              margin="dense"
              size="small"
              onChange={event => setAssignedToId(event.target.value)}
              label="Assigned To"
              variant="outlined"
              className={classes.field}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            >
              {staff.map(st => (
                <MenuItem key={st.id} value={st.id}>
                  {st.person.firstName} {st.person.lastName}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Priority"
              id="task-priority"
              select
              value={priority}
              margin="dense"
              size="small"
              onChange={event => setPriority(event.target.value)}
              className={classes.field}
              variant="outlined"
              fullWidth
            >
              <MenuItem value={1}>1 - Highest Priority</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5 - Lowest Priority</MenuItem>
            </TextField>
            <TextField
              fullWidth
              label="Minutes to Complete (estimate)"
              value={minutesEstimate}
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.field}
              onChange={event => setMinutesEstimate(event.target.value)}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={completed}
                  onChange={handleCompleted}
                  name="completed"
                  color="primary"
                />
              }
              label="Already Completed"
            />

            <Collapse in={completed} timeout="auto" unmountOnExit>
              <TextField
                id="datetime-local-completed"
                label="Completed Date"
                type="datetime-local"
                variant="outlined"
                fullWidth
                value={completedDate}
                onChange={event => setCompletedDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  native: true,
                }}
                className={classes.field}
              />

              <TextField
                fullWidth
                label="Minutes to Complete (reported)"
                value={minutesReported}
                variant="outlined"
                className={classes.field}
                margin="dense"
                size="small"
                onChange={event => setMinutesReported(event.target.value)}
              />
            </Collapse>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button
                onClick={() => setEdit(false)}
                variant="outlined"
                color="secondary"
                size="small"
              >
                Cancel
              </Button>

              <Button
                color="secondary"
                disabled={submitting}
                type="submit"
                variant="contained"
                size="small"
              >
                Update Task
              </Button>
            </Box>
          </CardContent>
        </form>
      </Card>
    </>
  )
}

// const PROJ_TODOS_QUERY = gql `

// `

const UPDATE_PROJECT_TASK_MUTATION = gql`
  mutation (
    $taskId: Int!
    $title: String!
    $categoryId: Int
    $projectId: Int
    $dueDate: DateTime
    $completedDate: DateTime
    $taskCompleted: Boolean
    $assignedToId: Int
    $note: String
    $priority: Int
    $minutesEstimate: Int
    $minutesReported: Int
  ) {
    updateTask(
      taskId: $taskId
      title: $title
      categoryId: $categoryId
      projectId: $projectId
      dueDate: $dueDate
      completedDate: $completedDate
      taskCompleted: $taskCompleted
      assignedToId: $assignedToId
      note: $note
      priority: $priority
      minutesEstimate: $minutesEstimate
      minutesReported: $minutesReported
    ) {
      task {
        id
        taskForProject {
          id
          task {
            id
            title
            createdDate
            dueDate
            completed
            completedDate
            note
            priority
            minutesEstimate
            minutesReported
            taskOverdueStatus
            category {
              id
              name
            }
            createdBy {
              id
              person {
                uid
                firstName
                lastName
              }
            }
            assignedTo {
              id
              person {
                uid
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`

const TASK_FORM_QUERY = gql`
  query {
    staff {
      id
      person {
        uid
        firstName
        lastName
      }
    }
    taskCategories {
      id
      name
    }
  }
`
export default UpdateTask
