import React, { useCallback, useState, useEffect } from "react"
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  makeStyles,
} from "@material-ui/core"
import { useQuery } from "@apollo/client"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import gql from "graphql-tag"
import Page from "../../../Global/Page"
// import axios from "src/utils/axios"
// import useIsMountedRef from "../../../../hooks/useIsMountedRef"
import Header from "./Header"
import Details from "./Details"
import Assets from "./Assets"
import Account from "./Account"
import Service from "./Service"
// import Invoices from './Invoices';
// import Logs from './Logs';
// import { useMatch } from "@reach/router"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

function PeopleDetailsView({ uid }) {
  const classes = useStyles()

  const [currentTab, setCurrentTab] = useState("details")
  const { loading, error, data } = useQuery(PERSON_DETAILS_QUERY, {
    variables: { uid },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      console.log(data)
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const person = data.person

  const tabs = [
    { value: "details", label: "Details" },
    { value: "account", label: "Account" },
    { value: "assets", label: "Assets" },
    { value: "service", label: "Service" },
  ]

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  return (
    <Page className={classes.root} title="Contact Details">
      <Container maxWidth={false}>
        <Header person={person} />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === "details" && <Details person={person} />}
          {currentTab === "account" && <Account person={person} />}
          {currentTab === "assets" && <Assets person={person} />}
          {currentTab === "service" && <Service person={person} />}
        </Box>
      </Container>
    </Page>
  )
}

export const PERSON_DETAILS_QUERY = gql`
  query ($uid: String!) {
    person(uid: $uid) {
      uid
      firstName
      lastName
      middleName
      suffix
      nickname
      title
      about
      userCanUpdate
      isSupplier
      dateAdded
      dateModified
      addressPerson {
        id
        address {
          uid
          region {
            id
            name
            globalRegion
            worldRegion
          }
          category {
            id
            name
          }
          isOrg
          attention
          addressLine1
          addressLine2
          addressLine3
          zone
          city
          postalCode
        }
      }
      preregPerson {
        preregId
        preregEmail
        preregQuoteCustomer {
          id
          quote {
            id
            createdAt
            sent
            sentAt
            isActive
            modifiedAt
            createdBy {
              id
              person {
                uid
                firstName
                lastName
              }
            }
          }
        }
      }
      user {
        email
        isVerified
        lastLogin
        admin
        manager
        staff
        partner
        quotedFor {
          id
          quote {
            id
            createdBy {
              id
              person {
                uid
                firstName
                lastName
              }
            }
          }
        }
      }
      phonePerson {
        id
        phoneType {
          id
          phoneType
        }
        phoneNumber
      }
      personSocialMedia {
        id
        socialMediaType {
          id
          name
          url
        }
        username
        url
      }
      emailPerson {
        id
        emailAddress
      }
    }
  }
`
export default PeopleDetailsView
