import React, { useState, useContext, useEffect } from "react"
import { Grid, Typography } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import gql from "graphql-tag"
import ProposalList from "./ProposalList"
import SortContext from "../../../../../../context/SortContext"
const noDataResponse = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <Typography variant="h3">
            This item does not appear in any proposals.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
const ItemInProposals = ({ item }) => {
  const itemId = item.id
  const { state, dispatch } = useContext(SortContext)
  const search = ""
  const { loading, error, data } = useQuery(ITEM_IN_QUOTES_QUERY, {
    variables: {
      itemId: itemId,
      page: state.page,
      pageSize: state.pageSize,
    },
    onCompleted: data => {
      console.log(data)
    },
  })
  //   useDidMountEffect(() => {
  //     dispatch({ type: "SET_PAGE_SIZE", payload: 15 })
  //     dispatch({ type: "SET_PAGE", payload: 0 })
  //     dispatch({ type: "SET_SEARCH", payload: "" })
  //   }, [])
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) {
    return { noDataResponse }
  }
  if (data == null) {
    return { noDataResponse }
  }
  const quotes = data.quotesWithItem

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <ProposalList quotes={quotes} />
        </Grid>
      </Grid>
    </>
  )
}

const ITEM_IN_QUOTES_QUERY = gql`
  query ($page: Int, $pageSize: Int, $itemId: Int) {
    quotesWithItem(page: $page, pageSize: $pageSize, itemId: $itemId) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        sent
        sentAt
        likelihood
        isActive
        businessUnit {
          id
          name
        }
        createdBy {
          person {
            uid
            firstName
            lastName
          }
        }
        createdAt
        modifiedAt
      }
    }
  }
`
export default ItemInProposals
