export default function reducer(state, action) {
  switch (action.type) {
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      }
    case "SET_PAGE":
      return {
        ...state,
        page: action.payload,
      }
    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageSize: action.payload,
      }
    case "CLOSE_SIDEBAR":
      return {
        ...state,
        sidebarOpen: false,
      }
    case "OPEN_SIDEBAR":
      return {
        ...state,
        sidebarOpen: true,
      }
    case "UPDATE_COUNT":
      return {
        ...state,
        updateCount: action.payload,
      }
    case "STAR_COUNT":
      return {
        ...state,
        starCount: action.payload,
      }
    case "ARCHIVE_COUNT":
      return {
        ...state,
        archivedCount: action.payload,
      }
    case "FOLLOWING_COUNT":
      return {
        ...state,
        followingCount: action.payload,
      }
    case "INVITES_COUNT":
      return {
        ...state,
        invitesCount: action.payload,
      }
    case "SET_STARRED_FILTER":
      return {
        ...state,
        starredFilter: action.payload,
      }
    case "SET_FOLLOWING_FILTER":
      return {
        ...state,
        followingFilter: action.payload,
      }
    case "SET_HAS_UPDATE_FILTER":
      return {
        ...state,
        hasUpdateFilter: action.payload,
      }
    case "SET_EXCLUDE_ARCHIVED_FILTER":
      return {
        ...state,
        excludeArchivedFilter: action.payload,
      }
    case "SET_ARCHIVED_FILTER":
      return {
        ...state,
        archivedFilter: action.payload,
      }
    case "SET_NEW_INVITES_FILTER":
      return {
        ...state,
        newInvitesFilter: action.payload,
      }
    case "SET_SELECTED_CONVERSATION_ID":
      return {
        ...state,
        selectedConversationId: action.payload,
      }
    default:
    case "SET_PANEL":
      return {
        ...state,
        panel: action.payload,
      }
      return state
  }
}
