import React, { useCallback, useState, useEffect, useContext } from "react"
import { Box, Container, makeStyles } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Page from "../../../Global/Page"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
// import axios from "src/utils/axios"
// import useIsMountedRef from "src/hooks/useIsMountedRef"
import Header from "./Header"
// import Filter from './Filter';
import Results from "./Results"
import ProjectContext from "../context/ProjectContext"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const BrowseProjects = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(ProjectContext)
  // const isMountedRef = useIsMountedRef()
  // const [projects, setProjects] = useState([]);
  const { loading, error, data } = useQuery(BROWSE_PROJECTS_QUERY, {
    variables: {
      pageSize: state.pageSize,
      page: state.page,
      search: state.search,
    },
  })

  if (loading) return <Loading />

  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const res = data.projects

  return (
    <Page className={classes.root} title="Project List">
      <Container maxWidth="lg">
        <Header />
        <Results res={res} />
      </Container>
    </Page>
  )
}
const BROWSE_PROJECTS_QUERY = gql`
  query ($page: Int, $pageSize: Int, $search: String) {
    projects(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        title
        objective
        createdDate
        dueDate
        completed
        completedDate
        clientProject
        priority
        value
        budget
        projectOverdueStatus
        category {
          id
          name
        }
        tags {
          id
          name
        }
        createdBy {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        planProject {
          id
          plan {
            id
            title
            isActive
          }
          dependsOn {
            id
            title
            completed
            completedDate
            priority
          }
        }
        projectManager {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        projectTask {
          id
          task {
            id
            title
            createdDate
            dueDate
            completedDate
            note
            priority
            minutesEstimate
            minutesReported
            taskOverdueStatus
            createdBy {
              id
              person {
                firstName
                lastName
              }
            }
            assignedTo {
              id
              person {
                uid
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`
export default BrowseProjects
