export default function reducer(state, action) {
  switch (action.type) {
    case "SET_ACTIVE_STEP":
      return {
        ...state,
        activeStep: action.payload,
      }
    case "SET_NEW_PERSON_ACTIVE_STEP":
      return {
        ...state,
        newPersonActiveStep: action.payload,
      }
    case "SET_ORG_OUID":
      return {
        ...state,
        ouid: action.payload,
      }
    case "SET_ORG_PEOPLE":
      return {
        ...state,
        orgPeople: action.payload,
      }
    case "SET_PEOPLE_VIEW":
      return {
        ...state,
        peopleView: action.payload,
      }
    case "SET_PAGE":
      return {
        ...state,
        page: action.payload,
      }
    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageSize: action.payload,
      }
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      }
    case "TOGGLE_CONFIRM_PERSON":
      return {
        ...state,
        confirmPerson: !state.confirmPerson,
      }
    case "SET_SELECTED_PERSON":
      return {
        ...state,
        selectedPerson: action.payload,
      }

    default:
      return state
  }
}
