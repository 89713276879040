import React, { useState, useContext, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import { Link } from "gatsby"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

import CreateTask from "./CreateTask"
import CompletedTasks from "./CompletedTasks"
import UpdateTask from "./UpdateTask"
import TaskList from "./TaskList"
// import ProjectContext from "../context/ProjectContext"
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
})

const Tasks = ({ project, className, ...rest }) => {
  const classes = useStyles()
  const [projectTasks, setProjectTasks] = useState(project.projectTask)
  // const { state, dispatch } = useContext(ProjectContext)

  const [edit, setEdit] = useState(false)
  const [activeTask, setActiveTask] = useState("")
  const { deleteMode, setDeleteMode } = useState(false)
  const [editTaskId, setEditTaskId] = useState({})
  const [showCompletedTasks, setShowCompletedTasks] = useState(true)
  const [completedTasks, setCompletedTasks] = useState(
    projectTasks.filter(t => t.task.completed)
  )
  const [incompleteTasks, setIncompleteTasks] = useState(
    projectTasks.filter(t => !t.task.completed)
  )
  useEffect(() => {
    setIncompleteTasks(projectTasks.filter(t => !t.task.completed))
    setCompletedTasks(projectTasks.filter(t => t.task.completed))
  }, [projectTasks])
  // const id = project.id
  // const { loading, error, data } = useQuery(PROJECT_TODOS_QUERY, {
  //   variables: { id: id },
  //   fetchPolicy: "cache-and-network",
  // })

  function handleUpdate(todo) {
    setEdit(true)
    setEditTaskId(todo)
  }

  // if (loading) return <Loading />
  // if (loading) return "Loading..."
  // if (error) return "Error..."
  // // if (error) return <Error />
  // if (data === undefined) return "something is wrong"
  // if (data == null) {
  //   return "something is wrong"
  // }
  // const todos = data.project.projectTasks
  return (
    <>
      <div className={classes.root}>
        <Grid
          className={clsx(classes.root, className)}
          container
          spacing={3}
          {...rest}
        >
          <Grid item lg={8} xl={9} xs={12}>
            {/* <UpdateTask editTaskId={editTaskId} /> */}

            <TaskList
              projectTasks={projectTasks}
              completedTasks={completedTasks}
              setCompletedTasks={setCompletedTasks}
              incompleteTasks={incompleteTasks}
              setIncompleteTasks={setIncompleteTasks}
            />

            <CompletedTasks
              projectTasks={projectTasks}
              completedTasks={completedTasks}
              setCompletedTasks={setCompletedTasks}
              incompleteTasks={incompleteTasks}
              setIncompleteTasks={setIncompleteTasks}
            />
          </Grid>

          <Grid item lg={4} xl={3} xs={12}>
            <CreateTask
              project={project}
              projectTasks={projectTasks}
              setProjectTasks={setProjectTasks}
            />
          </Grid>

          {/* <Grid item lg={4} xl={3} xs={12} id="CreateTask">
          <CreateTask project={project} /> */}
          {/* <Members members={project.members} /> */}
          {/* <ToDoList project={project} /> */}

          {/* <Box mt={3}>
            <CompletedTasks todos={todos} />
          </Box>
        </Grid> */}
        </Grid>
      </div>
    </>
  )
}

// export const PROJECT_TODOS_QUERY = gql`
//   query($id: Int!) {
//     project(id: $id) {
//       id
//       projectTasks {
//         id
//         title
//         category {
//           id
//           name
//         }
//         createdDate
//         dueDate
//         completed
//         completedDate
//         createdBy {
//           id
//           email
//           person {
//             uid
//             firstName
//             lastName
//           }
//         }
//         assignedTo {
//           id
//           email
//           person {
//             uid
//             firstName
//             lastName
//           }
//         }
//         note
//         priority
//         minutesEstimate
//         minutesReported
//         taskOverdueStatus
//       }
//     }
//   }
// `

export default Tasks
