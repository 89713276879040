import React, { useState, useContext } from "react"
import clsx from "clsx"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

import {
  XCircle as DeleteIcon,
  Search as SearchIcon,
  PlusCircle as AddIcon,
} from "react-feather"
import { Link } from "gatsby"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import SortContext from "../../../../context/SortContext"
import Label from "../../../Global/Label"
import AddQuoteItem from "./AddQuoteItem"
import QuoteFiles from "./QuoteFiles"

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const QuoteItems = ({
  id,
  items,
  setItems,
  search,
  setSearch,
  createNew,
  setCreateNew,
  quote,
  classes,
  ...rest
}) => {
  const { state, dispatch } = useContext(SortContext)
  const [addQuoteItem, setAddQuoteItem] = useState(false)
  const [count, setCount] = useState(0)
  const [deletingItem, setDeletingItem] = useState("")
  const [resPage, setResPage] = useState(null)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchQuery, setSearchQuery] = useState("")
  // const searchContext = ""
  // const resCount = res.count
  // const resPage = res.page
  // const resPages = res.pages
  //   useDidMountEffect(() => {
  //     console.log(updatingPricing)
  //     setUpdatingQty(updatingPricing.split(",")[0])
  //     setUpdatingPriceEach(updatingPricing.split(",")[1])
  //   }, [updatingPricing])

  //   useDidMountEffect(() => {
  //     setOrderItemId(updatingItem[0])
  //     setUpdatingItemId(updatingItem[1])
  //   }, [updatingItem])

  const { data, loading, error } = useQuery(QUOTE_ITEMS_QUERY, {
    variables: {
      quoteId: id,
      page: page,
      pageSize: pageSize,
      search: searchQuery,
    },
    onCompleted: (data, response, errors) => {
      console.log(data.quoteItemsByQuote.objects)
      setItems(data.quoteItemsByQuote.objects)
      setCount(data.quoteItemsByQuote.count)
      setResPage(data.quoteItemsByQuote.page)
      dispatch({ type: "SET_PAGE", payload: page })
      dispatch({ type: "SET_PAGE_SIZE", payload: pageSize })
      dispatch({ type: "SET_SEARCH", payload: searchQuery })
    },
    fetchPolicy: "network-only",
  })

  const [handleDelete] = useMutation(DELETE_QUOTE_ITEM_MUTATION, {
    variables: {
      quoteItemId: deletingItem,
    },
    onCompleted: (data, errors) => {
      const index = items.findIndex(quoteItem => quoteItem.id === deletingItem)
      const newList = [...items.slice(0, index), ...items.slice(index + 1)]
      setItems(newList)
    },
  })

  //

  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  function calcTotal(a, b) {
    //Return the sum
    return (a * b).toFixed(2)
  }
  const handlePageChange = (event, newPage) => {
    let x = 1
    const pagiNewPage = newPage
    dispatch({ type: "SET_PAGE", payload: pagiNewPage })
  }

  const handleChangeRowsPerPage = event => {
    const newPageSize = parseInt(event.target.value)
    setPageSize(newPageSize)
    // dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    // dispatch({ type: "SET_PAGE", payload: 0 })
  }

  const handleSetSearch = event => {
    setSearch(true)
    setAddQuoteItem(true)
  }

  const handleCreateNew = event => {
    setCreateNew(true)
    setAddQuoteItem(true)
  }

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }

  return (
    <>
      <Card className={classes.root} {...rest}>
        <CardHeader
          title="Proposal items"
          action={
            !addQuoteItem && (
              <>
                <IconButton
                  aria-label="search"
                  color="primary"
                  onClick={handleSetSearch}
                >
                  <SearchIcon />
                </IconButton>
                <IconButton
                  aria-label="create-new"
                  color="primary"
                  onClick={handleCreateNew}
                >
                  <AddIcon />
                </IconButton>
              </>
            )
          }
        />
        <Divider />
        <Collapse in={addQuoteItem} timeout="auto" unmountOnExit>
          <CardContent>
            <AddQuoteItem
              quote={quote}
              id={id}
              items={items}
              setItems={setItems}
              setAddQuoteItem={setAddQuoteItem}
              search={search}
              setSearch={setSearch}
              createNew={createNew}
              setCreateNew={setCreateNew}
            />
          </CardContent>
        </Collapse>
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableBody>
                {/* <TableRow> */}
                {items.map(i => (
                  <TableRow key={i.id}>
                    <TableCell>
                      <Typography className={classes.itemName}>
                        {i.item.name}
                      </Typography>{" "}
                      <br />
                      {i.item.category.name} <br />
                      Item #:{" "}
                      <Link
                        to={`/manager/items/${i.item.id}`}
                        state={{ id: `${i.item.id}` }}
                        className={classes.link}
                      >
                        <Label color="secondary">{i.item.id}</Label>
                      </Link>
                      <br />
                      <QuoteFiles quoteId={id} quoteItem={i} />
                    </TableCell>

                    <TableCell align="right">
                      {deletingItem === `${i.id}` ? (
                        <>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={handleDelete}
                            className={classes.button}
                          >
                            Remove
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setDeletingItem("")}
                            className={classes.button}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <IconButton
                            onClick={() => setDeletingItem(`${i.id}`)}
                          >
                            <SvgIcon fontSize="small">
                              <DeleteIcon />
                            </SvgIcon>
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        {count > 5 && (
          <TablePagination
            component="div"
            count={count}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25]}
          />
        )}
      </Card>
    </>
  )
}

const styles = theme => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  itemName: {
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(16),
  },
  button: {
    margin: theme.spacing(1),
  },
})

const QUOTE_ITEMS_QUERY = gql`
  query ($quoteId: Int, $page: Int, $pageSize: Int, $search: String) {
    quoteItemsByQuote(
      quoteId: $quoteId
      page: $page
      pageSize: $pageSize
      search: $search
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        quoteItemImages {
          id
          image
          title
          imageUrl
          thumbnailUrl
          carouselImageUrl
        }
        quoteItemSampleProofs {
          id
          version
          image
          sampleProofUrl
          thumbnailUrl
          carouselImageUrl
        }
        quote {
          id
        }
        item {
          id
          name
          category {
            id
            name
            description
          }
          businessUnit {
            id
            name
          }
          dateAdded
          dateModified
          isActive
          isPrivate
          pricingModel {
            id
            name
          }
          privateItem {
            itemOwner {
              uid
              firstName
              lastName
            }
          }
          itemPrice {
            id
            minQuantity
            maxQuantity
            price
          }
          itemImage {
            id
            image
            featured
            proof
            title
            note
            uploadedAt
            createdBy {
              id
              person {
                firstName
                lastName
              }
            }
            thumbnailUrl
            carouselImageUrl
          }
          sampleProofItem {
            id
            version
            isVertical
            thumbnailUrl
            carouselImageUrl
            image
          }
          specItem {
            specId
            description
            summary
          }
        }
      }
    }
  }
`
const DELETE_QUOTE_ITEM_MUTATION = gql`
  mutation ($quoteItemId: Int!) {
    deleteQuoteItem(quoteItemId: $quoteItemId) {
      quoteItemId
    }
  }
`

export default withStyles(styles)(QuoteItems)
