import React, { useState } from "react"
import clsx from "clsx"
import moment from "moment"
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import SkipNextIcon from "@material-ui/icons/SkipNext"
import { Link } from "gatsby"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import RoomIcon from "@material-ui/icons/Room"
import CreateIcon from "@material-ui/icons/Create"
import MessageIcon from "@material-ui/icons/Message"
import Label from "../../../../Global/Label"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "spaceBetween",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },

  link: {
    textDecoration: "none",
  },
  roundedCard: {
    borderRadius: "15px",
  },
  verticalImage: {
    // width:
    height: "100%",
  },
  horizontalImage: {
    width: "100%",
  },
}))

const SampleProofCommentCard = ({ comment }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [showFullSize, setShowFullSize] = useState(false)
  // const image = sp.sampleProofUrl

  const commentIcon = icon => {
    switch (icon) {
      case "circle":
        return <RadioButtonUncheckedIcon />
      case "arrow":
        return <RoomIcon />
      case "pen":
        return <CreateIcon />
      case "none":
        return <MessageIcon />
      default:
        return <MessageIcon />
    }
  }

  const CommentList = () => {
    return (
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Comments from {comment.uploadedBy.firstName}{" "}
            {comment.uploadedBy.lastName}
          </ListSubheader>
        }
      >
        {comment.sampleProofImageComment.map(ic => {
          const icon = ic.commentTool
          const commentDate = moment(ic.timestamp).format("DD/MM/YYYY | HH:MM")
          return (
            // <Dialog open={openDialog}></Dialog>
            <ListItem key={ic.id}>
              <ListItemIcon
                aria-label="comment type"
                style={{
                  // color: "white",
                  // backgroundColor: ic.commentColor,
                  color: ic.commentColor,
                  // backgroundColor: ic.commentColor,
                }}
              >
                {commentIcon(icon)}
              </ListItemIcon>
              <ListItemText primary={ic.comment} secondary={commentDate} />
            </ListItem>
            // <Avatar
            //   aria-label="comment type"
            //   style={{ color: "white", backgroundColor: ic.commentColor }}
            // >
            //   {commentIcon(icon)}
            // </Avatar>

            // <Typography>{ic.comment}</Typography>
          )
        })}
      </List>
    )
  }

  return (
    <>
      <Dialog open={showFullSize} onClose={() => setShowFullSize(false)}>
        <img src={comment.commentImageUrl} alt={comment.commentImage} />
        <CommentList />
      </Dialog>
      <Card className={clsx(classes.root, classes.roundedCard)}>
        {/* <img src={comment.commentImage} /> */}
        {/* {JSON.stringify(comment.carouselImageUrl)} */}
        <Grid container>
          <Grid item xs={12}>
            <CardActionArea onClick={() => setShowFullSize(true)}>
              <img
                src={comment.carouselImageUrl}
                alt={`Version ${comment.commentImage}`}
                className={
                  comment.isVertical
                    ? classes.verticalImage
                    : classes.horizontalImage
                }
              />
            </CardActionArea>
          </Grid>
          <Grid item xs={12}>
            {/* <div className={classes.details}> */}
            <CardContent className={classes.content}>
              {/* {JSON.stringify(comment)} */}
              <Typography component="h5" variant="h5">
                {/* <Label color={paymentStatusColors[cart.payment.status]}>
                          {cart.payment.status}
                        </Label> */}
                {`Version ${comment.commentImage}`}
              </Typography>
              {/* <Typography>Comments:</Typography> */}

              <CommentList />
              {/* <Typography variant="subtitle1" color="textSecondary">
            {`Version ${sp.version}`}
          </Typography> */}
            </CardContent>
            {/* </div> */}
          </Grid>
        </Grid>
      </Card>
    </>
  )
}
export default SampleProofCommentCard
