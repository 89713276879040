import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import ImageSearchIcon from "@material-ui/icons/ImageSearch"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import CircularProgress from "@material-ui/core/CircularProgress"
import Divider from "@material-ui/core/Divider"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import Alert from "@material-ui/lab/Alert"
import TextField from "@material-ui/core/TextField"

import { Box, Checkbox, Input } from "@material-ui/core"
import gql from "graphql-tag"
// import { useMutation } from "@apollo/client"
import { useApolloClient, useMutation, useQuery } from "@apollo/client"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(1),
  },
  save: {
    color: "green",
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
}))

const EditFile = ({ file, setEditing }) => {
  const [imageFile, setImageFile] = useState(file.image)

  const [title, setTitle] = useState(file.title)
  const [note, setNote] = useState(file.note)
  const [imageUrl, setImageUrl] = useState(file.imageUrl)
  const [isProof, setIsProof] = useState(file.proof)
  const [isFeatured, setIsFeatured] = useState(file.featured)
  const [image, setImage] = useState(file.image)
  const [itemId, setItemId] = useState(file.item.id)
  const [imageId, setImageId] = useState(file.id)
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [fileError, setFileError] = useState("")

  const classes = useStyles()

  const apolloClient = useApolloClient()

  const [handleSubmit, { data }] = useMutation(UPDATE_ITEM_IMAGE_MUTATION, {
    variables: {
      imageId: imageId,
      itemId: itemId,
      image: file,
      title: title,
      note: note,
      featured: isFeatured,
      proof: isProof,
    },

    onCompleted: (data, response, errors) => {
      let imageData = data.updateItemImage
      console.log(imageData)
      setEditing(false)
      //   const newImage = { ...imageData }
      //   setFiles(files => [...files, newImage])
      //   setFile("")
      //   setTitle("")
      //   setNote("")
      //   setIsFeatured(false)
      //   setIsProof(false)
      //   setSubmitting(false)
      //   setSuccess(true)
    },
    onError: err => console.error(err),
    // update={handleUpdateCache}
  })

  const handleImageChange = event => {
    const selectedFile = event.target.files[0]
    const fileSizeLimit = 10000000 // 10MB
    if (selectedFile && selectedFile.size > fileSizeLimit) {
      setFileError(`${selectedFile.name}: File size too large`)
    } else {
      setImage(selectedFile)
      setFileError("")
    }
  }
  const handleCheckProof = event => {
    setIsProof(event.target.checked)
  }
  const handleCheckFeatured = event => {
    setIsFeatured(event.target.checked)
  }
  // const handleUpdateCache = (cache, response) => {
  //   const data = cache.readQuery({ query: ITEM_IMAGES_QUERY })
  //   const images = data.itemImg.concat()
  //   cache.writeQuery({ query: ITEM_IMAGES_QUERY, data: {} })
  // }

  return (
    <>
      <Card className={classes.root}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Edit / Update This File
            </Typography>
          </CardContent>
        </CardActionArea>
        <form
          onSubmit={event => {
            event.preventDefault()
            setSubmitting(true)
            handleSubmit(event)
          }}
        >
          <FormControl error={Boolean(fileError)}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              required
              onChange={handleImageChange}
            />
            <label htmlFor="contained-button-file">
              <Button
                type="button"
                component="span"
                variant="contained"
                color="default"
                className={classes.button}
                startIcon={<ImageSearchIcon />}
              >
                Select an Image File
              </Button>
              {/* {imageFile && title} */}
              <FormHelperText>{fileError}</FormHelperText>
            </label>
            <TextField
              id="standard-full-width"
              label="Title"
              style={{ margin: 8 }}
              placeholder="Image Title (optional)"
              fullWidth
              margin="normal"
              onChange={e => setTitle(e.target.value)}
              value={title}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="standard-full-width"
              label="Note"
              style={{ margin: 8 }}
              placeholder="Note about this image (optional)"
              fullWidth
              margin="normal"
              onChange={e => setNote(e.target.value)}
              value={note}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Box alignItems="center" display="flex" mt={2} ml={-1}>
              <Checkbox
                checked={isProof}
                // name="supplier"
                onChange={handleCheckProof}
              />

              <Typography variant="body2" color="textSecondary">
                This is a proof.
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" mt={2} ml={-1}>
              <Checkbox
                checked={isFeatured}
                // name="supplier"
                onChange={handleCheckFeatured}
              />

              <Typography variant="body2" color="textSecondary">
                Set this as the primary/featured image for this item.
              </Typography>
            </Box>
            <Button
              type="button"
              disabled={submitting}
              onClick={() => {
                setFileError("")
                setImage("")
                setTitle("")
                setNote("")
                setEditing(false)
              }}
              className={classes.cancel}
            >
              Cancel
            </Button>
            <Button
              disabled={submitting || !file}
              type="submit"
              className={classes.save}
            >
              {submitting ? "Please wait..." : "Update"}
            </Button>
          </FormControl>
        </form>
        {success === true ? (
          <Alert
            variant="outlined"
            severity="success"
            onClose={() => {
              setSuccess(false)
            }}
          >
            Your info and image have successfully updated.
          </Alert>
        ) : null}
      </Card>
    </>
  )
}
const UPDATE_ITEM_IMAGE_MUTATION = gql`
  mutation (
    $imageId: Int!
    $itemId: Int!
    $image: Upload
    $title: String
    $note: String
    $proof: Boolean
    $featured: Boolean
  ) {
    updateItemImage(
      imageId: $imageId
      itemId: $itemId
      image: $image
      title: $title
      note: $note
      proof: $proof
      featured: $featured
    ) {
      itemImage {
        image
        title
        note
        featured
        proof
        uploadedAt
        imageUrl
        item {
          id
        }
      }
    }
  }
`

const ITEM_IMAGES_QUERY = gql`
  query ($id: Int!) {
    item(id: $id) {
      id
      name
      itemimgSet {
        id
        title
        note
        image
        imageUrl
        item {
          id
        }
      }
    }
  }
`
const LOGIN_QUERY = gql`
  query {
    me {
      id
      admin
    }
  }
`
export default EditFile
