import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { useLocation } from "@reach/router"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  SvgIcon,
  makeStyles,
} from "@material-ui/core"
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined"
import {
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
  Clock as ExpiredIcon,
  ShoppingCart as CartIcon,
  Package as PackageIcon,
  RefreshCw as UpdateIcon,
} from "react-feather"
import DateFnsUtils from "@date-io/date-fns"
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDateTimePicker,
// } from "@material-ui/pickers"
const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  icon: {
    padding: "3px",
  },
  statusButton: {
    margin: "3px",
  },
}))

// const statusOptions = ["Canceled", "Completed", "Rejected"]

function CartInfo({ cart, className, ...rest }) {
  const classes = useStyles()
  const location = useLocation()
  const userPermission = location.pathname.split("/")[1]
  const [status, setStatus] = useState("Change This")
  const cartItems = JSON.parse(cart.items)
  const cartId = cart.cartId
  const [expirationDate, setExpirationDate] = useState(cart.expirationDate)
  const [changeDate, setChangeDate] = useState(false)
  const [cartOpen, setCartOpen] = useState(cart.cartOpen)

  const [updateCartInfo, { loading, error }] = useMutation(
    UPDATE_CART_INFO_MUTATION,
    {
      variables: {
        cartId: cartId,
        expirationDate: expirationDate,
        cartOpen: cartOpen,
      },
      onCompleted: data => {
        console.log(data)
        setExpirationDate(data.updateCartInfo.cart.expirationDate)
        setChangeDate(false)
      },
    }
  )
  useEffect(() => {
    {
      changeDate && updateCartInfo()
    }
  }, [changeDate])
  //   const discount = order.discount
  //   const orderItems = order.orderWithItem
  //   const orderPayments = order.orderPayment
  const handleChange = event => {
    event.persist()
    setStatus(event.target.value)
  }

  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  function ccyFormat(num) {
    return `${num.toFixed(2)}`
  }

  function priceRow(qty, unit) {
    return qty * unit
  }

  function createRow(desc, qty, unit) {
    const price = priceRow(qty, unit)
    return { desc, qty, unit, price }
  }
  const handleExpirationDateChange = date => {
    setExpirationDate(date)
    setChangeDate(true)
  }
  // function subtotal() {
  //   let prices = items.priceEach

  //   return prices.map(price => price.reduce((sum, i) => sum + i, 0))
  // }
  // function subtotal(items) {
  //   let lineSubtotal = items.qty * items.priceEach
  //   return items.map(priceEach => priceEach.reduce((sum, i) => sum + i, 0))
  // }
  // function subtotal(items) {
  //   return items
  //     .map(lineSubtotal => lineSubtotal)
  //     .reduce((sum, i) => sum + i, 0)
  // }
  // function calcTotal(a, b) {
  //   return (a * b).toFixed(2)
  // }
  // const calculateTotalTaxes = items => items.reduce(item => item.taxAmt, 0)

  // const calculateTotalDiscount = items =>
  //   items.reduce((total, item) => total + item.discountAmt, 0)

  //   const calculateSubtotal = items =>
  //     items.reduce((total, item) => total + item.qty * item.priceEach, 0)
  //   const orderSubtotal = calculateSubtotal(items)

  //   const calculatePayment = orderPayments =>
  //     orderPayments.reduce(
  //       (total, orderPayments) => total + orderPayments.totalPaid,
  //       0
  //     )
  //   const totalPaid = calculatePayment(orderPayments)
  // const orderTaxes = calculateTotalTaxes(items)

  // const orderDiscount = calculateTotalDiscount(items)
  //   const orderTotal = (order.subTotal += order.taxAmt -= order.discountAmt)
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title={`${cart.businessUnit && cart.businessUnit.name} Cart # ${
          cart.id
        } `}
        // title={`${order.businessUnit.name} Order # ${order.id} | Status: ${order.status.orderStatus}`}
      />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              {cart.cartOpen ? "Open" : "Closed"}{" "}
              {cart.cartExpired ? "| Expired" : ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>
              <Link
                to={`/${userPermission}/people/${
                  cart.person && cart.person.uid
                }`}
                state={{ uid: `${cart.person && cart.person.uid}` }}
                className={classes.link}
              >
                {cart.person && cart.person.firstName}{" "}
                {cart.person && cart.person.lastName}
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Started Date</TableCell>
            <TableCell>
              {moment(cart.started).format("MMM Do, YYYY | HH:MM")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Latest Change</TableCell>
            <TableCell>
              {moment(cart.latestChange).format("MMM Do, YYYY | HH:MM")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Expiration Date</TableCell>
            <TableCell>
              {cart.cart_expired && (
                <SvgIcon
                  fontSize="small"
                  color="error"
                  className={classes.icon}
                >
                  <ExpiredIcon />
                </SvgIcon>
              )}
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="updae-expiration-date"
                  label="Cart Expiration Date"
                  value={expirationDate}
                  onChange={handleExpirationDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider> */}
              {/* {cart.cartExpired ? "Expired"} */}
              {moment(cart.expirationDate).format("MMM Do, YYYY")}
              {/* <Button
                className={classes.statusButton}
                color="primary"
                variant="outlined"
                size="small"
                onClick={handleChangeExpiration}
              >
                Change
              </Button> */}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Items in Cart</TableCell>
            <TableCell>
              {/* {order.orderWithItem && order.orderWithItem.length} */}
              {/* {cartItems && cartItems.totalItems}  */}
              {cart.totalItems} total items in cart <br />(
              {cart.totalUniqueItems} unique items)
            </TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>Subtotal</TableCell>
            <TableCell>$ {orderSubtotal}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Discount</TableCell>
            <TableCell>$ {order.discountAmt}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tax</TableCell>
            <TableCell>$ {order.taxAmt}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Shipping</TableCell>
            <TableCell>$ {order.postageAmt}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell>$ {orderTotal}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Paid</TableCell>
            <TableCell>$ {totalPaid}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Due</TableCell>
            <TableCell>{order.balanceDue}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell>Change Status</TableCell>
            <TableCell>
              <Button className={classes.statusButton}>Closed</Button>
              <Button className={classes.statusButton}>Expired</Button>
              {/* <TextField
                fullWidth
                name="option"
                size="small"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}

                value={status}
                variant="outlined"
              >
                <option value={"Change This"}>Change This</option>
                <option value={"Not Set Up"}>Not Set Up</option>
              </TextField> */}
              {/* <TextField
                fullWidth
                name="option"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={status}
                variant="outlined"
              >
                {statusOptions.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField> */}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardActions>
        <Button fullWidth variant="outlined" color="primary">
          <ReceiptIcon className={classes.actionIcon} />
          Create Invoice
        </Button>
      </CardActions>
    </Card>
  )
}

CartInfo.propTypes = {
  className: PropTypes.string,
}
const UPDATE_CART_INFO_MUTATION = gql`
  mutation ($cartId: String!, $cartOpen: Boolean, $expirationDate: DateTime) {
    updateCartInfo(
      cartId: $cartId
      cartOpen: $cartOpen
      expirationDate: $expirationDate
    ) {
      cart {
        id
        cartId
        started
        latestChange
        expirationDate
        cartExpired
        cartOpen
        person {
          uid
        }
      }
    }
  }
`
export default CartInfo
