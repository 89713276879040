import React, { useState, useContext } from "react"
import clsx from "clsx"
import {
  Box,
  Divider,
  IconButton,
  InputBase,
  Paper,
  SvgIcon,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core"
import moment from "moment"
import PerfectScrollbar from "react-perfect-scrollbar"
import { useQuery } from "@apollo/client"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import gql from "graphql-tag"
import OrderContext from "../context/OrderContext"
import {
  PlusSquare as CheckIcon,
  Search as SearchIcon,
  X as ClearIcon,
} from "react-feather"

const useStyles = makeStyles(theme => ({
  root: {},
  queryField: {
    margin: "dense",
    size: "small",
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  searchIcon: {
    margin: theme.spacing(0, 2, 0, 0),
    display: "inline",
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.primary.light,
  },
  searchTitle: {
    color: theme.palette.primary.dark,
  },
}))

const OrderItemSearch = ({ className, setSelected, setItem, ...rest }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(OrderContext)
  const { search, page, pageSize, allItems } = state
  // const pageQ = page + 1

  const [searchBar, setSearchBar] = useState("")
  const [newPage, setNewPage] = useState(1)
  const [pickId, setPickId] = useState("")
  const [pickName, setPickName] = useState("")
  const [pickCategory, setPickCategory] = useState("")

  const { loading, error, data } = useQuery(LIST_ALL_ITEMS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      search: search,
      page: page,
      pageSize: pageSize,
    },
    onCompleted: data => {
      dispatch({ type: "SET_ALL_ITEMS", payload: data.items.objects })
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const resCount = data.items.count
  const resPage = data.items.page
  const resPages = data.items.pages
  // const items = data.items.objects

  const selectItem = ({ item }) => {
    setItem(item)
    setSelected(true)
    console.log(item)
  }

  const handlePageChange = (event, newPage) => {
    dispatch({ type: "SET_PAGE", payload: newPage })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
  }
  return (
    <>
      <Box>
        <Box p={2}>
          <Grid
            className={clsx(classes.root, className)}
            container
            justify="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={6}>
              <SvgIcon className={classes.searchIcon}>
                <SearchIcon />
              </SvgIcon>
              <Typography
                variant="h3"
                display="inline"
                className={classes.searchTitle}
              >
                Search
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                component="form"
                onSubmit={event => {
                  event.preventDefault()
                  handleSearch(event)
                }}
                className={classes.searchField}
              >
                <InputBase
                  fullWidth
                  className={classes.input}
                  placeholder="Search Items"
                  inputProps={{ "aria-label": "search items" }}
                  onChange={event => setSearchBar(event.target.value)}
                  value={searchBar}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={handleClearSearchResults}
                >
                  <ClearIcon />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Divider />
          <PerfectScrollbar>
            <Box minWidth={700}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Added</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allItems &&
                    allItems.map(item => (
                      <TableRow hover key={item.id}>
                        <TableCell padding="checkbox">
                          <IconButton onClick={e => selectItem({ item }, e)}>
                            <SvgIcon fontSize="small">
                              <CheckIcon />
                            </SvgIcon>
                          </IconButton>
                        </TableCell>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>
                          {item.businessUnit.name}
                          <br />
                          {item.category.name}
                        </TableCell>
                        <TableCell>
                          {item.isPrivate ? (
                            <>
                              Private <br />
                              {item.privateItem ? (
                                <>{item.privateItem.itemOwner.lastName}</>
                              ) : (
                                "No owner specified"
                              )}
                            </>
                          ) : (
                            "Public"
                          )}
                        </TableCell>
                        <TableCell>
                          {moment(item.dateAdded).format("MMM DD YYYY")}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        </Box>
        <TablePagination
          component="div"
          count={resCount}
          onChangePage={handlePageChange}
          // onChangePage= {event => dispatch({type: 'SET_PAGE', payload: (newPage)})}
          // onChangeRowsPerPage={event => setPageSize(event.target.value)}
          // onChangeRowsPerPage = {event => dispatch({type: 'SET_PAGE_SIZE', payload: (event.target.value)})}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          // dispatch({ type: "SET_LOGIN" })
          // onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          // ActionsComponent={TablePaginationActions}
        />
      </Box>
    </>
  )
}
export const LIST_ALL_ITEMS_QUERY = gql`
  query ($page: Int!, $pageSize: Int!, $search: String) {
    items(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        name
        dateAdded
        dateModified
        isPrivate
        isActive
        category {
          id
          name
        }
        businessUnit {
          id
          name
        }
        itemPrice {
          id
          minQuantity
          maxQuantity
          price
        }
        privateItem {
          itemOwner {
            uid
            firstName
            lastName
          }
        }
      }
    }
  }
`
export default OrderItemSearch
