import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Loading from "../Utils/Loading"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { navigate } from "gatsby"
import { Link } from "gatsby"
import Divider from "@material-ui/core/Divider"

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
})

const UploadCards = () => {
  const classes = useStyles()

  const { loading, error, data } = useQuery(MY_UPLOADED_IMAGES_QUERY)
  if (loading) return <Loading />
  if (error) return navigate("/enter/")
  if (data === undefined) return navigate("/enter/")

  const imageList = data.me.image
  return (
    <>
      <h1>My Images</h1>
      {imageList.map(i => (
        <Card className={classes.root} key={i.id}>
          <CardActionArea>
            <a href={i.imageUrl}>
              <CardMedia
                component="img"
                alt={i.title ? i.title : i.image}
                height="140"
                src={i.imgUrl}
                title={i.title ? i.title : i.image}
              />
            </a>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {i.title ? i.title : null}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {i.note ? i.note : null}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary">
              Edit
            </Button>
            <Button size="small" color="primary">
              Delete
            </Button>
          </CardActions>
        </Card>
      ))}
    </>
  )
}
const MY_UPLOADED_IMAGES_QUERY = gql`
  query {
    me {
      id
      image {
        id
        image
        imageUrl
        title
        note
      }
    }
  }
`
export default UploadCards
