import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Button,
  Drawer,
  Grid,
  Hidden,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  Check as CheckIcon,
  X as XIcon,
  Trash as TrashIcon,
} from "react-feather"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}))

function BulkOperations({
  className,
  onDelete,
  // onMarkSpam,
  onMarkUnspam,
  open,
  selected,
  setSelectedInquiries,
  ...rest
}) {
  const classes = useStyles()
  const [isSpam, setIsSpam] = useState(null)

  useEffect(() => {
    selected.length > 0 && handleSpam()
  }, [isSpam])

  const [handleSpam, { data, loading, error }] = useMutation(
    MARK_AS_SPAM_MUTATION,
    {
      variables: {
        inquiryId: selected,
        spam: isSpam,
      },
      onCompleted: data => {
        console.log(data)
        setSelectedInquiries([])
        setIsSpam(null)
      },
    }
  )

  const handleMarkSpam = event => {
    setIsSpam(true)
  }
  return (
    <Drawer
      anchor="bottom"
      open={open}
      PaperProps={{ elevation: 1 }}
      variant="persistent"
    >
      <div className={clsx(classes.root, className)} {...rest}>
        <Grid alignItems="center" container spacing={2}>
          <Hidden smDown>
            <Grid item md={3}>
              <Typography color="textSecondary" variant="subtitle1">
                {selected.length} selected
              </Typography>
            </Grid>
          </Hidden>
          <Grid item md={6} xs={12}>
            <div className={classes.actions}>
              <Button onClick={() => setIsSpam(true)}>
                <SvgIcon fontSize="small" className={classes.actionIcon}>
                  <CheckIcon />
                </SvgIcon>
                Mark as Spam
              </Button>
              <Button onClick={() => setIsSpam(false)}>
                <SvgIcon fontSize="small" className={classes.actionIcon}>
                  <XIcon />
                </SvgIcon>
                Unmark Spam
              </Button>
              {/* <Button onClick={onDelete}>
                <SvgIcon
                  fontSize="small"
                  className={classes.actionIcon}
                >
                  <TrashIcon />
                </SvgIcon>
                Delete
              </Button> */}
            </div>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  )
}

BulkOperations.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func,
  onMarkSpam: PropTypes.func,
  onMarkUnspam: PropTypes.func,
  open: PropTypes.bool,
  selected: PropTypes.array.isRequired,
}

BulkOperations.defaultProps = {
  className: "",
  onDelete: () => {},
  onMarkSpam: () => {},
  onMarkUnspam: () => {},
  open: false,
}
const MARK_AS_SPAM_MUTATION = gql`
  mutation ($inquiryId: [Int], $spam: Boolean!) {
    updateInquiryAsSpam(inquiryId: $inquiryId, spam: $spam) {
      inquiries {
        id
        spam
      }
    }
  }
`
export default BulkOperations
