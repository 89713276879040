import React, { useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { Grid, makeStyles } from "@material-ui/core"
import ProfileDetails from "./ProfileDetails"
import GeneralSettings from "./GeneralSettings"
import ProfileImage from "./ProfileImage"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import { SettingsInputSvideoRounded } from "@material-ui/icons"

const useStyles = makeStyles(() => ({
  root: {},
}))

function General({ className, ...rest }) {
  const classes = useStyles()
  const [updatePhoto, setUpdatePhoto] = useState(false)
  const [profileImageUrl, setProfileImageUrl] = useState("")
  const [useGravatar, setUseGravatar] = useState(true)
  const [image, setImage] = useState("")

  const { data, loading, error } = useQuery(MY_PROFILE_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      setProfileImageUrl(data.me.person.profileImageUrl)
      setImage(data.me.person.profileImage)
      setUseGravatar(data.me.person.useGravatar)
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const user = data.me
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
      {...rest}
    >
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <ProfileDetails
          user={user}
          updatePhoto={updatePhoto}
          setUpdatePhoto={setUpdatePhoto}
          profileImageUrl={profileImageUrl}
          useGravatar={useGravatar}
          setUseGravatar={setUseGravatar}
        />
      </Grid>
      <Grid item lg={8} md={6} xl={9} xs={12}>
        {updatePhoto ? (
          <ProfileImage
            user={user}
            profileImageUrl={profileImageUrl}
            setProfileImageUrl={setProfileImageUrl}
            image={image}
            setImage={setImage}
            setUpdatePhoto={setUpdatePhoto}
          />
        ) : (
          <GeneralSettings user={user} />
        )}
      </Grid>
    </Grid>
  )
}

General.propTypes = {
  className: PropTypes.string,
}

const MY_PROFILE_QUERY = gql`
  query {
    me {
      id
      isVerified
      active
      staff
      admin
      manager
      email
      partner
      person {
        uid
        firstName
        lastName
        useGravatar
        profileImage
        profileImageUrl
      }
      lastLogin
    }
  }
`
export default General
