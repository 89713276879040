import React, { useState, useContext } from "react"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import DoneIcon from "@material-ui/icons/Done"
import useDidMountEffect from "../../../../Global/hooks/useDidMountEffect"
import OrgCreateContext from "../context/OrgCreateContext"
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

const AddOrgEmail = ({ classes }) => {
  const { state, dispatch } = useContext(OrgCreateContext)
  const ouid = state.ouid
  const [emailAccount, setEmailAccount] = useState("")
  const [addedEmails, setAddedEmails] = useState([])
  const [newArray, setNewArray] = useState([])
  const [emailId, setEmailId] = useState(null)

  const [deleteOrgEmailAccount] = useMutation(DELETE_ORG_EMAIL_MUTATION, {
    variables: {
      emailId: emailId,
    },
    onCompleted: () => {
      setAddedEmails(newArray)
      setEmailAccount("")
    },
  })
  const [createOrgEmailAccount] = useMutation(ADD_ORG_EMAIL_MUTATION, {
    variables: { ouid: ouid, emailAddress: emailAccount },
    onCompleted: data => {
      const emailValue = data.createOrgEmailAccount.emailAccount
      setAddedEmails(prevSelected => [...prevSelected, emailValue])
      setEmailAccount("")
    },
  })
  const handleFormEmail = event => {
    createOrgEmailAccount()
  }
  const handleDelete = email => {
    const filteredArray = addedEmails.filter(x => x.id !== email.id)
    setNewArray(filteredArray)
    setEmailId(email.id)
  }
  useDidMountEffect(() => {
    deleteOrgEmailAccount()
  }, [emailId])

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3">Email</Typography>
          <Typography variant="body1">
            Do you know an email address for this org? If not, skip.
          </Typography>

          {addedEmails.length === 0 ? (
            ""
          ) : (
            <>
              {addedEmails.map(email => (
                <Box key={email.id}>
                  <Chip
                    icon={<DoneIcon />}
                    label={email.emailAddress}
                    variant="outlined"
                    onDelete={e => handleDelete(email)}
                  />
                </Box>
              ))}
            </>
          )}
        </Grid>
        <Grid item sm container direction="column">
          <form
            onSubmit={event => {
              event.preventDefault()
              handleFormEmail(event)
            }}
            className={classes.root}
          >
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Email"
                margin="normal"
                name="emailAccount"
                onChange={event => setEmailAccount(event.target.value)}
                type="email"
                value={emailAccount}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                aria-label="add email"
                variant="contained"
                color="primary"
              >
                Add Email
              </Button>
            </Grid>
          </form>
        </Grid>

        <Divider />
        <Grid item xs={12}>
          <Button
            color="secondary"
            fullWidth
            size="large"
            variant="outlined"
            onClick={() => dispatch({ type: "SET_ACTIVE_STEP", payload: 3 })}
          >
            Next: Add Phone Numbers
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

const ADD_ORG_EMAIL_MUTATION = gql`
  mutation ($emailAddress: String!, $ouid: String!) {
    createOrgEmailAccount(emailAddress: $emailAddress, ouid: $ouid) {
      emailAccount {
        id
        emailAddress
      }
    }
  }
`

const DELETE_ORG_EMAIL_MUTATION = gql`
  mutation ($emailId: Int!) {
    deleteOrgEmailAccount(emailAccountId: $emailAccountId) {
      emailAccountId
    }
  }
`
AddOrgEmail.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
}

AddOrgEmail.default = {
  onSubmitSuccess: () => {},
}

const styles = theme => ({
  root: {},
  button: {
    margin: 10,
  },
  margin: {
    margin: 2,
  },
})
export default withStyles(styles)(AddOrgEmail)
