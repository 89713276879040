import React, { useState, useRef, useContext } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import PublicIcon from "@material-ui/icons/Public"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import SortContext from "../../../../context/SortContext"
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
} from "react-feather"
import Label from "../../../Label"

const supplierOptions = [
  {
    ouid: "all",
    name: "All",
  },
  {
    ouid: "0d624cfd-84c6-42de-9ab8-378af087a705",
    name: "Yong Ming",
  },
]

const batchStatusOptions = [
  {
    id: "all",
    name: "All",
  },
  {
    id: 9,
    name: "Supplier pre-production",
  },
  {
    id: 2,
    name: "In Production (supplier)",
  },

  {
    id: 10,
    name: "Shipped from supplier",
  },
  {
    id: 11,
    name: "Received from supplier",
  },
  {
    id: 12,
    name: "Discrepancy",
  },
  {
    id: 13,
    name: "Cancelled",
  },
]

const productionStatusOptions = [
  {
    id: "all",
    name: "All",
  },
  {
    id: 1,
    name: "Sample needs cust approval",
  },
  {
    id: 3,
    name: "Pre-Production",
  },
  {
    id: 5,
    name: "Mockup needs approval",
  },
  {
    id: 6,
    name: "Revision in progress",
  },
  {
    id: 7,
    name: "Supplier creating sample",
  },
  {
    id: 8,
    name: "Supplier in production",
  },
]

const sortOptions = [
  {
    value: "id|asc",
    label: "ID (lowest first)",
  },
  {
    value: "id|desc",
    label: "ID (highest first)",
  },
  {
    value: "orderSize|desc",
    label: "Order Size (largest first)",
  },
  {
    value: "totalValue|desc",
    label: "Value (highest first)",
  },
  {
    value: "supplier",
    label: "Supplier",
  },
  {
    value: "batch|desc",
    label: "Supplier Batch (latest first)",
  },
  {
    value: "batch|asc",
    label: "Supplier Batch (oldest first)",
  },
]

function getStatusLabel(statusType) {
  const map = {
    in_progress: {
      text: "In Progress",
      color: "warning",
    },
    completed: {
      text: "Completed",
      color: "success",
    },
    overdue: {
      text: "Overdue",
      color: "danger",
    },
  }

  const { text, color } = map[statusType]

  return <Label color={color}>{text}</Label>
}

const useStyles = makeStyles(theme => ({
  root: {},

  table: {
    marginTop: 5,
  },

  supplierField: {
    flexBasis: 200,
  },
  batchStatusField: {
    flexBasis: 200,
  },
  productionStatusField: {
    flexBasis: 200,
  },
  inProgressField: {},

  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0,
  },
  image: {
    height: 68,
    width: 68,
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  name: {
    textDecoration: "none",
    // fontWeight: "bold",
    // fontVariant: "small-caps",
    color: "primary",
    // fontFamily: "sans-serif",
  },
}))

const Results = ({
  className,
  res,
  supplierFilter,
  setSupplierFilter,
  productionStatusFilter,
  setProductionStatusFilter,
  batchStatusFilter,
  setBatchStatusFilter,
  inProgressFilter,
  setInProgressFilter,
  ...rest
}) => {
  const resCount = res.count
  const resPage = res.page
  const resPages = res.resPages
  const orders = res.objects
  const orderId = res.objects.id

  const { state, dispatch } = useContext(SortContext)
  const page = state.page
  const pageSize = state.pageSize
  const searchContext = state.search
  const [searchResults, setSearchResults] = useState([])

  const [searchBar, setSearchBar] = useState(searchContext)
  const classes = useStyles()
  const [selectedOrders, setSelectedOrders] = useState(orders)

  const [query, setQuery] = useState("")
  const [sort, setSort] = useState(sortOptions[0].value)

  const [filters, setFilters] = useState({
    supplier: null,
    batchStatus: null,
    productionStatus: null,
    inProgress: null,
  })

  const orderData = searchResults.length > 0 ? searchResults : res

  const inputEl = useRef()

  const handleSupplierChange = event => {
    event.persist()

    let value = ""

    if (event.target.value !== "all") {
      value = event.target.value
    }
    setSupplierFilter(value)
  }

  const handleBatchStatusChange = event => {
    event.persist()

    let value = null

    if (event.target.value !== "all") {
      value = event.target.value
    }
    setBatchStatusFilter(value)
  }

  const handleProductionStatusChange = event => {
    event.persist()

    let value = null

    if (event.target.value !== "all") {
      value = event.target.value
    }
    setProductionStatusFilter(value)
  }
  const handleInProgressChange = event => {
    event.persist()

    let value = null

    if (event.target.checked) {
      value = true
    }
    setInProgressFilter(value)
  }
  const handleStockChange = event => {
    event.persist()

    let value = null

    if (event.target.checked) {
      value = true
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      inProgress: value,
    }))
  }

  const handleSortChange = event => {
    event.persist()
    setSort(event.target.value)
  }

  const handlePageChange = (event, newPage) => {
    let x = 1
    const pagiNewPage = newPage
    dispatch({ type: "SET_PAGE", payload: pagiNewPage })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
  }
  const handleClearFilters = event => {
    setBatchStatusFilter(null)
    setInProgressFilter(null)
    setProductionStatusFilter(null)
    setSupplierFilter("")
  }
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={10} sm={5}>
              <Paper
                component="form"
                onSubmit={event => {
                  event.preventDefault()
                  handleSearch(event)
                }}
                className={classes.searchField}
              >
                <InputBase
                  fullWidth
                  className={classes.input}
                  placeholder="Search Supplier Orders"
                  inputProps={{ "aria-label": "search Supplier Orders" }}
                  onChange={event => setSearchBar(event.target.value)}
                  value={searchBar}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={handleClearSearchResults}
                >
                  <CancelIcon />
                </IconButton>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Sort By"
                name="sort"
                onChange={handleSortChange}
                select
                SelectProps={{ native: true }}
                value={sort}
                variant="outlined"
              >
                {sortOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>

        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={3}>
              <TextField
                className={classes.supplierField}
                fullWidth
                label="Supplier"
                name="supplier"
                onChange={handleSupplierChange}
                select
                SelectProps={{ native: true }}
                value={supplierFilter}
                variant="outlined"
              >
                {supplierOptions.map(supplierOption => (
                  <option key={supplierOption.ouid} value={supplierOption.ouid}>
                    {supplierOption.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                className={classes.batchStatusField}
                fullWidth
                label="Batch Status"
                name="batchStatus"
                onChange={handleBatchStatusChange}
                select
                SelectProps={{ native: true }}
                value={batchStatusFilter}
                variant="outlined"
              >
                {batchStatusOptions.map(batchStatusOption => (
                  <option
                    key={batchStatusOption.id}
                    value={batchStatusOption.id}
                  >
                    {batchStatusOption.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                className={classes.productionStatusField}
                fullWidth
                label="Production Status"
                name="productionStatus"
                onChange={handleProductionStatusChange}
                select
                SelectProps={{ native: true }}
                value={productionStatusFilter}
                variant="outlined"
              >
                {productionStatusOptions.map(productionStatusOption => (
                  <option
                    key={productionStatusOption.id}
                    value={productionStatusOption.id}
                  >
                    {productionStatusOption.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControlLabel
                className={classes.inProgressField}
                control={
                  <Checkbox
                    checked={inProgressFilter}
                    onChange={handleInProgressChange}
                    name="inProgressFilter"
                  />
                }
                label="In Progress"
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearFilters}
                fullWidth
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Box>
        <PerfectScrollbar>
          <Box minWidth={1200}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Supplier</TableCell>
                  <TableCell>Batch</TableCell>
                  <TableCell>Production Status</TableCell>
                  <TableCell>Invoice</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedOrders.map(order => (
                  <TableRow hover key={order.id}>
                    <TableCell>{order.id}</TableCell>
                    <TableCell>
                      {order.item && order.item.name} <br />
                      {order.item && order.item.category.name}
                    </TableCell>
                    <TableCell>
                      {order.batch && (
                        <Link
                          className={classes.name}
                          variant="subtitle2"
                          color="textPrimary"
                          to={`/manager/supply/suppliers/${order.batch.supplier.ouid}`}
                          state={{ ouid: `${order.batch.supplier.ouid}` }}
                        >
                          {order.batch.supplier.name}
                        </Link>
                      )}
                    </TableCell>
                    {order.batch && (
                      <TableCell>
                        <Link
                          className={classes.name}
                          variant="subtitle2"
                          color="textPrimary"
                          to={`/manager/supply/batches/${order.batch.id}`}
                          state={{ id: `${order.batch.id}` }}
                        >
                          {order.batch.id}
                        </Link>
                      </TableCell>
                    )}

                    <TableCell>
                      {order.production &&
                        order.production.productionStatus.name}
                    </TableCell>

                    <TableCell>{order.invoice && order.invoice.id}</TableCell>

                    <TableCell align="right">
                      <IconButton>
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                      <IconButton>
                        <SvgIcon fontSize="small">
                          <ArrowRightIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={resCount}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array,
}

Results.defaultProps = {
  orders: [],
}

export default Results
