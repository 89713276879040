import React, { useState } from "react"
import clsx from "clsx"
import moment from "moment"
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import GetAppIcon from "@material-ui/icons/GetApp"

const WorkingFileCard = ({ classes, wf }) => {
  const fileUri = wf.workingFileUrl
  const fileName = wf.workingFile

  return (
    <>
      <ListItemIcon>
        <InsertDriveFileIcon />
      </ListItemIcon>
      <ListItemText
        primary={`${wf.workingFile}, v.${wf.version}`}
        secondary={moment(wf.timestamp).format("YYYY-MM-DD")}
      />
      <IconButton aria-label="download">
        <a href={fileUri} download>
          <GetAppIcon />
        </a>
      </IconButton>
    </>
  )
}

const styles = theme => ({
  root: {},
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
})
export default withStyles(styles)(WorkingFileCard)
