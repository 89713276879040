import React, { useState } from "react"
import { Box, Grid, Button, Typography } from "@material-ui/core"
import ItemInOrders from "./ItemInOrders"
import ItemInProposals from "./ItemInProposals"

const Activity = ({ item, className, ...rest }) => {
  const [section, setSection] = useState("orders")
  const sectionSwitch = section => {
    switch (section) {
      case "proposals":
        return <ItemInProposals item={item} />
      case "orders":
        return <ItemInOrders item={item} />
      case "production":
        return <div>Production</div>
      case "delivery":
        return <div>Delivery</div>

      default:
        return <ItemInOrders item={item} />
    }
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Button
            fullWidth
            variant={section === "orders" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("orders")}
          >
            Orders
          </Button>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            fullWidth
            variant={section === "proposals" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("proposals")}
          >
            Proposals
          </Button>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Button
            fullWidth
            variant={section === "production" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("production")}
          >
            Production
          </Button>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Button
            fullWidth
            variant={section === "delivery" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("delivery")}
          >
            Delivery
          </Button>
        </Grid>
      </Grid>
      <Box mt={3}>{sectionSwitch(section)}</Box>
    </>
  )
}

export default Activity
