import React, { useState } from "react"
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

const DocsTags = ({ classes, allTags, tagIds, setTagIds, setTags }) => {
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  const handleTagsChange = event => {
    let newValue = event.target.value
    let newTags = allTags.filter(tag => tag.id === newValue)
    setTagIds(newValue)
    setTags(newTags)
  }

  return (
    <>
      <FormControl color="primary" variant="outlined" fullWidth>
        <InputLabel id="tags-mutiple-checkbox-label">Tags</InputLabel>
        <Select
          labelId="tags-mutiple-checkbox-label"
          id="tags-mutiple-checkbox"
          select
          label="Tags"
          multiple
          value={tagIds}
          onChange={handleTagsChange}
          InputLabelProps={{
            shrink: true,
          }}
          // input={<Input />}
          renderValue={selected =>
            allTags
              .filter(tag => tag.id in tagIds)
              .map(t => t.name)
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          {allTags.map(tag => (
            <MenuItem key={tag.id} value={tag.id}>
              <ListItemText primary={tag.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

const styles = theme => ({
  root: {},
})

export default withStyles(styles)(DocsTags)
