import React, { useState } from "react"
import { Link } from "gatsby"

import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"

import { Avatar, Box, Typography, makeStyles } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
// import { Lightbox } from "react-modal-image"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import Messages from ".."
// function senderSelector(state, senderId) {
//   const { user } = state.account;
//   const { contacts } = state.chat;

//   if (senderId !== user.id) {
//     const contact = contacts.byId[senderId];

//     return {
//       avatar: contact.avatar,
//       name: contact.name,
//       type: 'contact'
//     };
//   }

//   return {
//     avatar: user.avatar,
//     name: 'Me',
//     type: 'user'
//   };
// }

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    height: 32,
    width: 32,
  },
  image: {
    cursor: "pointer",
    height: "auto",
    maxWidth: "100%",
    width: 380,
  },
  myLink: {
    textDecoration: "none",
    color: "#ffffff",
    fontWeight: "bold",
    fontFamily: "sans-serif",
  },
  otherLink: {
    textDecoration: "none",
    color: "#000000",
    fontWeight: "bold",
    fontFamily: "sans-serif",
  },
}))

function Message({ className, message, ...rest }) {
  const classes = useStyles()
  const [openedFile, setOpenedFile] = useState(null)
  const senderId = message.messageFrom.id
  const senderUid = message.messageFrom.person.uid
  //   const senderName =
  //     message.messageFrom.person &&
  //     message.messageFrom.person.firstName +
  //       " " +
  //       message.messgeFrom.person.lastName
  //   const sender = useSelector(state => senderSelector(state, message.senderId))
  const { data, loading, error } = useQuery(ME_QUERY)
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const myId = data.me.id
  //   return (
  //     <div>
  //       {senderId}: {message.message} (My id: {myId})(
  //       {myId === senderId ? "true" : "false"})
  //     </div>
  //   )

  //   const senderType =
  //     message &&
  //     message.messageFrom &&
  //     message.messageFrom.id &&
  //     message.mesageFrom.id === myId
  //       ? "user"
  //       : "nonUser"

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        maxWidth={500}
        // ml={sender.type === "user" ? "auto" : 0}
        ml={myId === senderId ? "auto" : 0}
      >
        {/* <Avatar className={classes.avatar} src={sender.avatar} /> */}
        <Avatar className={classes.avatar} />
        <Box ml={2}>
          <Box
            bgcolor={
              myId === senderId ? "secondary.main" : "background.default"
              //   sender.type === "user" ? "secondary.main" : "background.default"
            }
            color={
              myId === senderId ? "text.secondaryContrast" : "text.primary"
              //   sender.type === "user" ? "secondary.contrastText" : "text.primary"
            }
            py={1}
            px={2}
            borderRadius="borderRadius"
            boxShadow={1}
          >
            <Link
              className={myId === senderId ? classes.myLink : classes.otherLink}
              component={Link}
              to={`/manager/person/${senderUid}`}
              state={{ uid: `${senderUid}` }}
              variant="h6"
            >
              {message.messageFrom.person.firstName}{" "}
              {message.messageFrom.person.lastName}
            </Link>
            <Box mt={1}>
              {message.messageFile ? (
                <Box
                  mt={2}
                  onClick={() => setOpenedFile(message.messageFileUrl)}
                >
                  <img
                    alt="Image in Message"
                    className={classes.image}
                    src={message.messageFileUrl}
                  />
                  <Typography color="inherit" variant="body1">
                    {message.message}
                  </Typography>
                </Box>
              ) : (
                <Typography color="inherit" variant="body1">
                  {message.message}
                </Typography>
              )}
            </Box>
          </Box>
          <Box mt={1} display="flex" justifyContent="flex-end">
            <Typography noWrap color="textSecondary" variant="caption">
              {moment(message.sent).fromNow()}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* {openedFile && (
        <Lightbox large={openedFile} onClose={() => setOpenedFile(null)} />
      )} */}
    </div>
  )
}

Message.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object.isRequired,
}

const ME_QUERY = gql`
  query {
    me {
      id
    }
  }
`
export default Message
