import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import { Link } from "gatsby"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  Share2 as ShareIcon,
  Check as CheckIcon,
  Calendar as CalendarIcon,
  AlertTriangle as AlertIcon,
  DollarSign as DollarSignIcon,
  Send as SendIcon,
  Activity as InProgressIcon,
} from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import MapIcon from "@material-ui/icons/Map"
// import Application from './Application';

const useStyles = makeStyles(theme => ({
  root: {},
  badge: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2),
  },
  badgeIcon: {
    marginRight: theme.spacing(1),
  },
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    color: theme.palette.common.black,
    textDecoration: "none",
  },
}))

function Header({ project, className, ...rest }) {
  const classes = useStyles()
  // const [openApplication, setOpenApplication] = useState(false);

  // const handleApplicationOpen = () => {
  //   setOpenApplication(true);
  // };
  const handleContactPM = () => {
    console.log("contact project manager")
  }
  // const handleApplicationClose = () => {
  //   setOpenApplication(false);
  // };

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/manager"
            component={Link}
            className={classes.link}
          >
            Dashboard
          </Link>
          <Link
            variant="body1"
            color="inherit"
            to="/manager/projects"
            component={Link}
            className={classes.link}
          >
            Projects
          </Link>

          <Typography variant="body1" color="textPrimary">
            {project.title}
          </Typography>
        </Breadcrumbs>
      </Grid>

      <Grid item>
        {/* <Button className={classes.action}>
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <ShareIcon />
          </SvgIcon>
          Share
        </Button> */}
        <Button
          className={classes.action}
          onClick={handleContactPM}
          variant="contained"
          color="secondary"
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <SendIcon />
          </SvgIcon>
          Contact PM
        </Button>
        {/* <Application
          author={project.author}
          onApply={handleApplicationClose}
          onClose={handleApplicationClose}
          open={openApplication}
        /> */}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" color="textPrimary">
          {project.title}
        </Typography>
        <Box
          mx={-2}
          display="flex"
          color="text.secondary"
          alignItems="center"
          flexWrap="wrap"
        >
          <div className={classes.badge}>
            <SvgIcon fontSize="small" className={classes.badgeIcon}>
              {project.completed ? <CheckIcon /> : <InProgressIcon />}
            </SvgIcon>
            <Typography variant="body2" color="inherit" component="span">
              {project.completed ? "Completed" : "In progress"}
            </Typography>
          </div>
          <div className={classes.badge}>
            <SvgIcon fontSize="small" className={classes.badgeIcon}>
              <CalendarIcon />
            </SvgIcon>
            <Typography variant="body2" color="inherit" component="span">
              {`Due ${moment(project.dueDate).fromNow()}`}
            </Typography>
          </div>
          {project.planProject && (
            <div className={classes.badge}>
              <SvgIcon fontSize="small" className={classes.badgeIcon}>
                <MapIcon />
              </SvgIcon>
              <Typography variant="body2" color="inherit" component="span">
                Part of a plan
              </Typography>
            </div>
          )}
          {project.value && (
            <div className={classes.badge}>
              <SvgIcon fontSize="small" className={classes.badgeIcon}>
                <DollarSignIcon />
              </SvgIcon>
              <Typography variant="body2" color="inherit" component="span">
                {project.value ? `Value: ${project.value}` : ""}
              </Typography>
            </div>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  project: PropTypes.object.isRequired,
}

Header.defaultProps = {}

export default Header
