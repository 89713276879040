import React, { useState, useContext } from "react"
import { Box, Button, Checkbox, TextField, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import OrgCreateContext from "../context/OrgCreateContext"

const AddOrgForm = ({ classes }) => {
  const { state, dispatch } = useContext(OrgCreateContext)
  const [name, setName] = useState("")
  const [nickname, setNickname] = useState("")
  const [about, setAbout] = useState("")
  const [isSupplier, setIsSupplier] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleCheckSupplier = event => {
    setIsSupplier(event.target.checked)
  }

  const [handleSubmit, { data, loading, error }] = useMutation(
    NEW_ORG_MUTATION,
    {
      variables: {
        name: name,
        nickname: nickname,
        about: about,
        isSupplier: isSupplier,
      },
      onCompleted: data => {
        setIsSubmitting(false)
        dispatch({ type: "SET_ORG", payload: data.createOrg.org })
        dispatch({ type: "SET_ORG_OUID", payload: data.createOrg.org.ouid })
        dispatch({ type: "SET_ACTIVE_STEP", payload: 1 })
      },
    }
  )

  return (
    <div className={classes.root}>
      <form
        onSubmit={event => {
          event.preventDefault()
          setIsSubmitting(true)
          handleSubmit(event)
        }}
      >
        <TextField
          fullWidth
          label="Name"
          margin="dense"
          size="small"
          name="name"
          onChange={event => setName(event.target.value)}
          type="text"
          value={name}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Nickname"
          margin="dense"
          size="small"
          name="name"
          onChange={event => setNickname(event.target.value)}
          type="text"
          value={nickname}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="About"
          margin="dense"
          size="small"
          name="name"
          onChange={event => setAbout(event.target.value)}
          type="text"
          value={about}
          variant="outlined"
        />

        <Box alignItems="center" display="flex" mt={2} ml={-1}>
          <Checkbox
            checked={isSupplier}
            // name="supplier"
            onChange={handleCheckSupplier}
          />

          <Typography variant="body2" color="textSecondary">
            This organization is a supplier. Check to add to supplier list.
          </Typography>
        </Box>
        <Button
          disabled={isSubmitting}
          fullWidth
          type="submit"
          color="secondary"
          variant="contained"
        >
          Add Org
        </Button>
      </form>
    </div>
  )
}

const styles = theme => ({
  root: {},
})

const NEW_ORG_MUTATION = gql`
  mutation (
    $name: String!
    $nickname: String
    $about: String
    $isSupplier: Boolean
  ) {
    createOrg(
      name: $name
      nickname: $nickname
      about: $about
      isSupplier: $isSupplier
    ) {
      org {
        id
        ouid
        name
        nickname
        about
        isSupplier
      }
    }
  }
`
export default withStyles(styles)(AddOrgForm)
