import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import PreregUserForm from "./PreregUserForm"
import clsx from "clsx"
import { Box, Button, Grid, Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {},
  infoBox: {
    padding: theme.spacing(3, 0, 2, 2),
  },
}))
const PreregItemCreateForm = ({ item, person, setActiveStep, className }) => {
  const classes = useStyles()
  const itemId = item.id

  const [ownerIsPrereg, setOwnerIsPrereg] = useState(false)
  const [preRegUser, setPreRegUser] = useState({})
  const [preregOwnerId, setPreregOwnerId] = useState("")

  const [createPreregPrivateItem] = useMutation(CREATE_PREREG_PRIVATE_ITEM, {
    variables: {
      privateitemId: itemId,
      preregOwnerId: preRegUser.preregId,
      isConverted: false,
    },
    onCompleted: data => {
      setActiveStep(3)
    },
  })
  const handleSubmit = (event, createPreregPrivateItem) => {
    event.preventDefault()

    // setPreregOwnerId(preRegUser.preregId)
    createPreregPrivateItem()
  }
  return (
    <>
      {ownerIsPrereg ? (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3">
                Prereg Private Item: Confirm and continue
              </Typography>
              <Box className={classes.infoBox}>
                <Typography variant="body1">
                  <strong>Item name:</strong> {item.name}
                </Typography>
                <Typography variant="body1">
                  <strong>Item owner:</strong> {person.firstName}{" "}
                  {person.lastName}
                </Typography>
                <Typography variant="body1">
                  <strong>Pre-registration email:</strong>{" "}
                  {preRegUser.preregEmail}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Button
                color="primary"
                fullWidth
                onClick={createPreregPrivateItem}
                variant="contained"
              >
                Set Prereg Private Item
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <PreregUserForm
          setOwnerIsPrereg={setOwnerIsPrereg}
          person={person}
          setPreRegUser={setPreRegUser}
        />
      )}
    </>
  )
}
const CREATE_PREREG_PRIVATE_ITEM = gql`
  mutation (
    $privateitemId: Int!
    $preregOwnerId: String!
    $isConverted: Boolean!
  ) {
    createPreregPrivateItem(
      privateItemId: $privateitemId
      preregOwnerId: $preregOwnerId
      isConverted: $isConverted
    ) {
      preregPrivateItem {
        privateItem {
          item {
            id
            name
          }
        }
        isConverted
        preregOwner {
          preregId
        }
      }
    }
  }
`
export default PreregItemCreateForm
