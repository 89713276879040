import React, { useState, useContext } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"

import { Box, Button, Checkbox, TextField, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import OrgCreateContext from "../../context/OrgCreateContext"

const AddPersonForm = ({ classes }) => {
  const { state, dispatch } = useContext(OrgCreateContext)
  const org = state.org
  const ouid = state.ouid
  const orgPeople = state.orgPeople
  const activeStep = state.newPersonActiveStep
  const selectedPerson = state.selectedPerson
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [personAdded, setPersonAdded] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [middleName, setMiddleName] = useState("")
  const [suffix, setSuffix] = useState("")
  const [nickname, setNickname] = useState("")
  const [title, setTitle] = useState("")
  const [supplier, setSupplier] = useState(false)
  const [about, setAbout] = useState("")
  const [emailForm, setEmailform] = useState(false)
  const [personUid, setPersonUid] = useState("")

  const handleCheckSupplier = event => {
    setSupplier(event.target.checked)
  }

  const handleSubmit = (event, createPerson) => {
    event.preventDefault()
    setIsSubmitting(true)
    createPerson()
  }

  const handleNext = () => {
    let nextStep = activeStep + 1
    dispatch({ type: "SET_NEW_PERSON_ACTIVE_STEP", payload: nextStep })
  }

  const [createPerson, { loading, error }] = useMutation(ADD_PERSON_MUTATION, {
    variables: {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      suffix: suffix,
      nickname: nickname,
      isSupplier: supplier,
      title: title,
      about: about,
      userCanUpdate: false,
    },
    onCompleted: data => {
      dispatch({
        type: "SET_SELECTED_PERSON",
        payload: data.createPerson.person,
      })
      setPersonUid(data.createPerson.person.uid)
      setPersonAdded(true)
      setIsSubmitting(false)
    },
  })

  const [confirmPerson] = useMutation(ADD_ORG_PERSON_MUTATION, {
    variables: {
      ouid: ouid,
      personUid: personUid,
    },
    onCompleted: data => {
      setIsSubmitting(false)
      setPersonUid("")
      setFirstName("")
      setLastName("")
      setMiddleName("")
      setSuffix("")
      setNickname("")
      setTitle("")
      setSupplier(null)
      setAbout("")
      handleNext()
    },
  })

  const handleAddToOrg = event => {
    setIsSubmitting(true)
    let newGroup = [...orgPeople, selectedPerson]
    dispatch({ type: "SET_ORG_PEOPLE", payload: newGroup })
    confirmPerson()
  }
  return (
    <>
      {personAdded ? (
        <>
          <Typography gutterBottom variant="h4" color="textPrimary">
            {firstName} {lastName} was added to your contacts.{" "}
          </Typography>

          <Button
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={handleAddToOrg}
          >
            Add {firstName} {lastName} to{" "}
            {org.name ? org.name : "this organization"}
          </Button>

          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            className={classes.button}
            onClick={dispatch(`/manager/orgs/${ouid}`)}
          >
            Cancel and Go to Org
          </Button>
        </>
      ) : (
        <>
          <Typography gutterBottom variant="h4" color="textPrimary">
            Add a new person to your Contacts
          </Typography>
          <form
            onSubmit={event => handleSubmit(event, createPerson)}
            className={classes.root}
          >
            <TextField
              fullWidth
              label="First Name"
              margin="dense"
              size="small"
              name="firstName"
              onChange={event => setFirstName(event.target.value)}
              type="firstName"
              value={firstName}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Middle Name"
              margin="dense"
              size="small"
              name="middleName"
              onChange={event => setMiddleName(event.target.value)}
              type="middleName"
              value={middleName}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Last Name"
              margin="dense"
              size="small"
              name="lastName"
              onChange={event => setLastName(event.target.value)}
              type="lastName"
              value={lastName}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Suffix"
              margin="dense"
              size="small"
              name="suffix"
              onChange={event => setSuffix(event.target.value)}
              type="suffix"
              value={suffix}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Title"
              margin="dense"
              size="small"
              name="Title"
              onChange={event => setTitle(event.target.value)}
              type="title"
              value={title}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Nickname"
              margin="dense"
              size="small"
              name="Nickname"
              onChange={event => setNickname(event.target.value)}
              type="nickname"
              value={nickname}
              variant="outlined"
            />
            <TextField
              fullWidth
              multiline
              rowsMax={4}
              label="About"
              margin="dense"
              size="small"
              name="About"
              onChange={event => setAbout(event.target.value)}
              type="about"
              value={about}
              variant="outlined"
            />

            <Box alignItems="center" display="flex" mt={2} ml={-1}>
              <Checkbox checked={supplier} onChange={handleCheckSupplier} />

              <Typography variant="body2" color="textSecondary">
                This person is a supplier. Check to add to supplier list.
              </Typography>
            </Box>

            <Box mt={2}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Add Person
              </Button>
            </Box>
          </form>
        </>
      )}
    </>
  )
}

const ADD_PERSON_MUTATION = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $middleName: String
    $suffix: String
    $nickname: String
    $title: String
    $about: String
    $isSupplier: Boolean
    $userCanUpdate: Boolean
  ) {
    createPerson(
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      suffix: $suffix
      userCanUpdate: $userCanUpdate
      isSupplier: $isSupplier

      nickname: $nickname
      title: $title
      about: $about
    ) {
      person {
        firstName
        lastName
        middleName
        suffix
        nickname
        title
        about
        userCanUpdate
        isSupplier
        uid
      }
    }
  }
`
const ADD_ORG_PERSON_MUTATION = gql`
  mutation ($ouid: String!, $personUid: String!) {
    addOrgPerson(ouid: $ouid, personUid: $personUid) {
      org {
        people {
          uid
          firstName
          lastName
        }
      }
    }
  }
`

AddPersonForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
}

AddPersonForm.default = {
  onSubmitSuccess: () => {},
}

const styles = theme => ({
  root: {},
  button: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})
export default withStyles(styles)(AddPersonForm)
