import React, { useState, useContext, useEffect } from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import UserContext from "../../context/UserContext"
import { navigate } from "gatsby"
import Loading from "../Utils/Loading"

const Verify = ({ isLoggedIn, setIsLoggedIn, setUser, user }) => {
  const { state, dispatch } = useContext(UserContext)
  const [verified, setVerified] = useState(false)

  const { data, loading, error } = useQuery(ME_QUERY, {
    onCompleted: data => {
      const staff = data.me.staff
      const manager = data.me.manager
      console.log(staff ? "staff" : "not staff")
      console.log(manager ? "manager" : "not manager")
      dispatch({ type: "SET_VIRGINIA", payload: data.me.isVerified })
      dispatch({ type: "SET_WISCONSIN", payload: data.me.staff })
      dispatch({ type: "SET_MISSOURI", payload: data.me.manager })
      setUser(data.me)
      console.log(data)

      if (data.me.isVerified) {
        setVerified(true)
        if (staff) {
          dispatch({ type: "SET_ILLINOIS", payload: true })
          setIsLoggedIn(true)
        }
        if (!staff && !manager) {
          setIsLoggedIn(false)
          dispatch({ type: "SET_ILLINOIS", payload: false })
        }
      }
    },
  })

  useEffect(() => {
    !isLoggedIn && navigate("/")
  }, [isLoggedIn])

  if (loading) return <Loading />
  if (error) return <>{JSON.stringify(error)}</>
  return <div></div>
}

const ME_QUERY = gql`
  query {
    me {
      isVerified
      staff
      manager
      person {
        firstName
        lastName
        uid
        profileImageUrl
      }
    }
  }
`
export default Verify
