import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"

const PrivateItemUserSearch = ({
  setPickPerson,
  setIsPrereg,
  setFoundUser,
  personUid,
}) => {
  const { loading, error, data } = useQuery(FIND_USER_ACCOUNT_QUERY, {
    variables: {
      uid: personUid,
    },
  })
  if (loading) return <Loading />
  if (error) return handlePrereg()
  if (data === undefined) return handlePrereg()
  if (data === null) {
    return handlePrereg()
  }
  return handleFoundUser()
}
const handlePrereg = (setIsPrereg, setPickPerson) => {
  setIsPrereg(true)
  setPickPerson(true)
}

const handleFoundUser = (setIsPrereg, setPickPerson) => {
  setIsPrereg(false)
  setPickPerson(true)
}

const FIND_USER_ACCOUNT_QUERY = gql`
  query ($uid: String!) {
    userByUid(uid: $uid) {
      id
      email
    }
  }
`
export default PrivateItemUserSearch
