/* eslint-disable max-len */
import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import SortContext from "../../../../context/SortContext"
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  SvgIcon,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  makeStyles,
} from "@material-ui/core"
import {
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
} from "react-feather"
import getInitials from "../../../../utils/getInitials"

const tabs = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "registered",
    label: "Registered Users",
  },
  {
    value: "prereg",
    label: "Pre-Registered",
  },
  // {
  //   value: "recent",
  //   label: "Recent Contacts",
  // },
]

// const sortOptions = [
//   {
//     value: "updatedAt|desc",
//     label: "Last update (newest first)",
//   },
//   {
//     value: "updatedAt|asc",
//     label: "Last update (oldest first)",
//   },
//   {
//     value: "orders|desc",
//     label: "Total orders (high to low)",
//   },
//   {
//     value: "orders|asc",
//     label: "Total orders (low to high)",
//   },
// ]

function applyFilters(people, query, filters) {
  return people.filter(person => {
    let matches = true

    if (query) {
      const properties = ["email", "name"]
      let containsQuery = false

      properties.forEach(property => {
        if (person[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true
        }
      })

      if (!containsQuery) {
        matches = false
      }
    }

    Object.keys(filters).forEach(key => {
      const value = filters[key]

      if (value && person[key] !== value) {
        matches = false
      }
    })

    return matches
  })
}

function applyPagination(people, page, limit) {
  return people.slice(page * limit, page * limit + limit)
}

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1
//   }

//   if (b[orderBy] > a[orderBy]) {
//     return 1
//   }

//   return 0
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy)
// }

// function applySort(customers, sort) {
//   const [orderBy, order] = sort.split("|")
//   const comparator = getComparator(order, orderBy)
//   const stabilizedThis = customers.map((el, index) => [el, index])

//   stabilizedThis.sort((a, b) => {
//     // eslint-disable-next-line no-shadow
//     const order = comparator(a[0], b[0])

//     if (order !== 0) return order

//     return a[1] - b[1]
//   })

//   return stabilizedThis.map(el => el[0])
// }

const useStyles = makeStyles(theme => ({
  root: {},
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: "relative",
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: "absolute",
    width: "100%",
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  table: {
    marginTop: 5,
  },
  avatar: {
    height: 30,
    width: 30,
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondaryContrast,
    backgroundColor: theme.palette.secondary.main,
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  scrollbar: {
    useBothWheelAxes: false,
    suppressScrollY: true,
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

function Results({
  className,
  res,
  preregFilter,
  setPreregFilter,
  userFilter,
  setUserFilter,
  ...rest
}) {
  const { state, dispatch } = useContext(SortContext)
  const classes = useStyles()
  const resCount = res.count
  const resPage = res.page
  const resPages = res.pages
  const people = res.objects

  const page = state.page
  const pageSize = state.pageSize
  const searchContext = state.search

  const [currentTab, setCurrentTab] = useState("all")
  const [selectedPeople, setSelectedPeople] = useState(people)

  const [newPage, setNewPage] = useState(1)

  const [query, setQuery] = useState("")
  // const [sort, setSort] = useState(sortOptions[0].value)
  const [searchBar, setSearchBar] = useState(searchContext)

  const [filters, setFilters] = useState({
    registered: null,
    prereg: null,
  })
  // const { count, page, rowsPerPage, onChangePage } = props;

  // const handleFirstPageButtonClick = (event) => {
  //   dispatch({ type: 'SET_PAGE', payload:0 });
  // };

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      registered: null,
      prereg: null,
    }
    if (value === "all") {
      setUserFilter(false)
      setPreregFilter(false)
      setCurrentTab("all")
    }
    if (value !== "all") {
      if (value === "registered") {
        setUserFilter(true)
        setPreregFilter(false)
        setCurrentTab("registered")
      }
      if (value === "prereg") {
        setPreregFilter(true)
        setUserFilter(false)
        setCurrentTab("prereg")
      }
      // updatedFilters[value] = true
    }

    setFilters(updatedFilters)
    // setSelectedPeople([])
    setCurrentTab(value)
  }

  useEffect(() => {
    userFilter && setCurrentTab("registered")
    preregFilter && setCurrentTab("prereg")
    !userFilter && !preregFilter && setCurrentTab("all")
  }, [userFilter])

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
  }
  const handleClearFilters = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
    dispatch({ type: "SET_PAGE", payload: 0 })
    dispatch({ type: "SET_PAGE_SIZE", payload: 10 })
    setUserFilter(false)
    setPreregFilter(false)
    setCurrentTab("all")
  }
  // const handleQueryChange = event => {
  //   event.persist()filter
  //   setQuery(event.target.value)
  // }

  // const handleSortChange = event => {
  //   event.persist()
  //   setSort(event.target.value)
  // }

  // const handleSelectAllPeople = (event, personId) => {
  //   setSelectedPeople(
  //     event.target.checked ? people.map(person => personId) : []
  //   )
  // }

  // const handleSelectOnePerson = (event, personId) => {
  //   if (!selectedPeople.includes(personId)) {
  //     setSelectedPeople(prevSelected => [...prevSelected, personId])
  //   } else {
  //     setSelectedPeople(prevSelected =>
  //       prevSelected.filter(id => id !== personId)
  //     )
  //   }
  // }

  const handlePageChange = (event, newPage) => {
    let x = 1
    const pagiNewPage = newPage
    dispatch({ type: "SET_PAGE", payload: pagiNewPage })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  // Usually query is done on backend with indexing solutions
  const filteredPeople = applyFilters(people, query, filters)
  // const sortedPeople = applySort(filteredPeople, sort)
  const paginatedCustomers = applyPagination(people, state.page, state.pageSize)
  const enableBulkOperations = selectedPeople.length > 0
  //   selectedCustomers.length > 0 && selectedCustomers.length < customers.length
  // const selectedAllCustomers = selectedCustomers.length === customers.length

  return (
    <Card
      className={clsx(classes.root, classes.roundedCard, className)}
      {...rest}
    >
      <Tabs
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="secondary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map(tab => (
          <Tab key={tab.value} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
      <Divider />

      <Box p={2} minHeight={56} display="flex" alignItems="center">
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item xs={10} sm={5}>
            <Paper
              component="form"
              onSubmit={event => {
                event.preventDefault()
                handleSearch(event)
              }}
              className={classes.searchField}
            >
              <InputBase
                fullWidth
                className={classes.input}
                placeholder="Search People"
                inputProps={{ "aria-label": "search people" }}
                onChange={event => setSearchBar(event.target.value)}
                value={searchBar}
              />
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                color="primary"
                className={classes.iconButton}
                aria-label="clear"
                onClick={handleClearSearchResults}
              >
                <CancelIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid xs={10} sm={5}>
            <Button
              onClick={handleClearFilters}
              variant="outlined"
              color="secondary"
            >
              Clear Filters
            </Button>
          </Grid>
          {/* <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Sort By"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={sort}
              variant="outlined"
            >
              {sortOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid> */}
        </Grid>
      </Box>
      {/* {enableBulkOperations && (
        <div className={classes.bulkOperations}>
          <div className={classes.bulkActions}>
            <Checkbox
            // checked={selectedAllCustomers}
            // indeterminate={selectedSomeCustomers}
            // onChange={handleSelectAllCustomers}
            />
            <Button variant="outlined" className={classes.bulkAction}>
              Delete
            </Button>
            <Button variant="outlined" className={classes.bulkAction}>
              Edit
            </Button>
          </div>
        </div>
      )} */}
      <PerfectScrollbar className={classes.scrollbar}>
        <Box minWidth={700}>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                  // checked={selectedAllCustomers}
                  // indeterminate={selectedSomeCustomers}
                  // onChange={handleSelectAllCustomers}
                  />
                </TableCell> */}
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>

                <TableCell>User</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedPeople.map(person => {
                const firstEmail =
                  person.emailPerson &&
                  person.emailPerson.length > 0 &&
                  person.emailPerson[0].emailAddress
                const moreEmails =
                  person.emailPerson &&
                  person.emailPerson.length > 0 &&
                  person.emailPerson.length - 1
                const firstPhone =
                  person.phonePerson &&
                  person.phonePerson.length > 0 &&
                  person.phonePerson[0].phoneNumber
                const morePhones =
                  person.phonePerson &&
                  person.phonePerson.length > 0 &&
                  person.phonePerson.length - 1
                return (
                  <TableRow
                    hover
                    key={person.uid}
                    // selected={isCustomerSelected}
                  >
                    {/* <TableCell padding="checkbox">
                    <Checkbox
                    // checked={isCustomerSelected}
                    // onChange={event =>
                    //   handleSelectOneCustomer(event, customer.objects.id)
                    // }
                    // value={isCustomerSelected}
                    />
                  </TableCell> */}
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatar}>
                          {getInitials(person.lastName)}
                        </Avatar>
                        <div>
                          <Link
                            color="inherit"
                            to={`./${person.uid}`}
                            className={classes.link}
                            state={{ uid: `${person.uid}` }}
                          >
                            {person.firstName} {person.lastName}
                          </Link>
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {firstEmail} {moreEmails ? `+ ${moreEmails} more` : ""}
                    </TableCell>
                    {/* <TableCell>
                      {person.addressPerson === undefined
                        ? ""
                        : `${person.addressPerson[0]}`}
                    </TableCell> */}
                    {/* <TableCell>{person.orders}</TableCell> */}
                    <TableCell>
                      {firstPhone} {morePhones ? `+ ${morePhones} more` : ""}
                    </TableCell>

                    <TableCell>
                      {person.user && person.user.isVerified && "Verified User"}
                      {person.user && person.user.staff && " - Staff"}
                      {person.user && person.user.manager && " - Manager"}
                      {person.user &&
                        person.user.partner &&
                        !person.user.staff &&
                        " - Partner"}
                      {person.user &&
                        !person.user.isVerified &&
                        "Unverified User"}
                      {person.preregPerson && "Pre-Registered"}
                      {/* === null
                        ? "Not User"
                        : `User ${person.user.id}`} */}
                    </TableCell>
                    <TableCell align="right">
                      {/* <IconButton to="/app/management/people/1/edit">
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton> */}
                      <IconButton
                        component={Link}
                        to={`/manager/people/${person.uid}`}
                        state={{ uid: `${person.uid}` }}
                      >
                        <SvgIcon fontSize="small">
                          <ArrowRightIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>

      <TablePagination
        component="div"
        count={resCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Card>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  people: PropTypes.array,
}

// Results.defaultProps = {
//   people [],
// }
// TablePaginationActions.propTypes = {
//   count: PropTypes.number.isRequired,
//   onChangePage: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
// };
export default Results
