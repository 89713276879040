import React from "react"
import { Link } from "gatsby"

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core"

import SearchPersonAdd from "./SearchPersonAdd"

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    paddingBottom: 40,
    paddingTop: 80,
  },
}))

function SearchPersonView({ inquiry, setIsPerson, setOpenSearchPerson }) {
  const classes = useStyles()

  const handleSubmitSuccess = () => {
    console.log("success")
  }

  return (
    <Container maxWidth="sm">
      <Card elevation={0}>
        <CardContent>
          <Box mt={0}>
            <SearchPersonAdd
              inquiry={inquiry}
              setOpenSearchPerson={setOpenSearchPerson}
              setIsPerson={setIsPerson}
            />
          </Box>
        </CardContent>
      </Card>
    </Container>
  )
}

export default SearchPersonView
