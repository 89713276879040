import React, { useState, Component, Fragment } from "react"

import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import clsx from "clsx"
import PropTypes from "prop-types"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import EditIcon from "@material-ui/icons/Edit"
import DoneIcon from "@material-ui/icons/Done"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import PhoneIcon from "@material-ui/icons/Phone"
import NumberFormat from "react-number-format"

import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"

import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    margin: 10,
  },
  margin: {
    margin: 2,
  },
}))

const AddPhoneForm = ({
  uid,
  inquiry,
  className,
  activeStep,
  setActiveStep,
  ...rest
}) => {
  const classes = useStyles()
  const fName = inquiry.inquiryPerson.firstName
  const phonePerson = inquiry.inquiryPhone

  const [phoneNumber, setPhoneNumber] = useState("")
  const [emailNumber, setEmailNumber] = useState("")
  const [edit, setEdit] = useState(false)
  const [selPhone, setSelPhone] = useState([])
  const [confirm, setConfirm] = useState(false)

  const [value, setValue] = useState("")
  const [newPhone, setNewPhone] = useState("")

  // const handleSubmit = (event, addPersonPhone) => {
  //     event.preventDefault();
  //     addPersonPhone()
  //   }

  function phoneAdded(phoneVal) {
    return selPhone.some(function (el) {
      return el.phoneVal === phoneVal
    })
  }

  const handleFormPhone = () => {
    console.log(newPhone)
    addPersonPhone({
      variables: { uid: uid, phoneNumber: newPhone, phoneTypeId: 1 },
    })
    setConfirm(true)
  }

  const handlePhoneForm = phoneValue => {
    console.log(phoneValue)
  }

  const handleAddPhone = phoneVal => {
    // console.log(phoneVal)
    const phone = Object.values(phoneVal).toString()
    addPersonPhone({
      variables: { uid: uid, phoneNumber: phone, phoneTypeId: 1 },
    })
    setSelPhone(prevSelected => [...prevSelected, phoneVal])
  }

  const [addPersonPhone] = useMutation(ADD_PERSON_PHONE_MUTATION, {
    onCompleted: (response, errors) => {
      console.log(phoneNumber)
      console.log("completed")
    },
  })
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }
  return (
    <>
      <Box>
        {Object.entries(phonePerson).length === 0 ? (
          <Box>
            <Typography>
              No phone numbers were included with this inquiry. Add one now or
              skip to the next step.
            </Typography>
            {confirm ? (
              <Box>
                <Chip icon={<DoneIcon />} label={newPhone} variant="outlined" />
              </Box>
            ) : (
              <div className={classes.root}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <InputLabel htmlFor="formatted-text-mask-input">
                      New phone number:
                    </InputLabel>
                    <PhoneInput
                      defaultCountry="US"
                      value={newPhone}
                      onChange={setNewPhone}
                    />
                  </Grid>

                  <Grid item>
                    <IconButton
                      size="small"
                      type="submit"
                      aria-label="add phone"
                      onClick={() => handleFormPhone()}
                      className={classes.margin}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            )}
          </Box>
        ) : (
          <Box>
            <Typography variant="h4">
              Click the number to confirm {fName}'s phone number or add a new
              one.
            </Typography>
            {phonePerson.map(p => {
              const phoneVal = p.phoneNumber
              return (
                <Fragment key={p.id}>
                  <Box key={p.id} mt={2} mb={2}>
                    {phoneAdded(phoneVal) ? (
                      <Box>
                        <Chip
                          icon={<DoneIcon />}
                          label={`${p.phoneNumber}`}
                          variant="outlined"
                        />
                      </Box>
                    ) : (
                      <Box>
                        <Chip
                          color="primary"
                          value={phoneVal}
                          icon={<PhoneIcon />}
                          label={`${p.phoneNumber}`}
                          onClick={e => handleAddPhone({ phoneVal })}
                        />
                      </Box>
                    )}
                  </Box>
                </Fragment>
              )
            })}
            {edit ? (
              ""
            ) : (
              <Box>
                <Button
                  color="secondary"
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={() => setEdit(true)}
                >
                  Add a new Phone
                </Button>
              </Box>
            )}
          </Box>
        )}
        <Box>
          {edit ? (
            <Box>
              {confirm ? (
                <Box>
                  <Chip
                    icon={<DoneIcon />}
                    label={newPhone}
                    variant="outlined"
                  />
                </Box>
              ) : (
                <div className={classes.root}>
                  <Grid container spacing={1} alignItems="flex-end">
                    <Grid item>
                      <InputLabel htmlFor="formatted-text-mask-input">
                        New phone number:
                      </InputLabel>
                      <PhoneInput
                        defaultCountry="US"
                        value={newPhone}
                        onChange={setNewPhone}
                      />
                    </Grid>

                    <Grid item>
                      <IconButton
                        size="small"
                        type="submit"
                        aria-label="add phone"
                        onClick={() => handleFormPhone()}
                        className={classes.margin}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
      <Box mt={2}>
        <Button
          color="primary"
          fullWidth
          size="large"
          variant="contained"
          onClick={handleNext}
        >
          {inquiry.samplesRequested ||
          (inquiry.inquiryAddress && inquiry.inquiryAddress.uid)
            ? "Next: Address"
            : "Complete Entry"}
        </Button>
      </Box>
    </>
  )
}

const ADD_PERSON_PHONE_MUTATION = gql`
  mutation ($uid: String, $phoneNumber: String, $phoneTypeId: Int) {
    createPhoneNumberPerson(
      uid: $uid
      phoneNumber: $phoneNumber
      phoneTypeId: $phoneTypeId
    ) {
      phoneNumber {
        id
        phoneNumber
        phoneType {
          id
          phoneType
        }
        person {
          uid
          firstName
          lastName
        }
      }
    }
  }
`
AddPhoneForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
}

AddPhoneForm.default = {
  onSubmitSuccess: () => {},
}

export default AddPhoneForm
