import React, { useState, useContext } from "react"
import clsx from "clsx"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  TextField,
  FormControl,
} from "@material-ui/core"
import { Edit as EditIcon, XCircle as DeleteIcon } from "react-feather"
import { Link } from "gatsby"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import SortContext from "../../../../context/SortContext"
import useDidMountEffect from "../../../Global/hooks/useDidMountEffect"
// import AddOrderItem from "./AddOrderItem"

const useStyles = makeStyles(() => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
}))

const BatchItems = ({ batch, className, ...rest }) => {
  const classes = useStyles()

  const orderItems = batch.orderItemBatch

  //   useDidMountEffect(() => {
  //     console.log(updatingPricing)
  //     setUpdatingQty(updatingPricing.split(",")[0])
  //     setUpdatingPriceEach(updatingPricing.split(",")[1])
  //   }, [updatingPricing])

  //   useDidMountEffect(() => {
  //     setOrderItemId(updatingItem[0])
  //     setUpdatingItemId(updatingItem[1])
  //   }, [updatingItem])

  //   const [handleDelete] = useMutation(DELETE_ORDER_ITEM_MUTATION, {
  //     variables: {
  //       orderItemId: deletingItem,
  //     },
  //     onCompleted: (data, errors) => {
  //       const index = items.findIndex(orderItem => orderItem.id == deletingItem)
  //       const newList = [...items.slice(0, index), ...items.slice(index + 1)]
  //       setItems(newList)
  //     },
  //   })

  //   const [updateOrderItem] = useMutation(UPDATE_ORDER_ITEM_MUTATION, {
  //     variables: {

  //     },

  //     onCompleted: (data, error) => {

  //     },
  //   })

  //   const { data, loading, error } = useQuery(ORDER_ITEMS_QUERY, {
  //     variables: {

  //     },
  //     onCompleted: (data, response, errors) => {

  //   })

  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }
  function calcTotal(a, b) {
    //Return the sum
    return (a * b).toFixed(2)
  }

  //   const handleUpdate = event => {
  //     event.preventDefault()
  //     updateOrderItem()
  //   }

  //   if (loading) return <Loading />
  //   if (error) return <Error />
  //   if (data == undefined) return ""
  //   if (data == null) {
  //     return ""
  //   }

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title="Batch items" />
        <Divider />
        {/* <>
          {addBatchItem || updatingBatch ? (
            ""
          ) : (
            <CardContent>
              <Button
                size="small"
                variant="contained"
                onClick={() => setAddBatchItem(true)}
              >
                Add to Batch
              </Button>
            </CardContent>
          )}
        </>
        <Divider /> */}
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order Item Id</TableCell>
                  <TableCell>Item Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Business Unit</TableCell>

                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {orderItems.map(i => (
                  <TableRow key={i.id}>
                    <TableCell>
                      {i.id}
                      {/* <Link
                        to={`/manager/supply/${i.item.id}`}
                        state={{ id: `${i.item.id}` }}
                      >
                     {i.id}
                      </Link> */}
                    </TableCell>

                    <TableCell>{i.item.id}</TableCell>
                    <TableCell>{i.item.name}</TableCell>

                    <TableCell>{i.item.category.name}</TableCell>
                    <TableCell>{i.item.businessUnit.name}</TableCell>

                    {/* <TableCell align="right">
                      {deletingItem === `${i.id}` ? (
                        <>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={handleDelete}
                          >
                            Remove
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => setDeletingItem("")}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                       
               
                            <>
                             
                              <IconButton
                                onClick={() => setDeletingItem(`${i.id}`)}
                              >
                                <SvgIcon fontSize="small">
                                  <DeleteIcon />
                                </SvgIcon>
                              </IconButton>
                            </>
                          
                  
                      )}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

// const ORDER_ITEMS_QUERY = gql``
// const DELETE_ORDER_ITEM_MUTATION = gql``
// const UPDATE_ORDER_ITEM_MUTATION = gql``
export default BatchItems
