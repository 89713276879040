import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { makeStyles } from "@material-ui/core/styles"
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import UpdateTask from "./UpdateTask"
import moment from "moment"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}))

const TaskList = ({
  projectTasks,
  completedTasks,
  setCompletedTasks,
  incompleteTasks,
  setIncompleteTasks,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const [edit, setEdit] = useState(false)
  const [editTaskId, setEditTaskId] = useState({})
  const [checkedTasks, setCheckedTasks] = useState([])
  const [completedDate, setCompletedDate] = useState("")
  const [minutesReported, setMinutesReported] = useState(0)
  const [selectedTaskId, setSelectedTaskId] = useState("")
  const [submitting, setSubmitting] = useState(false)

  function handleUpdate(todo) {
    setEdit(true)
    setEditTaskId(todo)
  }
  const handleCheck = taskId => {
    if (checkedTasks.includes(taskId)) {
      let filteredArray = checkedTasks.filter(ct => ct != taskId)
      setCheckedTasks(filteredArray)
    } else setCheckedTasks([...checkedTasks, taskId])
  }
  const handleComplete = taskId => {
    // setSelectedTaskId(taskId)
    let filteredTasks = incompleteTasks.filter(it => it.id != taskId)
    let compTask = projectTasks.find(pt => pt.id === taskId)
    setIncompleteTasks(filteredTasks)
    setCompletedTasks([...completedTasks, compTask])
    setSubmitting(true)
    handleCompleteTask()
  }
  const [handleCompleteTask, { loading, error }] = useMutation(
    COMPLETE_TASK_MUTATION,
    {
      variables: {
        taskId: selectedTaskId,
        minutesReported: minutesReported,
        completedDate: completedDate,
        taskCompleted: true,
      },
      onCompleted: data => {
        setSelectedTaskId(null)
        setMinutesReported(null)
        setCompletedDate(null)
        setSubmitting(false)
        console.log("completed")
      },
    }
  )
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <div>
      {incompleteTasks.map(todo => {
        const taskId = todo.id
        if (edit && editTaskId === taskId)
          return (
            <UpdateTask
              todo={todo}
              edit={edit}
              setEdit={setEdit}
              incompleteTasks={incompleteTasks}
              setIncompleteTasks={setIncompleteTasks}
            />
          )
        return (
          <Accordion key={todo.id} id={todo.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="task-details"
              id="task-details-header"
            >
              <FormControlLabel
                aria-label="Complete"
                onClick={event => event.stopPropagation()}
                onFocus={event => event.stopPropagation()}
                control={
                  <Checkbox
                    onChange={() => handleCheck(taskId)}
                    checked={checkedTasks.includes(taskId)}
                    checkedIcon={<CheckBoxIcon />}
                  />
                }
                label={todo.task.title}
                //
              />
            </AccordionSummary>
            {checkedTasks.includes(taskId) && (
              <AccordionDetails>
                <form
                  onSubmit={event => {
                    event.preventDefault()
                    handleComplete(taskId)
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        id="datetime-local-completed"
                        label="Completed Date"
                        type="datetime-local"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={completedDate}
                        onChange={event => setCompletedDate(event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.field}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        fullWidth
                        label="Minutes to Complete (reported)"
                        value={minutesReported}
                        variant="outlined"
                        className={classes.field}
                        size="small"
                        onChange={event =>
                          setMinutesReported(event.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={() => setSelectedTaskId(parseInt(taskId))}
                        disabled={
                          !completedDate || !minutesReported || submitting
                        }
                      >
                        Complete
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </AccordionDetails>
            )}
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography color="textSecondary">
                    {todo.task.note ? todo.task.note : ""}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Due:{" "}
                    {todo.task.dueDate
                      ? moment(todo.task.dueDate).format("MMM DD, YYYY, h:mm a")
                      : "No date selected"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Priority:{" "}
                    {todo.task.priority ? todo.task.priority : "None selected"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Created:{" "}
                    {moment(todo.task.createdDate).format(
                      "MMM DD, YYYY, h:mm a"
                    )}{" "}
                    {todo.task.createdBy
                      ? `by ${todo.task.createdBy.person.firstName} 
                        ${todo.task.createdBy.person.lastName}`
                      : ""}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Time estimate:{" "}
                    {todo.task.minutesEstimate
                      ? `${todo.task.minutesEstimate} minutes`
                      : "None specified"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Assigned To:{" "}
                    {todo.task.assignedTo
                      ? todo.task.assignedTo.person.firstName +
                        " " +
                        todo.task.assignedTo.person.lastName
                      : "Not assigned"}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  {todo.task.completed && (
                    <Typography color="textSecondary">
                      Task completed:{" "}
                      {moment(todo.task.completedDate).format(
                        "MMM DD, YYYY, h:mm a"
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Category:{" "}
                    {todo.task.category
                      ? todo.task.category.name
                      : "No Category"}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
            <Divider />

            <AccordionActions>
              <Button
                size="small"
                color="secondary"
                // onclick={() => setDeleteMode(true)}
              >
                Remove
              </Button>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => {
                  setEditTaskId(taskId)
                  setEdit(true)
                }}
              >
                Edit
              </Button>
              {/* <UpdateTask todo={todo} /> */}
              {/* <Button
                  size="small"
                  onClick={() => {
                    setEditTaskId(todo)
                    setEdit(true)
                  }}
                >
                  Update
                </Button> */}
            </AccordionActions>
          </Accordion>
        )
      })}
    </div>
  )
}
const COMPLETE_TASK_MUTATION = gql`
  mutation (
    $taskId: Int!
    $minutesReported: Int
    $completedDate: DateTime
    $taskCompleted: Boolean
  ) {
    completeTask(
      taskId: $taskId
      minutesReported: $minutesReported
      completedDate: $completedDate
      taskCompleted: $taskCompleted
    ) {
      task {
        id
        taskForProject {
          id
          task {
            id
            title
            createdDate
            dueDate
            completed
            completedDate
            note
            priority
            minutesEstimate
            minutesReported
            taskOverdueStatus
            category {
              id
              name
            }
            createdBy {
              id
              person {
                uid
                firstName
                lastName
              }
            }
            assignedTo {
              id
              person {
                uid
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`
export default TaskList
