import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import PropTypes from "prop-types"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Paper,
  Step,
  StepConnector,
  Stepper,
  StepLabel,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Page from "../../../Global/Page"
import {
  User as UserIcon,
  Star as StarIcon,
  Briefcase as BriefcaseIcon,
  File as FileIcon,
} from "react-feather"
import Header from "./Header"
import SelectPanel from "./SelectPanel"
import ProjectDescription from "./ProjectDescription"
import ProjectDetails from "./ProjectDetails"
import ProjectContext from "../context/ProjectContext"
const steps = [
  {
    label: "Project Type",
    icon: UserIcon,
  },
  {
    label: "Project Details",
    icon: BriefcaseIcon,
  },
  {
    label: "Project Description",
    icon: FileIcon,
  },
]

const CustomStepConnector = withStyles(theme => ({
  vertical: {
    marginLeft: 19,
    padding: 0,
  },
  line: {
    borderColor: theme.palette.divider,
  },
}))(StepConnector)

const useCustomStepIconStyles = makeStyles(theme => ({
  root: { minHeight: "92vh" },
  active: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[10],
  },
  completed: {
    backgroundColor: theme.palette.secondary.main,
  },
  avatar: {},
}))

function CustomStepIcon({ active, completed, icon }) {
  const classes = useCustomStepIconStyles()

  const Icon = steps[icon - 1].icon

  return (
    <Avatar
      className={clsx(classes.avatar, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <Icon size="20" />
    </Avatar>
  )
}

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number,
}

const ProjectCreation = ({ classes }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState(false)
  const { state, dispatch } = useContext(ProjectContext)
  const projectTags = state.projectTags
  const [projectId, setProjectId] = useState(null)
  const [tagIds, setTagIds] = useState(
    projectTags ? projectTags.map(pt => pt.id) : []
  )
  const { data, loading, error } = useQuery(NEW_PROJECT_FORM_VALUES_QUERY, {
    onCompleted: data => {
      const tagOptions = data.projectTags
      const categoryOptions = data.projectCategories
      const staff = data.staff
      dispatch({ type: "SET_TAG_OPTIONS", payload: tagOptions })
      dispatch({ type: "SET_CATEGORY_OPTIONS", payload: categoryOptions })
      dispatch({ type: "SET_STAFF_LIST", payload: staff })
    },
  })
  const [createProject] = useMutation(CREATE_PROJECT_MUTATION, {
    variables: {
      title: state.title,
      dueDate: state.dueDate,
      completed: state.completed,
      clientProject: state.clientProject,
      projectManagerId: state.projectManagerId,
      objective: state.objective,
      priority: state.priority,
      categoryId: state.categoryId,
      tagIds: tagIds,
      value: state.value,
      budget: state.budget,
    },
    onCompleted: data => {
      setProjectId(data.createProject.project.id)
      setCompleted(true)
    },
  })
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleComplete = () => {
    createProject()
  }
  if (loading) return "loading..."
  if (error) return <div>{JSON.stringify(error)}</div>
  return (
    <Page className={classes.root} title="New Project">
      <Container maxWidth="lg">
        <Header />
        {!completed ? (
          <Paper className={classes.mainSection}>
            <Grid container>
              <Grid item xs={12} md={3}>
                <Stepper
                  activeStep={activeStep}
                  connector={<CustomStepConnector />}
                  orientation="vertical"
                  component={Box}
                  bgcolor="transparent"
                >
                  {steps.map(step => (
                    <Step key={step.label}>
                      <StepLabel StepIconComponent={CustomStepIcon}>
                        {step.label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box p={3}>
                  {activeStep === 0 && <SelectPanel onNext={handleNext} />}
                  {activeStep === 1 && (
                    <ProjectDetails onBack={handleBack} onNext={handleNext} />
                  )}
                  {activeStep === 2 && (
                    <ProjectDescription
                      onBack={handleBack}
                      onComplete={handleComplete}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Card className={classes.mainSection}>
            <CardContent>
              <Box maxWidth={450} mx="auto">
                <Box display="flex" justifyContent="center">
                  <Avatar className={classes.avatar}>
                    <StarIcon />
                  </Avatar>
                </Box>
                <Box mt={2}>
                  <Typography variant="h3" color="textPrimary" align="center">
                    Project Started!
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="center"
                  >
                    Your project has been set up. View your project to add and
                    manage tasks and details.
                  </Typography>
                </Box>
                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to={`/manager/projects/${projectId}`}
                  >
                    View your project
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        )}
      </Container>
    </Page>
  )
}

const styles = theme => ({
  root: {},
  mainSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    borderRadius: "15px",
  },
})
const NEW_PROJECT_FORM_VALUES_QUERY = gql`
  query {
    projectTags {
      id
      name
    }
    projectCategories {
      id
      name
    }
    staff {
      id
      person {
        firstName
        lastName
      }
    }
  }
`

const CREATE_PROJECT_MUTATION = gql`
  mutation (
    $title: String!
    $startDate: DateTime
    $dueDate: DateTime
    $completed: Boolean
    $projectManagerId: Int
    $objective: String
    $priority: Int
    $categoryId: Int
    $tagIds: [Int]
    $value: Money
    $budget: Money
    $clientProject: Boolean
  ) {
    createProject(
      title: $title
      startDate: $startDate
      dueDate: $dueDate
      completed: $completed
      projectManagerId: $projectManagerId
      objective: $objective
      priority: $priority
      categoryId: $categoryId
      tagIds: $tagIds
      value: $value
      budget: $budget
      clientProject: $clientProject
    ) {
      project {
        id
        title
        createdBy {
          id
          person {
            firstName
            lastName
          }
        }
        dueDate
        completed
        completedDate
        clientProject
        projectManager {
          id
          person {
            firstName
            lastName
          }
        }
        objective
        priority
        category {
          id
          name
        }
        tags {
          id
          name
        }
        value
        budget
      }
    }
  }
`
export default withStyles(styles)(ProjectCreation)
