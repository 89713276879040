import React, { useState, useContext } from "react"
import clsx from "clsx"
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
  SvgIcon,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core"
import moment from "moment"
import PerfectScrollbar from "react-perfect-scrollbar"
import { useQuery, useMutation } from "@apollo/client"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import gql from "graphql-tag"
import SortContext from "../../../../context/SortContext"
import {
  ArrowRight as ArrowRightIcon,
  PlusSquare as CheckIcon,
  User as UserIcon,
  Search as SearchIcon,
  XSquare as CloseIcon,
  X as ClearIcon,
} from "react-feather"

const useStyles = makeStyles(theme => ({
  root: {},
  queryField: {
    margin: "dense",
    size: "small",
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

const QuoteItemSearch = ({ className, setSelected, setItem, uid, ...rest }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(SortContext)
  const search = state.search
  const page = state.page
  const pageQ = state.page + 1
  const pageSize = state.pageSize
  const [searchBar, setSearchBar] = useState("")
  const [newPage, setNewPage] = useState(1)
  const [pickId, setPickId] = useState("")
  const [pickName, setPickName] = useState("")
  const [pickCategory, setPickCategory] = useState("")
  const [privateItemFilter, setPrivateItemFilter] = useState(true)
  const { loading, error, data } = useQuery(QUOTE_ITEMS_QUERY, {
    variables: {
      search: search,
      page: pageQ,
      pageSize: pageSize,
      privateItemFilter: privateItemFilter,
      uid: uid,
    },
    fetchPolicy: "network-only",
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const resCount = data.itemsForQuote.count
  const resPage = data.itemsForQuote.page
  const resPages = data.itemsForQuote.pages
  const items = data.itemsForQuote.objects

  const selectItem = ({ item }) => {
    setItem(item)
    setSelected(true)
    console.log(item)
  }

  const handlePageChange = (event, newPage) => {
    dispatch({ type: "SET_PAGE", payload: newPage })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
  }

  const handlePrivateItemChange = event => {
    event.persist()

    let value = null

    if (event.target.checked) {
      value = true
    }

    setPrivateItemFilter(value)
  }
  return (
    <>
      <Box>
        <Box p={2}>
          <Grid
            className={clsx(classes.root, className)}
            container
            justify="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12}>
              <Typography variant="h4" color="textPrimary">
                Search for an Item
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item xs={6} sm={2}>
                <FormControlLabel
                  className={classes.shippableField}
                  control={
                    <Checkbox
                      checked={privateItemFilter}
                      onChange={handlePrivateItemChange}
                      name="Private Item"
                    />
                  }
                  label="Private Item"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                component="form"
                onSubmit={event => {
                  event.preventDefault()
                  handleSearch(event)
                }}
                className={classes.searchField}
              >
                <InputBase
                  fullWidth
                  className={classes.input}
                  placeholder="Search Items"
                  inputProps={{ "aria-label": "search items" }}
                  onChange={event => setSearchBar(event.target.value)}
                  value={searchBar}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={handleClearSearchResults}
                >
                  <ClearIcon />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Divider />
          <PerfectScrollbar>
            <Box minWidth={700}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Added</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map(item => (
                    <TableRow hover key={item.id}>
                      <TableCell padding="checkbox">
                        <IconButton onClick={e => selectItem({ item }, e)}>
                          <SvgIcon fontSize="small">
                            <CheckIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>
                        {item.businessUnit.name}
                        <br />
                        {item.category.name}
                      </TableCell>
                      <TableCell>
                        {item.isPrivate ? (
                          <>
                            Private <br />
                            {item.privateItem ? (
                              <>{item.privateItem.itemOwner.lastName}</>
                            ) : (
                              "No owner specified"
                            )}
                          </>
                        ) : (
                          "Public"
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(item.dateAdded).format("MMM DD YYYY")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        </Box>
        {/* This needs work */}
        {resCount > 5 && (
          <TablePagination
            component="div"
            count={resCount}
            onChangePage={handlePageChange}
            // onChangePage= {event => dispatch({type: 'SET_PAGE', payload: (newPage)})}
            // onChangeRowsPerPage={event => setPageSize(event.target.value)}
            // onChangeRowsPerPage = {event => dispatch({type: 'SET_PAGE_SIZE', payload: (event.target.value)})}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            // dispatch({ type: "SET_LOGIN" })
            // onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
            // ActionsComponent={TablePaginationActions}
          />
        )}
      </Box>
    </>
  )
}
export const QUOTE_ITEMS_QUERY = gql`
  query (
    $page: Int
    $pageSize: Int
    $search: String
    $privateItemFilter: Boolean
    $uid: String
  ) {
    itemsForQuote(
      page: $page
      pageSize: $pageSize
      search: $search
      privateItemFilter: $privateItemFilter
      uid: $uid
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        name
        category {
          id
          name
        }
        businessUnit {
          id
          name
        }
        dateAdded
        dateModified
        isActive
        isPrivate
        pricingModel {
          id
          name
        }
        privateItem {
          itemOwner {
            uid
            firstName
            lastName
          }
          isPrereg
          preregItem {
            isConverted
          }
        }
        itemImage {
          id
          image
          title
          note
          featured
          proof
          uploadedAt
          imageUrl
          createdBy {
            id
            person {
              firstName
              lastName
            }
          }
        }
        sampleProofItem {
          id
          version
          isVertical
          thumbnailUrl
          carouselImageUrl
          image
        }
        itemPrice {
          id
          minQuantity
          maxQuantity
          price
        }
      }
    }
  }
`
export default QuoteItemSearch
