import React from "react"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(3),
    },
    padding: 3,
  },
}))

const SelectButton = ({ setStatus, className, ...rest }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Button
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={() => {
          setStatus("searchPerson")
        }}
      >
        Search for a Customer
      </Button>
      <Button
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={() => {
          setStatus("createPerson")
        }}
      >
        Create a New Customer
      </Button>
    </div>
  )
}
export default SelectButton
