import React, { useState, useEffect, useCallback } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import moment from "moment"
import PerfectScrollbar from "react-perfect-scrollbar"
import PropTypes from "prop-types"
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import GenericMoreButton from "../../../Global/GenericMoreButton"

const useStyles = makeStyles(theme => ({
  root: {},

  navigateNextIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const LatestInquiries = ({ className, ...rest }) => {
  const classes = useStyles()
  const [inquiries, setInquiries] = useState([])
  const [pageInquiries, setPageInquiries] = useState([])
  const [page, setPage] = useState(1)

  const [data, loading, error] = useQuery(LATEST_INQUIRIES_QUERY, {
    variables: {
      page: 1,
      pageSize: 10,
      unquotedFilter: true,
    },
    onCompleted: data => {
      // console.log(data.inquiries.objects)
      // setInquiries(data.inquiries)
      setPageInquiries(data.inquiries.objects)
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />
  //   if (data == undefined) return ""
  //   if (data == null) {
  //     return ""
  //   }
  //   const pageInquiries = data.inquiries.objects
  //   if (!inquiries) {
  //     return null
  //   }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader action={<GenericMoreButton />} title="Latest Inquiries" />
      <Divider />

      <PerfectScrollbar>
        <Box minWidth={600}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>From</TableCell>
                <TableCell>Business Unit</TableCell>
                <TableCell>Source</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {pageInquiries.map(inquiry => (
                <TableRow hover key={inquiry.id}>
                  <TableCell>
                    {moment(inquiry.createdAt).format("MMM DD, YYYY")}
                  </TableCell>
                  <TableCell>
                    {inquiry.inquiryPerson.firstName}{" "}
                    {inquiry.inquiryPerson.lastName}
                  </TableCell>
                  <TableCell>{inquiry.businessUnit.name}</TableCell>
                  <TableCell>{inquiry.source}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="View inquiry">
                      <IconButton
                        className={classes.viewButton}
                        edge="end"
                        component={Link}
                        to={`/manager/inquiries/${inquiry.id}`}
                        state={{ id: inquiry.id }}
                      >
                        <OpenInNewIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {/* <Box p={2} display="flex" justifyContent="flex-end">
        <Button component={Link} size="small" to="/manager/inquiries">
          See all
          <NavigateNextIcon className={classes.navigateNextIcon} />
        </Button>
      </Box> */}
    </Card>
  )
}

LatestInquiries.propTypes = {
  className: PropTypes.string,
}

const LATEST_INQUIRIES_QUERY = gql`
  query ($page: Int, $pageSize: Int, $unquotedFilter: Boolean) {
    inquiries(
      page: $page
      pageSize: $pageSize
      unquotedFilter: $unquotedFilter
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        inquiryUuid
        inquiryPerson {
          id
          firstName
          lastName
        }
        businessUnit {
          id
          name
        }
        source
        createdAt
      }
    }
  }
`
export default LatestInquiries
