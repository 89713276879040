import React, { useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"

import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import INQUIRY_DETAILS_QUERY from "../InquiryDetailsView"

const useStyles = makeStyles(() => ({
  root: {},
}))

const AddPersonForm = ({
  uid,
  setUid,
  inquiry,
  activeStep,
  setActiveStep,
  className,
  onSubmitSuccess,
  setOpenAddPerson,
  setIsPerson,
  person,
  setPerson,
  setPersonUid,
  ...rest
}) => {
  const classes = useStyles()
  const ip = inquiry.inquiryPerson
  const ipid = ip.id
  const fn = ip.firstName
  const ln = ip.lastName
  const mn = ip.middleName
  const suf = ip.suffix
  const nn = ip.nickname
  const ttl = ip.title
  const sup = ip.isSupplier
  const org = ip.isOrg
  const on = ip.orgName
  const abt = ip.about

  const [id, setId] = useState(ipid ? ipid : "")
  const [firstName, setFirstName] = useState(fn ? fn : "")
  const [lastName, setLastName] = useState(ln ? ln : "")
  const [middleName, setMiddleName] = useState(mn ? mn : "")
  const [suffix, setSuffix] = useState(suf ? suf : "")
  const [nickname, setNickname] = useState(nn ? nn : "")
  const [title, setTitle] = useState(ttl ? ttl : "")
  const [supplier, setSupplier] = useState(sup ? sup : false)
  const [about, setAbout] = useState(abt ? abt : "")
  const [emailForm, setEmailform] = useState(false)

  const handleCheckSupplier = event => {
    setSupplier(event.target.checked)
  }

  const handleSubmit = (event, createPerson) => {
    event.preventDefault()
    createPerson()
  }

  // const handleCloseDialog = () => {
  //   setOpenAddPerson(false)
  // }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const [createPerson, { loading, error }] = useMutation(ADD_PERSON_MUTATION, {
    variables: {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      suffix: suffix,
      nickname: nickname,
      isSupplier: supplier,
      title: title,
      about: about,
      userCanUpdate: false,
    },
    onCompleted: data => {
      // const uid = data.createPerson.person.uid
      setUid(data.createPerson.person.uid)
      setPersonUid(data.createPerson.person.uid)
      setPerson(data.createPerson.person)
      setIsPerson(true)
      console.log("added new person")
      console.log(`active step is ${activeStep}`)
      // console.log(uid)
      // console.log(org)
      // console.log(data.createPerson.person.uid)
      confirmPerson()
    },
  })
  const [confirmPerson] = useMutation(CONNECT_PERSON_MUTATION, {
    variables: {
      id: id,
      isPerson: true,
      personUid: uid,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      isSupplier: false,
      isOrg: org,
      orgName: on,
      middleName: middleName,
      nickname: nickname,
      title: title,
      about: about,
    },
    onCompleted: (data, response, errors) => {
      console.log("matched new person to inquiry")
      setFirstName("")
      setLastName("")
      setMiddleName("")
      setSuffix("")
      setNickname("")
      setTitle("")
      setSupplier(null)
      setAbout("")
      setPersonUid(data.updateInquiryPerson.inquiryPerson.person.uid)
      setPerson(data.updateInquiryPerson.inquiryPerson.person)
      // setSnackBarOpen(true)
      handleNext()
    },
  })

  return (
    <>
      <Typography gutterBottom variant="h4" color="textPrimary">
        Create a new account for {firstName}
      </Typography>
      <form
        onSubmit={event => handleSubmit(event, createPerson)}
        className={clsx(classes.root, className)}
        {...rest}
      >
        <TextField
          // error={Boolean(touched.firstName && errors.firstName)}
          fullWidth
          // helperText={touched.firstName && errors.firstName}
          label="First Name"
          margin="dense"
          size="small"
          name="firstName"
          // onBlur={handleBlur}
          onChange={event => setFirstName(event.target.value)}
          type="firstName"
          value={firstName}
          variant="outlined"
        />
        <TextField
          fullWidth
          // helperText={touched.firstName && errors.firstName}
          label="Middle Name"
          margin="dense"
          size="small"
          name="middleName"
          // onBlur={handleBlur}
          onChange={event => setMiddleName(event.target.value)}
          type="middleName"
          value={middleName}
          variant="outlined"
        />
        <TextField
          // error={Boolean(touched.lastName && errors.lastName)}
          fullWidth
          // helperText={touched.lastName && errors.lastName}
          label="Last Name"
          margin="dense"
          size="small"
          name="lastName"
          // onBlur={handleBlur}
          onChange={event => setLastName(event.target.value)}
          type="lastName"
          value={lastName}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Suffix"
          margin="dense"
          size="small"
          name="suffix"
          // onBlur={handleBlur}
          onChange={event => setSuffix(event.target.value)}
          type="suffix"
          value={suffix}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Title"
          margin="dense"
          size="small"
          name="Title"
          // onBlur={handleBlur}
          onChange={event => setTitle(event.target.value)}
          type="title"
          value={title}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Nickname"
          margin="dense"
          size="small"
          name="Nickname"
          // onBlur={handleBlur}
          onChange={event => setNickname(event.target.value)}
          type="nickname"
          value={nickname}
          variant="outlined"
        />
        <TextField
          fullWidth
          multiline
          rowsMax={4}
          label="About"
          margin="dense"
          size="small"
          name="About"
          // onBlur={handleBlur}
          onChange={event => setAbout(event.target.value)}
          type="about"
          value={about}
          variant="outlined"
        />

        <Box alignItems="center" display="flex" mt={2} ml={-1}>
          <Checkbox
            checked={supplier}
            // name="supplier"
            onChange={handleCheckSupplier}
          />

          <Typography variant="body2" color="textSecondary">
            This person is a supplier. Check to add to supplier list.
          </Typography>
        </Box>

        <Box mt={2}>
          <Button
            color="secondary"
            // disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Add Person
          </Button>
        </Box>
      </form>
    </>
  )
}

const ADD_PERSON_MUTATION = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $middleName: String
    $suffix: String
    $nickname: String
    $title: String
    $about: String
    $isSupplier: Boolean
    $userCanUpdate: Boolean
  ) {
    createPerson(
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      suffix: $suffix
      userCanUpdate: $userCanUpdate
      isSupplier: $isSupplier

      nickname: $nickname
      title: $title
      about: $about
    ) {
      person {
        firstName
        lastName
        middleName
        suffix
        nickname
        title
        about
        userCanUpdate
        isSupplier
        uid
      }
    }
  }
`
const CONNECT_PERSON_MUTATION = gql`
  mutation (
    $id: Int!
    $isPerson: Boolean
    $personUid: String
    $firstName: String
    $lastName: String
    $middleName: String
    $isSupplier: Boolean
    $isOrg: Boolean
    $nickname: String
    $title: String
  ) {
    updateInquiryPerson(
      id: $id
      isPerson: $isPerson
      personUid: $personUid
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      isSupplier: $isSupplier
      isOrg: $isOrg
      nickname: $nickname
      title: $title
    ) {
      inquiryPerson {
        id
        firstName
        lastName
        isPerson
        isSupplier
        isOrg
        person {
          uid
          firstName
          lastName
        }
      }
    }
  }
`

AddPersonForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
}

AddPersonForm.default = {
  onSubmitSuccess: () => {},
}

export default AddPersonForm
