import React from "react"
import clsx from "clsx"
import { Link } from "gatsby"
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from "@material-ui/core"
import PerfectScrollbar from "react-perfect-scrollbar"

const useStyles = makeStyles(theme => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
  itemNumber: {
    // width: theme.spacing(4),
    // height: theme.spacing(4),
    // fontSize: theme.typography.pxToRem(12),
  },
}))

const PrivateItemsList = ({ privateItems, className, ...rest }) => {
  const classes = useStyles()

  if (privateItems.count === 0) {
    return (
      <div>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardHeader title="No Private Items on Record" />
          <Divider />
        </Card>
      </div>
    )
  }

  return (
    <div>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title="Private Items" />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Item #</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell align="right">Business Unit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {privateItems.objects.map(obj => (
                  <TableRow key={obj.item.id}>
                    <TableCell>
                      <IconButton
                        component={Link}
                        to={`/manager/items/${obj.item.id}`}
                        state={{ id: `${obj.item.id}` }}
                      >
                        <Avatar variant="square" className={classes.itemNumber}>
                          {obj.item.id}
                        </Avatar>
                      </IconButton>
                      {/* <Link
                        to={`/manager/items/${obj.item.id}`}
                        state={{ id: `${obj.item.id}` }}
                      >
                        {obj.item.id}
                      </Link> */}
                    </TableCell>
                    <TableCell>{obj.item.name}</TableCell>
                    <TableCell>{obj.item.category.name}</TableCell>
                    <TableCell align="right">
                      {obj.item.businessUnit.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </div>
  )
}

export default PrivateItemsList
