import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core"
import LockOpenIcon from "@material-ui/icons/LockOpenOutlined"
import PersonIcon from "@material-ui/icons/PersonOutline"
import Label from "../../../../Global/Label"

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

const UserAccount = ({ person, className, ...rest }) => {
  const classes = useStyles()
  const user = person.user
  return (
    <Card
      className={clsx(classes.root, classes.roundedCard, className)}
      {...rest}
    >
      <CardHeader title="User Account" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>
              User Account Email
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {user.email}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.fontWeightMedium}>Access</TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {user.admin && "Admin "} {user.manager && "Manager "}
                {user.staff && "Staff "}
                {user.partner && "Partner "}
                {!user.admin &&
                  !user.manager &&
                  !user.staff &&
                  !user.partner &&
                  "Customer"}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {/* <Box p={1} display="flex" flexDirection="column" alignItems="flex-start">
        <Button>
          <LockOpenIcon className={classes.actionIcon} />
          Reset &amp; Send Password
        </Button>
      </Box> */}
    </Card>
  )
}

UserAccount.propTypes = {
  className: PropTypes.string,
  person: PropTypes.object.isRequired,
}

export default UserAccount
