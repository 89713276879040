import React, { useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"

import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"

import { useMutation } from "@apollo/client"
import gql from "graphql-tag"

const useStyles = makeStyles(() => ({
  root: {},
}))

function AddPersonForm({ setActiveStep, className, setPerson, ...rest }) {
  const classes = useStyles()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [middleName, setMiddleName] = useState("")
  const [suffix, setSuffix] = useState("")
  const [nickname, setNickname] = useState("")
  const [title, setTitle] = useState("")
  const [supplier, setSupplier] = useState(false)
  const [about, setAbout] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleCheckSupplier = event => {
    setSupplier(event.target.checked)
  }

  const handleSubmit = (event, createPerson) => {
    event.preventDefault()
    setIsSubmitting(true)
    createPerson()
  }

  const [createPerson, { loading, error }] = useMutation(ADD_PERSON_MUTATION, {
    variables: {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      suffix: suffix,
      nickname: nickname,
      isSupplier: supplier,
      title: title,
      about: about,
      userCanUpdate: false,
    },
    onCompleted: data => {
      setPerson(data.createPerson.person)
      setIsSubmitting(false)
      setActiveStep(1)
    },
  })

  return (
    <>
      <Typography variant="h3">Basic Info</Typography>
      <form
        onSubmit={event => handleSubmit(event, createPerson)}
        className={clsx(classes.root, className)}
        {...rest}
      >
        <TextField
          // error={Boolean(touched.firstName && errors.firstName)}
          fullWidth
          // helperText={touched.firstName && errors.firstName}
          label="First Name"
          margin="dense"
          size="small"
          name="firstName"
          // onBlur={handleBlur}
          onChange={event => setFirstName(event.target.value)}
          type="firstName"
          value={firstName}
          variant="outlined"
        />
        <TextField
          fullWidth
          // helperText={touched.firstName && errors.firstName}
          label="Middle Name"
          margin="dense"
          size="small"
          name="middleName"
          // onBlur={handleBlur}
          onChange={event => setMiddleName(event.target.value)}
          type="middleName"
          value={middleName}
          variant="outlined"
        />
        <TextField
          // error={Boolean(touched.lastName && errors.lastName)}
          fullWidth
          // helperText={touched.lastName && errors.lastName}
          label="Last Name"
          margin="dense"
          size="small"
          name="lastName"
          // onBlur={handleBlur}
          onChange={event => setLastName(event.target.value)}
          type="lastName"
          value={lastName}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Suffix"
          margin="dense"
          size="small"
          name="suffix"
          // onBlur={handleBlur}
          onChange={event => setSuffix(event.target.value)}
          type="suffix"
          value={suffix}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Title"
          margin="dense"
          size="small"
          name="Title"
          // onBlur={handleBlur}
          onChange={event => setTitle(event.target.value)}
          type="title"
          value={title}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Nickname"
          margin="dense"
          size="small"
          name="Nickname"
          // onBlur={handleBlur}
          onChange={event => setNickname(event.target.value)}
          type="nickname"
          value={nickname}
          variant="outlined"
        />
        <TextField
          fullWidth
          multiline
          rowsMax={4}
          label="About"
          margin="dense"
          size="small"
          name="About"
          // onBlur={handleBlur}
          onChange={event => setAbout(event.target.value)}
          type="about"
          value={about}
          variant="outlined"
        />

        <Box alignItems="center" display="flex" mt={2} ml={-1}>
          <Checkbox
            checked={supplier}
            // name="supplier"
            onChange={handleCheckSupplier}
          />

          <Typography variant="body2" color="textSecondary">
            This person is a supplier. Check to add to supplier list.
          </Typography>
        </Box>

        <Box mt={2}>
          <Button
            color="primary"
            disabled={isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Add Person and Continue
          </Button>
        </Box>
      </form>
    </>
  )
}

const ADD_PERSON_MUTATION = gql`
  mutation (
    $firstName: String!
    $lastName: String!
    $middleName: String
    $suffix: String
    $nickname: String
    $title: String
    $about: String
    $isSupplier: Boolean
    $userCanUpdate: Boolean
  ) {
    createPerson(
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      suffix: $suffix
      userCanUpdate: $userCanUpdate
      isSupplier: $isSupplier
      nickname: $nickname
      title: $title
      about: $about
    ) {
      person {
        firstName
        lastName
        middleName
        suffix
        nickname
        title
        about
        userCanUpdate
        isSupplier
        uid
      }
    }
  }
`

AddPersonForm.propTypes = {
  className: PropTypes.string,
}

export default AddPersonForm
