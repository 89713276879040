import React, { useState, useContext } from "react"
import clsx from "clsx"
import PerfectScrollbar from "react-perfect-scrollbar"
import useDidMountEffect from "../../../Global/hooks/useDidMountEffect"
import SortContext from "../../../../context/SortContext"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"

import { Edit as EditIcon, XCircle as DeleteIcon, Search } from "react-feather"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

import QuoteItemSearch from "./QuoteItemSearch"
import QuoteItemCreate from "./QuoteItemCreate"

const useStyles = makeStyles(() => ({
  root: {},
}))

const AddQuoteItem = ({
  id,
  quote,
  className,
  items,
  setItems,
  setAddQuoteItem,
  search,
  setSearch,
  createNew,
  setCreateNew,
  ...rest
}) => {
  const classes = useStyles()

  const { state, dispatch } = useContext(SortContext)
  const [item, setItem] = useState("")
  const [itemId, setItemId] = useState(null)
  const [taxId, setTaxId] = useState(1)
  const [discountId, setDiscountId] = useState(1)
  const [qty, setQty] = useState(0)
  const [priceEach, setPriceEach] = useState(0.0)
  const [priceObject, setPriceObject] = useState("")
  const [taxList, setTaxList] = useState("")
  const [selected, setSelected] = useState(false)
  const [itemPricing, setItemPricing] = useState([])
  const [discount, setDiscount] = useState(false)

  const { data, loading, error } = useQuery(SALES_TAXES_QUERY, {
    onCompleted: (data, response, errors) => {
      setTaxList(data.taxes)
    },
    fetchPolicy: "cache-and-network",
  })

  const uid = quote.isPrereg
    ? quote.preRegQuoteUser.preregUser.person.uid
    : quote.userQuoteUser.user.person.uid

  const [addItem] = useMutation(ADD_QUOTE_ITEM_MUTATION, {
    variables: {
      quoteId: id,
      itemId: itemId,
    },

    onCompleted: (data, error) => {
      const addedItem = data.createQuoteItem.quoteItem
      const newList = items
      newList.push(addedItem)
      setItems(newList)
      setSelected(false)
      setSearch(false)
      setCreateNew(false)
      dispatch({ type: "SET_SEARCH", payload: "" })
      dispatch({ type: "SET_PAGE", payload: 0 })
      setAddQuoteItem(false)
    },
  })

  useDidMountEffect(() => {
    setItemId(item.id)
  }, [item])

  useDidMountEffect(() => {
    console.log(priceObject)
    setQty(priceObject.split(",")[0])
    setPriceEach(priceObject.split(",")[1])
  }, [priceObject])

  const handleCheckDiscount = event => {
    setDiscount(event.target.checked)
  }

  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }

  const handleAddItem = event => {
    event.preventDefault()
    addItem()
  }
  const handleCancel = event => {
    setSearch(false)
    setCreateNew(false)
    setSelected(false)
    setAddQuoteItem(false)
  }

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          title="Add Item to Proposal"
          action={
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          }
        />

        <Divider />
        <CardContent>
          {createNew && <QuoteItemCreate id={id} />}
          {search && selected === false && (
            <QuoteItemSearch
              uid={uid}
              id={id}
              setItem={setItem}
              setSelected={setSelected}
            />
          )}

          <Box p={2}>
            {search && selected && (
              <>
                <Typography variant="body1" color="textPrimary">
                  {item.name}
                  <br />
                  {item.category.name}
                </Typography>
                <form
                  // onSubmit={event => handleSubmit(event, addItem)}
                  onSubmit={handleAddItem}
                  className={clsx(classes.root, className)}
                  {...rest}
                >
                  <Grid container>
                    {/* <Grid item>
                    <Box p={1}>
                      <TextField
                        label="Qty + Pricing"
                        select
                        id="outlined-margin-dense"
                        value={priceObject}
                        className={classes.textField}
                        helperText="Select a Quantity and Price"
                        margin="dense"
                        variant="outlined"
                        SelectProps={{ native: true }}
                        onChange={event => setPriceObject(event.target.value)}
                      >
                        <option></option>
                        {itemPricing.map(price => (
                          <option
                            key={price.id}
                            value={[`${price.minQuantity}`, `${price.price}`]}
                          >
                            {price.minQuantity} for $
                            {financial(`${price.price}`)} ea
                          </option>
                        ))}
                      </TextField>
                    </Box>
                  </Grid> */}
                    {/* <Grid item>
                    {discount && (
                      <Box p={1}>
                        <TextField
                          label="Discount"
                          select
                          id="outlined-margin-dense"
                          value={discountId}
                          className={classes.textField}
                          helperText="Hook this up"
                          margin="dense"
                          variant="outlined"
                          SelectProps={{ native: true }}
                          onChange={event => setDiscountId(event.target.value)}
                        >
                          <option></option>
                          <option id={1} value={1}>
                            No Discount
                          </option>
                        </TextField>
                      </Box>
                    )}
                  </Grid> */}
                    {/* <Grid item>
                    <Box p={1}>
                      <TextField
                        label="Tax"
                        id="outlined-margin-dense"
                        className={classes.textField}
                        helperText="Hook This Up (map)"
                        margin="dense"
                        variant="outlined"
                        value={taxId}
                        select
                        SelectProps={{ native: true }}
                        onChange={event => setTaxId(event.target.value)}
                      >
                        <option></option>
                        {taxList.map(tax => (
                          <option key={tax.id} value={tax.id}>
                            {tax.code}
                          </option>
                        ))}
                      </TextField>
                    </Box>
                  </Grid> */}
                  </Grid>
                  {/* <Box alignItems="center" display="flex" mt={2} ml={-1}>
                  <Checkbox checked={discount} onChange={handleCheckDiscount} />
                  <Typography variant="body2" color="textSecondary">
                    Apply a Discount
                  </Typography>
                </Box> */}

                  <Button variant="contained" color="secondary" type="submit">
                    Add Item To Proposal
                  </Button>
                </form>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  )
}

const ADD_QUOTE_ITEM_MUTATION = gql`
  mutation ($itemId: Int!, $quoteId: Int!) {
    createQuoteItem(itemId: $itemId, quoteId: $quoteId) {
      quoteItem {
        id
        quote {
          id
        }
        item {
          id
          name
          category {
            id
            name
          }
        }
      }
    }
  }
`

const SALES_TAXES_QUERY = gql`
  query {
    taxes {
      id
      code
      name
      taxRate
    }
  }
`
export default AddQuoteItem
