import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import OrgCreateProvider from "../../OrgCreateView/context/OrgCreateProvider"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core"

import ContactMailIcon from "@material-ui/icons/ContactMail"
import EmailIcon from "@material-ui/icons/Email"
import LockOpenIcon from "@material-ui/icons/LockOpenOutlined"
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid"
import SearchIcon from "@material-ui/icons/Search"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"

import PersonIcon from "@material-ui/icons/PersonOutline"
import { navigate } from "gatsby-link"
import SearchOrgPeople from "./OrgUpdateForms/SearchOrgPeople"
import AddOrgPeople from "./OrgUpdateForms/AddOrgPeople"

const useStyles = makeStyles(theme => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

const OrgPeople = ({ org, className, ...rest }) => {
  const classes = useStyles()
  const [searchDialogOpen, setSearchDialogOpen] = useState(false)
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [people, setPeople] = useState(org.people)

  const handleCloseDialog = () => {
    setSearchDialogOpen(false)
    setAddDialogOpen(false)
  }
  const handleEmail = () => {
    console.log("email")
  }
  const handleFindPeople = () => {
    console.log("add")
  }
  const handleSearchPeople = () => {
    console.log("search")
  }
  return (
    <>
      <Card
        className={clsx(classes.root, classes.roundedCard, className)}
        {...rest}
      >
        <CardHeader
          title="People"
          action={
            <>
              <IconButton
                aria-label="search"
                onClick={() => setSearchDialogOpen(true)}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                aria-label="add"
                onClick={() => setAddDialogOpen(true)}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </>
          }
        />
        <Divider />
        <Table>
          <TableBody>
            {people &&
              people.map(person => {
                const firstName = person.firstName ? person.firstName : ""
                const lastName = person.lastName ? person.lastName : ""
                const personUid = person.uid
                const personPhones = person.phonePerson
                const personEmails = person.emailPerson
                return (
                  <TableRow key={personUid}>
                    <TableCell className={classes.fontWeightMedium}>
                      {firstName} {lastName}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => navigate(`/manager/people/${personUid}`)}
                      >
                        <PersonIcon />
                      </IconButton>
                      <IconButton>
                        <EmailIcon onClick={handleEmail} />
                      </IconButton>
                      <IconButton>
                        <PhoneAndroidIcon />
                      </IconButton>
                      {/* <Typography variant="body2" color="textSecondary">
     {emailAddresses && emailAddresses.length > 0
       ? emailAddresses.map(email => (
           <Fragment key={email.id}>
             {email.emailAddress}
             <br />{" "}
           </Fragment>
         ))
       : "No email addresses on file"}
   </Typography> */}
                      {/* <Label color={person.verified ? 'success' : 'error'}>
     {person.verified
       ? 'Email verified'
       : 'Email not verified'}
   </Label> */}
                    </TableCell>
                    {/* <Dialog
   fullWidth
   onClose={() => setUpdateEmail(false)}
   PaperProps={{ className: classes.roundedCard }}
   aria-labelledby="update-email"
   open={updateEmail}
 >
   <Box padding={5}>
     <UpdateOrgEmail
       org={org}
       emailAddresses={emailAddresses}
       setEmailAddresses={setEmailAddresses}
     />
   </Box>
 </Dialog> */}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </Card>
      <OrgCreateProvider>
        <Dialog
          open={addDialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="add-person"
          aria-describedby="add-person"
        >
          <DialogTitle>Add a new person</DialogTitle>
          <AddOrgPeople org={org} />
          <DialogTitle />
        </Dialog>
        <Dialog
          open={searchDialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="search-person"
          aria-describedby="search-person"
        >
          <DialogTitle>Search for a new person</DialogTitle>
          <SearchOrgPeople org={org} />
        </Dialog>
      </OrgCreateProvider>
    </>
  )
}

OrgPeople.propTypes = {
  className: PropTypes.string,
  org: PropTypes.object.isRequired,
}

export default OrgPeople
