import React from "react"
import Upload from "../../Upload"
const Files = () => {
  return (
    <div>
      <h1>Files</h1>
      <Upload />
    </div>
  )
}

export default Files
