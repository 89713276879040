import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"

import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import moment from "moment"
import PerfectScrollbar from "react-perfect-scrollbar"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"

import {
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  UserCheck as CheckIcon,
  User as UserIcon,
  Search as SearchIcon,
  XSquare as CloseIcon,
} from "react-feather"
import OrgCreateContext from "../../context/OrgCreateContext"

const SearchPersonAdd = ({ classes }) => {
  const { state, dispatch } = useContext(OrgCreateContext)
  const search = state.search
  const page = state.page
  const pageQ = state.page + 1
  const pageSize = state.pageSize

  const [searchBar, setSearchBar] = useState("")
  const [confirmPerson, setConfirmPerson] = useState(false)
  const [selectedPerson, setSelectedPerson] = useState("")

  const [newPage, setNewPage] = useState(1)

  const selectPerson = ({ person }) => {
    dispatch({ type: "SET_SELECTED_PERSON", payload: person })
    dispatch({ type: "TOGGLE_CONFIRM_PERSON" })
    // setSelectedPerson(person)
    // setConfirmPerson(true)
  }

  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  const { loading, error, data } = useQuery(SEARCH_PEOPLE_QUERY, {
    variables: {
      search: search,
      page: pageQ,
      pageSize: pageSize,
    },
    fetchPolicy: "cache-and-network",
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const resCount = data.people.count
  const resPage = data.people.page
  const resPages = data.people.pages
  const people = data.people.objects

  const handlePageChange = (event, newPage) => {
    dispatch({ type: "SET_PAGE", payload: newPage })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  return (
    <>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h4">Find a person</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() =>
              dispatch({ type: "SET_PEOPLE_VIEW", payload: "listView" })
            }
          >
            Cancel
          </Button>
        </Grid>
      </Grid>

      <Box display="flex" alignItems="center">
        <form
          noValidate
          onSubmit={event => {
            event.preventDefault()
            handleSearch(event)
          }}
        >
          <TextField
            className={classes.queryField}
            margin="dense"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit" color="primary">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={event => setSearchBar(event.target.value)}
            placeholder="Find a person"
            value={searchBar}
            variant="outlined"
          />
        </form>
      </Box>
      <Box>
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Date Added</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {people.map(person => {
                  const emails = person.emailPerson
                  const phones = person.phonePerson
                  const addresses = person.addressPerson
                  const user = person.user
                  const uid = person.uid
                  return (
                    <TableRow
                      hover
                      key={person.uid}
                      // selected={isCustomerSelected}
                    >
                      <TableCell padding="checkbox">
                        <IconButton onClick={e => selectPerson({ person }, e)}>
                          <SvgIcon fontSize="small">
                            <CheckIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2" color="textSecondary">
                            {person.firstName} {person.lastName}
                          </Typography>
                          {user ? (
                            <SvgIcon fontSize="small" color="secondary">
                              <UserIcon />
                            </SvgIcon>
                          ) : (
                            ""
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {emails.map(email => (
                          <div key={email.id}>{email.emailAddress}</div>
                        ))}
                      </TableCell>
                      <TableCell>
                        {phones.map(phone => (
                          <div key={phone.id}>{phone.phoneNumber}</div>
                        ))}
                      </TableCell>
                      <TableCell>
                        {addresses.map(address => (
                          <div key={address.id}>{address.city}</div>
                        ))}
                      </TableCell>
                      <TableCell>
                        {moment(person.dateAdded).format("MMM DD YYYY")}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={resCount}
          onChangePage={handlePageChange}
          // onChangePage= {event => dispatch({type: 'SET_PAGE', payload: (newPage)})}
          // onChangeRowsPerPage={event => setPageSize(event.target.value)}
          // onChangeRowsPerPage = {event => dispatch({type: 'SET_PAGE_SIZE', payload: (event.target.value)})}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          // dispatch({ type: "SET_LOGIN" })
          // onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
          // ActionsComponent={TablePaginationActions}
        />
      </Box>
    </>
  )
}

export const SEARCH_PEOPLE_QUERY = gql`
  query ($page: Int!, $pageSize: Int!, $search: String) {
    people(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        uid
        firstName
        lastName
        middleName
        suffix
        nickname
        title
        about
        userCanUpdate
        isSupplier
        dateAdded
        dateModified
        phonePerson {
          id
          phoneNumber
        }
        addressPerson {
          id
          address {
            uid
            region {
              id
              name
            }
            category {
              id
              name
            }
            attention
            addressLine1
            addressLine2
            addressLine3
            zone
            city
            postalCode
            summary
          }
        }
        personSocialMedia {
          id
          socialMediaType {
            id
            name
          }
          username
          url
        }
        emailPerson {
          id
          emailAddress
        }

        notePerson {
          id
          note
        }
      }
    }
  }
`

const styles = theme => ({
  root: {},
  queryField: {
    margin: "dense",
    size: "small",
  },
})

export default withStyles(styles)(SearchPersonAdd)
