import React, { useState, useRef, useContext, useEffect } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import PublicIcon from "@material-ui/icons/Public"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import Error from "../../../Utils/Error"
import Loading from "../../../Utils/Loading"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import SortContext from "../../../../context/SortContext"

import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  makeStyles,
} from "@material-ui/core"
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
} from "react-feather"

import FirstPageIcon from "@material-ui/icons/FirstPage"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import LastPageIcon from "@material-ui/icons/LastPage"

import Label from "../../../Label"

const avalabilityOptions = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "madeToOrder",
    name: "Made to Order",
  },
  {
    id: "inventory",
    name: "Inventory",
  },
  {
    id: "dropShip",
    name: "Drop Ship",
  },
]

const sortOptions = [
  {
    value: "updatedAt|desc",
    label: "Last update (newest first)",
  },
  {
    value: "updatedAt|asc",
    label: "Last update (oldest first)",
  },
  {
    value: "createdAt|desc",
    label: "Creation date (newest first)",
  },
  {
    value: "createdAt|asc",
    label: "Creation date (oldest first)",
  },
]

function getInventoryLabel(inventoryType) {
  const map = {
    in_stock: {
      text: "In Stock",
      color: "success",
    },
    out_of_stock: {
      text: "Out of Stock",
      color: "error",
    },
    limited: {
      text: "Limited",
      color: "warning",
    },
  }

  const { text, color } = map[inventoryType]

  return <Label color={color}>{text}</Label>
}

// function applyFilters(items, query, filters) {
//   return items.filter(item => {
//     let matches = true

//     if (query && !item.name.toLowerCase().includes(query.toLowerCase())) {
//       matches = false
//     }

//     if (filters.category && item.category !== filters.category) {
//       matches = false
//     }
//     if (filters.businessUnit && item.businessUnit !== filters.businessUnit) {
//       matches = false
//     }

//     if (filters.availability) {
//       if (filters.availability === "madeToOrder" && !item.isMadeToOrder) {
//         matches = false
//       }
//       if (filters.availability === "inventory" && !item.isInventory) {
//         matches = false
//       }
//       if (filters.availability === "dropShip" && !item.isDropShip) {
//         matches = false
//       }
//     }

//     if (
//       filters.inStock &&
//       !["in_stock", "limited"].includes(item.inventoryType)
//     ) {
//       matches = false
//     }

//     if (filters.isDigital && !item.isDigital) {
//       matches = false
//     }

//     return matches
//   })
// }
const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}))
function TablePaginationActions(props) {
  const classes = useStyles1()

  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {<LastPageIcon />}
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {},
  bulkOperations: {
    position: "relative",
  },
  table: {
    marginTop: 5,
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: "absolute",
    width: "100%",
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {},
  queryField: {
    width: 500,
  },
  categoryField: {
    flexBasis: 200,
  },
  businessUnitField: {
    flexBasis: 200,
  },
  availabilityField: {
    flexBasis: 200,
  },
  stockField: {},
  digitalField: {},
  imageCell: {
    fontSize: 0,
    width: 68,
    flexBasis: 68,
    flexGrow: 0,
    flexShrink: 0,
  },
  image: {
    height: 68,
    width: 68,
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  roundedCard: {
    borderRadius: "15px",
    marginBottom: theme.spacing(3),
  },
}))

const Results = ({ className, res, ...rest }) => {
  const { state, dispatch } = useContext(SortContext)
  const classes = useStyles()
  const resCount = res.count
  const resPage = res.page
  const resPages = res.pages
  const items = res.objects

  const page = state.page
  const pageSize = state.pageSize
  const searchContext = state.search

  const [searchResults, setSearchResults] = useState([])
  // const [search, setSearch] = useState("")
  const [searchBar, setSearchBar] = useState("")

  const [selectedItems, setSelectedItems] = useState(items)
  const [itemId, setItemId] = useState(null)
  const [limit, setLimit] = useState(10)
  const [query, setQuery] = useState("")
  const [sort, setSort] = useState(sortOptions[0].value)
  const [filters, setFilters] = useState({
    category: null,
    businessUnit: null,
    availability: null,
    inStock: null,
    isDigital: null,
  })

  useEffect(() => {
    let pageSizeOptions = [5, 10, 25, 50]
    if (!pageSizeOptions.includes(pageSize)) {
      dispatch({ type: "SET_PAGE_SIZE", payload: 25 })
    }
  }, [])

  const itemData = searchResults.length > 0 ? searchResults : res.objects
  const { data, loading, error } = useQuery(FORM_INFO_QUERY, {
    fetchPolicy: "cache-first",
  })
  const inputEl = useRef()

  const handleCategoryChange = event => {
    event.persist()

    let value = null

    if (event.target.value !== "all") {
      value = event.target.value
    }
    setFilters(prevFilters => ({
      ...prevFilters,
      category: value,
    }))
  }

  const handleBusinessUnitChange = event => {
    event.persist()

    let value = null

    if (event.target.value !== "all") {
      value = event.target.value
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      businessUnit: value,
    }))
  }

  const handleAvailabilityChange = event => {
    event.persist()

    let value = null

    if (event.target.value !== "all") {
      value = event.target.value
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      availability: value,
    }))
  }

  const handleStockChange = event => {
    event.persist()

    let value = null

    if (event.target.checked) {
      value = true
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      inStock: value,
    }))
  }

  const handleDigitalChange = event => {
    event.persist()

    let value = null

    if (event.target.checked) {
      value = true
    }

    setFilters(prevFilters => ({
      ...prevFilters,
      isDigital: value,
    }))
  }

  const handleSortChange = event => {
    event.persist()
    setSort(event.target.value)
  }

  const handleSelectAllItems = event => {
    setSelectedItems(event.target.checked ? items.map(item => item.id) : [])
  }

  const handleSelectOneProduct = (event, itemId) => {
    if (!selectedItems.includes(itemId)) {
      setSelectedItems(prevSelected => [...prevSelected, itemId])
    } else {
      setSelectedItems(prevSelected => prevSelected.filter(id => id !== itemId))
    }
  }
  // const handleBackButtonClick = event => {
  //   onChangePage(event, page - 1)
  // }

  // const handleNextButtonClick = event => {
  //   onChangePage(event, page + 1)
  // }

  // const handlePageChange = (event) => {
  //   dispatch({ type: "SET_PAGE", payload:  })
  // }

  // const handleChangeRowsPerPage = event => {
  //   dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
  //   dispatch({ type: "SET_PAGE", payload: 0 })
  // }
  const handlePageChange = (event, newPage) => {
    let x = 1
    const pagiNewPage = newPage
    dispatch({ type: "SET_PAGE", payload: pagiNewPage })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  // const handleLimitChange = event => {
  //   setLimit(event.target.value)
  // }

  // Usually query is done on backend with indexing solutions

  // const filteredItems = applyFilters(items, query, filters)
  // const paginatedItems = applyPagination(filteredItems, page, limit)
  const enableBulkOperations = selectedItems.length > 0
  const selectedSomeItems =
    selectedItems.length > 0 && selectedItems.length < items.length
  const selectedAllItems = selectedItems.length === items.length
  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }
  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
  }
  if (loading) return <Loading />
  if (error) return <Error />
  const businessUnitOptions = data.sbus
  const categoryOptions = data.categories
  return (
    <>
      <Card className={clsx(classes.root, classes.roundedCard)}>
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={10} sm={5}>
              <Paper
                component="form"
                onSubmit={event => {
                  event.preventDefault()
                  handleSearch(event)
                }}
                className={classes.searchField}
              >
                <InputBase
                  fullWidth
                  className={classes.input}
                  placeholder="Search Items"
                  inputProps={{ "aria-label": "search items" }}
                  onChange={event => setSearchBar(event.target.value)}
                  value={searchBar}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={handleClearSearchResults}
                >
                  <CancelIcon />
                </IconButton>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Sort By"
                name="sort"
                onChange={handleSortChange}
                select
                SelectProps={{ native: true }}
                value={sort}
                variant="outlined"
              >
                {sortOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>
        {/* <Box p={2} minHeight={56} display="flex" alignItems="center"> */}
        {/* <form
              noValidate
              onSubmit={event => {
                event.preventDefault()
                handleSearch(event)
              }}
            >
              <TextField
                className={classes.queryField}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={event => setSearchBar(event.target.value)}
                // onChange={handleQueryChange}
                placeholder="Search items"
                // value={query}
                value={searchBar}
                variant="outlined"
              />
              <Button type="submit" variant="contained" color="primary">
                Search
              </Button>
            </form> */}

        {/* <Box flexGrow={1} />
            <TextField
              label="Sort By"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={sort}
              variant="outlined"
            >
              {sortOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box> */}
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={3}>
              <TextField
                className={classes.businessUnitField}
                fullWidth
                label="Business Unit"
                name="businessUnit"
                onChange={handleBusinessUnitChange}
                select
                SelectProps={{ native: true }}
                value={filters.businessUnit || "all"}
                variant="outlined"
              >
                {businessUnitOptions.map(businessUnitOption => (
                  <option
                    key={businessUnitOption.id}
                    value={businessUnitOption.id}
                  >
                    {businessUnitOption.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                className={classes.categoryField}
                fullWidth
                label="Category"
                name="category"
                onChange={handleCategoryChange}
                select
                SelectProps={{ native: true }}
                value={filters.category || "all"}
                variant="outlined"
              >
                {categoryOptions.map(categoryOption => (
                  <option key={categoryOption.id} value={categoryOption.id}>
                    {categoryOption.name}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                className={classes.availabilityField}
                fullWidth
                label="Availability"
                name="availability"
                onChange={handleAvailabilityChange}
                select
                SelectProps={{ native: true }}
                value={filters.availability || "all"}
                variant="outlined"
              >
                {avalabilityOptions.map(avalabilityOption => (
                  <option
                    key={avalabilityOption.id}
                    value={avalabilityOption.id}
                  >
                    {avalabilityOption.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormControlLabel
                className={classes.stockField}
                control={
                  <Checkbox
                    checked={!!filters.inStock}
                    onChange={handleStockChange}
                    name="inStock"
                  />
                }
                label="In Stock"
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormControlLabel
                className={classes.digitalField}
                control={
                  <Checkbox
                    checked={!!filters.isDigital}
                    onChange={handleDigitalChange}
                    name="Digital"
                  />
                }
                label="Digital"
              />
            </Grid>
          </Grid>
        </Box>

        {/* {enableBulkOperations && (
          <div className={classes.bulkOperations}>
            <div className={classes.bulkActions}>
              <Checkbox
                checked={selectedAllProducts}
                indeterminate={selectedSomeProducts}
                onChange={handleSelectAllProducts}
              />
              <Button variant="outlined" className={classes.bulkAction}>
                Delete
              </Button>
              <Button variant="outlined" className={classes.bulkAction}>
                Edit
              </Button>
            </div>
          </div>
        )} */}
        <PerfectScrollbar>
          <Box minWidth={1200}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAllItems}
                      indeterminate={selectedSomeItems}
                      onChange={handleSelectAllItems}
                    />
                  </TableCell> */}

                  {/* <TableCell>Name</TableCell>
                <TableCell>Invetory</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Attributes</TableCell>
                <TableCell>Price</TableCell>
                <TableCell align="right">Actions</TableCell> */}
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Business Unit</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {paginatedProducts.map(item => { */}
                {selectedItems.map(item => (
                  // const isProductSelected = selectedProducts.includes(item.id)

                  // return (
                  // <TableRow hover key={item.id} selected={isProductSelected}>
                  <TableRow hover key={item.id}>
                    {/* <TableCell padding="checkbox">
                      <Checkbox

                      // checked={isProductSelected}
                      // onChange={event =>
                      //   handleSelectOneProduct(event, item.id)
                      // }
                      // value={isProductSelected}
                      />
                    </TableCell> */}
                    <TableCell className={classes.imageCell}>
                      <Link
                        className={classes.link}
                        variant="subtitle2"
                        color="textPrimary"
                        to={`/manager/items/${item.id}`}
                        state={{ id: `${item.id}` }}
                      >
                        {item.featuredImageThumbnailUrl ? (
                          <img
                            alt={item.name}
                            src={item.featuredImageThumbnailUrl}
                            className={classes.image}
                          />
                        ) : (
                          <Box p={2} bgcolor="background.dark">
                            <SvgIcon>
                              <ImageIcon />
                            </SvgIcon>
                          </Box>
                        )}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        className={classes.link}
                        variant="subtitle2"
                        color="textPrimary"
                        to={`/manager/items/${item.id}`}
                        state={{ id: `${item.id}` }}
                      >
                        {item.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {/* {getInventoryLabel(item.inventoryType)} */}
                      {item.isPrivate ? <AccountBoxIcon /> : <PublicIcon />}
                    </TableCell>
                    <TableCell>
                      {item.category.name}
                      {/* {item.quantity} in stock
                        {item.variants > 1 && ` in ${item.variants} variants`} */}
                    </TableCell>
                    {/* <TableCell>{item.attributes.map(attr => attr)}</TableCell> */}
                    <TableCell>{item.businessUnit.name}</TableCell>
                    <TableCell>
                      {item.isActive ? "Active" : "InActive"}
                      {/* {item.currency}
                        {item.price.toFixed(2)} */}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton>
                        <SvgIcon fontSize="small">
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                      <IconButton>
                        <SvgIcon fontSize="small">
                          <ArrowRightIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  // )
                ))}
              </TableBody>
            </Table>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, { label: "All" }]}
              colSpan={3}
              count={resCount}
              rowsPerPage={state.pageSize}
              page={state.page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            /> */}
            <TablePagination
              component="div"
              count={resCount}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
            {/* <TablePagination
              component="div"
              count={resCount}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              page={resPage}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: "All" }]}
            /> */}
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

Results.propTypes = {
  // className: PropTypes.string,
  items: PropTypes.array,
}

// Results.defaultProps = {
//   items: [],
// }

const FORM_INFO_QUERY = gql`
  query {
    categories {
      id
      name
      description
    }
    sbus {
      id
      name
    }
  }
`
export default Results
