import React from "react"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import NewPreregUser from "./NewPreregUser"
import { Box, Button, Card, Grid, Typography } from "@material-ui/core"
import { mergeClasses } from "@material-ui/styles"

const PreregUserForm = ({ setOwnerIsPrereg, setPreRegUser, person }) => {
  const uid = person.uid

  const { loading, error, data } = useQuery(PREREG_USER_QUERY, {
    variables: {
      uid: uid,
    },
  })

  const confirmPreReg = () => {
    let preRegister = data.preRegister
    setPreRegUser(preRegister)
    setOwnerIsPrereg(true)
  }

  if (loading) return <Loading />
  if (error)
    return (
      <NewPreregUser
        person={person}
        setOwnerIsPrereg={setOwnerIsPrereg}
        setPreRegUser={setPreRegUser}
      />
    )
  if (data == undefined)
    return (
      <NewPreregUser
        person={person}
        setOwnerIsPrereg={setOwnerIsPrereg}
        setPreRegUser={setPreRegUser}
      />
    )
  if (data == null)
    return (
      <NewPreregUser
        person={person}
        setOwnerIsPrereg={setOwnerIsPrereg}
        setPreRegUser={setPreRegUser}
      />
    )
  const preRegister = data.preRegister
  return (
    <>
      <Box mt={2} mb={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3">Matching Pre-Reg Account Found</Typography>
            <Typography variant="body1">
              {person.firstName} {person.lastName} with email{" "}
              {preRegister.preregEmail} is not a registered user but does have a
              pre-registered user account.
            </Typography>
            <Typography variant="body1">
              Assign item ownership to this pre-registered user account?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={confirmPreReg}
            >
              Confirm and Continue
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button fullWidth color="secondary" variant="outlined">
              Set Different Item Owner
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const PREREG_USER_QUERY = gql`
  query ($uid: String!) {
    preRegister(uid: $uid) {
      preregId
      preregEmail
      registered
    }
  }
`

export default PreregUserForm
