import React, { useContext } from "react"

import {
  Box,
  Card,
  CardContent,
  Container,
  makeStyles,
} from "@material-ui/core"
import OrgCreateContext from "../../context/OrgCreateContext"
import AddPersonForm from "./AddPersonForm"
import AddPersonEmailForm from "./AddPersonEmailForm"
import AddPersonPhoneForm from "./AddPersonPhoneForm"
import AddPersonAddressForm from "./AddPersonAddressForm"
import AddPersonSocialMediaForm from "./AddPersonSocialMediaForm"

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    paddingBottom: 40,
    paddingTop: 80,
  },
  button: {
    marginRight: 10,
  },
  card: {
    marginBottom: 15,
  },
  instructions: {
    marginTop: 10,
    marginBottom: 10,
  },
  stepper: {
    maxWidth: 400,
    flexGrow: 1,
  },
}))

const AddPerson = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(OrgCreateContext)
  const activeStep = state.newPersonActiveStep

  const getStepper = activeStep => {
    switch (activeStep) {
      case 0:
        return <AddPersonForm />
      case 1:
        return <AddPersonEmailForm />
      case 2:
        return <AddPersonPhoneForm />
      case 3:
        return <AddPersonAddressForm />
      case 4:
        return <AddPersonSocialMediaForm />
      default:
        return <AddPersonForm />
    }
  }

  return (
    <Container maxWidth="sm">
      <Card className={classes.card} elevation={0}>
        <CardContent>
          <Box className={classes.instructions}>{getStepper(activeStep)}</Box>
        </CardContent>
      </Card>
    </Container>
  )
}

export default AddPerson
