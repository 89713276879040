import React, { useState, useContext } from "react"
import { Box, Container, Dialog, Grid, makeStyles } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import Page from "../../../Global/Page"
import Header from "./Header"
import OrderInfo from "./OrderInfo"
import OrderItems from "./OrderItems"
import AddOrderItem from "./AddOrderItem"
import OrderPrint from "./OrderPrint"
import OrderContext from "./context/OrderContext"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const OrderDetails = ({ id }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(OrderContext)
  const { isAddingOrderItem, isSearching } = state
  // const [orderDetails, setOrderDetails] = useState("")
  // const [addOrderItem, setAddOrderItem] = useState(false)
  const [search, setSearch] = useState(false)
  // const [createNew, setCreateNew] = useState(false)
  // const [items, setItems] = useState([])
  const [printView, setPrintView] = useState(false)

  const handleClose = () => {
    setPrintView(false)
  }

  // if (loading) return <Loading />
  // if (error) return <Error />
  // if (data == undefined) return ""
  // if (data == null) {
  //   return ""
  // }

  return (
    <Page className={classes.root} title="Order Details">
      <Container maxWidth={false}>
        <Header setPrintView={setPrintView} />
        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item md={4} xl={3} xs={12}>
              <OrderInfo id={id} />
            </Grid>

            <Grid item md={8} xl={9} xs={12}>
              {isAddingOrderItem && (
                <Box mb={3}>
                  <AddOrderItem />
                </Box>
              )}

              <OrderItems />
            </Grid>
          </Grid>
        </Box>

        {/* <Dialog
          fullWidth
          maxWidth={"md"}
          scroll="body"
          open={printView}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <OrderPrint printView={printView} setPrintView={setPrintView} />
        </Dialog> */}
      </Container>
    </Page>
  )
}

export default OrderDetails
