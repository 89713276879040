import React, { createContext } from "react"

const ProposalListContext = createContext({
  search: "",
  searchBar: "",
  pageSize: 10,
  page: 1,
  pageIndex: 0,
  count: 0,
  selectedProposals: [],
  preregFilter: null,
  businessUnitFilter: null,
  likelihoodFilter: null,
})

export default ProposalListContext
