import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonBase,
  Grid,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core"
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Calendar as CalendarIcon,
  AlertTriangle as AlertIcon,
  DollarSign as DollarSignIcon,
  Send as SendIcon,
  FolderPlus as FolderPlusIcon,
} from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

import PublicIcon from "@material-ui/icons/Public"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
// import Application from './Application';

const useStyles = makeStyles(theme => ({
  root: {},
  badge: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2),
  },
  badgeIcon: {
    marginRight: theme.spacing(1),
  },
  action: {
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

function Header({ item, className, ...rest }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const itemName = item.name ? item.name : ""
  // const [openApplication, setOpenApplication] = useState(false);

  const handleOpenOrderMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseOrderMenu = () => {
    setAnchorEl(null)
  }
  // const handleAddToOrder = ()=>{
  //  ( item.isPrivate &&
  //   item.privateItem &&
  //   item.privateItem.itemOwner &&
  //   item.privateItem.itemOwner.uid) ? handleOpenPrivateItemDialog() : console.log("do something else. this could be a major flow.")
  //   }
  // }
  // const handleApplicationOpen = () => {
  //   setOpenApplication(true);
  // };

  // const handleApplicationClose = () => {
  //   setOpenApplication(false);
  // };

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item xs={12} sm={7}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            className={classes.link}
            variant="body1"
            color="inherit"
            to="/manager"
          >
            Dashboard
          </Link>
          <Link
            className={classes.link}
            variant="body1"
            color="inherit"
            to="/manager/items"
          >
            Items
          </Link>
          <Typography variant="body1" color="textPrimary">
            {itemName}
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12} sm={5} container spacing={3}>
        <Grid item>
          <Box>
            <Button
              fullWidth
              className={classes.action}
              // onClick={handleAddToOrder}
              // onClick={handleOpenOrderMenu}
              variant="contained"
              color="secondary"
            >
              <SvgIcon fontSize="small" className={classes.actionIcon}>
                <FolderPlusIcon />
              </SvgIcon>
              Add to an Order
            </Button>

            {/* <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseOrderMenu}
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleCloseOrderMenu}>Profile</MenuItem>
              <MenuItem onClick={handleCloseOrderMenu}>My account</MenuItem>
              <MenuItem onClick={handleCloseOrderMenu}>Logout</MenuItem>
            </Menu> */}
          </Box>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            className={classes.action}
            // onClick={handleApplicationOpen}
            variant="contained"
            color="secondary"
          >
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <DollarSignIcon />
            </SvgIcon>
            Add to a Proposal
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" color="textPrimary">
          {itemName}
        </Typography>
        <Box
          mx={-2}
          display="flex"
          color="text.secondary"
          alignItems="center"
          flexWrap="wrap"
        >
          <div className={classes.badge}>
            <Typography variant="body2" color="inherit" component="span">
              {item.businessUnit ? `${item.businessUnit.name}` : "NBU"}
            </Typography>
          </div>
          <div className={classes.badge}>
            <SvgIcon fontSize="small" className={classes.badgeIcon}>
              {item.isActive ? <CheckIcon /> : <AlertIcon />}
            </SvgIcon>
            <Typography variant="body2" color="inherit" component="span">
              {item.isActive ? "Active" : "Inactive"}
            </Typography>
          </div>
          <div className={classes.badge}>
            <SvgIcon fontSize="small" className={classes.badgeIcon}>
              {item.isPrivate ? <AccountBoxIcon /> : <PublicIcon />}
            </SvgIcon>
            <ButtonBase
              component={Link}
              to={`/manager/people/${
                item.isPrivate &&
                item.privateItem &&
                item.privateItem.itemOwner &&
                item.privateItem.itemOwner.uid
              }`}
              state={{
                uid: `${
                  item.isPrivate &&
                  item.privateItem &&
                  item.privateItem.itemOwner &&
                  item.privateItem.itemOwner.uid
                }`,
              }}
            >
              <Typography variant="body2" color="inherit" component="span">
                {item.isPrivate &&
                  item.privateItem &&
                  item.privateItem.itemOwner &&
                  item.privateItem.itemOwner.firstName +
                    " " +
                    item.privateItem.itemOwner.lastName}
              </Typography>
            </ButtonBase>
          </div>
          <div className={classes.badge}>
            <SvgIcon fontSize="small" className={classes.badgeIcon}>
              <CalendarIcon />
            </SvgIcon>
            <Typography variant="body2" color="inherit" component="span">
              {`Added ${moment(item.dateAdded).format("MMM Do YYYY")}`}
            </Typography>
          </div>
        </Box>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
}

Header.defaultProps = {}

export default Header
