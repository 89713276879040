import React from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { PlusCircle as AddIcon } from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import Label from "../../../Global/Label"
const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

function Header({ className, org, ...rest }) {
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            className={classes.link}
            variant="body1"
            color="inherit"
            to="/manager/"
          >
            Dashboard
          </Link>
          <Link
            className={classes.link}
            variant="body1"
            color="inherit"
            to="/manager/orgs/"
          >
            Orgs
          </Link>
          <Typography variant="body1" color="textPrimary">
            {org.name}
          </Typography>
        </Breadcrumbs>
        <Box display="block" mt={1}>
          <Typography variant="h3" color="textPrimary" display="inline">
            {org.name}
          </Typography>
          <Box ml={2} display="inline">
            {org.isSupplier && <Label color="error">Supplier</Label>}
          </Box>
        </Box>
      </Grid>
      {org.isSupplier && (
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            // href={`manager/people/edit/${person.uid}`}
            // onClick={event => {
            //   navigate(`manager/people/edit/${person.uid}`,
            //   {
            //     state:{uid:`${person.uid}`}
            //   },
            //   { replace: true }
            //   )}}
          >
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <AddIcon />
            </SvgIcon>
            New Order
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  person: PropTypes.object.isRequired,
}

export default Header
