import React, { useState } from "react"
import ItemSpecsForm from "./ItemSpecsForm"

import SpecProvider from "./context/SpecProvider"

const ItemSpecs = ({
  item,
  specs,
  updateItem,
  setUpdateItem,
  activeStep,
  setActiveStep,
  ...rest
}) => {
  return (
    <>
      <SpecProvider>
        <ItemSpecsForm
          specs={specs}
          item={item}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          updateItem={updateItem}
          setUpdateItem={setUpdateItem}
        />
      </SpecProvider>
    </>
  )
}

export default ItemSpecs
