import React from "react"
import { Container } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

import Page from "../../../Global/Page"
import Header from "./Header"
import Results from "./Results"

import ProposalListProvider from "./context/ProposalListProvider"

const ProposalListView = ({ classes }) => {
  return (
    <Page className={classes.root} title="Proposals">
      <Container maxWidth={false}>
        <Header />
        <ProposalListProvider>
          <Results />
        </ProposalListProvider>
      </Container>
    </Page>
  )
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "90vh",
    height: "100%",
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
})

export default withStyles(styles)(ProposalListView)
