import React from "react"
import BrowseProjects from "./BrowseProjects"
import ProjectProvider from "./context/ProjectProvider"
const Projects = () => {
  return (
    <ProjectProvider>
      <BrowseProjects />
    </ProjectProvider>
  )
}

export default Projects
