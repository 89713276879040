import React, { useState, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Link } from "gatsby"
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import gql from "graphql-tag"
import {
  ButtonBase,
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  Divider,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import UpdateBusinessUnit from "./UpdateItemForms/UpdateBusinessUnit"
import UpdatePricingModel from "./UpdateItemForms/UpdatePricingModel"
import UpdateCategory from "./UpdateItemForms/UpdateCategory"
import UpdatePrivateItem from "./UpdateItemForms/UpdatePrivateItem"
// import UpdateItemSpecs from "./UpdateItemForms/UpdateItemSpecs"
import ItemSpecs from "../../ItemCreateView/ItemCreateForms/ItemSpecs"

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 2,
    marginBotton: 5,
    borderRadius: "15px",
  },
  link: {
    textDecoration: "none",
    color: "#000000",
  },
  closeButton: {
    align: "right",
    marginTop: theme.spacing(1),
  },
  specSection: {
    padding: theme.spacing(2),
  },
  noBorderBottom: {
    borderBottom: "none",
  },
}))

const ItemInfo = ({ item, className, ...rest }) => {
  const classes = useStyles()
  const specDownload = JSON.parse(item.specItem.summary)

  const [spec, setSpec] = useState(item.specItem)
  const [itemName, setItemName] = useState(item.name)
  const [isActive, setIsActive] = useState(item.isActive)
  const [isPrivate, setIsPrivate] = useState(item.isPrivate)
  const [privateItem, setPrivateItem] = useState(item.privateItem)
  const [category, setCategory] = useState(item.category)
  const [businessUnit, setBusinessUnit] = useState(item.businessUnit)
  const [updateItem, setUpdateItem] = useState(false)
  const [formField, setFormField] = useState("")
  const [pricingModel, setPricingModel] = useState(item.pricingModel)
  const [categories, setCategories] = useState([])
  const [pricingModels, setPricingModels] = useState([])
  const [sbus, setSbus] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [rawSpecs, setRawSpecs] = useState(item.specItem.summary)

  const { loading, data, error } = useQuery(FORM_INFO_QUERY, {
    onCompleted: data => {
      setCategories(data.categories)
      setSbus(data.sbus)
      setPricingModels(data.pricingModels)
    },
  })

  const [updateItemInfo] = useMutation(UPDATE_ITEM_INFO, {
    variables: {
      itemId: item.id,
      name: itemName,
      isActive: isActive,
      isPrivate: isPrivate,
      pricingModelId: pricingModel.id,
      businessUnitId: businessUnit.id,
      categoryId: category.id,
    },
    onCompleted: data => {
      setUpdateItem(false)
      console.log(data)
    },
  })

  const [refreshItemInfo] = useLazyQuery(REFRESH_ITEM_QUERY, {
    variables: {
      id: item.id,
    },
    onCompleted: data => {
      let i = data.item
      setRawSpecs(i.specItem.summary)
      setItemName(i.name)
      setIsActive(i.isActive)
      setIsPrivate(i.isPrivate)
      setPrivateItem(i.privateItem)
      setCategory(i.category)
      setBusinessUnit(i.businessUnit)
      setPricingModel(i.pricingModel)
      // console.log("update complete")
    },
  })
  const itemSpec = JSON.parse(rawSpecs)

  const sortedSpecs = Object.entries(itemSpec).sort((a, b) =>
    b[0].localeCompare(a[0])
  )

  const measurementFields = ["width", "height", "length", "depth", "thickness"]
  const noteFields = ["notes", "note", "comments", "comment"]
  const specSize = []
  const specDetails = []
  const specNote = []

  for (const [key, value] of sortedSpecs) {
    let measurements = measurementFields.includes(key)
    if (measurements) console.log(value)
    let notes = noteFields.includes(key)
    let details = !measurements && !notes
    let format = (
      <Fragment key={key}>
        {isNaN(key.charAt(0)) &&
          (key.charAt(0).toUpperCase() + key.slice(1)).replace(/_/g, " ")}
        :{" "}
        {isNaN(value.charAt(0))
          ? value.charAt(0).toUpperCase() + value.slice(1)
          : value}
        <br />
      </Fragment>
    )
    if (measurements) specSize.push(format)
    if (notes) specNote.push(format)
    if (details) specDetails.push(format)
  }

  const handleOpenUpdateBusinessUnit = () => {
    setFormField("businessUnit")
    setUpdateItem(true)
  }

  const handleOpenUpdateCategory = () => {
    setFormField("category")
    setUpdateItem(true)
  }

  const handleOpenUpdatePrivate = () => {
    setFormField("private")
    setUpdateItem(true)
  }

  const handleOpenUpdatePricingModel = () => {
    setFormField("pricingModel")
    setUpdateItem(true)
  }
  const handleOpenUpdateSpecs = () => {
    setFormField("specs")
    setUpdateItem(true)
  }
  const handleCloseDialog = () => {
    setUpdateItem(false)
    refreshItemInfo()
  }

  const updateSwitch = formField => {
    switch (formField) {
      case "businessUnit":
        return (
          <UpdateBusinessUnit
            item={item}
            sbus={sbus}
            businessUnit={businessUnit}
            setBusinessUnit={setBusinessUnit}
            setUpdateItem={setUpdateItem}
            isSubmitting={isSubmitting}
            updateItemInfo={updateItemInfo}
          />
        )
      case "category":
        return (
          <UpdateCategory
            item={item}
            categories={categories}
            category={category}
            setCategory={setCategory}
            setUpdateItem={setUpdateItem}
            isSubmitting={isSubmitting}
            updateItemInfo={updateItemInfo}
          />
        )
      case "private":
        return (
          <UpdatePrivateItem
            item={item}
            isPrivate={isPrivate}
            setIsPrivate={setIsPrivate}
            privateItem={privateItem}
            setPrivateItem={setPrivateItem}
            setUpdateItem={setUpdateItem}
            isSubmitting={isSubmitting}
            updateItemInfo={updateItemInfo}
          />
        )
      case "pricingModel":
        return (
          <UpdatePricingModel
            item={item}
            pricingModels={pricingModels}
            pricingModel={pricingModel}
            setPricingModel={setPricingModel}
            setUpdateItem={setUpdateItem}
            isSubmitting={isSubmitting}
            updateItemInfo={updateItemInfo}
          />
        )
      case "specs":
        return (
          <ItemSpecs
            item={item}
            // spec={spec}
            // setSpec={setSpec}
            // specs={specs}
            updateItem={updateItem}
            setUpdateItem={setUpdateItem}
            // isSubmitting={isSubmitting}
            // updateItemInfo={updateItemInfo}
          />
        )
      default:
        return "category"
    }
  }

  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest} elevation={0}>
        <CardHeader title={itemName} />
        <Divider />
        <Dialog
          fullWidth
          onClose={handleCloseDialog}
          aria-labelledby="update-item-info"
          open={updateItem}
          formField={formField}
        >
          {updateSwitch(formField)}
        </Dialog>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <ButtonBase size="small" onClick={handleOpenUpdateBusinessUnit}>
                  Business Unit
                </ButtonBase>
              </TableCell>
              <TableCell>{businessUnit.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <ButtonBase size="small" onClick={handleOpenUpdateCategory}>
                  Item Category
                </ButtonBase>
              </TableCell>
              <TableCell>{category.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <ButtonBase size="small" onClick={handleOpenUpdatePrivate}>
                  Public / Private
                </ButtonBase>
              </TableCell>
              <TableCell>
                {isPrivate
                  ? privateItem && privateItem.isPrereg
                    ? "Pre-reg Private Item"
                    : "Private Item"
                  : "Public Item"}
              </TableCell>
            </TableRow>
            {isPrivate && (
              <TableRow>
                <TableCell>Item Owner</TableCell>
                <TableCell>
                  <Link
                    to={`/manager/people/${
                      privateItem &&
                      privateItem.itemOwner &&
                      privateItem.itemOwner.uid
                    }`}
                    className={classes.link}
                  >
                    {privateItem && privateItem.itemOwner.firstName}{" "}
                    {privateItem && privateItem.itemOwner.lastName}
                  </Link>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <ButtonBase size="small" onClick={handleOpenUpdatePricingModel}>
                  Pricing Model
                </ButtonBase>
              </TableCell>
              <TableCell>
                {pricingModel ? pricingModel.name : "Not Specified"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.noBorderBottom}>
                <ButtonBase size="small" onClick={handleOpenUpdateSpecs}>
                  Specs
                </ButtonBase>
              </TableCell>

              <TableCell className={classes.noBorderBottom}>
                <Box className={classes.specSection}>
                  <Typography variant="body1">{specSize}</Typography>
                  <Typography variant="body1" gutterBottom>
                    {specDetails}
                  </Typography>
                  <Typography variant="body1">{specNote}</Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    * measurements are in inches
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </>
  )
}

ItemInfo.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
}

const FORM_INFO_QUERY = gql`
  query {
    categories {
      id
      name
    }
    pricingModels {
      id
      name
    }
    sbus {
      id
      name
    }
  }
`
const UPDATE_ITEM_INFO = gql`
  mutation (
    $itemId: Int!
    $name: String!
    $isActive: Boolean
    $isPrivate: Boolean
    $categoryId: Int
    $businessUnitId: Int
    $pricingModelId: Int
  ) {
    updateItem(
      itemId: $itemId
      name: $name
      isActive: $isActive
      isPrivate: $isPrivate
      categoryId: $categoryId
      businessUnitId: $businessUnitId
      pricingModelId: $pricingModelId
    ) {
      item {
        id
        name
        category {
          id
          name
        }
        businessUnit {
          id
          name
        }
        isActive
        isPrivate
        pricingModel {
          id
          name
        }
        specItem {
          specId
          summary
        }
      }
    }
  }
`
const REFRESH_ITEM_QUERY = gql`
  query ($id: Int!) {
    item(id: $id) {
      id
      name
      isActive
      isPrivate
      specItem {
        specId
        item {
          id
          name
        }
        summary
      }
      pricingModel {
        id
        name
        description
      }
      itemImage {
        id
        image
        title
        note
        featured
        proof
        createdBy {
          id
          staff
          partner
          person {
            firstName
            lastName
            isSupplier
          }
        }
        uploadedAt
        imageUrl
        carouselImageUrl
        item {
          id
        }
      }
      category {
        id
        name
      }
      businessUnit {
        id
        name
      }
      customItemField {
        id
        name
        description
        isActive
      }
      dateAdded
      dateModified
      itemPrice {
        id
        minQuantity
        maxQuantity
        price
        name
        isActive
      }
      itemcostSet {
        id
        minQuantity
        maxQuantity
        cost
      }

      privateItem {
        isPrereg
        itemOwner {
          uid
          firstName
          lastName
        }
      }
      sampleProofItem {
        id
        status {
          id
          name
        }
        version
        image
        timestamp
        closed
        closedTimestamp
        sampleProofComment {
          id
          comment
          commentPerson {
            uid
            firstName
            lastName
          }
        }
        sampleProofUrl
      }
    }
  }
`
export default ItemInfo
