import React, { useState, useEffect } from "react"
import { useMutation } from "@apollo/client"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import gql from "graphql-tag"
import PreregInvite from "./PreregInvite"
import Alert from "@material-ui/lab/Alert"
import HelpIcon from "@material-ui/icons/Help"
import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
  makeStyles,
  TextField,
} from "@material-ui/core"
import clsx from "clsx"

export const WhatIsPrereg = () => {
  return (
    <>
      <DialogTitle>PreRegistration: What is this?</DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <Typography variant="body2">
              "Preregistration" is the in-between step that links a "person"
              account to a new "user" account.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              A "user" account is required for a person to log in to their
              dashboard.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              The "Create Prereg Acct" button will email this person a link with
              a personalized secure token in the url. The link sends the person
              to a "new user sign up page" with a form to create a new password.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              When the person registers, their existing "person" account will
              link to their new "user" account. This is useful because it allows
              us to gather information, create quotes and samples, and do other
              personalized tasks before a prospective customer decides to create
              a login account.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              The new user will have to re-verify their login email address
              before logging in for the first time. Other multi-step auth
              methods are available and will be implemented in a future release.
            </Typography>
          </ListItem>
        </List>
      </DialogContent>
    </>
  )
}
const useStyles = makeStyles(theme => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

const CreatePreregAccount = ({
  person,
  businessUnits,
  isPrereg,
  setIsPrereg,
  setPrereg,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const personUid = person.uid
  const [preregEmail, setPreregEmail] = useState("")
  const [businessUnit, setBusinessUnit] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [showAlert, setShowAlert] = useState(false)
  const [createPrereg] = useMutation(CREATE_PREREG_MUTATION, {
    variables: {
      email: preregEmail,
      guid: personUid,
      sbuId: businessUnit,
      emailMe: true,
      withOrder: false,
    },
    onCompleted: (data, response, errors) => {
      console.log(data)
      setPrereg(data.sendPreregLink.prereg)
      setMessage(data.sendPreregLink.message)
      setIsPrereg(true)
      setIsSubmitting(false)
    },
  })
  const handleUpdateBusinessUnit = event => {
    let value = event.target.value
    setBusinessUnit(value)
  }
  useEffect(() => {
    if (message && message.length > 3) {
      setShowAlert(true)
    }
  }, [message])
  const handleCloseAlert = event => {
    setShowAlert(false)
    setMessage("")
  }
  return (
    <>
      <Card className={classes.roundedCard}>
        <CardHeader
          title="Create a Prereg Account"
          action={
            <IconButton
              aria-label="What is this?"
              onClick={() => setDialogOpen(true)}
            >
              <HelpIcon color="primary" />
            </IconButton>
          }
        />
        <Divider />
        <Box padding={1}>
          <form
            onSubmit={event => {
              event.preventDefault()
              setIsSubmitting(true)
              createPrereg(event)
            }}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="preregEmail"
                  select
                  SelectProps={{ native: true }}
                  onChange={event => setPreregEmail(event.target.value)}
                  type="email"
                  value={preregEmail}
                  variant="outlined"
                >
                  <option></option>
                  {person.emailPerson.map(email => (
                    <option key={email.id}>{email.emailAddress}</option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  label="Business Unit"
                  margin="normal"
                  name="businessUnit"
                  select
                  SelectProps={{ native: true }}
                  onChange={handleUpdateBusinessUnit}
                  type="text"
                  value={businessUnit}
                  variant="outlined"
                >
                  <option></option>
                  {businessUnits.map(sbu => (
                    <option key={sbu.id} value={sbu.id}>
                      {sbu.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>
                  <Dialog
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                  >
                    <WhatIsPrereg />
                  </Dialog>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Create Prereg Acct
                  </Button>
                  {showAlert && (
                    <Alert onClose={handleCloseAlert}>{message}</Alert>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
    </>
  )
}
const CREATE_PREREG_MUTATION = gql`
  mutation (
    $guid: String!
    $sbuId: Int
    $email: String
    $emailMe: Boolean
    $withOrder: Boolean
  ) {
    sendPreregLink(
      guid: $guid
      sbuId: $sbuId
      email: $email
      emailMe: $emailMe
      withOrder: $withOrder
    ) {
      message
      prereg {
        preregId
        preregEmail
        person {
          uid
          firstName
          lastName
        }
      }
    }
  }
`

export default CreatePreregAccount
