import React, { useState, useEffect } from "react"
import clsx from "clsx"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import PrivateItemOwnerSearch from "./PrivateItemOwnerSearch"
import PrivateItemUserSearch from "./PrivateItemUserSearch"
import PreregItemCreateForm from "./PreregItemCreateForm"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: { padding: "1px" },
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
  title: {
    margin: theme.spacing(2, 0, 1),
  },
}))
const PrivateItemCreateForm = ({
  item,
  person,
  setPerson,
  setActiveStep,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const itemName = item.name
  const itemId = item.id
  //   const [personUid, setPersonUid] = useState("")
  //   const [personName, setPersonName] = useState("")

  const [pickPerson, setPickPerson] = useState(false)
  const [foundUser, setFoundUser] = useState(false)
  const [user, setUser] = useState(null)
  const [isPrereg, setIsPrereg] = useState(true)
  const [preregUser, setPreregUser] = useState(null)
  const [itemOwnerId, setItemOwnerId] = useState("")

  const nextStep = event => {
    {
      isPrereg ? setActiveStep(2) : setActiveStep(3)
    }
  }

  const [handleCreatePrivateItem] = useMutation(NEW_PRIVATE_ITEM_MUTATION, {
    variables: {
      itemId: itemId,
      itemOwnerId: itemOwnerId,
      isPrereg: isPrereg,
    },

    onCompleted: data => {
      nextStep()
    },
  })

  useEffect(() => {
    if (person) {
      setItemOwnerId(person.uid)
      setPickPerson(true)
      if (person.user) {
        setIsPrereg(false)
      }
    }
  }, [])
  return (
    //   Private items are customized and can only be purchased by one designated person, the "owner".
    <>
      {/* <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          title={`Private Item Owner: ${itemName}`}
          // title={`${order.businessUnit.name} Order # ${order.id} | Status: ${order.status.orderStatus}`}
        /> */}
      {/* <Divider /> */}
      {/* <Box padding={2}>
        <Typography variant="h4">Private Item Owner: {itemName}</Typography>
      </Box> */}
      <Typography className={classes.title} variant="h4">
        Private Item Owner: {itemName}
      </Typography>
      {/* <CardContent> */}
      {pickPerson ? (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {person.user ? (
                //   Messaging alerts user of their selection and provides the option to change it.
                <Typography variant="body1">
                  This item's owner is {person.firstName} {person.lastName} with
                  user account {person.user.email}.
                </Typography>
              ) : (
                <Box minHeight="200px">
                  <Box mt={3}>
                    <Typography variant="h5">
                      Item owner: {person.firstName} {person.lastName}
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography variant="body1">
                      {person.firstName} is in the customer database but does
                      not yet have a registered user account. Find or create{" "}
                      {person.firstName}'s pre-registration-user account in the
                      next step.
                    </Typography>
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleCreatePrivateItem}
              >
                Confirm Private Item Owner
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                color="secondary"
                variant="outlined"
                onClick={event => setPickPerson(false)}
              >
                Change Item Owner
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        // Search the Person DB for the correct item owner
        <>
          <PrivateItemOwnerSearch
            setPerson={setPerson}
            setPickPerson={setPickPerson}
            setItemOwnerId={setItemOwnerId}
            setIsPrereg={setIsPrereg}
          />
        </>
      )}
      {/* </CardContent>
      </Card> */}
    </>
  )
}

const NEW_PRIVATE_ITEM_MUTATION = gql`
  mutation ($itemId: Int!, $itemOwnerId: String, $isPrereg: Boolean) {
    createPrivateItem(
      itemId: $itemId
      itemOwnerId: $itemOwnerId
      isPrereg: $isPrereg
    ) {
      privateItem {
        isPrereg
        item {
          id
          name
        }
        itemOwner {
          firstName
          lastName
        }
      }
    }
  }
`
export default PrivateItemCreateForm
