import React, { useEffect, useState } from "react"
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import PerfectScrollbar from "react-perfect-scrollbar"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

import Page from "../../../Global/Page"
import Header from "./Header"
import InquiryInfo from "./InquiryInfo"
import UserInquiryInfo from "./UserInquiryInfo"
import InquiryItems from "./InquiryItems"
import InquiryNotes from "./InquiryNotes"
import { Link } from "gatsby"

const useStyles = makeStyles(() => ({
  root: {
    height: "91vh",
    paddingTop: 2,
    paddingBottom: 2,
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

const InquiryDetailsView = ({ id }) => {
  const classes = useStyles()
  const [isPerson, setIsPerson] = useState(false)
  const [person, setPerson] = useState("")
  const [userInquiry, setUserInquiry] = useState(false)
  // const [inquiry, setInquiry] = useState({id})

  const { loading, error, data } = useQuery(INQUIRY_DETAILS_QUERY, {
    variables: { id: id },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const inquiry = data.inquiry
      if (inquiry.userInquiry) {
        setUserInquiry(true)
        setIsPerson(true)
        setPerson(inquiry.userInquiryPerson)
      }
      if (inquiry.inquiryPerson && inquiry.inquiryPerson.person) {
        setPerson(inquiry.inquiryPerson.person)
        setIsPerson(true)
      }
      console.log(data)
    },
  })

  // const handleSetPerson = (event) =>{
  //   useQuery(FIND_PERSON_QUERY)
  // }

  if (loading) return <Loading />
  if (error) return <Error />

  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const inquiry = data.inquiry

  return (
    <Page className={classes.root} title="Inquiry Details">
      <PerfectScrollbar>
        <Container maxWidth={false}>
          <Header inquiry={inquiry} />
          <Box mt={2} mb={2}>
            <Grid container alignItems="flex-start" spacing={3}>
              {/* <Grid container spacing={3} direction="row" justify="flex-start"> */}
              <Grid item md={4} xl={3} xs={12}>
                {userInquiry ? (
                  <UserInquiryInfo inquiry={inquiry} />
                ) : (
                  <InquiryInfo
                    inquiry={inquiry}
                    setPerson={setPerson}
                    person={person}
                    isPerson={isPerson}
                    setIsPerson={setIsPerson}
                  />
                )}
              </Grid>
              <Grid
                item
                container
                alignItems="flex-start"
                spacing={3}
                xs={12}
                md={8}
              >
                {/* <Grid
                item
                container
                md={8}
                xl={9}
                xs={12}
                direction="column"
                justify="flex-start"
                alignItems="stretch"
              > */}
                <Grid item xs={12}>
                  {inquiry.contactFormMessage ? (
                    <Card className={classes.roundedCard}>
                      <CardHeader title="Contact Form Message" />
                      <CardContent>
                        <Typography variant="body2" color="textSecondary">
                          {inquiry.message}
                        </Typography>
                      </CardContent>
                    </Card>
                  ) : (
                    <InquiryItems
                      inquiry={inquiry}
                      person={person}
                      isPerson={isPerson}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InquiryNotes inquiry={inquiry} />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </PerfectScrollbar>
    </Page>
  )
}

export const INQUIRY_DETAILS_QUERY = gql`
  query ($id: Int!) {
    inquiry(id: $id) {
      id
      new
      deleteMe
      spam
      inquiryUuid
      userInquiry
      submitted
      notes
      quoteCreated
      samplesRequested
      message
      contactFormMessage
      userInquiryPerson {
        uid
        firstName
        lastName
        emailPerson {
          id
          emailAddress
        }
        user {
          email
        }
        phonePerson {
          id
          phoneType {
            id
            phoneType
          }
          phoneNumber
        }
      }
      inquiryPerson {
        id
        firstName
        lastName
        middleName
        suffix
        nickname
        title
        isSupplier
        isOrg
        orgName
        isPerson
        person {
          uid
          firstName
          lastName
          emailPerson {
            id
            emailAddress
          }
          user {
            email
          }
        }
      }
      businessUnit {
        id
        name
      }
      source
      spam
      createdAt
      processedAt
      processedBy {
        id
        email
      }
      inquiryPhone {
        id
        phoneNumber
        phoneType {
          id
          phoneType
        }
      }
      inquiryAddress {
        uid
        inquiry {
          id
          submitted
        }
        region {
          id
          name
          label
          worldRegion
          globalRegion
        }
        attention
        addressLine1
        addressLine2
        addressLine3
        zone
        city
        postalCode
      }
      emailInquiry {
        id
        emailAccount
      }
      quoteCreated
      quoteInquiry {
        id
        createdBy {
          person {
            uid
            firstName
            lastName
          }
        }
        createdAt
        sent
        sentAt
      }
      inquiryInquiryItem {
        id
        attachment
        attachmentUrl
        inquiryItemType {
          id
          name
          description
        }
        note
        description
      }
    }
  }
`
export default InquiryDetailsView
