import React, { useState, useEffect, useContext, useReducer } from "react"
import OrderContext from "./OrderContext"
import OrderReducer from "./orderReducer"

const OrderProvider = props => {
  const [localState, setLocalState] = useState("")
  const initialState = useContext(OrderContext)
  const [state, dispatch] = useReducer(OrderReducer, localState || initialState)

  return (
    <OrderContext.Provider value={{ state, dispatch }}>
      {props.children}
    </OrderContext.Provider>
  )
}
export default OrderProvider
