import React, { useCallback, useState, useEffect } from "react"
import {
  Box,
  Container,
  Divider,
  Tabs,
  Tab,
  makeStyles,
} from "@material-ui/core"
import { useQuery } from "@apollo/client"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import gql from "graphql-tag"
import useIsMountedRef from "../../../../hooks/useIsMountedRef"
import Page from "../../../Global/Page"
import Header from "./Header"
import Overview from "./Overview"
import Files from "./Files"
import Activity from "./Activity"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "90vh",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

function ItemDetailsView({ id }) {
  const classes = useStyles()
  const isMountedRef = useIsMountedRef()
  const [currentTab, setCurrentTab] = useState("overview")
  const [files, setFiles] = useState([])
  const [workingFiles, setWorkingFiles] = useState([])
  const { loading, error, data } = useQuery(ITEM_DETAILS_QUERY, {
    variables: { id: id },
    fetchPolicy: "cache-and-network",
    onCompleted: (data, response, errors) => {
      setFiles(data.item.itemImage)
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const item = data.item
  const tabs = [
    { value: "overview", label: "Overview" },
    { value: "files", label: "Files" },
    { value: "activity", label: "Activity" },
  ]

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  return (
    <Page className={classes.root} title="Item Details">
      <Container maxWidth={false}>
        <Header item={item} />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="secondary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === "overview" && <Overview item={item} files={files} />}
          {currentTab === "files" && <Files item={item} />}
          {currentTab === "activity" && <Activity item={item} />}
        </Box>
      </Container>
    </Page>
  )
}

export const ITEM_DETAILS_QUERY = gql`
  query ($id: Int!) {
    item(id: $id) {
      id
      name
      isActive
      isPrivate
      specItem {
        specId
        item {
          id
          name
        }
        summary
      }
      pricingModel {
        id
        name
        description
      }

      itemImage {
        id
        image
        title
        note
        featured
        proof
        createdBy {
          id
          staff
          partner
          person {
            firstName
            lastName
            isSupplier
          }
        }
        uploadedAt
        carouselImageUrl
      }
      category {
        id
        name
      }
      businessUnit {
        id
        name
      }
      customItemField {
        id
        name
        description
        isActive
      }
      dateAdded
      dateModified
      itemPrice {
        id
        minQuantity
        maxQuantity
        price
        name
        isActive
      }
      itemcostSet {
        id
        minQuantity
        maxQuantity
        cost
      }

      privateItem {
        isPrereg
        itemOwner {
          uid
          firstName
          lastName
        }
      }
      sampleProofItem {
        id
        status {
          id
          name
        }
        version
        image
        isVertical
        isPrototypePhoto
        timestamp
        closed
        closedTimestamp
        sampleProofComment {
          id
          comment
          commentPerson {
            uid
            firstName
            lastName
          }
        }
        sampleProofUrl
      }
    }
  }
`
export default ItemDetailsView
