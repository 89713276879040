import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { Edit as EditIcon } from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { useLocation } from "@reach/router"

const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}))

function Header({ batch, className, ...rest }) {
  const classes = useStyles()
  const batchId = batch.id
  const location = useLocation()
  const userPermission = location.pathname.split("/")[1]
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to={`/${userPermission}`}
            component={Link}
          >
            Dashboard
          </Link>
          <Link
            variant="body1"
            color="inherit"
            to={`/${userPermission}/supply/suppliers`}
            component={Link}
          >
            Suppliers
          </Link>
          <Typography variant="body1" color="textPrimary">
            Batch {batchId}
          </Typography>
        </Breadcrumbs>
        <Box mt={2}>
          <Typography variant="h3" color="textPrimary">
            Batch Details
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Button color="secondary" variant="contained">
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <EditIcon />
          </SvgIcon>
          Edit
        </Button>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  batch: PropTypes.object.isRequired,
}

export default Header
