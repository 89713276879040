import React, { useEffect, useContext, useReducer } from "react"
import SortContext from "./SortContext"
import SortReducer from "./SortReducer"

const SortConducer = props => {
  const initialState = useContext(SortContext)
  const isBrowser = () => typeof window !== "undefined"
  const localState =
    isBrowser() && JSON.parse(window.localStorage.getItem("srt"))
  const [state, dispatch] = useReducer(SortReducer, localState || initialState)

  useEffect(() => {
    isBrowser() && window.localStorage.setItem("srt", JSON.stringify(state))
  }, [state])

  return (
    <SortContext.Provider value={{ state, dispatch }}>
      {props.children}
    </SortContext.Provider>
  )
}

export default SortConducer
