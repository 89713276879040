import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  avatar: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    padding: 2,
    marginBotton: 5,
  },
}))

const ItemProductionInfo = ({ item, className, ...rest }) => {
  const classes = useStyles()

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Item Production" />
      <Divider />
      <CardContent>
        <List disablePadding>
          <ListItem
          //   divider={i < referrals.length - 1}
          //   key={referral.name}
          >
            <Chip variant="outlined" size="small" label="123" />
            <ListItemText
              primary="Prod info"
              primaryTypographyProps={{ variant: "h6" }}
            />

            <Typography variant="body2" color="textSecondary">
              Date
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Status
            </Typography>
          </ListItem>
        </List>
      </CardContent>
      <CardActions>
        <Button fullWidth size="small" color="primary" variant="contained">
          Start New Production Order
        </Button>
      </CardActions>
    </Card>
  )
}

ItemProductionInfo.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
}
export default ItemProductionInfo
