import React, { useState } from "react"
import clsx from "clsx"
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  IconButton,
  Switch,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"

import {
  Edit as EditIcon,
  XCircle as DeleteIcon,
  XSquare as CancelIcon,
  Search as SearchIcon,
  PlusCircle as AddIcon,
} from "react-feather"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(2),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}))
const QuoteMessage = ({ quote }) => {
  const classes = useStyles()
  const quoteNotes = quote.notes
  const [message, setMessage] = useState(quoteNotes)
  const [editNote, setEditNote] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [updateMessage, { data, loading, error }] = useMutation(
    UPDATE_NOTES_MUTATION,
    {
      variables: {
        quoteId: quote.id,
        notes: message,
      },
      onCompleted: data => {
        setMessage(data.updateQuoteNote.quote.notes)
        setEditNote(false)
        setIsSubmitting(false)
      },
    }
  )

  const handleSubmit = (event, updateMessage) => {
    event.preventDefault()
    setIsSubmitting(true)
    updateMessage()
  }

  const handleCancel = event => {
    setEditNote(false)
    setMessage(quoteNotes)
  }
  return (
    <>
      <Card>
        <CardHeader
          title="Message to Customer"
          action={
            editNote ? (
              <>
                <IconButton
                  aria-label="cancel"
                  color="primary"
                  onClick={handleCancel}
                >
                  <CancelIcon />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={() => setEditNote(true)}
                >
                  <EditIcon />
                </IconButton>
              </>
            )
          }
        />
        <Divider />

        <form
          onSubmit={event => handleSubmit(event, updateMessage)}
          //   className={clsx(classes.root, className)}
          //   {...rest}
        >
          {editNote ? (
            <TextField
              fullWidth
              multiline
              disabled={isSubmitting}
              rows={4}
              //   variant="outlined"
              //   label="Message to Customer"
              value={message}
              onChange={event => setMessage(event.target.value)}
              id="message-to-customer"
              className={classes.margin}
            />
          ) : (
            <CardContent>
              <Typography variant="body1">
                {message ? message : "Add a message"}
              </Typography>
            </CardContent>
          )}
          {editNote && (
            <CardActions>
              <Button
                type="submit"
                size="small"
                color="primary"
                variant="outlined"
                disabled={isSubmitting}
              >
                Save
              </Button>
              <Button
                size="small"
                color="secondary"
                onClick={handleCancel}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </CardActions>
          )}
        </form>
      </Card>
    </>
  )
}

const UPDATE_NOTES_MUTATION = gql`
  mutation ($quoteId: Int!, $notes: String) {
    updateQuoteNote(quoteId: $quoteId, notes: $notes) {
      quote {
        id
        notes
      }
    }
  }
`
export default QuoteMessage
