import React, { useState } from "react"
import { Box, Grid, Button, Typography } from "@material-ui/core"
import Orders from "./Orders"
import Carts from "./Carts"
import Inquiries from "./Inquiries"
import Proposals from "./Proposals"
const Account = ({ person }) => {
  const [section, setSection] = useState("orders")
  const sectionSwitch = section => {
    switch (section) {
      case "orders":
        return <Orders person={person} />
      case "carts":
        return <Carts person={person} />
      case "inquiries":
        return (
          <div>
            <Inquiries person={person} />
          </div>
        )
      case "proposals":
        return (
          <div>
            <Proposals person={person} />
          </div>
        )
      case "invoices":
        return <div>Invoices</div>
      case "payments":
        return <div>Payments</div>
      case "delivery":
        return <div>Delivery</div>
      default:
        return <div>None Selected</div>
    }
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant={section === "inquiries" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("inquiries")}
          >
            Inquiries
          </Button>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant={section === "proposals" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("proposals")}
          >
            Proposals
          </Button>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Button
            fullWidth
            variant={section === "carts" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("carts")}
          >
            Carts
          </Button>
        </Grid>

        <Grid item xs={12} sm={1}>
          <Button
            fullWidth
            variant={section === "orders" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("orders")}
          >
            Orders
          </Button>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant={section === "invoices" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("invoices")}
          >
            Invoices
          </Button>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant={section === "payments" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("payments")}
            // disabled={asset === "inquiries"}
          >
            Payments
          </Button>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant={section === "delivery" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("delivery")}
            // disabled={asset === "inquiries"}
          >
            Delivery
          </Button>
        </Grid>
      </Grid>
      <Box mt={3}>{sectionSwitch(section)}</Box>
    </>
  )
}

export default Account
