import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  makeStyles,
} from "@material-ui/core"
// import ReceiptIcon from "@material-ui/icons/ReceiptOutlined"

const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}))

// const statusOptions = ["Canceled", "Completed", "Rejected"]

const BatchInfo = ({ batch, className, ...rest }) => {
  const classes = useStyles()
  //   const [status, setStatus] = useState("Change This")
  //   const discount = order.discount
  //   const handleChange = event => {
  //     event.persist()
  //     setStatus(event.target.value)
  //   }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={`Batch# ${batch.id}`} />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Supplier</TableCell>
            <TableCell>
              <Link
                component={Link}
                to={`/manager/supply/suppliers/${batch.supplier.ouid}`}
              >
                {batch.supplier.name}
              </Link>
            </TableCell>
          </TableRow>
          {batch.shipFrom && (
            <TableRow>
              <TableCell>Ship From</TableCell>
              <TableCell>
                <Link
                  component={Link}
                  to={`/manager/supply/suppliers/${batch.supplier.ouid}`}
                >
                  {batch.supplier.name}
                </Link>
              </TableCell>
            </TableRow>
          )}
          {batch.shipTo && (
            <TableRow>
              <TableCell>Ship To</TableCell>
              <TableCell>
                <Link
                  component={Link}
                  to={`/manager/people/${batch.shipTo.uid}`}
                >
                  {batch.shipTo.firstName} {batch.shipTo.lastName}
                </Link>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Batch Status</TableCell>
            <TableCell>{batch.batchStatus.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Carrier</TableCell>
            <TableCell>
              {batch.carrier.name}
              <br />
              {batch.tracking}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Expected Arrival</TableCell>
            <TableCell>
              {moment(batch.expectedArrival).format("DD/MM/YYYY")}
            </TableCell>
          </TableRow>
          {batch.actualArrival && (
            <TableRow>
              <TableCell>Actual Arrival</TableCell>
              <TableCell>
                {moment(batch.actualArrival).format("DD/MM/YYYY HH:MM")}
              </TableCell>
            </TableRow>
          )}
          {/* <TableRow>
            <TableCell>Batch Status</TableCell>
            <TableCell>
              <TextField
                fullWidth
                name="option"
                size="small"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={status}
                variant="outlined"
              >
                <option value={"Change This"}>Change This</option>
                <option value={"Not Set Up"}>Not Set Up</option>
              </TextField>
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </Card>
  )
}

BatchInfo.propTypes = {
  className: PropTypes.string,
}

export default BatchInfo
