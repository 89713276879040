import React, { useRef, useContext } from "react"

import { makeStyles } from "@material-ui/core"
import Page from "../../Global/Page"

import Sidebar from "./Sidebar"
import ConversationList from "./ConversationList"
import ConversationDetails from "./ConversationDetails"
import InviteList from "./InvitesList"
import ConversationNew from "./ConversationNew"
import MessagesContext from "./context/MessagesContext"

const useStyles = makeStyles(() => ({
  root: {
    height: "91vh",
    display: "flex",
    // overflow: "hidden",
    overflowY: "hidden",
    overflowX: "hidden",
    position: "relative",
  },
}))

const Messages = () => {
  const classes = useStyles()
  const { state } = useContext(MessagesContext)
  const panel = state.panel ? state.panel : "conversationList"
  const pageRef = useRef(null)

  const panelSwitch = panel => {
    switch (panel) {
      case "conversationList":
        return <ConversationList />
      case "newInvites":
        return <InviteList />
      case "conversationSelected":
        return <ConversationDetails />
      case "conversationNew":
        return <ConversationNew />
      default:
        return "conversationList"
    }
  }

  return (
    <Page className={classes.root} title="Conversations" ref={pageRef}>
      <Sidebar containerRef={pageRef} />
      {panelSwitch(panel)}
    </Page>
  )
}

export default Messages
