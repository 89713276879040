import React from "react"
import { Box, Button, Container, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
    padding: 3,
  },
  confirm: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const ConfirmOrderPerson = ({
  person,
  setPersonUid,
  setConfirmed,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const uid = person.uid

  const handleConfirm = event => {
    setPersonUid(uid)
    setConfirmed(true)
  }

  return (
    <>
      <Box m={5}>
        <Button
          onClick={handleConfirm}
          color="secondary"
          fullWidth
          size="large"
          variant="contained"
        >
          Confirm {person.firstName} {person.lastName}
        </Button>
      </Box>
    </>
  )
}

export default ConfirmOrderPerson
