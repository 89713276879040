export default function reducer(state, action) {
  switch (action.type) {
    case "SET_PAGE":
      return {
        ...state,
        page: action.payload,
      }
    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageSize: action.payload,
      }
    case "SET_SEARCH":
      return {
        ...state,
        search: action.payload,
      }
    case "SET_PAGE_COUNT":
      return {
        ...state,
        pageCount: action.payload,
      }
    case "SET_USER_PERM":
      return {
        ...state,
        userPerm: action.payload,
      }
    case "SET_SELECTED_TASK":
      return {
        ...state,
        selectedTask: action.payload,
      }
    case "SET_TASKS":
      return {
        ...state,
        tasks: action.payload,
      }
    case "SET_TASK_FILTERS":
      return {
        ...state,
        taskFilters: action.payload,
      }
    case "SET_SHOW_UNASSIGNED_TASKS":
      return {
        ...state,
        showUnassignedTasks: action.payload,
      }
    case "SET_ALL_STAFF_COLUMNS":
      return {
        ...state,
        allStaffColumns: action.payload,
      }
    case "SET_ACTIVE_STAFF_COLUMNS":
      return {
        ...state,
        activeStaffColumns: action.payload,
      }
    case "SET_STAFF_TASKS":
      return {
        ...state,
        staffTasks: action.payload,
      }
    default:
      return state
  }
}
