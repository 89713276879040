import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import ImageSearchIcon from "@material-ui/icons/ImageSearch"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import CircularProgress from "@material-ui/core/CircularProgress"
import Divider from "@material-ui/core/Divider"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import Alert from "@material-ui/lab/Alert"
import TextField from "@material-ui/core/TextField"

import { Box, Checkbox, Input } from "@material-ui/core"
import gql from "graphql-tag"
// import { useMutation } from "@apollo/client"
import { useApolloClient, useMutation, useQuery } from "@apollo/client"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(1),
  },
  save: {
    color: "green",
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
}))

const UploadButton = ({
  item,
  files,
  setFiles,
  workingFiles,
  setWorkingFiles,
}) => {
  const [file, setFile] = useState("")
  const [title, setTitle] = useState("")
  const [note, setNote] = useState("")
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [fileError, setFileError] = useState("")
  const [isFeatured, setIsFeatured] = useState(false)
  const [isActive, setIsActive] = useState(true)
  const [isWorkingFile, setIsWorkingFile] = useState(true)
  const classes = useStyles()

  const apolloClient = useApolloClient()

  const id = item.id

  const [handleUploadImageFile, { data }] = useMutation(
    ITEM_IMAGE_UPLOAD_MUTATION,
    {
      variables: {
        itemId: id,
        image: file,
        title: title,
        note: note,
        featured: isFeatured,
        proof: false,
      },

      onCompleted: (data, response, errors) => {
        let imageData = data.uploadImageFile.itemImage
        const newImage = { ...imageData }
        setFiles(files => [...files, newImage])
        setFile("")
        setTitle("")
        setNote("")
        setIsFeatured(false)
        setSubmitting(false)
        setSuccess(true)
      },
      onError: err => console.error(err),
      // update={handleUpdateCache}
    }
  )
  const [handleUploadWorkingFile] = useMutation(
    ITEM_WORKING_FILE_UPLOAD_MUTATION,
    {
      variables: {
        itemId: id,
        workingFile: file,
        comments: note,
        isActive: isActive,
        proof: false,
      },

      onCompleted: (data, response, errors) => {
        let fileData = data.createItemWorkingFile
        const newFile = { ...fileData }
        setWorkingFiles(workingFiles => [...files, newFile])
        setFile("")
        setTitle("")
        setNote("")
        setIsActive(true)
        setIsFeatured(false)
        setSubmitting(false)
        setSuccess(true)
      },
      onError: err => console.error(err),
      // update={handleUpdateCache}
    }
  )
  const handleSubmit = () => {
    if (isWorkingFile) {
      handleUploadWorkingFile()
    } else handleUploadImageFile()
  }

  const handleImageChange = event => {
    const selectedFile = event.target.files[0]
    const fileSizeLimit = 10000000 // 10MB
    if (selectedFile && selectedFile.size > fileSizeLimit) {
      setFileError(`${selectedFile.name}: File size too large`)
    } else {
      setFile(selectedFile)
      setFileError("")
      console.log(selectedFile)
    }
  }

  const handleCheckFeatured = event => {
    setIsFeatured(event.target.checked)
  }
  const handleCheckWorkingFile = event => {
    setIsWorkingFile(event.target.checked)
  }
  const handleCheckActive = event => {
    setIsActive(event.target.checked)
  }
  // const handleUpdateCache = (cache, response) => {
  //   const data = cache.readQuery({ query: ITEM_IMAGES_QUERY })
  //   const images = data.itemImg.concat()
  //   cache.writeQuery({ query: ITEM_IMAGES_QUERY, data: {} })
  // }

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Upload a File
          </Typography>
          <Box alignItems="center" display="flex" mt={2} ml={-1}>
            <Checkbox
              checked={isWorkingFile}
              // name="supplier"
              onChange={handleCheckWorkingFile}
            />
            <Typography variant="body2" color="textSecondary">
              Working File for Staff Use Only
            </Typography>
          </Box>
        </CardContent>

        <form
          onSubmit={event => {
            event.preventDefault()
            handleSubmit(event)
          }}
        >
          <FormControl error={Boolean(fileError)}>
            {/* <FormControl> */}
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              required
              onChange={handleImageChange}
            />
            <label htmlFor="contained-button-file">
              <Button
                component="span"
                variant="contained"
                color="default"
                className={classes.button}
                startIcon={<ImageSearchIcon />}
              >
                {isWorkingFile
                  ? "Select a working file"
                  : "Select an Image File"}
              </Button>

              {file && file.name}
              <FormHelperText>{fileError}</FormHelperText>
            </label>
            {!isWorkingFile && (
              <TextField
                id="standard-full-width"
                label="Title"
                style={{ margin: 8 }}
                placeholder="Image Title (optional)"
                fullWidth
                margin="normal"
                onChange={e => setTitle(e.target.value)}
                value={title}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            <TextField
              id="standard-full-width"
              label="Note"
              style={{ margin: 8 }}
              placeholder="Note about this image (optional)"
              fullWidth
              margin="normal"
              onChange={e => setNote(e.target.value)}
              value={note}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {!isWorkingFile && (
              <Box alignItems="center" display="flex" mt={2} ml={-1}>
                <Checkbox
                  checked={isFeatured}
                  // name="supplier"
                  onChange={handleCheckFeatured}
                />
                <Typography variant="body2" color="textSecondary">
                  Set this as the primary/featured image for this item.
                </Typography>
              </Box>
            )}
            {isWorkingFile && (
              <Box alignItems="center" display="flex" mt={2} ml={-1}>
                <Checkbox checked={isActive} onChange={handleCheckActive} />
                <Typography variant="body2" color="textSecondary">
                  Active file, currently in use or save for future use.
                </Typography>
              </Box>
            )}
            <Button
              disabled={submitting}
              onClick={() => {
                setFileError("")
                setFile("")
                setTitle("")
                setNote("")
              }}
              className={classes.cancel}
            >
              Cancel
            </Button>
            <Button
              disabled={submitting || !file}
              type="submit"
              className={classes.save}
            >
              {submitting ? "Please wait..." : "Add this File"}
            </Button>
          </FormControl>
        </form>
        {success === true ? (
          <Alert
            variant="outlined"
            severity="success"
            onClose={() => {
              setSuccess(false)
            }}
          >
            Your file has successfully uploaded.
          </Alert>
        ) : null}
      </Card>
    </>
  )
}
const ITEM_IMAGE_UPLOAD_MUTATION = gql`
  mutation (
    $image: Upload
    $itemId: Int
    $title: String
    $note: String
    $featured: Boolean
    $proof: Boolean
  ) {
    uploadImageFile(
      image: $image
      itemId: $itemId
      title: $title
      note: $note
      featured: $featured
      proof: $proof
    ) {
      itemImage {
        id
        image
        imageUrl
        title
        note
        featured
        proof
        item {
          id
        }
      }
    }
  }
`

const ITEM_IMAGES_QUERY = gql`
  query ($id: Int!) {
    item(id: $id) {
      id
      name
      itemimgSet {
        id
        title
        note
        image
        imageUrl
        item {
          id
        }
      }
    }
  }
`
const ITEM_WORKING_FILE_UPLOAD_MUTATION = gql`
  mutation (
    $workingFile: Upload!
    $itemId: Int!
    $comments: String
    $isActive: Boolean
  ) {
    createItemWorkingFile(
      workingFile: $workingFile
      itemId: $itemId
      comments: $comments
      isActive: $isActive
    ) {
      workingFile {
        id
        item {
          id
          name
        }
        workingFile
        workingFileUrl
        comments
        isActive
        timestamp
        version
      }
    }
  }
`
const LOGIN_QUERY = gql`
  query {
    me {
      id
      admin
    }
  }
`
export default UploadButton
