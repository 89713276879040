import React, { useContext, useReducer } from "react"
import OrgCreateReducer from "./OrgCreateReducer"
import OrgCreateContext from "./OrgCreateContext"

const OrgCreateProvider = props => {
  const initialState = useContext(OrgCreateContext)
  const [state, dispatch] = useReducer(OrgCreateReducer, initialState)

  return (
    <OrgCreateContext.Provider value={{ state, dispatch }}>
      {props.children}
    </OrgCreateContext.Provider>
  )
}
export default OrgCreateProvider
