import React, { useState, useContext, useEffect } from "react"

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import SearchPersonAdd from "../../../OrgCreateView/OrgCreateForms/SearchPerson/SearchPersonAdd"
import SearchPersonConfirm from "../../../OrgCreateView/OrgCreateForms/SearchPerson/SearchPersonConfirm"
import OrgCreateContext from "../../../OrgCreateView/context/OrgCreateContext"

const SearchOrgPeople = ({ classes, org }) => {
  const { state, dispatch } = useContext(OrgCreateContext)
  const confirmPerson = state.confirmPerson
  useEffect(() => {
    dispatch({ type: "SET_ORG_OUID", payload: org.ouid })
  }, [])
  return <>{confirmPerson ? <SearchPersonConfirm /> : <SearchPersonAdd />}</>
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(SearchOrgPeople)
