import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Chip,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {},
  roundedCard: {
    borderRadius: "15px",
  },
  markdown: {
    fontFamily: theme.typography.fontFamily,
    "& p": {
      marginBottom: theme.spacing(2),
    },
  },
  title: {},
  tagChip: {
    marginRight: theme.spacing(1),
  },
  indent: {
    paddingLeft: theme.spacing(2),
  },
}))

function Brief({ project, className, ...rest }) {
  const classes = useStyles()

  return (
    <Card
      className={clsx(classes.root, classes.roundedCard, className)}
      {...rest}
    >
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              color="textPrimary"
              className={classes.title}
            >
              {project.title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {project.planProject && project.planProject.plan && (
              <Box mt={3}>
                <Typography
                  display="block"
                  variant="overline"
                  color="textSecondary"
                >
                  Part of a Plan
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  className={classes.indent}
                >
                  {project.planProject &&
                  project.planProject.plan &&
                  project.planProject.plan.title
                    ? project.planProject.plan.title
                    : "Future Plan"}
                </Typography>
              </Box>
            )}
            <Box mt={3}>
              <Typography
                display="block"
                variant="overline"
                color="textSecondary"
              >
                Category
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className={classes.indent}
              >
                {project.category && project.category.name
                  ? project.category.name
                  : ""}
              </Typography>
            </Box>
            <Box mt={3}>
              <Typography
                display="block"
                variant="overline"
                color="textSecondary"
              >
                Tags
              </Typography>

              <Box mt={1} className={classes.indent}>
                {project.tags && (
                  <Box mt={1}>
                    {project.tags &&
                      project.tags.map(tag => (
                        <Chip
                          key={tag.id}
                          variant="outlined"
                          color="primary"
                          label={tag.name}
                          className={classes.tagChip}
                        />
                      ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mt={3}>
              <Typography
                display="block"
                variant="overline"
                color="textSecondary"
              >
                Objective
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className={classes.indent}
              >
                {project.objective ? project.objective : ""}
              </Typography>
              {/* <Markdown
            source={project.brief}
            className={classes.markdown}
          /> */}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

Brief.propTypes = {
  project: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default Brief
