import React, { useState, useContext } from "react"

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import SearchPersonAdd from "./SearchPersonAdd"
import SearchPersonConfirm from "./SearchPersonConfirm"
import OrgCreateContext from "../../context/OrgCreateContext"

const SearchPerson = ({ classes }) => {
  const { state, dispatch } = useContext(OrgCreateContext)
  const confirmPerson = state.confirmPerson
  return <>{confirmPerson ? <SearchPersonConfirm /> : <SearchPersonAdd />}</>
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(SearchPerson)
