import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import {
  Box,
  Button,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles"
import { red, green } from "@material-ui/core/colors"
import Alert from "@material-ui/lab/Alert"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import UploadSampleProof from "./UploadSampleProof"
import useDidMountEffect from "../../../../Global/hooks/useDidMountEffect"
import gql from "graphql-tag"
import { useMutation, useLazyQuery } from "@apollo/client"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import SampleProofCommentCard from "./SampleProofCommentCard"
import SamplesProofsListCard from "./SamplesProofsListCard"
import SampleProofComments from "./SampleProofComments"
import NotifyCustomer from "./NotifyCustomer"
import { image } from "@uiw/react-md-editor"
const useStyles = makeStyles(theme => ({
  root: {},
  media: {
    width: "100%",
  },
  img: {
    // height: "100%",
    maxheight: "800px",
    width: "100%",
  },
  imageCard: {
    maxHeight: "800px",
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  commentCard: {
    marginTop: theme.spacing(2),
  },
  feedbackButtons: {
    margin: theme.spacing(1),
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))
const greenTheme = createMuiTheme({
  palette: {
    primary: green,
  },
})
const redTheme = createMuiTheme({
  palette: {
    primary: red,
  },
})
const statusOptions = [
  { id: 1, name: "Art/Proof in progress", value: 1 },
  { id: 2, name: "Art/Proof awaiting customer approval", value: 2 },
  { id: 3, name: "Sample awaiting customer approval", value: 3 },
  { id: 4, name: "Art/Proof approved by customer", value: 4 },
  { id: 5, name: "Revision in progress", value: 5 },
  { id: 6, name: "Sample creation in progress", value: 6 },
  { id: 7, name: "Sample approved by customer", value: 7 },
  { id: 8, name: "Discrepancy", value: 8 },
  { id: 9, name: "Rejected and abandoned", value: 9 },
]
const SampleProofDetails = ({
  item,
  className,
  fileSet,
  samplesProofs,
  setSamplesProofs,
  setActiveView,
  ...rest
}) => {
  const classes = useStyles()
  const [files, setFiles] = useState([])
  const [statusId, setStatusId] = useState(fileSet.status.id)
  const [status, setStatus] = useState(fileSet.status)

  const [updateStatus, setUpdateStatus] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  // const [updateClosed, setUpdateClosed] = useState(false)
  const [isClosed, setIsClosed] = useState(fileSet.closed)
  const [removeCheck, setRemoveCheck] = useState(false)
  const [notificationDialog, setNotificationDialog] = useState(false)

  const [deleteImage, { data, loading, error }] = useMutation(
    DELETE_PROOF_MUTATION,
    {
      variables: {
        sampleProofId: fileSet.id,
      },
      onCompleted: data => {
        let updatedList = samplesProofs.filter(i => i.id !== fileSet.id)
        setSamplesProofs(updatedList)
        setRemoveCheck(false)
        setSubmitting(false)
        setActiveView("create")
      },
    }
  )

  // Toggle "are you sure" button first. Click again and delete image.
  const handleRemoveImage = () => {
    if (!removeCheck) {
      setRemoveCheck(true)
    } else {
      setSubmitting(true)
      deleteImage()
    }
  }

  const handleUpdateStatus = event => {
    event.persist()

    let value = 0

    if (event.target.value !== 0) {
      value = event.target.value
    }
    setStatusId(value)
    const updatedStatus = statusOptions.find(opt => opt.id == value)
    setStatus(updatedStatus)
    setUpdateStatus(updateStatus => !updateStatus)
  }
  const handleUpdateClosed = () => {
    setIsClosed(isClosed => !isClosed)
    // setUpdateClosed(updateClosed => !updateClosed)
  }
  useEffect(() => {
    if (statusId != fileSet.status.id) {
      setSubmitting(true)
      updateFileStatus()
    }
  }, [statusId])

  useEffect(() => {
    if (isClosed != fileSet.closed) {
      setSubmitting(true)
      updateFileStatus()
    }
  }, [isClosed])

  const [updateFileStatus] = useMutation(UPDATE_FILE_STATUS, {
    variables: {
      sampleProofId: fileSet.id,
      statusId: statusId,
      closed: isClosed,
    },
    onCompleted: data => {
      setSubmitting(false)
      let updatedProof = data.updateSampleProofStatus.sampleProof
      let unchangedProofs = samplesProofs.filter(
        sp => sp.id !== updatedProof.id
      )
      setSamplesProofs([...unchangedProofs, updatedProof])
      console.log("updated status")
      console.log(data)
    },
  })

  const handleNotification = () => {
    setNotificationDialog(true)
  }
  return (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() => setActiveView("create")}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Card className={classes.imageCard}>
              <img
                src={fileSet.sampleProofUrl}
                alt="file"
                className={classes.img}
              />
            </Card>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Card className={classes.roundedCard}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {isClosed ? (
                        "Status"
                      ) : (
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          disabled={submitting}
                          onClick={() => setUpdateStatus(!updateStatus)}
                        >
                          {updateStatus ? "Cancel" : "Status"}
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      {updateStatus ? (
                        <TextField
                          id="status"
                          label="Status"
                          className={classes.dropdown}
                          variant="outlined"
                          size="small"
                          select
                          margin="normal"
                          value={statusId}
                          onChange={handleUpdateStatus}
                          SelectProps={{
                            native: true,
                          }}
                        >
                          {statusOptions.map(option => (
                            <option key={option.id} value={option.value}>
                              {option.name}
                            </option>
                          ))}
                        </TextField>
                      ) : (
                        status.name
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Version</TableCell>
                    <TableCell>
                      {fileSet.version} -{" "}
                      {fileSet.isPrototypePhoto
                        ? "Prototype / Sample Photo"
                        : "Proof / Art / Design"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell>{fileSet.image}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <ThemeProvider theme={isClosed ? redTheme : greenTheme}>
                        <Button
                          color="primary"
                          size="small"
                          variant={isClosed ? "outlined" : "contained"}
                          disabled={submitting}
                          onClick={handleUpdateClosed}
                        >
                          {isClosed ? "Closed" : "Open"}
                        </Button>
                      </ThemeProvider>
                    </TableCell>
                    <TableCell>
                      <>
                        {isClosed
                          ? "Closed to edits/comments/changes."
                          : "Open. Please update soon."}
                      </>
                    </TableCell>
                    {/* {updateClosed ? (
                        <TextField
                          id="status"
                          label="Status"
                          className={classes.dropdown}
                          variant="outlined"
                          size="small"
                          select
                          margin="normal"
                          value={isClosed}
                          onChange={handleUpdateClosed}
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option value={true}>Closed</option>
                          <option value={false}>Open</option>
                        </TextField>
                      ) : (
                        <>
                          {isClosed
                            ? "Closed to edits/comments/changes."
                            : "Open"}
                        </>
                      )} */}
                  </TableRow>
                  <TableRow>
                    <TableCell>Remove File</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleRemoveImage}
                        disabled={submitting}
                      >
                        {submitting
                          ? "Deleting..."
                          : removeCheck
                          ? "Are you sure?"
                          : "Remove File"}
                      </Button>
                    </TableCell>
                    {/* {updateClosed ? (
                        <TextField
                          id="status"
                          label="Status"
                          className={classes.dropdown}
                          variant="outlined"
                          size="small"
                          select
                          margin="normal"
                          value={isClosed}
                          onChange={handleUpdateClosed}
                          SelectProps={{
                            native: true,
                          }}
                        >
                          <option value={true}>Closed</option>
                          <option value={false}>Open</option>
                        </TextField>
                      ) : (
                        <>
                          {isClosed
                            ? "Closed to edits/comments/changes."
                            : "Open"}
                        </>
                      )} */}
                  </TableRow>
                </TableBody>
              </Table>
              <Box className={classes.feedbackButtons}>
                <Dialog
                  open={notificationDialog}
                  onClose={() => setNotificationDialog(false)}
                >
                  <NotifyCustomer
                    item={item}
                    fileSet={fileSet}
                    setNotificationDialog={setNotificationDialog}
                  />
                </Dialog>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {statusId == 2 || statusId == 3 ? (
                      <Button
                        onClick={handleNotification}
                        fullWidth
                        color="primary"
                        variant="outlined"
                      >
                        Notify Customer
                      </Button>
                    ) : statusId == 4 ? (
                      <Button fullWidth color="primary" variant="outlined">
                        New Production Order
                      </Button>
                    ) : statusId == 7 ? (
                      <>
                        <Button
                          color="primary"
                          variant="outlined"
                          style={{ marginRight: "1rem" }}
                        >
                          Update Production Order
                        </Button>
                        <Button color="primary" variant="outlined">
                          New Production Order
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>

                  {/* <Grid item xs={12}>
                    <div>{JSON.stringify(status)}</div>
                  </Grid> */}

                  {/* <Grid item xs={6}>
                    <Button fullWidth color="primary" variant="outlined">
                      Approve
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button fullWidth color="primary" variant="outlined">
                      Reject
                    </Button>
                  </Grid> */}
                </Grid>
              </Box>
            </Card>
            <SampleProofComments id={fileSet.id} />

            {/* <div>
              {fileSet.sampleProofComment.length > 0 &&
                fileSet.sampleProofComment.map(comment => (
                  <List key={comment.id}>
                    {comment.id}
                    <SampleProofCommentCard comment={comment} />
                  </List>
                ))}
            </div> */}
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const UPDATE_FILE_STATUS = gql`
  mutation ($sampleProofId: Int!, $statusId: Int!, $closed: Boolean) {
    updateSampleProofStatus(
      sampleProofId: $sampleProofId
      closed: $closed
      statusId: $statusId
    ) {
      sampleProof {
        id
        status {
          id
          name
        }
        version
        image
        isVertical
        isPrototypePhoto
        timestamp
        closed
        closedTimestamp
        sampleProofComment {
          id
          comment
          commentPerson {
            uid
            firstName
            lastName
          }
        }
        sampleProofUrl
      }
    }
  }
`
const DELETE_PROOF_MUTATION = gql`
  mutation ($sampleProofId: Int!) {
    deleteSampleProof(sampleProofId: $sampleProofId) {
      sampleProofId
    }
  }
`
export default SampleProofDetails
