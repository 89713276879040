import React, { createContext } from "react"

const OrgCreateContext = createContext({
  activeStep: 0,
  ouid: "",
  org: "",
  orgPeople: [],
  peopleView: "listView",

  page: 0,
  pageSize: 10,
  search: "",

  confirmPerson: false,
  selectedPerson: "",
  newPersonActiveStep: 0,
})
export default OrgCreateContext
