import React, { useState } from "react"
import clsx from "clsx"
import { Bar } from "react-chartjs-2"
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import PerfectScrollbar from "react-perfect-scrollbar"
import withStyles from "@material-ui/core/styles/withStyles"
const chartColors = [
  "#336699",
  "#99CCFF",
  "#999933",
  "#666699",
  "#CC9933",
  "#006666",
  "#3399FF",
  "#993300",
  "#CCCC99",
  "#666666",
  "#FFCC66",
  "#6699CC",
  "#663366",
  "#9999CC",
  "#CCCCCC",
  "#669999",
  "#CCCC66",
  "#CC6600",
  "#9999FF",
  "#0066CC",
  "#99CCCC",
  "#999999",
  "#FFCC00",
  "#009999",
  "#99CC33",
  "#FF9900",
  "#999966",
  "#66CCCC",
  "#339966",
  "#CCCC33",
  "#003f5c",
  "#665191",
  "#a05195",
  "#d45087",
  "#2f4b7c",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
  "#EF6F6C",
  "#465775",
  "#56E39F",
  "#59C9A5",
  "#5B6C5D",
  "#0A2342",
  "#2CA58D",
  "#84BC9C",
  "#CBA328",
  "#F46197",
  "#DBCFB0",
  "#545775",
]

const options = {
  responsive: true,
  indexAxis: "y",
  // legend: {
  //   display: false,
  //   position: "right",
  // },

  // tooltips: {
  //   callbacks: {
  //     label: "custom label",
  //   },
  // },
}
// elements: {
//   arc: {
//     borderWidth: 0,
//   },
// },

const doughnutData = {
  maintainAspectRatio: false,
  responsive: false,
  labels: ["a", "b", "c", "d"],
  datasets: [
    {
      data: [300, 50, 100, 50],
      backgroundColor: chartColors,
      hoverBackgroundColor: chartColors,
    },
  ],
}

const StatusOverview = ({ classes, project }) => {
  const tasks = project.projectTask
  const theme = useTheme()
  const smallAndUp = useMediaQuery(theme.breakpoints.up("sm"))
  // a = Array.from(a, item => item || 0)
  const timeEstimates = tasks.map(pt => pt.task.minutesEstimate)
  const timeReported = tasks.map(pt => pt.task.minutesReported)
  const timeEstimateValues = Array.from(timeEstimates, item => item || 0)
  const timeReportedValues = Array.from(timeReported, item => item || 0)
  const taskLabels = tasks.map(pt =>
    smallAndUp ? pt.task.title.slice(0, 25) : pt.task.title.slice(0, 10)
  )
  const tooltipLabels = tasks.map(pt => pt.task.title)
  var numbers = [15.5, 2.3, 1.1, 4.7]

  function getSum(total, num) {
    return total + num
  }
  // function myFunction(item) {
  //   numbers.reduce(getSum, 0)
  // }
  const totalEstimatedTime = timeEstimates.reduce(getSum, 0)
  const totalReportedTime = timeReported.reduce(getSum, 0)
  // const matches = useMediaQuery(theme.breakpoints.up('sm'))
  // const taskLabels = tasks.map(pt =>
  //   pt.task.title.length < 11
  //     ? pt.task.title
  //     : `${pt.task.title.slice(0, 10)}, ${pt.task.title.slice(10)}`
  // )
  const taskData = {
    labels: taskLabels,

    // labels: [
    //   "completed",
    //   "scheduled",
    //   <AddCircleIcon />,
    //   "scheduled",
    //   "scheduled",
    //   "overdue",
    //   "scheduled",
    // ],
    datasets: [
      {
        label: `Time Estimate: ${totalEstimatedTime} mins`,
        backgroundColor: "#99CCFF",
        // borderColor: "rgba(255,99,132,1)",
        // borderWidth: 1,
        // barThickness: 20,
        // maxBarThickness: 25,
        hoverBackgroundColor: "#B6D0E2",
        hoverBorderColor: "#99CCFF",
        data: timeEstimateValues,
        // data: [60, 40, 10, 60, 60, 75, 30],
      },
      {
        label: `Reported Time: ${totalReportedTime} mins`,
        backgroundColor: "#1434A4",
        // barThickness: 20,
        // maxBarThickness: 25,
        // borderColor: "rgba(255,99,132,1)",
        // borderWidth: 1,
        hoverBackgroundColor: "#6082B6",
        hoverBorderColor: "#1434A4",
        data: timeReportedValues,
        // data: [65, 59, 80, 81, 56, 55, 40],
      },
    ],
  }

  return (
    <Card className={clsx(classes.root, classes.roundedCard)}>
      <CardContent>
        {/* {JSON.stringify(timeEstimateValues)}
            {JSON.stringify(timeReportedValues)}
            <Box className={classes.barBox}> */}
        {/* {JSON.stringify(totalReportedTime)}
        {JSON.stringify(totalEstimatedTime)} */}
        <PerfectScrollbar>
          <Bar data={taskData} options={options} />
        </PerfectScrollbar>

        {/* </Box>
         */}
      </CardContent>
    </Card>
  )
}

const styles = theme => ({
  root: {},
  barBox: {
    height: "400px",
  },
  roundedCard: {
    borderRadius: "15px",
  },
})
export default withStyles(styles)(StatusOverview)
