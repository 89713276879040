import React, { useRef, useState, useContext } from "react"
import { Link, navigate } from "gatsby"
import UserContext from "../../../context/UserContext"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core"
// import { logout } from "src/actions/accountActions"
// import Gravatar from "react-gravatar"
import { useLocation } from "@reach/router"

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    // marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
    borderRadius: "15px",
  },
  name: {
    textDecoration: "none",
    marginLeft: theme.spacing(2),
    // fontVariant: "small-caps",
    color: theme.palette.text.primary,
    // fontFamily: "sans-serif",
  },
}))

const Account = ({ user, isLoggedIn, setIsLoggedIn }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(UserContext)
  const alertCount = state.alertCount
  const ref = useRef(null)
  const location = useLocation()
  const userPermission = location.pathname.split("/")[1]
  const fullName =
    user.person && user.person.firstName && user.person.lastName
      ? user.person.firstName + " " + user.person.lastName
      : ""
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // const handleSignOut = event => {
  //   dispatch({ type: "SET_ILLINOIS", payload: false })
  //   setIsLoggedIn(false)
  // }

  const [handleSignOut, { client, loading, error }] = useMutation(
    LOGOUT_MUTATION,
    {
      onCompleted() {
        dispatch({ type: "SET_ILLINOIS", payload: false })
        setIsLoggedIn(false)
        client.resetStore()
        navigate(`/${userPermission}`)
      },
    }
  )

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        {user && user.person && user.person.profileImageUrl && (
          <Badge badgeContent={alertCount} color="secondary">
            <Avatar
              alt="User"
              className={classes.avatar}
              src={
                // currentUser.person.profileImage &&
                user && user.person && user.person.profileImageUrl
                  ? user.person.profileImageUrl
                  : undefined
              }
            />
          </Badge>
        )}

        {/* <Gravatar
          email={currentUser.email}
          size={25}
          className={classes.avatar}
        /> */}
        <Hidden smDown>
          <Typography variant="h5" className={classes.name}>
            {fullName}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={Link} to={`/${userPermission}/profile`}>
          Profile
        </MenuItem>
        <MenuItem component={Link} to={`/${userPermission}`}>
          Home
        </MenuItem>
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
        {/* <GoogleLogout
          clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
          buttonText="Logout"
          onLogoutSuccess={handleSignOut}
        ></GoogleLogout> */}
        {/* <MenuItem onClick={handleLogout}>Logout</MenuItem> */}
      </Menu>
    </>
  )
}
const LOGOUT_MUTATION = gql`
  mutation {
    deleteTokenCookie {
      deleted
    }
  }
`
export default Account
