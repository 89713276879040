import React from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { Edit as EditIcon } from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}))

function Header({ className, supplier, ...rest }) {
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link variant="body1" color="inherit" to="/manager/">
            Dashboard
          </Link>
          <Link variant="body1" color="inherit" to="/manager/supply/">
            Supply
          </Link>
          <Link variant="body1" color="inherit" to="/manager/supply/suppliers">
            Suppliers
          </Link>
          <Typography variant="body1" color="textPrimary">
            {supplier.name}
          </Typography>
        </Breadcrumbs>
        <Box mt={1}>
          <Typography variant="h3" color="textPrimary">
            {supplier.name}
          </Typography>
        </Box>
        <Typography variant="h6" color="textSecondary"></Typography>
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          // href={`manager/people/edit/${person.uid}`}
          // onClick={event => {
          //   navigate(`manager/people/edit/${person.uid}`,
          //   {
          //     state:{uid:`${person.uid}`}
          //   },
          //   { replace: true }
          //   )}}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <EditIcon />
          </SvgIcon>
          Edit
        </Button>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  person: PropTypes.object.isRequired,
}

export default Header
