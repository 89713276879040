import React, { useState } from "react"
import { Grid, Typography } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import gql from "graphql-tag"
import PrivateItemsList from "./PrivateItemsList"

const noDataResponse = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <Typography variant="h3">
            This person has no private items on record.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
const PrivateItems = ({ person }) => {
  const uid = person.uid
  const page = 1
  const pageSize = 20
  const [search, setSearch] = useState("")
  const { loading, error, data } = useQuery(PRIVATE_ITEMS_QUERY, {
    variables: {
      uid: uid,
      search: search,
      page: page,
      pageSize: pageSize,
    },
    onCompleted: data => {
      console.log(data)
    },
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) {
    return { noDataResponse }
  }
  if (data == null) {
    return { noDataResponse }
  }
  const privateItems = data.personPrivateItems

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <PrivateItemsList privateItems={privateItems} />
        </Grid>
      </Grid>
    </>
  )
}

const PRIVATE_ITEMS_QUERY = gql`
  query ($uid: String, $page: Int, $pageSize: Int, $search: String) {
    personPrivateItems(
      uid: $uid
      page: $page
      pageSize: $pageSize
      search: $search
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        item {
          id
          name

          isActive
          businessUnit {
            id
            name
          }

          category {
            id
            name
          }
        }
        itemOwner {
          uid
          firstName
          lastName
        }
        isPrereg
        preregItem {
          preregOwner {
            preregId
            preregEmail
          }
        }
      }
    }
  }
`
export default PrivateItems
