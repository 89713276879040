import React, { useState, useContext } from "react"
// import ProjectProvider from "./context/ProjectContext"

import {
  Box,
  Container,
  Divider,
  Tabs,
  Tab,
  makeStyles,
} from "@material-ui/core"

import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
// import ProjectProvider from "../context/ProjectContext"
// import ProjectContext from "../context/ProjectContext"
import Page from "../../../Global/Page"
import Header from "./Header"
import Overview from "./Overview"
import Tasks from "./Tasks"
import ProjectDocs from "./ProjectDocs"
import Files from "./Files"
// import Activities from "./Activities"
// import Subscribers from "./Subscribers"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import { useParams } from "@reach/router"
// import PerfectScrollbar from "react-perfect-scrollbar"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "91vh",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

function ProjectDetailsView() {
  const params = useParams()
  const paramId = parseInt(params.id)
  const classes = useStyles()
  // const { state, dispatch } = useContext(ProjectContext)
  const [currentTab, setCurrentTab] = useState("overview")
  // const [project, setProject] = useState({})

  const tabs = [
    { value: "overview", label: "Overview" },
    { value: "tasks", label: "Tasks" },
    { value: "files", label: "Files" },
    { value: "docs", label: "Docs" },
    // { value: "subscribers", label: "Subscribers" },
  ]
  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  const { loading, error, data } = useQuery(PROJECT_QUERY, {
    variables: { id: paramId },
    fetchPolicy: "cache-and-network",
    // onCompleted: data => {
    //   setProject(data.project)
    // },
  })
  if (loading) return <Loading />
  // if (error) return <Error />
  if (error) return <>{JSON.stringify(error)}</>
  if (data == undefined) return "something is wrong"
  if (data == null) {
    return "something is wrong"
  }
  const project = data.project

  if (!project) {
    return "Could not find a project by that id."
  }

  return (
    <Page className={classes.root} title="Project Details">
      <Container maxWidth="lg">
        <Header project={project} />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="secondary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === "overview" && <Overview project={project} />}
          {currentTab === "tasks" && <Tasks project={project} />}
          {currentTab === "docs" && <ProjectDocs project={project} />}

          {currentTab === "files" && <Files project={project} />}
          {/* {currentTab === "activity" && <Activities activities={project.activities} />} */}
        </Box>
      </Container>
    </Page>
  )
}
const PROJECT_QUERY = gql`
  query ($id: Int!) {
    project(id: $id) {
      id
      title
      value
      budget
      objective
      priority
      createdDate
      dueDate
      completed
      completedDate
      clientProject
      projectOverdueStatus
      category {
        id
        name
      }
      tags {
        id
        name
      }
      createdBy {
        id
        person {
          uid
          firstName
          lastName
        }
      }
      projectManager {
        id
        email
        person {
          uid
          firstName
          lastName
        }
      }
      projectTask {
        id
        project {
          id
        }
        task {
          id
          title
          createdDate
          dueDate
          completed
          completedDate
          note
          priority
          minutesEstimate
          minutesReported
          taskOverdueStatus
          category {
            id
            name
          }
          createdBy {
            id
            person {
              uid
              firstName
              lastName
            }
          }
          assignedTo {
            id
            person {
              uid
              firstName
              lastName
            }
          }
        }
      }
      personProject {
        id
        person {
          uid
          firstName
          lastName
        }
        role {
          id
          name
          description
        }
        note
        removedFromProject
      }
      planProject {
        id
        step
        priority
        isActive
        completed
        plan {
          id
          title
          isActive
          startDate
        }
      }
      projectFile {
        id
        image
        imageUrl
        addedBy {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        timestamp
      }
    }
  }
`
export default ProjectDetailsView
