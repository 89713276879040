import React, { useState } from "react"
import { makeStyles } from "@material-ui/core"

import ItemCreateForm from "../../Items/ItemCreateView/ItemCreateForm"
const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    // minHeight: "100%",
    // paddingTop: theme.spacing(3),
    // paddingBottom: 100,
  },
}))

const QuoteItemCreate = () => {
  const [item, setItem] = useState({})
  const [activeStep, setActiveStep] = useState(0)
  const [person, setPerson] = useState(null)
  const classes = useStyles()
  return (
    <>
      <ItemCreateForm
        item={item}
        setItem={setItem}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        person={person}
        setPerson={setPerson}
      />
    </>
  )
}

export default QuoteItemCreate
