/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useContext } from "react"
import { useLocation, Match } from "@reach/router"

import PerfectScrollbar from "react-perfect-scrollbar"
import PropTypes from "prop-types"

import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core"
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined"
import {
  Bell as BellIcon,
  Grid as GridIcon,
  HelpCircle as HelpIcon,
  Home as HomeIcon,
  ShoppingCart as ShoppingCartIcon,
  Layers as LayersIcon,
  DollarSign as DollarSignIcon,
  MessageCircle as MessageCircleIcon,
  TrendingUp as ProjectsIcon,
  Users as UsersIcon,
  Truck as SupplyIcon,
  List as ListIcon,
} from "react-feather"

import NavItem from "./NavItem"
import UserContext from "../../../context/UserContext"

const staffNavConfig = [
  {
    subheader: "Staff",
    items: [
      {
        title: "Home",
        icon: HomeIcon,
        href: "/staff",
      },
      {
        title: "Tasks",
        href: "/staff/tasks",
        icon: ListIcon,
      },
      {
        title: "Docs",
        href: "/staff/docs",
        icon: HelpIcon,
      },
    ],
  },
]
const managerNavConfig = [
  {
    subheader: "General",
    items: [
      {
        title: "Home",
        icon: HomeIcon,
        href: "/manager",
      },
      {
        title: "Chats",
        href: "/manager/conversations",
        icon: MessageCircleIcon,
      },
      // {
      //   title: "Service",
      //   href: "/manager/service",
      //   icon: UserIcon,
      // },
      {
        title: "Docs",
        href: "/manager/docs",
        icon: HelpIcon,
      },
      {
        title: "Tasks",
        href: "/manager/tasks",
        icon: ListIcon,
        items: [
          {
            title: "Plan",
            href: "/manager/tasks",
          },
          {
            title: "Delegate",
            href: "/manager/tasks",
          },
        ],
      },
    ],
  },

  {
    subheader: "Business",
    items: [
      {
        title: "People",
        icon: UsersIcon,
        href: "/manager/people",
        items: [
          {
            title: "People List",
            href: "/manager/people",
          },
          {
            title: "Add a Person",
            href: "/manager/people/create",
          },
          {
            title: "Organizations",
            href: "/manager/orgs",
          },
        ],
      },
      {
        title: "Inquiries",
        icon: BellIcon,
        href: "/manager/inquiries",
        // items: [
        //   {
        //     title: "Inquiries List",
        //     href: "/manager/inquiries",
        //   },
        // {
        //   title: "Add an Inquiry",
        //   href: "/manager/inquiries",
        // },
        // {
        //   title: "Archived Inquiries",
        //   href: "/manager/inquiries",
        // },
        // {
        //   title: "Spam",
        //   href: "/app/management/customers/1/edit",
        // },
        // ],
      },
      {
        title: "Proposals",
        icon: DollarSignIcon,
        href: "/manager/proposals",
        items: [
          {
            title: "Quotes & Proposals",
            href: "/manager/proposals",
          },
          {
            title: "New Proposal",
            href: "/manager/proposals/new",
          },
        ],
      },
      {
        title: "Plans & Projects",
        icon: ProjectsIcon,
        href: "/manager/projects",
        items: [
          {
            title: "Plans",
            href: "/manager/plans",
          },
          {
            title: "Projects",
            href: "/manager/projects",
          },
        ],
      },
      {
        title: "Items",
        icon: LayersIcon,
        href: "/manager/items",
        items: [
          {
            title: "List Items",
            href: "/manager/items",
          },
          {
            title: "Create New Item",
            href: "/manager/items/create",
          },
          {
            title: "Item Production",
            href: "/manager/items/production",
          },
        ],
      },
      {
        title: "Orders",
        icon: ShoppingCartIcon,
        href: "/manager/orders",
        items: [
          {
            title: "List Orders",
            href: "/manager/orders",
          },
          {
            title: "New Order",
            href: "/manager/orders/new",
          },
          {
            title: "List Carts",
            href: "/manager/carts",
          },
        ],
      },
    ],
  },
  {
    subheader: "Management",
    items: [
      {
        title: "Business Units",
        icon: GridIcon,
        href: "/manager/sbu",
      },
      {
        title: "Staff",
        icon: UsersIcon,
        href: "/manager/managestaff",
      },
      {
        title: "Accounting",
        icon: ReceiptIcon,
        href: "/manager/accounting",
        // items: [
        //   {
        //     title: "List Bills",
        //     href: "/manager/accounting/bills",
        //   },
        //   {
        //     title: "New Bill",
        //     href: "/manager/accounting/bills/new",
        //   },
        //   {
        //     title: "Payments In",
        //     href: "/manager/accounting/payments/in",
        //   },
        // ],
      },
      {
        title: "Supply",
        icon: SupplyIcon,
        href: "/manager/supply",
        items: [
          {
            title: "Suppliers",
            href: "/manager/supply/suppliers",
          },
          {
            title: "Supplier Orders",
            href: "/manager/supply/orders",
          },
          {
            title: "Supplier Batches",
            href: "/manager/supply/batches",
          },
          {
            title: "Bills and Payments",
            href: "/manager/supply/bills",
          },
          {
            title: "Supplier Categories",
            href: "/manager/supply/suppliers/categories",
          },
        ],
      },
    ],
  },
]

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  )
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const key = item.title + depth

  if (item.items) {
    const open = Match(pathname, {
      path: item.href,
      exact: false,
    })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    )
  }

  return acc
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
    backgroundColor: "#DEDEDE",
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
    backgroundColor: "#DEDEDE",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  name: {
    textDecoration: "none",
    fontWeight: "bold",
    fontVariant: "small-caps",
    color: "#000000",
    fontFamily: "sans-serif",
  },
  subheader: {
    textTransform: "uppercase",
    // fontVariant: "small-caps",
  },
}))

function getNavBar(cred) {
  switch (cred) {
    case "manager":
      return "Select campaign settings..."
    case "staff":
      return "What is an ad group anyways?"

    default:
      return "staff"
  }
}
function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles()
  const location = useLocation()
  const { state } = useContext(UserContext)
  const wisconsin = state.wisconsin
  const missouri = state.missouri
  const navConfig = state.missouri
    ? managerNavConfig
    : state.wisconsin && !state.missouri
    ? staffNavConfig
    : null

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {navConfig.map(config => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader
                  disableGutters
                  disableSticky
                  className={classes.subheader}
                >
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default NavBar
