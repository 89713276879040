import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Box, Card, makeStyles } from "@material-ui/core"
import AddPersonForm from "./PersonCreateForms/AddPersonForm"
import AddEmailForm from "./PersonCreateForms/AddEmailForm"
import AddPhoneForm from "./PersonCreateForms/AddPhoneForm"
import AddAddressForm from "./PersonCreateForms/AddAddressForm"
import AddSocialMediaForm from "./PersonCreateForms/AddSocialMediaForm"
import ConfirmPerson from "./PersonCreateForms/ConfirmPerson"
import { navigate } from "gatsby"
import PrivateItemOwnerSearch from "../../Items/ItemCreateView/ItemCreateForms/PrivateItemOwnerSearch"
// import AddToOrgForm from "./PersonCreateForms/AddToOrgForm"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    backgroundColor: theme.palette.background.light,
    minHeight: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  instructions: {
    marginTop: 10,
    marginBottom: 10,
  },
}))

function PersonCreateForm({
  className,
  newPiOwner,
  fromNewPerson,
  setFromNewPerson,
  match,
  setMatch,
  ...rest
}) {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [person, setPerson] = useState({})

  const addPerson = activeStep => {
    switch (activeStep) {
      case 0:
        return (
          <AddPersonForm setActiveStep={setActiveStep} setPerson={setPerson} />
        )

      case 1:
        return <AddEmailForm setActiveStep={setActiveStep} person={person} />
      case 2:
        return <AddPhoneForm setActiveStep={setActiveStep} person={person} />
      case 3:
        return <AddAddressForm setActiveStep={setActiveStep} person={person} />
      case 4:
        return (
          <AddSocialMediaForm setActiveStep={setActiveStep} person={person} />
        )
      case 5: {
        newPiOwner ? setActiveStep(7) : setActiveStep(7)
      }
      case 6: {
        navigate(`/manager/people/${person.uid}`)
      }
      case 7: {
        setFromNewPerson && setFromNewPerson(true)
        return (
          <PrivateItemOwnerSearch
            person={person}
            fromNewPerson={fromNewPerson}
          />
        )
      }
      default:
        return "error, try again"
    }
  }
  return (
    <Card className={clsx(classes.card, className)} {...rest} square>
      <Box className={classes.instructions}>{addPerson(activeStep)}</Box>
    </Card>
  )
}

PersonCreateForm.propTypes = {
  className: PropTypes.string,
}

export default PersonCreateForm
