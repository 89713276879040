import React, { useState } from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Bar } from "react-chartjs-2"

const options = {
  responsive: true,
  indexAxis: "y",
  hover: {
    animationDuration: 10,
  },
  scales: {
    xAxes: [
      {
        label: "Duration",
        ticks: {
          beginAtZero: true,
          fontFamily: "'Open Sans Bold', sans-serif",
          fontSize: 11,
        },
        scaleLabel: {
          display: false,
        },
        gridLines: {},
        stacked: true,
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
          color: "#fff",
          zeroLineColor: "#fff",
          zeroLineWidth: 0,
        },
        ticks: {
          fontFamily: "'Open Sans Bold', sans-serif",
          fontSize: 11,
        },
        stacked: true,
      },
    ],
  },
  legend: {
    display: false,
  },
}

const data = {
  data: {
    labels: ["1", "2", "3", "4"],

    datasets: [
      {
        data: [50, 150, 300, 400, 500],
        backgroundColor: "rgba(63,103,126,0)",
        hoverBackgroundColor: "rgba(50,90,100,0)",
      },
      {
        data: [100, 100, 200, 200, 100],
        backgroundColor: ["red", "green", "blue", "yellow"],
      },
    ],
  },
}
const TimelineChart = ({ classes }) => {
  return (
    <PerfectScrollbar>
      <Bar data={data} options={options} />
    </PerfectScrollbar>
  )
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(TimelineChart)
