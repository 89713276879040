import React from "react"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import { makeStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search"
import PersonAddIcon from "@material-ui/icons/PersonAdd"
const useStyles = makeStyles(theme => ({
  root: {
    display: "inline",
    // flexDirection: "column",
    // alignItems: "center",
    // "& > *": {
    //   margin: theme.spacing(1),
    // },
    padding: 2,
  },
  button: {
    margin: theme.spacing(1),
  },
}))

const SelectButton = ({ setStatus, className, ...rest }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Button
        onClick={() => {
          setStatus("searchPerson")
        }}
        startIcon={<SearchIcon />}
        color="secondary"
        variant="outlined"
        className={classes.button}
      >
        Search
      </Button>
      <Button
        onClick={() => {
          setStatus("createPerson")
        }}
        startIcon={<PersonAddIcon />}
        color="secondary"
        variant="outlined"
        className={classes.button}
      >
        New Contact
      </Button>
    </div>
  )
}
export default SelectButton
