import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Paper,
  Radio,
  Typography,
  makeStyles,
  TextField,
} from "@material-ui/core"
import ProjectContext from "../context/ProjectContext"
const typeOptions = [
  {
    id: 1,
    value: "development",
    title: "App development",
    description: "In-house development project",
  },
  {
    id: 2,
    value: "business",
    title: "Business",
    description: "In-house business project",
  },
  {
    id: 3,
    value: "contract",
    title: "Contract",
    description: "Contract project for a client",
  },
  {
    id: 4,
    value: "other",
    title: "Other",
    description: "Unspecified category",
  },
]

const useStyles = makeStyles(theme => ({
  root: {},
  stepButton: {
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
}))

function SelectPanel({ className, onBack, onNext, ...rest }) {
  const classes = useStyles()
  const [type, setType] = useState(typeOptions[1].value)
  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const { state, dispatch } = useContext(ProjectContext)
  const handleChange = newType => {
    setType(newType.value)
    dispatch({ type: "SET_PROJECT_CATEGORY_ID", payload: newType.id })
    if (newType.id == 3) {
      dispatch({ type: "SET_CLIENT_PROJECT", payload: true })
    }
    if (state.clientProject && newType.id != 3) {
      dispatch({ type: "SET_CLIENT_PROJECT", payload: false })
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()

    try {
      // Do api call
      setSubmitting(false)

      if (onNext) {
        onNext()
      }
    } catch (err) {
      setError(err.message)
      setSubmitting(false)
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Typography variant="h3" color="textPrimary">
        Select a Category
      </Typography>

      <Box mt={2}>
        {typeOptions.map(typeOption => (
          <Paper
            display="flex"
            alignItems="flex-start"
            p={2}
            mb={2}
            component={Box}
            elevation={type === typeOption.value ? 10 : 1}
            key={typeOption.id}
          >
            <Radio
              checked={type === typeOption.value}
              onClick={() => handleChange(typeOption)}
            />
            <Box ml={2}>
              <Typography gutterBottom variant="h5" color="textPrimary">
                {typeOption.title}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {typeOption.description}
              </Typography>
            </Box>
          </Paper>
        ))}
      </Box>

      {error && (
        <Box mt={2}>
          <FormHelperText error>{error}</FormHelperText>
        </Box>
      )}
      <Box mt={6} display="flex">
        {onBack && (
          <Button onClick={onBack} size="large">
            Previous
          </Button>
        )}
        <Box flexGrow={1} />
        <Button
          color="secondary"
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          size="large"
        >
          Next
        </Button>
      </Box>
    </form>
  )
}

SelectPanel.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
}

export default SelectPanel
