import React, { useState } from "react"

import {
  Box,
  Card,
  CardContent,
  Container,
  makeStyles,
} from "@material-ui/core"

import AddPersonForm from "./AddPersonForm"
import AddEmailForm from "./AddEmailForm"
import AddPhoneForm from "./AddPhoneForm"
import AddAddressForm from "./AddAddressForm"

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    paddingBottom: 40,
    paddingTop: 80,
  },
  button: {
    marginRight: 10,
  },
  card: {
    marginBottom: 15,
  },
  instructions: {
    marginTop: 10,
    marginBottom: 10,
  },
  stepper: {
    maxWidth: 400,
    flexGrow: 1,
  },
}))

function AddPersonView({
  inquiry,
  setOpenAddPerson,
  setIsPerson,
  person,
  setPerson,
  setPersonUid,
}) {
  const classes = useStyles()
  const steps = ["name", "email", "phone", "address"]

  const [activeStep, setActiveStep] = useState(0)

  const [uid, setUid] = useState("")

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  const handleComplete = () => {
    setActiveStep(0)
  }
  const getStepper = activeStep => {
    switch (activeStep) {
      case 0:
        return (
          <AddPersonForm
            uid={uid}
            setUid={setUid}
            inquiry={inquiry}
            person={person}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setIsPerson={setIsPerson}
            setPerson={setPerson}
            setPersonUid={setPersonUid}
          />
        )
      case 1:
        return (
          <AddEmailForm
            uid={uid}
            person={person}
            inquiry={inquiry}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        )
      case 2:
        return (
          <AddPhoneForm
            uid={uid}
            person={person}
            inquiry={inquiry}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        )
      case 3:
        return (
          <>
            {inquiry.samplesRequested ||
            (inquiry.inquiryAddress && inquiry.inquiryAddress.uid) ? (
              <AddAddressForm
                uid={uid}
                person={person}
                inquiry={inquiry}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                setOpenAddPerson={setOpenAddPerson}
              />
            ) : (
              setOpenAddPerson(false)
            )}
          </>
        )

      default:
        return "unknown step"
    }
  }

  return (
    <Container maxWidth="sm">
      <Card className={classes.card} elevation={0}>
        <CardContent>
          <Box className={classes.instructions}>{getStepper(activeStep)}</Box>
        </CardContent>
      </Card>
    </Container>
  )
}

export default AddPersonView
