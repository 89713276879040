import React, { useState, useEffect } from "react"
import moment from "moment"
import base64 from "base-64"

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Link,
  Tooltip,
  Typography,
  Hidden,
} from "@material-ui/core"
import Markdown from "react-markdown"

import MoreIcon from "@material-ui/icons/MoreVert"
import ReplyIcon from "@material-ui/icons/ReplyOutlined"
import ReplyAllIcon from "@material-ui/icons/ReplyAllOutlined"
import DeleteIcon from "@material-ui/icons/DeleteOutlined"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import Toolbar from "./Toolbar"

const EmailDetails = ({ classes, selectedEmail, setEmailSelected }) => {
  const mail = selectedEmail
  const body = base64.decode(selectedEmail.body)
  var plainTextPos = body.indexOf("text/plain")
    ? body.indexOf("text/plain") + 27
    : null

  var htmTextPos = body.indexOf("text/html")
    ? body.indexOf("text/html") - 14
    : null
  const bodyText = plainTextPos ? body.slice(plainTextPos, htmTextPos) : ""
  if (!mail) {
    return null
  }

  return (
    <div className={classes.root}>
      <Toolbar setEmailSelected={setEmailSelected} />
      <Divider />
      <Box p={3} display="flex" justifyContent="space-between" flexShrink={0}>
        <Box display="flex" alignItems="center">
          <Avatar
            className={classes.avatar}
            // src={mail.from.avatar}
          >
            NM {/* {getInitials(mail.from.name)} */}
          </Avatar>
          <Box ml={2}>
            <Typography display="inline" variant="h5" color="textPrimary">
              {mail.fromHeader}
            </Typography>{" "}
            <Link color="textSecondary" display="inline" variant="body2">
              from email {/* {mail.from.email} */}
            </Link>
            <Typography variant="subtitle2" color="textSecondary">
              To: to emails
              {/* {mail.to.map((person) => (
                <Link
                  color="inherit"
                  key={person.email}
                >
                  {person.email}
                </Link>
              ))} */}
            </Typography>
            <Typography
              className={classes.date}
              color="textSecondary"
              variant="caption"
            >
              {moment(mail.processed).format("MMMM Do YYYY, h:mm:ss a")}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Hidden smDown>
            <Tooltip title="Reply">
              <IconButton>
                <ReplyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reply all">
              <IconButton>
                <ReplyAllIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Hidden>
          <Tooltip title="More options">
            <IconButton>
              <MoreIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Divider />
      <Box flexGrow={1} py={6} px={3} bgcolor="background.dark">
        <Typography variant="h1" color="textPrimary">
          {mail.subject}
        </Typography>
        <Box mt={2}>
          <Markdown source={bodyText} className={classes.message} />
        </Box>
      </Box>
      <Divider />
      {/* <MailReply /> */}
    </div>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "auto",
  },
  avatar: {
    height: 56,
    width: 56,
  },
  date: {
    whiteSpace: "nowrap",
  },
  message: {
    color: theme.palette.text.secondary,
    "& > p": {
      ...theme.typography.body1,
      marginBottom: theme.spacing(2),
    },
  },
})
export default withStyles(styles)(EmailDetails)
