import React, { useState, useContext } from "react"
import { Box, Container, makeStyles } from "@material-ui/core"

import Page from "../../../Global/Page"
// import useIsMountedRef from "../../../../hooks/useIsMountedRef"
import Header from "./Header"
// import Results from "./Results"
import SortContext from "../../../../context/SortContext"

import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

import ItemProductionDashboard from "./ItemProductionDashboard"
import ItemProductionSupplierView from "./ItemProductionSupplierView"
import PreProductionView from "./PreProductionView"
// import ProductionNotifications from "./ProductionNotifications"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: 100,
  },
}))

const ItemProductionView = ({ className, ...rest }) => {
  const { state, dispatch } = useContext(SortContext)
  const classes = useStyles()
  const [products, setProducts] = useState(null)
  const [view, setView] = useState("dashboard")
  const search = state.search
  const page = state.page + 1
  const pageSize = state.pageSize

  const productionView = view => {
    switch (view) {
      case "dashboard":
        return <ItemProductionDashboard />
      case "supplier":
        return <ItemProductionSupplierView />
      case "preproduction":
        return <PreProductionView />
      default:
        return null
    }
  }

  // const { loading, error, data } = useQuery(LIST_ALL_ITEMS_QUERY, {
  //   variables: {
  //     search: search,
  //     page: page,
  //     pageSize: pageSize,
  //   },
  //   fetchPolicy: "cache-and-network",
  // })
  // if (loading) return <Loading />
  // if (error) return <Error />
  // if (data == undefined) return ""
  // if (data == null) {
  //   return ""
  // }
  // const itemDataSet = data.items
  return (
    <Page className={classes.root} title="Item Production">
      <Container maxWidth={false}>
        <Header />
        {/* <ProductionNotifications /> */}
        <Box mt={3}>
          {productionView(view)}
          <div>
            <h2>Main Item Production Dashboard</h2>
            <p>
              Any time an item is ordered any any production or preparation has
              to be made.
            </p>
            <ul>
              <li>View current production orders</li>
              <li>Start new production orders</li>
              <li>Add new items to current orders</li>
              <li>Tie this in with projects?</li>
            </ul>
          </div>
          {/* <Results /> */}
        </Box>
      </Container>
    </Page>
  )
}

export default ItemProductionView
