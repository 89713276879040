import React, { useState } from "react"

import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"

const FormError = ({ error }) => {
  const [open, setOpen] = useState(true)

  return (
    <Snackbar
      open={open}
      message={"Please check form entries"}
      action={
        <Button onClick={() => setOpen(false)} color="secondary" size="small">
          Close
        </Button>
      }
    />
  )
}

export default FormError
