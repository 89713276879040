import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  colors,
  makeStyles,
  ButtonBase,
} from "@material-ui/core"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFileOutlined"
import GetAppIcon from "@material-ui/icons/GetApp"
import MoreIcon from "@material-ui/icons/MoreVert"
import DeleteIcon from "@material-ui/icons/DeleteOutlined"
import ArchiveIcon from "@material-ui/icons/ArchiveOutlined"
import EditIcon from "@material-ui/icons/Edit"
import bytesToSize from "../../../../../../utils/bytesToSize"
import moment from "moment"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import useDidMountEffect from "../../../../../Global/hooks/useDidMountEffect"
import EditFile from "./EditFile"

const useStyles = makeStyles(theme => ({
  root: {},
  media: {
    height: 240,
  },
  placeholder: {
    height: 240,
    backgroundColor: colors.blueGrey[50],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  insertDriveFileIcon: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    fontSize: theme.spacing(6),
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
  getAppIcon: {
    marignRight: theme.spacing(1),
  },
  menu: {
    width: 250,
    maxWidth: "100%",
  },
  confirm: {
    color: "#ff0000",
  },
}))

function FileCard({ file, myFiles, setMyFiles, className, ...rest }) {
  const classes = useStyles()
  const moreRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)
  const [editing, setEditing] = useState(false)
  const imageUrl = file.imageUrl
  const image = file.image
  const [imageId, setImageId] = useState(null)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [newArray, setNewArray] = useState([])

  const [deleteImage] = useMutation(DELETE_IMAGE_MUTATION, {
    variables: {
      imageId: imageId,
    },
    onCompleted: (data, response, errors) => {
      // console.log(data)
      setMyFiles(newArray)
    },
  })

  const handleMenuOpen = () => {
    setOpenMenu(true)
  }

  const handleMenuClose = () => {
    setOpenMenu(false)
  }

  const handleConfirmDelete = () => {
    setConfirmDelete(true)
  }
  const handleDelete = file => {
    const filteredArray = myFiles.filter(x => x.id !== file.id)
    setNewArray(filteredArray)
    setConfirmDelete(false)
    handleMenuClose()
    setImageId(file.id)
  }
  useDidMountEffect(() => {
    deleteImage()
  }, [imageId])

  const handleEditing = event => {
    setEditing(true)
    handleMenuClose()
  }
  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        {editing ? (
          <EditFile file={file} setEditing={setEditing} />
        ) : (
          <>
            <CardMedia className={classes.media} image={file.imageUrl} />

            <CardContent className={classes.content}>
              <div>
                <Typography variant="h5" color="textPrimary">
                  {file.title}
                </Typography>
                <Typography variant="h5" color="textPrimary">
                  Item:{" "}
                  <Link
                    to={`/manager/items/${file.item.id}`}
                    state={{ id: `${file.item.id}` }}
                  >
                    {file.item.name}
                  </Link>
                </Typography>
                {file.featured ? (
                  <Chip size="small" label="Featured Image" />
                ) : (
                  ""
                )}
                {file.proof ? (
                  <Chip size="small" label="Proof or Sample" />
                ) : (
                  ""
                )}
                <Typography variant="subtitle2" color="textPrimary">
                  Uploaded {moment(file.uploadedAt).format("MMM Do YYYY")}
                </Typography>
                <Typography variant="subtitle2" color="textPrimary">
                  {file.note}
                </Typography>
              </div>
              <div>
                <Tooltip title="More options">
                  <IconButton
                    edge="end"
                    onClick={handleMenuOpen}
                    ref={moreRef}
                    size="small"
                  >
                    <MoreIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </CardContent>
            <Divider />
            <CardActions>
              <Link href={imageUrl} download={image}>
                <Button>
                  <GetAppIcon className={classes.getAppIcon} />
                  Download
                </Button>
              </Link>
            </CardActions>
            <Menu
              anchorEl={moreRef.current}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              classes={{ paper: classes.menu }}
              onClose={handleMenuClose}
              elevation={1}
              open={openMenu}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem divider onClick={handleEditing}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Edit Info" />
              </MenuItem>
              <MenuItem divider>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Duplicate File" />
              </MenuItem>
              <MenuItem divider>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="QuickEdit Image" />
              </MenuItem>
              {confirmDelete ? (
                <MenuItem
                  divider
                  onClick={e => handleDelete(file)}
                  className={classes.confirm}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Confirm Delete" />
                </MenuItem>
              ) : (
                <MenuItem divider onClick={handleConfirmDelete}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delete File" />
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </Card>
    </>
  )
}

FileCard.propTypes = {
  className: PropTypes.string,
  file: PropTypes.object.isRequired,
}

const DELETE_IMAGE_MUTATION = gql`
  mutation ($imageId: Int!) {
    deleteImageFile(imageId: $imageId) {
      imageId
    }
  }
`
export default FileCard
