import React, { useState, version } from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import ImageSearchIcon from "@material-ui/icons/ImageSearch"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import CircularProgress from "@material-ui/core/CircularProgress"
import Divider from "@material-ui/core/Divider"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import Alert from "@material-ui/lab/Alert"
import TextField from "@material-ui/core/TextField"

import { Box, Checkbox, Input } from "@material-ui/core"
import gql from "graphql-tag"
// import { useMutation } from "@apollo/client"
import { useApolloClient, useMutation, useQuery } from "@apollo/client"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(1),
  },
  save: {
    color: "green",
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
}))

const UploadSampleProof = ({ item, setFiles, croppedImage }) => {
  const [file, setFile] = useState("")
  const [title, setTitle] = useState("")
  const [version, setVersion] = useState("")
  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [fileError, setFileError] = useState("")
  const classes = useStyles()

  const apolloClient = useApolloClient()

  const id = item.id
  const customerUid = item.privateItem.itemOwner.uid
  const [handleSubmit, { data }] = useMutation(SAMPLE_PROOF_UPLOAD_MUTATION, {
    variables: {
      itemId: id,
      image: croppedImage,
      customerUid: customerUid,
      closed: false,
      statusId: 1,
      version: 1,
    },

    onCompleted: (data, response, errors) => {
      let imageData = data.createSampleProof.sampleProof
      const newImage = { ...imageData }
      setFiles(files => [...files, newImage])
      setFile("")
      setSubmitting(false)
    },
    onError: err => console.error(err),
    // update={handleUpdateCache}
  })

  // const [handleSubmit, { loading, error }] = useMutation(
  //   ITEM_IMAGE_UPLOAD_MUTATION,
  //   {
  //     variables: {
  //       itemId: id,
  //       image: file,
  //       title: title,
  //       note: note,
  //     },
  //     awaitRefetchQueries: true,
  //     refetchQueries: [
  //       { query: (ITEM_IMAGES_QUERY, { variables: { id: id } }) },
  //     ],
  //     onCompleted: (response, errors) => {
  //       setFile("")
  //       setTitle("")
  //       setNote("")
  //       setSuccess(true)
  //     },
  //     onError: err => console.error(err),
  //     // update={handleUpdateCache}
  //   }
  // )

  const handleImageChange = event => {
    const selectedFile = event.target.files[0]
    const fileSizeLimit = 10000000 // 10MB
    if (selectedFile && selectedFile.size > fileSizeLimit) {
      setFileError(`${selectedFile.name}: File size too large`)
    } else {
      setFile(selectedFile)
      setFileError("")
    }
  }

  // const handleUpdateCache = (cache, response) => {
  //   const data = cache.readQuery({ query: ITEM_IMAGES_QUERY })
  //   const images = data.itemImg.concat()
  //   cache.writeQuery({ query: ITEM_IMAGES_QUERY, data: {} })
  // }

  return (
    <>
      <Card className={classes.root}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Upload a File (sample proof)
            </Typography>
          </CardContent>
        </CardActionArea>
        <form
          onSubmit={event => {
            event.preventDefault()
            handleSubmit(event)
          }}
        >
          <FormControl error={Boolean(fileError)}>
            {/* <FormControl> */}
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              required
              onChange={handleImageChange}
            />
            <label htmlFor="contained-button-file">
              <Button
                component="span"
                variant="contained"
                color="default"
                className={classes.button}
                startIcon={<ImageSearchIcon />}
              >
                Select an Image File
              </Button>
              {file && file.name}
              <FormHelperText>{fileError}</FormHelperText>
            </label>
          </FormControl>
          <Button
            disabled={submitting}
            onClick={() => {
              setFileError("")
              setFile("")
            }}
            className={classes.cancel}
          >
            Cancel
          </Button>
          <Button
            disabled={submitting || !file}
            type="submit"
            className={classes.save}
          >
            {submitting ? "Please wait..." : "Add this File"}
          </Button>
        </form>
        {success === true ? (
          <Alert
            variant="outlined"
            severity="success"
            onClose={() => {
              setSuccess(false)
            }}
          >
            Your image has successfully uploaded.
          </Alert>
        ) : null}
      </Card>
    </>
  )
}
const SAMPLE_PROOF_UPLOAD_MUTATION = gql`
  mutation (
    $image: Upload
    $itemId: Int
    $closed: Boolean
    $statusId: Int
    $customerUid: String
    $version: Int
  ) {
    createSampleProof(
      image: $image
      itemId: $itemId
      closed: $closed
      statusId: $statusId
      customerUid: $customerUid
      version: $version
    ) {
      sampleProof {
        id
        item {
          id
          name
          businessUnit {
            id
            name
          }
        }
        timestamp
        closed
        closedTimestamp
        status {
          id
          name
        }
        customer {
          uid
        }
        version
        isVertical
        isPrototypePhoto
      }
    }
  }
`

const LOGIN_QUERY = gql`
  query {
    me {
      id
      admin
    }
  }
`
export default UploadSampleProof
