import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import gql from "graphql-tag"
import { useApolloClient, useMutation } from "@apollo/client"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  button: {
    margin: theme.spacing(1),
  },
  save: {
    color: "green",
  },
  previewImage: {
    width: "100%",
    maxWidth: 200,
    maxHeight: 200,
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
}))

const UploadProfileImage = ({
  user,
  setImageFile,
  imageFile,

  image,
  setImage,
  profileImageUrl,
  setProfileImageUrl,
  setUseGravatar,
  setUpdatePhoto,
}) => {
  const [file, setFile] = useState("")
  const [statusId, setStatusId] = useState("")
  const [isClosed, setIsClosed] = useState(false)

  const [success, setSuccess] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [fileError, setFileError] = useState("")
  const [closedTimestamp, setClosedTimestamp] = useState(null)
  let dateTimeNow = new Date().toISOString()
  const name = user.person.lastName + "_" + user.person.firstName

  useEffect(() => {
    createFile()
  }, [])

  const createFile = async () => {
    let newFile = await fetch(imageFile)
      .then(r => r.blob())
      .then(
        imageFile =>
          new File([imageFile], `${name}.jpg`, {
            type: "image/jpeg",
          })
      )
    setFile(newFile)
    console.log(newFile)
  }

  const thumbnail = (
    <img
      height="150"
      width="150"
      src={imageFile}
      alt={"uploaded file thumbnail"}
    />
  )
  const classes = useStyles()

  const apolloClient = useApolloClient()

  const [handleSubmit, { data, error }] = useMutation(
    UPDATE_PERSON_PROFILE_MUTATION,
    {
      variables: {
        uid: user.person.uid,
        image: file,
        // useGravatar: user.person.useGravatar,
        useGravatar: false,
        firstName: user.person.firstName,
        lastName: user.person.lastName,
      },
      onCompleted: data => {
        let updatedProfile = data.updatePersonProfile.person
        //   setFirstName(updatedProfile.firstName)
        //   setLastName(updatedProfile.lastName)
        // setUseGravatar(updatedProfile.useGravatar)
        setImage(updatedProfile.profileImage)
        setProfileImageUrl(updatedProfile.profileImageUrl)
        console.log(data)
        setSubmitting(false)
        setSuccess(true)
      },
      onError: err => console.error(err),
    }
  )

  const handleClosed = event => {
    let now = new Date().toISOString()
    setIsClosed(!isClosed)
    if (isClosed) {
      setClosedTimestamp(now)
    } else {
      setClosedTimestamp(null)
    }
  }

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener("load", () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  return (
    <>
      <Card className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            {thumbnail}
            {file && file.name}
          </Grid>
          <Grid item xs={12} sm={8}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Update Your Profile Image
              </Typography>
            </CardContent>
            <form
              onSubmit={event => {
                event.preventDefault()

                handleSubmit(event)
              }}
            >
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={submitting || !imageFile}
                type="submit"
                className={classes.save}
              >
                {submitting ? "Please wait..." : "Add this File"}
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                disabled={submitting}
                onClick={() => {
                  setFileError("")
                  setImageFile(null)
                }}
              >
                Cancel
              </Button>
            </form>
          </Grid>
          {success === true ? (
            <Alert
              variant="outlined"
              severity="success"
              onClose={() => {
                setSuccess(false)
                setUpdatePhoto(false)
              }}
            >
              Your image has successfully uploaded.
            </Alert>
          ) : null}
        </Grid>
      </Card>
    </>
  )
}
const UPDATE_PERSON_PROFILE_MUTATION = gql`
  mutation (
    $uid: String!
    $image: Upload
    $useGravatar: Boolean
    $firstName: String
    $lastName: String
  ) {
    updatePersonProfile(
      uid: $uid
      image: $image
      useGravatar: $useGravatar
      firstName: $firstName
      lastName: $lastName
    ) {
      person {
        uid
        firstName
        lastName
        useGravatar
        profileImage
        profileImageUrl
      }
    }
  }
`

const LOGIN_QUERY = gql`
  query {
    me {
      id
      admin
    }
  }
`
export default UploadProfileImage
