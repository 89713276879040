import React, { useContext, useEffect } from "react"
import SignIn from "./SignIn"
import Verify from "./Verify"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import UserContext from "../../context/UserContext"

import { graphql, useStaticQuery, Link } from "gatsby"

import BackgroundImage from "gatsby-background-image"

const useStyles = makeStyles(theme => ({
  background: {
    width: "100%",
    height: "100vh",
    backgroundPosition: "bottom",

    backgroundSize: "cover",
    overflowY: "hidden",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const Cred = ({ isLoggedIn, setIsLoggedIn, user, setUser }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(UserContext)

  const data = useStaticQuery(
    graphql`
      query {
        hex: file(relativePath: { eq: "hex-background.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  // const data = useStaticQuery(
  //   graphql`
  //     query {
  //       bridge: file(relativePath: { eq: "rope-bridge.jpg" }) {
  //         childImageSharp {
  //           fluid(quality: 90, maxWidth: 1920) {
  //             ...GatsbyImageSharpFluid_withWebp
  //           }
  //         }
  //       }
  //     }
  //   `
  // )

  const imageData = data.hex.childImageSharp.fluid
  useEffect(() => {
    if (state.illinois && state.virginia) setIsLoggedIn(true)
  }, [])

  if (isLoggedIn === false)
    return (
      <BackgroundImage className={classes.background} fluid={imageData}>
        <SignIn isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      </BackgroundImage>
    )
  else
    return (
      <Verify
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
        user={user}
        setUser={setUser}
      />
    )
}

export default Cred
