import React, { useContext } from "react"

import OrgCreateContext from "../context/OrgCreateContext"
import { Box, Card, CardContent, Container, Grid } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import Page from "../../../../Global/Page"
import Header from "../Header"
import AddOrgForm from "./AddOrgForm"
import AddOrgPeople from "./AddOrgPeople"
import AddOrgPhone from "./AddOrgPhone"
import AddOrgEmail from "./AddOrgEmail"
import AddOrgAddress from "./AddOrgAddress"
import AddOrgSocialMedia from "./AddOrgSocialMedia"

const OrgCreateForm = ({ classes }) => {
  const { state, dispatch } = useContext(OrgCreateContext)
  const activeStep = state.activeStep
  const addOrg = activeStep => {
    switch (activeStep) {
      case 0:
        return <AddOrgForm />
      case 1:
        return <AddOrgPeople />
      case 2:
        return <AddOrgEmail />
      case 3:
        return <AddOrgPhone />
      case 4:
        return <AddOrgAddress />
      case 5:
        return <AddOrgSocialMedia />
      default:
        return "error, try again"
    }
  }
  return (
    <Page className={classes.root} title="New Organization">
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <Card className={classes.roundedCard}>
                <CardContent>{addOrg(activeStep)}</CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

const styles = theme => ({
  root: {
    minHeight: "92vh",
  },
  roundedCard: {
    borderRadius: "15px",
  },
})
export default withStyles(styles)(OrgCreateForm)
