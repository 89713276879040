import React, { useState, useEffect } from "react"

import ItemConversionStepOne from "./ItemConversionStepOne"
import PrivateItemCreateForm from "../../../Items/ItemCreateView/ItemCreateForms/PrivateItemCreateForm"
import PreregItemCreateForm from "../../../Items/ItemCreateView/ItemCreateForms/PreregItemCreateForm"
// import InquiryItemConversion from "./ItemCreateForms/InquiryItemConversion"
import ItemSpecs from "../../../Items/ItemCreateView/ItemCreateForms/ItemSpecs"
import ItemImage from "../../../Items/ItemCreateView/ItemCreateForms/ItemImage"
import ItemPricing from "../../../Items/ItemCreateView/ItemCreateForms/ItemPricing"
import InquiryItemNextStep from "./InquiryItemNextStep"
import NextStep from "../../../Items/ItemCreateView/ItemCreateForms/NextStep"
import { Box, makeStyles } from "@material-ui/core"

const InquiryItemConversion = ({
  inquiryItem,
  handleClose,
  inquiry,
  specs,
  person,
}) => {
  const [item, setItem] = useState({})
  const [activeStep, setActiveStep] = useState(0)
  //   const [person, setPerson] = useState(null)

  const getStepper = activeStep => {
    switch (activeStep) {
      case 0:
        return (
          <ItemConversionStepOne
            setActiveStep={setActiveStep}
            item={item}
            setItem={setItem}
            specs={specs}
            person={person}
            inquiry={inquiry}
            inquiryItem={inquiryItem}
          />
        )
      case 1:
        return (
          <PrivateItemCreateForm
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            item={item}
            person={person}
            // setPerson={setPerson}
          />
        )
      case 2:
        return (
          <PreregItemCreateForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            item={item}
            person={person}
            // setPerson={setPerson}
          />
        )
      case 3:
        return (
          <ItemSpecs
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            item={item}
            specs={specs}
          />
        )
      case 4:
        return (
          <ItemImage
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            item={item}
            inquiryItem={inquiryItem}
          />
        )
      case 5:
        return (
          <ItemPricing
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            item={item}
            specs={specs}
          />
        )

      case 6:
        return (
          <InquiryItemNextStep
            activeStep={activeStep}
            item={item}
            inquiry={inquiry}
            handleClose={handleClose}
          />
        )

      default:
        return "unknown step"
    }
  }

  return <Box>{getStepper(activeStep)}</Box>
}

export default InquiryItemConversion
