import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"

import {
  Box,
  Button,
  Breadcrumbs,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { Printer as PrintIcon } from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import OrderContext from "./context/OrderContext"
const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  link: {
    textDecoration: "none",
    color: "#000000",
  },
}))

function Header({ setPrintView, className, ...rest }) {
  const classes = useStyles()
  const { state, dispatch } = useContext(OrderContext)
  const orderId = state.orderId

  const handleClickOpen = () => {
    setPrintView(true)
  }

  return (
    <>
      <Grid
        className={clsx(classes.root, className)}
        container
        justify="space-between"
        spacing={3}
        {...rest}
      >
        <Grid item>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              className={classes.link}
              variant="body1"
              color="inherit"
              to="/manager"
              component={Link}
            >
              Management
            </Link>
            <Link
              className={classes.link}
              variant="body1"
              color="inherit"
              to="/manager/orders"
              component={Link}
            >
              Orders
            </Link>
            <Typography variant="body1" color="textPrimary">
              Order {orderId}
            </Typography>
          </Breadcrumbs>
          <Box mt={2}>
            <Typography variant="h3" color="textPrimary">
              Order Details
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <IconButton
            onClick={handleClickOpen}
            color="secondary"
            variant="contained"
            to={"/manager/items/create"}
          >
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <PrintIcon />
            </SvgIcon>
          </IconButton>
        </Grid>
      </Grid>
    </>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
