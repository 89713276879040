import React, { useState, useContext, useEffect } from "react"
import clsx from "clsx"
import moment from "moment"
import { Link } from "gatsby"
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core"
import Rating from "@material-ui/lab/Rating"
import Pagination from "@material-ui/lab/Pagination"
import PerfectScrollbar from "react-perfect-scrollbar"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import SortContext from "../../../../../../context/SortContext"
const useStyles = makeStyles(theme => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.link,
  },
}))

const OrderList = ({ orders, className, ...rest }) => {
  const classes = useStyles()
  const [orderObjects, setOrderObjects] = useState(orders.objects)
  const { state, dispatch } = useContext(SortContext)
  const [page, setPage] = useState(state.page)
  const handleChange = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
    setPage(event.target.value)
  }
  useEffect(() => {
    console.log("hi")
    // dispatch({ type: "SET_PAGE", payload: page })
  }, [page])

  if (orders.count === 0) {
    return (
      <div>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardHeader title="No Orders on Record" />
          <Divider />
        </Card>
      </div>
    )
  }

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          title="Orders"
          action={
            orders.pages > 1 && (
              <Pagination
                count={orders.pages}
                page={state.page}
                onChange={handleChange}
              />
            )
          }
        />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Order Date</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Business Unit</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderObjects.map(order => (
                  <TableRow key={order.id}>
                    <TableCell>
                      <IconButton
                        component={Link}
                        to={`/manager/orders/${order.id}`}
                        state={{ id: `${order.id}` }}
                      >
                        <Avatar variant="square" className={classes.itemNumber}>
                          {order.id}
                        </Avatar>
                      </IconButton>
                      {/* <Link
                        to={`/manager/orders/${order.id}`}
                        state={{ id: `${order.id}` }}
                      >
                        {order.id}
                      </Link> */}
                    </TableCell>
                    <TableCell>
                      {order.orderDate &&
                        moment(order.orderDate).format("DD MMM YYYY | HH:MM")}
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`manager/people/${order.customer.uid}`}
                        state={{ uid: `${order.customer.uid}` }}
                        className={classes.link}
                      >
                        {order.customer &&
                          order.customer.firstName +
                            " " +
                            order.customer.lastName}
                      </Link>
                    </TableCell>
                    <TableCell>{order.businessUnit.name}</TableCell>
                    <TableCell>
                      {order.status && order.status.orderStatus}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

export default OrderList
