import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import clsx from "clsx"
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {},
}))

const CreatePrereg = ({
  person,
  prereg,
  setPrereg,
  setIsPrereg,
  className,
}) => {
  const classes = useStyles()
  const emails = person.emailPerson
  const personUid = person.uid
  const [preregEmail, setPreregEmail] = useState("")

  const [createPreRegUser] = useMutation(CREATE_PREREG_USER_MUTATION, {
    variables: {
      personUid: personUid,
      preregEmail: preregEmail,
    },

    onCompleted: data => {
      console.log("SUCCESS")
      setPrereg(data.createPrereg.prereg)
      setIsPrereg(true)
    },
  })
  const handleSubmit = (event, createPreRegUser) => {
    event.preventDefault()
    createPreRegUser()
  }

  return (
    <>
      <Typography>
        This person does not have a user account or a preregistration account.
        Select an email address to associate with the new pre-registration
        account.
      </Typography>

      <Box>
        <form
          onSubmit={event => handleSubmit(event, createPreRegUser)}
          className={clsx(classes.root, className)}
        >
          {emails ? (
            <TextField
              label="Select and confirm email address"
              name="category"
              onChange={event => setPreregEmail(event.target.value)}
              select
              margin="dense"
              size="small"
              fullWidth
              variant="outlined"
              SelectProps={{ native: true }}
              value={preregEmail}
            >
              <option></option>
              {emails.map(e => (
                <option key={e.id} value={e.emailAddress}>
                  {e.emailAddress}
                </option>
              ))}
            </TextField>
          ) : (
            <TextField
              fullWidth
              label="Enter an email address (required)"
              margin="dense"
              size="small"
              name="preregEmail"
              onChange={event => setPreregEmail(event.target.value)}
              type="preregEmail"
              value={preregEmail}
              variant="outlined"
            />
          )}
          <Button
            color="secondary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            {" "}
            Create Prereg User Account
          </Button>
        </form>
      </Box>
    </>
  )
}

const CREATE_PREREG_USER_MUTATION = gql`
  mutation ($personUid: String!, $preregEmail: String!) {
    createPrereg(personUid: $personUid, preregEmail: $preregEmail) {
      prereg {
        preregId
        preregEmail
      }
    }
  }
`
export default CreatePrereg
