import React, { useState, useContext } from "react"
import clsx from "clsx"
import base64 from "base-64"
import moment from "moment"
import { Link } from "gatsby"
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardActions,
  Divider,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from "@material-ui/core"
import Rating from "@material-ui/lab/Rating"
import Pagination from "@material-ui/lab/Pagination"
import PerfectScrollbar from "react-perfect-scrollbar"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import SortContext from "../../../../../../context/SortContext"

import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import { rest } from "lodash"

const EmailList = ({
  classes,
  person,
  personEmails,
  setEmailSelected,
  setSelectedEmail,
  ...rest
}) => {
  const { state, dispatch } = useContext(SortContext)
  const personUid = person.uid
  const handleSelectEmail = email => {
    setSelectedEmail(email)
    setEmailSelected(true)
  }
  const handleChange = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
  }
  if (!personEmails || personEmails.count === 0) {
    return (
      <div>
        <Card className={classes.root} {...rest}>
          <CardHeader title={"No emails on record"} />

          <CardActions>
            <Divider />
            <Button size="small" color="secondary" variant="contained">
              New Email
            </Button>
          </CardActions>

          <Divider />
        </Card>
      </div>
    )
  }
  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          title="Emails"
          action={
            personEmails.pages > 1 && (
              <Pagination
                count={personEmails.pages}
                page={state.page}
                onChange={handleChange}
              />
            )
          }
        />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Preview</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>

                  <TableCell>Mailbox</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {personEmails.objects.map(email => {
                  const body = base64.decode(email.body)
                  var plainPos = body.indexOf("text/plain")
                    ? body.indexOf("text/plain") + 27
                    : null
                  var htmPos = body.indexOf("text/html")
                  return (
                    <TableRow key={email.id}>
                      <TableCell>
                        <Button onClick={() => handleSelectEmail(email)}>
                          {moment(email.processed).format(
                            "DD MMM YYYY | HH:MM"
                          )}
                        </Button>
                      </TableCell>
                      <TableCell>{email.subject}</TableCell>
                      <TableCell>
                        {plainPos
                          ? body.slice(plainPos, plainPos + 15) + "..."
                          : ""}
                      </TableCell>
                      <TableCell>{email.fromHeader}</TableCell>
                      <TableCell>{email.toHeader}</TableCell>
                      {/* <TableCell>{JSON.stringify(body)}</TableCell> */}

                      <TableCell>{email.mailbox.name}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(EmailList)
