import React, { useState } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  TextField,
  Paper,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import ReactMarkdown from "react-markdown"
import DocsTags from "../../../../Users/Docs/DocsPublishView/DocsTags"
import { X as CancelIcon } from "react-feather"
import MDEditor from "@uiw/react-md-editor"

const NewProjectDoc = ({ classes, project, setProjectDoc, setView }) => {
  const projectId = project.id
  const [title, setTitle] = useState("")
  const [body, setBody] = useState("")
  const [tagIds, setTagIds] = useState([1])
  const [tags, setTags] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [staffOnly, setStaffOnly] = useState(true)
  const [membersOnly, setMembersOnly] = useState(true)
  const { data, loading, error } = useQuery(PROJECT_DOCS_FORM_QUERY)

  const [createProjectDoc] = useMutation(NEW_PROJECT_DOC_MUTATION, {
    variables: {
      projectId: projectId,
      title: title,
      body: body,
      tagIds: tagIds,
      staffOnly: staffOnly,
      membersOnly: membersOnly,
    },
    onCompleted: data => {
      const newProjectDoc = data.createProjectDoc.projectDoc
      setProjectDoc(newProjectDoc)
      setSubmitting(false)
      setView("viewDoc")
    },
  })

  const handleSubmitPost = event => {
    setSubmitting(true)
    createProjectDoc()
  }

  const handleRemoveTag = (tagId, event) => {
    const filteredArray = tagIds.filter(t => t !== tagId)
    setTagIds(filteredArray)
  }

  if (loading) return <Loading />
  if (error) return <Error />
  const allTags = data.docsTags
  return (
    <>
      <Card className={classes.roundedCard}>
        <CardContent>
          <form
            onSubmit={event => {
              event.preventDefault()
              handleSubmitPost(event)
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <Typography gutterBottom variant="h5" component="h2">
                  Add or Edit Project Docs
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="body1"
                >
                  Create a new project document
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Button onClick={() => setView("viewDoc")}>Cancel</Button>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Title"
                  style={{ margin: 8 }}
                  placeholder="Title"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={title}
                  onChange={event => setTitle(event.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <div style={{ margin: 8 }}>
                  <DocsTags
                    allTags={allTags}
                    tagIds={tagIds}
                    setTagIds={setTagIds}
                    setTags={setTags}
                  />
                </div>
              </Grid>

              {/* <BodyEditor body={body} setBody={setBody} /> */}
              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  component="ul"
                  className={classes.chipList}
                >
                  {tagIds &&
                    tagIds.length >= 1 &&
                    tagIds.map(tagId => {
                      const t = allTags.find(element => element.id === tagId)
                      return (
                        <li key={tagId}>
                          <Chip
                            className={classes.tagChip}
                            size="small"
                            label={t ? t.name : ""}
                            clickable
                            color="primary"
                            variant="outlined"
                            onDelete={e => handleRemoveTag(tagId)}
                            deleteIcon={<CancelIcon />}
                          />
                        </li>
                      )
                    })}
                </Paper>
              </Grid>

              {/* {body && body.length > 0 && (
                <Grid item xs={12}>
                  <Typography>Preview:</Typography>
                  <Paper className={classes.previewPaper}>
                    <ReactMarkdown className={classes.body}>
                      {body}
                    </ReactMarkdown>
                  </Paper>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  label="Body"
                  style={{ margin: 8 }}
                  placeholder="Body"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={8}
                  rowsMax={120}
                  InputLabelProps={{
                    shrink: true,
                    rows: 10,
                  }}
                  variant="outlined"
                  value={body}
                  onChange={event => setBody(event.target.value)}
                />
              </Grid> */}
              <Grid item xs={12}>
                <MDEditor value={body} onChange={setBody} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={staffOnly}
                        onChange={event => setStaffOnly(event.target.checked)}
                        name="staffOnly"
                        color="secondary"
                      />
                    }
                    label="Staff Only"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={membersOnly}
                        onChange={event => setMembersOnly(event.target.checked)}
                        name="membersOnly"
                        color="primary"
                      />
                    }
                    label="Project Members Only"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={submitting}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  )
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  roundedCard: {
    borderRadius: "1rem",
  },
  body: {
    fontFamily: theme.typography.fontFamily,
    "& h1": {
      marginBottom: theme.spacing(3),
    },
    "& h2": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& p": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& li": {
      marginLeft: theme.spacing(4),
    },
  },
  previewPaper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  tags: {
    fontSize: "19px",
    display: "inline",
  },
  chipList: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  tagChip: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
})

export const PROJECT_DOCS_FORM_QUERY = gql`
  query {
    docsTags {
      id
      name
    }
  }
`

const NEW_PROJECT_DOC_MUTATION = gql`
  mutation (
    $projectId: Int!
    $membersOnly: Boolean
    $staffOnly: Boolean
    $title: String
    $body: String
    $tagIds: [Int]
  ) {
    createProjectDoc(
      projectId: $projectId
      membersOnly: $membersOnly
      staffOnly: $staffOnly
      title: $title
      body: $body
      tagIds: $tagIds
    ) {
      projectDoc {
        id
        project {
          id
          title
        }
        doc {
          id
          dateAdded
          dateModified
          title
          body
          category {
            id
            name
          }
          tags {
            id
            name
          }
          author {
            id
            person {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`
export default withStyles(styles)(NewProjectDoc)
