export default function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_TASK_DETAILS":
      return {
        ...state,
        taskDetails: !state.taskDetails,
      }
    case "TOGGLE_TODO_LIST":
      return {
        ...state,
        todoList: !state.todoList,
      }
    case "SET_STAFF_LIST":
      return {
        ...state,
        staffList: action.payload,
      }
    case "SET_TAG_OPTIONS":
      return {
        ...state,
        tagOptions: action.payload,
      }
    case "SET_PROJECT_TITLE":
      return {
        ...state,
        title: action.payload,
      }
    case "SET_PROJECT_CATEGORY_ID":
      return {
        ...state,
        categoryId: action.payload,
      }
    case "SET_PROJECT_TAGS":
      return {
        ...state,
        projectTags: action.payload,
      }
    case "SET_CLIENT_PROJECT":
      return {
        ...state,
        clientProject: action.payload,
      }
    case "SET_PROJECT_OBJECTIVE":
      return {
        ...state,
        objective: action.payload,
      }
    case "SET_PROJECT_PRIORITY":
      return {
        ...state,
        priority: action.payload,
      }
    case "SET_PROJECT_MANAGER_ID":
      return {
        ...state,
        projectManagerId: action.payload,
      }
    case "SET_CATEGORY_OPTIONS":
      return {
        ...state,
        categoryOptions: action.payload,
      }
    case "SET_IS_SUBMITTING":
      return {
        ...state,
        isSubmitting: action.payload,
      }
    case "SET_START_DATE":
      return {
        ...state,
        startDate: action.payload,
      }
    case "SET_DUE_DATE":
      return {
        ...state,
        dueDate: action.payload,
      }
    case "SET_PAGE_CHANGE":
      return {
        ...state,
        page: action.payload,
      }
    default:
      return state
  }
}
