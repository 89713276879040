import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Box, Breadcrumbs, Typography, makeStyles } from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

const useStyles = makeStyles(theme => ({
  root: {},
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

function Header({ className, ...rest }) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          variant="body1"
          color="inherit"
          className={classes.link}
          to="/manager/"
        >
          Dashboard
        </Link>
        <Link
          variant="body1"
          color="inherit"
          className={classes.link}
          to="/manager/home"
        >
          Management
        </Link>
        <Typography variant="body1" color="textPrimary">
          People
        </Typography>
      </Breadcrumbs>
      <Box mt={2}>
        <Typography variant="h3" color="textPrimary">
          Add a New Person
        </Typography>
      </Box>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
