import React, { useState, useEffect } from "react"
import clsx from "clsx"
import Error from "../../../../Utils/Error"
import Loading from "../../../../Utils/Loading"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import useDidMountEffect from "../../../../Global/hooks/useDidMountEffect"
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  makeStyles,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2, 0, 1),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  input: {
    display: "none",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {},

  image: {
    width: 200,
  },
  inquiryImg: {
    maxWidth: 300,
  },
}))

// const InquiryItemImageCard = ()=>{
//   return(
//     <>
//     <Card elevation={0}>
//     <Grid container spacing={1}>
//                       <Grid item xs={8} sm={4}>
//                         <img src={i}
//                       </Grid>
//                       <Grid item xs={8} sm={4}>
//                         <Typography className={classes.secondaryHeading}>
//                           {inquiryItem.inquiryItemType.name}
//                         </Typography>
//                       </Grid>
//                     </Grid>

//     </Card>
//     </>
//   )
// }
const ItemImage = ({
  className,
  item,
  inquiryItem,
  setActiveStep,
  ...rest
}) => {
  const classes = useStyles()
  const [images, setImages] = useState([])
  const [file, setFile] = useState("")
  const [fileError, setFileError] = useState("")
  const [title, setTitle] = useState("")
  const [note, setNote] = useState("")
  const [success, setSuccess] = useState(false)
  const [isProof, setIsProof] = useState(false)
  const [isFeatured, setIsFeatured] = useState(false)
  const [inquiryImage, setInquiryImage] = useState("")
  const [showInquiryImage, setShowInquiryImage] = useState(true)
  const [newForm, setNewForm] = useState(true)
  const [newArray, setNewArray] = useState([])
  const [imageId, setImageId] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const itemId = item.id

  const [deleteImage] = useMutation(DELETE_IMAGE_MUTATION, {
    variables: {
      imageId: imageId,
    },
    onCompleted: data => {
      setImages(newArray)
      console.log("deleted")
    },
  })
  const [handleSubmit, { loading, error }] = useMutation(
    ITEM_IMAGE_UPLOAD_MUTATION,
    {
      variables: {
        itemId: itemId,
        image: file,
        title: title,
        note: note,
        featured: isFeatured,
        proof: isProof,
      },
      onCompleted: (data, response, errors) => {
        let imageData = data.uploadImageFile.itemImage
        console.log(imageData)
        const newImage = { ...imageData }
        setImages(images => [...images, newImage])
        setFile("")
        setTitle("")
        setNote("")
        setSuccess(true)
        setShowInquiryImage(false)
        setSubmitting(false)
      },
      onError: err => console.error(err),
      // update={handleUpdateCache}
    }
  )

  const handleCheckProof = event => {
    setIsProof(event.target.checked)
  }
  const handleCheckFeatured = event => {
    setIsFeatured(event.target.checked)
  }

  // const handleConfirmDelete = () => {
  //   setConfirmDelete(true)
  // }
  const handleDelete = image => {
    const filteredArray = images.filter(x => x.id !== image.id)
    setNewArray(filteredArray)
    setImageId(image.id)
    // setConfirmDelete(false)
    // handleMenuClose()
    // setImageId(file.id)
  }
  useDidMountEffect(() => {
    deleteImage()
  }, [imageId])
  //   const handleDrop = useCallback(acceptedFiles => {
  //     setFile(acceptedFiles[0])
  //   }, [])

  //   const handleRemoveFile = () => {
  //     setFile("")
  //   }

  //   const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //     multiple: false,
  //     maxsize: 5000000,
  //     accept: "image/*",
  //     onDrop: handleDrop,
  //   })

  //   const handleSubmit = event => {
  //     console.log("just a placeholder")
  //   }
  const handleImageChange = event => {
    const selectedFile = event.target.files[0]
    const fileSizeLimit = 10000000 // 10mb
    if (selectedFile && selectedFile.size > fileSizeLimit) {
      setFileError(`${selectedFile.name}: File size too large`)
    } else {
      setFile(selectedFile)
      setFileError("")
    }
  }
  const handleNextStep = event => {
    setActiveStep(5)
  }
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const id = open ? "simple-popover" : undefined

  const handlePreview = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleAddInquiryFile = event => {
    setTitle("Customer File")
    handleSubmit()
  }
  return (
    <>
      <Typography variant="h3">{item.name}</Typography>
      <Typography variant="body1">Select Item Images</Typography>
      <Typography variant="caption">
        User uploaded files are auto-saved.
      </Typography>

      {/* Note: Do an array deletable Chips with mini photo or avatar here */}
      {/* {success && <>{JSON.stringify(images)}</> } */}
      {success &&
        images.map(image => (
          <>
            <Chip
              key={image.id}
              avatar={<Avatar alt={image.title} src={image.imageUrl} />}
              // icon={image.imageUrl}
              label={image.title}
              onClick={handlePreview}
              onDelete={() => handleDelete(image)}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <img
                className={classes.image}
                alt={image.title}
                src={image.imageUrl}
              />
            </Popover>
          </>
        ))}

      <FormControl>
        <form
          onSubmit={event => {
            event.preventDefault()
            setSubmitting(true)
            handleSubmit(event)
          }}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <TextField
            label="Image Title"
            placeholder="Image Title (optional)"
            fullWidth
            margin="dense"
            size="small"
            variant="outlined"
            onChange={e => setTitle(e.target.value)}
            value={title}
          />

          <TextField
            label="Note"
            placeholder="Note about this image (optional)"
            fullWidth
            margin="dense"
            size="small"
            variant="outlined"
            onChange={e => setNote(e.target.value)}
            value={note}
          />

          <input
            id="image"
            required
            type="file"
            accept="image/*"
            className={classes.input}
            onChange={handleImageChange}
          />
          <label htmlFor="image">
            <Button
              fullWidth
              variant="contained"
              color={file ? "inherit" : "primary"}
              component="span"
              className={classes.button}
              disabled={submitting}
            >
              Select an Image File
            </Button>
            <Typography>{file && file.name}</Typography>
          </label>

          <Box alignItems="center" display="flex" mt={2} ml={1}>
            <Checkbox checked={isProof} onChange={handleCheckProof} />

            <Typography variant="body2" color="textSecondary">
              This is a proof.
            </Typography>
          </Box>

          <Box alignItems="center" display="flex" mt={2} ml={1} mb={2}>
            <Checkbox checked={isFeatured} onChange={handleCheckFeatured} />

            <Typography variant="body2" color="textSecondary">
              Set this as the primary/featured image for this item.
            </Typography>
          </Box>
          {file && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                >
                  Upload Item Image
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  onClick={() => setFile("")}
                  color="primary"
                  variant="outlined"
                  disabled={submitting}
                >
                  Cancel Image
                </Button>
              </Grid>
            </Grid>
          )}
          <Box mt={4}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              disabled={submitting}
              onClick={handleNextStep}
            >
              Next: Pricing
            </Button>
          </Box>
        </form>
      </FormControl>
    </>
  )
}
const ITEM_IMAGE_UPLOAD_MUTATION = gql`
  mutation (
    $image: Upload
    $itemId: Int
    $title: String
    $note: String
    $featured: Boolean
    $proof: Boolean
  ) {
    uploadImageFile(
      image: $image
      itemId: $itemId
      title: $title
      note: $note
      featured: $featured
      proof: $proof
    ) {
      itemImage {
        id
        image
        imageUrl
        title
        note
        featured
        proof
        item {
          id
        }
      }
    }
  }
`

const DELETE_IMAGE_MUTATION = gql`
  mutation ($imageId: Int!) {
    deleteImageFile(imageId: $imageId) {
      imageId
    }
  }
`
export default ItemImage
