import React, { useContext, useReducer } from "react"
import ProposalListContext from "./ProposalListContext"
import ProposalListReducer from "./ProposalListReducer"

const ProposalListProvider = props => {
  const initialState = useContext(ProposalListContext)
  const [state, dispatch] = useReducer(ProposalListReducer, initialState)

  return (
    <ProposalListContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ProposalListContext.Provider>
  )
}

export default ProposalListProvider
