import React, { useState } from "react"
import { Box, Grid, Button, Typography } from "@material-ui/core"
import Conversations from "./Conversations"
import EmailPerson from "./EmailPerson"
const Service = ({ person }) => {
  const [section, setSection] = useState("conversations")
  const sectionSwitch = section => {
    switch (section) {
      case "email":
        return <EmailPerson person={person} />
      case "sms":
        return <div>sms</div>
      case "conversations":
        return <Conversations person={person} />

      default:
        return <div>None Selected</div>
    }
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Button
            fullWidth
            variant={section === "email" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("email")}
          >
            Email
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            fullWidth
            variant={section === "sms" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("sms")}
          >
            SMS
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            fullWidth
            variant={section === "conversations" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setSection("conversations")}
          >
            Conversations
          </Button>
        </Grid>
      </Grid>
      <Box mt={3}>{sectionSwitch(section)}</Box>
    </>
  )
}

export default Service
