import React, { useState, useContext } from "react"
import clsx from "clsx"
import moment from "moment"
import { Link } from "gatsby"
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from "@material-ui/core"
import Rating from "@material-ui/lab/Rating"
import Pagination from "@material-ui/lab/Pagination"
import PerfectScrollbar from "react-perfect-scrollbar"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import SortContext from "../../../../../../context/SortContext"
const useStyles = makeStyles(() => ({
  root: {},
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
}))

const ProposalList = ({ personQuotes, className, ...rest }) => {
  const classes = useStyles()
  const [quotes, setQuotes] = useState(personQuotes.objects)
  const { state, dispatch } = useContext(SortContext)
  const handleChange = (event, value) => {
    dispatch({ type: "SET_PAGE", payload: value })
  }
  if (personQuotes.count === 0) {
    return (
      <div>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardHeader title="No Proposals on Record" />
          <Divider />
        </Card>
      </div>
    )
  }

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          title="Proposals"
          action={
            personQuotes.pages > 1 && (
              <Pagination
                count={personQuotes.pages}
                page={state.page}
                onChange={handleChange}
              />
            )
          }
        />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Sent</TableCell>
                  <TableCell>Business Unit</TableCell>
                  <TableCell>Likelihood</TableCell>
                  <TableCell>Created By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quotes.map(quote => (
                  <TableRow key={quote.id}>
                    <TableCell>
                      <Link
                        to={`/manager/proposals/${quote.id}`}
                        state={{ id: `${quote.id}` }}
                      >
                        {quote.id}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {quote.sent
                        ? moment(quote.sentAt).format("DD MMM YYYY | HH:MM")
                        : "Not Sent"}
                    </TableCell>

                    <TableCell>{quote.businessUnit.name}</TableCell>
                    <TableCell>
                      <Rating
                        name="likelihood"
                        value={quote.likelihood}
                        readOnly
                      />
                    </TableCell>
                    <TableCell>
                      {quote.createdBy &&
                        quote.createdBy.person.firstName +
                          " " +
                          quote.createdBy.person.lastName}{" "}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  )
}

export default ProposalList
