import React, { useState, useContext } from "react"
import { Box, Container } from "@material-ui/core"

import withStyles from "@material-ui/core/styles/withStyles"
import PerfectScrollbar from "react-perfect-scrollbar"

import SortContext from "../../../../context/SortContext"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import Results from "./Results"
import Header from "./Header"
import Page from "../../../Global/Page"

const DocsListView = ({ classes }) => {
  const { state, dispatch } = useContext(SortContext)
  const [pageCount, setPageCount] = useState(1)
  const [postCount, setPostCount] = useState(1)
  const page = state.page
  const pageSize = state.pageSize
  const search = state.search
  const [docsCategoryFilter, setDocsCategoryFilter] = useState(0)
  const [docsTagsFilter, setDocsTagsFilter] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [tagList, setTagList] = useState([])

  const { data, loading, error } = useQuery(DOCS_LIST_QUERY, {
    variables: {
      page: page,
      pageSize: pageSize,
      search: search,
      docsCategoryFilter: docsCategoryFilter,
      docsTagsFilter: docsTagsFilter,
    },
    onCompleted: data => {
      setPageCount(data.pages)
      setPostCount(data.count)
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />
  const res = data.docsPosts
  return (
    <Page className={classes.root} title="Docs">
      {/* <PerfectScrollbar> */}
      <Container maxWidth={false}>
        <Header />
        <Results
          res={res}
          docsTagsFilter={docsTagsFilter}
          setDocsTagsFilter={setDocsTagsFilter}
          docsCategoryFilter={docsCategoryFilter}
          setDocsCategoryFilter={setDocsCategoryFilter}
        />
      </Container>
      {/* </PerfectScrollbar> */}
    </Page>
  )
}

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minHeight: "90vh",
  },
  card: {
    marginTop: theme.spacing(2),
  },
  docTitle: {
    fontSize: "22px",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
})

const DOCS_LIST_QUERY = gql`
  query (
    $page: Int
    $pageSize: Int
    $search: String
    $docsCategoryFilter: Int
    $docsTagsFilter: [Int]
  ) {
    docsPosts(
      page: $page
      pageSize: $pageSize
      search: $search
      docsCategoryFilter: $docsCategoryFilter
      docsTagsFilter: $docsTagsFilter
    ) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        title
        body
        category {
          id
          name
        }
        author {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        tags {
          id
          name
        }
        dateAdded
        dateModified
      }
    }
  }
`
export default withStyles(styles)(DocsListView)
