import React, { useState, useContext } from "react"
import { Divider, makeStyles } from "@material-ui/core"
import Toolbar from "./Toolbar"
import ConversationItem from "./ConversationItem"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import MessagesContext from "../context/MessagesContext"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    backgroundColor: theme.palette.background.dark,
  },
}))

const ConversationList = () => {
  const { state, dispatch } = useContext(MessagesContext)
  const classes = useStyles()
  const page = state.page ? state.page : 1
  const pageSize = state.pageSize ? state.pageSize : 15
  const search = state.search ? state.search : ""
  const archivedFilter = state.archivedFilter ? state.archivedFilter : false
  const starredFilter = state.starredFilter ? state.starredFilter : false
  const followingFilter = state.followingFilter ? state.followingFilter : false
  const hasUpdateFilter = state.hasUpdateFilter ? state.hasUpdateFilter : false
  const excludeArchivedFilter = state.excludeArchivedFilter
    ? state.excludeArchivedFilter
    : true

  const [selectedConversations, setSelectedConversations] = useState([])
  const [conversations, setConversations] = useState([])

  const { data, loading, error } = useQuery(USER_CONVERSATIONS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      page: page,
      pageSize: pageSize,
      search: search,
      starredFilter: starredFilter,
      followingFilter: followingFilter,
      hasUpdateFilter: hasUpdateFilter,
      excludeArchivedFilter: excludeArchivedFilter,
      archivedFilter: archivedFilter,
    },
    onCompleted: data => {
      console.log(data)
      const objects = data.userConversations.objects
      setConversations(data.userConversations)
      console.log(objects.length)
    },
  })

  // const handleUpdateCount = conversations => {
  //   // conversations.objects.filter(hasUpdate)
  //   dispatch({
  //     type: "UPDATE_COUNT",
  //     payload: conversations.length,
  //   })
  // }
  const handleSelectOneConversation = convId => {
    setSelectedConversations(prevSelectedConversations => {
      if (!prevSelectedConversations.includes(convId)) {
        return [...prevSelectedConversations, convId]
      }

      return prevSelectedConversations
    })
  }

  const handleDeselectOneConversation = convId => {
    setSelectedConversations(prevSelectedConversations =>
      prevSelectedConversations.filter(id => id !== convId)
    )
  }

  const handleSelectAllConversations = () => {
    setSelectedConversations(conversations.objects.map(c => c.id))
  }
  const handleDeselectAllConversations = () => {
    setSelectedConversations([])
  }

  if (loading) return <Loading />
  if (error) return <Error />
  // const conversations = data.userConversations.objects
  return (
    <div className={classes.root}>
      <Toolbar
        onDeselectAll={handleDeselectAllConversations}
        onSelectAll={handleSelectAllConversations}
        selectedConversations={selectedConversations.length}
        conversations={conversations}
      />
      <Divider />

      {conversations &&
        conversations.count > 0 &&
        conversations.objects.map(c => {
          const conv = c
          const convId = c.id
          return (
            <ConversationItem
              conv={conv}
              convId={convId}
              key={c.id}
              onDeselect={() => handleDeselectOneConversation(convId)}
              onSelect={() => handleSelectOneConversation(convId)}
              selected={selectedConversations.includes(convId)}
            />
          )
        })}
    </div>
  )
}
const USER_CONVERSATIONS_QUERY = gql`
  query (
    $page: Int
    $pageSize: Int
    $search: String
    $starredFilter: Boolean
    $followingFilter: Boolean
    $hasUpdateFilter: Boolean
    $archivedFilter: Boolean
    $excludeArchivedFilter: Boolean
  ) {
    userConversations(
      page: $page
      pageSize: $pageSize
      search: $search
      starredFilter: $starredFilter
      followingFilter: $followingFilter
      hasUpdateFilter: $hasUpdateFilter
      archivedFilter: $archivedFilter
      excludeArchivedFilter: $excludeArchivedFilter
    ) {
      page
      count
      pages
      hasNext
      hasPrev
      objects {
        id
        hasUpdate
        conversation {
          uuid
          topic
          lastUpdate
          archived
          startedBy {
            id
            admin
            staff
            manager
            partner
            person {
              uid
              firstName
              lastName
            }
          }
          participants {
            id
            person {
              firstName
              lastName
            }
          }
          category {
            id
            name
          }
          sbu {
            id
            name
          }
          topic
          archived
          startDate
          archivedDate
          conversationMessage {
            uuid
            sent
          }
        }
        firstQuery
        latestQuery
        follow
        star
        alerts
      }
    }
  }
`

export default ConversationList
