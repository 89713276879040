import React, { useState, useContext } from "react"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import DoneIcon from "@material-ui/icons/Done"
import useDidMountEffect from "../../../../../Global/hooks/useDidMountEffect"

import {
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import OrgCreateContext from "../../context/OrgCreateContext"

const AddPersonSocialMediaForm = ({ classes }) => {
  const { state, dispatch } = useContext(OrgCreateContext)
  const personUid = state.selectedPerson.uid

  const [username, setUsername] = useState("")
  const [id, setId] = useState(null)

  const [socialMediaTypes, setSocialMediaTypes] = useState([])
  const [socialMediaType, setSocialMediaType] = useState(1)
  const [socialMediaTypeId, setSocialMediaTypeId] = useState(1)
  const [socialMediaTypeUrl, setSocialMediaTypeUrl] = useState("")

  const [addedAccounts, setAddedAccounts] = useState([])
  const [url, setUrl] = useState("")
  const [newArray, setNewArray] = useState([])

  const { loading, error } = useQuery(SOCIAL_MEDIA_TYPES_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      setSocialMediaTypes(data.socialMediaTypes)
    },
  })
  const [deleteSocialMediaAccountPerson] = useMutation(
    DELETE_PERSON_SOCIAL_MEDIA_MUTATION,
    {
      variables: {
        id: id,
      },
      onCompleted: () => {
        setAddedAccounts(newArray)
        console.log("delete completed")
      },
    }
  )
  const [createSocialMediaAccountPerson] = useMutation(
    ADD_PERSON_SOCIAL_MEDIA_MUTATION,
    {
      variables: {
        personUid: personUid,
        username: username,
        socialMediaTypeId: socialMediaTypeId,
        url: url,
      },
      onCompleted: data => {
        // console.log(data.createSocialMediaAccountPerson)
        const accountValue =
          data.createSocialMediaAccountPerson.socialMediaAccountPerson
        setAddedAccounts(prevSelected => [...prevSelected, accountValue])
        setUsername("")
        setUrl("")
      },
    }
  )
  const handleFormSocialMedia = event => {
    createSocialMediaAccountPerson()
  }
  const handleSocialMediaTypeChange = event => {
    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
    }
    const smtype = socialMediaTypes.filter(x => x.id == value).pop()
    setSocialMediaTypeUrl(smtype.url)
    setSocialMediaTypeId(value)
    setSocialMediaType(value)
  }

  const handleDelete = account => {
    const filteredArray = addedAccounts.filter(x => x.id !== account.id)
    setNewArray(filteredArray)
    setId(account.id)
  }

  useDidMountEffect(() => {
    deleteSocialMediaAccountPerson()
  }, [id])

  useDidMountEffect(() => {
    const fullUrl = `${socialMediaTypeUrl}${username}`
    setUrl(fullUrl)
  }, [username, socialMediaTypeId])

  const handleCompleteAddPerson = () => {
    dispatch({ type: "SET_SELECTED_PERSON", payload: "" })
    dispatch({ type: "SET_NEW_PERSON_ACTIVE_STEP", payload: 0 })
    dispatch({ type: "SET_PEOPLE_VIEW", payload: "listView" })
  }
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography>
            Do you know any social media accounts for this person? If not, skip.
          </Typography>

          {addedAccounts.length === 0 ? (
            ""
          ) : (
            <>
              {addedAccounts.map(account => (
                <Box key={account.id}>
                  <Chip
                    icon={<DoneIcon />}
                    label={account.username}
                    variant="outlined"
                    onDelete={e => handleDelete(account)}
                  />
                </Box>
              ))}
            </>
          )}
        </Grid>
        <Grid item sm container direction="column">
          <form
            onSubmit={event => {
              event.preventDefault()
              handleFormSocialMedia(event)
            }}
            className={classes.root}
          >
            <Grid item xs={12}>
              <TextField
                name="socialMediaType"
                onChange={handleSocialMediaTypeChange}
                select
                margin="dense"
                size="small"
                fullWidth
                variant="outlined"
                SelectProps={{ native: true }}
                value={socialMediaType}
              >
                {socialMediaTypes.map(smt => (
                  <option key={smt.id} value={smt.id}>
                    {smt.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Username"
                margin="normal"
                name="username"
                onChange={event => setUsername(event.target.value)}
                type="text"
                value={username}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="URL"
                margin="normal"
                name="url"
                onChange={event => setUrl(event.target.value)}
                type="url"
                value={url}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                size="medium"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                aria-label="add social media acct"
                className={classes.margin}
              >
                Add Social Media Account
              </Button>
            </Grid>
          </form>
        </Grid>

        <Grid item xs={12}>
          <Button
            color="secondary"
            fullWidth
            size="large"
            variant="outlined"
            // IMOPORTANT activeStep(5) is localized option. Do not change.
            onClick={handleCompleteAddPerson}
          >
            Finish
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

const ADD_PERSON_SOCIAL_MEDIA_MUTATION = gql`
  mutation (
    $personUid: String!
    $socialMediaTypeId: Int!
    $username: String!
    $url: String
  ) {
    createSocialMediaAccountPerson(
      personUid: $personUid
      socialMediaTypeId: $socialMediaTypeId
      username: $username
      url: $url
    ) {
      socialMediaAccountPerson {
        id
        username
        socialMediaType {
          id
          name
          url
        }
        url
      }
    }
  }
`

const DELETE_PERSON_SOCIAL_MEDIA_MUTATION = gql`
  mutation ($id: Int!) {
    deleteSocialMediaAccountPerson(id: $id) {
      id
    }
  }
`

const SOCIAL_MEDIA_TYPES_QUERY = gql`
  query {
    socialMediaTypes {
      id
      name
      url
    }
  }
`
AddPersonSocialMediaForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
}

AddPersonSocialMediaForm.default = {
  onSubmitSuccess: () => {},
}

const styles = theme => ({
  root: {},
  button: {
    margin: 10,
  },
  margin: {
    margin: 2,
  },
})

export default withStyles(styles)(AddPersonSocialMediaForm)
