/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _ from "lodash"
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core"
// import HUE from '@material-ui/core/colors/HUE'
import typography from "./typography"
import { softShadows, strongShadows } from "./shadows"
import { THEMES } from "../constants"

// const color = HUE[SHADE]
const baseConfig = {
  direction: "ltr",
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 2,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
  },
}

// MUI Colors are here: https://material-ui.com/customization/color/#color

const themeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: colors.grey[600],
          },
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: colors.grey[400],
      },
      background: {
        default: "#f7f7f7",
        // dark: colors.common.white,
        dark: "#f7f7f7",
        paper: colors.common.white,
        selected: "#b7dbf4",
      },
      primary: {
        main: "#3e5063",
        // main: "#2e4a75",
        light: "#4d7cbb",
        dark: "#142235",
        // main: "#3f6177",
        // light: "#a0b6c8",
        // dark: "#233b4c",
        // main: "#a99a86",
        // light: "#dbcbb6",
        // dark: "#7a6c59",
        // main: colors.purple[500],
        // light: colors.purple[100],
        // dark: colors.purple[900],
      },
      secondary: {
        // main: colors.blue[500],
        main: "#1E1E1E",
        light: "#C5C5C5",
        dark: "#010101",
        // main: guideGreen,
        // light: "#3b5f47",
        // dark: "#001000",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        primaryContrast: colors.purple[50],
        secondaryContrast: colors.common.white,
        danger: colors.red[600],
        success: colors.green[300],
        warning: colors.yellow[200],
        link: colors.blue[800],
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.TWO_LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: "#f4f6f8",
        selected: "#b7dbf4",
        paper: colors.common.white,
      },
      primary: {
        main: colors.lightBlue[800],
        // main: colors.indigo[600]
      },
      secondary: {
        main: "#5850EC",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        link: colors.blue[800],
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#282C34",

        dark: "#1c2025",
        paper: "#282C34",
      },
      primary: {
        main: "#8a85ff",
      },
      secondary: {
        main: "#8a85ff",
      },
      text: {
        primary: "#e6e5e8",
        secondary: "#adb0bb",
        link: colors.blue[800],
      },
    },
    shadows: strongShadows,
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#2a2d3d",
        dark: "#222431",
        paper: "#2a2d3d",
      },
      primary: {
        main: "#a67dff",
      },
      secondary: {
        main: "#a67dff",
      },
      text: {
        primary: "#f6f5f8",
        secondary: "#9699a4",
        accountLink: "#CC5500",
      },
    },
    shadows: strongShadows,
  },
]

export function createTheme(settings = {}) {
  let themeConfig = themeConfigs.find(theme => theme.name === THEMES.LIGHT)
  // let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme)

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`))
    ;[themeConfig] = themeConfigs
  }

  let theme = createMuiTheme(
    _.merge({}, baseConfig, themeConfig, { direction: settings.direction })
    // _.merge({}, baseConfig, themeConfig)
  )

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
