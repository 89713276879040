import React, { useState, useEffect } from "react"
import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import DocsCategorySelect from "../DocsPublishView/DocsCategorySelect"
import DocsTags from "../DocsPublishView/DocsTags"
import { X as CancelIcon } from "react-feather"
import ReactMarkdown from "react-markdown"
import MDEditor from "@uiw/react-md-editor"

const EditMode = ({
  classes,
  postData,
  setEditMode,
  title,
  setTitle,
  category,
  setCategory,
  body,
  setBody,
  tags,
  setTags,
}) => {
  const tagIdArray = tags.map(t => t.id)
  //   const [title, setTitle] = useState(postData.title)
  //   const [category, setCategory] = useState(postData.category)
  //   const [body, setBody] = useState(postData.body)
  //   const [tags, setTags] = useState(tags)
  const [tagIds, setTagIds] = useState(tagIdArray)
  const [submitting, setSubmitting] = useState(false)

  const { data, loading, error } = useQuery(DOCS_FORM_QUERY)
  const handleUpdatePost = () => {
    setSubmitting(true)
    updatePost()
  }
  const [updatePost] = useMutation(UPDATE_POST_MUTATION, {
    variables: {
      postId: postData.id,
      title: title,
      body: body,
      categoryId: category.id,
      tagIds: tagIds,
    },
    onCompleted: data => {
      const post = data.updatePost.post
      setTitle(post.title)
      setBody(post.body)
      setCategory(post.category)
      setTags(post.tags)
      setSubmitting(false)
      setEditMode(false)
    },
  })
  const handleRemoveTag = (tagId, event) => {
    const filteredArray = tagIds.filter(t => t !== tagId)
    setTagIds(filteredArray)
  }
  const handleCancelEdit = () => {
    setTitle(postData.title)
    setBody(postData.body)
    setCategory(postData.category)
    setTags(postData.tags)
    setEditMode(false)
  }
  if (loading) return <Loading />
  if (error) return <Error />
  const allTags = data.docsTags
  const categoryOptions = data.docsCategories
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <form
                onSubmit={event => {
                  event.preventDefault()
                  handleUpdatePost(event)
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={11}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Edit Mode
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Edit Doc
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleCancelEdit}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Title"
                      style={{ margin: 8 }}
                      placeholder="Title"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={title}
                      onChange={event => setTitle(event.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <DocsCategorySelect
                      categoryOptions={categoryOptions}
                      category={category}
                      setCategory={setCategory}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DocsTags
                      allTags={allTags}
                      tagIds={tagIds}
                      setTagIds={setTagIds}
                      setTags={setTags}
                    />
                  </Grid>

                  {/* <BodyEditor body={body} setBody={setBody} /> */}
                  <Grid item xs={12}>
                    <Paper
                      elevation={0}
                      component="ul"
                      className={classes.chipList}
                    >
                      {tagIds &&
                        tagIds.length > 0 &&
                        tagIds.map(tagId => {
                          const t = allTags.find(
                            element => element.id === tagId
                          )
                          return (
                            <li key={tagId}>
                              <Chip
                                className={classes.tagChip}
                                size="small"
                                label={t ? t.name : ""}
                                clickable
                                color="primary"
                                variant="outlined"
                                onDelete={e => handleRemoveTag(tagId)}
                                deleteIcon={<CancelIcon />}
                              />
                            </li>
                          )
                        })}
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <MDEditor value={body} onChange={setBody} />
                  </Grid>

                  {/* {body && body.length > 0 && (
                    <Grid item xs={12}>
                      <Typography>Preview:</Typography>
                      <Paper className={classes.previewPaper}>
                        <ReactMarkdown className={classes.preview}>
                          {body}
                        </ReactMarkdown>
                      </Paper>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      label="Body"
                      style={{ margin: 8 }}
                      placeholder="Body"
                      fullWidth
                      margin="normal"
                      multiline
                      rows={8}
                      rowsMax={120}
                      InputLabelProps={{
                        shrink: true,
                        rows: 10,
                      }}
                      variant="outlined"
                      value={body}
                      onChange={event => setBody(event.target.value)}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={submitting}
                    >
                      {submitting ? "Updating..." : "Update"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

const styles = theme => ({
  root: {},
  preview: {
    fontFamily: theme.typography.fontFamily,
  },
  previewPaper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  tags: {
    fontSize: "19px",
    display: "inline",
  },
  chipList: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  tagChip: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
})
const DOCS_FORM_QUERY = gql`
  query {
    docsTags {
      id
      name
    }
    docsCategories {
      id
      name
    }
  }
`
const UPDATE_POST_MUTATION = gql`
  mutation (
    $postId: Int!
    $title: String
    $body: String
    $categoryId: Int
    $tagIds: [Int]
  ) {
    updatePost(
      postId: $postId
      title: $title
      body: $body
      categoryId: $categoryId
      tagIds: $tagIds
    ) {
      post {
        id
        title
        body
        category {
          id
          name
        }
        author {
          id
          person {
            uid
            firstName
            lastName
          }
        }
        tags {
          id
          name
        }
        dateAdded
        dateModified
      }
    }
  }
`
export default withStyles(styles)(EditMode)
