import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { useLocation } from "@reach/router"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

function Header({ cart, className, ...rest }) {
  const classes = useStyles()
  const cartId = cart.id
  const location = useLocation()
  const userPermission = location.pathname.split("/")[1]
  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            className={classes.link}
            to={`/${userPermission}`}
            component={Link}
          >
            Dashboard
          </Link>
          <Link
            variant="body1"
            color="inherit"
            className={classes.link}
            to={`/${userPermission}/carts`}
            component={Link}
          >
            Carts
          </Link>
          <Typography variant="body1" color="textPrimary">
            Cart {cartId}
          </Typography>
        </Breadcrumbs>
        <Box mt={2}>
          <Typography variant="h3" color="textPrimary">
            Cart Details
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired,
}

export default Header
