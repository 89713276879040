import React from "react"
import clsx from "clsx"

import { Button, Box, TextField, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 2,
    marginBotton: 5,
  },
}))

const UpdateCategory = ({
  className,
  item,
  categories,
  category,
  setCategory,
  setUpdateItem,
  updateItemInfo,
  isSubmitting,
  ...rest
}) => {
  const classes = useStyles()

  const handleUpdateCategory = event => {
    event.preventDefault()
    updateItemInfo()
  }

  return (
    <>
      <Box padding={5}>
        <form
          onSubmit={event => handleUpdateCategory(event)}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <TextField
            label="Select an Item Category"
            name="category"
            onChange={event => setCategory(event.target.value)}
            value={category}
            select
            margin="dense"
            size="small"
            fullWidth
            variant="outlined"
            SelectProps={{ native: true }}
          >
            <option></option>
            {categories.map(c => (
              <option key={c.id} value={c}>
                {c.name}
              </option>
            ))}
          </TextField>
          <Box mt={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Update Item Category
            </Button>
          </Box>
        </form>
      </Box>
    </>
  )
}

export default UpdateCategory
