import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Card, CardContent, Grid, makeStyles } from "@material-ui/core"
import ProjectFilesDropzone from "./ProjectFilesDropzone"
import FileCard from "./FileCard"

const useStyles = makeStyles(() => ({
  root: {},
}))

const Files = ({ project, className, ...rest }) => {
  const classes = useStyles()
  const [projectFiles, setProjectFiles] = useState(project.projectFile)
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardContent>
          <ProjectFilesDropzone
            project={project}
            projectFiles={projectFiles}
            setProjectFiles={setProjectFiles}
          />
        </CardContent>
      </Card>
      <Grid container spacing={3}>
        {projectFiles.map(file => (
          <Grid item key={file.id} lg={4} md={4} sm={6} xs={12}>
            <FileCard file={file} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

Files.propTypes = {
  className: PropTypes.string,
  projectFiles: PropTypes.array.isRequired,
}

export default Files
