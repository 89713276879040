import React, { createContext } from "react"

const SpecContext = createContext({
  itemId: null,
  item: "",
  specId: "",
  specSummary: "",
  categoryId: "",
  editing: false,
  item: "",
  inquirySpecs: "",
  updateItem: false,
  activeStep: null,
  mymessage: "hey is this working?",
})

export default SpecContext
