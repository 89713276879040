import React, { useState, useContext } from "react"
import { Link, navigate } from "gatsby"
import clsx from "clsx"
import moment from "moment"
import PropTypes from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"

import {
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
  Clock as ExpiredIcon,
  ShoppingCart as CartIcon,
} from "react-feather"
import { useLocation } from "@reach/router"
import GenericMoreButton from "../../../Global/GenericMoreButton"
import BulkOperations from "./BulkOperations"
import SortContext from "../../../../context/SortContext"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

function applyPagination(carts, page, limit) {
  return carts.slice(page * limit, page * limit + limit)
}

const sortOptions = [
  {
    value: "newestFirst",
    label: "Newest first",
  },
  {
    value: "oldestFirst",
    label: "Oldest first",
  },
]

const useStyles = makeStyles(theme => ({
  root: {},
  table: {
    marginTop: 5,
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

function Results({
  className,
  res,
  defaultStartDate,
  now,
  cartOpenFilter,
  setCartOpenFilter,
  cartExpiredFilter,
  setCartExpiredFilter,
  cartBusinessUnitFilter,
  setCartBusinessUnitFilter,
  cartStartedFilter,
  setCartStartedFilter,
  cartLatestChangeFilter,
  setCartLatestChangeFilter,
  //   recentFirstFilter,
  //   setRecentFirstFilter,
  ...rest
}) {
  const { state, dispatch } = useContext(SortContext)
  const location = useLocation()
  const userPermission = location.pathname.split("/")[1]
  const page = state.page
  const pageSize = state.pageSize
  const searchContext = state.search
  const [searchBar, setSearchBar] = useState(searchContext)
  const classes = useStyles()
  const resCount = res.count
  const resPage = res.page
  const resPages = res.pages
  const carts = res.objects
  const [sort, setSort] = useState(sortOptions[0].value)

  const [selectedCarts, setSelectedCarts] = useState([])
  const { data, loading, error } = useQuery(SBU_QUERY, {
    fetchPolicy: "cache-and-network",
  })
  //   const [startedFromDate, setStartedFromDate] = useState(
  //     new Date("2014-08-18T21:11:54")
  //   )
  //   const [startedToDate, setStartedToDate] = useState(
  //     new Date("2014-08-18T21:11:54")
  //   )
  //   const [latestChangeFromDate, setLatestChangeFromDate] = useState(
  //     new Date("2014-08-18T21:11:54")
  //   )
  //   const [latestChangeToDate, setLatestChangeToDate] = useState(
  //     new Date("2014-08-18T21:11:54")
  //   )
  const handleSearch = () => {
    dispatch({ type: "SET_SEARCH", payload: searchBar })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  const handleSelectAllCarts = (event, cart) => {
    setSelectedCarts(event.target.checked ? carts.map(cart => cart.cartId) : [])
  }

  const handleSelectOneCart = (event, cartUid) => {
    if (!selectedCarts.includes(cartUid)) {
      setSelectedCarts(prevSelected => [...prevSelected, cartUid])
    } else {
      setSelectedCarts(prevSelected =>
        prevSelected.filter(id => id !== cartUid)
      )
    }
  }

  const handlePageChange = (event, newPage) => {
    let x = 1
    const pagiNewPage = newPage
    dispatch({ type: "SET_PAGE", payload: pagiNewPage })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({ type: "SET_PAGE_SIZE", payload: parseInt(event.target.value) })
    dispatch({ type: "SET_PAGE", payload: 0 })
  }

  // const handleRecentFirstChange = event => {
  //   event.persist()

  //   let value = null

  //   if (event.target.checked) {
  //     value = true
  //   }

  //   setFromCartFilter(value)
  // }

  // const handleInProgressChange = event => {
  //   event.persist()

  //   let value = null

  //   if (event.target.checked) {
  //     value = true
  //   }
  //   setInProgressFilter(value)
  // }

  const handleBusinessUnitChange = event => {
    event.persist()

    let value = 0

    if (event.target.value !== 0) {
      value = event.target.value
    }

    setCartBusinessUnitFilter(value)
  }

  const handleCartExpiredFilterChange = event => {
    event.persist()

    //   let value = null
    if (cartExpiredFilter === null) {
      setCartExpiredFilter(true)
    }
    if (cartExpiredFilter === true) {
      setCartExpiredFilter(false)
    }
    if (cartExpiredFilter === false) {
      setCartExpiredFilter(true)
    }
    //   if (event.target.value !== null) {
    //     value = event.target.value
    //   }

    // setCartExpiredFilter(value)
  }

  // const handleOrderStatusChange = event => {
  //   event.persist()

  //   let value = ""

  //   if (event.target.value !== "all") {
  //     value = event.target.value
  //   }
  //   setOrderStatusFilter(value)
  // }
  //   const handleSortChange = event => {
  //     event.persist()
  //     if (event.target.value !== "oldestFirst") {
  //       // value = event.target.valuesetRecentFirstFilter(true)
  //     } else {
  //       setRecentFirstFilter(false)
  //     }

  //     // setSort(event.target.value)
  //   }

  const handleClearSearchResults = () => {
    dispatch({ type: "SET_SEARCH", payload: "" })
  }

  const handleClearFilters = event => {
    setCartBusinessUnitFilter(0)
    setCartStartedFilter([defaultStartDate, now])
    setCartLatestChangeFilter([defaultStartDate, now])
    setCartExpiredFilter(null)
    // setFromCartFilter(null)
    // setInProgressFilter(null)
    // setOrderStatusFilter("")
  }

  const handleCartStartedFromChange = date => {
    let newDates = [...cartStartedFilter]
    newDates[0] = date
    newDates[1] = cartStartedFilter[1]
    // setSelectedDate(date);
    setCartStartedFilter(newDates)
  }
  const handleCartStartedToChange = date => {
    let newDates = [...cartStartedFilter]
    newDates[0] = cartStartedFilter[0]
    newDates[1] = date

    setCartStartedFilter(newDates)
  }

  const handleCartLatestChangeFromChange = date => {
    let newDates = [...cartLatestChangeFilter]
    newDates[0] = date
    newDates[1] = cartLatestChangeFilter[1]
    // setSelectedDate(date);
    setCartLatestChangeFilter(newDates)
  }
  const handleCartLatestChangeToChange = date => {
    let newDates = [...cartLatestChangeFilter]
    newDates[0] = cartLatestChangeFilter[0]
    newDates[1] = date

    setCartLatestChangeFilter(newDates)
  }

  const enableBulkOperations = selectedCarts.length > 0
  const selectedSomeCarts =
    selectedCarts.length > 0 && selectedCarts.length < carts.length
  const selectedAllCarts = selectedCarts.length === carts.length
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const sbus = data.sbus
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={10} sm={5}>
              <Paper
                component="form"
                onSubmit={event => {
                  event.preventDefault()
                  handleSearch(event)
                }}
                className={classes.searchField}
              >
                <InputBase
                  fullWidth
                  className={classes.input}
                  placeholder="Search Carts"
                  inputProps={{ "aria-label": "Search Carts" }}
                  onChange={event => setSearchBar(event.target.value)}
                  value={searchBar}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="clear"
                  onClick={handleClearSearchResults}
                >
                  <CancelIcon />
                </IconButton>
              </Paper>
            </Grid>

            {/* <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Sort By"
                name="sort"
                onChange={handleSortChange}
                select
                SelectProps={{ native: true }}
                value={recentFirstFilter}
                variant="outlined"
              >
                {sortOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid> */}
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12} sm={3}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="cart-started-from"
                  label="Cart Started From"
                  value={cartStartedFilter[0]}
                  onChange={handleCartStartedFromChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="cart-started-to"
                  label="Cart Started To"
                  value={cartStartedFilter[1]}
                  onChange={handleCartStartedToChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider> */}
          </Grid>
        </Box>
        <Divider />
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                className={classes.businessUnitField}
                label="Business Unit"
                name="businessUnit"
                onChange={handleBusinessUnitChange}
                select
                SelectProps={{ native: true }}
                value={cartBusinessUnitFilter}
                variant="outlined"
              >
                <option value={0}>All</option>
                {sbus.map(sbu => (
                  <option key={sbu.id} value={sbu.id}>
                    {sbu.name}
                  </option>
                ))}
                {/* {businessUnitOptions.map(businessUnitOption => (
                  <option
                    key={businessUnitOption.id}
                    value={businessUnitOption.id}
                  >
                    {businessUnitOption.name}
                  </option>
                ))} */}
              </TextField>
            </Grid>
            {/* <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                className={classes.businessUnitField}
                label="Expired Carts"
                name="expired"
                onChange={handleExpiredFilterChange}
                select
                SelectProps={{ native: true }}
                value={cartExpiredFilter}
                variant="outlined"
              >
                <option></option>
                {expiredOptions.map(expiredOption => (
                  <option key={expiredOption.id} value={expiredOption.value}>
                    {expiredOption.label}
                  </option>
                ))}
              </TextField>
            </Grid> */}
            <Grid item xs={6} sm={2}>
              <IconButton
                className={classes.iconButton}
                onClick={handleCartExpiredFilterChange}
              >
                <SvgIcon color={cartExpiredFilter ? "primary" : "action"}>
                  <ExpiredIcon />
                </SvgIcon>
              </IconButton>
              <IconButton
                className={classes.iconButton}
                onClick={() => setCartOpenFilter(!cartOpenFilter)}
              >
                <SvgIcon color={cartOpenFilter ? "secondary" : "action"}>
                  <CartIcon />
                </SvgIcon>
              </IconButton>

              {/* <FormControlLabel
                className={classes.shippableField}
                control={
                  <Checkbox
                    // checked={inProgressFilter}
                    // onChange={handleInProgressChange}
                    name="ShowExpiredCarts"
                  />
                }
                label="Include Expired Carts"
              /> */}
            </Grid>
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12} sm={2}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="cart-latest-change-from"
                  label="Cart Latest Change From"
                  value={cartLatestChangeFilter[0]}
                  onChange={handleCartLatestChangeFromChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="cart-latest-change-to"
                  label="Cart Latest Change To"
                  value={cartLatestChangeFilter[1]}
                  onChange={handleCartLatestChangeToChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider> */}
            {/* <Grid item xs={12} sm={3}>
              <TextField
                className={classes.orderStatusField}
                fullWidth
                label="Order Status"
                name="orderStatus"
                // onChange={handleOrderStatusChange}
                select
                SelectProps={{ native: true }}
                // value={orderStatusFilter}
                variant="outlined"
              >
                <option></option> */}
            {/* {orderStatusOptions.map(orderStatusOption => (
                  <option
                    key={orderStatusOption.id}
                    value={orderStatusOption.id}
                  >
                    {orderStatusOption.name}
                  </option>
                ))} */}
            {/* </TextField>
            </Grid> */}
            {/* <Grid item xs={6} sm={2}>
              <FormControlLabel
                className={classes.shippableField}
                control={
                  <Checkbox
                    // checked={inProgressFilter}
                    // onChange={handleInProgressChange}
                    name="New"
                  />
                }
                label="New"
              />
            </Grid> */}
            {/* <Grid item xs={6} sm={2}>
              <FormControlLabel
                className={classes.shippableField}
                control={
                  <Checkbox
                    // checked={fromCartFilter}
                    // onChange={handleFromCartChange}
                    name="OpenCarts"
                  />
                }
                label="Open Carts"
              />
            </Grid> */}
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearFilters}
                fullWidth
              >
                Clear
              </Button>
            </Grid>
          </Grid>
          {/* <form
            noValidate
            onSubmit={event => {
              event.preventDefault()
              handleSearch(event)
            }}
          >
            <TextField
              className={classes.queryField}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={event => setSearchBar(event.target.value)}
              // onChange={handleQueryChange}
              placeholder="Search Carts"
              // value={query}
              value={searchBar}
              variant="outlined"
            />
            <Button type="submit" variant="contained" color="primary">
              Search
            </Button>
          </form> */}
        </Box>
        <Box flexGrow={1} />
        <Divider />
        <CardHeader
          action={<GenericMoreButton />}
          title={`${resCount} Records found. Page ${page + 1} of ${resPages}`}
        />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={1150}>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAllCarts}
                      indeterminate={selectedSomeCarts}
                      onChange={handleSelectAllCarts}
                    />
                  </TableCell>
                  <TableCell>SBU + Started Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Last Update</TableCell>

                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carts.map(cart => {
                  const isCartSelected = selectedCarts.includes(cart.cartId)

                  const id = cart.id
                  //   const cartId = cart.id
                  const cartUid = cart.cartId
                  return (
                    <TableRow
                      key={id}
                      selected={selectedCarts.indexOf(id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isCartSelected}
                          onChange={event =>
                            handleSelectOneCart(event, cartUid)
                          }
                          value={isCartSelected}
                        />
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`/${userPermission}/carts/${cartUid}`}
                          state={{ id: `${cartUid}` }}
                          className={classes.link}
                        >
                          {cart.businessUnit &&
                            cart.businessUnit.name &&
                            cart.businessUnit.name}
                        </Link>

                        <Typography variant="body2" color="textSecondary">
                          {moment(cart.started).format("DD MMM YYYY | hh:mm")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {/* <Label color={paymentStatusColors[cart.payment.status]}>
                          {cart.payment.status}
                        </Label> */}
                        {cart.cartOpen && (
                          <CartIcon
                            fontSize="small"
                            style={{ color: "green", paddingLeft: "5px" }}
                          />
                        )}
                        {cart.cartExpired && (
                          <ExpiredIcon
                            fontSize="small"
                            style={{ color: "red", paddingLeft: "5px" }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {cart.person
                          ? cart.person.firstName + " " + cart.person.lastName
                          : "Visitor"}
                      </TableCell>
                      <TableCell>{cart.businessUnit.name}</TableCell>
                      <TableCell>
                        {moment(cart.latestChange).format(
                          "DD MMM YYYY | hh:mm"
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={event => {
                            navigate(
                              `/${userPermission}/carts/${cartUid}`,
                              {
                                state: { id: `${cartUid}` },
                              },
                              { replace: true }
                            )
                          }}
                        >
                          <SvgIcon fontSize="small">
                            <ArrowRightIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={resCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </Card>
      <BulkOperations
        open={enableBulkOperations}
        selected={selectedCarts}
        setSelectedCarts={setSelectedCarts}
      />
    </div>
  )
}

Results.propTypes = {
  className: PropTypes.string,
  carts: PropTypes.array,
}

Results.defaultProps = {
  carts: [],
}
const SBU_QUERY = gql`
  query {
    sbus {
      id
      name
    }
  }
`
export default Results
