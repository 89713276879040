import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { Box, Grid, makeStyles } from "@material-ui/core"
import PersonInfo from "./PersonInfo"
import UserAccount from "./UserAccount"
import PreRegAccount from "./PreRegAccount"
import CreatePreregAccount from "./CreatePreregAccount"
import NewPersonOrder from "./NewPersonOrder"
import NewPersonProposal from "./NewPersonProposal"
import RemovePerson from "./RemovePerson"
// import Emails from './Emails';
// import Invoices from './Invoices';
// import OtherActions from './OtherActions';

const useStyles = makeStyles(() => ({
  root: {},
}))

function Details({ person, className, ...rest }) {
  const classes = useStyles()
  const preregPerson = person.preregPerson && !person.user ? true : false
  const [businessUnits, setBusinessUnits] = useState([])
  const [taxes, setTaxes] = useState([])
  const { data, loading, error } = useQuery(FORM_INFO_QUERY, {
    fetchPolicy: "cache-first",
    onCompleted: data => {
      setBusinessUnits(data.sbus)
      setTaxes(data.taxes)
    },
  })
  const [isPrereg, setIsPrereg] = useState(preregPerson)
  const [prereg, setPrereg] = useState(person.preregPerson)

  useEffect(() => {
    console.log("prereg")
  }, [isPrereg])

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      alignItems="flex-start"
      spacing={3}
      {...rest}
    >
      <Grid item lg={4} md={6} xl={3} xs={12}>
        {/* PersonInfo will show for all people in the database */}
        <PersonInfo person={person} />
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <>
          {/* Show UserAccount if the person has an existing user account */}
          {person.user && (
            <UserAccount person={person} businessUnits={businessUnits} />
          )}
          {/* Show PreRegAccount if the person has a "prereg" account but not a "user" account */}
          {preregPerson && (
            <PreRegAccount person={person} businessUnits={businessUnits} />
          )}
          {/* Show CreatePreregAccount if the person does not have a "user" account or a "prereg" account*/}
          {!person.user && !isPrereg && (
            <CreatePreregAccount
              person={person}
              isPrereg={isPrereg}
              setIsPrereg={setIsPrereg}
              setPrereg={setPrereg}
              businessUnits={businessUnits}
            />
          )}
        </>
      </Grid>
      <Grid item container lg={4} md={6} xl={3} xs={12} direction="column">
        <Grid item xs={12}>
          <NewPersonOrder person={person} businessUnits={businessUnits} />
        </Grid>
        <Grid item xs={12}>
          <NewPersonProposal
            person={person}
            businessUnits={businessUnits}
            taxes={taxes}
          />
        </Grid>
      </Grid>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <RemovePerson person={person} />
      </Grid>
    </Grid>
  )
}

Details.propTypes = {
  className: PropTypes.string,
  person: PropTypes.object.isRequired,
}

const FORM_INFO_QUERY = gql`
  query {
    sbus {
      id
      name
    }
    taxes {
      id
      code
      name
    }
  }
`

export default Details
