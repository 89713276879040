import React, { useState, useEffect } from "react"

import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import useDidMountEffect from "../../../../../Global/hooks/useDidMountEffect"

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"

import ContactMailIcon from "@material-ui/icons/ContactMail"

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    paddingBottom: 40,
    paddingTop: 80,
  },
  card: {
    minWidth: 200,
    margin: 10,
  },
  selectedCard: {
    minWidth: 200,
    border: `4px solid ${theme.palette.divider}`,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  top: {
    display: "inline-block",
  },
  pos: {
    marginBottom: 12,
  },
  show: {},
  hide: {
    display: "none",
  },
}))

const regions = [
  {
    id: 1,
    name: "United States",
  },
  {
    id: 2,
    name: "Canada",
  },
  {
    id: 153,
    name: "Mexico",
  },
  {
    id: 17,
    name: "Australia",
  },
  {
    id: 32,
    name: "Brazil",
  },
  {
    id: 49,
    name: "China",
  },
  {
    id: 76,
    name: "France (Metro)",
  },

  {
    id: 95,
    name: "Hong Kong",
  },
  {
    id: 104,
    name: "India",
  },
  {
    id: 102,
    name: "Ireland",
  },
  {
    id: 103,
    name: "Israel",
  },
  {
    id: 109,
    name: "Italy",
  },
  {
    id: 112,
    name: "Japan",
  },

  {
    id: 174,
    name: "Pakistan",
  },
  {
    id: 170,
    name: "Peru",
  },
  {
    id: 175,
    name: "Poland",
  },
  {
    id: 186,
    name: "Russian Federation",
  },
  {
    id: 241,
    name: "Serbia",
  },
  {
    id: 194,
    name: "Singapore",
  },
  {
    id: 68,
    name: "Spain",
  },
  {
    id: 193,
    name: "Sweden",
  },
  {
    id: 222,
    name: "Taiwan",
  },
  {
    id: 212,
    name: "Thailand",
  },
  {
    id: 78,
    name: "United Kingdom",
  },
  {
    id: 224,
    name: "Ukraine",
  },
  {
    id: 235,
    name: "Vietnam",
  },
  {
    id: 3,
    name: "Other International Region (More...)",
  },
]

const usaStates = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
]

const canadaProvinces = [
  {
    name: "Alberta",
    abbreviation: "AB",
  },
  {
    name: "British Columbia",
    abbreviation: "BC",
  },
  {
    name: "Manitoba",
    abbreviation: "MB",
  },
  {
    name: "New Brunswick",
    abbreviation: "BN",
  },
  {
    name: "Newfoundland and Labrador",
    abbreviation: "NL",
  },
  {
    name: "Nova Scotia",
    abbreviation: "NS",
  },
  {
    name: "Ontario",
    abbreviation: "ON",
  },
  {
    name: "Prince Edward Island",
    abbreviation: "PE",
  },
  {
    name: "Quebec",
    abbreviation: "QC",
  },
  {
    name: "Saskatchewan",
    abbreviation: "SK",
  },
  {
    name: "Northwest Territories",
    abbreviation: "NT",
  },
  {
    name: "Nunavut",
    abbreviation: "NU",
  },
  {
    name: "Yukon",
    abbreviation: "YT",
  },
]

const UpdatePersonAddress = ({
  person,
  addresses,
  setAddresses,
  setUpdateAddress,
  className,
}) => {
  const classes = useStyles()
  const uid = person.uid

  const [personAddressIndex, setPersonAddressIndex] = useState(0)
  const [region, setRegion] = useState(1)
  const [regionId, setRegionId] = useState(1)
  const [allRegions, setAllRegions] = useState([])
  const [findMoreRegions, setFindMoreRegions] = useState(false)
  const [regionSetting, setRegionSetting] = useState("United States")
  const [category, setCategory] = useState(1)
  const [categoryId, setCategoryId] = useState(1)
  const [addressUid, setAddressUid] = useState("")
  const [addressCategories, setAddressCategories] = useState([])
  const [newArray, setNewArray] = useState([])
  const [addressDelUid, setAddressDelUid] = useState("")
  const [editing, setEditing] = useState(false)
  const [updateAddressUid, setUpdateAddressUid] = useState("")
  const [attention, setAttention] = useState("")
  const [addressLine1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")
  const [addressLine3, setAddressLine3] = useState("")
  const [city, setCity] = useState("")
  const [zone, setZone] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  const { data, loading, error } = useQuery(ADDRESS_FORM_INFO_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      setAddressCategories(data.addressCategories)
      setAllRegions(data.regions)
    },
  })

  const [deleteAddress] = useMutation(DELETE_ADDRESS_MUTATION, {
    variables: {
      uid: addressDelUid,
    },
    onCompleted: () => {
      setAddresses(newArray)
    },
  })

  const [addPersonAddress] = useMutation(ADD_PERSON_ADDRESS_MUTATION, {
    variables: {
      addressUid: addressUid,
      personUid: uid,
    },
    onCompleted: (data, response, errors) => {
      console.log("person address added")
      const addressValue = data.createAddressPerson.addressPerson
      setAddresses(prevSelected => [...prevSelected, addressValue])
      handleClear()
    },
  })

  const [handleNewAddress] = useMutation(ADD_ADDRESS_MUTATION, {
    variables: {
      regionId: region,
      categoryId: category,
      isOrg: false,
      attention: attention,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      addressLine3: addressLine3,
      city: city,
      zone: zone,
      postalCode: postalCode,
    },
    onCompleted: (data, response, errors) => {
      console.log(data)
      setSubmitting(false)
      setAddressUid(data.createAddress.address.uid)
    },
    onError: err => console.error(err),
  })

  const [handleUpdateAddress] = useMutation(UPDATE_ADDRESS_MUTATION, {
    variables: {
      uid: updateAddressUid,
      regionId: regionId,
      categoryId: categoryId,
      isOrg: false,
      attention: attention,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      addressLine3: addressLine3,
      city: city,
      zone: zone,
      postalCode: postalCode,
    },
    onCompleted: (data, response, errors) => {
      console.log(data)
      let addressData = data.updateAddress.address
      let newArray = [...addresses]
      newArray[personAddressIndex] = {
        ...newArray[personAddressIndex],
        address: addressData,
      }
      setAddresses(newArray)
      setSubmitting(false)
      setEditing(false)
      console.log("updated address")
      handleClear()
    },
    onError: err => console.error(err),
  })

  useDidMountEffect(() => {
    addressUid.length > 5 && !editing && addPersonAddress()
  }, [addressUid])

  useDidMountEffect(() => {
    addressDelUid.length > 5 && deleteAddress()
  }, [addressDelUid])

  const handleEdit = a => {
    let addressIndex = addresses.findIndex(x => x.id == a.id)
    setPersonAddressIndex(addressIndex)
    let address = a.address
    console.log(`editing ${address.uid}`)
    setUpdateAddressUid(address.uid)
    setAttention(address.attention)
    setAddressLine1(address.addressLine1)
    setAddressLine2(address.addressLine2)
    setAddressLine3(address.addressLine3)
    setCity(address.city)
    setZone(address.zone)
    setPostalCode(address.postalCode)
    setRegion(address.region.id)
    setCategory(address.category.id)

    setEditing(true)
  }

  const handleDelete = (address, event) => {
    const filteredArray = addresses.filter(x => x.address.uid !== address.uid)
    setNewArray(filteredArray)
    setAddressDelUid(address.uid)
    setConfirmDelete(false)
    console.log(address.uid)
  }

  const handleAddOrUpdateAddress = event => {
    editing ? handleUpdateAddress() : handleNewAddress()

    editing ? console.log("editing") : console.log("new address")

    // console.log(updateAddressUid)
    // console.log(region)
    // console.log(category)
    // console.log(attention)
    // console.log(addressLine1)
    // console.log(addressLine2)
    // console.log(addressLine3)
    // console.log(city)
    // console.log(zone)
    // console.log(postalCode)
  }

  const handleZoneChange = event => {
    event.persist()

    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
      console.log(value)
    }

    setZone(value)

    console.log(`region is ${zone}`)
  }

  const handleRegionChange = event => {
    event.persist()

    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
      console.log(value)
    }
    // if (value == 3) setFindMoreRegions(true)
    // if (value == 1) setRegionSetting("United States")
    // if (value == 2) setRegionSetting("Canada")
    // if (value !== 1 && value !== 2) setRegionSetting("other")
    setRegion(value)
    setRegionId(value)
    console.log(`region is ${region}`)
  }

  useEffect(() => {
    console.log(region)
    if (region === 3) setFindMoreRegions(true)
    region == 1
      ? setRegionSetting("United States")
      : region == 2
      ? setRegionSetting("Canada")
      : setRegionSetting("other")
  }, [region])

  const handleCategoryChange = event => {
    event.persist()

    let value = 0
    if (event.target.value !== 0) {
      value = event.target.value
    }

    setCategory(value)
    setCategoryId(value)
  }

  const handleClear = event => {
    setRegionId(1)
    setCategoryId(1)
    setRegion("")
    setCategory("")
    setUpdateAddressUid("")
    setAttention("")
    setAddressLine1("")
    setAddressLine2("")
    setAddressLine3("")
    setCity("")
    setZone("")
    setPostalCode("")
    setRegion("")
    setCategory("")
    setAddressUid("")
    setEditing(false)
  }

  const getZone = regionSetting => {
    switch (regionSetting) {
      case "United States":
        return (
          <TextField
            select
            label="State"
            id="zone"
            type="text"
            helperText="(required)"
            value={zone}
            onChange={handleZoneChange}
            SelectProps={{ native: true }}
          >
            <option></option>
            {usaStates.map((us, index) => (
              <option key={index} value={us.abbreviation}>
                {us.name}
              </option>
            ))}
          </TextField>
        )
      case "Canada":
        return (
          <TextField
            select
            label="Province"
            id="zone"
            type="text"
            helperText="(required)"
            value={zone}
            onChange={event => setZone(event.target.value)}
            SelectProps={{ native: true }}
          >
            <option></option>
            {canadaProvinces.map((ca, index) => (
              <option key={index} value={ca.abbreviation}>
                {ca.name}
              </option>
            ))}
          </TextField>
        )
      case "other":
        return (
          <TextField
            label="State, Province or Zone"
            id="zone"
            type="text"
            helperText="(required)"
            value={zone}
            onChange={event => setZone(event.target.value)}
            SelectProps={{ native: true }}
          />
        )
      default:
        return "unknown"
    }
  }

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <>
      <Box className={classes.top} mb={2}>
        <ContactMailIcon style={{ fontSize: 30, marginBottom: -6 }} />
        <Box ml={3} display="inline">
          <Typography display="inline" variant="h3">
            {editing ? "Edit and Update" : "Add an Address"}
          </Typography>
        </Box>
      </Box>
      <>
        <Grid container spacing={1}>
          {addresses.map(a => {
            const address = a.address
            return (
              <Grid item xs={12} sm={6} key={address.uid}>
                <Card
                  className={
                    address.uid === updateAddressUid
                      ? classes.selectedCard
                      : classes.card
                  }
                  variant="outlined"
                  key={address.uid}
                >
                  <CardContent key={address.uid}>
                    <Typography
                      variant="caption"
                      component="p"
                      key={address.uid}
                    >
                      {address.attention && address.attention}
                      {address.attention && <br />}
                      {address.addressLine1 && address.addressLine1}
                      {address.addressLine1 && <br />}
                      {address.addressLine2 && address.addressLine2}
                      {address.addressLine2 && <br />}
                      {address.addressLine3 && address.addressLine3}
                      {address.addressLine3 && <br />}
                      {address.city}, {address.zone} {address.postalCode} <br />
                      {address.region.name}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <>
                      <>
                        <Button size="small" onClick={e => handleEdit(a)}>
                          Edit
                        </Button>
                        <Button
                          size="small"
                          onClick={e => handleDelete(address)}
                        >
                          Delete
                        </Button>
                      </>
                    </>
                  </CardActions>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </>
      <Divider />
      <form
        onSubmit={event => {
          event.preventDefault()
          setSubmitting(true)

          handleAddOrUpdateAddress(event)
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Address Category"
              name="category"
              onChange={handleCategoryChange}
              select
              margin="dense"
              size="small"
              fullWidth
              SelectProps={{ native: true }}
              value={category}
            >
              <option></option>
              {addressCategories.map(ac => (
                <option key={ac.id} value={ac.id}>
                  {ac.name}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Country or Region"
              select
              className={findMoreRegions ? classes.hide : classes.show}
              value={region}
              onChange={handleRegionChange}
              SelectProps={{ native: true }}
            >
              <option></option>
              {regions.map(r => (
                <option key={r.id} value={r.id}>
                  {r.name}
                </option>
              ))}
            </TextField>
            <TextField
              fullWidth
              select
              label="More Regions"
              className={findMoreRegions ? classes.show : classes.hide}
              helperText="(required)"
              value={region}
              onChange={handleRegionChange}
              SelectProps={{ native: true }}
            >
              {allRegions.map(r => (
                <option key={r.id} value={r.id}>
                  {r.name}
                </option>
              ))}
            </TextField>
          </Grid>

          <>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label="Attention (optional)"
                  id="attention"
                  helperText="(not required)"
                  value={attention}
                  onChange={event => setAttention(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <FormControl required fullWidth>
                <TextField
                  label="Address"
                  id="addressLine1"
                  helperText="(required)"
                  value={addressLine1}
                  onChange={event => setAddressLine1(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label={
                    regionSetting === "USA"
                      ? "(Apt, Unit, Box...)"
                      : "Address Line 2"
                  }
                  id="addressLine2"
                  value={addressLine2}
                  onChange={event => setAddressLine2(event.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  className={
                    regionSetting === "USA"
                      ? classes.hide
                      : regionSetting === "Canada"
                      ? classes.hide
                      : classes.show
                  }
                  label="Address Line 3"
                  id="addressLine3"
                  value={addressLine3}
                  onChange={event => setAddressLine3(event.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl required fullWidth>
                <TextField
                  label="City"
                  id="city"
                  value={city}
                  fullWidth
                  onChange={event => setCity(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Divider />

            <Grid item xs={12} sm={5}>
              <FormControl fullWidth>{getZone(regionSetting)}</FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl>
                <TextField
                  label="Postal Code"
                  id="postalCode"
                  type="text"
                  helperText="(required)"
                  value={postalCode}
                  onChange={event => setPostalCode(event.target.value)}
                />
              </FormControl>
            </Grid>
          </>

          <Divider />

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting || !addressLine1.trim()}
              >
                {loading ? "Updating..." : editing ? "Update" : "Add New"}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <Button variant="outlined" color="primary" onClick={handleClear}>
                Clear
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setUpdateAddress(false)}
              >
                Cancel
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

const ADDRESS_FORM_INFO_QUERY = gql`
  query {
    addressCategories {
      id
      name
    }
    regions {
      id
      name
    }
  }
`
const ADD_ADDRESS_MUTATION = gql`
  mutation (
    $regionId: Int
    $categoryId: Int
    $isOrg: Boolean
    $attention: String
    $addressLine1: String
    $addressLine2: String
    $addressLine3: String
    $zone: String
    $city: String
    $postalCode: String
  ) {
    createAddress(
      regionId: $regionId
      categoryId: $categoryId
      isOrg: $isOrg
      attention: $attention
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressLine3: $addressLine3
      city: $city
      zone: $zone
      postalCode: $postalCode
    ) {
      address {
        uid
        isOrg
        region {
          id
          name
          label
          worldRegion
          globalRegion
        }
        category {
          id
          name
        }
        attention
        addressLine1
        addressLine2
        addressLine3
        zone
        city
        postalCode
      }
    }
  }
`
const UPDATE_ADDRESS_MUTATION = gql`
  mutation (
    $uid: String!
    $regionId: Int
    $categoryId: Int
    $isOrg: Boolean
    $attention: String
    $addressLine1: String
    $addressLine2: String
    $addressLine3: String
    $zone: String
    $city: String
    $postalCode: String
  ) {
    updateAddress(
      uid: $uid
      regionId: $regionId
      categoryId: $categoryId
      isOrg: $isOrg
      attention: $attention
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressLine3: $addressLine3
      city: $city
      zone: $zone
      postalCode: $postalCode
    ) {
      address {
        uid
        isOrg
        region {
          id
          name
          label
          worldRegion
          globalRegion
        }
        category {
          id
          name
        }
        attention
        addressLine1
        addressLine2
        addressLine3
        zone
        city
        postalCode
      }
    }
  }
`

const ADD_PERSON_ADDRESS_MUTATION = gql`
  mutation ($addressUid: String!, $personUid: String!) {
    createAddressPerson(addressUid: $addressUid, personUid: $personUid) {
      addressPerson {
        id
        address {
          uid
          isOrg
          region {
            id
            name
            label
            worldRegion
            globalRegion
          }
          category {
            id
            name
          }
          attention
          addressLine1
          addressLine2
          addressLine3
          zone
          city
          postalCode
        }
        person {
          uid
        }
      }
    }
  }
`

const DELETE_ADDRESS_MUTATION = gql`
  mutation ($uid: String!) {
    deleteAddress(uid: $uid) {
      uid
    }
  }
`
// AddAddressForm.propTypes = {
//   region: PropTypes.int,
// }
export default UpdatePersonAddress
