import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Typography, Breadcrumbs, makeStyles } from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

const useStyles = makeStyles(() => ({
  root: {},
}))

function Header({ className, ...rest }) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" to="/manager">
          Dashboard
        </Link>
        <Typography color="textPrimary">Account</Typography>
      </Breadcrumbs>
      <Typography variant="h3" color="textPrimary">
        Settings
      </Typography>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
