import React from "react"
import { Box, Container, Grid, makeStyles } from "@material-ui/core"
import Images from "./Images"
import ItemInfo from "./ItemInfo"
import PrivateItem from "./PrivateItem"
import ItemPricing from "./ItemPricing"
import ItemProductionInfo from "./ItemProductionInfo"
import CustomFields from "./CustomFields"
import ItemDelete from "./ItemDelete"
const useStyles = makeStyles(() => ({
  root: {
    minHeight: "100%",
    paddingTop: 2,
    paddingBottom: 2,
  },
}))

const Overview = ({ item }) => {
  const classes = useStyles()

  return (
    <>
      <Box mt={2}>
        <Grid container spacing={3}>
          <Grid item md={4} xl={4} xs={12}>
            {item.itemImage && item.itemImage.length > 0 && (
              <Box mb={3}>
                <Images item={item} />
              </Box>
            )}
            <ItemInfo item={item} />
          </Grid>

          <Grid item md={4} xl={4} xs={12}>
            <ItemPricing item={item} />
          </Grid>

          <Grid item md={4} xl={4} xs={12}>
            <Box mb={3}>
              {/* <ItemProductionInfo item={item} /> */}
              <CustomFields item={item} />
            </Box>

            <Box mb={3}>
              <ItemDelete item={item} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Overview
