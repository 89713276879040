import React, { useState, useEffect, useContext, useReducer } from "react"
import TasksContext from "./TasksContext"
import TasksReducer from "./TasksReducer"

const TasksProvider = props => {
  const initialState = useContext(TasksContext)
  const [localState, setLocalState] = useState("")
  const isBrowser = () => typeof window !== "undefined"
  useEffect(() => {
    isBrowser && setLocalState(JSON.parse(window.localStorage.getItem("tsk")))
  }, [])
  const [state, dispatch] = useReducer(TasksReducer, localState || initialState)
  useEffect(() => {
    isBrowser && window.localStorage.setItem("tsk", JSON.stringify(state))
  }, [state])

  return (
    <TasksContext.Provider value={{ state, dispatch }}>
      {props.children}
    </TasksContext.Provider>
  )
}

export default TasksProvider
