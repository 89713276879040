import React, { useState, useContext } from "react"
import {
  Alert,
  Box,
  Button,
  makeStyles,
  Grid,
  Snackbar,
  Typography,
} from "@material-ui/core"

import MuiAlert from "@material-ui/lab/Alert"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import OrgCreateContext from "../../context/OrgCreateContext"

const useStyles = makeStyles(() => ({
  root: {},
  queryField: {
    margin: "dense",
    size: "small",
  },
  text: {
    marginBottom: 20,
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: 10,
    },
  },
}))

const SearchPersonConfirm = (
  {
    // ouid,
    // selectedPerson,
    // setSelectedPerson,
    // confirmPerson,
    // setConfirmPerson,
    // orgPeople,
    // setOrgPeople,
  }
) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(OrgCreateContext)
  const selectedPerson = state.selectedPerson
  const org = state.org
  const ouid = state.ouid
  const orgPeople = state.orgPeople
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const personUid = selectedPerson.uid
  const firstName = selectedPerson.firstName ? selectedPerson.firstName : ""
  const lastName = selectedPerson.lastName ? selectedPerson.lastName : ""
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setSnackBarOpen(false)
  }

  const deSelectPerson = () => {
    dispatch({ type: "SET_SELECTED_PERSON", payload: "" })
    dispatch({ type: "TOGGLE_CONFIRM_PERSON" })
    // setSelectedPerson("")

    // setConfirmPerson(false)
  }
  const addOrgPerson = () => {
    setIsSubmitting(true)
    let newGroup = [...orgPeople, selectedPerson]
    dispatch({ type: "SET_ORG_PEOPLE", payload: newGroup })
    addPerson()
  }
  const [addPerson] = useMutation(ADD_ORG_PERSON_MUTATION, {
    variables: {
      ouid: ouid,
      personUid: personUid,
    },
    onCompleted: data => {
      setIsSubmitting(false)
      dispatch({ type: "SET_SELECTED_PERSON", payload: "" })
      dispatch({ type: "TOGGLE_CONFIRM_PERSON" })
      dispatch({ type: "SET_PEOPLE_VIEW", payload: "listView" })
    },
  })
  return (
    <>
      <Grid className={classes.text}>
        <Typography gutterBottom variant="h5" color="textPrimary">
          You're about to add {firstName} {lastName} to{" "}
          {org.name ? org.name : "this organization"}.
        </Typography>
        <Typography gutterBottom variant="h5" color="textPrimary">
          Are you sure?
        </Typography>
      </Grid>
      <Grid container justify="space-between">
        <Grid item>
          <Button
            onClick={deSelectPerson}
            color="secondary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
          >
            Back to List
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={addOrgPerson}
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
          >
            {/* <Button onClick={confirmPerson} color="primary" variant="contained" type="submit"> */}
            Add {firstName} {lastName}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

const ADD_ORG_PERSON_MUTATION = gql`
  mutation ($ouid: String!, $personUid: String!) {
    addOrgPerson(ouid: $ouid, personUid: $personUid) {
      org {
        people {
          uid
          firstName
          lastName
        }
      }
    }
  }
`
export default SearchPersonConfirm
