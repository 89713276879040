import React, { useContext } from "react"
import PropTypes from "prop-types"

import MessagesContext from "../context/MessagesContext"

import {
  Drawer,
  Hidden,
  Box,
  List,
  Button,
  Divider,
  makeStyles,
} from "@material-ui/core"

import LabelItem from "./LabelItem"

const labels = [
  {
    id: "conversations",
    name: "All Chats",
    type: "system_label",
  },
  {
    id: "starred",
    name: "Starred",
    type: "system_label",
  },
  {
    id: "following",
    name: "Following",
    type: "system_label",
  },
  {
    id: "invites",
    name: "Invites",
    type: "system_label",
  },
  {
    id: "updates",
    name: "New Messages",
    type: "system_label",
  },
  {
    id: "archived",
    name: "Archived & Closed",
    type: "system_label",
  },
]
const useStyles = makeStyles(() => ({
  drawerDesktopRoot: {
    width: 280,
    flexShrink: 0,
  },
  drawerDesktopPaper: {
    position: "relative",
  },
  drawerMobilePaper: {
    position: "relative",
    width: 280,
  },
  drawerMobileBackdrop: {
    position: "absolute",
  },
}))

function Sidebar() {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)

  const sidebarOpen = state.sidebarOpen

  const handleCloseSidebar = () => {
    dispatch({ type: "CLOSE_SIDEBAR" })
  }

  // const handleClick = () => {
  //   dispatch({ type: "SET_PANEL", payload: "conversationNew" })
  //   loadData()
  // }
  //   const handleComposeClick = () => {
  //     dispatch(openNewMessage())
  //   }

  //   useEffect(() => {
  //     if (sidebarOpen) {
  //       dispatch(closeSidebar())
  //     }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [location.pathname])

  const content = (
    <div>
      <Hidden smUp>
        <Box mt={8} />
      </Hidden>
      <Box px={3} py={2}>
        <Button
          color="secondary"
          fullWidth
          variant="contained"
          onClick={() =>
            dispatch({ type: "SET_PANEL", payload: "conversationNew" })
          }
        >
          New Chat
        </Button>
      </Box>
      <Divider />
      {/* <PerfectScrollbar options={{ suppressScrollX: true }}> */}
      <Box py={2} pr={2}>
        <List>
          {labels.map(label => {
            return <LabelItem key={label.id} label={label} />
          })}
        </List>
      </Box>
      {/* </PerfectScrollbar> */}
    </div>
  )

  return (
    <>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          classes={{
            root: classes.drawerDesktopRoot,
            paper: classes.drawerDesktopPaper,
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={sidebarOpen}
          onClose={handleCloseSidebar}
          classes={{
            paper: classes.drawerMobilePaper,
          }}
          style={{ position: "absolute", zIndex: 1200 }}
          BackdropProps={{ classes: { root: classes.drawerMobileBackdrop } }}
          //   ModalProps={{ container: () => containerRef.current }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

Sidebar.propTypes = {
  containerRef: PropTypes.any.isRequired,
}

export default Sidebar
