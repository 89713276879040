export default function reducer(state, action) {
  switch (action.type) {
    case "SET_ILLINOIS":
      return {
        ...state,
        illinois: action.payload,
      }
    case "SET_VIRGINIA":
      return {
        ...state,
        virginia: action.payload,
      }
    case "SET_WISCONSIN":
      return {
        ...state,
        wisconsin: action.payload,
      }
    case "SET_MISSOURI":
      return {
        ...state,
        missouri: action.payload,
      }
    case "SET_INITIAL":
      return {
        ...state,
        initial: action.payload,
      }
    case "SET_ALERT_COUNT":
      return {
        ...state,
        alertCount: action.payload,
      }
    case "SET_HOME_PAGE_CARDS":
      return {
        ...state,
        homePageCards: action.payload,
      }
    case "TOGGLE_HOME_PAGE_HEADER":
      return {
        ...state,
        expandedHome: !state.expandedHome,
      }
    default:
      return state
  }
}
