import React, { useState } from "react"
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Tabs,
  Tab,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { useParams } from "@reach/router"
import Page from "../../../Global/Page"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import Header from "./Header"
import Projects from "./Projects"
import Overview from "./Overview"
import Strategy from "./Strategy"

const PlanDetailsView = ({ classes }) => {
  const params = useParams()
  const planId = parseInt(params.id)
  const [title, setTitle] = useState("")
  const [currentTab, setCurrentTab] = useState("overview")
  const { data, loading, error } = useQuery(PLAN_DETAILS_QUERY, {
    variables: {
      id: planId,
    },
    onCompleted: data => {
      setTitle(data.plan.title)
    },
  })
  const tabs = [
    { value: "overview", label: "Overview" },
    { value: "strategy", label: "Strategy" },
    { value: "projects", label: "Projects" },
  ]
  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }
  if (loading) return <Loading />
  if (error) return <Error />
  const plan = data.plan
  if (!plan)
    return (
      <Card>
        <CardContent>
          <Typography>Could not find a plan by that ID</Typography>
        </CardContent>
      </Card>
    )
  return (
    <Page className={classes.root} title={`Plan ${planId}`}>
      <Container maxWidth="lg">
        <Header id={planId} title={title} />
        {JSON.stringify(plan)}
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="secondary"
            value={currentTab}
            variant="scrollable"
          >
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === "overview" && <Overview plan={plan} />}
          {currentTab === "strategy" && <Strategy plan={plan} />}
          {currentTab === "projects" && <Projects plan={plan} />}
        </Box>
      </Container>
    </Page>
  )
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "91vh",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
})
const PLAN_DETAILS_QUERY = gql`
  query ($id: Int!) {
    plan(id: $id) {
      id
      isActive
      title
      startDate
      createdBy {
        id
        person {
          firstName
          lastName
        }
      }
      objective
      strategy
      category {
        id
        name
      }
      tags {
        id
        name
      }
      projectPlan {
        id
        dependsOn {
          id
          title
        }
        project {
          id
          title
          startDate
          dueDate
          completed
          completedDate
          priority
        }
      }
    }
  }
`
export default withStyles(styles)(PlanDetailsView)
