import React, { useState } from "react"
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  makeStyles,
} from "@material-ui/core"
import Page from "../../Global/Page"
import Header from "./Header"
import General from "./General"
// import Subscription from './Subscription';
// import Notifications from './Notifications';
// import Security from './Security';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const Profile = () => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState("general")
  const tabs = [
    { value: "general", label: "General" },
    // { value: "subscription", label: "Subscription" },
    { value: "preferences", label: "Preferences" },
    { value: "password", label: "Change Password" },
  ]

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  return (
    <Page className={classes.root} title="Profile">
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {/* {currentTab === "general" && "General"} */}
          {/* {currentTab === "subscription" && "Subscription"} */}
          {currentTab === "preferences" && "Preferences"}
          {currentTab === "password" && "Change Password"}
          {currentTab === "general" && <General />}
          {/* {currentTab === 'subscription' && <Subscription />}
          {currentTab === 'notifications' && <Notifications />}
          {currentTab === 'security' && <Security />} */}
        </Box>
      </Container>
    </Page>
  )
}

export default Profile
