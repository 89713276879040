import React, { useState } from "react"
import { Grid, Typography } from "@material-ui/core"
import ImageFileCard from "./ImageFileCard"

const NoImages = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12} xl={12} xs={12}>
          <Typography variant="h3">No image files found</Typography>
        </Grid>
      </Grid>
    </>
  )
}
const ImageFiles = ({ item, files, setFiles }) => {
  // const [itemImageFiles, setItemImageFiles] = useState(files)
  if (!files || files === 0) return <NoImages />
  // if (!item.itemImage || item.itemImage.length === 0) return <NoImages />
  return (
    <>
      <Grid container spacing={3}>
        {files.map(currentImage => (
          <Grid item xs={12} sm={4} key={currentImage.id}>
            <ImageFileCard
              item={item}
              currentImage={currentImage}
              files={files}
              setFiles={setFiles}
              // itemImageFiles={itemImageFiles}
              // setItemImageFiles={setItemImageFiles}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default ImageFiles
