import React, { useState } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import { X as CancelIcon } from "react-feather"
import PerfectScrollbar from "react-perfect-scrollbar"
import Page from "../../../Global/Page"

import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

import DocsCategorySelect from "./DocsCategorySelect"
import DocsTags from "./DocsTags"
import Header from "./Header"
import MDEditor from "@uiw/react-md-editor"
const DocsPublishView = ({ classes }) => {
  const [title, setTitle] = useState("")
  const [body, setBody] = useState("")
  const [category, setCategory] = useState("")
  const [categoryId, setCategoryId] = useState(1)
  const [tagIds, setTagIds] = useState([1])
  const [tags, setTags] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const handleSubmitPost = event => {
    setSubmitting(true)
    createDocsPost()
  }
  const { data, loading, error } = useQuery(DOCS_FORM_QUERY)

  const [createDocsPost] = useMutation(CREATE_DOCS_POST, {
    variables: {
      title: title,
      body: body,
      categoryId: categoryId,
      tagIds: tagIds,
    },
    onCompleted: data => {
      console.log(data)
      setSubmitting(false)
      setTitle("")
      setBody("")
    },
  })
  const handleRemoveTag = (tagId, event) => {
    const filteredArray = tagIds.filter(t => t !== tagId)
    setTagIds(filteredArray)
  }
  if (loading) return <Loading />
  if (error) return <Error />
  const allTags = data.docsTags
  const categoryOptions = data.docsCategories
  return (
    <Page className={classes.root} title="Docs">
      <PerfectScrollbar>
        <Container maxWidth={false}>
          <Header />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <form
                    onSubmit={event => {
                      event.preventDefault()
                      handleSubmitPost(event)
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography gutterBottom variant="h5" component="h2">
                          Add or Edit Docs
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          Add or edit posts for the staff documentation area
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label="Title"
                          style={{ margin: 8 }}
                          placeholder="Title"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          value={title}
                          onChange={event => setTitle(event.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <DocsCategorySelect
                          categoryOptions={categoryOptions}
                          category={category}
                          setCategory={setCategory}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DocsTags
                          allTags={allTags}
                          tagIds={tagIds}
                          setTagIds={setTagIds}
                          setTags={setTags}
                        />
                      </Grid>

                      {/* <BodyEditor body={body} setBody={setBody} /> */}
                      <Grid item xs={12}>
                        <Paper
                          elevation={0}
                          component="ul"
                          className={classes.chipList}
                        >
                          {tagIds &&
                            tagIds.length > 0 &&
                            tagIds.map(tagId => {
                              const t = allTags.find(
                                element => element.id === tagId
                              )
                              return (
                                <li key={tagId}>
                                  <Chip
                                    className={classes.tagChip}
                                    size="small"
                                    label={t ? t.name : ""}
                                    clickable
                                    color="primary"
                                    variant="outlined"
                                    onDelete={e => handleRemoveTag(tagId)}
                                    deleteIcon={<CancelIcon />}
                                  />
                                </li>
                              )
                            })}
                        </Paper>
                      </Grid>

                      <Grid item xs={12}>
                        <MDEditor value={body} onChange={setBody} />
                        {/* {view === "read" && <MDEditor.Markdown source={value} />} */}
                      </Grid>

                      {/* {body && body.length > 0 && (
                        <Grid item xs={12}>
                          <Typography>Preview:</Typography>
                          <Paper className={classes.previewPaper}>
                            <ReactMarkdown className={classes.preview}>
                              {body}
                            </ReactMarkdown>
                          </Paper>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TextField
                          label="Body"
                          style={{ margin: 8 }}
                          placeholder="Body"
                          fullWidth
                          margin="normal"
                          multiline
                          rows={8}
                          rowsMax={120}
                          InputLabelProps={{
                            shrink: true,
                            rows: 10,
                          }}
                          variant="outlined"
                          value={body}
                          onChange={event => setBody(event.target.value)}
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={submitting}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>{" "}
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </PerfectScrollbar>
    </Page>
  )
}

const CREATE_DOCS_POST = gql`
  mutation ($title: String, $body: String, $categoryId: Int, $tagIds: [Int]) {
    createPost(
      title: $title
      body: $body
      categoryId: $categoryId
      tagIds: $tagIds
    ) {
      post {
        id
        title
        body
        tags {
          id
          name
        }
        category {
          id
          name
        }
        dateAdded
        dateModified
        author {
          id
          person {
            uid
            firstName
            lastName
          }
        }
      }
    }
  }
`
const DOCS_FORM_QUERY = gql`
  query {
    docsTags {
      id
      name
    }
    docsCategories {
      id
      name
    }
  }
`

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  preview: {
    fontFamily: theme.typography.fontFamily,
  },
  previewPaper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  tags: {
    fontSize: "19px",
    display: "inline",
  },
  chipList: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  tagChip: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
})
export default withStyles(styles)(DocsPublishView)
