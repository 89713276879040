import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
import useDidMountEffect from "../../../../Global/hooks/useDidMountEffect"

import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  TextField,
  Divider,
  Grid,
  Typography,
  FormControl,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center",
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    minHeight: "100%",
    flexDirection: "column",
    paddingBottom: 40,
    paddingTop: 80,
  },
  box: {
    minWidth: "250px",
  },
  show: {},
  hide: {
    display: "none",
  },
  formButton: {
    marginTop: theme.spacing(2),
  },
}))

const NextStep = ({ item }) => {
  const classes = useStyles()
  const [itemInfo, setItemInfo] = useState("")
  const [isPreregItem, setIsPreregItem] = useState(false)
  const { data, loading, error } = useQuery(ITEM_INFO_QUERY, {
    variables: {
      id: item.id,
    },
    onCompleted: data => {
      setItemInfo(data.item)

      setIsPreregItem(
        data.item.privateItem && data.item.privateItem.isPrereg ? true : false
      )
    },
  })

  // const [createProposal]=useMutation(CREATE_NEW_PROPOSAL_MUTATION,{
  //   variables:{
  //     sbuId: itemInfo.businessUnit.id,
  //     likelihood: likelihood,
  //     isActive: true,
  //     isPrereg: isPrereg,
  //     expiresAt: oneMonth,
  //     notes: notes,
  //     preregId: preregId,
  //     userId: userId,
  //     fromInquiry: false,
  //   },
  // })
  if (loading) return "loading..."
  if (error) return "error..."

  return (
    <div className={classes.box}>
      {/* {itemInfo.isPrivate && itemInfo.privateItem.itemOwner && itemInfo.privateItem.itemOwner.uid.length > 5 && 
      <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"

          >
            Start a New Proposal
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            component={Link}
            to="/manager/proposals/new"
          >
            Add to an Existing Proposal
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            component={Link}
            to="/manager/proposals/new"
          >
            Start a new Order
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            component={Link}
            to="/manager/proposals/new"
          >
            Add to an existing order
          </Button>
        </Grid>
</Grid></Box>} */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mt={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              component={Link}
              to="/manager/proposals/new"
            >
              Go to Proposals
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2} mb={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              component={Link}
              to="/manager/orders/new"
            >
              Go to Orders
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

const ITEM_INFO_QUERY = gql`
  query ($id: Int!) {
    item(id: $id) {
      id
      name
      isActive
      isPrivate
      category {
        id
        name
      }
      businessUnit {
        id
        name
      }
      dateAdded
      dateModified
      privateItem {
        isPrereg
        itemOwner {
          uid
          firstName
          lastName
        }
      }
    }
  }
`

// const CREATE_NEW_PROPOSAL_MUTATION = gql`
//   mutation(
//     $sbuId: Int
//     $likelihood: Int
//     $isActive: Boolean
//     $isPrereg: Boolean
//     $expiresAt: DateTime
//     $notes: String
//     $preregId: String
//     $userId: Int
//     $fromInquiry: Boolean
//   ) {
//     createQuote(
//       sbuId: $sbuId
//       likelihood: $likelihood
//       isActive: $isActive
//       isPrereg: $isPrereg
//       expiresAt: $expiresAt
//       notes: $notes
//       preregId: $preregId
//       userId: $userId
//       fromInquiry: $fromInquiry
//     ) {
//       quote {
//         id
//         createdBy {
//           id
//         }
//         createdAt
//         businessUnit {
//           id
//         }
//         isPrereg
//         userQuoteUser {
//           user {
//             id
//           }
//         }
//         preRegQuoteUser {
//           preregUser {
//             preregId
//           }
//         }
//       }
//     }
//   }
// `
export default NextStep
