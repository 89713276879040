import React, { useState, Component, Fragment } from "react"
import { useMutation, useQuery } from "@apollo/client"
import gql from "graphql-tag"
import clsx from "clsx"
import PropTypes from "prop-types"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import EditIcon from "@material-ui/icons/Edit"
import DoneIcon from "@material-ui/icons/Done"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"

import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  TextField,
  FormControl,
  Typography,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {},
  button: {
    margin: 10,
  },
  margin: {
    margin: 2,
  },
}))

const AddEmailForm = ({
  uid,
  inquiry,
  className,
  activeStep,
  setActiveStep,
  ...rest
}) => {
  const classes = useStyles()
  const inquiryId = inquiry.id
  const emails = inquiry.emailInquiry
  const fName = inquiry.inquiryPerson.firstName

  // const xEmail = emailPerson.length
  const [emailAddress, setEmailAddress] = useState("")
  const [emailAccount, setEmailAccount] = useState("")
  const [edit, setEdit] = useState(false)
  const [selEmail, setSelEmail] = useState([])
  const [confirm, setConfirm] = useState(false)

  const [addPersonEmail] = useMutation(ADD_PERSON_EMAIL_MUTATION, {
    onCompleted: (response, errors) => {
      console.log("completed")
    },
  })

  const { loading, error, data } = useQuery(INQUIRY_EMAIL_QUERY, {
    variables: { inquiryId: inquiryId },
    fetchPolicy: "no-cache",
    // onCompleted: data => {
    //   console.log(data)
    // },
  })

  if (loading) return "loading..."
  // if (error) return <Error />
  if (error) return "error"
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }

  const emailAccounts = data.thisInquiryEmailAccounts

  const handleSubmit = (event, addPersonEmail) => {
    event.preventDefault()
    addPersonEmail()
  }

  function emailAdded(emailVal) {
    return selEmail.some(function (el) {
      return el.emailVal === emailVal
    })
  }

  const handleFormEmail = event => {
    addPersonEmail({ variables: { uid: uid, emailAddress: emailAccount } })
    setConfirm(true)
  }

  const handleAddEmail = emailVal => {
    const email = Object.values(emailVal).toString()
    addPersonEmail({ variables: { uid: uid, emailAddress: email } })
    setSelEmail(prevSelected => [...prevSelected, emailVal])
  }
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }
  return (
    <>
      <Box>
        {Object.entries(emails).length === 0 ? (
          <Box>
            <Typography>
              Do you know an email address for {fName}? If not, skip.
            </Typography>
            {confirm ? (
              <Box>
                <Chip
                  icon={<DoneIcon />}
                  label={emailAccount}
                  variant="outlined"
                />
              </Box>
            ) : (
              <form
                onSubmit={event => {
                  event.preventDefault()
                  handleFormEmail(event)
                }}
                className={clsx(classes.root, className)}
                {...rest}
              >
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <TextField
                      size="small"
                      // error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      //helperText={touched.firstName && errors.firstName}
                      label="Email"
                      margin="normal"
                      name="emailAccount"
                      // onBlur={handleBlur}
                      onChange={event => setEmailAccount(event.target.value)}
                      type="email"
                      value={emailAccount}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      size="medium"
                      type="submit"
                      aria-label="add email"
                      className={classes.margin}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Box>
        ) : (
          <Box>
            <Typography>Confirm {fName}'s email.</Typography>
            {emails.map(e => {
              const emailVal = e.emailAccount
              return (
                <Fragment key={e.id}>
                  <Box key={e.id} mt={2} mb={2}>
                    {emailAdded(emailVal) ? (
                      <Box>
                        <Chip
                          icon={<DoneIcon />}
                          label={`${e.emailAccount}`}
                          variant="outlined"
                        />
                      </Box>
                    ) : (
                      <Box>
                        <Chip
                          color="primary"
                          value={emailVal}
                          icon={<MailOutlineIcon />}
                          label={`${e.emailAccount}`}
                          onClick={e => handleAddEmail({ emailVal })}
                        />
                      </Box>
                    )}
                  </Box>
                </Fragment>
              )
            })}
            {edit ? (
              ""
            ) : (
              <Box>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={() => setEdit(true)}
                >
                  Add a new email
                </Button>
              </Box>
            )}
          </Box>
        )}

        <Box>
          {edit ? (
            <Box>
              {confirm ? (
                <Box>
                  <Chip
                    icon={<DoneIcon />}
                    label={emailAccount}
                    variant="outlined"
                  />
                </Box>
              ) : (
                <form
                  onSubmit={event => {
                    event.preventDefault()
                    handleFormEmail(event)
                  }}
                  className={clsx(classes.root, className)}
                  {...rest}
                >
                  <Grid container spacing={1} alignItems="flex-end">
                    <Grid item>
                      <FormControl>
                        <TextField
                          size="small"
                          fullWidth
                          label="Email"
                          margin="normal"
                          name="emailAccount"
                          onChange={event =>
                            setEmailAccount(event.target.value)
                          }
                          type="email"
                          value={emailAccount}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <IconButton
                        size="medium"
                        type="submit"
                        aria-label="add email"
                        variant="contained"
                        className={classes.margin}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                      {/* <Button size="small" type="submit">
                      Add email
                    </Button> */}
                    </Grid>
                  </Grid>
                </form>
              )}
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
      <Box mt={2}>
        <Button
          color="primary"
          fullWidth
          size="large"
          variant="contained"
          onClick={handleNext}
        >
          Next: Phone Number
        </Button>
      </Box>
    </>
  )
}

const INQUIRY_EMAIL_QUERY = gql`
  query ($inquiryId: Int) {
    thisInquiryEmailAccounts(inquiryId: $inquiryId) {
      id
      emailAccount
    }
  }
`

const ADD_PERSON_EMAIL_MUTATION = gql`
  mutation ($emailAddress: String!, $uid: String!) {
    createPersonEmailAccount(emailAddress: $emailAddress, uid: $uid) {
      emailAccount {
        id
        emailAddress
        person {
          uid
        }
      }
      person {
        uid
        firstName
        lastName
        phonePerson {
          id
          phoneType {
            id
            phoneType
          }
          phoneNumber
        }
        emailPerson {
          id
          emailAddress
        }
      }
    }
  }
`
AddEmailForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
}

AddEmailForm.default = {
  onSubmitSuccess: () => {},
}

export default AddEmailForm
