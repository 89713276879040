import React, { useState, useCallback } from "react"
import Cropper from "react-easy-crop"
import Slider from "@material-ui/core/Slider"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import ImageSearchIcon from "@material-ui/icons/ImageSearch"
import { withStyles } from "@material-ui/core/styles"
import { getOrientation } from "get-orientation/browser"
import ImgDialog from "./ImgDialog"
import { getCroppedImg, getRotatedImage } from "./canvasUtils"
import { styles } from "./styles"

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
}

const ImageCropper = ({
  classes,
  setSelect,
  croppedImage,
  setCroppedImage,
  setImageFile,
  setFileField,
}) => {
  const [imageSrc, setImageSrc] = React.useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const [fileError, setFileError] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [constructedImage, setConstructedImage] = useState("")
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      console.log("done", { croppedImage })
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])

  const makeCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      console.log("done", { croppedImage })
      setImageFile(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])

  const onClose = useCallback(() => {
    setCroppedImage(null)
  }, [])

  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      console.log(file)
      let imageDataUrl = await readFile(file)

      // apply rotation if needed
      const orientation = await getOrientation(file)
      const fileSizeLimit = 10000000 // 10MB
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }
      if (file && file.size > fileSizeLimit) {
        setFileError(`${file.name}: File size too large`)
      } else {
        setImageSrc(imageDataUrl)
        setFileError("")
      }
    }
  }

  return (
    <div>
      {imageSrc ? (
        <React.Fragment>
          <div className={classes.cropContainer}>
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              restrictPosition={false}
            />
          </div>
          <div className={classes.controls}>
            <Grid
              container
              spacing={3}
              justify="space-evenly"
              alignItems="center"
            >
              {/* <div className={classes.sliderContainer}> */}
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Zoom
                </Typography>
                <Slider
                  value={zoom}
                  min={0.6}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  classes={{ container: classes.slider }}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </Grid>
              {/* </div> */}
              {/* <div className={classes.sliderContainer}> */}
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Rotation
                </Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  classes={{ container: classes.slider }}
                  onChange={(e, rotation) => setRotation(rotation)}
                />
              </Grid>
              {/* </div> */}
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  onClick={showCroppedImage}
                  variant="contained"
                  color="primary"
                  // classes={{ root: classes.cropButton }}
                >
                  Preview
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  onClick={makeCroppedImage}
                  // onClick={makeImage}
                  variant="contained"
                  color="primary"
                  // classes={{ root: classes.cropButton }}
                >
                  Select
                </Button>
              </Grid>

              <img src={imageSrc} alt="image src" height={300} />
            </Grid>
          </div>
          <ImgDialog img={croppedImage} onClose={onClose} />
        </React.Fragment>
      ) : (
        // <input type="file" onChange={onFileChange} accept="image/*" />
        <>
          <FormControl error={Boolean(fileError)}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              // multiple
              type="file"
              required
              onChange={onFileChange}
            />

            <label htmlFor="contained-button-file">
              <Button
                component="span"
                variant="contained"
                color="default"
                className={classes.button}
                startIcon={<ImageSearchIcon />}
              >
                Select an Image File
              </Button>
              {/* {file && file.name} */}
              <FormHelperText>{fileError}</FormHelperText>
            </label>

            <Button
              disabled={submitting}
              onClick={() => {
                setFileError("")
                setImageSrc(null)
              }}
              className={classes.cancel}
            >
              Cancel
            </Button>
          </FormControl>
        </>
      )}
    </div>
  )
}

function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener("load", () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export default withStyles(styles)(ImageCropper)
