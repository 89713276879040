import React, { useState } from "react"
import { Box, Container, Grid } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { useParams } from "@reach/router"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
import Page from "../../../Global/Page"
import Header from "./Header"
import ProposalInfo from "./ProposalInfo"
import QuoteItems from "./QuoteItems"
import AddQuoteItem from "./AddQuoteItem"
import PreviewQuote from "./PreviewQuote"
import QuoteMessage from "./QuoteMessage"
// import QuoteFiles from "./QuoteFiles"

const ProposalDetailsView = ({ classes, id }) => {
  const [search, setSearch] = useState(false)
  const [createNew, setCreateNew] = useState(false)
  const [items, setItems] = useState([])
  const { data, loading, error } = useQuery(PROPOSAL_DETAILS_QUERY, {
    variables: {
      id: id,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const quote = data.quote

  return (
    <Page className={classes.root} title="Proposal Details">
      <Container maxWidth={false}>
        <Header quote={quote} />

        <Box mt={2}>
          <Grid container spacing={3}>
            <Grid item md={4} xl={3} xs={12}>
              <ProposalInfo quote={quote} />
            </Grid>

            <Grid item md={8} xl={9} xs={12}>
              <>
                <QuoteItems
                  quote={quote}
                  id={id}
                  items={items}
                  setItems={setItems}
                  search={search}
                  setSearch={setSearch}
                  createNew={createNew}
                  setCreateNew={setCreateNew}
                />
                <Box mt={3}>
                  <QuoteMessage quote={quote} />
                </Box>
              </>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
})

const PROPOSAL_DETAILS_QUERY = gql`
  query ($id: Int!) {
    quote(id: $id) {
      id
      createdBy {
        id
        staff
        person {
          firstName
          lastName
        }
      }
      createdAt
      sent
      sentAt
      modifiedAt
      expiresAt
      isActive
      likelihood
      notes
      businessUnit {
        id
        name
        primaryEmail
      }
      isPrereg
      preRegQuoteUser {
        preregUser {
          preregId
          preregEmail
          registered
          person {
            uid
            firstName
            lastName
          }
        }
      }
      userQuoteUser {
        id
        user {
          id
          email
          person {
            uid
            firstName
            lastName
            emailPerson {
              id
              emailAddress
            }
            phonePerson {
              id
              phoneType {
                id
                phoneType
              }
              phoneNumber
            }
          }
        }
      }
      quoteForItems {
        id
        quoteItemImages {
          id
          title
          thumbnailUrl
          image
        }
        quoteItemSampleProofs {
          id
          version
          thumbnailUrl
          image
        }
        item {
          name
          category {
            id
            name
            description
          }
          businessUnit {
            id
            name
            homepage
            primaryEmail
          }
          specItem {
            specId
            summary
          }
          dateAdded
          dateModified
          isActive
          isPrivate
          pricingModel {
            id
            name
            description
          }
          itemPrice {
            id
            name
            minQuantity
            maxQuantity
            price
            isActive
          }
          itemBasePrice {
            id
            basePrice
          }
        }
      }
    }
  }
`

export default withStyles(styles)(ProposalDetailsView)
