import React, { useState, useEffect } from "react"
import clsx from "clsx"
import Error from "../../../Utils/Error"
import Loading from "../../../Utils/Loading"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import {
  TextField,
  Button,
  FormControl,
  Typography,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  input: {
    display: "none",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {},

  image: {
    width: 200,
  },
  inquiryImg: {
    maxWidth: 300,
  },
}))

const UploadRegions = (className, ...rest) => {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)
  const [file, setFile] = useState("")
  const [fileError, setFileError] = useState("")
  const [success, setSuccess] = useState(false)

  const [uploadFile, { data, loading, error }] = useMutation(
    REGIONS_FILE_UPLOAD_MUTATION,
    {
      variables: {
        addressFile: file,
      },
      onCompleted: data => {
        console.log(data)
        setFile("")
        setSuccess(true)
        setSubmitting(false)
      },
      //   onError: err => console.error(err),
      // update={handleUpdateCache}
    }
  )
  const handleSubmit = (event, uploadFile) => {
    event.preventDefault()
    uploadFile()
  }
  const handleFileChange = event => {
    const selectedFile = event.target.files[0]
    const fileSizeLimit = 10000000 // 10mb
    if (selectedFile && selectedFile.size > fileSizeLimit) {
      setFileError(`${selectedFile.name}: File size too large`)
    } else {
      setFile(selectedFile)
      setFileError("")
    }
  }
  if (loading) return <Loading />
  if (error) return <Error />
  return (
    <div>
      <Typography variant="h1">Upload a list of Regions</Typography>{" "}
      <form
        onSubmit={event => handleSubmit(event, uploadFile)}
        className={clsx(classes.root, className)}
        {...rest}
      >
        <input
          id="file"
          required
          type="file"
          accept=".csv"
          className={classes.input}
          onChange={handleFileChange}
        />
        <label htmlFor="file">
          <Button
            fullWidth
            variant="outlined"
            color={file ? "inherit" : "primary"}
            component="span"
            className={classes.button}
            disabled={submitting}
          >
            Select a CSV File
          </Button>
        </label>
        <Button
          type="submit"
          fullWidth
          variant={file ? "contained" : "outlined"}
          color="secondary"
          //   component="span"
          //   className={classes.button}
          //   disabled={submitting}
        >
          Upload File
        </Button>
      </form>
      <Typography>
        {file && file.name}
        {fileError && fileError}
      </Typography>{" "}
      {success && JSON.stringify(data)}
    </div>
  )
}

const REGIONS_FILE_UPLOAD_MUTATION = gql`
  mutation ($addressFile: Upload) {
    createRegions(addressFile: $addressFile) {
      region {
        id
        name
        label
        worldRegion
        globalRegion
      }
    }
  }
`
export default UploadRegions
