import React, { useState } from "react"
import { Box, Grid, TextField, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"

const DocsCategorySelect = ({
  categoryOptions,
  classes,
  category,
  setCategory,
}) => {
  const handleCategoryChange = event => {
    event.persist()

    let value = 0

    if (event.target.value !== 0) {
      value = event.target.value
    }
    const newCategory = categoryOptions.find(cat => cat.id === value)
    setCategory(newCategory)
  }
  // const handleChange =
  //   if (loading) return <Loading />
  //   if (error) return <Error />
  return (
    <>
      <TextField
        id="docs-category-select"
        select
        fullWidth
        label="Category"
        value={category}
        onChange={handleCategoryChange}
        SelectProps={{
          native: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
      >
        {category && (
          <option
            key={category && category.name ? category.name : "nocategory"}
            value={category && category.id ? category.id : ""}
          >
            {category.name}
          </option>
        )}
        {categoryOptions
          .filter(opt => opt.id !== category.id)
          .map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
      </TextField>
    </>
  )
}

const styles = theme => ({
  root: {},
})

export default withStyles(styles)(DocsCategorySelect)
