import React from "react"
import { Box, Card, Container, Grid, makeStyles } from "@material-ui/core"
import Page from "../../../Global/Page"
import Header from "./Header"
import PersonCreateForm from "./PersonCreateForm"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

function PersonCreateView() {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Add New Person">
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <Card className={classes.roundedCard}>
                <PersonCreateForm />
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default PersonCreateView
