import React, { useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../Utils/Loading"
import FormError from "../../../../Utils/FormError"

// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';

import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"

import NavigateNextIcon from "@material-ui/icons/NavigateNext"

const useStyles = makeStyles(() => ({
  root: {},
}))

const ItemConversionStepOne = ({
  setActiveStep,

  setItem,
  inquiry,
  person,
  specs,
  inquiryItem,
  setSpec,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const [itemName, setItemName] = useState(specs.title)
  const [isActive, setIsActive] = useState(true)
  const [isPrivate, setIsPrivate] = useState(true)
  const [pricingModelId, setPricingModelId] = useState("")
  const [businessUnitId, setBusinessUnitId] = useState(inquiry.businessUnit.id)
  const [categoryId, setCategoryId] = useState(inquiryItem.inquiryItemType.id)
  const [enterPrivate, setEnterPrivate] = useState(false)
  const [newOwner, setNewOwner] = useState(null)
  const [itemId, setItemId] = useState("")

  const { loading, data, error } = useQuery(FORM_INFO_QUERY)
  const [createItem] = useMutation(CREATE_ITEM_MUTATION, {
    variables: {
      name: itemName,
      isActive: isActive,
      isPrivate: isPrivate,
      pricingModelId: pricingModelId,
      businessUnitId: businessUnitId,
      categoryId: categoryId,
    },

    onCompleted: data => {
      console.log(data)
      setItem(data.createItem.item)
      setItemId(data.createItem.item.id)
      convertInquiryItem()
      //   handleOwnerUserCheck()
      // nextStep()
    },
  })
  const [convertInquiryItem] = useMutation(INQUIRY_ITEM_CONVERSION_MUTATION, {
    variables: {
      itemId: itemId,
      inquiryItemId: inquiryItem.id,
    },
    onCompleted: data => {
      console.log(data)
      console.log("converted")

      nextStep()
    },
  })
  if (loading) return <Loading />
  if (error) return <FormError />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const handleChange = event => {
    setCategoryId()
  }

  const handleSubmit = (event, createItem) => {
    event.preventDefault()

    createItem()
  }
  const handleCheckActive = event => {
    setIsActive(event.target.checked)
  }
  const handleCheckPrivate = event => {
    setIsPrivate(event.target.checked)
    if (isPrivate && newOwner === null) {
      setNewOwner(false)
    }
  }

  const handleCheckNewPrivate = event => {
    setIsPrivate(event.target.checked)
  }

  const handleChangeOwner = event => {
    setNewOwner(event.target.value)
  }

  const handleOwnerUserCheck = event => {
    if (newOwner === false) {
      console.log(
        "Use the find user query results and either create prereg user or confirm user account step."
      )
    }
  }
  const nextStep = () => {
    {
      isPrivate ? setActiveStep(1) : setActiveStep(3)
    }
  }
  const categories = data.categories
  const sbus = data.sbus
  const pricingModels = data.pricingModels
  if (person == "undefined")
    return (
      <Box p={2}>
        <Typography>
          Please go back to the inquiry page and set up the Person account
          before converting this item.
        </Typography>
      </Box>
    )
  return (
    // <Card className={clsx(classes.root, className)} {...rest}>
    //   <CardHeader title="Inquiry Info" />
    //   <Divider />
    <>
      <Box p={2}>
        <form
          onSubmit={event => handleSubmit(event, createItem)}
          className={clsx(classes.root, className)}
          {...rest}
        >
          {person.firstName}
          <TextField
            // error={Boolean(touched.firstName && errors.firstName)}
            fullWidth
            // helperText={touched.firstName && errors.firstName}
            label="Item Name"
            margin="dense"
            size="small"
            name="itemName"
            // onBlur={handleBlur}
            onChange={event => setItemName(event.target.value)}
            type="itemName"
            value={itemName}
            variant="outlined"
          />
          <TextField
            label="Select a Business Unit"
            name="businessUnit"
            onChange={event => setBusinessUnitId(event.target.value)}
            select
            margin="dense"
            size="small"
            fullWidth
            variant="outlined"
            SelectProps={{ native: true }}
            value={businessUnitId}
          >
            <option></option>
            {sbus.map(b => (
              <option key={b.id} value={b.id}>
                {b.name}
              </option>
            ))}
          </TextField>
          <TextField
            label="Select an Item Category"
            name="category"
            onChange={event => setCategoryId(event.target.value)}
            value={categoryId}
            select
            margin="dense"
            size="small"
            fullWidth
            variant="outlined"
            SelectProps={{ native: true }}
            value={categoryId}
          >
            <option></option>
            {categories.map(c => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </TextField>
          <TextField
            label="Select a Pricing Model"
            name="pricingModel"
            onChange={event => setPricingModelId(event.target.value)}
            select
            margin="dense"
            size="small"
            fullWidth
            variant="outlined"
            SelectProps={{ native: true }}
            value={pricingModelId}
          >
            <option></option>
            {pricingModels.map(p => (
              <option key={p.id} value={p.id}>
                {p.name}
              </option>
            ))}
          </TextField>

          <Box alignItems="center" display="flex" mt={2} ml={-1}>
            <Checkbox
              checked={isActive}
              // name="supplier"
              onChange={handleCheckActive}
            />

            <Typography variant="body2" color="textSecondary">
              This item is active.
            </Typography>
          </Box>
          <Box alignItems="center" display="flex" mt={2} ml={-1}>
            <Checkbox
              checked={isPrivate}
              // name="supplier"
              onChange={handleCheckPrivate}
            />

            <Typography variant="body2" color="textSecondary">
              This is a private item
            </Typography>
          </Box>
          <Collapse open={isPrivate}>
            <Box alignItems="center" display="flex" mt={2} ml={-1}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Private Item Owner</FormLabel>
                <RadioGroup
                  aria-label="owner"
                  name="owner"
                  value={newOwner}
                  onChange={handleChangeOwner}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label={`Assign current owner ownername`}
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Assign a different owner"
                  />
                </RadioGroup>
              </FormControl>
              {/* <Checkbox
              checked={isPrivate}
              // name="supplier"
              onChange={handleCheckNewPrivate}
            />

            <Typography variant="body2" color="textSecondary">
              conditionally open a boolean or switch here to select this owner or another.
            </Typography> */}
            </Box>
          </Collapse>
          <Box mt={2}>
            <Button
              color="primary"
              // disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Add New Item
            </Button>
          </Box>
        </form>
        {error ? <FormError /> : ""}
      </Box>
    </>
  )
}

const FORM_INFO_QUERY = gql`
  query {
    categories {
      id
      name
    }
    pricingModels {
      id
      name
    }
    sbus {
      id
      name
    }
  }
`
const CREATE_ITEM_MUTATION = gql`
  mutation (
    $name: String!
    $isActive: Boolean
    $isPrivate: Boolean
    $categoryId: Int
    $businessUnitId: Int
    $pricingModelId: Int
  ) {
    createItem(
      name: $name
      isActive: $isActive
      isPrivate: $isPrivate
      categoryId: $categoryId
      businessUnitId: $businessUnitId
      pricingModelId: $pricingModelId
    ) {
      item {
        id
        name
        category {
          id
          name
        }
        businessUnit {
          id
          name
        }
        isPrivate
        isActive
        pricingModel {
          id
          name
        }
        specItem {
          specId
          summary
        }
      }
    }
  }
`

const FIND_USER_ACCOUNT_QUERY = gql`
  query ($uid: String!) {
    userByUid(uid: $uid) {
      id
      email
    }
  }
`
const INQUIRY_ITEM_CONVERSION_MUTATION = gql`
  mutation ($inquiryItemId: Int, $itemId: Int) {
    createInquiryItemConversion(
      inquiryItemId: $inquiryItemId
      itemId: $itemId
    ) {
      inquiryItemConversion {
        id
        inquiryItem {
          id
        }
        item {
          id
        }
        convertedAt
        convertedBy {
          id
        }
      }
    }
  }
`
ItemConversionStepOne.propTypes = {
  className: PropTypes.string,
}

export default ItemConversionStepOne
