import React, { useState } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import Alert from "@material-ui/lab/Alert"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { Link } from "gatsby"
import ProjectDocsList from "./ProjectDocsList"
import NewProjectDoc from "./NewProjectDoc"
import ProjectDocsDetails from "./ProjectDocsDetails"
import EditMode from "./EditMode"

const ProjectDocs = ({ classes, project }) => {
  const [isUpdated, setIsUpdated] = useState(false)
  const [projectDoc, setProjectDoc] = useState("")
  const [view, setView] = useState("createDoc")
  const viewSwitch = view => {
    switch (view) {
      case "viewDoc":
        return (
          <>
            {projectDoc ? (
              <ProjectDocsDetails
                projectDoc={projectDoc}
                setProjectDoc={setProjectDoc}
                setView={setView}
                isUpdated={isUpdated}
              />
            ) : (
              <Card>
                <CardContent>
                  <Typography>No project docs found.</Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    onClick={() => setView("createDoc")}
                  >
                    Create a new doc
                  </Button>
                </CardContent>
              </Card>
            )}
          </>
        )
      // else return "No project doc selected"
      // return "view doc component"
      case "editDoc":
        return (
          <>
            {projectDoc ? (
              <EditMode
                projectDoc={projectDoc}
                setProjectDoc={setProjectDoc}
                setView={setView}
                setIsUpdated={setIsUpdated}
              />
            ) : (
              <>No doc to edit</>
            )}
          </>
        )
      case "createDoc":
        return (
          <NewProjectDoc
            project={project}
            setProjectDoc={setProjectDoc}
            setView={setView}
          />
        )
      default:
        return (
          <NewProjectDoc
            project={project}
            setProjectDoc={setProjectDoc}
            setView={setView}
          />
        )
    }
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <ProjectDocsList
            project={project}
            projectDoc={projectDoc}
            setProjectDoc={setProjectDoc}
            view={view}
            setView={setView}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          {isUpdated && (
            <Alert
              onClose={() => {
                setIsUpdated(!isUpdated)
              }}
            >
              Doc successfully updated
            </Alert>
          )}
          <div>{viewSwitch(view)}</div>
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  root: {},
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
})
export default withStyles(styles)(ProjectDocs)
