import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import { Link } from "gatsby"
import {
  Avatar,
  Badge,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Tooltip,
  makeStyles,
  SvgIcon,
} from "@material-ui/core"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import getInitials from "../../../../../../utils/getInitials"
// import StackAvatars from 'src/components/StackAvatars';
import { User as UserIcon } from "react-feather"

const useStyles = makeStyles(theme => ({
  root: {},
  viewButton: {
    marginLeft: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  avatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    backgroundColor: theme.palette.primary.dark,
  },
  myTask: {
    backgroundColor: theme.palette.primary.light,
  },
  customerTask: {},
  task: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}))

function TaskItem({ task, className, ...rest }) {
  const classes = useStyles()

  let deadline = "N/A"
  let critical = false

  if (task.due) {
    const now = moment()
    const deadlineMoment = moment(task.due)

    if (deadlineMoment.isAfter(now) && deadlineMoment.diff(now, "day") < 3) {
      deadline = `${deadlineMoment.diff(now, "day")} days remaining`
      critical = true
    }
  }

  return (
    <ListItem
      className={clsx(
        classes.root,
        { [classes.critical]: critical },
        className
      )}
      {...rest}
    >
      <Tooltip
        title={
          task.assignedTo.person.firstName +
          " " +
          task.assignedTo.person.lastName
        }
      >
        <ListItemAvatar>
          <Badge badgeContent={task.priority} color="primary">
            <Avatar className={clsx(classes.task)}>
              {task.isCustomerTask ? (
                <SvgIcon>
                  <UserIcon />
                </SvgIcon>
              ) : (
                getInitials(
                  task.assignedTo.person.firstName +
                    " " +
                    task.assignedTo.person.lastName
                )
              )}
            </Avatar>
          </Badge>
        </ListItemAvatar>
      </Tooltip>
      <ListItemText
        className={classes.listItemText}
        primary={
          <Link
            className={classes.link}
            to={`/manager/items/${task.item.id}`}
            state={{ uid: `${task.item.id}}` }}
          >
            {task.item.name}
          </Link>
        }
        primaryTypographyProps={{ variant: "h6", noWrap: true }}
        secondary={task.task + " | " + deadline}
      />
      <Tooltip title="View task">
        <IconButton className={classes.viewButton} edge="end">
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </ListItem>
  )
}

TaskItem.propTypes = {
  className: PropTypes.string,
  task: PropTypes.object.isRequired,
}

export default TaskItem
