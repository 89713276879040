import React, { useState } from "react"
import AddProposalPerson from "./AddProposalPerson"
import SearchProposalPerson from "./SearchProposalPerson"
import ConfirmProposalPerson from "./ConfirmProposalPerson"
import SelectButton from "./SelectButton"
import { makeStyles, Box, Card, Paper, Typography } from "@material-ui/core"
import clsx from "clsx"
import useDidMountEffect from "../../../../Global/hooks/useDidMountEffect"
const useStyles = makeStyles(theme => ({
  root: {
    padding: 3,
  },
  instructions: { margin: 4 },
}))

const ProposalPerson = ({
  setPersonUid,
  setPreregId,
  setUserId,
  person,
  setPerson,
  setIsPrereg,
  className,
  ...rest
}) => {
  const classes = useStyles()

  const [status, setStatus] = useState("begin")
  const [confirmed, setConfirmed] = useState(false)
  const [prereg, setPrereg] = useState(null)

  useDidMountEffect(() => {
    if (!person.user) {
      if (prereg) {
        setIsPrereg(true)
      }
    }
  }, [prereg])

  const addPerson = status => {
    switch (status) {
      case "begin":
        return null
      case "createPerson":
        return (
          <AddProposalPerson
            setPerson={setPerson}
            person={person}
            setPersonUid={setPersonUid}
            setStatus={setStatus}
          />
        )
      case "searchPerson":
        return (
          <SearchProposalPerson
            setPerson={setPerson}
            person={person}
            setPersonUid={setPersonUid}
            setStatus={setStatus}
          />
        )
      case "confirm":
        return (
          <ConfirmProposalPerson
            person={person}
            setPersonUid={setPersonUid}
            setConfirmed={setConfirmed}
            prereg={prereg}
            setPrereg={setPrereg}
            setIsPrereg={setIsPrereg}
          />
        )

      default:
        return null
    }
  }
  return (
    <>
      {confirmed ? (
        <>
          <Box m={5}>
            <Typography variant="h2" color="primary">
              {person.firstName} {person.lastName}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="h6" color="textPrimary">
            Choose from existing contacts or add a new person.
          </Typography>
          <SelectButton setStatus={setStatus} />
          {addPerson(status)}
        </>
      )}
    </>
  )
}
export default ProposalPerson
