import React, { useState } from "react"
import { Box, Grid, Button, Typography } from "@material-ui/core"
import PrivateItems from "./PrivateItems"
import Files from "./Files"

const Assets = ({ person }) => {
  const [asset, setAsset] = useState("files")
  const assetSwitch = asset => {
    switch (asset) {
      case "privateItems":
        return <PrivateItems person={person} />
      case "files":
        return <Files person={person} />

      default:
        return <div></div>
    }
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth
            variant={asset === "files" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setAsset("files")}
          >
            Files
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth
            variant={asset === "privateItems" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setAsset("privateItems")}
          >
            Private Items
          </Button>
        </Grid>
      </Grid>
      <Box mt={3}>{assetSwitch(asset)}</Box>
    </>
  )
}

export default Assets
