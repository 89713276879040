import React, { useState } from "react"
import {
  Container,
  Card,
  Paper,
  Grid,
  makeStyles,
  useTheme,
} from "@material-ui/core"
import Page from "../../../Global/Page"
import Header from "./Header"
import clsx from "clsx"

// import LatestProjects from './LatestProjects';
// import NewProjects from './NewProjects';
import NewOrders from "./NewOrders"
import NewInquiries from "./NewInquiries"
import TaskCounter from "./TaskCounter"
import SupplierBatches from "./SupplierBatches"
import ItemTasks from "./Tasks/ItemTasks"
import LatestInquiries from "./LatestInquiries"
import CurrentOrders from "./CurrentOrders"

import Drawer from "@material-ui/core/Drawer"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import CssBaseline from "@material-ui/core/CssBaseline"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import MailIcon from "@material-ui/icons/Mail"

// import PerformanceOverTime from './PerformanceOverTime';
// import RealTime from './RealTime';
// import RoiPerCustomer from './RoiPerCustomer';
// import SystemHealth from './SystemHealth';
// import TeamTasks from './TeamTasks';
// import TodaysMoney from './TodaysMoney';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  // container: {
  //   [theme.breakpoints.up("lg")]: {
  //     paddingLeft: 64,
  //     paddingRight: 64,
  //   },
  // },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}))

const drawerWidth = 240

function ServiceHomeView() {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false} className={classes.container}>
        <Header />
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xs={12}>
            <TaskCounter />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <NewInquiries />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <NewOrders />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <SupplierBatches />
          </Grid>
          <Grid item sm={2} xs={2}>
            <Paper>
              <List>
                {["All mail", "Trash", "Spam"].map((text, index) => (
                  <ListItem button key={text}>
                    <ListItemIcon>
                      {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                    </ListItemIcon>
                    {/* <ListItemText primary={text} /> */}
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item lg={9} xs={10}>
            Performance Over Time we like to chat
          </Grid>
          {/* <Grid
            item
            lg={9}
            xs={12}
          ><CurrentOrders />
            <PerformanceOverTime />
          </Grid>
          <Grid item lg={5} xl={4} xs={12}>
            <ItemTasks />
          </Grid>
          <Grid item lg={7} xl={8} xs={12}>
            <LatestInquiries />
          </Grid> */}
        </Grid>
        {/* <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {["All mail", "Trash", "Spam"].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer> */}
      </Container>
    </Page>
  )
}

export default ServiceHomeView
