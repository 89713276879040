import React, { useEffect, useContext, useReducer } from "react"
import SpecContext from "./SpecContext"
import SpecReducer from "./SpecReducer"

const SpecProvider = props => {
  const initialState = useContext(SpecContext)
  const [state, dispatch] = useReducer(SpecReducer, initialState)

  return (
    <SpecContext.Provider value={{ state, dispatch }}>
      {props.children}
    </SpecContext.Provider>
  )
}

export default SpecProvider
