import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from "@material-ui/core"
import getInitials from "../../../../../utils/getInitials"

const useStyles = makeStyles(() => ({
  root: {},
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: 0,
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

function Members({ project, className, ...rest }) {
  const classes = useStyles()
  const projectPeople = project.personProject

  return (
    <Card
      // className={clsx(classes.root, className)}

      // {...rest}
      className={classes.roundedCard}
    >
      <CardHeader
        className={classes.header}
        title="Project people"
        titleTypographyProps={{
          variant: "overline",
        }}
      />
      <CardContent className={classes.content}>
        <List>
          {projectPeople.map(member => {
            const memberName = member.person.firstName
              ? member.person.firstName + " " + member.person.lastName
              : ""
            return (
              <ListItem disableGutters key={member.id}>
                <ListItemAvatar>
                  <Avatar
                    alt="Project Member"
                    className={classes.avatar}
                    // src={member.person.profileImage}
                  >
                    {getInitials(memberName)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={memberName}
                  primaryTypographyProps={{ variant: "h6" }}
                  secondary={member.role ? member.role.name : ""}
                />
              </ListItem>
            )
          })}
        </List>
      </CardContent>
      <Divider />
      <CardActions>
        <Button fullWidth>Edit Project People</Button>
      </CardActions>
    </Card>
  )
}

Members.propTypes = {
  className: PropTypes.string,
}

export default Members
