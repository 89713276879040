import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  makeStyles,
} from "@material-ui/core"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart"
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined"
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd"
import OrderContext from "./context/OrderContext"

const statusOptions = [
  {
    id: 10,
    orderStatus: "Unspecified",
  },
  { id: 1, orderStatus: "Completed" },
  {
    id: 2,
    orderStatus: "Order received",
  },
  {
    id: 3,
    orderStatus: "Items in production",
  },
  { id: 4, orderStatus: "Cancelled" },
  {
    id: 5,
    orderStatus: "On hold",
  },
  {
    id: 6,
    orderStatus: "Shipped",
  },
  {
    id: 7,
    orderStatus: "Customer placed order",
  },
  {
    id: 8,
    orderStatus: "Payment issue",
  },
  {
    id: 9,
    orderStatus: "Staff initiated order",
  },
]

const useStyles = makeStyles(theme => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: "#000000",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  moreButton: {
    margin: theme.spacing(0, 0, 0, 3),
  },
  roundedCard: {
    borderRadius: "15px",
  },
}))

function OrderInfo({ id, className, ...rest }) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const { state, dispatch } = useContext(OrderContext)
  const od = state.orderDetails
  const {
    status,
    businessUnit,
    orderDate,
    completed,
    inProgress,
    completionDate,
    orderTotal,
    orderPaymentTotal,
    orderBalanceDue,
    orderSubtotal,
    taxAmt,
    discountAmt,
    postageAmt,
    balanceDue,
    balanceDueDate,
    notes,
    fromCart,
    cartId,
  } = od
  const orderItems = state.orderItems
  // const status = od.status
  // const businessUnit = od.businessUnit
  const orderPayments = od.orderPayment ? od.orderPayment : undefined
  // const order = state.orderDetails
  // const items = state.orderItems
  // const orderTotal = state.total
  // const discount = order.discount
  // const taxAmt = order.taxAmt
  // const [status, setStatus] = useState(order.status)
  // const [statusId, setStatusId] = useState(
  //   order.status ? order.status.id : null
  // )

  const [editing, setEditing] = useState(false)

  // const [completed, setCompleted] = useState(order.completed)
  // const [inProgress, setInProgress] = useState(order.inProgress)
  // const [completionDate, setCompletionDate] = useState(order.completionDate)

  // const [taxAmt, setTaxAmt] = useState(order.taxAmt)
  // const [discountAmt, setDiscountAmt] = useState(order.discountAmt)
  // const [postageAmt, setPostageAmt] = useState(order.postageAmt)
  const { data, loading, error } = useQuery(ORDER_DETAILS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: id,
    },
    onCompleted: (data, response, errors) => {
      console.log(data)

      // setOrderDetails(data.order)
      // dispatch({ type: "SET_ORDER_ID", payload: data.order.id })
      console.log("order details query on orderdetails")
      dispatch({ type: "SET_ORDER_DETAILS", payload: data.order })
      // dispatch({ type: "SET_ORDER_ITEMS", payload: data.order.orderItems })
      // dispatch({ type: "SET_ORDER_COMPLETED", payload: data.order.completed })
      // dispatch({ type: "SET_ORDER_TOTAL", payload: data.order.orderTotal })
      // dispatch({ type: "SET_ORDER_TAX_AMT", payload: data.order.taxAmt })
    },
  })

  const [updateOrderShippingTaxDiscount] = useMutation(
    UPDATE_SHIPPING_TAX_DISCOUT_MUTATION,
    {
      variables: {
        orderId: id,
        discountAmt: discountAmt,
        taxAmt: taxAmt,
        postageAmt: postageAmt,
      },
      onCompleted: data => {
        console.log(data)
      },
    }
  )
  // const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS, {
  //   variables: {
  //     orderId: id,
  //     statusId: status.id,
  //     inProgress: inProgress,
  //     completed: completed,
  //   },
  //   onCompleted: data => {
  //     console.log(data)
  //     // setInProgress(data.updateOrderStatus.order.inProgress)
  //     // setCompleted(data.updateOrderStatus.order.inProgress)
  //     // setStatus(data.updateOrderStatus.order.status)
  //     // setStatusId(data.updateOrderStatus.order.status.id)
  //     console.log(`mutated status is ${data.updateOrderStatus.order.status.id}`)
  //     // setStatusName(data.updateOrderStatus.order.status.orderStatus)
  //     // setCompletionDate(data.updateOrderStatus.order.completionDate)
  //     setEditing(false)
  //   },
  // })

  // useEffect(() => {
  //   {
  //     !status && setStatusId(10)
  //   }
  //   updateOrderStatus()
  // }, [inProgress, completed, statusId])

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  function financial(x) {
    return Number.parseFloat(x).toFixed(2)
  }

  function priceRow(qty, unit) {
    return qty * unit
  }

  function createRow(desc, qty, unit) {
    const price = priceRow(qty, unit)
    return { desc, qty, unit, price }
  }

  // function subtotal() {
  //   let prices = items.priceEach

  //   return prices.map(price => price.reduce((sum, i) => sum + i, 0))
  // }
  // function subtotal(items) {
  //   let lineSubtotal = items.qty * items.priceEach
  //   return items.map(priceEach => priceEach.reduce((sum, i) => sum + i, 0))
  // }
  // function subtotal(items) {
  //   return items
  //     .map(lineSubtotal => lineSubtotal)
  //     .reduce((sum, i) => sum + i, 0)
  // }
  // function calcTotal(a, b) {
  //   return (a * b).toFixed(2)
  // }
  // const calculateTotalTaxes = items => items.reduce(item => item.taxAmt, 0)

  // const calculateTotalDiscount = items =>
  //   items.reduce((total, item) => total + item.discountAmt, 0)

  // const calculateSubtotal = items =>
  //   items.reduce((total, item) => total + item.qty * item.priceEach, 0)
  // const orderSubtotal = calculateSubtotal(items)

  // const handleStatusChange = event => {
  //   event.persist()

  //   let value = 0
  //   if (event.target.value !== 0) {
  //     value = event.target.value
  //   }
  //   if (value === 0 || value == undefined) setStatusId(10)
  //   setStatusId(value)
  //   const result = statusOptions.find(({ id }) => id === value)
  //   setStatus(result)
  //   console.log(`set status is ${value}`)
  // }

  // const calculatePayment = orderPayments =>
  //   orderPayments &&
  //   orderPayments.reduce(
  //     (total, orderPayments) => total + orderPayments.totalPaid,
  //     0
  //   )
  // const totalPaid = calculatePayment(orderPayments)

  // const orderTaxes = calculateTotalTaxes(items)

  // const orderDiscount = calculateTotalDiscount(items)
  // const subTotal = parseFloat(order.subTotal)
  // const taxAmt = parseFloat(order.taxAmt)
  // const discountAmt = parseFloat(order.discountAmt)
  // const orderTotal = subTotal + taxAmt - discountAmt
  const handleUpdateStatus = (val, e) => {
    console.log(val)
  }
  if (loading) return "loading..."
  return (
    <Card className={clsx(classes.root, classes.roundedCard)}>
      <CardHeader
        title={`${businessUnit && businessUnit.name} Order # ${id}`}
        subheader={status && status.orderStatus}
        // title={`${order.businessUnit.name} Order # ${order.id} | Status: ${order.status.orderStatus}`}
      />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell align="right">
              <Link
                className={classes.link}
                component={Link}
                to={`/manager/people/${od.customer && od.customer.uid}`}
              >
                {od.customer && od.customer.firstName}{" "}
                {od.customer && od.customer.lastName}
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Order Placed</TableCell>
            <TableCell align="right">
              {moment(orderDate).format("MMM Do, YYYY, HH:MM a")}
            </TableCell>
          </TableRow>
          {completed && (
            <TableRow>
              <TableCell>Completed</TableCell>
              <TableCell align="right">
                {moment(completionDate && completionDate).format(
                  "MMM Do, YYYY"
                )}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Items in Order</TableCell>
            <TableCell align="right">
              {/* {order.orderWithItem && order.orderWithItem.length} */}
              {orderItems && orderItems.length > 0 ? orderItems.length : "None"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>From Cart</TableCell>
            <TableCell align="right">
              {/* {order.orderWithItem && order.orderWithItem.length} */}
              {fromCart && cartId.length > 5 ? (
                <IconButton
                  size="small"
                  color="primary"
                  component={Link}
                  to={`/manager/carts/${cartId && cartId}`}
                  state={{ cartId: `${cartId && cartId}` }}
                >
                  <ShoppingCartIcon />
                </IconButton>
              ) : (
                <RemoveShoppingCartIcon />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Collapse in={expanded}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Subtotal</TableCell>
              <TableCell align="right">
                {orderSubtotal && parseFloat(orderSubtotal) > 0
                  ? `$${financial(orderSubtotal)}`
                  : ""}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Discount</TableCell>
              <TableCell align="right">
                {/* {order.discountAmt && order.discountAmt > 0
                  ? `$${financial(order.discountAmt)}`
                  : ""} */}
                {discountAmt && parseFloat(discountAmt) > 0
                  ? `$${financial(discountAmt)}`
                  : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tax</TableCell>
              <TableCell align="right">
                {/* {order.taxAmt && order.taxAmt > 0
                  ? `$${financial(order.taxAmt)}`
                  : ""} */}
                {taxAmt && taxAmt > 0 ? `$${financial(taxAmt)}` : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Postage / Deliv</TableCell>
              <TableCell align="right">
                {/* {order.postageAmt && order.postageAmt > 0
                  ? `$${financial(order.postageAmt)}`
                  : ""} */}
                {postageAmt && postageAmt > 0
                  ? `$${financial(postageAmt)}`
                  : ""}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Collapse>

      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              Total
              <IconButton
                size="small"
                edge="end"
                color="primary"
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                className={clsx(classes.moreButton, classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
              >
                <PlaylistAddIcon />
              </IconButton>
            </TableCell>
            <TableCell align="right">
              {orderTotal && parseFloat(orderTotal) > 0
                ? `$${financial(orderTotal)}`
                : ""}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Paid</TableCell>
            <TableCell align="right">
              {orderPaymentTotal && parseFloat(orderPaymentTotal) > 0
                ? `$${financial(orderPaymentTotal)}`
                : ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Balance Due</TableCell>
            <TableCell align="right">
              {orderBalanceDue && parseFloat(orderBalanceDue) > 0
                ? `$${financial(orderBalanceDue)}`
                : `$${financial(orderBalanceDue)}`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>In Progress </TableCell>
            <TableCell align="right">
              <Switch
                checked={inProgress}
                // onChange={event => setInProgress(event.target.checked)}
                onChange={e => handleUpdateStatus(e.target.value, e)}
                color="secondary"
                name="inProgress"
                inputProps={{ "aria-label": "in progress" }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Completed </TableCell>
            <TableCell align="right">
              <Switch
                checked={completed}
                onChange={e => handleUpdateStatus(e.target.Value, e)}
                // onChange={event => setCompleted(event.target.checked)}
                // onChange={handleCompletedChange}
                color="secondary"
                name="completed"
                inputProps={{ "aria-label": "completed" }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell align="right">
              {editing ? (
                <TextField
                  fullWidth
                  name="option"
                  size="small"
                  value={status.id}
                  onChange={e => handleUpdateStatus(e.target.value, e)}
                  // onChange={handleStatusChange}
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                >
                  <option></option>
                  {statusOptions.map(so => (
                    <option key={so.id} value={so.id}>
                      {so.orderStatus}
                    </option>
                  ))}
                </TextField>
              ) : (
                <ButtonBase onClick={() => setEditing(true)}>
                  {status && status.orderStatus}
                </ButtonBase>
              )}
              {/* <TextField
                fullWidth
                name="option"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={status}
                variant="outlined"
              >
                {statusOptions.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField> */}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardActions>
        {completed && orderBalanceDue && orderBalanceDue === 0 ? (
          <Button fullWidth variant="contained" color="primary">
            <ReceiptIcon className={classes.actionIcon} />
            Send a Receipt
          </Button>
        ) : (
          <Button fullWidth variant="outlined" color="primary">
            <ReceiptIcon className={classes.actionIcon} />
            Send an Invoice
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

OrderInfo.propTypes = {
  className: PropTypes.string,
}
const ORDER_DETAILS_QUERY = gql`
  query ($id: Int!) {
    order(id: $id) {
      id
      status {
        id
        orderStatus
      }
      orderDate
      completed
      inProgress
      completionDate
      orderTotal
      orderPaymentTotal
      orderBalanceDue
      orderSubtotal
      taxAmt
      discountAmt
      postageAmt
      balanceDue
      balanceDueDate
      notes
      fromCart
      cartId
      orderPayment {
        id
        totalPaid
        paymentDate
      }
      orderItems: orderWithItem {
        id
      }
      businessUnit {
        id
        name
        homepage
        primaryEmail
      }

      customer {
        uid
        firstName
        lastName
        addressPerson {
          id
          address {
            uid
            summary
          }
        }
        user {
          id
          email
          isVerified
          active
          lastLogin
        }
        preregPerson {
          preregId
          preregEmail
          person {
            uid
            firstName
            lastName
          }
        }
      }
    }
  }
`
const UPDATE_ORDER_STATUS = gql`
  mutation (
    $orderId: Int!
    $statusId: Int
    $completed: Boolean
    $inProgress: Boolean
  ) {
    updateOrderStatus(
      orderId: $orderId
      statusId: $statusId
      completed: $completed
      inProgress: $inProgress
    ) {
      order {
        id
        status {
          id
          orderStatus
        }
        orderDate
        completed
        inProgress
        completionDate
        orderTotal
        orderPaymentTotal
        orderBalanceDue
        orderSubtotal
        taxAmt
        discountAmt
        postageAmt
        balanceDue
        balanceDueDate
        orderPayment {
          id
          totalPaid
          paymentDate
        }
        orderWithItem {
          id
          order {
            id
          }
          qty
          priceEach
          itemSubtotal
          taxAmt
          discountAmt
          dueInHands
          customFields
          item {
            id
            name
            itemPrice {
              id
              minQuantity
              maxQuantity
              price
            }
            pricingModel {
              id
              name
            }
            category {
              id
              name
            }
          }
        }
        businessUnit {
          id
          name
          homepage
          primaryEmail
        }
        taxAmt
        discountAmt
        postageAmt
        balanceDue
        balanceDueDate
        notes
        fromCart
        cartId
        customer {
          uid
          firstName
          lastName
          addressPerson {
            id
            address {
              uid
              summary
            }
          }
          user {
            id
            email
            isVerified
            active
            lastLogin
          }
          preregPerson {
            preregId
            preregEmail
            person {
              uid
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`
const UPDATE_SHIPPING_TAX_DISCOUT_MUTATION = gql`
  mutation (
    $orderId: Int!
    $discountAmt: Money
    $taxAmt: Money
    $postageAmt: Money
  ) {
    updateOrderShippingTaxDiscount(
      orderId: $orderId
      discountAmt: $discountAmt
      taxAmt: $taxAmt
      postageAmt: $postageAmt
    ) {
      order {
        id
        status {
          id
          orderStatus
        }
        orderDate
        completed
        inProgress
        completionDate
        orderTotal
        orderPaymentTotal
        orderBalanceDue
        orderSubtotal
        taxAmt
        discountAmt
        postageAmt
        balanceDue
        balanceDueDate
        orderPayment {
          id
          totalPaid
          paymentDate
        }
        orderWithItem {
          id
          order {
            id
          }
          qty
          priceEach
          itemSubtotal
          taxAmt
          discountAmt
          dueInHands
          customFields
          item {
            id
            name
            itemPrice {
              id
              minQuantity
              maxQuantity
              price
            }
            pricingModel {
              id
              name
            }
            category {
              id
              name
            }
          }
        }
        businessUnit {
          id
          name
          homepage
          primaryEmail
        }
        taxAmt
        discountAmt
        postageAmt
        balanceDue
        balanceDueDate
        notes
        fromCart
        cartId
        customer {
          uid
          firstName
          lastName
          addressPerson {
            id
            address {
              uid
              summary
            }
          }
          user {
            id
            email
            isVerified
            active
            lastLogin
          }
          preregPerson {
            preregId
            preregEmail
            person {
              uid
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`
export default OrderInfo
