import React, { useContext, useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
// import { useParams } from 'react-router';
import PropTypes from "prop-types"
import clsx from "clsx"
import moment from "moment"
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  IconButton,
  Hidden,
  Tooltip,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core"
import Label from "../../../Global/Label"
import StarIcon from "@material-ui/icons/Star"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import LabelImportantIcon from "@material-ui/icons/LabelImportant"
import LabelImportantOutlinedIcon from "@material-ui/icons/LabelImportantOutlined"
import RoomServiceIcon from "@material-ui/icons/RoomService"
import getInitials from "../../../../utils/getInitials"
import MessagesContext from "../context/MessagesContext"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import lightGreen from "@material-ui/core/colors/lightGreen"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
function getTo(params, convId) {
  const { systemLabel, customLabel } = params
  const baseUrl = "/manager/messages/"

  if (systemLabel) {
    return `${baseUrl}/${systemLabel}/${convId}`
  }

  if (customLabel) {
    return `${baseUrl}/label//${convId}`
    // return `${baseUrl}/label/${customLabel}/${mailId}`;
  }

  return baseUrl
}
const green = lightGreen[900]
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    alignItems: "flex-start",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  unread: {
    position: "relative",
    "&:before": {
      content: '" "',
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      width: 4,
      backgroundColor: theme.palette.error.main,
    },
    "& $name, & $subject": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
  filterActive: {
    color: colors.amber[400],
  },
  content: {
    cursor: "pointer",
    textDecoration: "none",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  details: {
    [theme.breakpoints.up("md")]: {
      flexGrow: 1,
      display: "flex",
      alignItems: "flex-end",
    },
    [theme.breakpoints.down("md")]: {
      flexGrow: 1,
      display: "flex",
      alignItems: "flex-end",
    },
  },
  name: {
    [theme.breakpoints.up("md")]: {
      minWidth: 250,
      flexBasis: 250,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      alignItems: "flex-end",
      alignContent: "flex-end",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: 50,
      flexBasis: 50,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      alignItems: "flex-end",
      alignContent: "flex-end",
    },
  },
  latest: {
    [theme.breakpoints.up("md")]: {
      minWidth: 180,
      flexBasis: 180,
    },
  },
  topic: {
    [theme.breakpoints.up("md")]: {
      width: 200,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      alignItems: "flex-start",
      alignContent: "flex-start",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: 50,
      maxWidth: 125,
      // flexBasis: 150,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      alignItems: "flex-end",
      alignContent: "flex-end",
    },
  },
  hasUpdate: {
    color: green,
    fontWeight: "bold",
  },
  message: {
    maxWidth: 800,
    flexGrow: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "auto",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.white,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 2,
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  date: {
    whiteSpace: "nowrap",
  },
  avatar: {
    height: 30,
    width: 30,
  },
  new: {
    backgroundColor: green,
  },
}))

const ConversationItem = ({
  className,
  conversation,
  conv,
  convId,
  setSelectedConversation,

  onDeselect,
  onSelect,
  selected,
  setPanel,
  ...rest
}) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(MessagesContext)
  const [star, setStar] = useState(conv.star)
  const [follow, setFollow] = useState(conv.follow)
  const [alerts, setAlerts] = useState(conv.alerts)

  const [updateStarFollow, { loading, data, error }] = useMutation(
    UPDATE_STAR_FOLLOW_USER_CONVERSATION_MUTATION,
    {
      variables: {
        conversationId: conv.conversation.uuid,
        star: star,
        follow: follow,
        alerts: alerts,
      },
      onCompleted: data => {
        dispatch({
          type: "STAR_COUNT",
          payload: data.updateFollowUserConversation.userConversation.starCount,
        })
        dispatch({
          type: "FOLLOWING_COUNT",
          payload:
            data.updateFollowUserConversation.userConversation.followingCount,
        })
      },
    }
  )
  let messages = conv.conversation.conversationMessage
  const lastMessage = messages[messages.length - 1]
  //   const params = useParams();
  //   const { labels } = useSelector((state) => state.mail);

  useEffect(() => {
    updateStarFollow()
  }, [star, follow, alerts])

  const handleCheckboxChange = event => {
    event.target.checked ? onSelect() : onDeselect()
  }
  const handleSelectConversation = event => {
    console.log(conv.conversation.uuid)
    dispatch({
      type: "SET_SELECTED_CONVERSATION_ID",
      payload: conv.conversation.uuid,
    })
    dispatch({ type: "SET_PANEL", payload: "conversationSelected" })
  }
  // if (loading) return <Loading />
  if (error) return <Error />
  return (
    <div
      className={clsx(
        classes.root,
        {
          //   [classes.unread]: !mail.isUnread,
          //   [classes.selected]: selected
        },
        className
      )}
      {...rest}
    >
      <Hidden smDown>
        <Box mr={1} display="flex" alignItems="center">
          <Checkbox checked={selected} onChange={handleCheckboxChange} />
          <Tooltip title="Starred">
            <IconButton onClick={() => setStar(!star)}>
              {star ? (
                <StarIcon className={classes.filterActive} />
              ) : (
                <StarBorderIcon />
              )}
            </IconButton>
          </Tooltip>

          <Tooltip title="Follow">
            <IconButton onClick={() => setFollow(!follow)}>
              {follow ? (
                <LabelImportantIcon className={classes.filterActive} />
              ) : (
                <LabelImportantOutlinedIcon />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Hidden>
      <Box
        minWidth="1px"
        display="flex"
        flexGrow={1}
        justifyContent="space-between"
        component={ButtonBase}
        onClick={handleSelectConversation}
        className={classes.content}
      >
        <Avatar
          className={
            conv.hasUpdate ? clsx(classes.avatar, classes.new) : classes.avatar
          }
          src={conv.conversation.category.name}
        >
          <Typography variant="body2">
            {getInitials(conv.conversation.category.name)}
          </Typography>
        </Avatar>
        <Box minWidth="1px" ml={1} className={classes.details}>
          <Typography
            variant="body2"
            color="textPrimary"
            className={
              conv.hasUpdate
                ? clsx(classes.hasUpdate, classes.topic)
                : classes.topic
            }
          >
            {conv.conversation.topic}
          </Typography>

          <Box flexGrow={1} />
          <Typography
            variant="body2"
            color="textSecondary"
            className={
              conv.hasUpdate
                ? clsx(classes.name, classes.hasUpdate)
                : classes.name
            }
          >
            {conv.conversation.startedBy.person.firstName}{" "}
            {conv.conversation.startedBy.person.lastName}
          </Typography>
          {/* <Typography
              variant="body2"
              color="textSecondary"
              className={classes.message}
            >
           
            </Typography> */}
          <Box flexGrow={1} />
          <Typography
            variant="caption"
            color="textSecondary"
            className={
              conv.hasUpdate
                ? clsx(classes.latest, classes.hasUpdate)
                : classes.latest
            }
          >
            {lastMessage &&
              `${moment(lastMessage.sent).format("MM/DD/YY h:mm a")}`}
          </Typography>
          {/* {mail.labelIds.length > 0 && (
              <Box
                display="flex"
                mx={2}
              >
                {mail.labelIds.map((labelId) => {
                  const label = labels.find((_label) => _label.id === labelId);

                  if (!label) return null;

                  return (
                    <span
                      style={{ backgroundColor: label.color }}
                      key={label.id}
                      className={classes.label}
                    >
                      {label.name}
                    </span>
                  );
                })}
              </Box>
            )} */}
          <Hidden smDown>
            <Typography
              className={classes.date}
              color="textSecondary"
              variant="caption"
            >
              {moment(conv.conversation.startDate).format("MM/DD/YY h:mm a")}
            </Typography>
          </Hidden>
        </Box>
      </Box>
    </div>
  )
}

ConversationItem.propTypes = {
  className: PropTypes.string,
  conv: PropTypes.object.isRequired,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.bool.isRequired,
}
const UPDATE_STAR_FOLLOW_USER_CONVERSATION_MUTATION = gql`
  mutation (
    $conversationId: String
    $follow: Boolean
    $star: Boolean
    $alerts: Boolean
  ) {
    updateFollowUserConversation(
      conversationId: $conversationId
      follow: $follow
      star: $star
      alerts: $alerts
    ) {
      userConversation {
        id
        starCount
        followingCount
        conversation {
          uuid
        }
        follow
        star
        alerts
      }
    }
  }
`
export default ConversationItem
