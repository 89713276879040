import React, { useState } from "react"
import {
  Button,
  Card,
  Container,
  Grid,
  Step,
  Stepper,
  StepLabel,
  StepContent,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { useQuery, useMutation } from "@apollo/client"
import { navigate } from "gatsby"
import Loading from "../../../Utils/Loading"
import FormError from "../../../Utils/FormError"
import gql from "graphql-tag"
import Header from "./Header"
import OrderPerson from "./OrderPerson"
import Page from "../../../Global/Page"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "91vh",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    backgroundColor: theme.palette.background.light,
    paddingT: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  roundedCard: {
    borderRadius: "15px",
    marginTop: theme.spacing(3),
  },
}))

function getSteps() {
  return ["Select a customer", "Pick a business unit", "Confirm and continue"]
}

const OrderCreateView = ({ className, ...rest }) => {
  const classes = useStyles()
  const [businessUnitId, setBusinessUnitId] = useState("")
  const [personUid, setPersonUid] = useState("")
  const [activeStep, setActiveStep] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const steps = getSteps()
  const now = new Date().toISOString()
  const { loading, data, error } = useQuery(ORDER_FORM_INFO_QUERY)
  const [addNewOrder] = useMutation(ORDER_CREATE_MUTATION, {
    variables: {
      customerUid: personUid,
      statusId: 7,
      orderDate: now,
      sbuId: businessUnitId,
      taxAmt: 0.0,
      discountAmt: 0.0,
      postageAmt: 0.0,
      balanceDue: 0.0,
      balanceDueDate: now,
      notes: "",
      fromCart: false,
      cartId: "",
      inProgress: true,
    },
    onCompleted: (data, errors) => {
      const orderId = data.createOrder.order.id
      setIsSubmitting(false)
      navigate(`/manager/orders/${orderId}`)
    },
  })
  const handleForm = event => {
    setIsSubmitting(true)
    addNewOrder()
  }
  const handleNext = () => {
    if (activeStep === steps.length - 1) {
    } else setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <OrderPerson setPersonUid={setPersonUid} />
      case 1:
        return (
          <>
            <TextField
              name="businessUnit"
              onChange={event => setBusinessUnitId(event.target.value)}
              select
              margin="dense"
              size="small"
              fullWidth
              variant="outlined"
              SelectProps={{ native: true }}
              value={businessUnitId}
            >
              <option></option>
              {sbus.map(b => (
                <option key={b.id} value={b.id}>
                  {b.name}
                </option>
              ))}
            </TextField>
          </>
        )
      case 2:
        return "Add and go to the new order"
      default:
        return <OrderPerson setPersonUid={setPersonUid} />
    }
  }

  if (loading) return <Loading />
  if (error) return <FormError />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const sbus = data.sbus

  return (
    <>
      <Page className={classes.root} title="Add a New Order">
        <Container maxWidth={false}>
          <Header />
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Card className={classes.roundedCard}>
                <form
                  onSubmit={event => {
                    event.preventDefault()
                    handleForm(event)
                  }}
                >
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                          <Typography>{getStepContent(index)}</Typography>
                          <div className={classes.actionsContainer}>
                            <Button
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              className={classes.button}
                            >
                              Back
                            </Button>
                            {activeStep === steps.length - 1 ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                type="submit"
                                className={classes.button}
                                disabled={
                                  !personUid || !businessUnitId || isSubmitting
                                }
                              >
                                Complete
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                              >
                                Next
                              </Button>
                            )}
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  )
}
const ORDER_FORM_INFO_QUERY = gql`
  query {
    sbus {
      id
      name
    }
    taxes {
      id
      code
      name
    }
  }
`

const ORDER_CREATE_MUTATION = gql`
  mutation (
    $customerUid: String
    $statusId: Int
    $orderDate: DateTime
    $sbuId: Int
    $taxAmt: Money
    $discountAmt: Money
    $postageAmt: Money
    $balanceDue: Money
    $balanceDueDate: DateTime
    $notes: String
    $fromCart: Boolean
    $cartId: String
    $inProgress: Boolean
  ) {
    createOrder(
      customerUid: $customerUid
      statusId: $statusId
      orderDate: $orderDate
      sbuId: $sbuId

      taxAmt: $taxAmt
      discountAmt: $discountAmt
      postageAmt: $postageAmt
      balanceDue: $balanceDue
      balanceDueDate: $balanceDueDate
      notes: $notes
      fromCart: $fromCart
      cartId: $cartId
      inProgress: $inProgress
    ) {
      order {
        id
        customer {
          uid
        }
        status {
          id
          orderStatus
        }
        inProgress
        orderDate
        completionDate
        businessUnit {
          id
          name
        }

        taxAmt
        discountAmt
        postageAmt
        balanceDue
        balanceDueDate
        notes
        fromCart
        cartId
      }
    }
  }
`
export default OrderCreateView
