import React, { useContext, useState } from "react"
import clsx from "clsx"
import Error from "../../../../../Utils/Error"
import Loading from "../../../../../Utils/Loading"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"

import { Box, Typography, makeStyles } from "@material-ui/core"
import SpecContext from "./context/SpecContext"

import EmbroideredPatchesSpecs from "./EmbroideredPatchesSpecs"
import LapelPinSpecs from "./LapelPinSpecs"

const useStyles = makeStyles(() => ({
  root: { padding: "5px" },
  update: { display: "flex", flexWrap: "wrap" },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: "25ch",
  },
}))

const ItemSpecsForm = ({
  // categoryId,
  // editing,
  // setEditing,
  // spec,
  specs,
  item,
  updateItem,
  setUpdateItem,
  activeStep,
  setActiveStep,
  className,
  ...rest
}) => {
  const { state, dispatch } = useContext(SpecContext)
  const [itemName, setItemName] = useState(item.name)
  const [categoryName, setCategoryName] = useState("")
  const classes = useStyles()
  console.log(state.mymessage)
  const categoryId = state.categoryId

  const { loading, error, data } = useQuery(GET_SPEC_QUERY, {
    variables: {
      itemId: item.id,
    },
    onCompleted: data => {
      console.log(data)
      setCategoryName(
        data.specByItem.item &&
          data.specByItem.item.category &&
          data.specByItem.item.category.name
          ? data.specByItem.item.category.name
          : ""
      )
      dispatch({ type: "SET_SPEC_ID", payload: data.specByItem.specId })
      dispatch({ type: "SET_SPEC_SUMMARY", payload: data.specByItem.summary })
      dispatch({ type: "SET_ITEM_ID", payload: item.id })
      dispatch({ type: "SET_ITEM", payload: data.specByItem.item })
      dispatch({
        type: "SET_CATEGORY_ID",
        payload: data.specByItem.item.category.id,
      })
      if (updateItem) {
        dispatch({ type: "SET_EDITING", payload: true })
      }
      if (specs) {
        dispatch({ type: "SET_INQUIRY_SPECS", payload: specs })
      }
      if (activeStep) {
        dispatch({ type: "SET_ACTIVE_STEP", payload: activeStep })
      }
    },
  })

  const getSpecForm = categoryId => {
    switch (categoryId) {
      case "0":
        return "Select a spec category"
      case "1":
        return (
          <EmbroideredPatchesSpecs
            // specs={specs}
            // editing={editing}
            // setEditing={setEditing}
            // spec={spec}
            // item={item}
            // updateItem={updateItem}
            // setUpdateItem={setUpdateItem}
            setActiveStep={setActiveStep}
          />
        )
      case "2":
        return (
          <LapelPinSpecs
            // specs={specs}
            // editing={editing}
            // setEditing={setEditing}
            // spec={spec}
            // item={item}
            // updateItem={updateItem}
            // setUpdateItem={setUpdateItem}
            setActiveStep={setActiveStep}
          />
        )
      default:
        return "unknown spec"
    }
  }
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Box mb={2}>
          <Typography variant="h4">{itemName}</Typography>
          <Typography variant="h5">{categoryName}</Typography>
        </Box>

        <div>{getSpecForm(categoryId)}</div>
      </Box>
    </>
  )
}
const GET_SPEC_QUERY = gql`
  query ($itemId: Int!) {
    specByItem(itemId: $itemId) {
      specId
      summary
      item {
        id
        name
        category {
          id
          name
        }
      }
    }
  }
`
export default ItemSpecsForm
