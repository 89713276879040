import React, { useState } from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

const Projects = ({ plan, classes }) => {
  return <div>Projects Component / Scrum board at top</div>
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(Projects)
