import React, { useState, useEffect, useCallback } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import PerfectScrollbar from "react-perfect-scrollbar"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  colors,
  CircularProgress,
  makeStyles,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"

import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"
//   import useIsMountedRef from 'src/hooks/useIsMountedRef';
import GenericMoreButton from "../../../../Global/GenericMoreButton"
//   import CircularProgress from './CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    flexShrink: 0,
    height: 56,
    width: 56,
  },
  subscriptions: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  value: {
    color: colors.green[600],
    fontWeight: theme.typography.fontWeightMedium,
  },
  navigateNextIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const CurrentSupplierOrders = ({ className, ...rest }) => {
  const classes = useStyles()

  const [products, setProducts] = useState(null)
  const { loading, error, data } = useQuery(SUPPLIER_BATCHES_QUERY, {
    variables: {
      pageSize: 10,
      page: 1,
      search: "",
    },
  })

  if (loading) return <Loading />

  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  // useEffect(() => {
  //   getProducts();
  // }, [getProducts]);

  // if (!products) {
  //   return null;
  // }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader action={<GenericMoreButton />} title="Batch Production" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Box>{JSON.stringify(data)}</Box>
          <Table>
            <TableBody>
              <TableRow>Order Batches</TableRow>
              <TableRow></TableRow>
              {/* {products.map((product) => (
                  <TableRow
                    hover
                    key={product.id}
                  >
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                      >
                        <img
                          alt="Product"
                          className={classes.image}
                          src={product.image}
                        />
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            color="textPrimary"
                          >
                            {product.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            <span className={classes.subscriptions}>
                              {product.subscriptions}
                            </span>
                            {' '}
                            Active
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="h6"
                        color="textPrimary"
                      >
                        Price
                      </Typography>
                      <Typography
                        noWrap
                        variant="body2"
                        color="textSecondary"
                      >
                        <span className={classes.value}>
                          {product.currency}
                          {product.price}
                        </span>
                        {' '}
                        monthly
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Box mr={2}>
                          <Typography
                            align="right"
                            variant="h6"
                            color="textPrimary"
                          >
                            {product.progress}
                            %
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >
                            Conversion Rate
                          </Typography>
                        </Box>
                        <CircularProgress value={product.progress} />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))} */}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button component={Link} size="small" to="#">
          See all
          <NavigateNextIcon className={classes.navigateNextIcon} />
        </Button>
      </Box>
    </Card>
  )
}

CurrentSupplierOrders.propTypes = {
  className: PropTypes.string,
}

const SUPPLIER_BATCHES_QUERY = gql`
  query ($page: Int, $pageSize: Int, $search: String) {
    itemBatches(page: $page, pageSize: $pageSize, search: $search) {
      page
      pages
      count
      hasNext
      hasPrev
      objects {
        id
        supplier {
          ouid
          name
        }
        batchStatus {
          id
          name
        }
        carrier {
          ouid
          name
        }
        expectedArrival
        tracking
        shipFrom {
          ouid
          addressOrg {
            address {
              summary
            }
          }
        }
      }
    }
  }
`
export default CurrentSupplierOrders
