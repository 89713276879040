import React, { useState, useEffect } from "react"
import clsx from "clsx"

import {
  Button,
  Box,
  Card,
  CardContent,
  Checkbox,
  Typography,
  Link,
  TextField,
  makeStyles,
} from "@material-ui/core"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import PrivateItemOwnerSearch from "../../../ItemCreateView/ItemCreateForms/PrivateItemOwnerSearch"
const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 2,
    marginBotton: 5,
  },
  name: {
    textDecoration: "none",
    fontWeight: "bold",
    // fontVariant: "small-caps",
    color: theme.palette.text.primary,
    // fontFamily: "sans-serif",
  },
}))

const UpdatePrivateItem = ({
  className,
  item,
  isPrivate,
  setIsPrivate,
  privateItem,
  setPrivateItem,
  setUpdateItem,
  updateItemInfo,
  isSubmitting,
  ...rest
}) => {
  const classes = useStyles()
  const [pickPerson, setPickPerson] = useState(false)
  const [person, setPerson] = useState(null)
  const [itemOwnerId, setItemOwnerId] = useState("")
  const [isPrereg, setIsPrereg] = useState(true)

  const [handleCreatePrivateItem] = useMutation(NEW_PRIVATE_ITEM_MUTATION, {
    variables: {
      itemId: item.id,
      itemOwnerId: itemOwnerId,
      isPrereg: isPrereg,
    },

    onCompleted: data => {
      console.log(data)
    },
  })

  useEffect(() => {
    if (person) {
      setItemOwnerId(person.uid)
      setPickPerson(true)
      if (person.user) {
        setIsPrereg(false)
      }
    }
  }, [person])

  const handleUpdatePrivateItem = event => {
    event.preventDefault()
    updateItemInfo()
  }

  return (
    <>
      <Box padding={5}>
        <Box alignItems="center" display="flex" mt={2} ml={-1}>
          <Checkbox
            checked={isPrivate}
            onChange={event => setIsPrivate(event.target.checked)}
          />
          <Typography variant="body2" color="textSecondary">
            This is a private item.
          </Typography>
        </Box>
        {isPrivate &&
          privateItem &&
          privateItem.itemOwner &&
          privateItem.itemOwner.uid && (
            <>
              {" "}
              <Box alignItems="center" display="flex" mt={2} mb={2}>
                <Typography variant="body2" color="textPrimary">
                  <Link
                    to={`/manager/people/${
                      privateItem &&
                      privateItem.itemOwner &&
                      privateItem.itemOwner.uid
                    }`}
                    className={classes.name}
                    variant="body2"
                    color="textPrimary"
                  >
                    {privateItem && privateItem.itemOwner.firstName}{" "}
                    {privateItem && privateItem.itemOwner.lastName}
                  </Link>{" "}
                  is the current private item owner and therefore the only
                  person authorized to purchase this item.
                </Typography>
              </Box>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="secondary"
              >
                Change the Item Owner
              </Button>
            </>
          )}
        {isPrivate && privateItem == undefined && !pickPerson && (
          <>
            <Box alignItems="center" display="flex" mt={2} mb={2}>
              <Typography variant="body1" color="textPrimary">
                No private item instance or owner was found.
              </Typography>
            </Box>
            <Box mb={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={() => setPickPerson(true)}
              >
                Set up a new Private Item
              </Button>
            </Box>
            {/* <Box>
              <Button fullWidth variant="outlined" color="secondary">
                Search for Existing Private Item
              </Button>
            </Box> */}
          </>
        )}
        {isPrivate && privateItem == undefined && pickPerson && (
          <Box>
            <PrivateItemOwnerSearch
              setPerson={setPerson}
              setPickPerson={setPickPerson}
              setItemOwnerId={setItemOwnerId}
              setIsPrereg={setIsPrereg}
            />
          </Box>
        )}

        {/* <form
          onSubmit={event => handleUpdateCategory(event)}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <TextField
            label="Select an Item Category"
            name="category"
            onChange={event => setCategory(event.target.value)}
            value={category}
            select
            margin="dense"
            size="small"
            fullWidth
            variant="outlined"
            SelectProps={{ native: true }}
          >
            <option></option>
            {categories.map(c => (
              <option key={c.id} value={c}>
                {c.name}
              </option>
            ))}
          </TextField>
          <Box mt={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Update Item Category
            </Button>
          </Box>
        </form> */}
      </Box>
    </>
  )
}
const NEW_PRIVATE_ITEM_MUTATION = gql`
  mutation ($itemId: Int!, $itemOwnerId: String, $isPrereg: Boolean) {
    createPrivateItem(
      itemId: $itemId
      itemOwnerId: $itemOwnerId
      isPrereg: $isPrereg
    ) {
      privateItem {
        isPrereg
        item {
          id
          name
        }
        itemOwner {
          firstName
          lastName
        }
      }
    }
  }
`
export default UpdatePrivateItem
