import React, { useState, useEffect } from "react"
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useMutation } from "@apollo/client"
import gql from "graphql-tag"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import DeleteIcon from "@material-ui/icons/Delete"
import CheckIcon from "@material-ui/icons/Check"
const QuoteFiles = ({ classes, quoteId, quoteItem }) => {
  // complete list for the menu
  const allItemImages = quoteItem.item.itemImage
  const allSampleProofs = quoteItem.item.sampleProofItem
  // existing proofs and images
  const quoteItemImages = quoteItem.quoteItemImages
  const quoteItemSampleProofs = quoteItem.quoteItemSampleProofs
  // ids of existing proofs and images
  const prevItemImageIds = quoteItemImages
    ? quoteItemImages.map(img => img.id)
    : []
  const prevQuoteItemSampleProofIds = quoteItemSampleProofs
    ? quoteItemSampleProofs.map(img => img.id)
    : []
  // for the ui
  const [itemImages, setItemImages] = useState(quoteItemImages)
  const [sampleProofs, setSampleProofs] = useState(quoteItemSampleProofs)
  // for the mutation
  const [itemImageIds, setItemImageIds] = useState(prevItemImageIds)
  const [sampleProofIds, setSampleProofIds] = useState(
    prevQuoteItemSampleProofIds
  )
  // values for the menu
  const [selectedProof, setSelectedProof] = useState("")
  const [selectedImage, setSelectedImage] = useState("")
  // triggers useEffect and hides buttons during loading/mutation
  const [isSubmitting, setIsSubmitting] = useState(false)
  // switch value
  const [view, setView] = useState("buttons")
  // dialog
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogImage, setDialogImage] = useState("")
  const [dialogVersion, setDialogVersion] = useState("")
  // confirmation alert
  const [showAlert, setShowAlert] = useState(false)

  // do this to allow state to update before triggering mutation
  useEffect(() => {
    if (isSubmitting) {
      updateQuoteItem()
    }
  }, [isSubmitting])

  // add item image from menu to list to be mutated
  const handleAddItemImage = event => {
    const img = event.target.value
    const imgId = img.id
    setItemImages(itemImages => [...itemImages, img])
    setItemImageIds(itemImageIds => [...itemImageIds, imgId])
    setIsSubmitting(true)
    setView("buttons")
  }

  // add sample proof from menu to list to be mutated
  const handleAddSampleProof = event => {
    const img = event.target.value
    const imgId = img.id
    setSampleProofs(sampleProofs => [...sampleProofs, img])
    setSampleProofIds(sampleProofIds => [...sampleProofIds, imgId])
    setIsSubmitting(true)
    setView("buttons")
  }

  // Remove selected attachment via the dialog "remove" button
  const handleRemoveImage = remImg => {
    if (dialogVersion === "sampleProof") {
      const filteredProofs = sampleProofs.filter(
        proof => proof.id !== remImg.id
      )
      const filteredProofIds = sampleProofIds.filter(
        proofId => proofId !== remImg.id
      )
      // for the mutation
      setSampleProofIds(filteredProofIds)
      // for the view render
      setSampleProofs(filteredProofs)
    }
    if (dialogVersion === "itemImage") {
      const filteredItemImages = itemImages.filter(
        itemImage => itemImage.id !== remImg.id
      )
      const filteredItemImageIds = itemImageIds.filter(
        itemImageId => itemImageId !== remImg.id
      )
      // for the mutation
      setItemImageIds(filteredItemImageIds)
      // for the view render
      setItemImages(filteredItemImages)
    }
    setOpenDialog(false)
    // this will trigger the useEffect hook that calls the update mutation
    setIsSubmitting(true)
  }
  // open dialog and set dialog image
  const handleOpenDialog = imageFile => {
    setDialogImage(imageFile)
    imageFile.version && setDialogVersion("sampleProof")
    imageFile.title && setDialogVersion("itemImage")
    setOpenDialog(true)
  }

  // close dialog and clear dialog image
  const handleCloseDialog = () => {
    setOpenDialog(false)
    setDialogImage("")
  }

  // mutation
  const [updateQuoteItem, { data, loading, error }] = useMutation(
    UPDATE_QUOTE_ITEM_MUTATION,
    {
      variables: {
        quoteItemId: quoteItem.id,
        sampleProofIds: sampleProofIds,
        imageIds: itemImageIds,
      },
      onCompleted: data => {
        setShowAlert(true)
        setIsSubmitting(false)
      },
    }
  )
  // view setters for getView(view) switch
  const handlePickItemImage = () => {
    setView("pickItemImage")
  }
  const handlePickSampleProof = () => {
    setView("pickSampleProof")
  }
  // switch to render buttons or menu
  function getView(view) {
    switch (view) {
      case "pickSampleProof":
        return (
          <>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="sample-proof-select-label">
                Sample Proof
              </InputLabel>
              <Select
                labelId="sample-proof-select-label"
                id="sample-proof"
                value={selectedProof}
                onChange={handleAddSampleProof}
                label="Sample Proof"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {allSampleProofs &&
                  allSampleProofs.length > 0 &&
                  allSampleProofs.map(proof => (
                    <MenuItem key={proof.id} value={proof}>
                      <img
                        src={proof.thumbnailUrl}
                        alt={`Version ${proof.version}`}
                        className={classes.thumbnail}
                      />
                      <Typography>Version {proof.version}</Typography>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button onClick={() => setView("buttons")}>x</Button>
          </>
        )
      case "pickItemImage":
        return (
          <>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="item-image-label">Item Image</InputLabel>
              <Select
                labelId="item-image-label"
                id="item-image"
                value={selectedImage}
                onChange={handleAddItemImage}
                label="Item Image"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {allItemImages &&
                  allItemImages.map(image => (
                    <MenuItem
                      key={image.id}
                      value={image}
                      // component={<Box />}
                    >
                      <img src={image.thumbnailUrl} alt={image.image} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <Button onClick={() => setView("buttons")}>x</Button>
          </>
        )
      case "buttons":
        return (
          <>
            {allItemImages && allItemImages.length > 0 && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                className={classes.button}
                startIcon={<AttachFileIcon />}
                onClick={handlePickItemImage}
              >
                Item Image
              </Button>
            )}
            {allSampleProofs && allSampleProofs.length > 0 && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                className={classes.button}
                startIcon={<AttachFileIcon />}
                onClick={handlePickSampleProof}
              >
                Sample Proof
              </Button>
            )}
          </>
        )
      default:
        return (
          <>
            {allItemImages && allItemImages.length > 0 && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                className={classes.button}
                startIcon={<AttachFileIcon />}
                onClick={handlePickItemImage}
              >
                Item Image
              </Button>
            )}
            {allSampleProofs && allSampleProofs.length > 0 && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                className={classes.button}
                startIcon={<AttachFileIcon />}
                onClick={handlePickSampleProof}
              >
                Sample Proof
              </Button>
            )}
          </>
        )
    }
  }

  if (loading) return "loading..."
  if (error) return <div>{JSON.stringify(error)}</div>
  return (
    <>
      {itemImages && itemImages.length > 0 && (
        <Box className={classes.listBox}>
          <Typography>Item Image Attachments:</Typography>
          <List aria-label="item images">
            {itemImages &&
              itemImages.map(img => (
                <ListItem
                  key={img.id}
                  button
                  dense
                  className={classes.listItem}
                  onClick={() => handleOpenDialog(img)}
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      src={img.thumbnailUrl}
                      alt={img.title}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={img.title} />
                  <ListItemIcon>
                    <AttachFileIcon />
                  </ListItemIcon>
                </ListItem>
              ))}
          </List>
          {/* <Divider /> */}
        </Box>
      )}
      {sampleProofs && sampleProofs.length > 0 && (
        <Box className={classes.listBox}>
          <Typography>Sample Proof Attachments:</Typography>
          <List aria-label="sample proofs">
            {sampleProofs &&
              sampleProofs.map(proof => {
                return (
                  <ListItem
                    key={proof.id}
                    button
                    dense
                    className={classes.listItem}
                    onClick={() => handleOpenDialog(proof)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        src={proof.thumbnailUrl}
                        alt={`Version ${proof.version}`}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={`Proof version ${proof.version}`} />
                    <ListItemIcon>
                      <AttachFileIcon />
                    </ListItemIcon>
                  </ListItem>
                )
              })}
          </List>
        </Box>
      )}

      {/* if not submitting, render the view from the switch */}
      {!isSubmitting && getView(view)}

      {/* dialog to show larger image and delete button */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ className: classes.dialog }}
      >
        <DialogTitle id="alert-dialog-title">
          {dialogImage.title && dialogImage.title}
          {dialogImage.version && `Version ${dialogImage.version}`}
        </DialogTitle>
        <DialogContent>
          <img
            src={dialogImage.carouselImageUrl}
            className={classes.dialogImage}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleRemoveImage(dialogImage)}
            color="primary"
          >
            Remove
          </Button>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const styles = theme => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  thumbnail: {
    height: "40px",
  },
  listItem: {
    // maxWidth: "270px",
  },
  listBox: {
    maxWidth: "270px",
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  dialogImage: {
    width: "100%",
  },
  dialog: {
    borderRadius: "15px",
  },
})

const UPDATE_QUOTE_ITEM_MUTATION = gql`
  mutation ($quoteItemId: Int!, $sampleProofIds: [Int], $imageIds: [Int]) {
    updateQuoteItem(
      quoteItemId: $quoteItemId
      sampleProofIds: $sampleProofIds
      imageIds: $imageIds
    ) {
      quoteItem {
        id
        quoteItemImages {
          id
          image
          title
          featured
          thumbnailUrl
          carouselImageUrl
        }
        quoteItemSampleProofs {
          id
          status {
            id
            name
          }
          version
          isVertical
          carouselImageUrl
          thumbnailUrl
        }
      }
    }
  }
`
export default withStyles(styles)(QuoteFiles)
