import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"
import {
  Breadcrumbs,
  Button,
  Box,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { Edit as EditIcon, PlusCircle as AddIcon } from "react-feather"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 4,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  quoteButton: {
    marginLeft: 5,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
}))

const Header = ({ className, ...rest }) => {
  const classes = useStyles()

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            className={classes.link}
            variant="body1"
            color="inherit"
            to="/manager"
          >
            Dashboard
          </Link>
          <Typography variant="body1" color="textPrimary">
            Proposals
          </Typography>
        </Breadcrumbs>
        <Box mt={2}>
          <Typography variant="h3" color="textPrimary">
            Proposals
          </Typography>
        </Box>
      </Grid>

      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          className={classes.quoteButton}
          component={Link}
          to="/manager/proposals/new"
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <AddIcon />
          </SvgIcon>
          New Proposal
        </Button>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  // supplierOrder: PropTypes.object.isRequired,
}

export default Header
