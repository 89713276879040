import React from "react"
import UploadButton from "./UploadButton"
import UploadCards from "./UploadCards"
import UploadList from "./UploadList"
import Divider from "@material-ui/core/Divider"

const Upload = () => {
  return (
    <div>
      <UploadButton />
      <Divider />
      <UploadList />
      {/* <UploadCards /> */}
    </div>
  )
}

export default Upload
