import React from "react"

const Vendors = () => {
  return (
    <div>
      <h1>Vendors</h1>
      <h2>Lists</h2>
      <ul>
        <li>companies</li>
        <li>contacts</li>
      </ul>
      <h2>Filters</h2>
      <ul>
        <li>Product</li>
        <li>service</li>
        <li>country</li>
        <li>rating</li>
      </ul>
      <h2>Lists</h2>
      <ul>
        <li>Total orders</li>
        <li>Total spent</li>
      </ul>
      <h2>Charts and Graphs</h2>
      <ul>
        <li>map</li>
        <li>sales by item</li>
        <li>profit margin</li>
        <li>turnaround time</li>
      </ul>
    </div>
  )
}

export default Vendors
