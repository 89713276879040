import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import PerfectScrollbar from "react-perfect-scrollbar"
import { useQuery } from "@apollo/client"
import Loading from "../../../../../Utils/Loading"
import Error from "../../../../../Utils/Error"
import gql from "graphql-tag"
import { Link } from "gatsby"

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 2,
    marginBotton: 5,
  },
}))

const PrivateItem = ({ item, className, ...rest }) => {
  const classes = useStyles()
  const id = item.id
  const { loading, error, data } = useQuery(PRIVATE_ITEM_QUERY, {
    variables: { id: id },
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const privateItem = data.privateItem

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          title={privateItem.isPrereg ? "Pre-reg Private Item" : "Private Item"}
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" component="p">
            Item Owner:{" "}
            <Link to={`/manager/people/${privateItem.itemOwner.uid}`}>
              {privateItem.itemOwner.firstName} {privateItem.itemOwner.lastName}
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}

PrivateItem.propTypes = {
  className: PropTypes.string,
  //   tem: PropTypes.object.isRequired,
}
const PRIVATE_ITEM_QUERY = gql`
  query ($id: Int!) {
    privateItem(id: $id) {
      item {
        id
        name
        category {
          name
        }
      }
      itemOwner {
        uid
        firstName
        lastName
      }
      isPrereg
      preregItem {
        privateItem {
          item {
            id
          }
        }
        isConverted
      }
    }
  }
`
export default PrivateItem
