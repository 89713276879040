import React, { useEffect } from "react"
import { Box, Button, Container, makeStyles } from "@material-ui/core"
import CreatePrereg from "./CreatePrereg"
import { SettingsBackupRestoreOutlined } from "@material-ui/icons"
import useDidMountEffect from "../../../../Global/hooks/useDidMountEffect"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
    padding: 3,
  },
  confirm: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const ConfirmProposalPerson = ({
  person,
  setPersonUid,
  setConfirmed,
  prereg,
  setPrereg,
  setIsPrereg,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const uid = person.uid

  const handleConfirm = event => {
    setPersonUid(uid)
    setConfirmed(true)
  }
  useEffect(() => {
    if (!person.user) {
      if (person.preregPerson || prereg) {
        setIsPrereg(true)
      }
    }
  }, [])
  return (
    <>
      <Box m={5}>
        {!person.user && !person.preregPerson && !prereg ? (
          <CreatePrereg
            prereg={prereg}
            setPrereg={setPrereg}
            setIsPrereg={setIsPrereg}
            person={person}
          />
        ) : (
          <Button
            onClick={handleConfirm}
            color="secondary"
            fullWidth
            size="large"
            variant="contained"
          >
            Confirm {person.firstName} {person.lastName}{" "}
            {person.user && "(user)"} {person.preregister && "(prereg)"}{" "}
            {prereg && "(prereg)"}
          </Button>
        )}
      </Box>
    </>
  )
}

export default ConfirmProposalPerson
