import React, { useCallback, useEffect, useState, useContext } from "react"
import { Box, Container, makeStyles } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import PerfectScrollbar from "react-perfect-scrollbar"
import SortContext from "../../../../context/SortContext"
import Loading from "../../../Utils/Loading"
import Error from "../../../Utils/Error"
// import useIsMountedRef from "src/hooks/useIsMountedRef"
import Page from "../../../Global/Page"
import Header from "./Header"
import Results from "./Results"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "91vh",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const OrderListView = () => {
  const classes = useStyles()
  const { state, dispatch } = useContext(SortContext)
  const search = state.search
  const page = state.page + 1
  const pageSize = state.pageSize

  const [orderStatusFilter, setOrderStatusFilter] = useState("")
  const [fromCartFilter, setFromCartFilter] = useState(null)
  const [inProgressFilter, setInProgressFilter] = useState(null)
  const [businessUnitFilter, setBusinessUnitFilter] = useState(0)

  const { data, loading, error } = useQuery(LIST_ORDERS_QUERY, {
    variables: {
      search: search,
      page: page,
      pageSize: pageSize,
      orderStatusFilter: orderStatusFilter,
      businessUnitFilter: businessUnitFilter,
      fromCartFilter: fromCartFilter,
      inProgressFilter: inProgressFilter,
    },

    fetchPolicy: "cache-and-network",
  })
  if (loading) return <Loading />
  if (error) return <Error />
  if (data == undefined) return ""
  if (data == null) {
    return ""
  }
  const orderData = data.orders

  return (
    <Page className={classes.root} title="Orders List">
      <PerfectScrollbar>
        <Container maxWidth={false}>
          <Header />
          <Box mt={3}>
            <Results
              orderData={orderData}
              orderStatusFilter={orderStatusFilter}
              setOrderStatusFilter={setOrderStatusFilter}
              fromCartFilter={fromCartFilter}
              setFromCartFilter={setFromCartFilter}
              inProgressFilter={inProgressFilter}
              setInProgressFilter={setInProgressFilter}
              businessUnitFilter={businessUnitFilter}
              setBusinessUnitFilter={setBusinessUnitFilter}
            />
          </Box>
        </Container>
      </PerfectScrollbar>
    </Page>
  )
}

const LIST_ORDERS_QUERY = gql`
  query (
    $page: Int
    $pageSize: Int
    $search: String
    $orderStatusFilter: String
    $businessUnitFilter: Int
    $fromCartFilter: Boolean
    $inProgressFilter: Boolean
  ) {
    orders(
      page: $page
      pageSize: $pageSize
      search: $search
      orderStatusFilter: $orderStatusFilter
      businessUnitFilter: $businessUnitFilter
      fromCartFilter: $fromCartFilter
      inProgressFilter: $inProgressFilter
    ) {
      page
      pages
      hasNext
      hasPrev
      count
      objects {
        id
        customer {
          uid
          firstName
          lastName
        }
        status {
          id
          orderStatus
        }
        orderDate
        completionDate
        businessUnit {
          id
          name
        }
        taxAmt
        discountAmt
        postageAmt
        balanceDue
        balanceDueDate
        notes
        inProgress
        fromCart
        cartId
      }
    }
  }
`
export default OrderListView
