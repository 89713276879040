import React from "react"
import OrderProvider from "./context/OrderProvider"
import OrderDetails from "./OrderDetails"
import { useMatch } from "@reach/router"
const OrderDetailsView = () => {
  const match = useMatch("/manager/orders/:id")

  return (
    <OrderProvider>
      <OrderDetails id={match.id} />
    </OrderProvider>
  )
}

export default OrderDetailsView
