import React, { useState } from "react"
import clsx from "clsx"
import {
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  ClickAwayListener,
} from "@material-ui/core"
import gql from "graphql-tag"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import { navigate } from "gatsby-link"
import Loading from "../../../../Utils/Loading"
import Error from "../../../../Utils/Error"

const RemovePerson = ({ classes, person, ...rest }) => {
  const [message, setMessage] = useState("")
  const [clickAgain, setClickAgain] = useState(false)
  const uid = person.uid

  const [handleMutate, { loading, error }] = useMutation(
    REMOVE_PERSON_MUTATION,
    {
      variables: {
        uid: uid,
      },
      onCompleted: data => {
        navigate("/manager/people")
      },
      onError: data => {
        setMessage("Unable to remove person")
      },
    }
  )
  const handleSubmit = event => {
    clickAgain ? handleMutate() : setClickAgain(true)
  }
  const handleClickAway = () => {
    clickAgain && setClickAgain(false)
  }
  if (loading) return <Loading />
  if (error)
    return (
      <>
        <Typography>{message ? message : "error..."}</Typography>
      </>
    )
  return (
    <div>
      <Card className={clsx(classes.root, classes.roundedCard)}>
        <CardHeader title="Remove Person" />
        <Divider />
        <ClickAwayListener onClickAway={handleClickAway}>
          <CardContent>
            <Button
              className={classes.button}
              fullWidth
              size="small"
              variant="contained"
              color={clickAgain ? "primary" : "secondary"}
              type="submit"
              onClick={handleSubmit}
            >
              {clickAgain ? "Confirm or click away" : "Remove Person"}
            </Button>
            <Typography>{message ? message : ""}</Typography>
          </CardContent>
        </ClickAwayListener>
      </Card>
    </div>
  )
}

const styles = theme => ({
  root: {},
  button: {
    // margin: theme.spacing(1),
  },
  roundedCard: {
    borderRadius: "15px",
  },
})
const REMOVE_PERSON_MUTATION = gql`
  mutation ($uid: String!) {
    deletePerson(uid: $uid) {
      uid
    }
  }
`
export default withStyles(styles)(RemovePerson)
